import { useState, useEffect } from "react";

interface UseFullScreenModeProps {
	initialFullScreenMode?: boolean;
}

interface UseFullScreenModeReturn {
	isFullScreenMode: boolean;
	setIsFullScreenMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const useFullScreenMode = (
	props: UseFullScreenModeProps = {}
): UseFullScreenModeReturn => {
	const { initialFullScreenMode = false } = props;
	const [isFullScreenMode, setIsFullScreenMode] = useState<boolean>(
		initialFullScreenMode
	);

	useEffect(() => {
		function onFullscreenChange() {
			setIsFullScreenMode(Boolean(document.fullscreenElement));
		}

		document.addEventListener("fullscreenchange", onFullscreenChange);

		return () =>
			document.removeEventListener("fullscreenchange", onFullscreenChange);
	}, []);

	return { isFullScreenMode, setIsFullScreenMode };
};

export default useFullScreenMode;
