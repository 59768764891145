import { Icon } from "@iconify/react";
import {
	Avatar,
	Box,
	IconButton,
	InputAdornment,
	Paper,
	Stack,
	TextField,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import { useAi } from "hooks/data/useAi";
import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { openedTabAtom, selectedToolAtom } from "store/atoms/UiAtoms";
import { aiMessagesAtom } from "store/atoms/aiAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import hex from "utils/hexTransparency";
import EllipsisAnimation from "./EllipsisAnimation";
import { rateLimitAtoms } from "store/atoms/userAtoms";

const QUESTIONS = [
	"Can you briefly analyze and summarize this code for me?",
	"What are the security concerns of this project?",
	"Which functions change the state of the contract?",
	"How does the contract handle user funds?"
];

const AIMessage: React.FC<{
	children: React.ReactNode;
	trimPadding: boolean;
}> = ({ children, trimPadding }) => {
	const theme = useTheme();

	return (
		<Stack direction="row" spacing={1}>
			<Avatar sx={{ bgcolor: "primary.dark" }}>
				<Typography sx={{ fontWeight: 600, color: "text.primary" }}>
					AI
				</Typography>
			</Avatar>

			<Paper
				sx={{
					backgroundImage: "none",
					position: "relative",
					flex: 1,
					borderRadius: "14px",
					p: 2,
					py: trimPadding ? "2px" : 2,
					bgcolor: `${theme.palette.primary.dark}`,
					borderTopLeftRadius: 0,
					code: { whiteSpace: "pre-wrap !important" }
				}}
			>
				{/* Triangle */}
				<Box
					sx={{
						position: "absolute",
						height: 0,
						width: 0,
						top: "0px",
						left: "-7px",
						borderTop: "0px solid transparent",
						borderBottom: "18px solid transparent",
						borderRight: `12px solid ${theme.palette.primary.dark}`,
						borderTopLeftRadius: "2px"
					}}
				/>
				{children}
			</Paper>
		</Stack>
	);
};

const UserMessage: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const theme = useTheme();

	return (
		<Stack direction="row" spacing={1}>
			<Paper
				sx={{
					backgroundImage: "none",
					position: "relative",
					flex: 1,
					borderRadius: "14px",
					p: 2,
					bgcolor: `${theme.palette.background.default}`,
					borderTopRightRadius: 0
				}}
			>
				{/* Triangle */}
				<Box
					sx={{
						position: "absolute",
						height: 0,
						width: 0,
						top: "0px",
						right: "-7px",
						borderTop: "0px solid transparent",
						borderBottom: "18px solid transparent",
						borderLeft: `12px solid ${theme.palette.background.default}`,
						borderTopRightRadius: "2px"
					}}
				/>
				{children}
			</Paper>

			<Avatar
				sx={{
					bgcolor: `${theme.palette.background.default}${hex["80%"]}`
				}}
			>
				<Typography sx={{ fontWeight: 600, color: "text.primary" }}>
					ME
				</Typography>
			</Avatar>
		</Stack>
	);
};

const AIChatBox: React.FC = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [{ openedFile, projectFilePath }] = useAtom(openedTabAtom);
	const [userMessage, setUserMessage] = useState("");

	const [project] = useAtom(projectAtom);
	const [selectedTool] = useAtom(selectedToolAtom);
	const [atomMessages] = useAtom(aiMessagesAtom);

	const { responding, sendChatWithAI, handleStopResponding } = useAi();

	const messages = atomMessages?.[projectFilePath] || [];

	const bottomRef = useRef(null);

	// Scroll to bottom whenever updating the chat window
	useEffect(() => {
		bottomRef.current?.scrollIntoView(false, { behavior: "smooth" });
	}, [responding, atomMessages]);

	// Scroll to bottom on first load
	useEffect(() => {
		if (selectedTool === "AIScan") {
			setTimeout(() => {
				bottomRef.current?.scrollIntoView(false, { behavior: "smooth" });
			}, 500);
		}
	}, [selectedTool]);

	const sendMessage = async (qn = null) => {
		await sendChatWithAI(
			qn || userMessage,
			messages,
			projectFilePath,
			openedFile?.id,
			project.id
		);

		setUserMessage("");
	};

	const handleSubmitForm = (e: React.FormEvent) => {
		e.preventDefault();
		sendMessage();
	};

	const [rateLimits] = useAtom(rateLimitAtoms);

	const { current, limit, retryAfter } = rateLimits["ai"] || {};

	return (
		<>
			{/* Scrollable messages container */}
			<Stack sx={{ height: "100%", overflow: "auto", my: 1.5 }}>
				{/* Default questions */}
				{!messages.length && (
					<Stack spacing={1} sx={{ flex: 1, mb: 2 }}>
						<Typography sx={{ pb: 0.5 }}>
							Ask me anything about this project! Here are some ideas:
						</Typography>

						{QUESTIONS.map((qn, i) => (
							<Paper
								elevation={0}
								key={i}
								onClick={() => sendMessage(qn)}
								sx={{
									p: 2,
									cursor: "pointer",
									borderRadius: "14px",
									backgroundColor: `${theme.palette.background.default}${hex["80%"]}`,
									boxShadow: 1,
									"&:hover": {
										backgroundColor:
											themeMode === "dark"
												? `${theme.palette.primary.main}${hex["80%"]}`
												: `${theme.palette.primary.light}${hex["80%"]}`
									}
								}}
							>
								<Typography sx={{ wordBreak: "break-word" }}>{qn}</Typography>
							</Paper>
						))}
					</Stack>
				)}

				{/* AI Messages */}
				{!!messages?.length && (
					<Stack sx={{ overflow: "auto" }} spacing={2}>
						{messages.map(({ role, content }, i) => {
							const isAi = role === "assistant";

							if (isAi) {
								const isLoading =
									responding &&
									i === messages.length - 1 &&
									content.length === 0;

								return (
									<AIMessage key={i} trimPadding={!isLoading}>
										{isLoading ? (
											<EllipsisAnimation />
										) : (
											<Box
												sx={{
													width: "100%",
													wordBreak: "break-word"
												}}
											>
												<ReactMarkdown>{content}</ReactMarkdown>
											</Box>
										)}
									</AIMessage>
								);
							} else {
								return (
									<UserMessage key={i}>
										<Typography
											sx={{ whiteSpace: "pre-line", wordBreak: "break-word" }}
										>
											{content}
										</Typography>
									</UserMessage>
								);
							}
						})}

						<Box ref={bottomRef} sx={{ m: "0px !important" }} />
					</Stack>
				)}
			</Stack>

			<Box sx={{ flex: 1 }} />

			{/* Submit */}
			<form onSubmit={handleSubmitForm} style={{ width: "100%" }}>
				<TextField
					variant="standard"
					fullWidth
					required
					multiline
					minRows={1}
					maxRows={3}
					value={userMessage}
					onKeyDown={(event) => {
						if (event.key === "Enter" && !event.shiftKey && current !== limit) {
							event.preventDefault();
							handleSubmitForm(event);
						}
					}}
					placeholder={"Chat about " + openedFile?.name}
					disabled={responding || current === limit}
					onChange={(e) => {
						setUserMessage(e.target.value);
					}}
					sx={{
						border: `10px solid ${theme.palette.background.default}${hex["80%"]}`,
						borderRadius: "12px",
						py: 1,
						px: 1.5,
						bgcolor: "background.paper",
						...(themeMode === "light" && {
							border: `5px solid ${theme.palette.background.paper}`,
							boxShadow: 1
						})
					}}
					InputProps={{
						disableUnderline: true,
						sx: {
							"& ::placeholder": {
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap"
							}
						},
						endAdornment: (
							<InputAdornment position="end">
								{responding ? (
									<Tooltip title="Stop generating response">
										<IconButton
											size="small"
											onClick={handleStopResponding}
											sx={{ p: 0, height: 30, width: 30 }}
										>
											<Icon icon="f7:stop-fill" fontSize={18} />
										</IconButton>
									</Tooltip>
								) : (
									<IconButton
										size="small"
										type="submit"
										disabled={!userMessage || current === limit}
										sx={{ p: 0, height: 30, width: 30 }}
									>
										<Icon icon="iconoir:send-solid" fontSize={20} />
									</IconButton>
								)}
							</InputAdornment>
						)
					}}
				/>
			</form>
		</>
	);
};

export default React.memo(AIChatBox);
