import { Icon } from "@iconify/react";
import {
	Box,
	Button,
	Fade,
	Stack,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import AnimatedBg from "assets/images/gif/smoke.gif";
import { useAtom } from "jotai";
import React from "react";
import Tilt from "react-parallax-tilt";
import { openBadgeAtom } from "store/atoms/badgesAtom";

const StyledSocialButton = styled(Button)({
	borderRadius: "8px",
	height: 39
});

const StyledTilt = styled(Tilt)({
	borderRadius: "12px",
	"& .glare-wrapper": {
		borderRadius: "12px!important"
	}
});

const BadgeFull: React.FC = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [badge, setOpen] = useAtom(openBadgeAtom);
	const open = !!badge;

	const { name, description, imageUrl } = badge || {};

	const handleClose = () => {
		setOpen(null);
	};

	const shareTweet = () => {
		const text = `I just unlocked the ${badge.name} badge on Audit Wizard!`;

		const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
			window.location.href
		)}&text=${encodeURIComponent(text)}`;

		window.open(url, "_blank");
	};

	const shareLinkedin = () => {
		const text = `I just unlocked the ${badge.name} badge on Audit Wizard!`;

		const url = `https://www.linkedin.com/feed/?shareActive=true&text=${encodeURIComponent(
			text
		)}`;

		window.open(url, "_blank");
	};

	return (
		<Fade in>
			<Box
				sx={{
					maxWidth: 450,
					position: "relative",
					backgroundColor: "transparent",
					backgroundImage: "none"
				}}
			>
				<Stack
					sx={{
						position: "relative",
						border: `1px solid ${theme.palette.primary.main}`,
						bgcolor:
							themeMode === "dark" ? "background.paper" : "background.default",
						flex: 1
					}}
				>
					{/* BG Image */}
					<Box
						component="img"
						src={AnimatedBg}
						sx={{
							position: "absolute",
							top: 0,
							right: 0,
							left: 0,
							bottom: 0,
							borderTopRightRadius: "inherit",
							borderTopLeftRadius: "inherit",
							width: "100%",
							height: "70%",
							objectFit: "cover"
						}}
					/>

					{/* Overlay */}
					<Box
						sx={{
							mt: "0px!important",
							position: "absolute",
							top: 0,
							right: 0,
							left: 0,
							bottom: 0,
							width: "100%",
							height: "70%",
							borderTopRightRadius: "inherit",
							borderTopLeftRadius: "inherit",
							// bgcolor: `${theme.palette.background.paper}${hex["80%"]}`
							background:
								themeMode === "dark"
									? `linear-gradient(to top, ${theme.palette.background.paper}, rgba(255, 0, 0, 0))`
									: `linear-gradient(to top, ${theme.palette.background.default}, rgba(255, 255,255,0))`
						}}
					/>

					{/* Content */}
					<Stack
						alignItems="center"
						justifyContent="center"
						sx={{ p: 2, textAlign: "center", zIndex: 1 }}
					>
						<StyledTilt
							glareEnable={true}
							glareMaxOpacity={0.2}
							glarePosition="all"
							glareColor={theme.palette.background["nav"]}
							trackOnWindow={true}
							tiltMaxAngleX={4}
							tiltMaxAngleY={4}
							perspective={700}
						>
							{/* Image */}
							<Tilt trackOnWindow={true} tiltMaxAngleX={12} tiltMaxAngleY={12}>
								<Box
									component="img"
									src={imageUrl}
									sx={{
										height: {
											xs: 150,
											md: 250,
											maxWidth: "100%",
											objectFit: "contain"
										},
										mb: 1
									}}
								/>
							</Tilt>
						</StyledTilt>

						<Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
							{name}
						</Typography>

						<Typography sx={{ px: 1, mb: 1.5 }}>{description}</Typography>
					</Stack>

					<Box sx={{ flex: 1 }} />

					{/* Socials */}
					<Box sx={{ p: 2, zIndex: 1 }}>
						<Typography sx={{ mb: 1.5, textAlign: "center" }}>
							Share on
						</Typography>

						<Stack spacing={1} direction="row" alignItems="center">
							<StyledSocialButton
								variant="outlined"
								fullWidth
								onClick={shareTweet}
							>
								<Icon
									icon="ri:twitter-x-fill"
									fontSize={19}
									color={theme.palette.text.primary}
								/>
							</StyledSocialButton>

							<StyledSocialButton
								variant="outlined"
								fullWidth
								onClick={shareLinkedin}
							>
								<Icon
									icon="mingcute:linkedin-fill"
									fontSize={22}
									color={theme.palette.text.primary}
								/>
							</StyledSocialButton>
						</Stack>
					</Box>
				</Stack>
			</Box>
		</Fade>
	);
};

export default React.memo(BadgeFull);
