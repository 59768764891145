import { codeChallengeSubmittedEvent } from "Mixpanel/mixpanel.helper";
import { addErrorNotification, addSuccessNotification } from "./Notifications";
import { fetch401Interceptor, getCsrfToken } from "./ServerInterface";

export interface Challenge {
	_id?: string;
	live: boolean;
    repoUrl: string;
    expectedEndDate: string;
    title: string;
    description: string;
    image: string;
    commitHash: string;
    solutionWriteup?: string;
}

export interface ChallengeSubmission {
    userID?: string;
    _id?: string;
    challengeID: string;
    answerFileLocation?: string;
    answerLineStart: string;
    answerLineEnd: string;
    answerDescription: string;
}

export interface SubmittedChallengesResponse {
    challengeIds: string[];
}

const SERVER_URL =
	window.location.protocol +
	"//" +
	window.location.hostname +
	":" +
	(process.env.REACT_APP_SERVER_PORT || "5000");

export async function getChallenges(): Promise<Challenge[]> {
    try {
        const url =
            SERVER_URL +
            `/challenge?` +
            new URLSearchParams({ csrfToken: await getCsrfToken() });

        const res = await fetch401Interceptor(url, { credentials: "include" });

        if (res.ok) {
            const resJson: Challenge[] = await res.json();
            return resJson;
        } else {
            const resError = await res.text();
            throw new Error(resError);
        }
    } catch (e) {
        addErrorNotification("Error", "Error loading code challenges: " + e);
        return [];
    }
}

export async function getSubmittedChallenges(): Promise<SubmittedChallengesResponse> {
    try {
        const url =
            SERVER_URL +
            `/challenge/submitted?` +
            new URLSearchParams({ csrfToken: await getCsrfToken() });

        const res = await fetch401Interceptor(url, { credentials: "include" });

        if (res.ok) {
            const resJson: SubmittedChallengesResponse = await res.json();
            return resJson;
        } else {
            const resError = await res.text();
            throw new Error(resError);
        }
    } catch (e) {
        return { challengeIds: [] };
    }
}

export async function submitChallengeAnswer(submission: ChallengeSubmission, mixpanel: any): Promise<boolean> {
    try {
        const url =
            SERVER_URL +
            `/challenge/submit?` +
            new URLSearchParams({ csrfToken: await getCsrfToken() });

        const res = await fetch401Interceptor(url, {
            method: "PUT",
            credentials: "include",
            body: JSON.stringify(submission)
        });

        if (!res.ok) throw new Error(await res.text());

        codeChallengeSubmittedEvent(mixpanel, { userID: submission.userID, challengeID: submission.challengeID });

        return true;
    } catch (e) {
        addErrorNotification("Error", "Error submitting challenge: " + e);
        return false;
    }
}