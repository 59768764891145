import { Icon } from "@iconify/react";
import { Box, Button, Stack, useTheme } from "@mui/material";
import { useAtom } from "jotai";
import { useState } from "react";
import Draggable, { DraggableData } from "react-draggable";
import { useHotkeys } from "react-hotkeys-hook";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { stickyNoteOpenAtom } from "store/atoms/stickyNoteAtoms";
import StickyNoteField from "./StickyNoteField";
import { isMac } from "utils/helpersFunctions";

const StickyNote = () => {
	const theme = useTheme();

	const [isDragging, setIsDragging] = useState(false);
	const [size, setSize] = useState({ height: 310, width: 280 });

	const [position, setPosition] = useState<{ x: number; y: number }>({
		x: 80,
		y: 80
	});

	const [open, setOpen] = useAtom(stickyNoteOpenAtom);

	const isOsMac = isMac();

	useHotkeys(
		isOsMac ? "meta+shift+slash" : "ctrl+shift+slash",
		() => setOpen(!open),
		[open]
	);

	const handleDrag = (e: MouseEvent, data: DraggableData) => {
		let x = data.x;
		let y = data.y;

		if (x < 0 || x > window.innerWidth - 100) {
			x = x < 0 ? 20 : window.innerWidth - 100;
		}

		if (y < 0 || y > window.innerHeight - 100) {
			y = y < 0 ? 20 : window.innerHeight - 100;
		}

		setPosition({ x, y });
	};

	const onResize = (event, { node, size, handle }) => {
		setSize({ width: size.width, height: size.height });
	};

	if (!open) return null;

	return (
		<Draggable
			handle=".draggable-handle"
			position={position}
			onDrag={handleDrag}
		>
			<Resizable
				className="box"
				height={size.height}
				width={size.width}
				onResize={onResize}
				minConstraints={[200, 200]}
				resizeHandles={["s", "se", "e"]}
			>
				<Box
					sx={{
						bgcolor: "background.default",
						zIndex: 999999,
						position: "absolute",
						height: size.height,
						width: size.width,
						borderRadius: "8px",
						border: "1px solid",
						borderColor: "primary.main",
						boxShadow:
							"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 25px"
					}}
				>
					{/* Toolbar */}
					<Stack
						direction="row"
						className="draggable-handle"
						onMouseDown={() => setIsDragging(true)}
						onMouseUp={() => setIsDragging(false)}
						sx={{
							cursor: isDragging ? "grabbing" : "grab",
							bgcolor: "primary.main",
							borderTopRightRadius: "5px",
							borderTopLeftRadius: "5px",
							height: 33
						}}
					>
						<Box sx={{ flex: 1 }} />

						<Button
							sx={{
								minWidth: 0,
								color: "grey.600",
								borderTopRightRadius: "inherit",
								"&:hover": { bgcolor: "primary.dark" }
							}}
							onClick={() => setOpen(false)}
						>
							<Icon
								icon="fluent:minimize-12-filled"
								fontSize={18}
								color={theme.palette.text.primary}
							/>
						</Button>
					</Stack>

					<StickyNoteField />
				</Box>
			</Resizable>
		</Draggable>
	);
};

export default StickyNote;
