import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FieldValues, Resolver, useForm } from "react-hook-form";
import * as Yup from "yup";
import FormTextField from "../../formFields/FormTextField";

export interface TestCollectionFormType {
	title: string;
}

interface Props {
	handleSubmit: (data: TestCollectionFormType) => void;
	disabled?: boolean;
}

const schema = Yup.object().shape({
	title: Yup.string().required("Title is required")
});

const TestCollectionForm: React.FC<Props> = ({
	handleSubmit: propsHandleSubmit,
	disabled: PropsDisabled = false
}) => {
	const [disabled, setDisabled] = useState(PropsDisabled);

	useEffect(() => {
		setDisabled(PropsDisabled);
	}, [PropsDisabled]);

	const formOptions = {
		resolver: yupResolver(schema) as Resolver<
			TestCollectionFormType,
			FieldValues
		>
	};

	const {
		handleSubmit,
		register,
		formState: { errors }
	} = useForm<FieldValues & TestCollectionFormType & any>(formOptions);

	const onSubmit = (data: TestCollectionFormType) => {
		// setDisabled(true);
		propsHandleSubmit?.(data);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={1.5} sx={{ px: 0.4 }}>
				<Typography sx={{ fontWeight: 600 }}>
					Create template collection
				</Typography>

				<Typography>
					Organize your tests into collections to save time and make is easier
					for others auditors to discover and learn from you
				</Typography>

				<FormTextField
					fullWidth
					disabled={disabled}
					required
					name="title"
					placeholder="Title"
					register={register}
					error={!!errors["title"]}
					helperText={errors["title"]?.message}
					sx={{ flex: 1 }}
				/>

				<LoadingButton
					type="submit"
					variant="contained"
					disabled={disabled}
					loading={disabled}
					sx={{ height: 39, boxShadow: 1 }}
				>
					Create collection
				</LoadingButton>
			</Stack>
		</form>
	);
};

export default TestCollectionForm;
