import AnsiToHtml from "ansi-to-html";

export function formatStackTrace(stackTrace): string {
	if (!stackTrace) {
		return "";
	}

	const colors = {
		cyan: "\x1b[36m",
		yellow: "\x1b[33m",
		blue: "\x1b[38;5;117m",
		magenta: "\x1b[35m",
		green: "\x1b[32m",
		red: "\x1b[31m",
		white: "\x1b[37m",
		gray: "\x1b[90m",
		reset: "\x1b[0m"
	};

	const formatted = stackTrace
		.map((entry) => {
			const errorLine = entry.error
				? `${colors.red}Error: ${entry.error}${colors.reset}`
				: `${colors.green}No error${colors.reset}`;

			return [
				`${colors.yellow}Contract Address: ${entry.contract}${colors.reset}`,
				`${colors.blue}Contract Name: ${entry.name}${colors.reset}`,
				`${colors.magenta}Line: ${entry.line}${colors.reset}`,
				errorLine,
				`${colors.white}Function Name: ${entry.code}${colors.reset}`,
				`${colors.white}Operation: ${entry.op}${colors.reset}`,
				`${colors.white}Length: ${entry.length}${colors.reset}`,
				`${colors.gray}${"=".repeat(50)}${colors.reset}`
			].join("\n");
		})
		.join("\n");

	return formatted;
}

export function formatCallTrace(callTrace): string {
	if (!callTrace) {
		return "";
	}

	const colors = {
		cyan: "\x1b[36m",
		yellow: "\x1b[33m",
		skyBlue: "\x1b[38;5;117m",
		magenta: "\x1b[35m",
		green: "\x1b[32m",
		red: "\x1b[31m",
		white: "\x1b[37m",
		gray: "\x1b[90m",
		reset: "\x1b[0m"
	};

	const formatted = callTrace
		.map((entry) => {
			const lines: string[] = [];

			if (entry.hash !== undefined)
				lines.push(
					`${colors.cyan}Transaction Hash: ${entry.hash}${colors.reset}`
				);
			if (entry.contract_name !== undefined)
				lines.push(
					`${colors.yellow}Contract Name: ${entry.contract_name}${colors.reset}`
				);
			if (entry.function_name !== undefined)
				lines.push(
					`${colors.skyBlue}Function Name: ${entry.function_name}${colors.reset}`
				);
			if (entry.function_pc !== undefined)
				lines.push(
					`${colors.magenta}Function PC: ${entry.function_pc}${colors.reset}`
				);
			if (entry.function_op !== undefined)
				lines.push(
					`${colors.white}Function OP: ${entry.function_op}${colors.reset}`
				);
			if (entry.function_file_index !== undefined)
				lines.push(
					`${colors.white}File Index: ${entry.function_file_index}${colors.reset}`
				);
			if (entry.function_code_start !== undefined)
				lines.push(
					`${colors.white}Function Code Start: ${entry.function_code_start}${colors.reset}`
				);
			if (entry.function_line_number !== undefined)
				lines.push(
					`${colors.white}Function Line Number: ${entry.function_line_number}${colors.reset}`
				);
			if (entry.function_code_length !== undefined)
				lines.push(
					`${colors.white}Function Code Length: ${entry.function_code_length}${colors.reset}`
				);
			if (entry.absolute_position !== undefined)
				lines.push(
					`${colors.white}Absolute Position: ${entry.absolute_position}${colors.reset}`
				);
			if (entry.caller_pc !== undefined)
				lines.push(
					`${colors.white}Caller PC: ${entry.caller_pc}${colors.reset}`
				);
			if (entry.caller_op !== undefined)
				lines.push(
					`${colors.white}Caller OP: ${entry.caller_op}${colors.reset}`
				);
			if (entry.call_type !== undefined)
				lines.push(
					`${colors.white}Call Type: ${entry.call_type}${colors.reset}`
				);
			if (entry.address !== undefined)
				lines.push(`${colors.white}Address: ${entry.address}${colors.reset}`);
			if (entry.from !== undefined)
				lines.push(`${colors.white}From: ${entry.from}${colors.reset}`);
			if (entry.from_balance !== undefined)
				lines.push(
					`${colors.white}From Balance: ${entry.from_balance}${colors.reset}`
				);
			if (entry.to !== undefined)
				lines.push(`${colors.white}To: ${entry.to}${colors.reset}`);
			if (entry.to_balance !== undefined)
				lines.push(
					`${colors.white}To Balance: ${entry.to_balance}${colors.reset}`
				);
			if (entry.value !== undefined)
				lines.push(`${colors.white}Value: ${entry.value}${colors.reset}`);
			if (entry.caller?.address !== undefined)
				lines.push(
					`${colors.white}Caller Address: ${entry.caller.address}${colors.reset}`
				);
			if (entry.caller?.balance !== undefined)
				lines.push(
					`${colors.white}Caller Balance: ${entry.caller.balance}${colors.reset}`
				);
			if (entry.block_timestamp !== undefined)
				lines.push(
					`${colors.white}Block Timestamp: ${entry.block_timestamp}${colors.reset}`
				);
			if (entry.gas !== undefined)
				lines.push(`${colors.white}Gas: ${entry.gas}${colors.reset}`);
			if (entry.gas_used !== undefined)
				lines.push(`${colors.white}Gas Used: ${entry.gas_used}${colors.reset}`);
			if (entry.intrinsic_gas !== undefined)
				lines.push(
					`${colors.white}Intrinsic Gas: ${entry.intrinsic_gas}${colors.reset}`
				);
			if (entry.storage_address !== undefined)
				lines.push(
					`${colors.white}Storage Address: ${entry.storage_address}${colors.reset}`
				);
			if (entry.input !== undefined)
				lines.push(`${colors.white}Input: ${entry.input}${colors.reset}`);
			if (entry.output !== undefined)
				lines.push(`${colors.white}Output: ${entry.output}${colors.reset}`);
			if (entry.decoded_output?.length)
				lines.push(
					`${colors.white}Decoded Output: ${entry.decoded_output
						.map((out) => out.value)
						.join(", ")}${colors.reset}`
				);
			if (entry.network_id !== undefined)
				lines.push(
					`${colors.white}Network ID: ${entry.network_id}${colors.reset}`
				);
			lines.push(`${colors.gray}${"=".repeat(50)}${colors.reset}`);

			return lines.join("\n");
		})
		.join("\n");

	return formatted;
}

export const convertAnsiToHtml = (ansiString) => {
	const convert = new AnsiToHtml({ fg: "#000", bg: "#FFF" });
	return convert.toHtml(ansiString);
};
