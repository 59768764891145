import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import AnsiToHtml from "ansi-to-html";
import TestTracePanel from "./TestTracePanel";
import { formatTraces } from "utils/tracing/traceHelper";

const TestTrace: React.FC<any> = ({ name, data = {} }) => {
	const { status, reason, traces, duration, decodedLogs } = data;

	const convertAnsiToHtml = (ansiString) => {
		const convert = new AnsiToHtml({ fg: "#000", bg: "#FFF" });
		return convert.toHtml(ansiString);
	};

	const traceList = formatTraces(traces);
	const htmlTraces = traceList?.map((trace) => convertAnsiToHtml(trace));

	return (
		<Stack spacing={2} sx={{ flex: 1, m: 1.5 }}>
			{/* Test Panel */}
			<TestTracePanel name={name} result={status} durationNanos={duration} />

			{!!reason && (
				<Box
					sx={{
						borderRadius: "8px",
						p: 1.5,
						m: 1,
						bgcolor: "background.default"
					}}
				>
					<Typography sx={{ whiteSpace: "pre" }}>{reason}</Typography>
				</Box>
			)}

			{/* Summary of fail (decoded_logs) */}
			{!!decodedLogs?.length && (
				<Box
					sx={{ borderRadius: "8px", p: 1.5, bgcolor: "background.default" }}
				>
					<Typography sx={{ whiteSpace: "pre" }}>
						{decodedLogs.join("\n")}
					</Typography>
				</Box>
			)}

			{!!traceList?.length && (
				<Box
					sx={{
						borderRadius: "8px",
						p: 1.5,
						bgcolor: "background.default"
						// overflowX: "auto"
					}}
				>
					{htmlTraces.map((trace, index) => (
						<Typography
							key={index}
							sx={{
								fontFamily: "Monaco, monospace",
								whiteSpace: "pre-wrap",
								wordBreak: "break-all"
							}}
							dangerouslySetInnerHTML={{ __html: trace }}
						/>
					))}
				</Box>
			)}
		</Stack>
	);
};

export default TestTrace;
