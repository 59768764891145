const getIndent = depth => '    '.repeat(depth);

function toChecksumAddress(address) {
    var keccak256 = require('js-sha3').keccak256;
    address = address.toLowerCase().replace('0x', '');
    var hash = keccak256(address);
    var ret = '0x';

    for (var i = 0; i < address.length; i++) {
        if (parseInt(hash[i], 16) >= 8) {
            ret += address[i].toUpperCase();
        } else {
            ret += address[i];
        }
    }

    return ret;
}

function formatRawParameters(data, inputs) {
    const params = [];
    data = data.slice(10); // Remove function signature
    let offset = 0; // Start offset after the function signature

    inputs.forEach(input => {
        let paramValue;
        const rawValue = data.slice(offset, offset + 64); // Always read 64 characters (32 bytes)

        switch (input.type) {
            case 'string':
                const stringDataOffset = parseInt(rawValue, 16) * 2;
                const stringLength = parseInt(data.slice(stringDataOffset, stringDataOffset + 64), 16) * 2;
                const stringDataStart = stringDataOffset + 64;
                const stringData = data.slice(stringDataStart, stringDataStart + stringLength);
                paramValue = hexToString(stringData);
                break;
            case 'bool':
                // Booleans in Solidity are just 1 byte in a 32 byte word, where 0 is false and anything else is true
                const boolValue = parseInt(rawValue, 16);
                paramValue = boolValue ? 'true' : 'false';
                break;
            case 'address':
                // Addresses are 20 bytes, but passed as 32 bytes with padding zeros at the beginning
                paramValue = '0x' + rawValue.slice(24);
                break;
            case 'uint256':
            case 'int256':
                // Strip unnecessary leading zeros from numbers
                paramValue = BigInt('0x' + rawValue).toString();
                break;
            default:
                // Default to removing unnecessary leading zeros for any unrecognized type
                paramValue = BigInt('0x' + rawValue).toString();
                break;
        }

        params.push(` ${input.name}: ${paramValue}`);
        offset += 64; // Move to the next slot
    });

    return params;
}

function hexToString(hex) {
    var str = '';
    for (var i = 0; i < hex.length; i += 2) {
        var v = parseInt(hex.substr(i, 2), 16);
        if (v) str += String.fromCharCode(v);
    }
    return str;
}

function formatLogEntry(log, knownEvents) {
    const topicsDescription = [];
    const eventName = knownEvents[log.topics[0]] ? knownEvents[log.topics[0]][0].name : 'unknownEvent';
    const eventInputs = knownEvents[log.topics[0]] ? knownEvents[log.topics[0]][0].abi.inputs : [];

    eventInputs.forEach((input, index) => {
        if (input.indexed) {
            topicsDescription.push(`${input.name}: ${log.topics[index + 1]}`);
        }
    });

    const dataDescription = [];
    if (log.data !== '0x' && eventInputs.some(input => !input.indexed)) {
        let dataOffset = 0;
        eventInputs.filter(input => !input.indexed).forEach(input => {
            const rawValue = log.data.slice(2 + dataOffset, 2 + dataOffset + 64);
            let formattedValue;
            switch (input.type) {
                case 'uint256':
                case 'int256':
                    formattedValue = BigInt('0x' + rawValue).toString();
                    break;
                case 'address':
                    formattedValue = '0x' + rawValue.slice(24);
                    break;
                case 'bool':
                    formattedValue = parseInt('0x' + rawValue, 16) ? 'true' : 'false';
                    break;
                // Add more cases as necessary for other types
                default:
                    formattedValue = rawValue; // Default to showing raw hex value
                    break;
            }
            dataDescription.push(`${input.name}: ${formattedValue}`);
            dataOffset += 64;
        });
    }

    return `Emit ${eventName} { ${topicsDescription.concat(dataDescription).join(', ')} }`;
}

function normalizeSignatures(knownItems) {
    const normalized = {};
    Object.keys(knownItems).forEach(key => {
        try {
            const cleanedKey = key.match(/\[\s*((?:\d+\s*,?\s*)+)\s*\]/)[1];
            const jsonArray = `[${cleanedKey.replace(/\s+/g, '').replace(/,$/, '')}]`;
            const bytes = JSON.parse(jsonArray);
            const hexSignature = bytes.map(byte => byte.toString(16).padStart(2, '0')).join('');
            normalized[`0x${hexSignature}`] = knownItems[key];
        } catch (e) {
            console.error(`Error parsing key ${key}:`, e);
        }
    });
    return normalized;
}

// Update formatCallTraceNode function to use formatRawOutputs
const formatCallTraceNode = (node, knownAddresses, knownFunctions, traces, colors, outputArray, traceKind, isFirstNode, processedNodes, known_events) => {
    const { depth, caller, address, kind, gas_used, output, data } = node.trace;
    const indent = getIndent(depth);

    const calleeLabel = knownAddresses[toChecksumAddress(address)] || address;
    const dataSignature = data.toString().slice(0, 10);
    const functionEntry = knownFunctions[dataSignature];
    const functionName = functionEntry ? functionEntry[0].name : 'unknownFunction';
    const functionParams = functionEntry ? formatRawParameters(data, functionEntry[0].abi.inputs) : [];
    const formattedOutputs = functionEntry && output !== '0x' ? formatRawOutputs(output, functionEntry[0].abi.outputs) : [];

    const nodeKey = `${address}-${gas_used}-${depth}`;
    if (processedNodes.has(nodeKey)) return;
    processedNodes.add(nodeKey);

    if (isFirstNode) {
        outputArray.push(`${indent}${colors.magenta}Trace Kind: ${traceKind}${colors.reset}`);
    }

    if (kind.toLowerCase() === 'create' || kind.toLowerCase() === 'create2') {
        outputArray.push(`${indent}[${gas_used}] ${colors.yellow}→ ${colors.reset}${colors.yellow}new${colors.reset} ${calleeLabel}@${address}`);
        outputArray.push(`${indent}${getIndent(1)}└─ ${colors.green}← ${colors.reset}${output.length} bytes of code`);
    } else {
        outputArray.push(`${indent}[${gas_used}] ${colors.green}${calleeLabel}${colors.reset}::${colors.green}${functionName}${colors.reset}(${functionParams.join(', ')})`);
        if (formattedOutputs.length > 0) {
            formattedOutputs.forEach(output => {
                outputArray.push(`${indent}${getIndent(1)}└─ ${colors.cyan}Output: ${output}${colors.reset}`);
            });
        }
    }

    const normalizedEvents = normalizeSignatures(known_events);

    node.logs.forEach(log => {
        const logEntry = formatLogEntry(log, normalizedEvents);
        outputArray.push(`${indent}${getIndent(1)}${colors.grey}${logEntry}${colors.reset}`);
    });
    

    node.children.forEach(childIdx => {
        formatCallTraceNode(traces.arena[childIdx], knownAddresses, knownFunctions, traces, colors, outputArray, traceKind, false, processedNodes, known_events);
    });
};

function formatRawOutputs(output, outputs) {
    if (output === '0x' || outputs.length === 0) return [];

    const result = [];
    let data = output.slice(2); // Remove '0x' prefix from the entire output string
    let offset = 0; // Start offset

    outputs.forEach(output => {
        let outputValue;
        const rawValue = data.slice(offset, offset + 64); // Read 64 characters (32 bytes)

        switch (output.type) {
            case 'string':
                const stringDataOffset = parseInt('0x' + rawValue, 16) * 2;
                const stringLength = parseInt(data.slice(stringDataOffset, stringDataOffset + 64), 16) * 2;
                const stringDataStart = stringDataOffset + 64;
                const stringData = data.slice(stringDataStart, stringDataStart + stringLength);
                outputValue = hexToString(stringData);
                break;
            case 'bool':
                const boolValue = parseInt('0x' + rawValue, 16);
                outputValue = boolValue ? 'true' : 'false';
                break;
            case 'address':
                outputValue = '0x' + rawValue.slice(24); // Addresses are 20 bytes, but passed as 32 bytes with padding zeros at the beginning
                break;
            case 'uint256':
            case 'int256':
                outputValue = BigInt('0x' + rawValue).toString(); // Strip unnecessary leading zeros from numbers
                break;
            default:
                outputValue = BigInt('0x' + rawValue).toString(); // Default to removing unnecessary leading zeros for any unrecognized type
                break;
        }

        result.push(`${output.name ? output.name + ': ' : ''}${outputValue}`);
        offset += 64; // Move to the next slot
    });

    return result;
}

export const formatTraces = identifiedTraces => {
    const { known_addresses, known_functions, traces, known_events } = identifiedTraces;
    const normalizedFunctions = normalizeSignatures(known_functions);
    const colors = {
        yellow: '\u001b[33m',
        green: '\u001b[32m',
        cyan: '\u001b[36m',
        magenta: '\u001b[35m',
        grey: '\u001b[90m',
        reset: '\u001b[0m'
    };
    let outputArray = [];
    let processedNodes = new Set();

    traces.forEach(trace => {
        const traceKind = trace.trace_kind;
        trace.traces.arena.forEach((node, index) => {
            const isFirstNode = index === 0;
            formatCallTraceNode(node, known_addresses, normalizedFunctions, trace.traces, colors, outputArray, traceKind, isFirstNode, processedNodes, known_events);
        });
    });

    return outputArray;
};
