import { Monaco } from "@monaco-editor/react";
import { IScrollEvent, editor } from "monaco-editor";

export const createCustomTheme = (
	monaco: Monaco,
	monacoThemeColors: editor.IColors = {},
	mode: "light" | "dark" = "dark"
) => {
	monaco.editor.defineTheme("aw-theme", {
		base: mode === "dark" ? "vs-dark" : "vs",
		inherit: true,
		rules: [],
		colors: {
			...monacoThemeColors
		}
	});
};

export const listenEditorSelection = (
	editor: editor.IStandaloneCodeEditor,
	callback: any
) => {
	return editor?.onDidChangeCursorSelection?.(() => {
		const selection = editor.getSelection();
		if (selection) {
			const selection = editor?.getSelection?.();
			const selectedText = editor?.getModel?.().getValueInRange(selection);

			callback({
				startLineNumber: selection.startLineNumber,
				endLineNumber: selection.endLineNumber,
				startColumn: selection.startColumn,
				endColumn: selection.endColumn,
				selectedText
			});
		}
	});
};

export const listenClickLocation = (
	editor: editor.IStandaloneCodeEditor,
	callback: any
) => {
	return editor?.onMouseDown?.((event: editor.IEditorMouseEvent) => {
		const position = event.target.position!;
		const clickType = event.target?.type;

		if (clickType && position) {
			const model = editor.getModel()!;
			const word = model?.getWordAtPosition?.(position) || {};
			const offset = model.getOffsetAt(position);

			callback?.({
				...word,
				...position,
				clickType,
				offset,
				timestamp: event?.event.timestamp,
				event
			});
			// setTimeout
		}
	});
};

export const listenScrollPosition = (
	editor: editor.IStandaloneCodeEditor,
	callback: any
) => {
	return editor.onDidScrollChange((e: IScrollEvent) => {
		// Scroll from top
		const scrollTop = e.scrollTop;

		// Line number range
		const visibleRanges = editor.getVisibleRanges();

		callback?.({ scrollTop, visibleRanges });
	});
};

export const listenHoverLocation = (
	editor: editor.IStandaloneCodeEditor,
	callback: any
) => {
	return editor.onMouseMove((e: any) => {
		// Scroll from top
		const _event = {
			relativePos: e.event.relativePos,
			editorPos: e.event.editorPos,
			posX: e.event.posx,
			posY: e.event.posy
		};

		callback?.({ event: _event, target: e.target });
	});
};

export const addMonacoAction = (
	editor: editor.IStandaloneCodeEditor,
	monaco: Monaco,
	callback: any,
	overrides: any = {}
) => {
	const found = editor.getAction(overrides.id);

	if (!found)
		return editor.addAction({
			id: "add-button",
			label: "New Action",
			keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyB],
			contextMenuGroupId: "1",
			contextMenuOrder: 1,
			run: () => callback(editor, monaco),
			...overrides
		});
};

export const editorDidMount = (
	editor: editor.IStandaloneCodeEditor,
	monaco: Monaco
) => {
	// Fold/expand code
	addMonacoAction(editor, monaco, () => foldCode(editor), {
		id: "fold-code-button",
		label: "Fold All Code",
		keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyL],
		contextMenuOrder: 2,
		contextMenuGroupId: "2"
	});

	addMonacoAction(editor, monaco, () => expandCode(editor), {
		id: "expand-code-button",
		label: "Expand All Code",
		keybindings: [
			monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyL
		],
		contextMenuOrder: 3,
		contextMenuGroupId: "2"
	});

	// Cursor undo/redo
	editor.addCommand(monaco.KeyMod.Alt | monaco.KeyCode.Comma, () => {
		editor.trigger("", "cursorUndo", null);
	});

	editor.addCommand(monaco.KeyMod.Alt | monaco.KeyCode.Period, () => {
		editor.trigger("", "cursorRedo", null);
	});
};

export const goToEditorLine = (
	editor: editor.IStandaloneCodeEditor,
	startLineNumber: number,
	endLineNumber: number | null = null,
	useScrollTop: boolean = false,
	blinkAnimation: boolean = true
) => {
	if (useScrollTop) {
		editor.setScrollPosition({
			scrollTop: startLineNumber
		});
	} else {
		editor.revealLineNearTop(startLineNumber);
	}

	if (blinkAnimation) {
		triggerBlinkAnimation(
			editor,
			startLineNumber,
			endLineNumber || startLineNumber
		);
	}
};

export const triggerBlinkAnimation = (
	editor: editor.IStandaloneCodeEditor,
	startLineNumber: number,
	endLineNumber: number
) => {
	const res = editor.createDecorationsCollection([
		{
			range: {
				startLineNumber,
				endLineNumber,
				startColumn: 0,
				endColumn: 100
			},
			options: {
				isWholeLine: true,
				className: "editor-blink-lines",
				minimap: {
					color: "#FFFFFF80",
					darkColor: "#FFFFFF80",
					position: 1
				}
			}
		}
	]);

	setTimeout(() => {
		res.clear();
	}, 2000);
};

export const removeEventListener = (event) => {
	event?.dispose?.();
};

export const expandCode = (editor: editor.IStandaloneCodeEditor) => {
	if (editor) {
		editor?.getAction?.("editor.unfoldAll")?.run();
	}
};

export const foldCode = (editor: editor.IStandaloneCodeEditor) => {
	if (editor) {
		editor?.getAction?.("editor.foldAll")?.run();
	}
};

export const zoomIn = (editor: editor.IStandaloneCodeEditor) => {
	if (editor) {
		editor.trigger("keyboard", "editor.action.fontZoomIn", {});
	}
};

export const zoomOut = (editor: editor.IStandaloneCodeEditor) => {
	if (editor) {
		editor.trigger("keyboard", "editor.action.fontZoomOut", {});
	}
};

export const removePlaceholderOnChange = (value: string | undefined) => {
	// if there is a value, hide the placeholder...
	// else show it
	let placeholder = document.querySelector(
		".monaco-placeholder"
	) as HTMLElement | null;
	if (!value) {
		placeholder!.style.display = "block";
	} else {
		placeholder!.style.display = "none";
	}
};

export const addPlaceholderOnMount = () => {
	// show placeholder when mounted
	let placeholder = document.querySelector(
		".monaco-placeholder"
	) as HTMLElement | null;
	placeholder!.style!.display = "block";
};
