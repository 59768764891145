import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { addSuccessNotification } from "Resources/Notifications";
import { Template } from "Resources/ServerInterfaceTemplateCollection";
import { ToolboxCloseButton } from "components/Toolbox";
import TestTemplateForm from "components/forms/TestTemplateForm";
import { useTestTemplate } from "hooks/data/useTestTemplate";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { testTemplateToSaveAtom } from "store/atoms/testAtoms";

const TemplateFormTool: React.FC<{ backToTool: any }> = ({ backToTool }) => {
	const [testTemplateToSave] = useAtom(testTemplateToSaveAtom);
	const { addNewTemplate } = useTestTemplate();
	const [disabled, setDisabled] = useState(false);

	const handleSubmit = async (data) => {
		try {
			const collectionId: string = data.collection;
			const templateName: string = data.name;
			const template: Template = {
				name: templateName,
				contents: testTemplateToSave,
				description: ""
			};
			setDisabled(true);
			await addNewTemplate(collectionId, template);

			addSuccessNotification("Success", "Template Created!", 1000);

			setDisabled(false);
			setTimeout(() => {
				backToTool();
			}, 500);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ mb: 2 }}
			>
				<IconButton onClick={backToTool}>
					<ArrowBackIcon fontSize="small" />
				</IconButton>

				<Typography>Back to templates</Typography>

				<Box sx={{ flex: 1 }} />

				<ToolboxCloseButton />
			</Stack>

			<TestTemplateForm disabled={disabled} handleSubmit={handleSubmit} />
		</>
	);
};

export default TemplateFormTool;
