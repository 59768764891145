import AddIcon from "@mui/icons-material/Add";
import {
	Box,
	Button,
	CircularProgress,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import { findFilePath } from "Resources/Helpers";
import { useEditorTabs } from "hooks/ui/useEditorTabs";
import { useAtom, useSetAtom } from "jotai";
import React, { useMemo } from "react";
import { projectAtom } from "store/atoms/projectToolAtoms";
import {
	testCompilerErrorAtom,
	testFilesAtom,
	testsInitializingAtom,
	TestTabs,
	testTabToggleValueAtom
} from "store/atoms/testAtoms";
import { SlitherErrorItem } from "../CodeScanTool/ScanToolItem/ImplementedScanTool";
import TestItem from "./TestItem";

const TestTab = () => {
	const theme = useTheme();

	const { findAndOpenTab, openCustomTab } = useEditorTabs();

	const [project] = useAtom(projectAtom);
	const [testFiles] = useAtom(testFilesAtom);
	const [testsInitializing] = useAtom(testsInitializingAtom);
	const [testCompilerError] = useAtom(testCompilerErrorAtom);
	const setTestTabToggleValue = useSetAtom(testTabToggleValueAtom);

	const projectTests = useMemo(() => {
		return testFiles.filter((tf) => !!tf.originFile);
	}, [testFiles]);

	const userTests = useMemo(() => {
		return testFiles.filter((tf) => !tf.originFile);
	}, [testFiles]);

	return (
		<Stack>
			{testsInitializing && (
				<Box sx={{ textAlign: "center", mt: 1 }}>
					<CircularProgress color="inherit" />
					<Typography style={{ color: theme.palette.text.primary }}>
						Preparing tests...
					</Typography>
				</Box>
			)}

			{!testsInitializing && (
				<>
					<Button
						variant="contained"
						sx={{ borderRadius: "8px", boxShadow: 1, mb: 2 }}
						onClick={() => {
							const tabName = "New Test";
							const tabPath = "/custom-test";

							findAndOpenTab(tabPath, () => {
								openCustomTab(null, tabName, tabPath);
								setTestTabToggleValue(TestTabs.Builder);
							});
						}}
					>
						<AddIcon sx={{ mr: 0.5 }} /> Add new test
					</Button>

					{testCompilerError && (
						<SlitherErrorItem
							title={"Error"}
							ansiText={testCompilerError}
							description=""
							version={[]}
							sx={{ mb: 1 }}
							defaultCollapsed={false}
						/>
					)}

					{/* User Tests */}
					{!!userTests.length && (
						<>
							<Typography sx={{ mt: 1, mb: 1, fontWeight: 600 }}>
								User Tests
							</Typography>
							<Stack spacing={2}>
								{userTests.map((result, i) => {
									if (result.originFile) {
										const originFilePath = findFilePath(
											project,
											result.originFile
										);
										result.originFilePath = originFilePath;
									}

									return (
										<TestItem
											key={i}
											result={result}
											testCompilerError={!!testCompilerError}
										/>
									);
								})}
							</Stack>
						</>
					)}

					{/* Project Tests */}
					{!!projectTests.length && (
						<>
							<Typography sx={{ mt: 2, mb: 1, fontWeight: 600 }}>
								Project Tests
							</Typography>
							<Stack spacing={2}>
								{projectTests.map((result, i) => {
									if (result.originFile) {
										const originFilePath = findFilePath(
											project,
											result.originFile
										);
										result.originFilePath = originFilePath;
									}

									return (
										<TestItem
											key={i}
											result={result}
											testCompilerError={!!testCompilerError}
										/>
									);
								})}
							</Stack>
						</>
					)}
				</>
			)}
		</Stack>
	);
};

export default React.memo(TestTab);
