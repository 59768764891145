import { Container, Stack } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { projectAtom } from "store/atoms/projectToolAtoms";
import { STORAGE_HATS_SUBMISSION } from "utils/constants";
import {
	getObjectFromLocalStorage,
	updateObjectInLocalStorage
} from "utils/helpersFunctions";
import { riskLevelFromText } from "../Tools/AddFindingsTool/helpers";
import SubmissionsEditor, { SubmissionItemValues } from "./SubmissionsEditor";
import SubmissionsToolbar from "./SubmissionsToolbar";

const Submissions = () => {
	const [project] = useAtom(projectAtom);

	const [submissionItem, setSubmissionItem] = useState<SubmissionItemValues>({
		walletAddress: "",
		email: "Loading...",
		issueTitle:
			getObjectFromLocalStorage(`${STORAGE_HATS_SUBMISSION}_${project.id}`)[
				"issueTitle"
			] ?? "",
		issueDescription:
			getObjectFromLocalStorage(`${STORAGE_HATS_SUBMISSION}_${project.id}`)[
				"issueDescription"
			] ?? "",
		issueSeverity:
			getObjectFromLocalStorage(`${STORAGE_HATS_SUBMISSION}_${project.id}`)[
				"issueSeverity"
			] ?? riskLevelFromText("Low")
	});
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true);
	}, []);

	useEffect(() => {
		if (!mounted) {
			return;
		}
		updateObjectInLocalStorage(`${STORAGE_HATS_SUBMISSION}_${project.id}`, {
			issueTitle: submissionItem.issueTitle,
			issueDescription: submissionItem.issueDescription,
			issueSeverity: submissionItem.issueSeverity
		});
	}, [
		submissionItem.issueTitle,
		submissionItem.issueDescription,
		submissionItem.issueSeverity
	]);

	return (
		<Container sx={{ height: "100%", display: "flex" }}>
			<Stack spacing={2} sx={{ minHeight: 0, width: "100%" }}>
				<SubmissionsToolbar submissionItem={submissionItem} />
				<SubmissionsEditor
					submissionItem={submissionItem}
					setSubmissionItem={setSubmissionItem}
				/>
			</Stack>
		</Container>
	);
};

export default Submissions;
