import { Box, Stack, TextField, useTheme } from "@mui/material";
import DarkBg from "assets/images/backgrounds/functionExplorerBgDark.png";
import { useAtom } from "jotai";
import React, { useMemo, useState } from "react";
import { getBreakdownFromSolidity } from "Resources/SolidityParser";
import { openedTabAtom } from "store/atoms/UiAtoms";
import hex from "utils/hexTransparency";
import FilterButton from "./FilterButton";
import FunctionItem from "./FunctionItem";
import { computeFilters } from "./helpers";

export interface FilterType {
	name: string;
}

const FunctionExplorer = () => {
	const theme = useTheme();

	const [{ openedFile }] = useAtom(openedTabAtom);

	const [searchText, setSearchText] = useState("");
	const [selectedVisibilities, setVisibilities] = useState<FilterType[]>([]);
	const [selectedTypes, setTypes] = useState<FilterType[]>([]);
	const [selectedMutabilities, setMutabilities] = useState<FilterType[]>([]);

	const functions = useMemo(() => {
		if (!!openedFile?.text) {
			return getBreakdownFromSolidity(openedFile?.ast, openedFile?.text);
		}

		return [];
	}, [openedFile?.text]);

	const filteredFunctions = useMemo(() => {
		return computeFilters(
			functions,
			searchText,
			selectedVisibilities,
			selectedTypes,
			selectedMutabilities
		);
	}, [
		functions?.length,
		searchText,
		selectedVisibilities,
		selectedTypes,
		selectedMutabilities
	]);

	return (
		<Box
			sx={{
				p: 2,
				background: `url(${DarkBg})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				overflow: "hidden",
				height: "100%"
			}}
		>
			{/* Toolbar */}
			<Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap">
				{/* Search */}
				<TextField
					value={searchText}
					onChange={(e) => setSearchText(e.target.value)}
					variant="outlined"
					size="small"
					placeholder="Search"
					InputProps={{
						sx: {
							width: { xs: 200, md: 300 },
							borderRadius: "10px",
							bgcolor: `${theme.palette.background["nav"]}${hex["70%"]}`
						}
					}}
				/>

				<Box />

				<FilterButton
					title="TYPE"
					filters={[
						{ name: "FunctionDefinition" },
						{ name: "VariableDeclaration" },
						{ name: "StructDefinition" },
						{ name: "EnumDefinition" },
						{ name: "EventDefinition" }
					]}
					selected={selectedTypes}
					setSelected={setTypes}
				/>

				<FilterButton
					title="VISIBILITY"
					filters={[
						{ name: "public" },
						{ name: "external" },
						{ name: "internal" },
						{ name: "private" }
					]}
					selected={selectedVisibilities}
					setSelected={setVisibilities}
				/>

				<FilterButton
					title="MUTABILITY"
					filters={[{ name: "view" }, { name: "pure" }, { name: "payable" }]}
					selected={selectedMutabilities}
					setSelected={setMutabilities}
				/>
			</Stack>

			<Stack
				spacing={2}
				sx={{
					height: "100%",
					overflow: "auto",
					mt: 2,
					pb: 7
				}}
			>
				{filteredFunctions.map((item, i) => (
					<FunctionItem key={`${item.name}-${item.type}-${i}`} {...item} />
				))}
			</Stack>
		</Box>
	);
};

export default React.memo(FunctionExplorer);
