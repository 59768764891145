import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
	Box,
	Button,
	IconButton,
	Stack,
	TextField,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import { useTests } from "hooks/data/useTests";
import { useTools } from "hooks/ui/useTools";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
	pocAIGeneratingAtom,
	testPanelNameAtom,
	testRunningAtom
} from "store/atoms/testAtoms";

const TestPanel: React.FC<{
	testName?: string;
	onSaveClick?: any;
	prepareTestAsTemplate?: any;
	onTemplateSelect?: any;
	onTemplateHoverValue?: any;
	existingFile?: boolean;
	contentToCopy?: string;
	testId?: string;
	loading?: boolean;
}> = ({
	testName = "",
	onSaveClick,
	prepareTestAsTemplate,
	existingFile,
	contentToCopy,
	onTemplateSelect,
	onTemplateHoverValue,
	testId,
	loading
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [nameAtom] = useAtom(testPanelNameAtom);

	const [isGenerating, setIsgenerating] = useState(false);
	const [pocAIGenerating] = useAtom(pocAIGeneratingAtom);

	const [error, setError] = useState(false);
	const [name, setName] = useState(
		nameAtom || testName.slice(0, testName.lastIndexOf(".t.sol"))
	);

	const [copied, setCopied] = useState(false);
	const [testRunning] = useAtom(testRunningAtom);

	const { selectTool } = useTools();
	const { runAllTests } = useTests();

	function isValidFileName(fileName: string): boolean {
		// Regular expression to match invalid file name characters and whitespace
		const invalidCharsOrWhitespace = /[\/:*?"<>|\s]/;

		// Check if the file name contains any invalid characters or whitespace
		return fileName.length > 0 && !invalidCharsOrWhitespace.test(fileName);
	}

	const handleInputChange = (value: string) => {
		setName(value);

		if (error) setError(!isValidFileName(value));
	};

	const handleSaveClick = () => {
		if (!isValidFileName(name)) {
			setError(true);
		} else {
			onSaveClick(name + ".t.sol");
		}
	};

	useEffect(() => {
		if (!isGenerating && pocAIGenerating && !testId) {
			setIsgenerating(true);
		}
		if (isGenerating && !pocAIGenerating) {
			handleSaveClick();
			setTimeout(() => {
				setIsgenerating(false);
			}, 2000);
		}
	}, [isGenerating, testId, pocAIGenerating]);

	useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		}
	}, [copied]);

	useEffect(() => {
		if (nameAtom !== "" && !testId) {
			setName(nameAtom);
		}
	}, [nameAtom, testId]);

	const disableAll = (!!pocAIGenerating && !testId && isGenerating) || loading;

	return (
		<>
			<Stack
				spacing={1}
				direction="row"
				alignItems="center"
				sx={{ borderRadius: "8px", m: 1.5, p: 1, bgcolor: "background.nav" }}
			>
				<TextField
					disabled={!!existingFile || disableAll}
					value={name}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							handleSaveClick();
							e.preventDefault();
						}
					}}
					onChange={(e) => !existingFile && handleInputChange(e.target.value)}
					variant="outlined"
					placeholder="myTest"
					error={error}
					label={error ? "Invalid file name" : ""}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						sx: { height: 34, borderRadius: "8px" }
					}}
				/>
				<Typography color={theme.palette.text.disabled}>.t.sol</Typography>

				<Box sx={{ flex: 1 }} />

				{/* Template */}
				{!existingFile && (
					<Button
						size="small"
						variant="outlined"
						disabled={disableAll}
						sx={{
							borderRadius: "8px",
							height: "100%",
							color: "inherit",
							border: `1px solid ${theme.palette.divider}`
						}}
						onClick={() => {
							selectTool("Tests", { mode: null });

							prepareTestAsTemplate();

							return setTimeout(() => {
								selectTool("Tests", { mode: "templateForm" });
							}, 100);
						}}
					>
						<Stack direction="row" alignItems="center" spacing={0.5}>
							<AutoAwesomeMosaicIcon fontSize="small" />

							<Typography>Create template</Typography>
						</Stack>
					</Button>
				)}

				{typeof contentToCopy === "string" && (
					<CopyToClipboard text={contentToCopy} onCopy={() => setCopied(true)}>
						<Tooltip title={copied ? "Copied" : "Copy to clipboard"}>
							<IconButton
								size="small"
								disabled={!contentToCopy?.length || disableAll}
								sx={{
									height: "100%",
									borderRadius: "8px",
									border: `1px solid ${theme.palette.divider}`
								}}
							>
								<ContentCopyIcon
									sx={{
										color: copied ? "text.secondary" : "inherit",
										fontSize: 18
									}}
								/>
							</IconButton>
						</Tooltip>
					</CopyToClipboard>
				)}

				{!existingFile && (
					<IconButton
						disabled={disableAll}
						size="small"
						onClick={handleSaveClick}
						sx={{
							height: "100%",
							borderRadius: "8px",
							border: `1px solid ${theme.palette.divider}`
						}}
					>
						<SaveIcon fontSize="small" />
					</IconButton>
				)}

				{testId && (
					<LoadingButton
						variant="contained"
						loading={testRunning}
						onClick={() => runAllTests(testId)}
						sx={{ borderRadius: "8px", minWidth: 0, pl: 1 }}
					>
						<Stack direction="row" alignItems="center">
							<PlayArrowIcon />
							<Typography>Run All Tests</Typography>
						</Stack>
					</LoadingButton>
				)}
			</Stack>
		</>
	);
};

export default React.memo(TestPanel);
