import { getGithubInstallationId } from "Resources/ServerInterface";
import { githubInstallationIdAtom, githubLoginCodeAtom } from "atoms";
import SplashScreen from "components/SplashScreen";
import useSetAtom from "hooks/useSetAtom";
import { useMemo, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

const GithubRedirect = () => {
	const location = useLocation();
	const [redirect, setRedirect] = useState(false);
	const setGithubLoginCode = useSetAtom(githubLoginCodeAtom);
	const setInstallationId = useSetAtom(githubInstallationIdAtom);

	useMemo(() => {
		const query = new URLSearchParams(location.search);
		const code = query.get("code");

		if (code && !localStorage.getItem("linkingGithub")) {
			setGithubLoginCode(code);
		} else if (code && !!localStorage.getItem("linkingGithub")) {
			getGithubInstallationId(code).then((installationId: string) => {
				localStorage.removeItem("linkingGithub");
				setInstallationId(installationId);
				setRedirect(true);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (redirect) {
		return <Navigate to="/settings" />;
	}

	if (!!localStorage.getItem("linkingGithub")) {
		return <SplashScreen />;;
	}

	return <Navigate to="/" />;
};

export default GithubRedirect;
