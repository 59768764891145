import { usernameAtom } from "atoms";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

const OpenChallengeRedirect = () => {
	const [username] = useAtom(usernameAtom);

	useEffect(() => {
		localStorage.setItem("challengeTabDefault", "true");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [username]);

	return <Navigate to="/" />;
};

export default OpenChallengeRedirect;
