import {
	ContractDefinition,
	FunctionDefinition,
	Identifier,
	ImportDirective,
	ModifierDefinition,
	parse,
	visit
} from "solidity-parser-antlr";
import Project from "./Project";
import ProjectFile from "./ProjectFile";
import { flattenFiles } from "../utils/helpersFunctions";

function resolveImport(project: Project, root: ProjectFile, path: string) {
	const paths = [];

	// Apply remappings to path

	// Add immediate path

	// Add recursive upward paths

	// resolve local paths

	// recurse to resolve local paths with ../ root

	// else, look through remappings to find where to locate the remote import
}

function applyRemappings(project: Project, path: string) {
	let remappedPath = path;
	project.remappings.forEach((remapping) => {
		if (path.startsWith(remapping.source)) {
		}
	});
	return path;
}

export class ControlFlowGraph {
	contracts: CfgContract[];
	functions: CfgFunction[];
	functionCalls: Map<CfgFunction, CfgFunction[]> = new Map();
	project: Project;

	constructor(project: Project) {
		this.project = project;
		this.contracts = [];
		this.functions = [];
	}

	public async generate() {
		let currentContract: CfgContract = null;
		let currentFunction: CfgFunction = null;
		const contracts: CfgContract[] = [];
		const functions: CfgFunction[] = [];
		const functionCallDestinations: Map<CfgFunction, string[]> = new Map();
		const projectFileIds: string[] = flattenFiles(this.project.rootFolder).map(
			(projectFile: ProjectFile) => projectFile.id
		);
		await new Promise((resolve) => setTimeout(resolve, 500));
		const fileASTs = await ProjectFile.getASTForMany(projectFileIds);

		flattenFiles(this.project.rootFolder).forEach((projectFile) => {
			const localContracts = [];
			const remoteImports = [];
			visit(fileASTs.get(projectFile.id), {
				ImportDirective: function (node) {
					remoteImports.push(node);
				},
				ContractDefinition: function (node) {
					const cd = new CfgContract(node);
					//TODO: investigate how abstract contracts affect this. Are they caught in this function?
					this.currentContract = cd;
					localContracts.push(cd);
					contracts.push(cd);
				},
				FunctionDefinition: function (node) {
					// Check if the function's visibility is either 'public' or 'external'
					if (node.visibility === "public" || node.visibility === "external") {
						const fd = new CfgFunction(node, this.currentContract);
						this.currentFunction = fd;
						functions.push(fd);
					}
				},
				/*ModifierDefinition: function (node) {
					const fd = new CfgFunction(node, currentContract);
					currentFunction = fd;
					// currentContract.functions.push(fd);
					functions.push(fd);
				},*/
				ExpressionStatement: function (node) {
					if (node.expression.type === "FunctionCall") {
						if (!functionCallDestinations.has(currentFunction))
							functionCallDestinations.set(currentFunction, []);

						functionCallDestinations
							.get(currentFunction)
							.push((node.expression.expression as Identifier).name);
					}
				},
				VariableDeclaration: function (node) {
					//TODO: save a mapping of variables to locations
				},
				Identifier: function (node) {
					//TODO: save a mapping of identifiers to locations?
				}
			});

			// Add the local contract references to each contract object
			localContracts.forEach((localContract, index) => {
				const localContractsMinusThis = [
					...localContracts.slice(0, index),
					...localContracts.slice(index + 1)
				];
				localContract.siblings = localContractsMinusThis;

				//TODO: expand siblings to remote imports?

				localContract.remoteImports = remoteImports;
			});
		});

		// Resolve the remote import contract references to each contract object
		//TODO: resolve contract parents

		// Resolve the function call destinations
		// functionCallDestinations.forEach((destinations, source) => {
		// 	this.functionCalls.set(source, []);

		// 	destinations.forEach(destination => {
		// 		// Resolve function calls by searching the contract and its parents for the function
		// 		const contractsToSearch = [source.parentContract];//, ...source.parentContract.getParents(this.contracts)];
		// 		//TODO: search the parent contract and inherited contracts in order, until the callee function is found

		// 		contractsToSearch.forEach(contract => {
		// 			contract.functions.forEach(cf => {
		// 				if (cf.node.name === destination)
		// 					this.functionCalls.get(source).push(cf);
		// 			});
		// 		});
		// 	});
		// })

		this.contracts = contracts;
		this.functions = functions;
	}

	public getFunctions() {
		return this.functions.map((f) => {
			const func = f.node as FunctionDefinition;
			const modifiers = func.modifiers
				? func.modifiers.map((mod) => mod.name)
				: [];

			return {
				name: func.name,
				code: func.body,
				visibility: func.visibility,
				mutability: func.stateMutability,
				modifiers,
				isConstructor: func.isConstructor ?? false,
				location: func.loc,
				parameters: func.parameters,
				returnParameters: func.returnParameters,
				parentContract: f.parentContract
			};
		});
	}
}

export class CfgContract {
	node: ContractDefinition;
	functions: CfgFunction[];
	siblings: CfgContract[];
	parents: ContractDefinition[];
	remoteImports: ImportDirective[];

	constructor(node) {
		this.node = node;
	}

	getParents(contracts: CfgContract[]): CfgContract[] {
		//TODO: finish implementation
		return this.node.baseContracts.map<CfgContract>((inheritanceSpecifier) => {
			const parentContractName = inheritanceSpecifier.baseName.namePath;

			// Search siblings
			this.siblings.find((sibling) => {
				return sibling.functions.find(
					(func) => func.node.name === parentContractName
				);
			});

			// Search remoteImports

			return null;
		});
	}
}

export class CfgFunction {
	node: FunctionDefinition | ModifierDefinition;
	parentContract: CfgContract;

	constructor(node, parentContract) {
		this.node = node;
		this.parentContract = parentContract;
	}
}

export class CfgFunctionCall {
	from: CfgFunction;
	to: CfgFunction;
	lineNumber: number;

	constructor(from, to, lineNumber) {
		this.from = from;
		this.to = to;
		this.lineNumber = lineNumber;
	}
}

//TODO: replace this with a pre-computed graph call
export function getFunctionsFromSolidity(solidityText: string) {
	const functions = [];

	if (!solidityText) return functions;

	try {
		const ast = parse(solidityText, { range: true });

		visit(ast, {
			FunctionDefinition: function (func) {
				// Skip virtual/unimplemented functions
				if (func.body.statements.length > 0)
					functions.push({
						name: func.name,
						code: func.body,
						visibility: func.visibility,
						mutability: func.stateMutability,
						modifiers: func.modifiers.map((mod) => mod.name),
						isConstructor: func.isConstructor,
						location: func.loc,
						range: func.range,
						parameters: func.parameters,
						returnParameters: func.returnParameters
					});
			}
		});
	} catch (error) {
		// console.log("ERROR PARSING AST", error);
	} finally {
		return functions.filter((i) => !!i.name);
	}
}

export function getBreakdownFromSolidity(ast: any, contract: string) {
	const nodes = [];

	if (!contract) return nodes;

	const contractArr = contract.split("\n");

	const getCode = (start: number, end: number) => {
		let functionCode = contractArr.slice(start - 1, end + 1);

		// Add the } from the last line
		if (start === end) {
			functionCode = contractArr.slice(start - 1, end);
		}

		return functionCode.join("\n").trim();
	};

	try {
		visit(ast, {
			VariableDeclaration: (node) => {
				if (!!node.visibility) {
					nodes.push({
						...node,
						codeString: getCode(node.loc.start.line, node.loc.end.line)
					});
				}
			},
			FunctionDefinition: function (node) {
				nodes.push({
					...node,
					codeString: getCode(node.loc.start.line, node.loc.end.line)
				});
			},
			StructDefinition: (node) => {
				nodes.push({
					...node,
					codeString: getCode(node.loc.start.line, node.loc.end.line)
				});
			},
			EnumDefinition: (node) => {
				nodes.push({
					...node,
					codeString: getCode(node.loc.start.line, node.loc.end.line)
				});
			},
			EventDefinition: (node) => {
				nodes.push({
					...node,
					codeString: getCode(node.loc.start.line, node.loc.end.line)
				});
			}
		});
	} catch (error) {
		console.log("ERROR PARSING AST getBreakdownFromSolidity", error);
	} finally {
		return nodes.filter(Boolean);
	}
}
