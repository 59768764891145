import { yupResolver } from "@hookform/resolvers/yup";
import SettingsIcon from "@mui/icons-material/Settings";
import { LoadingButton } from "@mui/lab";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Divider,
	IconButton,
	InputAdornment,
	ListSubheader,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import {
	isCode4Url,
	isEtherscanUrl,
	isGitHubUrl,
	isSherlockUrl
} from "Resources/Helpers";
import ArbitrumIcon from "assets/icons/arbitrumIcon.svg";
import AvalancheIcon from "assets/icons/avalancheIcon.svg";
import BaseIcon from "assets/icons/baseIcon.png";
import EthIconDark from "assets/icons/ethIconDark.png";
import EthIconLight from "assets/icons/ethIconLight.png";
import OptimismIcon from "assets/icons/optimismIcon.svg";
import BearachainIcon from "assets/icons/bearachainIcon.svg";
import PolygonIcon from "assets/icons/polygonIcon.svg";
import SeiIcon from "assets/icons/seiLogo.svg";
import { usernameAtom } from "atoms";
import ProjectPrivacyBox from "components/ProjectPrivacyBox";
import { useAtom, useSetAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { FieldValues, Resolver, useForm } from "react-hook-form";
import { loginModalOpenAtom } from "store/atoms/UiAtoms";
import * as Yup from "yup";
import FormTextField from "../../formFields/FormTextField";
import hex from "utils/hexTransparency";

export interface ImportProjectFormType {
	link: string;
	privacyType?: string;
}

const addressRegexp = /^0x[a-fA-F0-9]{40}$/;

interface Props {
	handleSubmit: (data: ImportProjectFormType, network: string) => void;
	setPrivateMode: (privateMode: boolean) => void;
	disabled?: boolean;
}

const schema = Yup.object().shape({
	link: Yup.string()
		.test(
			"regex",
			"Field must match an Ethereum address, a GitHub repository URL, or an Etherscan URL.",
			(value) => {
				return (
					addressRegexp.test(value) ||
					isEtherscanUrl(value) ||
					isGitHubUrl(value) ||
					isCode4Url(value) ||
					isSherlockUrl(value)
				);
			}
		)
		.required("Required")
});

const ImportProjectForm: React.FC<Props> = ({
	handleSubmit: propsHandleSubmit,
	setPrivateMode,
	disabled: PropsDisabled = false
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [focused, setFocused] = useState(false);
	const [hovered, setHovered] = useState(false);

	const [disabled, setDisabled] = useState(PropsDisabled);
	const [network, setNetwork] = useState("ethereum");

	const [privacyType, setPrivacyType] = useState("standard");
	const [privacyExpanded, setPrivacyExpanded] = useState(false);

	const [username] = useAtom(usernameAtom);
	const setLoginModalOpen = useSetAtom(loginModalOpenAtom);

	const chainItems = [
		{
			value: "ethereum",
			Icon: themeMode === "dark" ? EthIconDark : EthIconLight,
			label: "Ethereum Mainnet"
		},
		{ value: "avalanche", Icon: AvalancheIcon, label: "Avalanche C-Chain" },
		{ value: "optimism", Icon: OptimismIcon, label: "Optimism Mainnet" },
		{ value: "arbitrum", Icon: ArbitrumIcon, label: "Arbitrum Mainnet" },
		{ value: "base", Icon: BaseIcon, label: "Base Mainnet" },
		{ value: "polygon", Icon: PolygonIcon, label: "Polygon Mainnet" }
	];

	const testnetItems = [
		{
			value: "ethereumSepolia",
			Icon: themeMode === "dark" ? EthIconDark : EthIconLight,
			label: "Ethereum Sepolia"
		},
		{
			value: "ethereumGoerli",
			Icon: themeMode === "dark" ? EthIconDark : EthIconLight,
			label: "Ethereum Goerli"
		},
		{ value: "polygonMumbai", Icon: PolygonIcon, label: "Polygon Mumbai" },
		{ value: "optimismSepolia", Icon: OptimismIcon, label: "Optimism Sepolia" },
		{
			value: "bearachainArtio",
			Icon: BearachainIcon,
			label: "Berachain Artio"
		},
		{ value: "seiArctic", Icon: SeiIcon, label: "Sei Arctic" }
	];

	useEffect(() => {
		setDisabled(PropsDisabled);
	}, [PropsDisabled]);

	const formOptions = {
		resolver: yupResolver(schema) as Resolver<
			ImportProjectFormType,
			FieldValues
		>
	};

	const {
		handleSubmit,
		register,
		watch,
		formState: { errors }
	} = useForm<FieldValues & ImportProjectFormType & any>(formOptions);

	const onSubmit = (data: ImportProjectFormType) => {
		propsHandleSubmit?.({ ...data, privacyType }, network);
	};

	const linkValue = watch("link");
	const importBtnDisabled = !username || disabled;

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Accordion
				expanded={privacyExpanded}
				square={false}
				disableGutters
				sx={{
					width: "100%",
					borderRadius: "16px !important",
					backgroundImage: "none",
					bgcolor: "transparent",
					boxShadow: 0,
					"& .Mui-focusVisible": {
						bgcolor: "transparent !important"
					},
					"& .MuiAccordionSummary-root:hover": {
						cursor: "default"
					},
					"& .MuiAccordionSummary-content": {
						my: 0
					}
				}}
			>
				<AccordionSummary
					disableRipple
					disableTouchRipple
					focusRipple={false}
					sx={{
						p: 0,
						borderRadius: "16px",
						cursor: "default"
					}}
				>
					<Stack sx={{ height: "100%", width: "100%" }} spacing={1}>
						<Stack
							direction="row"
							alignItems="center"
							spacing={1}
							sx={{ width: "100%", height: { xs: 48, md: 52 } }}
						>
							{/* @ts-ignore */}
							<Box
								sx={{
									width: "100%",
									height: "100%",
									pl: 0.3,
									pr: 0.3,
									pt: 0.3,
									pb: 0.32,
									background: `linear-gradient(0deg, #0D94C4${
										hex[focused || hovered ? "100%" : "50%"]
									} 19.64%, #8870E6${
										hex[focused || hovered ? "100%" : "50%"]
									} 85.2%)`,
									borderRadius: "8px!important"
								}}
							>
								<FormTextField
									onFocus={() => setFocused(true)}
									onBlur={() => setFocused(false)}
									onMouseEnter={() => setHovered(true)}
									onMouseLeave={() => setHovered(false)}
									fullWidth
									disabled={disabled}
									required
									name="link"
									placeholder="Github repository or smart contract address"
									register={register}
									variant={themeMode === "dark" ? "outlined" : "standard"}
									onClick={() => {
										if (!username) {
											return setLoginModalOpen(true);
										}
									}}
									sx={{
										height: "100%",
										"& .MuiInputBase-root": {
											height: "100%"
										}
									}}
									InputProps={{
										readOnly: !username,
										...(themeMode === "light" && { disableUnderline: true }),
										sx: {
											position: "relative",
											borderRadius: "7px",
											background:
												themeMode === "dark"
													? `linear-gradient(275.47deg, ${theme.palette.background.paper} 53.21%, #8870E6 256.86%),linear-gradient(27.78deg, #0D94C4 19.64%, #8870E6 85.2%)`
													: `linear-gradient(275.47deg, ${theme.palette.background.paper} 53.21%, ${theme.palette.primary.light} 256.86%),linear-gradient(27.78deg, #0D94C4 19.64%, ${theme.palette.primary.light} 85.2%)`,
											boxShadow: 1,
											pr: 0.4,
											height: "100%",
											...(themeMode === "light" && { pl: 1.5, pt: 0.5 }),
											"& fieldset": {
												border: "0px !important"
											}
										},

										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={() => setPrivacyExpanded(!privacyExpanded)}
													sx={{
														height: 24,
														width: 24,
														color: "text.disabled",
														"&:hover": {
															color: "text.primary"
														}
													}}
												>
													<SettingsIcon sx={{ fontSize: 18 }} />
												</IconButton>

												<Divider
													sx={{ height: 28, my: 0.5, mx: { xs: 0.5, md: 1 } }}
													orientation="vertical"
												/>

												<Select
													value={network}
													variant="standard"
													disableUnderline
													disabled={disabled}
													onChange={(e) => setNetwork(e.target.value)}
													sx={{
														"& .MuiSelect-select:focus": {
															background: "none" // Override the background for focus state
														}
													}}
													renderValue={(selectedValue) => {
														const Icon = chainItems
															.concat(testnetItems)
															.find((i) => i.value === selectedValue)?.Icon;

														return (
															<Stack
																alignItems="center"
																justifyContent="center"
																sx={{
																	...(disabled && {
																		filter: "invert(0.5) !important"
																	})
																}}
															>
																<Box
																	component="img"
																	src={Icon}
																	alt={selectedValue}
																	sx={{
																		height: 20,
																		width: 20,
																		objectFit: "contain"
																	}}
																/>
															</Stack>
														);
													}}
													MenuProps={{
														anchorOrigin: {
															vertical: "bottom",
															horizontal: "right"
														},
														transformOrigin: {
															vertical: "top",
															horizontal: "right"
														}
													}}
												>
													<ListSubheader
														sx={{
															background: "inherit",
															lineHeight: "36px !important",
															fontWeight: 600
														}}
													>
														Mainnets
													</ListSubheader>

													{chainItems.map((item, i) => (
														<MenuItem value={item.value} key={"mainnet-" + i}>
															<Stack direction={"row"} spacing={1}>
																<Box
																	component="img"
																	src={item.Icon}
																	sx={{
																		height: 20,
																		width: 20,
																		objectFit: "contain"
																	}}
																/>
																<Typography>{item.label}</Typography>
															</Stack>
														</MenuItem>
													))}

													<ListSubheader
														sx={{
															background: "inherit",
															lineHeight: "36px !important",
															fontWeight: 600
														}}
													>
														Testnets
													</ListSubheader>

													{testnetItems.map((item, i) => (
														<MenuItem value={item.value} key={"testnet-" + i}>
															<Stack direction={"row"} spacing={1}>
																<Box
																	component="img"
																	src={item.Icon}
																	sx={{
																		height: 20,
																		width: 20,
																		objectFit: "contain"
																	}}
																/>
																<Typography>{item.label}</Typography>
															</Stack>
														</MenuItem>
													))}
												</Select>
											</InputAdornment>
										)
									}}
								/>
							</Box>

							<LoadingButton
								type={!!username ? "submit" : "button"}
								variant={themeMode === "dark" ? "contained" : "text"}
								disabled={importBtnDisabled}
								loading={disabled}
								sx={{
									px: { xs: 2, md: 3 },
									height: "99%",
									boxShadow: 1,
									borderRadius: "8px",
									bgcolor:
										themeMode === "dark" ? "primary.main" : "background.paper",
									color: "text.primary",
									background:
										!importBtnDisabled &&
										"linear-gradient(180deg, #8870E6 0%, #0D94C4 100%), linear-gradient(0deg, #8870E6, #8870E6)"
								}}
								onClick={() => {
									if (!username) {
										return setLoginModalOpen(true);
									}
								}}
							>
								Import
							</LoadingButton>
						</Stack>

						{errors?.link && (
							<Typography
								sx={{ px: 0.5, fontSize: 11, color: "text.secondary" }}
							>
								{errors?.link?.message}
							</Typography>
						)}
					</Stack>
				</AccordionSummary>

				<AccordionDetails sx={{ pt: 1.5, pb: 0, px: 0 }}>
					<ProjectPrivacyBox
						selected={privacyType}
						setSelected={(type) => {
							setPrivacyType(type);
							setPrivateMode(type === "private");
						}}
					/>
				</AccordionDetails>
			</Accordion>
		</form>
	);
};

export default ImportProjectForm;
