import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
	Drawer,
	IconButton,
	Stack,
	SxProps,
	styled,
	useTheme
} from "@mui/material";
import { NAVBAR_HEIGHT } from "components/Navbar";
import { useMediaQuery } from "hooks/ui/useMediaQuery";
import React, { useCallback, useEffect, useRef, useState } from "react";
import hex from "utils/hexTransparency";

export const SIDEBAR_WIDTH = 290;

const StyledDragHandle = styled(IconButton)(({ theme }) => ({
	borderRadius: "8px",
	position: "relative",
	overflow: "hidden",
	width: 20,
	cursor: "grab",
	"&:hover": { backgroundColor: theme.palette.background["nav"] },

	...(theme.palette.mode === "dark" && {
		backgroundColor: theme.palette.background["nav"]
	}),
	...(theme.palette.mode === "light" && {
		backgroundColor: "#E1E1E1"
	})
}));

interface DraggableSidebarProps {
	defaultWidth?: number;
	width?: number;
	setWidth?: any;
	children: React.ReactNode;
	minWidth?: number;
	maxWidth?: number;
	snapWidth?: number;
	disableOverflowScroll?: boolean;
	paperSx?: SxProps | any;
	sx?: SxProps | any;
	handleSx?: SxProps | any;
}

const DraggableSidebar: React.FC<DraggableSidebarProps> = ({
	defaultWidth = SIDEBAR_WIDTH,
	width = SIDEBAR_WIDTH,
	setWidth,
	maxWidth = 350,
	minWidth = 10,
	snapWidth = 100,
	disableOverflowScroll = false,
	paperSx = {},
	sx = {},
	handleSx = {},
	children
}) => {
	const sidebarRef = useRef(null);
	const [isResizing, setIsResizing] = useState(null);
	const [stateSidebarWidth, setStateSidebarWidth] = useState(width);
	const isLargeScreen = useMediaQuery("(min-width:1200px)");

	const sidebarWidth = width || stateSidebarWidth;
	const setSidebarWidth = setWidth || setStateSidebarWidth;

	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const startResizing = useCallback((mouseMoveEvent) => {
		const _position = mouseMoveEvent.clientX;
		setIsResizing(_position);
	}, []);

	const stopResizing = useCallback(
		(mouseMoveEvent) => {
			const _position = mouseMoveEvent.clientX;

			// Collapse if only clicked without dragging
			if (_position === isResizing && sidebarWidth > 10) {
				setSidebarWidth(10);
			}

			setIsResizing(null);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isResizing, sidebarWidth]
	);

	const resize = useCallback(
		(mouseMoveEvent) => {
			if (isResizing !== null) {
				const sidebarRect = sidebarRef.current.getBoundingClientRect();
				const _width = mouseMoveEvent.clientX - sidebarRect.left;

				const validWidth =
					_width < minWidth ? minWidth : _width > maxWidth ? maxWidth : _width;

				// Check if resizing to the left and width reaches snapWidth
				if (_width <= snapWidth && _width >= minWidth) {
					setSidebarWidth(minWidth); // Automatically close to 10px
				} else {
					setSidebarWidth(validWidth); // Resize as normal
				}
			}
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isResizing, maxWidth, minWidth, snapWidth, setSidebarWidth]
	);

	useEffect(() => {
		if (!isLargeScreen) {
			setSidebarWidth(10);
		}
	}, [isLargeScreen, setSidebarWidth]);

	useEffect(() => {
		window.addEventListener("mousemove", resize);
		window.addEventListener("mouseup", stopResizing);
		return () => {
			window.removeEventListener("mousemove", resize);
			window.removeEventListener("mouseup", stopResizing);
		};
	}, [resize, stopResizing]);

	return (
		<Drawer
			open
			onClose={() => setSidebarWidth(10)}
			ref={sidebarRef}
			variant={isLargeScreen ? "persistent" : "temporary"}
			ModalProps={{ disableEnforceFocus: true }}
			sx={{
				width: sidebarWidth,
				flexShrink: 0,

				// Transition on mobile screens or when opening a snapped width
				...((!isLargeScreen ||
					sidebarWidth === minWidth ||
					sidebarWidth === defaultWidth) && {
					transition: theme.transitions.create("width", {
						easing: theme.transitions.easing.easeInOut,
						duration: theme.transitions.duration.standard
					})
				}),
				"& .MuiBackdrop-root": {
					backgroundColor: `#36325E${hex["20%"]}`,
					backdropFilter: "blur(2px)"
				},
				[`& .MuiDrawer-paper`]: {
					pt: `${NAVBAR_HEIGHT}px`,
					position: "relative",
					// width: sidebarWidth,
					boxSizing: "border-box",
					overflow: "unset",
					borderRight: 0,
					backgroundImage: "none",
					[theme.breakpoints.up("lg")]: {
						pt: 0
					},
					...(themeMode === "dark" && { bgcolor: "background.nav" }),
					...(themeMode === "light" && {
						background:
							"linear-gradient(233.84deg, #E1E1E1 40.64%, rgba(230, 230, 230, 1) 85.1%)"
					}),
					...paperSx
				},
				...sx
			}}
			onMouseDown={(e) => {
				e.stopPropagation();
				// e.preventDefault();
			}}
			hideBackdrop={isLargeScreen || sidebarWidth === 10}
		>
			{/* Handle */}
			{isLargeScreen ? (
				sidebarWidth === minWidth ? (
					<StyledDragHandle
						size="small"
						sx={{
							position: "absolute",
							top: "50%",
							right: -10,
							zIndex: 99,
							cursor: "pointer",
							...handleSx
						}}
						onClick={() => setSidebarWidth(SIDEBAR_WIDTH)}
					>
						<KeyboardArrowRightIcon />
					</StyledDragHandle>
				) : (
					<StyledDragHandle
						size="small"
						sx={{
							position: "absolute",
							top: "50%",
							right: -10,
							zIndex: 99,
							...handleSx
						}}
						onMouseDown={startResizing}
					>
						<DragIndicatorIcon fontSize="small" />
					</StyledDragHandle>
				)
			) : (
				<StyledDragHandle
					size="small"
					sx={{ position: "absolute", top: "50%", right: -10, zIndex: 99 }}
					onClick={() => {
						sidebarWidth > 10
							? setSidebarWidth(10)
							: setSidebarWidth(SIDEBAR_WIDTH);
					}}
				>
					{sidebarWidth > 10 ? (
						<KeyboardArrowLeftIcon />
					) : (
						<KeyboardArrowRightIcon />
					)}
				</StyledDragHandle>
			)}

			{/* Content */}
			<Stack
				sx={{
					width: "100%",
					overflowY: disableOverflowScroll ? "unset" : "scroll",
					overflowX: "hidden",
					height: "100%"
				}}
			>
				{children}
			</Stack>
		</Drawer>
	);
};

export default DraggableSidebar;
