import { Icon } from "@iconify/react";
import { Add as AddIcon } from "@mui/icons-material";
import {
	Button,
	ButtonGroup,
	Divider,
	Hidden,
	Tooltip,
	tooltipClasses,
	useTheme
} from "@mui/material";
import { useTools } from "hooks/ui/useTools";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React from "react";
import { selectedToolAtom } from "store/atoms/UiAtoms";
import { findingToEditAtom, findingsAtom } from "store/atoms/findingsAtom";
import { projectAtom } from "store/atoms/projectToolAtoms";

const FindingsButton = () => {
	const theme = useTheme();

	const { selectTool } = useTools();

	const [selectedTool] = useAtom(selectedToolAtom);
	const [project] = useAtom(projectAtom);
	const [findings] = useAtom(findingsAtom);

	const setFindingToEdit = useSetAtom(findingToEditAtom);

	const numFindings = findings?.length || 0;

	if (project?.name === "CodeChallenge") return null;

	return (
		<ButtonGroup
			variant="contained"
			sx={{
				height: { xs: 34, md: 36, xl: 37 },
				color: "common.white",
				borderRadius: "8px",
				boxShadow: 1,
				background:
					selectedTool === "AddFinding"
						? "linear-gradient(230.24deg, #085769 9.78%, #507780 94.7%)"
						: "linear-gradient(230.24deg, #0B667B 9.78%, #7EB3BF 94.7%)"
			}}
		>
			<Button
				onClick={() => {
					if (selectedTool === "Findings") selectTool(null);
					else selectTool("Findings");
				}}
				sx={{
					borderRight: "none !important",
					bgcolor:
						selectedTool === "Findings" ? "rgba(0,0,0,0.1)" : "transparent",
					pr: { xs: 1, sm: 1.5 },
					pl: { xs: 1.3, sm: 2 },
					borderRadius: "8px",
					fontWeight: 400,
					"&:hover": { bgcolor: "rgba(0,0,0,0.1)" },
					"& span": {
						fontWeight: 600,
						mr: 0.5
					}
				}}
			>
				<span>{numFindings}</span>

				<Hidden smDown>{numFindings === 1 ? "Finding" : "Findings"}</Hidden>

				<Hidden mdUp>
					<Icon icon="ph:detective-fill" fontSize={18} />
				</Hidden>
			</Button>

			<Divider
				sx={{
					height: 20,
					my: 1,
					borderRightColor: "#78ABB6",
					borderRightWidth: 2
				}}
				orientation="vertical"
			/>

			<Tooltip
				title="Add a finding"
				slotProps={{
					tooltip: {
						sx: {
							[`&.${tooltipClasses.tooltip}`]: {
								border: `1px solid ${theme.palette.primary.dark}`,
								borderRadius: "8px",
								backgroundColor: theme.palette.background.paper
							}
						}
					}
				}}
			>
				<Button
					size="small"
					onClick={() => {
						setFindingToEdit({});
						if (selectedTool === "AddFinding") selectTool(null);
						else selectTool("AddFinding");
					}}
					sx={{
						bgcolor:
							selectedTool === "AddFinding" ? "rgba(0,0,0,0.1)" : "transparent",
						px: 0,
						borderRadius: "8px",
						"&:hover": { bgcolor: "rgba(0,0,0,0.1)" },
						minWidth: { xs: "32px!important", sm: "40px!important" }
					}}
				>
					<AddIcon fontSize="small" />
				</Button>
			</Tooltip>
		</ButtonGroup>
	);
};

export default React.memo(FindingsButton);
