import {
	collection,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	setDoc
} from "firebase/firestore";

export const createNewUserMetadata = async (uid: string, db) => {
	try {
		const docRef = doc(db, "user_metadata", uid);

		const payload = {
			uid: uid
		};

		await setDoc(docRef, {
			...payload
		});

		return { success: true, payload: { id: docRef.id } };
	} catch (error) {
		console.log(error);
		return error;
	}
};

export const listenUserMetadata = async (uid: string, callback: any, db) => {
	const docRef = doc(db, "user_metadata", uid);
	const docSnap = await getDoc(docRef);

	if (!docSnap.exists()) {
		await createNewUserMetadata(uid, db);
	}

	const unsub = onSnapshot(docRef, (doc) => {
		if (doc.exists()) {
			const data = doc.data();
			callback?.(data);
		} else {
			console.log("Document does not exist");
		}
	});

	return unsub;
};

export const getHomepageImprovements = async (db) => {
	try {
		const docsRef = collection(db, "homepage_improvements");

		const docQuery = query(docsRef, orderBy("timestamp", "desc"));

		const querySnapshot = await getDocs(docQuery);

		const snapshotData: any = [];
		querySnapshot.forEach(async (doc) => {
			const timestamp = doc.data().timestamp.toDate();
			snapshotData.push({ ...doc.data(), timestamp });
		});

		return snapshotData;
	} catch (error) {
		console.log(error);
		return null;
	}
};

export const getHomepageNews = async (db) => {
	try {
		const docsRef = collection(db, "homepage_news");

		const docQuery = query(docsRef, orderBy("slideshowIndex", "asc"));

		const querySnapshot = await getDocs(docQuery);

		const snapshotData: any = [];
		querySnapshot.forEach(async (doc) => {
			const timestamp = doc.data().timestamp.toDate();
			snapshotData.push({ ...doc.data(), timestamp });
		});

		return snapshotData;
	} catch (error) {
		console.log(error);
		return null;
	}
};
