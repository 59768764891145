import CircleIcon from "@mui/icons-material/Circle";
import {
	Box,
	Container,
	Stack,
	StackProps,
	Typography,
	useTheme
} from "@mui/material";
import React from "react";
import PlanBox from "./PlanBox";

const BulletPoint: React.FC<StackProps & { text: string }> = ({
	text,
	...other
}) => (
	<Stack direction="row" alignItems="center" spacing={1} {...other}>
		<CircleIcon sx={{ fontSize: 8 }} />
		<Typography>{text}</Typography>
	</Stack>
);

const PaymentsAndBilling = () => {
	const theme = useTheme();

	return (
		<Container maxWidth="md">
			<Stack
				sx={{
					width: "100%",
					height: "100%",
					bgcolor: theme.palette.background.default
				}}
			>
				{/* Plan Box */}
				<Box
					sx={{
						width: "100%",
						borderRadius: "20px",
						bgcolor: "background.paper",
						p: 3
					}}
				>
					<Typography variant="h5" sx={{ mb: 1.5 }}>
						Plan
					</Typography>

					<Stack direction={{ xs: "column", md: "row" }} spacing={2}>
						<PlanBox
							title="Basic (current plan)"
							price="FREE"
							bullets={[
								"Full access to scanning tools",
								"Unlimited project imports",
								"Report generation and contest submissions",
								"AI analysis tool"
							]}
							selected
						/>

						<PlanBox
							title="Pro ⭐ (coming soon)"
							bullets={[
								"Advanced scanning tools",
								"AI report writing, assistant, and PoC generator",
								"Fuzzing",
								"Test and PoC writing",
								"...and more!"
							]}
						/>
					</Stack>
				</Box>

				{/* Payment Methods */}
				{/* <Box
				sx={{
					width: "70%",
					borderRadius: "inherit",
					bgcolor: theme.palette.background.paper,
					mt: 2,
					mx: "auto",
					height: "320px",
					py: 2,
					px: 2,
					minWidth: "753px"
				}}
			>
				<Typography variant="h4">Payment Methods</Typography>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "70%"
					}}
				>
					<Grid container>
						<Grid item xs={12}>
							<Typography
								align="center"
								style={{ margin: "auto", fontSize: "18px" }}
							>
								<strong>No Payment Methods</strong>
							</Typography>
							<Typography
								align="center"
								style={{ margin: "auto", fontSize: "14px" }}
							>
								Coming Soon
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</Box> */}

				{/* Billing History */}
				{/* <Box
				sx={{
					width: "70%",
					borderRadius: "inherit",
					bgcolor: theme.palette.background.paper,
					mt: 2,
					mx: "auto",
					height: "320px",
					py: 2,
					px: 2,
					minWidth: "753px"
				}}
			>
				<Typography variant="h4">Billing History</Typography>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "70%"
					}}
				>
					<Grid container>
						<Grid item xs={12}>
							<Typography
								align="center"
								style={{ margin: "auto", fontSize: "18px" }}
							>
								<strong>No Billing History</strong>
							</Typography>
							<Typography
								align="center"
								style={{ margin: "auto", fontSize: "14px" }}
							>
								Coming Soon
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</Box> */}
			</Stack>
		</Container>
	);
};

export default React.memo(PaymentsAndBilling);
