import {
	Box,
	FormControl,
	FormControlProps,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	SelectProps,
	Stack,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { varExists } from "utils/helpersFunctions";
import hex from "utils/hexTransparency";

interface FormSelectFieldItems {
	prefix?: string;
	name: string;
	value?: any;
	Icon?: React.ReactElement;
	Action?: React.ReactElement;
	[anykey: string]: any;
}

interface FormSelectFieldProps {
	inputSx?: object;
	control: any;
	items: FormSelectFieldItems[];
	helperText?: string;
}

const FormSelectField: React.FC<
	SelectProps & FormControlProps & FormSelectFieldProps
> = ({
	name,
	control,
	sx = {},
	inputSx = {},
	required,
	error,
	label,
	helperText,
	disabled,
	items,
	children,
	...props
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, onBlur, value, ref } }) => (
				<FormControl size="small" {...props}>
					{label && <InputLabel id="select-label">{label}</InputLabel>}

					<Select
						labelId="select-label"
						variant="outlined"
						size="small"
						onChange={onChange}
						onBlur={onBlur}
						value={value}
						error={error}
						label={label}
						sx={{
							height: 40,
							borderRadius: "8px",
							...(themeMode === "light" && {
								bgcolor: "background.paper",
								boxShadow: 1,
								"& fieldset": {
									borderColor: "transparent"
								}
							}),
							...sx
						}}
						MenuProps={{
							slotProps: {
								paper: {
									sx: {
										mt: 1,
										bgcolor: "background.paper",
										borderRadius: "8px",
										backgroundImage: "none",
										...(themeMode === "light" && {
											border: `1px solid ${theme.palette.background["paper2"]}`,
											boxShadow: 1
										}),
										...(themeMode === "dark" && {
											border: `2px solid ${theme.palette.primary.main}`,
											boxShadow: "none"
										})
									}
								}
							}
						}}
						renderValue={(val) => {
							const item = items.find((i) => i.value === val || i.name === val);

							if (!item) return null;

							return (
								<Stack
									direction="row"
									spacing={0.7}
									alignItems="center"
									sx={{ width: "100%" }}
								>
									{item.Icon}
									{item.prefix
										? <Stack direction="row">
											<Typography sx={{ fontWeight: 'light' }}>{item.prefix}</Typography>
											<Typography sx={{ color: theme.palette.primary.main }}>{item.name}</Typography>
										</Stack>
										: <Typography>{item.name}</Typography>
									}
								</Stack>
							);
						}}
						disabled={disabled}
						{...props}
					>
						{items?.map((item, i) => {
							if (!item) return null;

							return (
								<MenuItem
									key={i}
									value={varExists(item?.value) ? item?.value : item?.name}
									sx={{
										borderTop:
											item?.borderTop &&
											`2px solid ${theme.palette.primary.dark}${hex["30%"]}`,
										"&:hover": {
											"& .selectActionShowOnhover": {
												display: "inline-flex!important"
											}
										}
									}}
								>
									<Tooltip title={item?.tooltip}>
										<Stack
											direction="row"
											spacing={0.7}
											alignItems="center"
											sx={{ width: "100%" }}
										>
											{item.Icon}

											{item.prefix
												? <Stack direction="row">
														<Typography sx={{ fontWeight: 'light' }}>{item.prefix}</Typography>
														<Typography sx={{ color: theme.palette.primary.main }}>{item.name}</Typography>
													</Stack>
												: <Typography>{item.name}</Typography>
											}

											{item.Action && (
												<>
													<Box flex={1} />
													{item.Action}
												</>
											)}
										</Stack>
									</Tooltip>
								</MenuItem>
							);
						})}

						<Box
							onKeyDown={(e) => e.stopPropagation()}
							// onClickCapture={stopImmediatePropagation}
						>
							{children}
						</Box>
					</Select>

					{helperText && (
						<FormHelperText
							sx={{ color: !!error ? "error.main" : "text.secondary" }}
						>
							{helperText}
						</FormHelperText>
					)}
				</FormControl>
			)}
		/>
	);
};

export default React.memo(FormSelectField);
