import { Icon } from "@iconify/react";
import CodeIcon from "@mui/icons-material/Code";
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Stack,
	SvgIcon,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import { ReactComponent as HatsIcon } from "assets/icons/hatsIcon.svg";
import PrivateBg from "assets/images/incognito.png";
import { useProject } from "hooks/data/useProject";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { submissionModeAtom } from "store/atoms/UiAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import CustomTreeView from "./CustomTreeView";
import GlobalState from 'utils/globals';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
	borderRadius: "8px",
	...(theme.palette.mode === "light" && {
		border: 0,
		color: `${theme.palette.text.primary}!important`
	}),
	"&.Mui-selected, &.Mui-selected:hover": {
		backgroundColor: theme.palette.background["selected"],
		...(theme.palette.mode === "light" && {
			color: `${theme.palette.text.primary}!important`
		})
	}
}));

const ProjectTree = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [showUnloadedFiles, setShowUnloadedFiled] = useState(false);

	const [project] = useAtom(projectAtom);
	const [submissionMode, setSubmissionMode] = useAtom(submissionModeAtom);

	const { closeProject } = useProject();

	const closeProjectAndCancelHydration = () => {
		closeProject();
		const globalState = GlobalState.getInstance();
		globalState.setHydratingProject(false);
	};

	const handleTypeChange = (e: React.MouseEvent<HTMLElement>, val: boolean) => {
		if (val !== null) {
			if (project.submittable) {
				setSubmissionMode(val);
			}
		}
	};

	const handleHiddenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowUnloadedFiled(event.target.checked);
	};

	return (
		<Stack
			sx={{
				height: "100%",
				p: 1.8,
				width: "100%",
				minWidth: 265,
				position: "relative"
			}}
		>
			<Box
				component="img"
				src={PrivateBg}
				sx={{
					position: "absolute",
					height: "95%",
					objectFit: "cover",
					opacity: themeMode === "dark" ? 0.04 : 0.03,
					bottom: 0
				}}
			/>

			{/* Back to projects button */}
			<Button
				variant={themeMode === "light" ? "contained" : "outlined"}
				fullWidth
				onClick={() => closeProjectAndCancelHydration()}
				sx={{
					height: 40,
					color: "text.primary",
					mb: 1.5,
					borderRadius: "8px",
					...(themeMode === "light" && {
						border: "none",
						bgcolor: "background.paper",
						boxShadow: 1,
						":hover": {
							bgcolor: "#EEE",
							boxShadow: 1
						}
					})
				}}
			>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
					spacing={0.7}
				>
					<Icon icon="ant-design:layout-filled" fontSize={18} />

					<Typography variant="body2" sx={{ fontWeight: 500 }}>
						Return to home page
					</Typography>
				</Stack>
			</Button>

			{/* Code-submit toggle */}
			{project.submittable && (
				<ToggleButtonGroup
					size="small"
					color="primary"
					value={submissionMode}
					exclusive
					onChange={handleTypeChange}
					fullWidth
					sx={{
						mb: 2.5,
						height: 40,
						borderRadius: "8px",
						...(themeMode === "light" && {
							bgcolor: "background.paper",
							boxShadow: 1
						})
					}}
				>
					<StyledToggleButton value={false}>
						<CodeIcon fontSize="small" sx={{ mr: 0.5 }} />
						Code
					</StyledToggleButton>

					<StyledToggleButton value>
						<SvgIcon sx={{ mr: -0.4, mt: 1.5, fontSize: "40px" }}>
							<HatsIcon />
						</SvgIcon>
						Submit
					</StyledToggleButton>
				</ToggleButtonGroup>
			)}

			{/* Tree View */}
			<CustomTreeView showUnloadedFiles={showUnloadedFiles} />

			{/* Show hidden files */}
			<FormControlLabel
				control={
					<Checkbox
						size="small"
						checked={showUnloadedFiles}
						onChange={handleHiddenChange}
					/>
				}
				label={<Typography variant="caption">Show hidden files</Typography>}
			/>
		</Stack>
	);
};

export default React.memo(ProjectTree);
