import { Checkbox, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { ChecklistType } from "store/atoms/checklistAtoms";

const LibraryItem: React.FC<{
	checklist: ChecklistType;
}> = ({ checklist }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const { title, completed } = checklist;

	return (
		<Stack
			direction="row"
			spacing={1}
			sx={{
				p: 1.5,
				border: "1px solid",
				borderColor: "background.paper2",
				borderRadius: "8px",
				bgcolor: completed ? "background.paper" : "transparent",
				...(themeMode === "light" && {
					borderColor: "background.paper",
					boxShadow: 1
				})
			}}
		>
			<Checkbox
				size="small"
				defaultChecked={completed}
				disabled
				sx={{ height: 16, width: 16 }}
			/>

			<Typography sx={{ flex: 1 }}>{title}</Typography>
		</Stack>
	);
};

export default LibraryItem;
