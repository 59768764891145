import { Icon } from "@iconify/react";
import {
	Badge,
	Button,
	Checkbox,
	Menu,
	MenuItem,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import React from "react";
import hex from "utils/hexTransparency";
import { FilterType } from ".";

const FilterButton: React.FC<{
	title: string;
	filters: FilterType[];
	selected: FilterType[];
	setSelected: any;
}> = ({ title, filters, selected, setSelected }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSelect = (item) => {
		const exists = !!selected.find((i) => i.name === item.name);

		if (exists) {
			setSelected((old) => old.filter((i) => i.name !== item.name));
		} else {
			setSelected((old) => [...old, item]);
		}
	};

	const total = selected?.length || 0;

	return (
		<>
			<Badge
				badgeContent={total}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left"
				}}
				sx={{
					"& .MuiBadge-badge": {
						fontSize: 10,
						bgcolor: "background.paper2",
						height: 18,
						width: 18
					}
				}}
			>
				<Button onClick={handleClick} sx={{ color: "text.secondary" }}>
					<Stack direction="row" alignItems="center" spacing={1}>
						<Typography variant="body2">{title}</Typography>

						<Icon icon="mingcute:down-fill" fontSize={14} />
					</Stack>
				</Button>
			</Badge>

			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				slotProps={{
					paper: {
						elevation: 0,
						sx: {
							borderRadius: "8px",
							mt: 1,
							boxShadow: 2,
							border: "1px solid",
							borderColor: "divider",
							bgcolor: `${theme.palette.background["nav"]}${hex["80%"]}`,
							backdropFilter: "blur(5px)"
						}
					}
				}}
			>
				{filters.map((item, i) => {
					const _selected = !!selected.find((i) => i.name === item.name);

					return (
						<MenuItem
							onClick={() => handleSelect(item)}
							key={i}
							selected={_selected}
						>
							<Checkbox
								size="small"
								checked={_selected}
								sx={{
									width: 0,
									height: 0,
									mr: 1,
									"& .MuiSvgIcon-root": { fontSize: 18 }
								}}
							/>

							{item.name}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

export default React.memo(FilterButton);
