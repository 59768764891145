import {
	Box,
	Button,
	Stack,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import { codeChallengeOpenedEvent } from "Mixpanel/mixpanel.helper";
import { Challenge } from "Resources/ServerInterfaceChallenge";
import { mixpanelAtom, usernameAtom } from "atoms";
import { appConfig } from "config";
import { useBugChallenge } from "hooks/data/useBugChallenge";
import { useAtom, useSetAtom } from "jotai";
import React from "react";
import { focusedChallengeAtom } from "store/atoms/BugChallengeAtoms";
import { importingAtom } from "store/atoms/ImportsAtoms";
import { loginModalOpenAtom } from "store/atoms/UiAtoms";

const ChallengesCard: React.FC<{
	timeState: "active" | "upcoming" | "expired";
	data: Challenge;
}> = ({ timeState, data }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [importing] = useAtom(importingAtom);

	const [username] = useAtom(usernameAtom);
	const [mixpanel] = useAtom(mixpanelAtom);
	const setFocusedChallenge = useSetAtom(focusedChallengeAtom);
	const setLoginModalOpen = useSetAtom(loginModalOpenAtom);

	const { importAndOpenProject } = useBugChallenge();

	const { darkLogo, lightLogo } = appConfig;

	return (
		<Button
			disabled={importing}
			onClick={() => {
				if (!username) {
					return setLoginModalOpen(true);
				}

				// if (!solved) importAndOpenProject();
				importAndOpenProject(data.commitHash, true);
				codeChallengeOpenedEvent(mixpanel, {
					userID: username,
					challengeID: data._id
				});
				setFocusedChallenge(data);
			}}
			fullWidth
			sx={{
				cursor: "pointer",
				padding: 0,
				height: "auto",
				color: "unset",
				textAlign: "unset",
				border: `1px solid ${theme.palette.background["paper2"]}`,
				borderRadius: "10px",
				bgcolor: "background.paper",
				"&:hover": {
					border: `1px solid ${theme.palette.primary.main}`,
					bgcolor: "background.paper"
				}
			}}
		>
			<Stack
				spacing={1.5}
				sx={{
					height: "100%",
					width: "100%",
					borderRadius: "10px",
					p: 2,
					...(timeState === "active" && { bgcolor: "background.paper" }),
					...(timeState === "upcoming" && {
						bgcolor: `${theme.palette.background.default}`
					}),
					...(timeState === "expired" && {
						bgcolor: `${theme.palette.background.default}`
					})
				}}
			>
				{/* Top */}
				<Stack spacing={1.2} alignItems="center" direction="row">
					<Box
						component="img"
						src={data.image}
						sx={{
							borderRadius: "8px",
							border: "1px solid background.paper2",
							height: 40,
							width: 40,
							objectFit: "cover",
							objectPosition: "center center"
						}}
					/>

					<Stack alignItems="flex-start" spacing={0.6}>
						<Typography>{data.title}</Typography>

						<Box
							component="img"
							src={themeMode === "dark" ? darkLogo : lightLogo}
							sx={{
								height: 13,
								objectFit: "contain",
								opacity: importing ? 0.4 : 1
							}}
						/>
					</Stack>
				</Stack>

				{/* Middle */}
				<Box sx={{ flex: 1 }}>
					<Tooltip
						title={data.description?.length > 80 ? data.description : ""}
						enterDelay={500}
						enterNextDelay={500}
					>
						<Typography
							sx={{
								pl: 1,
								overflow: "hidden",
								textOverflow: "ellipsis",
								display: "-webkit-box",
								WebkitBoxOrient: "vertical",
								WebkitLineClamp: 3,
								maxHeight: "5em",
								minHeight: 50
							}}
						>
							{data.description}
						</Typography>
					</Tooltip>
				</Box>

				{/* Bottom */}
				{/* <Stack
					spacing={1}
					sx={{
						p: 1.5,
						borderRadius: "10px",
						bgcolor: "background.default",
						...(timeState === "active" && {
							background: `linear-gradient(87.53deg, rgba(229, 177, 41, 0.2) 0.94%, ${theme.palette.background.default} 89.48%)`
						}),
						...(timeState === "upcoming" && {
							bgcolor: "background.nav"
						}),
						...(timeState === "expired" && {
							background: `linear-gradient(87.53deg, ${theme.palette.success.main}${hex["15%"]} 0.94%, ${theme.palette.background["nav"]} 89.48%)`
						})
					}}
				>
					<Stack spacing={0.5} direction="row" alignItems="center">
						<Box
							component="img"
							src={image}
							sx={{
								height: "100%",
								maxHeight: 21,
								objectFit: "contain"
							}}
						/>

						<Typography>Unique badge</Typography>

						<Box sx={{ flex: 1 }} />

						{timeState === "active" && (
							<Chip
								size="small"
								label="Live"
								sx={{
									minWidth: 50,
									fontSize: theme.typography.body2.fontSize,
									fontWeight: 600,
									bgcolor: "success.main",
									color: "text.inverse"
								}}
							/>
						)}

						{timeState === "upcoming" && (
							<Chip
								size="small"
								label="Upcoming"
								sx={{
									minWidth: 50,
									fontSize: theme.typography.body2.fontSize
								}}
							/>
						)}

						{timeState === "expired" && (
							<Chip
								size="small"
								label="Expired"
								color="default"
								sx={{
									minWidth: 50,
									fontSize: theme.typography.body2.fontSize,
									color: "text.inverse",
									fontWeight: 600
								}}
							/>
						)}
					</Stack>

					{solved ? (
						<Typography>Solved!</Typography>
					) : (
						<Stack
							direction="row"
							alignItems="center"
							spacing={1}
							justifyContent="space-between"
						>
							<Typography>
								{timeState === "active"
									? "Time left:"
									: timeState === "upcoming"
									? "Starts in:"
									: "Unsolved"}
							</Typography>

							<Typography>01:24</Typography>
						</Stack>
					)}
				</Stack> */}
			</Stack>
		</Button>
	);
};

export default ChallengesCard;
