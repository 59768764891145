import { Divider, Typography } from "@mui/material";
import PlanBox from "components/MyAccount/PlanBox";
import CustomModal from ".";

interface ProModalProps {
	open: boolean;
	handleClose: () => void;
}

const ProModal: React.FC<ProModalProps> = ({ open, handleClose }) => {
	return (
		<CustomModal
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="md"
			isProModal
			paperSx={{ maxWidth: "609px", py: 6 }}
		>
			<Typography variant="h4" sx={{ textAlign: "center", mb: 2 }}>
				Unleash your potential with Pro
			</Typography>

			<Typography sx={{ textAlign: "center" }}>
				For auditors and devs who need to quickly find and report threats and
				stay ahead of the competition.{" "}
			</Typography>

			<Divider sx={{ my: 3 }} />

			<PlanBox
				selected
				title={"Pro ⭐"}
				bullets={[
					"Unlimited project imports",
					"Advanced scanning tools",
					"AI code explanations",
					"Unlimited reporting"
				]}
				price={"$120/mo"}
			/>
		</CustomModal>
	);
};

export default ProModal;
