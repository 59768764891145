import CircleIcon from "@mui/icons-material/Circle";
import {
	Box,
	Button,
	Chip,
	Stack,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { PartnerAdType, importingAtom } from "store/atoms/ImportsAtoms";
import { calculateTimeRemaining } from "./ContestsBountiesProjects";
import SherlockLogo from "assets/images/sherlock.jpg";
import CodeHawksLogo from "assets/images/codehawks.png";
import Code4renaLogo from "assets/images/code4rena.png";
import HatsLogo from "assets/images/hatsLogo.png";
import CantinaLogo from "assets/images/cantina.png";

function buildTimeString(param) {
	if (param.days > 0) {
		return `${param.days}d ${param.hours}h`;
	} else if (param.hours > 0) {
		return `${param.hours}h ${param.minutes}m`;
	} else {
		return `${param.minutes}m ${param.seconds}s`;
	}
}

export const projectSourceLogo = (source) => {
	switch (source) {
		case "Sherlock":
			return SherlockLogo;
		case "Cantina":
			return CantinaLogo;
		case "Code4rena":
			return Code4renaLogo;
		case "Hats":
			return HatsLogo;
		case "Hats Finance":
			return HatsLogo;
		case "CodeHawks":
			return CodeHawksLogo;
		default:
			return null;
	}
};

const ContestsBountiesCard: React.FC<{
	data: PartnerAdType;
	type: "contests" | "bounties";
	handleProjectClick: any;
	timeState?: "expired" | "active" | "upcoming";
}> = ({ data, type, handleProjectClick, timeState }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [importing] = useAtom(importingAtom);

	const [timeRemaining, setTimeRemaining] = useState(
		calculateTimeRemaining(data?.endTime, data?.startTime)
	);

	useEffect(() => {
		const interval = setInterval(() => {
			setTimeRemaining(calculateTimeRemaining(data?.endTime, data?.startTime));
		}, 1000);

		return () => clearInterval(interval);
	}, [data?.endTime]);

	const timeRemainingString = buildTimeString(timeRemaining);

	const expired = timeState === "expired";

	const formatTokenAmount = (_number) => {
		const number = parseFloat(_number);

		if (isNaN(number)) return _number;

		if (number < 1000) {
			// Return the number as is (no symbol)
			return number.toFixed(2);
		} else if (number < 1000000) {
			// Convert to thousands (xK) format
			return (number / 1000).toFixed(2) + "K";
		} else {
			// Convert to millions (xMM) format
			return (number / 1000000).toFixed(2) + "MM";
		}
	};

	const sourceLogo = projectSourceLogo(data?.source);

	return (
		<Button
			disabled={!!importing}
			onClick={() => {
				if (!importing && timeState !== "upcoming") handleProjectClick();
			}}
			fullWidth
			disableRipple={timeState === "upcoming"}
			sx={{
				cursor: timeState !== "upcoming" ? "pointer" : "default",
				padding: 0,
				height: "auto",
				color: "unset",
				textAlign: "unset",
				border: `1px solid ${theme.palette.background["paper2"]}`,
				borderRadius: "10px",
				bgcolor: "background.paper",
				...(timeState !== "upcoming" && {
					"&:hover": {
						border: `1px solid ${theme.palette.primary.main}`,
						bgcolor: "background.paper"
					}
				})
			}}
		>
			<Stack
				spacing={1.5}
				sx={{
					height: "100%",
					width: "100%",
					borderRadius: "10px",
					p: 2,
					bgcolor: "background.paper",
					"&:hover":
						timeState !== "upcoming"
							? {
									bgcolor: "background.paper"
									// background: `linear-gradient(320deg, ${theme.palette.background.paper} 53.21%, #8870E6 256.86%),linear-gradient(27.78deg, #0D94C4 19.64%, #8870E6 85.2%)`
							  }
							: {},
					...(timeState === "active" && { bgcolor: "background.paper" }),
					...(timeState === "upcoming" && {
						bgcolor: `${theme.palette.background.default}`
					}),
					...(timeState === "expired" && {
						bgcolor: `${theme.palette.background.default}`
					})
				}}
			>
				{/* Top */}
				<Stack spacing={1.5} alignItems="center" direction="row">
					<Box
						component="img"
						src={data.imageUrl}
						alt={data.title}
						onError={(e) => {
							e.currentTarget.src = sourceLogo;
						}}
						sx={{
							height: 41,
							width: 41,
							objectFit: "contain",
							borderRadius: "8px"
						}}
					/>

					<Stack spacing={0.2} sx={{ flex: 1 }}>
						<Typography>{data.title}</Typography>

						<Stack direction="row" alignItems="center" spacing={0.5}>
							{sourceLogo && (
								<Box
									component="img"
									src={sourceLogo}
									sx={{
										height: 16,
										objectFit: "contain",
										borderRadius: "5px"
									}}
								/>
							)}

							<Typography>{data.source}</Typography>
						</Stack>
					</Stack>

					{!expired && timeState !== "upcoming" && type === "contests" && (
						<CircleIcon
							sx={{
								fontSize: 12,
								color: "primary.main",
								alignSelf: "start"
							}}
						/>
					)}
				</Stack>

				{/* Middle */}
				<Box sx={{ flex: 1 }}>
					<Tooltip
						title={data.description?.length > 80 ? data.description : ""}
						enterDelay={500}
						enterNextDelay={500}
					>
						<Typography
							sx={{
								pl: 1,
								overflow: "hidden",
								textOverflow: "ellipsis",
								display: "-webkit-box",
								WebkitBoxOrient: "vertical",
								WebkitLineClamp: 3,
								maxHeight: "5em",
								minHeight: 50
							}}
						>
							{data.description}
						</Typography>
					</Tooltip>
				</Box>

				{/* Bottom */}
				<Stack
					spacing={1}
					sx={{
						p: 1.5,
						borderRadius: "10px",
						bgcolor: "background.default",
						...(timeState === "active" && {
							background: `linear-gradient(87.53deg, rgba(229, 177, 41, 0.2) 0.94%, ${theme.palette.background.default} 89.48%)`
						}),
						...(timeState === "upcoming" && {
							bgcolor: "background.nav"
						}),
						...(timeState === "expired" && {
							bgcolor: "background.nav"
						})
					}}
				>
					<Stack spacing={0.5} direction="row" alignItems="center">
						<CircleIcon
							sx={{
								fontSize: 18,
								color: importing ? "warning.dark" : "warning.main"
							}}
						/>

						<Typography
							sx={{
								"& span": {
									color: importing ? "warning.dark" : "warning.main",
									fontWeight: 500
								}
							}}
						>
							<span>{formatTokenAmount(data.prizePool)}</span>{" "}
							{data.prizeCurrency}
						</Typography>

						<Box sx={{ flex: 1 }} />

						{type === "contests" &&
							["active", "upcoming"].includes(timeState) && (
								<Chip
									size="small"
									label={timeState === "upcoming" ? "Upcoming" : "Live"}
									sx={{
										minWidth: 50,
										fontSize: theme.typography.body2.fontSize,
										fontWeight: timeState === "active" ? 600 : 500,
										...(timeState === "active" && {
											bgcolor: "success.main",
											color: "text.inverse"
										})
									}}
								/>
							)}

						{type === "contests" && expired && <Typography>Ended</Typography>}
					</Stack>

					{type === "contests" && !expired && (
						<Stack
							direction="row"
							alignItems="center"
							spacing={1}
							justifyContent="space-between"
						>
							<Typography>
								{timeState === "active"
									? "Time remaining:"
									: timeState === "upcoming"
									? "Starts in:"
									: ""}
							</Typography>

							<Typography>{timeRemainingString}</Typography>
						</Stack>
					)}
				</Stack>
			</Stack>
		</Button>
	);
};

export default ContestsBountiesCard;
