import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FieldValues, Resolver, useForm } from "react-hook-form";
import * as Yup from "yup";
import FormTextField from "../../formFields/FormTextField";

export interface EmailVerificationFormType {
	verification_code: string;
}

interface Props {
	handleSubmit: (data: EmailVerificationFormType) => void;
	disabled?: boolean;
}

const schema = Yup.object().shape({
	verification_code: Yup.string().required("Verification Code is required")
});

const EmailVerificationForm: React.FC<Props> = ({
	handleSubmit: propsHandleSubmit,
	disabled: PropsDisabled = false
}) => {
	const [disabled, setDisabled] = useState(PropsDisabled);

	useEffect(() => {
		setDisabled(PropsDisabled);
	}, [PropsDisabled]);

	const formOptions = { resolver: yupResolver(schema) as Resolver<EmailVerificationFormType, FieldValues> };

	const {
		handleSubmit,
		register,
		formState: { errors }
	} = useForm<FieldValues & EmailVerificationFormType & any>(formOptions);

	const onSubmit = (data: EmailVerificationFormType) => {
		// setDisabled(true);
		propsHandleSubmit?.(data);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
			<Stack spacing={3}>
				<FormTextField
					fullWidth
					disabled={disabled}
					required
					name="verification_code"
					placeholder="Verification Code"
					register={register}
					error={!!errors["verification_code"]}
					helperText={errors["verification_code"]?.message}
					sx={{ flex: 1 }}
					InputProps={{
						sx: { bgcolor: "background.paper" }
					}}
				/>

				<LoadingButton
					type="submit"
					variant="contained"
					disabled={disabled}
					loading={disabled}
					sx={{ height: 39 }}
				>
					Submit
				</LoadingButton>
			</Stack>
		</form>
	);
};

export default EmailVerificationForm;
