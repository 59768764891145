import { recordLogout } from "Resources/ServerInterface";
import PrivacyModeCodeNeededModal from "components/CustomModal/PrivacyModeCodeNeededModal";
import PrivacyModeSetupModal from "components/CustomModal/PrivacyModeSetupModal";
import SignInWithModal from "components/LogInSignUp/SignInWithModal";
import TutorialDialog from "components/TutorialDialog";
import { useAtom } from "jotai";
import React, { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import {
	feedbackModalOpenAtom,
	pocModalOpenAtom,
	privacyModeCodeNeededModalOpenAtom,
	privacyModeSetupModalOpenAtom,
	proModalOpenAtom
} from "store/atoms/UiAtoms";
import { LOCALSTORAGE_GITHUB_CONNECTION } from "utils/constants";
import { logout } from "../Resources/Auth";
import { usernameAtom } from "../atoms";
import FeedbackModal from "../components/CustomModal/FeedbackModal";
import PocModal from "../components/CustomModal/PocModal";
import ProModal from "../components/CustomModal/ProModal";

interface AppContextType {
	handleLogout: () => void;
}

const AppContext = createContext<AppContextType>({
	handleLogout: () => {}
});

export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({
	children
}) => {
	const [username, setUsername] = useAtom(usernameAtom);

	// Modal Atoms
	const [feedbackModalOpen, setFeedbackModalOpen] = useAtom(
		feedbackModalOpenAtom
	);
	const [pocModalOpen, setPocModalOpen] = useAtom(pocModalOpenAtom);
	const [privacyModeSetupModalOpen, setPrivacyModeSetupModalOpen] = useAtom(
		privacyModeSetupModalOpenAtom
	);
	const [privacyModeCodeNeededModalOpen, setPrivacyModeCodeNeededModalOpen] =
		useAtom(privacyModeCodeNeededModalOpenAtom);
	const [proModalOpen, setProModalOpen] = useAtom(proModalOpenAtom);

	const navigate = useNavigate();

	const handleLogout = () => {
		recordLogout().then(() => {
			logout();
			navigate("/");
			secureLocalStorage.removeItem("personalAccessToken");
			secureLocalStorage.removeItem("privateModeEncryptionKey");
			secureLocalStorage.removeItem("csrfToken");
			localStorage.removeItem(LOCALSTORAGE_GITHUB_CONNECTION);
			setUsername(null);
		});
	};

	return (
		<AppContext.Provider value={{ handleLogout }}>
			{children}

			{/* Feedback Modal */}
			<FeedbackModal
				open={feedbackModalOpen}
				handleClose={() => setFeedbackModalOpen(false)}
			/>

			{/* PoC Modal */}
			<PocModal
				open={pocModalOpen}
				handleClose={() => setPocModalOpen(false)}
			/>

			{/* Tutorial Modal */}
			<TutorialDialog />

			{/* Pro Modal */}
			<ProModal
				open={proModalOpen}
				handleClose={() => setProModalOpen(false)}
			/>

			{/* Private Mode Setup Modal */}
			<PrivacyModeSetupModal
				open={privacyModeSetupModalOpen}
				onClose={() => setPrivacyModeSetupModalOpen(false)}
			/>

			{/* Private Mode Key Needed Modal */}
			<PrivacyModeCodeNeededModal
				open={privacyModeCodeNeededModalOpen}
				onClose={() => setPrivacyModeCodeNeededModalOpen(false)}
			/>

			{/* Sign in/sign up modal */}
			{!username && <SignInWithModal />}
		</AppContext.Provider>
	);
};

export const useAppContext = () => useContext<AppContextType>(AppContext);
