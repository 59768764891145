import ProjectFile from "Resources/ProjectFile";
import { chatWithAI, writePocWithAIFromFile } from "Resources/ServerInterface";
import { mixpanelAtom } from "atoms";
import { useEditorTabs } from "hooks/ui/useEditorTabs";
import { useAtom, useSetAtom } from "jotai";
import { useCallback, useState } from "react";
import {
	aiMessagesAtom,
	randomnessValAtom,
	uniquenessValAtom,
	wordValAtom
} from "store/atoms/aiAtoms";
import {
	pocAIContentsAtom,
	pocAIGeneratingAtom,
	testPanelNameAtom
} from "store/atoms/testAtoms";
import { useTests } from "./useTests";
import EditorTab from "context/EditorTab";
import { useAtomCallback } from "jotai/utils";
import { editorTabsAtom } from "store/atoms/UiAtoms";
import { useRateLimits } from "./useRateLimits";
import { rateLimitAtoms } from "store/atoms/userAtoms";

let abortController: AbortController;

export function useAi() {
	const [mixpanel] = useAtom(mixpanelAtom);
	const setMessages = useSetAtom(aiMessagesAtom);

	// AI Test generation atoms
	const setPocAIContents = useSetAtom(pocAIContentsAtom);
	const setPocAIGenerating = useSetAtom(pocAIGeneratingAtom);
	const setPanelNameAtom = useSetAtom(testPanelNameAtom);

	const [responding, setResponding] = useState(false);

	const [randomnessVal] = useAtom(randomnessValAtom);
	const [uniquenessVal] = useAtom(uniquenessValAtom);
	const [wordVal] = useAtom(wordValAtom);

	const { findAndOpenTab, openCustomTab, changeOpenTabValues } =
		useEditorTabs();
	const { addNewTest, editTest } = useTests();

	const { updateOneRateLimit } = useRateLimits();

	const [rateLimits] = useAtom(rateLimitAtoms);

	const { current, limit, retryAfter, configName, remaining } = rateLimits["ai"] || {};

	const openTabs = useAtomCallback(
		useCallback((get) => {
			const curr = get(editorTabsAtom);
			return curr;
		}, [])
	);

	const loadUserAiSettings = () => {
		try {
			// Fetch then set the values
		} catch (error) {
			console.log(error);
		}
	};

	const handleAddMessages = (_messages, projectFilePath: string) => {
		setMessages((old) => ({
			...old,
			[projectFilePath]: [...(old?.[projectFilePath] || []), ..._messages]
		}));
	};

	const handleUpdateLastMessage = (_text, projectFilePath: string) => {
		setMessages((old) => {
			const _messages = old?.[projectFilePath];
			_messages[_messages.length - 1].content = _text;
			return {
				...old,
				[projectFilePath]: _messages
			};
		});
	};

	const sendChatWithAI = async (
		userMessage: string,
		prevMessages: any[],

		projectFilePath: string,
		openedFileId: string,
		projectId: string
	) => {
		setResponding(true);

		try {
			abortController = new AbortController();

			if (userMessage.length) {
				handleAddMessages(
					[
						{ role: "user", content: userMessage },
						{ role: "assistant", content: "" }
					],
					projectFilePath
				);

				const _messages = [
					...(prevMessages || []),
					{ role: "user", content: userMessage }
				];

				await chatWithAI(
					openedFileId,
					projectId,
					_messages,
					randomnessVal,
					uniquenessVal,
					wordVal,
					mixpanel,
					(text, done) => {
						handleUpdateLastMessage(text, projectFilePath);
						if (done) {
							setResponding(false);
						}
					},
					abortController
				);

				updateOneRateLimit("ai", {current: current + 1, limit, remaining, retryAfter, configName});
			}
		} catch (error) {
			console.log(error);
		} finally {
			setResponding(false);
		}
	};

	const clearAiChat = (projectFilePath) => {
		setMessages((old) => ({
			...old,
			[projectFilePath]: []
		}));
	};

	const handleStopResponding = () => {
		abortController?.abort?.();
	};

	const generateAITest = async (
		projectFile: ProjectFile,
		promptText: string
	) => {
		try {
			setPocAIGenerating(true);

			// set the file name
			const testName = projectFile?.name.split(".")[0];

			setPanelNameAtom(testName);

			// Create new blank test
			const newTestRes = await addNewTest(testName, "Generating test ...");

			if (newTestRes._id) {
				const { originFile, projectID, testName, _id } = newTestRes;

				// Open new tab
				const tabPath = `/custom-test/${_id}`;
				findAndOpenTab(tabPath, () =>
					openCustomTab(
						null,
						testName,
						tabPath,
						new ProjectFile(testName, null, tabPath, null),
						null,
						{ tabPath, aiGenerating: true }
					)
				);

				// generate the AI test
				await writePocWithAIFromFile(
					{ fileId: projectFile.id, prompt: promptText },
					async (text, done) => {
						setPocAIContents(text);

						if (done) {
							// Edit test
							await editTest({
								projectID,
								testName,
								_id,
								originFile,
								testContents: text
							})
								.then((res) => {
									const tabName = testName;

									const tabIndex = openTabs().findIndex(
										(i) => i.path === tabPath
									);

									if (tabIndex > -1) {
										changeOpenTabValues(
											tabIndex,
											new EditorTab(
												new ProjectFile(tabName, null, tabPath, null, {
													...(res || {})
												}),
												tabName,
												tabPath
											)
										);
									}
								})
								.finally(() => {
									setPocAIGenerating(false);
									setPocAIContents("");
								});
						}
					}
				);
			}
		} catch (error) {
			console.log(error);
		}
	};

	return {
		loadUserAiSettings,
		responding,
		sendChatWithAI,
		handleStopResponding,
		clearAiChat,
		generateAITest
	};
}
