import {
	Box,
	Card,
	Stack,
	StackProps,
	Typography,
	useTheme
} from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";

const BulletPoint: React.FC<StackProps & { text: string }> = ({
	text,
	...other
}) => (
	<Stack direction="row" alignItems="center" spacing={1} {...other}>
		<CircleIcon sx={{ fontSize: 8 }} />
		<Typography>{text}</Typography>
	</Stack>
);

interface Props {
	title: string;
	price?: string;
	bullets: string[];
	selected?: boolean;
}

const PlanBox: React.FC<Props> = ({ title, price, bullets, selected }) => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				p: 3,
				width: "100%",
				borderRadius: "16px",
				border: `2px solid ${theme.palette.background["nav"]}`,
				...(selected && {
					border: `2px solid ${theme.palette.primary.main}`,
					background:
						"linear-gradient(232.03deg, rgba(29, 4, 82, 0.5) 21.86%, rgba(105, 9, 228, 0.5) 81.88%)"
				})
			}}
		>
			<Stack
				direction="row"
				spacing={2}
				justifyContent="space-between"
				sx={{ mb: 1.5 }}
			>
				<Typography variant="h6" sx={{ fontWeight: 600 }}>
					{title}
				</Typography>
				{price && (
					<Typography variant="h6" sx={{ fontWeight: 600 }}>
						{price}
					</Typography>
				)}
			</Stack>

			<Stack spacing={0.3}>
				{bullets.map((item: string, i) => (
					<BulletPoint key={i} text={item} />
				))}
			</Stack>
		</Box>
	);
};

export default PlanBox;
