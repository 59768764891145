import {
	Box,
	CssBaseline,
	IconButton,
	Stack,
	ThemeProvider,
	Typography
} from "@mui/material";
import darkTheme from "config/theme/dark";
import React from "react";
import hex from "utils/hexTransparency";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import lightTheme from "config/theme/light";

const BookmarkViewZone = ({
	title,
	tag,
	noteContent,
	tagColor: propsTagColor,
	handleViewNote,
	handleEditNote,
	handleDeleteNote,
	themeMode
}) => {
	const tagColorArr = propsTagColor.split("-");

	// @audit-
	return (
		<ThemeProvider theme={themeMode === "dark" ? darkTheme : lightTheme}>
			<CssBaseline />

			<Stack
				spacing={1}
				direction="row"
				alignItems="center"
				sx={(theme) => ({
					zIndex: 999,
					width: "fit-content",
					"&:hover": {
						"& .actionButtons": {
							opacity: 1
						}
					}
				})}
			>
				<Stack
					spacing={0.5}
					direction="row"
					alignItems="center"
					sx={(theme) => ({
						border: `1px solid ${
							theme.palette[tagColorArr[0]][tagColorArr[1]] ||
							theme.palette.primary.main
						}`,
						width: "fit-content",
						borderRadius: "8px",
						px: 1.5,
						py: 0.5,
						bgcolor: `${
							theme.palette[tagColorArr[0]][tagColorArr[1]] ||
							theme.palette.primary.main
						}${hex["10%"]}`
					})}
				>
					<BookmarkIcon
						fontSize="small"
						sx={(theme) => ({
							color: theme.palette[tagColorArr[0]][tagColorArr[1]]
						})}
					/>

					<Typography>{title}</Typography>
				</Stack>

				<Stack
					direction="row"
					alignItems="center"
					className="actionButtons"
					sx={{ opacity: themeMode === "dark" ? 0.1 : 0.3 }}
				>
					{!!noteContent && (
						<IconButton
							size="small"
							onClick={handleViewNote}
							sx={{
								height: 20,
								width: 22,
								opacity: themeMode === "dark" ? 0.6 : 0.8,
								"&:hover": { opacity: 1 }
							}}
						>
							<VisibilityIcon sx={{ fontSize: 16 }} />
						</IconButton>
					)}

					<IconButton
						size="small"
						onClick={handleEditNote}
						sx={{
							height: 20,
							width: 22,
							opacity: themeMode === "dark" ? 0.6 : 0.8,
							"&:hover": { opacity: 1 }
						}}
					>
						<EditIcon sx={{ fontSize: 16 }} />
					</IconButton>

					<IconButton
						size="small"
						onClick={handleDeleteNote}
						sx={{
							height: 20,
							width: 22,
							opacity: themeMode === "dark" ? 0.6 : 0.8,
							"&:hover": { opacity: 1 }
						}}
					>
						<DeleteIcon sx={{ fontSize: 16 }} />
					</IconButton>
				</Stack>
			</Stack>
		</ThemeProvider>
	);
};

export default BookmarkViewZone;
