import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import BookmarkIcon from "assets/icons/bookmark.png";
import hex from "utils/hexTransparency";

export const bookmarkStyles = (theme: Theme) => {
	const styles: SxProps = {
		"& .editor-notes-widget": {
			bgcolor: `${theme.palette.primary.dark}${hex["5%"]}`,
			"&.text-disabled": {
				bgcolor: `${theme.palette.text.disabled}${hex["5%"]}`
			},
			"&.info-light": {
				bgcolor: `${theme.palette.info.main}${hex["5%"]}`
			},
			"&.success-light": {
				bgcolor: `${theme.palette.success.main}${hex["5%"]}`
			},
			"&.error-light": {
				bgcolor: `${theme.palette.error.main}${hex["5%"]}`
			},
			"&.warning-light": {
				bgcolor: `${theme.palette.warning.main}${hex["5%"]}`
			},
			"&.primary-main": {
				bgcolor: `${theme.palette.primary.main}${hex["5%"]}`
			}
		},
		"& .glyph-notes": {
			cursor: "pointer",
			borderRadius: "50%",
			left: "5px !important",
			backgroundImage: `url(${BookmarkIcon})`,
			backgroundSize: "contain",
			backgroundPosition: "center",
			"&.text-disabled": {
				filter:
					"invert(52%) sepia(8%) saturate(9%) hue-rotate(336deg) brightness(95%) contrast(88%)"
			},
			"&.info-light": {
				filter:
					"invert(67%) sepia(99%) saturate(5084%) hue-rotate(193deg) brightness(101%) contrast(101%)"
			},
			"&.success-light": {
				filter:
					"invert(55%) sepia(78%) saturate(438%) hue-rotate(107deg) brightness(100%) contrast(92%)"
			},
			"&.error-light": {
				filter:
					"invert(47%) sepia(13%) saturate(2563%) hue-rotate(314deg) brightness(100%) contrast(100%)"
			},
			"&.warning-light": {
				filter:
					"invert(81%) sepia(58%) saturate(527%) hue-rotate(337deg) brightness(300%) contrast(103%)"
			},
			"&.primary-main": {
				filter:
					"invert(48%) sepia(66%) saturate(3110%) hue-rotate(226deg) brightness(99%) contrast(83%)"
			}
		}
	};

	return styles;
};

export const noteColor = (theme: Theme, _color) => {
	switch (_color) {
		case "text-disabled":
			return `${theme.palette.text.disabled}${hex["30%"]}`;
		case "info-light":
			return `${theme.palette.info.main}${hex["30%"]}`;
		case "success-light":
			return `${theme.palette.success.main}${hex["30%"]}`;
		case "warning-light":
			return `${theme.palette.warning.main}${hex["30%"]}`;
		case "error-light":
			return `${theme.palette.error.main}${hex["30%"]}`;
		case "primary-main":
			return `${theme.palette.primary.main}${hex["30%"]}`;

		default:
			return `${theme.palette.info.main}${hex["30%"]}`;
	}
};
