import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import FlagIcon from "@mui/icons-material/Flag";
import {
	Box,
	CircularProgress,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Typography
} from "@mui/material";
import DeleteCtfModal from "components/CustomModal/DeleteCtfModal";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { projectsAtom, projectsLoadedAtom } from "store/atoms/projectToolAtoms";

const SelectCtf = () => {
	const [selected] = useState(null);
	const [deleteModal, setDeleteModal] = useState<null | string>(null);

	const [projectsLoaded] = useAtom(projectsLoadedAtom);

	const [projects] = useAtom(projectsAtom);

	const handleSelectCtf = async (ctfID) => {};

	const openDeleteModal = (
		projectID: string,
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		event?.stopPropagation?.();

		setDeleteModal(projectID);
	};

	if (projectsLoaded && !projects.length) {
		return (
			<Typography>No CTF's in account. Add a CTF to get started.</Typography>
		);
	}

	const CtfListItem = ({ project, completed = false }) => {
		return (
			<ListItem disablePadding disableGutters>
				<ListItemButton
					disableGutters
					disabled={!!selected}
					sx={{
						p: 1,
						borderRadius: "8px",
						"&:hover": {
							bgcolor: "background.default",
							color: "primary.main",
							"& .showOnHover": {
								display: "inline-flex"
							},
							"& .hideOnHover": {
								display: "none"
							}
						}
					}}
				>
					<ListItemIcon
						sx={{
							color: completed ? "success.main" : "primary.main",
							minWidth: 0,
							mr: 1
						}}
					>
						<FlagIcon />
					</ListItemIcon>

					<ListItemText
						primary={project.name}
						sx={{ overflowWrap: "break-word" }}
					/>

					{selected === project.ID && (
						<ListItemSecondaryAction sx={{ pr: 1 }}>
							<CircularProgress size={15} />
						</ListItemSecondaryAction>
					)}

					<ListItemSecondaryAction sx={{ pr: 1 }}>
						<Typography className="hideOnHover">3/5</Typography>

						<IconButton
							size="small"
							onClick={(e) => openDeleteModal(project.ID, e)}
							className="showOnHover"
							sx={{ display: "none" }}
						>
							<DeleteForeverOutlinedIcon sx={{ fontSize: 18 }} />
						</IconButton>
					</ListItemSecondaryAction>
				</ListItemButton>
			</ListItem>
		);
	};

	return (
		<Box sx={{ mt: 3 }}>
			{/* Active List */}
			<Typography sx={{ fontWeight: 600 }}>Active</Typography>

			<List>
				{projects.map((project, i) => (
					<CtfListItem project={project} key={i} />
				))}
			</List>

			<Divider sx={{ my: 1.5 }} />

			{/* Completed List */}
			<Typography sx={{ fontWeight: 600 }}>Completed</Typography>

			<List>
				{projects.map((project, i) => (
					<CtfListItem project={project} completed key={i} />
				))}
			</List>

			{/* Delete CTF Modal */}
			<DeleteCtfModal
				open={!!deleteModal}
				onClose={() => setDeleteModal(null)}
				ctfId={deleteModal}
			/>
		</Box>
	);
};

export default React.memo(SelectCtf);
