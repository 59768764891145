import { isFileMd } from "Resources/Helpers";
import ProjectFile from "Resources/ProjectFile";
import ProjectImport from "Resources/ProjectImport";
import { graphFile } from "Resources/ServerInterface";
import { mixpanelAtom } from "atoms";
import EditorTab, { EditorTabType } from "context/EditorTab";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
	editorTabsAtom,
	focusedEditorTabIndexAtom,
	openedTabAtom,
	selectedToolAtom
} from "store/atoms/UiAtoms";

export const getOpenedTabType = (
	fileName: string,
	filePath: string,
	isTestFile: boolean
): EditorTabType => {
	if (!fileName || !filePath) return null;

	if (isTestFile) return "test";

	if (isFileMd(fileName)) return "markdown";

	if (filePath.endsWith(".pdf")) return "pdf";

	if (filePath.includes("/graph-")) return "graph";

	if (filePath.includes("/missing-import")) return "import";

	if (filePath.includes("/whiteboard")) return "whiteboard";

	if (filePath.includes("/report")) return "report";

	if (filePath.includes("/custom-test")) return "customTest";

	if (filePath.includes("/test-trace")) return "testTrace";

	if (filePath.includes("/function-explorer")) return "functionExplorer";

	return "editor";
};

export function useEditorTabs() {
	const navigate = useNavigate();

	const [mixpanel] = useAtom(mixpanelAtom);
	const [{ openedFile, projectFilePath }] = useAtom(openedTabAtom);
	const setFocusedTabIndex = useSetAtom(focusedEditorTabIndexAtom);
	const setSelectedTool = useSetAtom(selectedToolAtom);
	const setOpenTabs = useSetAtom(editorTabsAtom);

	const openTabs = useAtomCallback(
		useCallback((get) => {
			const curr = get(editorTabsAtom);
			return curr;
		}, [])
	);

	const focusedTabIndex = useAtomCallback(
		useCallback((get) => {
			const curr = get(focusedEditorTabIndexAtom);
			return curr;
		}, [])
	);

	const findAndOpenTab = (
		_path: string,
		elseCallback?: any,
		callback?: any
	) => {
		const foundIndex = openTabs().findIndex(
			(editorTab) => editorTab.path === _path
		);

		if (foundIndex >= 0) {
			setFocusedTabIndex(foundIndex);
		} else {
			elseCallback?.();
		}

		callback?.();
	};

	const findAndCloseTab = (
		openTabs: EditorTab[],
		_path: string,
		elseCallback?: any,
		callback?: any
	) => {
		const foundIndex = openTabs.findIndex(
			(editorTab) => editorTab.path === _path
		);

		if (foundIndex >= 0) {
			closeFile(foundIndex);
		} else {
			elseCallback?.();
		}

		callback?.();
	};

	const closeFiles = (indexes: number[]) => {
		setOpenTabs((old) => {
			const updatedTabs = old.filter((_, i) => !indexes.includes(i));

			if (updatedTabs.length > 0) {
				setFocusedTabIndex(0);
			}

			return updatedTabs;
		});
	};

	const closeFile = (index: number) => {
		setOpenTabs((old) => {
			const updatedTabs = old.filter((_, i) => i !== index);

			// If the closed tab was the focused tab, update the focusedTabIndex
			if (focusedTabIndex() === index) {
				// Determine the index of the previous tab
				const newFocusedTabIndex = Math.max(index - 1, 0);
				setFocusedTabIndex(newFocusedTabIndex);
			} else if (index < focusedTabIndex()) {
				// If the closed tab was before the focused tab, we need to adjust the focusedTabIndex
				setFocusedTabIndex((prevFocusedIndex) => prevFocusedIndex - 1);
			}

			return updatedTabs;
		});
	};

	const closeAllTabs = () => {
		setOpenTabs([]);
		setFocusedTabIndex(null);
	};

	const closeAllOtherTabs = (index: number) => {
		setOpenTabs((old) => {
			const filtered = old.filter((_, i) => i === index);
			return filtered;
		});
		setFocusedTabIndex(0);
	};

	const openCustomTab = (
		contents: JSX.Element | string,
		name: string,
		path: string,
		projectFile?: ProjectFile,
		type?: EditorTabType,
		metadata?: object
	) => {
		let length = 0;

		setOpenTabs((old) => {
			const newOpenProjectFiles = old.concat(
				new EditorTab(
					projectFile ||
						(typeof contents === "string"
							? new ProjectFile(name, contents, path, null)
							: contents),
					name,
					path,
					type,
					metadata
				)
			);

			length = newOpenProjectFiles?.length;

			return newOpenProjectFiles;
		});

		setTimeout(() => {
			setFocusedTabIndex(length - 1);
		}, 100);
	};

	const changeOpenTabValues = (indexToChange: number, data: EditorTab) => {
		setOpenTabs((old) => {
			if (indexToChange < 0 || indexToChange > old.length - 1) return;

			const copiedState = [...old];

			copiedState[indexToChange] = data;

			return copiedState;
		});
	};

	const changeOpenTabName = (
		indexToChange: number,
		newName: string,
		metadata?: any
	) => {
		setOpenTabs((old) => {
			if (indexToChange < 0 || indexToChange > old.length - 1) return;

			const copiedState = [...old];

			copiedState[indexToChange] = {
				...copiedState[indexToChange],
				name: newName,
				metadata: {
					...(copiedState[indexToChange]?.metadata || {}),
					...metadata
				}
			};

			return copiedState;
		});
	};

	const openGraphFile = async (mode: string) => {
		const response: string = await graphFile(
			mixpanel,
			openedFile?.id,
			mode,
			openedFile instanceof ProjectImport ? "true" : "false"
		);

		const tabPath = openedFile?.id + "/graph-" + mode;
		const tabName = `${mode.charAt(0).toUpperCase() + mode.slice(1)} Graph - ${
			openedFile?.name
		}`;

		openCustomTab(
			null,
			tabName,
			tabPath,
			new ProjectFile(tabName, response, tabPath, openedFile?.ast, {
				code: openedFile?.text,
				fileName: openedFile?.name,
				codeFilePath: projectFilePath
			})
		);

		setSelectedTool(null);
	};

	return {
		findAndOpenTab,
		findAndCloseTab,
		closeFile,
		closeFiles,
		openCustomTab,
		openGraphFile,
		changeOpenTabValues,
		changeOpenTabName,
		closeAllTabs,
		closeAllOtherTabs
	};
}
