import { Icon } from "@iconify/react";
import CircleIcon from "@mui/icons-material/Circle";
import Error from "@mui/icons-material/Error";
import { TreeItem, TreeView, TreeViewProps, treeItemClasses } from "@mui/lab";
import {
	Box,
	IconButton,
	Stack,
	Theme,
	Tooltip,
	Typography,
	styled,
	tooltipClasses,
	useTheme
} from "@mui/material";
import { getFileExtension } from "Resources/Helpers";
import ProjectFolder from "Resources/ProjectFolder";
import ShieldIconDark from "assets/icons/privacyShieldIconDark.png";
import ShieldIconLight from "assets/icons/privacyShieldIconLight.png";
import ResolveMissingImport from "components/ResolveMissingImport";
import { useProject } from "hooks/data/useProject";
import { useEditorTabs } from "hooks/ui/useEditorTabs";
import { useAtom } from "jotai";
import path from "path";
import React, { useEffect, useMemo, useState } from "react";
import { expandedProjectTreeAtom, openedTabAtom } from "store/atoms/UiAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import { testFilesAtom } from "store/atoms/testAtoms";
import hex from "utils/hexTransparency";
import CollapseAllButton from "./CollapseAllButton";
import {
	addMissingImportsToNewProjectTree,
	folderHasMissingFile
} from "./helpers";

interface CustomTreeViewProps {
	showUnloadedFiles: boolean;
}

const StyledTreeItem = styled(TreeItem)(({ theme }) => {
	const themeMode = theme.palette.mode;

	return {
		"& .MuiTreeItem-content .MuiTreeItem-label": {
			fontWeight: "inherit",
			color: "inherit"
		},
		"& .MuiTreeItem-root": {
			position: "relative",
			"&:last-of-type": {
				"&:before": {
					height: 16
				}
			},
			"&:before": {
				content: '""',
				display: "block",
				position: "absolute",
				left: 3,
				height: "100%",
				width: 2,
				backgroundColor:
					themeMode === "dark"
						? `${theme.palette.primary.main}${hex["30%"]}`
						: `${theme.palette.primary.main}${hex["50%"]}!important`
			}
		},
		"& .MuiTreeItem-group": {
			// marginLeft: 10
		},
		[`& .${treeItemClasses.content}`]: {
			position: "relative",
			paddingTop: theme.spacing(0.5),
			paddingBottom: theme.spacing(0.5),
			borderTopRightRadius: theme.spacing(1),
			borderBottomRightRadius: theme.spacing(1),
			color: theme.palette.text.secondary,
			marginLeft: theme.spacing(0.5),
			zIndex: 1,
			border: "none",
			backgroundColor: "transparent",
			borderRadius: "8px",
			width: "98%",
			"&.Mui-expanded": {
				color: theme.palette.text.primary
			},
			"&.Mui-selected, &.Mui-focused": {
				backgroundColor: "transparent",
				backgroundImage:
					themeMode === "dark"
						? `linear-gradient(89.86deg, ${theme.palette.background["paper2"]}${hex["30%"]} 21.46%, ${theme.palette.background["paper2"]} 120.55%)`
						: `linear-gradient(270deg, ${theme.palette.background["selected"]} 4.78%, rgba(189, 174, 248, 0) 87.8%)`
			},
			".MuiTreeItem-iconContainer": {
				// display: "none"
				width: 0
			},
			"&.Mui-focused": {
				backgroundImage: "none",
				background: "none"
			},
			"&.Mui-selected": {
				background:
					themeMode === "dark"
						? "linear-gradient(89.86deg, transparent 15.46%, #393761 120.55%)"
						: `linear-gradient(270deg, ${theme.palette.background["selected"]} 4.78%, rgba(189, 174, 248, 0) 87.8%)`,
				color:
					themeMode === "dark"
						? `${theme.palette.success.main} !important`
						: `${theme.palette.success.dark} !important`,
				fontWeight: 500,
				"& .MuiSvgIcon-root.blur": {
					color:
						themeMode === "dark"
							? `${theme.palette.success.main} !important`
							: `${theme.palette.success.dark} !important`,
					fontWeight: 500,
					filter: "blur(2px)"
				},
				".boxLine": {
					background:
						themeMode === "dark"
							? "linear-gradient(90.13deg, #49407F 2.38%, #1EC78D 95.41%)"
							: `linear-gradient(90.13deg, ${theme.palette.success.dark} 2.38%, #1EC78D 95.41%)`
				}
			},
			"&:hover": {
				background:
					themeMode === "dark"
						? "linear-gradient(89.86deg, transparent 0%, #393761 120.55%)"
						: `linear-gradient(270deg, ${theme.palette.background["selected"]} 4.78%, rgba(189, 174, 248, 0) 87.8%)`
			}
		}
	};
});

// Folder
const FolderIconLabel: React.FC<{
	folderPath?: string;
	open?: boolean;
	label: string;
	isSubNode?: boolean;
	isMissingImport?: boolean;
}> = ({ open, label, isSubNode, isMissingImport, folderPath }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [project] = useAtom(projectAtom);

	const isContractSourceFolder = useMemo(() => {
		return (
			folderPath.slice(folderPath.indexOf("/") + 1) ===
			path.join(project.configRootPath, project.contractSourcePath)
		);
	}, [folderPath]);

	const subProjectIcon = useMemo(() => {
		// Subproject folders
		return (
			(project.subProjects.some(
				(sp) =>
					sp.configRootPath === folderPath.slice(folderPath.indexOf("/") + 1)
			) &&
				"mdi:folder-download") ||
			// Base project folder
			(folderPath.slice(folderPath.indexOf("/") + 1 || folderPath.length) ===
				project.configRootPath &&
				"material-symbols:folder") ||
			// Contract source folder
			(isContractSourceFolder && "material-symbols:topic") ||
			// Other folders
			"material-symbols:folder"
		);
	}, [project, folderPath, isContractSourceFolder]);

	return (
		<Stack
			direction="row"
			alignItems="center"
			spacing={1}
			sx={{
				":hover": {
					"& .showOnHover": { display: "inline-flex" }
				}
			}}
		>
			{/* Line */}
			{isSubNode && (
				<Box
					className="boxLine"
					sx={{
						width: "12px!important",
						height: "2px",
						bgcolor:
							themeMode === "dark"
								? `${theme.palette.primary.main}${hex["30%"]}`
								: `${theme.palette.primary.main}${hex["50%"]}`,
						ml: "-8px!important",
						mr: "-11px!important",
						flex: "none"
					}}
				/>
			)}

			{/* Folder icons */}
			<IconButton sx={{ p: 0 }} disableRipple>
				{open ? (
					<Icon
						icon="mdi:folder-outline"
						fontSize={18}
						color={
							isContractSourceFolder
								? // Contract source folder
								  theme.palette.success.main
								: // Other folders
								  theme.palette.primary.main
						}
					/>
				) : (
					<Icon
						icon={subProjectIcon}
						fontSize={18}
						color={
							isContractSourceFolder
								? // Contract source folder
								  theme.palette.success.main
								: // Other folders
								  theme.palette.primary.main
						}
					/>
				)}
			</IconButton>

			<Stack direction="row" alignItems="center" spacing={1}>
				{/* Folder name */}
				<Typography
					sx={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						textWrap: "nowrap",
						color: isContractSourceFolder
							? // Contract source folder
							  theme.palette.success.main
							: // Other folders
							  theme.palette.text.primary
					}}
				>
					{label}
				</Typography>

				{/* Library */}
				{project.subProjects.some(
					(sp) =>
						sp.configRootPath === folderPath.slice(folderPath.indexOf("/") + 1)
				) && (
					<Typography
						sx={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							textWrap: "nowrap",
							// flex: 1,
							color: theme.palette.text.disabled
						}}
					>
						(library)
					</Typography>
				)}
			</Stack>

			{/* Collapse button */}
			{!isSubNode && <CollapseAllButton folderPath={folderPath} />}

			{/* Missing import icon */}
			{isMissingImport && (
				<Error
					sx={{
						fontSize: 18,
						color: themeMode === "dark" ? "warning.main" : "warning.dark"
					}}
				/>
			)}

			{/* Private mode */}
			{!isSubNode && project.privateMode && label === project.name && (
				<Tooltip title="Private mode - Findings and notes are end-to-end encrypted">
					<Box
						component="img"
						src={themeMode === "dark" ? ShieldIconDark : ShieldIconLight}
						sx={{ height: 18, objectFit: "contain" }}
					/>
				</Tooltip>
			)}
		</Stack>
	);
};

// Add file we want to hide here
const hiddenFileTypes = [
	"jpg",
	"jpeg",
	"png",
	"gif",
	"bmp",
	"svg",
	"webp",
	"pdf"
];

// All files/folder
const getProjectFolderAsTreeItem = (
	theme: Theme,
	folder: ProjectFolder,
	expandedFolders: string[],
	showUnloadedFiles = false,
	basePath: string = "",
	isSubNode = false,
	missingPaths: string[] = [],
	testFileIds: string[] = []
) => {
	const themeMode = theme.palette.mode;

	let thisPath = path.join(basePath, folder.name);
	const foldersToConsolidate = [folder.name];

	// Consolidate paths of folders with only one folder and no files
	while (folder.folders.length === 1 && folder.files.length === 0) {
		thisPath = path.join(thisPath, folder.folders[0].name);
		foldersToConsolidate.push(folder.folders[0].name);
		folder = folder.folders[0];
	}

	// Shorten long consolidated names to [0]/.../[n-1]
	const folderName =
		foldersToConsolidate.length > 2
			? path.join(
					foldersToConsolidate[0],
					"...",
					foldersToConsolidate[foldersToConsolidate.length - 1]
			  )
			: path.join(...foldersToConsolidate);

	const hasMissingFile = folderHasMissingFile(thisPath, missingPaths);

	return (
		<StyledTreeItem
			key={thisPath}
			nodeId={thisPath}
			label={
				<Box sx={{ ml: -1, borderRadius: 10 }}>
					<FolderIconLabel
						folderPath={thisPath}
						label={folderName}
						open={expandedFolders?.includes?.(thisPath)}
						isSubNode={isSubNode}
						isMissingImport={hasMissingFile}
					/>
				</Box>
			}
		>
			{folder.folders.map((f) => {
				return getProjectFolderAsTreeItem(
					theme,
					f,
					expandedFolders,
					showUnloadedFiles,
					thisPath,
					true,
					missingPaths,
					testFileIds
				);
			})}

			{folder.files.map((file) => {
				const thisFilePath = path.join(thisPath, file.name);

				const fileExtension = getFileExtension(file.name).replace(".", "");

				const notClickable =
					file.text === null || hiddenFileTypes?.includes?.(fileExtension);

				const isFileMissing = file.id === "-1";

				const isTestFile = testFileIds?.includes(file.id);

				// Files
				return (
					<StyledTreeItem
						hidden={!showUnloadedFiles && notClickable}
						disabled={notClickable}
						key={thisFilePath}
						nodeId={
							isFileMissing
								? `${file.name}#missing#${thisFilePath}`
								: thisFilePath
						}
						label={
							<Tooltip
								title={`${thisPath}/${file.name}`}
								placement="right"
								enterDelay={1500}
								enterNextDelay={700}
								slotProps={{
									tooltip: {
										sx: {
											[`&.${tooltipClasses.tooltip}`]: {
												border: `1px solid ${theme.palette.primary.dark}`,
												borderRadius: "8px",
												bgcolor: "background.paper",
												maxWidth: 250
											}
										}
									}
								}}
							>
								<Box sx={{ ml: -1, borderRadius: 10 }}>
									<Stack direction="row" alignItems="center" spacing={1}>
										<Stack direction="row" alignItems="center">
											<Box
												className="boxLine"
												sx={{
													width: 22,
													height: "2px",
													bgcolor:
														themeMode === "dark"
															? "#443A77"
															: `${theme.palette.primary.main}${hex["50%"]}`,
													ml: -1
												}}
											/>
											<CircleIcon
												className="blur"
												sx={{
													height: 10,
													width: 10,
													ml: -1,
													color: themeMode === "dark" ? "#443A77" : "#B4A8E3"
												}}
											/>
										</Stack>

										<Stack direction="row" alignItems="center" spacing={0.5}>
											<Typography
												sx={{
													textWrap: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
													flex: 1
												}}
											>
												{file.name}
											</Typography>

											{isTestFile && (
												<Icon icon="heroicons:beaker-20-solid" fontSize={14} />
											)}
										</Stack>

										{isFileMissing && (
											<Error
												sx={{
													fontSize: 18,
													color:
														themeMode === "dark"
															? "warning.main"
															: "warning.dark"
												}}
											/>
										)}
									</Stack>
								</Box>
							</Tooltip>
						}
						sx={{
							[`& .${treeItemClasses.content}`]: {
								"&:hover": {
									...(notClickable && { cursor: "default", background: "none" })
								}
							}
						}}
					/>
				);
			})}
		</StyledTreeItem>
	);
};

const CustomTreeView: React.FC<CustomTreeViewProps & TreeViewProps> = ({
	showUnloadedFiles
}) => {
	const theme = useTheme();

	const { openCustomTab } = useEditorTabs();

	const [{ projectFilePath }] = useAtom(openedTabAtom);

	const { openProjectFile } = useProject();

	const [project] = useAtom(projectAtom);
	const [expandedPaths, setExpandedPaths] = useAtom(expandedProjectTreeAtom);
	const [projectTree, setProjectTree] = useState<{
		rootFolder: ProjectFolder;
		importsFolder: ProjectFolder;
	}>({
		rootFolder: null,
		importsFolder: null
	});

	const [testResults] = useAtom(testFilesAtom);
	const testFileIds = testResults?.map((item) => item.originFile) || [];

	useEffect(() => {
		if (!!project.missingImports?.length) {
			const newProjectTree = addMissingImportsToNewProjectTree(
				project.importsFolder,
				project.missingImports
			);

			setProjectTree({
				rootFolder: project.rootFolder,
				importsFolder: newProjectTree
			});
		} else {
			setProjectTree({
				rootFolder: project.rootFolder,
				importsFolder: project.importsFolder
			});
		}

		return () => {
			setProjectTree({
				rootFolder: null,
				importsFolder: null
			});
		};
	}, [project]);

	useEffect(() => {
		const folderPath = projectFilePath?.substring(
			0,
			projectFilePath.lastIndexOf("/")
		);

		if (
			!expandedPaths?.includes(folderPath) ||
			!expandedPaths?.includes(project?.name)
		) {
			setExpandedPaths((old) => {
				const paths = Array.from(new Set([...old, folderPath, project?.name]));

				return paths;
			});
		}
	}, [projectFilePath]);

	if (!projectTree.rootFolder) return null;

	const missingImports = project.missingImports.map(
		(i) => `${project.importsFolder.name}/${i}`
	);

	return (
		<Box
			sx={{
				ml: -1,
				minWidth: 230,
				height: "100%",
				overflowY: "scroll",
				flex: 1
			}}
		>
			<TreeView
				expanded={expandedPaths}
				selected={projectFilePath || ""}
				onNodeSelect={(event, nodeId) => {
					if (nodeId.includes("#missing#")) {
						const [_name, _path] = nodeId.split("#missing#");

						openCustomTab(
							<ResolveMissingImport fileName={_name} filePath={_path} />,
							`${_name} (Resolve Import)`,
							_path + "/missing-import"
						);
					} else {
						openProjectFile(nodeId);
					}
				}}
				onNodeToggle={(event, nodeIds) => {
					const paths = Array.from(new Set(nodeIds));
					setExpandedPaths(paths);
				}}
			>
				{getProjectFolderAsTreeItem(
					theme,
					projectTree.rootFolder,
					expandedPaths,
					showUnloadedFiles,
					"",
					false,
					missingImports,
					testFileIds
				)}

				{/* Imports */}
				{(!!projectTree.importsFolder?.files?.length ||
					!!projectTree.importsFolder?.folders?.length) &&
					getProjectFolderAsTreeItem(
						theme,
						projectTree.importsFolder,
						expandedPaths,
						showUnloadedFiles,
						"",
						false,
						missingImports,
						testFileIds
					)}
			</TreeView>
		</Box>
	);
};

export default React.memo(CustomTreeView);
