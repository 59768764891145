import { Box, useTheme } from "@mui/material";
import { Editor } from "@toast-ui/react-editor";
import { useLocalStorage } from "hooks/ui/useLocalStorage";
import useSetAtom from "hooks/useSetAtom";
import { useRef } from "react";
import { stickyNoteOpenAtom } from "store/atoms/stickyNoteAtoms";
import { STORAGE_STICKY_NOTE_VALUE } from "utils/constants";

const StickyNoteField = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const setOpen = useSetAtom(stickyNoteOpenAtom);

	const [value, setValue] = useLocalStorage(STORAGE_STICKY_NOTE_VALUE, "");

	const editorRef = useRef(null);

	const handleTextChange = (editorType: any) => {
		const _val = editorRef?.current?.editorInst?.getMarkdown();
		setValue(_val);
	};

	return (
		<Box
			sx={{
				height: "92%",
				"& .toastui-editor-defaultUI": {
					borderRadius: "8px",
					borderColor: "transparent"
				},
				"& .toastui-editor-md-container": {
					bgcolor: `transparent !important`
				},
				"& .toastui-editor-mode-switch, & .toastui-editor-md-tab-container": {
					display: "none !important"
				},
				"& .toastui-editor-toolbar": {
					height: 32
				},
				"& .toastui-editor-defaultUI-toolbar": {
					borderTopRightRadius: "0px",
					borderTopLeftRadius: "0px !important",
					bgcolor: "transparent",
					borderBottomColor: "divider",
					px: 0.5,
					height: 32,
					"& button": {
						transform: "scale(0.7)",
						margin: 0,
						border: "0px !important",
						width: 30
					}
				},
				"& .toastui-editor-toolbar-divider": {
					margin: "7px !important"
				},
				"& .ProseMirror": {
					height: "100%",
					px: 1.5,
					py: 1
				}
			}}
		>
			<Editor
				toolbarItems={[
					["heading", "bold", "italic", "strike"],
					["ul", "ol", "task", "indent", "outdent"]
				]}
				initialEditType="markdown"
				useCommandShortcut
				onChange={handleTextChange}
				usageStatistics={false}
				ref={editorRef}
				theme={themeMode}
				initialValue={value}
				autofocus={true}
				onKeydown={(_, event) => {
					if (event.ctrlKey && event.shiftKey && event.code === "Slash") {
						setOpen(false);
					}
				}}
			/>
		</Box>
	);
};

export default StickyNoteField;
