import {
	RateLimitConfigNames,
	RateLimitSummaryType,
	getRateLimitSummaryForUser
} from "Resources/ServerInterfaceProfile";
import useSetAtom from "hooks/useSetAtom";
import { rateLimitAtoms } from "store/atoms/userAtoms";

export function useRateLimits() {
	const setRateLimits = useSetAtom(rateLimitAtoms);

	const loadUserRateLimit = async () => {
		try {
			const testData = await getRateLimitSummaryForUser();

			const transformedData = testData.reduce((acc, item) => {
				acc[item.configName] = item;
				return acc;
			}, {});

			setRateLimits(transformedData);
		} catch (error) {
			console.log(error);
		}
	};

	const updateOneRateLimit = (
		name: RateLimitConfigNames,
		data: RateLimitSummaryType
	) => {
		setRateLimits((old) => {
			return { ...old, [name]: data };
		});
	};

	return { loadUserRateLimit, updateOneRateLimit };
}
