import {
	Box,
	MenuItem,
	Select,
	Stack,
	SvgIcon,
	Typography,
	useTheme
} from "@mui/material";
import { ReactComponent as LockIcon } from "../../../assets/icons/lock.svg";
import { HATS_SEVERITY_OPTIONS } from "../Tools/AddFindingsTool/helpers";

const SubmissionItem = ({
	getSubmissionField,
	submissionItem,
	setSubmissionItem
}) => {
	const theme = useTheme();
	// const fileInputRef = useRef(null);

	// const handleFileUpload = (e) => {
	//     const file = e.target.files[0];

	//     if (file) {
	//       const reader = new FileReader();

	//       reader.onloadend = () => {
	//         const result = reader.result as string; // Explicitly cast to a string
	//         const base64String = result.split(',')[1]; // Extracting base64 data
	//         const combinedString = `${file.type}|${base64String}`; // Combine type and base64 with a delimiter
	//         console.log(combinedString);
	//       };

	//       reader.readAsDataURL(file);
	//     }
	//   };

	//   const handleCustomButtonClick = () => {
	//     // Trigger the file input click event when the custom button is clicked
	//     fileInputRef.current.click();
	// };

	return (
		<Box
			sx={{
				borderRadius: "10px",
				border: `1px solid ${theme.palette.primary.dark}`,
				bgcolor: theme.palette.background.default
			}}
		>
			{/* Top */}
			<Stack
				direction="row"
				alignItems="center"
				sx={{ px: 2, borderBottom: `1px solid ${theme.palette.primary.dark}` }}
			>
				<Typography variant="body1" sx={{ py: 2, fontWeight: 600 }}>
					Submitting to Hats Finance
				</Typography>

				<Box sx={{ flex: 1 }} />

				<SvgIcon sx={{ fontSize: 30, mt: 1.1 }}>
					<LockIcon />
				</SvgIcon>
				<Typography variant="body1" sx={{ py: 2, fontWeight: 600 }}>
					Encrypted Submission
				</Typography>
			</Stack>

			{/* Middle */}
			<Box sx={{ p: 2, pt: 1.5 }}>
				<Stack direction="row" spacing={2} sx={{ width: "100%", mt: 1, mb: 4 }}>
					{getSubmissionField(
						"Issue title",
						(e) => {
							setSubmissionItem({
								...submissionItem,
								issueTitle: e.target.value
							});
						},
						false,
						submissionItem.issueTitle
					)}
					<Box sx={{ flex: 1 }} />
					<Stack sx={{ width: "200px" }} spacing={1}>
						<Typography>
							<strong>Severity</strong>
						</Typography>
						<Select
							variant="outlined"
							size="small"
							sx={{ height: 40, borderRadius: "8px" }}
							value={submissionItem.issueSeverity}
							onChange={(e) =>
								setSubmissionItem({
									...submissionItem,
									issueSeverity: Number(e.target.value)
								})
							}
						>
							{HATS_SEVERITY_OPTIONS.map((i) => ({
								name: i.name,
								value: i.value,
								Icon: <i.icon fontSize="small" sx={{ color: i.color }} />
							})).map((item) => (
								<MenuItem key={item.value} value={item.value}>
									<Stack direction="row" spacing={0.7} alignItems="center">
										{item.Icon}
										<Typography>{item.name}</Typography>
									</Stack>
								</MenuItem>
							))}
						</Select>
					</Stack>
				</Stack>
				<Box sx={{ mb: 1 }}>
					{getSubmissionField(
						"Description",
						(newValue) => {
							setSubmissionItem({
								...submissionItem,
								issueDescription: newValue
							});
						},
						true,
						submissionItem.issueDescription
					)}
				</Box>
				{/* File Upload */}
				{/* <input
                    type="file"
                    accept="*"
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                    ref={fileInputRef}
                />
                <Stack direction="row" spacing={2}>
                    <Stack sx={{width: '200px'}} spacing={1}>
                        <Typography><strong>File Uploads</strong></Typography>
                        <CustomButton
                            Icon={<></>}
                            text={"Proof of concept"}
                            onClick={handleCustomButtonClick}
                        />
                    </Stack>
                    <Stack sx={{width: '200px'}} spacing={1}>
                        <Typography>{`(optional)`}</Typography>
                        <CustomButton
                            Icon={<CheckOutlined />}
                            text={"Revised code file"}
                            onClick={() => {
                            }}
                        />
                    </Stack>
                </Stack> */}
			</Box>
		</Box>
	);
};

export default SubmissionItem;
