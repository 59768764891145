import { atom } from "jotai";

interface NotesTagType {
	name: string;
	tooltip?: string;
	color: string;
	_id?: string;
}

export const NOTES_TAG_ITEMS: NotesTagType[] = [
	{
		name: "Note",
		tooltip: "A general note",
		color: "info.light"
	},
	{
		name: "Issue",
		tooltip: "Potential threat or finding",
		color: "error.light"
	},
	{
		name: "Ok",
		tooltip: "Mitigation or no issue",
		color: "success.light"
	},
	{
		name: "Todo",
		tooltip: "Mark for revisit",
		color: "primary.main"
	},
	{
		name: "Info",
		tooltip: "Informational comment",
		color: "warning.light"
	},
	{
		name: "Ignored",
		tooltip: "Out of scope / won't review",
		color: "text.disabled"
	}
];

export const notesAtom = atom([]);
export const notesTagsAtom = atom<NotesTagType[]>(NOTES_TAG_ITEMS);
