import { Icon } from "@iconify/react";
import { Add as AddIcon } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListIcon from "@mui/icons-material/List";
import {
	Box,
	Button,
	Card,
	Collapse,
	IconButton,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import { WriteVulnContext, writePocWithAI } from "Resources/ServerInterface";
import { pocModalAtom } from "atoms";
import ConfirmationModal from "components/CustomModal/ConfirmationModal";
import { useFindings } from "hooks/data/useFindings";
import { useTools } from "hooks/ui/useTools";
import { useAtom, useSetAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { openedTabAtom, pocModalOpenAtom } from "store/atoms/UiAtoms";
import { findingToEditAtom, findingsAtom } from "store/atoms/findingsAtom";
import { projectAtom } from "store/atoms/projectToolAtoms";
import hex from "utils/hexTransparency";
import { ToolboxCloseButton } from "../..";
import { SEVERITY_OPTIONS, riskLevelToText } from "../AddFindingsTool/helpers";

const Item: React.FC<Finding & { IconSrc: any; color: string }> = ({
	description,
	severity,
	recommendation,
	source,
	name,
	location,
	_id,
	IconSrc,
	color
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const setFindingToEdit = useSetAtom(findingToEditAtom);
	const [, setPoc] = useAtom(pocModalAtom);

	const [project] = useAtom(projectAtom);
	const [open, setOpen] = useState(false);
	const [collapseOpen, setCollapseOpen] = useState(false);

	const [{ openedFile }] = useAtom(openedTabAtom);
	const { selectTool } = useTools();

	const { deleteFinding } = useFindings();

	const setPocModalOpen = useSetAtom(pocModalOpenAtom);

	const handleCollapse = () => {
		setCollapseOpen(!collapseOpen);
	};

	const handleOpenConfirmModal = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		setOpen(true);
	};

	const handleEditFindings = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		setFindingToEdit({
			title: name,
			description,
			severity,
			recommendation,
			source,
			location
		});
		selectTool("AddFinding", {
			editMode: true,
			_id
		});
	};

	const handleDelete = async () => {
		await deleteFinding(_id, project.id);
		setOpen(false);
	};

	const handleWritePocWithAI = async () => {
		setPocModalOpen(true);
		const [startLocation, endLocation] = getLinesFromLocation();

		setPoc({ findingTitle: name, content: "", loading: true });

		const writeVulnContext: WriteVulnContext = {
			code: openedFile.text,
			lineStart: parseInt(startLocation),
			lineEnd: parseInt(endLocation),
			vulnerabilityTitle: name,
			vulnerabilityDescription: description
		};
		writePocWithAI(writeVulnContext, (text, done) => {
			if (done) setPoc({ findingTitle: name, content: text, loading: false });
			else setPoc({ findingTitle: name, content: text, loading: true });
		});
	};

	const getLinesFromLocation = () => {
		return location.split("Lines: ")[1].split(" - ");
	};

	return (
		<>
			<Card
				sx={{
					borderRadius: "14px",
					bgcolor: "background.paper",
					p: 2,
					...(themeMode === "light" && {
						boxShadow: 1,
						bgcolor: collapseOpen ? "background.default" : "background.paper",
						"&:hover": {
							bgcolor: theme.palette.background["default"],
							"& .actionButton": {
								display: "inline-flex"
							}
						}
					}),
					...(themeMode === "dark" && {
						border: "1px solid",
						borderColor: "background.default",
						"&:hover": {
							bgcolor: theme.palette.background["nav"],
							"& .actionButton": {
								display: "inline-flex"
							}
						}
					})
				}}
				elevation={0}
			>
				<Stack spacing={1.5} sx={{ width: "100%" }}>
					{/* Title and buttons */}
					<Stack
						direction="row"
						alignItems="center"
						onClick={handleCollapse}
						sx={{ cursor: "pointer" }}
					>
						<IconSrc sx={{ color }} />

						<Typography sx={{ fontWeight: 600, ml: 0.7 }}>{name}</Typography>

						<Box sx={{ flex: 1 }} />

						<IconButton
							size="small"
							className="actionButton"
							sx={{ display: "none", color: "info.light" }}
							onClick={handleEditFindings}
						>
							<EditIcon sx={{ fontSize: 18 }} />
						</IconButton>

						<IconButton
							size="small"
							onClick={handleOpenConfirmModal}
							className="actionButton"
							sx={{ display: "none", color: "error.light" }}
						>
							<DeleteOutlineOutlinedIcon sx={{ fontSize: 18 }} />
						</IconButton>

						<IconButton size="small">
							{collapseOpen ? (
								<ExpandLessIcon fontSize="small" />
							) : (
								<ExpandMoreIcon fontSize="small" />
							)}
						</IconButton>
					</Stack>

					{/* Risk - source */}
					<Stack direction="row" alignItems="center" spacing={2}>
						<Typography sx={{ fontWeight: 600 }}>
							Risk level:
							<Typography component="span">
								{" "}
								{riskLevelToText(severity)}
							</Typography>
						</Typography>

						<Typography sx={{ fontWeight: 600 }}>
							Source:
							<Typography component="span"> {source}</Typography>
						</Typography>
					</Stack>

					<Collapse in={!collapseOpen} timeout="auto" unmountOnExit>
						<Box
							sx={{
								bgcolor: "background.default",
								p: 1.5,
								borderRadius: "8px",
								mb: 2
							}}
						>
							<Typography
								sx={{
									overflow: "hidden",
									textOverflow: "ellipsis",
									display: "-webkit-box",
									WebkitBoxOrient: "vertical",
									WebkitLineClamp: 2,
									maxHeight: "4.2em"
								}}
							>
								{description}
							</Typography>
						</Box>

						<Button
							variant="outlined"
							fullWidth
							onClick={handleWritePocWithAI}
							sx={{
								height: 40,
								borderRadius: "8px",
								boxShadow: 1,
								borderColor: `${theme.palette.warning.dark}${hex["30%"]}`,
								"&:hover": {
									borderColor: theme.palette.warning.dark
								}
							}}
						>
							<Stack direction="row" alignItems="center" spacing={0.7}>
								<Icon
									icon="mingcute:magic-hat-fill"
									fontSize={20}
									color={theme.palette.warning.main}
								/>

								<Typography sx={{ color: "warning.main", fontWeight: 600 }}>
									Generate PoC
								</Typography>
							</Stack>
						</Button>
					</Collapse>
				</Stack>

				<Collapse in={collapseOpen} timeout="auto" unmountOnExit sx={{ mt: 2 }}>
					<Stack spacing={2}>
						{/* Location */}
						<Stack spacing={0.7}>
							<Typography sx={{ fontWeight: 600 }}>Location:</Typography>
							<Typography
								sx={{
									wordWrap: "break-word",
									whiteSpace: "pre-line",
									borderRadius: "8px",
									p: 1.5,
									bgcolor: "background.paper",
									boxShadow: 1
								}}
							>
								{location}
							</Typography>
						</Stack>

						{/* Description */}
						<Stack spacing={0.7}>
							<Typography sx={{ fontWeight: 600 }}>Description:</Typography>
							<Box
								sx={{
									bgcolor: "background.paper",
									p: 1.5,
									borderRadius: "8px",
									boxShadow: 1
								}}
							>
								<Typography
									sx={{ wordWrap: "break-word", whiteSpace: "pre-line" }}
								>
									{description}
								</Typography>
							</Box>
						</Stack>

						{/* Recommendation */}
						<Stack spacing={0.7}>
							<Typography sx={{ fontWeight: 600 }}>Recommendation:</Typography>
							<Box
								sx={{
									bgcolor: "background.paper",
									p: 1.5,
									borderRadius: "8px",
									boxShadow: 1
								}}
							>
								<Typography
									sx={{ wordWrap: "break-word", whiteSpace: "pre-line" }}
								>
									{recommendation}
								</Typography>
							</Box>
						</Stack>
					</Stack>
				</Collapse>
			</Card>

			<ConfirmationModal
				open={open}
				onClose={() => setOpen(false)}
				onConfirm={handleDelete}
				title="Delete Finding?"
				description="Are you sure you want to delete this finding?"
				confirmButtonText="Delete"
				confirmButtonLoadingText="Deleting..."
			/>
		</>
	);
};

export interface Finding {
	severity: number;
	description: string;
	projectID: string;
	source: string;
	name: string;
	_id: string;
	location: string;
	recommendation: string;
}

const FindingsTool: React.FC = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [findings] = useAtom(findingsAtom);

	const [filteredFindings, setFilteredFindings] = useState([]);
	const [filterBy, setFilterBy] = useState<number>(-1);

	const { selectTool } = useTools();
	const setFindingToEdit = useSetAtom(findingToEditAtom);

	useEffect(() => {
		if (findings?.length) {
			if (filterBy === -1) {
				setFilteredFindings(findings);
			} else {
				setFilteredFindings(findings.filter((i) => i.severity === filterBy));
			}
		} else {
			setFilteredFindings([]);
		}
	}, [findings, filterBy]);

	const Header = () => (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{ mb: 2 }}
		>
			<Typography variant="h6">Findings</Typography>

			<ToolboxCloseButton />
		</Stack>
	);

	return (
		<>
			<Header />

			{/* Filter */}
			<Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 2 }}>
				<Typography>Filter by</Typography>

				<Select
					variant="outlined"
					size="small"
					sx={{
						height: 40,
						mb: 2,
						flex: 1,
						borderRadius: "8px",
						fieldset: {
							borderColor: `${theme.palette.primary.main}${hex["30%"]}`
						},
						...(themeMode === "light" && {
							bgcolor: "background.paper"
						})
					}}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "right"
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "right"
						},
						slotProps: {
							paper: {
								sx: {
									mt: 1,
									bgcolor: "background.paper",
									borderRadius: "8px",
									boxShadow: 1,
									backgroundImage: "none",
									...(themeMode === "dark" && {
										border: `2px solid ${theme.palette.primary.main}`
									}),
									...(themeMode === "light" && {
										border: `1px solid ${theme.palette.background["paper2"]}`
									})
								}
							}
						}
					}}
					value={filterBy}
					onChange={(e) => setFilterBy(e.target.value as number)}
				>
					<MenuItem value={-1}>
						<Stack direction="row" spacing={0.7} alignItems="center">
							<ListIcon fontSize="small" sx={{ color: "text.primary" }} />
							<Typography>All</Typography>
						</Stack>
					</MenuItem>

					{SEVERITY_OPTIONS?.map((item) => (
						<MenuItem key={item.value} value={item.value}>
							<Stack direction="row" spacing={0.7} alignItems="center">
								<item.icon fontSize="small" sx={{ color: item.color }} />
								<Typography>{item.name}</Typography>
							</Stack>
						</MenuItem>
					))}
				</Select>
			</Stack>

			<Stack spacing={2} sx={{ flex: 1 }}>
				{!filteredFindings.length && (
					<Typography>
						There are no {filterBy === -1 ? "" : riskLevelToText(filterBy)}{" "}
						findings
					</Typography>
				)}

				{filteredFindings.map((item, i) => {
					const iconColor = SEVERITY_OPTIONS.find(
						(i) => i.value === item.severity
					);

					return (
						<Item
							key={i}
							IconSrc={iconColor.icon}
							color={iconColor.color}
							{...item}
						/>
					);
				})}

				{!filteredFindings?.length && (
					<Button
						variant="contained"
						fullWidth
						sx={{ borderRadius: "8px", height: 40 }}
						onClick={() => {
							setFindingToEdit({});
							selectTool("AddFinding");
						}}
					>
						<AddIcon fontSize="small" sx={{ mr: 0.5 }} />
						New Finding
					</Button>
				)}
			</Stack>
		</>
	);
};

export default React.memo(FindingsTool);
