import { Icon } from "@iconify/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Collapse,
	IconButton,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import React, { useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import ProjectItem from "./ProjectItem";
import { DEFAULT_FOLDER_NAME } from "./helpers";

const Folder = ({ folderName, folderItems }) => {
	const [open, setOpen] = useState(true);

	const theme = useTheme();

	const handleCollapse = () => {
		setOpen((prev) => !prev);
	};

	const isDefaultFolder = folderName === DEFAULT_FOLDER_NAME;

	if (isDefaultFolder) {
		return (
			<>
				<Droppable droppableId={folderName}>
					{(provided, snapshot) => {
						const isDraggingOver = snapshot.isDraggingOver;

						return (
							<Box
								{...provided.droppableProps}
								ref={provided.innerRef}
								disableGutters
								slotProps={{ transition: { unmountOnExit: true } }}
								sx={{
									borderWidth: "1px",
									borderStyle: isDraggingOver ? "dashed" : "none",
									borderColor: isDraggingOver ? "text.secondary" : "divider",
									borderRadius: "8px"
								}}
							>
								<Typography sx={{ flex: 1, my: 1, px: 1, fontWeight: 500 }}>
									My Imports
								</Typography>

								<Stack spacing={1} sx={{ pr: 1, mb: 1 }}>
									{folderItems?.map?.((item, index) => {
										return (
											<ProjectItem
												key={index}
												text={item.name}
												index={index}
												project={item}
												notInFolder
											/>
										);
									})}

									{provided.placeholder}
								</Stack>
							</Box>
						);
					}}
				</Droppable>
			</>
		);
	}

	return (
		<Droppable droppableId={folderName}>
			{(provided, snapshot) => {
				const isDraggingOver = snapshot.isDraggingOver;

				return (
					<Box
						{...provided.droppableProps}
						ref={provided.innerRef}
						sx={{
							borderWidth: "1px",
							borderStyle: isDraggingOver ? "dashed" : "solid",
							borderColor: isDraggingOver ? "text.secondary" : "divider",
							borderRadius: "8px",
							py: 1.5,
							bgcolor: "background.paper"
						}}
					>
						<Stack
							direction="row"
							alignItems="center"
							spacing={1}
							onClick={handleCollapse}
							sx={{
								flex: 1,
								px: 1.5,
								cursor: "pointer",
								"&:hover": {
									"& .showOnHover": {
										display: "inline-flex"
									}
								}
							}}
						>
							{/* Folder icon */}
							{!open ? (
								<Icon icon="ion:folder" fontSize={16} />
							) : (
								<Icon icon="ion:folder-outline" fontSize={16} />
							)}

							{/* Folder name */}
							<Typography
								variant="body2"
								sx={{ flex: 1, textTransform: "uppercase", fontWeight: 500 }}
							>
								{folderName}
							</Typography>

							{/* Action buttons */}
							<Stack
								direction="row"
								alignItems="center"
								spacing={0.5}
								className="showOnHover"
								sx={{
									display: "none"
								}}
							>
								{/* <IconButton
									size="small"
									sx={{
										p: 0,
										color: "text.secondary",
										"&:hover": { color: "text.primary" }
									}}
								>
									<Icon icon="ic:round-edit" fontSize={18} />
								</IconButton> */}

								{/* <IconButton
									size="small"
									sx={{
										p: 0,
										color: "text.secondary",
										"&:hover": { color: "text.primary" }
									}}
								>
									<Icon icon="ic:round-delete" fontSize={18} />
								</IconButton> */}
							</Stack>

							{/* Arrow icon */}
							{open ? (
								<Icon icon="eva:arrow-up-fill" fontSize={16} />
							) : (
								<Icon icon="eva:arrow-down-fill" fontSize={16} />
							)}
						</Stack>

						{/* Projects collapse */}
						<Collapse in={open} timeout="auto" unmountOnExit>
							<Stack spacing={1} sx={{ mt: 1.5, mr: 1.5 }}>
								{folderItems?.map?.((item, i) => (
									<ProjectItem
										key={i}
										text={item.name}
										project={item}
										index={i}
									/>
								))}

								{provided.placeholder}
							</Stack>
						</Collapse>
					</Box>
				);
			}}
		</Droppable>
	);
};

export default React.memo(Folder);
