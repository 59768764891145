import { Stack } from "@mui/material";
import { getUsername } from "Resources/Auth";
import { authWithGithub } from "Resources/ServerInterface";
import { githubLoginCodeAtom, mixpanelAtom, usernameAtom } from "atoms";
import { NAVBAR_HEIGHT } from "components/Navbar";
import ImportsSidebar from "components/sidebars/ImportsSidebar";
import { useAtom, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { LOCALSTORAGE_REFERRAL } from "utils/constants";
import ImportsView from "views/ImportsView";

const ImportLayout = () => {
	const [githubLoginCode, setGithubLoginCode] = useAtom(githubLoginCodeAtom);
	const [mixpanel] = useAtom(mixpanelAtom);
	const setUsername = useSetAtom(usernameAtom);

	const [loading, setLoading] = useState(false);
	const referral = window.localStorage.getItem(LOCALSTORAGE_REFERRAL) || "";

	useEffect(() => {
		if (githubLoginCode && !loading) {
			setLoading(true);
			authWithGithub(githubLoginCode, mixpanel, referral).then(() => {
				setLoading(false);
				setGithubLoginCode("");
				const username = getUsername();
				setUsername(username);
			});
		}
	}, [githubLoginCode]);
	
	return (
		<Stack
			direction="row"
			sx={{
				flexGrow: 1,
				height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
				width: "100vw"
			}}
		>
			{/* Sidebar */}
			<ImportsSidebar />

			{/* Main */}
			<ImportsView />
		</Stack>
	);
};

export default ImportLayout;
