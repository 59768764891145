import { Box, Grid, Radio, Stack, Typography, useTheme } from "@mui/material";
import { userPrivacyHashAtom } from "atoms";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React from "react";
import secureLocalStorage from "react-secure-storage";
import { privacyModeCodeNeededModalOpenAtom } from "store/atoms/UiAtoms";
import hex from "utils/hexTransparency";
import { compareEncryptionKeyHash } from "utils/privateModeEncryption";

const PrivacyItem: React.FC<{
	selected?: boolean;
	handleSelect: any;
	title: string;
	description: string;
}> = ({ selected = false, handleSelect, title, description, children }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	return (
		<Box
			sx={{
				height: "100%",
				width: "100%",
				borderRadius: "8px",
				p: 1,
				cursor: "pointer",
				bgcolor: selected
					? `${theme.palette.primary.main}${hex["15%"]}`
					: "transparent",
				...(themeMode === "dark" && {
					border: selected
						? `1px solid ${theme.palette.primary.main}`
						: `1px solid ${theme.palette.primary.dark}`
				}),
				...(themeMode === "light" && {
					boxShadow: 1,
					border: `1px solid ${theme.palette.background["paper2"]}`
				})
			}}
			onClick={handleSelect}
		>
			<Stack direction="row" alignItems="center" spacing={0.5} sx={{ mb: 1 }}>
				<Radio
					size="small"
					checked={selected}
					onClick={handleSelect}
					sx={{ height: 22, width: 22 }}
				/>
				<Typography sx={{ fontWeight: 500 }}>{title}</Typography>
			</Stack>

			<Typography variant="body2">{description}</Typography>

			{children}
		</Box>
	);
};

const ProjectPrivacyBox: React.FC<{
	selected: string;
	setSelected: any;
}> = ({ selected, setSelected }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;
	const [userPrivacyHash] = useAtom(userPrivacyHashAtom);

	const setPrivacyModeCodeNeededModalOpen = useSetAtom(
		privacyModeCodeNeededModalOpenAtom
	);

	const shouldDisablePrivateMode = async (): Promise<boolean> => {
		if (!!secureLocalStorage.getItem("privateModeEncryptionKey")) {
			const key = secureLocalStorage.getItem(
				"privateModeEncryptionKey"
			) as string;
			const comparison = await compareEncryptionKeyHash(key, userPrivacyHash);
			return !comparison;
		} else if (userPrivacyHash) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<Box
			sx={{
				width: "100%",
				p: 1.5,
				bgcolor: "background.paper",
				borderRadius: "8px",
				textAlign: "initial",
				...(themeMode === "dark" && {
					border: `1px solid ${theme.palette.primary.main}`
				}),
				...(themeMode === "light" && {
					boxShadow: 1,
					border: `1px solid ${theme.palette.background["paper2"]}`
				})
			}}
		>
			<Grid container spacing={1}>
				<Grid item xs={12} md={6}>
					<PrivacyItem
						selected={selected === "standard"}
						handleSelect={() => setSelected("standard")}
						title="Standard"
						description="Your data is kept secure on our servers, enabling all of our powerful features and allowing you to seamlessly collaborate."
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<PrivacyItem
						selected={selected === "private"}
						handleSelect={async () => {
							const shouldDisable = await shouldDisablePrivateMode();
							if (!shouldDisable) {
								setSelected("private");
							} else {
								setPrivacyModeCodeNeededModalOpen(true);
							}
						}}
						title="Private"
						description="Findings and notes are end-to-end encrypted with a private key kept in your browser. Some features may be disabled."
					/>
				</Grid>
			</Grid>

			{/* Encryption Key */}
			{/* <Stack spacing={1} sx={{ mt: 3 }}>
				<Typography>Encryption key</Typography>

				<Stack
					alignItems="center"
					direction="row"
					sx={{
						pl: 1.5,
						pr: 0.8,
						py: 1,
						borderRadius: "8px",
						...(themeMode === "dark" && {
							border: `1px solid ${theme.palette.primary.dark}`
						}),
						...(themeMode === "light" && {
							boxShadow: 1,
							border: `1px solid ${theme.palette.background["paper2"]}`
						})
					}}
				>
					<Typography
						sx={{
							flex: 1,
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis"
						}}
					>
						2783TR8GR24768GF934F34F34F32783TR8GR24768GF934F34F34F32783TR8GR24768GF934F34F34F3
					</Typography>

					<Stack direction="row" alignItems="center">
						<IconButton size="small">
							<VisibilityIcon sx={{ fontSize: 18 }} />
						</IconButton>

						<IconButton size="small">
							<ContentCopyIcon sx={{ fontSize: 18 }} />
						</IconButton>
					</Stack>
				</Stack>
			</Stack> */}
		</Box>
	);
};

export default ProjectPrivacyBox;
