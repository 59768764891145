import { EventEmitter } from 'events';

class GlobalState extends EventEmitter {
    private static instance: GlobalState;
    private hydratingProject: boolean;
  
    private constructor() {
        super();
        this.hydratingProject = false;
    }
  
    public static getInstance(): GlobalState {
      if (!GlobalState.instance) {
        GlobalState.instance = new GlobalState();
      }
      return GlobalState.instance;
    }
  
    public setHydratingProject(value: boolean): void {
      this.hydratingProject = value;
      this.emit('hydrateChange', value);
    }
  
    public getHydratingProject(): boolean {
      return this.hydratingProject;
    }
  }
  
  export default GlobalState;