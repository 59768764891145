import { Icon } from "@iconify/react";
import {
	Box,
	Chip,
	css,
	Fade,
	IconButton,
	keyframes,
	Stack,
	styled,
	Tooltip,
	tooltipClasses,
	Typography,
	useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import {
	a11yDark,
	a11yLight
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import hex from "utils/hexTransparency";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useProject } from "hooks/data/useProject";
import useSetAtom from "hooks/useSetAtom";
import { JumpToLineAtom } from "store/atoms/EditorAtoms";
import { useAtom } from "jotai";
import { openedTabAtom } from "store/atoms/UiAtoms";

const fadeIn = keyframes`
  from {
    opacity: 0.5;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledHighlighter = styled(SyntaxHighlighter)(({ theme }) => ({
	background: "transparent !important",
	padding: "0px !important",
	margin: "0px !important",
	"& .react-syntax-highlighter-line-number": {
		borderRight: "1px solid",
		borderColor: theme.palette.divider,
		color: theme.palette.text.disabled,
		marginRight: "10px"
	}
}));

const StyledIconbutton = styled(IconButton)(({ theme }) => ({
	height: 22,
	width: 22,
	padding: 0,
	borderRadius: "5px",
	color: theme.palette.text.secondary,
	"&:hover": { color: theme.palette.text.primary }
}));

const FunctionItem = ({ visibility, name, type, codeString, loc, ...rest }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [{ openedFile, openedTab }] = useAtom(openedTabAtom);

	const [copied, setCopied] = useState(false);

	const { openProjectFile } = useProject();
	const setJumpToEditorLine = useSetAtom(JumpToLineAtom);

	useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		}
	}, [copied]);

	const toolTipStyles = {
		tooltip: {
			sx: {
				[`&.${tooltipClasses.tooltip}`]: {
					border: `1px solid ${theme.palette.primary.dark}`,
					borderRadius: "8px",
					backgroundColor: theme.palette.background.paper
				}
			}
		}
	};

	const handleOpenLocation = () => {
		const pathToFile = openedTab?.metadata?.originalFilePath;

		if (!!pathToFile) {
			openProjectFile(pathToFile, () => {
				setTimeout(() => {
					setJumpToEditorLine({
						startLineNumber: loc.start.line,
						endLineNumber: loc.end.line
					});
				}, 500);
			});
		}
	};

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			sx={{
				p: 2,
				pt: 1.5,
				border: "1px solid",
				borderColor: "divider",
				borderRadius: "8px",
				bgcolor: `${theme.palette.background["nav"]}${hex["50%"]}`,
				backdropFilter: "blur(5px)",
				boxShadow: 2,
				animation: `${fadeIn} 0.5s ease-in-out`,
				transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out"
			}}
		>
			<Box>
				<Stack
					direction="row"
					alignItems="center"
					spacing={1}
					sx={{ pl: 1, mb: 1.5 }}
				>
					<Typography variant="body2" sx={{ color: "text.secondary" }}>
						{type} - {name}
					</Typography>

					<Chip
						size="small"
						label={<Typography variant="body2">{visibility}</Typography>}
						sx={{ bgcolor: "background.paper2" }}
					/>
				</Stack>

				<StyledHighlighter
					language="javascript"
					style={themeMode === "dark" ? a11yDark : a11yLight}
					showLineNumbers
				>
					{codeString}
				</StyledHighlighter>
			</Box>

			<Stack spacing={1}>
				<CopyToClipboard text={codeString} onCopy={() => setCopied(true)}>
					<Tooltip
						slotProps={toolTipStyles}
						title={copied ? "Copied" : "Copy Code Block"}
						placement="left"
						enterDelay={300}
						enterNextDelay={300}
					>
						<StyledIconbutton>
							<Icon
								icon={copied ? "tabler:copy-check-filled" : "tabler:copy"}
								fontSize={16}
							/>
						</StyledIconbutton>
					</Tooltip>
				</CopyToClipboard>

				<Tooltip
					slotProps={toolTipStyles}
					title="Open in Code"
					placement="left"
					enterDelay={300}
					enterNextDelay={300}
					onClick={handleOpenLocation}
				>
					<StyledIconbutton>
						<Icon icon="fluent:open-24-filled" fontSize={16} />
					</StyledIconbutton>
				</Tooltip>

				{/* <Tooltip
					slotProps={toolTipStyles}
					title="Explain with AI"
					placement="left"
					enterDelay={300}
					enterNextDelay={300}
				>
					<StyledIconbutton>
						<Icon icon="carbon:ai" fontSize={22} />
					</StyledIconbutton>
				</Tooltip> */}
			</Stack>
		</Stack>
	);
};

export default React.memo(FunctionItem);
