import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { callableValuesAtom } from "store/atoms/CallableValueAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import hex from "utils/hexTransparency";

const ErrorComponent: React.FC<{ error: string }> = ({ error }) => {
	return (
		<Stack
			spacing={1.5}
			sx={(theme) => ({
				border: `1px solid ${theme.palette.info.main}`,
				bgcolor: `${theme.palette.info.main}${hex["7%"]}`,
				boxShadow: `${theme.palette.info.main} 0px 0px 6px`,
				width: "98%",
				borderRadius: "8px",
				alignSelf: "center",
				// boxShadow: 1,
				p: 2,
				maxHeight: 250,
				overflow: "auto",
				whiteSpace: "pre-line"
			})}
		>
			<Typography sx={{ wordBreak: "break-word", textAlign: "center" }}>
				{error}
			</Typography>
		</Stack>
	);
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(even)": {
		backgroundColor: `${theme.palette.action.hover}`
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0
	}
}));

const OnchainValues = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [expanded, setExpanded] = useState(true);

	const [callableValues] = useAtom(callableValuesAtom);
	const [project] = useAtom(projectAtom);

	const isProjectOnChain = project?.source?.startsWith("0x");

	return (
		<Accordion
			expanded={expanded}
			onChange={(e, v) => setExpanded(v)}
			disableGutters
			sx={{
				width: "100%",
				borderRadius: "8px !important",
				backgroundImage: "none",
				bgcolor: "background.default",
				"&.MuiAccordion-root:before": {
					bgcolor: "transparent"
				}
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 1.5 }}>
				On-chain values
			</AccordionSummary>

			<AccordionDetails sx={{ px: 1.5, pt: 0 }}>
				{!isProjectOnChain ? (
					<ErrorComponent error="On-chain values are only supported on projects imported by contract address" />
				) : !callableValues?.length ? (
					<ErrorComponent error="This contract has no public values" />
				) : (
					<Box sx={{ flex: 1, height: "100%", overflow: "auto", pb: 1 }}>
						<TableContainer
							component={Paper}
							elevation={0}
							sx={{
								// bgcolor: "transparent",
								border:
									themeMode === "dark"
										? `1px solid ${theme.palette.background["paper2"]}`
										: `1px solid ${theme.palette.background["nav"]}`,
								borderRadius: "8px",
								boxShadow: 1
							}}
						>
							<Table sx={{ minWidth: 300 }}>
								<TableHead>
									<TableRow>
										<TableCell
											align="center"
											sx={{
												fontWeight: "600",
												bgcolor: "background.paper2",
												py: 1,
												borderRight: `1px solid ${theme.palette.background["paper"]} !important`,
												borderBottom: `1px solid ${theme.palette.background["paper"]}`
											}}
										>
											Variable
										</TableCell>
										<TableCell
											align="center"
											sx={{
												fontWeight: "600",
												bgcolor: "background.paper2",
												py: 1,
												borderBottom: `1px solid ${theme.palette.background["paper"]}`
											}}
										>
											Value
										</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{callableValues?.map?.((item, i) => (
										<StyledTableRow
											key={i}
											sx={{ "&:last-child td": { borderBottom: 0 } }}
										>
											<TableCell
												align="left"
												sx={{
													py: 1,
													px: 1,
													borderRight: `1px solid ${theme.palette.background["paper2"]} !important`,
													borderBottom: `1px solid ${theme.palette.background["paper2"]}`
												}}
											>
												<Tooltip
													title={String(item.name)}
													enterNextDelay={1000}
												>
													<Typography
														sx={{
															width: "min-content",
															maxWidth: 100,
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis"
														}}
													>
														{item.name}
													</Typography>
												</Tooltip>
											</TableCell>

											<TableCell
												sx={{
													p: 0,
													borderBottom: `1px solid ${theme.palette.background["paper2"]}`
												}}
											>
												<Tooltip
													title={String(item.value)}
													enterNextDelay={600}
												>
													<Typography
														variant="body2"
														sx={{
															width: "min-content",
															px: 1,
															maxWidth: 200,
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
															fontWeight: 500
														}}
													>
														{String(item.value)}
													</Typography>
												</Tooltip>
											</TableCell>
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				)}
			</AccordionDetails>
		</Accordion>
	);
};

export default React.memo(OnchainValues);
