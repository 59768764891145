import { Icon } from "@iconify/react";
import {
	IconButton,
	Stack,
	Tooltip,
	Typography,
	styled,
	tooltipClasses,
	useTheme
} from "@mui/material";
import ResolveMissingImport from "components/ResolveMissingImport";
import { useEditorTabs } from "hooks/ui/useEditorTabs";
import useFullScreenMode from "hooks/ui/useFullScreenMode";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React from "react";
import { openedTabAtom, searchAllBoxOpenAtom } from "store/atoms/UiAtoms";
import { terminalOpenAtom } from "store/atoms/terminalAtoms";
import { toggleFullScreen } from "utils/helpersFunctions";
import DownloadButton from "./DownloadButton";

const StyledIconbutton = styled(IconButton)({
	height: 24,
	width: 24,
	padding: 0,
	borderRadius: "5px"
});

const CodeViewButton = ({ toolTipStyles }) => {
	const theme = useTheme();

	const [{ openedFile, projectFilePath }] = useAtom(openedTabAtom);
	const { findAndOpenTab, openCustomTab } = useEditorTabs();

	const openFunctionExplorer = () => {
		const tabName = `Explorer - ${openedFile.name}`;
		const tabPath = `/function-explorer/${projectFilePath}`;

		findAndOpenTab(tabPath, () =>
			openCustomTab(openedFile, tabName, tabPath, openedFile, null, {
				originalFilePath: projectFilePath
			})
		);
	};

	return (
		<Tooltip slotProps={toolTipStyles} title={"Function Explorer"}>
			<StyledIconbutton
				onClick={openFunctionExplorer}
				sx={{
					bgcolor: "background.paper",
					boxShadow: `0 0 5px 1px ${theme.palette.primary.main}, 0 0 10px 1px ${theme.palette.primary.main}`,
					"&:hover": {
						boxShadow: `0 0 10px 1px ${theme.palette.primary.main}, 0 0 10px 1px ${theme.palette.primary.main}`
					}
				}}
			>
				<Icon icon={"fluent:text-grammar-wand-16-filled"} fontSize={20} />
			</StyledIconbutton>
		</Tooltip>
	);
};

const ActionButtons = () => {
	const theme = useTheme();

	const { isFullScreenMode } = useFullScreenMode();

	const [{ openedTab, openedFile, isSolFileSelected }] = useAtom(openedTabAtom);
	const { openCustomTab } = useEditorTabs();

	const setTerminalOpen = useSetAtom(terminalOpenAtom);
	const setSearchAllBoxOpen = useSetAtom(searchAllBoxOpenAtom);

	const toolTipStyles = {
		tooltip: {
			sx: {
				[`&.${tooltipClasses.tooltip}`]: {
					border: `1px solid ${theme.palette.primary.dark}`,
					borderRadius: "8px",
					backgroundColor: theme.palette.background.paper
				}
			}
		}
	};

	return (
		<Stack
			direction="row"
			alignItems="center"
			spacing={0.5}
			sx={{
				px: 1,
				borderLeft: `1px solid ${theme.palette.background.paper}`,
				height: "100%"
			}}
		>
			{/* Download graph button */}
			{openedTab?.path?.includes("graph-function") && <DownloadButton />}

			{/* Imports edit button */}
			{openedTab?.path?.includes("Imports/") && (
				<Tooltip
					title={
						<Typography sx={{ whiteSpace: "pre-line" }} variant="caption">
							{"Edit file contents"}
						</Typography>
					}
				>
					<IconButton
						size="small"
						sx={{
							height: 24,
							width: 24,
							p: 0,
							color: "text.secondary",
							"&:hover": { color: "text.primary" }
						}}
						onClick={() => {
							openCustomTab(
								<ResolveMissingImport
									fileName={openedFile.name}
									filePath={openedFile.path}
									editMode={true}
									importId={openedFile.id}
								/>,
								`${openedFile.name} (Edit Import)`,
								openedFile.path + "/missing-import"
							);
						}}
					>
						<Icon icon="material-symbols:edit" fontSize={20} />
					</IconButton>
				</Tooltip>
			)}

			{isSolFileSelected && <CodeViewButton toolTipStyles={toolTipStyles} />}

			{/* Search button */}
			<Tooltip
				slotProps={toolTipStyles}
				title={
					<Typography sx={{ whiteSpace: "pre-line" }} variant="body2">
						{"Find text in files\n(ctrl + shift + F)"}
					</Typography>
				}
			>
				<StyledIconbutton onClick={() => setSearchAllBoxOpen(true)}>
					<Icon icon="material-symbols:search" fontSize={20} />
				</StyledIconbutton>
			</Tooltip>

			{/* Full screen button */}
			<Tooltip
				slotProps={toolTipStyles}
				title={
					!isFullScreenMode ? "Enter fullscreen mode" : "Leave fullscreen mode"
				}
			>
				<StyledIconbutton onClick={toggleFullScreen}>
					{!isFullScreenMode ? (
						<Icon icon="fluent:full-screen-maximize-24-filled" fontSize={18} />
					) : (
						<Icon icon="fluent:full-screen-minimize-24-filled" fontSize={18} />
					)}
				</StyledIconbutton>
			</Tooltip>

			<Tooltip slotProps={toolTipStyles} title="Open terminal">
				<StyledIconbutton onClick={() => setTerminalOpen((old) => !old)}>
					<Icon icon="heroicons-outline:terminal" fontSize={22} />
				</StyledIconbutton>
			</Tooltip>
		</Stack>
	);
};

export default React.memo(ActionButtons);
