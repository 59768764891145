import AddIcon from "@mui/icons-material/Add";
import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	LinearProgress,
	Stack,
	TextField,
	Typography,
	useTheme
} from "@mui/material";
import { useChecklist } from "hooks/data/useChecklist";
import { useAtom } from "jotai";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { checklistGroupsAtom } from "store/atoms/checklistAtoms";
import ChecklistGroup from "./ChecklistGroup";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const NewGroupView: React.FC<{ handleSave: any; setAddGroupMode: any }> = ({
	handleSave,
	setAddGroupMode
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [value, setValue] = useState("");

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		handleSave(value);
	};

	return (
		<form onSubmit={handleSubmit}>
			<Stack
				spacing={1}
				sx={{
					p: 1.5,
					bgcolor: "background.default",
					borderRadius: "8px"
				}}
			>
				<TextField
					placeholder="New checklist group name"
					name="value"
					value={value}
					onChange={(e) => setValue(e.target.value)}
					fullWidth
					sx={{ borderWidth: "0px !important" }}
					InputProps={{
						autoFocus: true,
						sx: {
							height: 40,
							bgcolor: "background.paper",
							borderRadius: "8px",
							"& fieldset": {
								borderRadius: "8px",
								borderWidth: 1,
								borderStyle: "solid",
								borderColor:
									themeMode === "dark"
										? "background.paper2"
										: "background.paper"
							}
						},
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									sx={{ color: "primary.main", height: 24, width: 22 }}
									onClick={() => setAddGroupMode(false)}
								>
									<CloseIcon sx={{ fontSize: 18 }} />
								</IconButton>

								<IconButton
									sx={{ color: "primary.main", height: 24, width: 22 }}
									type="submit"
								>
									<CheckIcon sx={{ fontSize: 18 }} />
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
			</Stack>
		</form>
	);
};

const ThisProjectTab = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [checklistsGroups] = useAtom(checklistGroupsAtom);

	const [addGroupMode, setAddGroupMode] = useState(false);

	const { loadChecklists, addNewGroup } = useChecklist();

	const bottomRef = useRef(null);

	// useEffect(() => {
	// 	loadChecklists();
	// }, []);

	const handleNewGroup = async (val) => {
		await addNewGroup(val);
		setAddGroupMode(false);
	};

	const allChecklists = useMemo(() => {
		return checklistsGroups.map((group) => group.checklists).flat();
	}, [checklistsGroups]);

	const completedChecklists = useMemo(() => {
		return allChecklists.filter((i) => !!i.completed);
	}, [allChecklists]);

	const percentageCompleted =
		(completedChecklists.length / allChecklists.length) * 100;

	return (
		<Stack>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Stack direction="row" alignItems="center" spacing={1}>
					<Typography sx={{ minWidth: 39 }}>
						{completedChecklists.length} of {allChecklists.length}
					</Typography>

					<LinearProgress
						variant="determinate"
						value={percentageCompleted}
						sx={{
							width: 120,
							height: 9,
							borderRadius: "100px",
							"& .MuiLinearProgress-bar": { bgcolor: "success.main" }
						}}
					/>
				</Stack>

				<Button
					size="small"
					onClick={() => setAddGroupMode(!addGroupMode)}
					sx={{
						color: "inherit"
					}}
				>
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<AddIcon fontSize="small" />
						<Typography>Checklist group</Typography>
					</Stack>
				</Button>
			</Stack>

			{addGroupMode && (
				<NewGroupView
					handleSave={handleNewGroup}
					setAddGroupMode={setAddGroupMode}
				/>
			)}

			<Stack spacing={1.5} sx={{ mt: 1 }}>
				{checklistsGroups.map((group, i) => {
					return <ChecklistGroup key={i} group={group} />;
				})}

				<Box ref={bottomRef} sx={{ m: "0px !important" }} />
			</Stack>
		</Stack>
	);
};

export default React.memo(ThisProjectTab);
