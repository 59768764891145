import { Monaco } from "@monaco-editor/react";
import { useProject } from "hooks/data/useProject";
import { useAtom } from "jotai";
import { editor } from "monaco-editor";
import React, { useEffect, useState } from "react";
import { editorClickListenerAtom } from "store/atoms/EditorAtoms";
import { openedTabAtom } from "store/atoms/UiAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import { getProjectPath, provideDefinition } from "./helpers";

const SolidityDefinitionProviderAddon: React.FC<{
	editorRef: editor.IStandaloneCodeEditor;
	monacoRef: Monaco;
}> = ({ editorRef, monacoRef }) => {
	const [foundNode, setFoundNode] = useState(null);

	const [{ projectFilePath, openedFile }] = useAtom(openedTabAtom);
	const { openProjectFile } = useProject();

	const [project] = useAtom(projectAtom);
	const [clickListener] = useAtom(editorClickListenerAtom);

	useEffect(() => {
		const _definition = monacoRef.languages.registerDefinitionProvider("sol", {
			provideDefinition: (m, p) =>
				provideDefinition(
					m,
					p,
					monacoRef,
					editorRef,
					openedFile?.ast,
					openImportPath
				)
		});

		return () => {
			_definition?.dispose?.();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectFilePath, openedFile?.ast]);

	useEffect(() => {
		if (foundNode && clickListener) {
			const timeDifferenceSeconds =
				(Date.now() - clickListener.timestamp) / 1000;

			if (timeDifferenceSeconds < 0.2) {
				const fullPath = getProjectPath(
					foundNode.path,
					projectFilePath,
					project.importsFolder.name
				);
				openProjectFile(fullPath);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clickListener, foundNode]);

	const openImportPath = (node) => {
		setFoundNode(node);
		setTimeout(() => {
			setFoundNode(null);
		}, 20);
	};

	return null;
};

export default SolidityDefinitionProviderAddon;
