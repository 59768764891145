import { Icon } from "@iconify/react";
import {
	Box,
	ClickAwayListener,
	Divider,
	IconButton,
	Stack,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import Slider, { SliderProps, SliderThumb } from "@mui/material/Slider";
import { aiSliderModifiedEvent } from "Mixpanel/mixpanel.helper";
import { mixpanelAtom } from "atoms";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
	randomnessValAtom,
	uniquenessValAtom,
	wordValAtom
} from "store/atoms/aiAtoms";

const CustomSlider = styled(Slider)(({ theme }) => ({
	color: "#402F83", // theme.palette.primary.dark,
	height: 6,
	"& .MuiSlider-valueLabel": {
		lineHeight: 1.2,
		fontSize: 11,
		fontWeight: 600,
		background: "unset",
		padding: 0,
		width: 28,
		height: 28,
		borderRadius: "50% 50% 50% 0",
		backgroundColor: theme.palette.primary.main,
		transformOrigin: "bottom left",
		transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
		"&::before": { display: "none" },
		"&.MuiSlider-valueLabelOpen": {
			transform: "translate(50%, -100%) rotate(-45deg) scale(1)"
		},
		"& > *": {
			transform: "rotate(45deg)"
		}
	},
	"& .MuiSlider-track": {
		paddingRight: "3px"
	},
	"& .MuiSlider-thumb": {
		backgroundImage: "none",
		backgroundColor: "transparent !important",
		boxShadow: "0px 4px 4px 0px #000000",
		"&:before": {
			boxShadow: "none"
		}
	}
}));

function CustomThumbComponent(props: any) {
	const theme = useTheme();

	const { children, ...other } = props;

	return (
		<SliderThumb {...other} sx={{ position: "relative" }}>
			{children}

			<Icon
				icon="fa6-solid:circle-dot"
				fontSize={20}
				color={theme.palette.primary.main}
				enableBackground={2}
				style={{ zIndex: 1 }}
			/>

			<Box
				sx={{
					position: "absolute",
					bgcolor: "background.paper",
					borderRadius: "50%",
					height: 12,
					width: 12,
					left: "20%"
				}}
			/>
		</SliderThumb>
	);
}

const SliderComponent: React.FC<
	SliderProps & { label: string; handleChange: any; value: number }
> = ({ label, value: propsValue, handleChange, min, max, step, ...props }) => {
	const theme = useTheme();

	const [value, setValue] = useState<number>(propsValue);

	const [showLabel, setShowLabel] = useState(false);

	useEffect(() => {
		handleChange(value);
	}, [value]);

	useEffect(() => {
		if (showLabel) {
			setTimeout(() => {
				setShowLabel(false);
			}, 1000);
		}
	}, [showLabel]);

	const decimalPlaces = step.toString().split(".")[1]?.length || 0;

	const handleMinusClick = () => {
		const newValue = Math.max(
			min,
			parseFloat((value - step).toFixed(decimalPlaces))
		);
		setValue(newValue);

		setShowLabel(true);
	};

	const handlePlusClick = () => {
		const newValue = Math.min(
			max,
			parseFloat((value + step).toFixed(decimalPlaces))
		);
		setValue(newValue);

		setShowLabel(true);
	};

	return (
		<Stack direction="row" alignItems="center" spacing={1}>
			<Typography variant="body2" sx={{ fontWeight: 600, minWidth: 90 }}>
				{label}
			</Typography>

			<Stack
				spacing={1.7}
				direction="row"
				alignItems="center"
				sx={{ width: "100%" }}
			>
				<IconButton
					size="small"
					onClick={handleMinusClick}
					sx={{ p: 0, height: 16, width: 16, bgcolor: "background.nav" }}
				>
					<Icon
						icon="ic:round-minus"
						fontSize={14}
						color={theme.palette.primary.main}
					/>
				</IconButton>

				<CustomSlider
					slots={{ thumb: CustomThumbComponent }}
					valueLabelDisplay={showLabel ? "on" : "auto"}
					defaultValue={40}
					value={value}
					onChange={(e, v: number) => setValue(v)}
					min={min}
					max={max}
					step={step}
					sx={{ flex: 1 }}
					{...props}
				/>

				<IconButton
					size="small"
					onClick={handlePlusClick}
					sx={{ p: 0, height: 16, width: 16, bgcolor: "background.nav" }}
				>
					<Icon
						icon="ic:round-plus"
						fontSize={14}
						color={theme.palette.primary.main}
					/>
				</IconButton>
			</Stack>
		</Stack>
	);
};

const SettingsBox: React.FC<{ onClose: any }> = ({ onClose }) => {
	const [randomnessVal, setRandomnessVal] = useAtom(randomnessValAtom);
	const [uniquenessVal, setUniquenessVal] = useAtom(uniquenessValAtom);
	const [wordVal, setWordVal] = useAtom(wordValAtom);

	const [mixpanel] = useAtom(mixpanelAtom);

	return (
		<ClickAwayListener onClickAway={onClose}>
			<Box
				sx={{
					width: "100%",
					p: 1.5,
					bgcolor: "background.paper",
					border: "1px solid",
					borderColor: "primary.main",
					borderRadius: "8px"
				}}
			>
				<Typography sx={{ fontWeight: "600" }}>AI response settings</Typography>

				<Divider sx={{ my: 1 }} />

				<Stack>
					<SliderComponent
						label="Randomness"
						value={randomnessVal}
						handleChange={(val) => {
							aiSliderModifiedEvent(mixpanel, { slider: "randomness", val });
							setRandomnessVal(val);
						}}
						min={0}
						max={1}
						step={0.1}
					/>

					<SliderComponent
						label="Uniqueness"
						value={uniquenessVal}
						handleChange={(val) => {
							aiSliderModifiedEvent(mixpanel, { slider: "uniqueness", val });
							setUniquenessVal(val);
						}}
						min={-2}
						max={2}
						step={0.2}
					/>

					<SliderComponent
						label="Response Size"
						value={wordVal}
						handleChange={(val) => {
							aiSliderModifiedEvent(mixpanel, { slider: "brevity", val });
							setWordVal(val);
						}}
						min={50}
						max={500}
						step={50}
					/>
				</Stack>
			</Box>
		</ClickAwayListener>
	);
};

export default SettingsBox;
