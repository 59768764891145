import { Box, Typography, useTheme } from "@mui/material";
import { Editor } from "@toast-ui/react-editor";
import React, { useRef } from "react";

interface Props {
	name: string;
	height?: string;
	setValue?: (name: string, val: string) => void;
	error?: string;
	value?: string;
	onChange?: Function;
}

const FormToastEditorField: React.FC<Props> = ({
	name,
	height = "320px",
	setValue = () => {},
	error,
	value,
	onChange = () => {}
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const editorRef = useRef(null);

	const handleDescriptionChange = (editorType: any) => {
		const _val = editorRef?.current?.editorInst?.getMarkdown();
		setValue(name, _val);
		onChange(editorRef?.current?.editorInst?.getMarkdown());
	};

	return (
		<Box
			sx={{
				"& .toastui-editor-defaultUI": {
					borderRadius: "8px",
					borderColor: error
						? theme.palette.error.main
						: "rgba(255, 255, 255, 0.23)",
					...(themeMode === "light" && {
						boxShadow: 1
					})
				},
				"& .toastui-editor-md-container": {
					bgcolor: `${theme.palette.background.paper}!important`,
					borderBottomLeftRadius: "8px",
					borderBottomRightRadius: "8px"
				},
				"& .toastui-editor-mode-switch, & .toastui-editor-md-tab-container": {
					display: "none !important"
				},
				"& .ProseMirror": {
					height: "100%"
				},
				"& .toastui-editor-defaultUI-toolbar": {
					borderTopRightRadius: "8px",
					borderTopLeftRadius: "8px !important",
					bgcolor: `${theme.palette.background["nav"]}!important`,
					px: 0.5,
					"& button": {
						width: 30,
						height: 30
					}
				}
			}}
		>
			<Editor
				previewStyle="tab"
				initialEditType="markdown"
				useCommandShortcut
				onChange={handleDescriptionChange}
				usageStatistics={false}
				ref={editorRef}
				theme={themeMode}
				height={height}
				initialValue={value}
				autofocus={false}
			/>

			{error && (
				<Typography variant="body2" color="error" sx={{ mt: 0.5, ml: 0.875 }}>
					{error}
				</Typography>
			)}
		</Box>
	);
};

export default FormToastEditorField;
