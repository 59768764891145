export enum MixpanelEvents {
	ToolOpened = "ToolOpened",
	SlitherScanSuccess = "SlitherScanSuccess",
	SlitherScanFailure = "SlitherScanFailure",
	AderynScanSuccess = "AderynScanSuccess",
	AderynScanFailure = "AderynScanFailure",
	AnalyzerScanSuccess = "AnalyzerScanSuccess",
	AnalyzerScanFailure = "AnalyzerScanFailure",
	StorageSlotsSuccess = "StorageSlotsSuccess",
	StorageSlotsFailure = "StorageSlotsFailure",
	AIScanSuccess = "AIScanSuccess",
	AIScanFailure = "AIScanFailure",
	NoteCreated = "NoteCreated",
	ProjectImportSuccess = "ProjectImportSuccess",
	ProjectImportFailure = "ProjectImportFailure",
	PartnerImportLinkClicked = "PartnerImportLinkClicked",
	ProjectOpened = "ProjectOpened",
	SignedUp = "SignedUp",
	NotepadOpenedFromTool = "NotepadOpenedFromTool",
	NoteAdded = "NoteAdded",
	FindingAdded = "FindingAdded",
	WhiteboardAdded = "WhiteboardAdded",
	GraphCreated = "GraphCreated",
	MessageSentToAI = "MessageSentToAI",
	TestCreated = "TestCreated",
	TestUpdated = "TestUpdated",
	TestRan = "TestRan",
	TemplateCollectionCreated = "TemplateCollectionCreated",
	TemplateAddedToCollection = "TemplateAddedToCollection",
	TemplateCollectionDeleted = "TemplateCollectionDeleted",
	ChecklistGroupCreated = "ChecklistGroupCreated",
	ChecklistGroupDeleted = "ChecklistGroupDeleted",
	ChecklistAddedToLibrary = "ChecklistAddedToLibrary",
	ChecklistRemovedFromLibrary = "ChecklistRemovedFromLibrary",
	AISliderModified = "AISliderModified",
	CodeChallengeOpened = "CodeChallengeOpened",
	CodeChallengeSubmitted = "CodeChallengeSubmitted",
	SingleSimulationRan = "SingleSimulationRan",
}
