import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { IconButton, InputAdornment, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FieldValues, Resolver, useForm } from "react-hook-form";
import * as Yup from "yup";
import FormTextField from "../../formFields/FormTextField";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export interface NewPasswordFormType {
	verification_code: string;
	password: string;
}

interface Props {
	handleSubmit: (data: NewPasswordFormType) => void;
	disabled?: boolean;
}

const schema = Yup.object().shape({
	verification_code: Yup.string().required("Verification Code is required"),
	password: Yup.string()
		.required("Password is required")
		.min(8, "Password must be at least 8 characters long")
});

const NewPasswordForm: React.FC<Props> = ({
	handleSubmit: propsHandleSubmit,
	disabled: PropsDisabled = false
}) => {
	const [disabled, setDisabled] = useState(PropsDisabled);
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		setDisabled(PropsDisabled);
	}, [PropsDisabled]);

	const formOptions = { resolver: yupResolver(schema) as Resolver<NewPasswordFormType, FieldValues> };

	const {
		handleSubmit,
		register,
		formState: { errors }
	} = useForm<FieldValues & NewPasswordFormType & any>(formOptions);

	const onSubmit = (data: NewPasswordFormType) => {
		// setDisabled(true);
		propsHandleSubmit?.(data);
	};

	const togglePassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
			<Stack spacing={3}>
				<FormTextField
					fullWidth
					disabled={disabled}
					required
					name="verification_code"
					placeholder="Verification Code"
					register={register}
					error={!!errors["verification_code"]}
					helperText={errors["verification_code"]?.message}
					sx={{ flex: 1 }}
					InputProps={{
						sx: { bgcolor: "background.paper" }
					}}
				/>

				<FormTextField
					required
					disabled={disabled}
					name="password"
					label="New Password"
					register={register}
					type={showPassword ? "text" : "password"}
					error={!!errors["password"]}
					helperText={errors["password"]?.message}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={togglePassword} size="small">
									{showPassword ? (
										<Visibility fontSize="small" />
									) : (
										<VisibilityOff fontSize="small" />
									)}
								</IconButton>
							</InputAdornment>
						)
					}}
				/>

				<LoadingButton
					type="submit"
					variant="contained"
					disabled={disabled}
					loading={disabled}
					sx={{ height: 39 }}
				>
					Submit
				</LoadingButton>
			</Stack>
		</form>
	);
};

export default NewPasswordForm;
