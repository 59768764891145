import {
	Box,
	Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import CustomModal from ".";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {useAtom} from "jotai";
import {pocModalAtom} from "../../atoms";
import ReactMarkdown from "react-markdown";
import {LoadingButton} from "@mui/lab";

interface PocModalProps {
	open: boolean,
	handleClose: () => void
}

const PocModal: React.FC<PocModalProps> = ({ open, handleClose }) => {
	const [copied, setCopied] = useState(false);
	const [poc, ] = useAtom(pocModalAtom);

	useEffect(() => {
		setCopied(false);
	}, [poc]);

	return (
		<CustomModal
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="md"
			paperSx={{ py: 6, minHeight: "70%" }}
		>
			<Typography variant="h4" sx={{ textAlign: "center", mb: 2 }}>
				Test for "{poc.findingTitle}"
			</Typography>

			<CopyToClipboard text={poc.content} onCopy={() => setCopied(true)}>
				<LoadingButton
					loading={poc.loading}
					fullWidth
					variant="outlined"
					sx={{ borderRadius: "8px", color: "text.primary" }}
					disabled={copied || poc.loading}
				>
					<ContentCopyIcon sx={{fontSize: 18, mr: 1}}/>
					{copied ? "Copied" : "Copy PoC"}
				</LoadingButton>
			</CopyToClipboard>

			<Box
				sx={{
					width: "100%",
					overflowY: "scroll",
					overflowX: "clip",
					display: "flex",
					flexDirection: "column-reverse",
					borderRadius: '8px',
					mt: 1,
				}}
			>
				<div className="white-text-markdown">
					<ReactMarkdown>{poc.content}</ReactMarkdown>
				</div>
			</Box>
		</CustomModal>
	);
};

export default PocModal;
