import { projectOpenedEvent } from "Mixpanel/mixpanel.helper";
import Project from "Resources/Project";
import ProjectFile from "Resources/ProjectFile";
import ProjectFolder from "Resources/ProjectFolder";
import ProjectImport from "Resources/ProjectImport";
import {
	deleteProjectById,
	loadProject,
	loadProjectFile,
	loadProjectImport
} from "Resources/ServerInterface";
import { mixpanelAtom, userPrivacyHashAtom, usernameAtom } from "atoms";
import { useAppContext } from "context/AppContext";
import EditorTab from "context/EditorTab";
import { useFindings } from "hooks/data/useFindings";
import { useNotes } from "hooks/data/useNotes";
import { useWhiteboard } from "hooks/data/useWhiteboard";
import { useClearAtoms } from "hooks/useClearAtoms";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import path from "path";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import {
	CallableValueType,
	callableValuesAtom
} from "store/atoms/CallableValueAtoms";
import {
	editorTabsAtom,
	expandedProjectTreeAtom,
	focusedEditorTabIndexAtom,
	privacyModeCodeNeededModalOpenAtom
} from "store/atoms/UiAtoms";
import { projectAtom, projectLoadedAtom } from "store/atoms/projectToolAtoms";
import {
	STORAGE_EXPANDED_PATHS,
	STORAGE_FOCUSED_FILE,
	STORAGE_OPEN_TABS
} from "utils/constants";
import {
	findCallableValueDefinition,
	getObjectFromLocalStorage,
	getPathOfDefaultOpenFile
} from "utils/helpersFunctions";
import { compareEncryptionKeyHash } from "utils/privateModeEncryption";
import { useEditorTabs } from "../ui/useEditorTabs";
import { useProjects } from "./useProjects";
import { useSimulation } from "./useSimulation";
import GlobalState from 'utils/globals';

export function useProject() {
	const navigate = useNavigate();

	// TODO: do not use context in hooks
	const { handleLogout } = useAppContext();
	const { closeFile } = useEditorTabs();

	const { loadProjects } = useProjects();

	const { loadNotes } = useNotes();
	const { loadWhiteboards } = useWhiteboard();
	const { loadFindings } = useFindings();
	const { loadTransactions } = useSimulation();
	const { clearAtoms } = useClearAtoms();

	const [mixpanel] = useAtom(mixpanelAtom);

	const setCallableValues = useSetAtom(callableValuesAtom);

	const openTabs = useAtomCallback(
		useCallback((get) => {
			const curr = get(editorTabsAtom);
			return curr;
		}, [])
	);

	const project = useAtomCallback(
		useCallback((get) => {
			const curr = get(projectAtom);
			return curr;
		}, [])
	);

	const focusedTabIndex = useAtomCallback(
		useCallback((get) => {
			const curr = get(focusedEditorTabIndexAtom);
			return curr;
		}, [])
	);

	const callableValues = useAtomCallback(
		useCallback((get) => {
			const curr = get(callableValuesAtom);
			return curr || [];
		}, [])
	);

	const userPrivacyHash = useAtomCallback(
		useCallback((get) => {
			const curr = get(userPrivacyHashAtom);
			return curr;
		}, [])
	);

	const username = useAtomCallback(
		useCallback((get) => {
			const curr = get(usernameAtom);
			return curr;
		}, [])
	);

	const setProjectLoaded = useSetAtom(projectLoadedAtom);
	const setExpandedPaths = useSetAtom(expandedProjectTreeAtom);
	const setOpenTabs = useSetAtom(editorTabsAtom);
	const setProject = useSetAtom(projectAtom);
	const setFocusedTabIndex = useSetAtom(focusedEditorTabIndexAtom);

	const setPrivacyModeCodeNeededModalOpen = useSetAtom(
		privacyModeCodeNeededModalOpenAtom
	);

	const openProject = (
		newProject: Project,
		_callableValues: any[] = callableValues(),
		callback?: any
	) => {
		projectOpenedEvent(mixpanel, newProject.id);

		const addProjectIndex = openTabs().findIndex(
			(editorTab) => editorTab.path === "addProject"
		);

		if (addProjectIndex >= 0) {
			closeFile(addProjectIndex);
		}

		setProject(newProject);

		// Load initial files
		const _openTabsStorage = getObjectFromLocalStorage(
			`${STORAGE_OPEN_TABS}_${newProject.id}`
		);
		const openTabsStorage = _openTabsStorage?.[newProject?.id] || [];

		const _focusedTabStorage = getObjectFromLocalStorage(STORAGE_FOCUSED_FILE);
		const focusedTabStorage = _focusedTabStorage?.[newProject?.id] || 0;

		const _expandedPathsStorage = getObjectFromLocalStorage(
			STORAGE_EXPANDED_PATHS
		);
		const expandedPathsStorage = _expandedPathsStorage?.[newProject?.id] || [];

		if (openTabsStorage?.length) {
			const newOpenTabs = openTabsStorage?.map(({ contents, path, name }) => {
				return new EditorTab(
					typeof contents === "string"
						? new ProjectFile(name, contents, path, null)
						: contents,
					name,
					path
				);
			});

			if (focusedTabStorage >= newOpenTabs?.length) {
				setFocusedTabIndex(newOpenTabs?.length - 1);
			} else if (focusedTabStorage < newOpenTabs?.length) {
				setFocusedTabIndex(focusedTabStorage);
			}

			setExpandedPaths(expandedPathsStorage);
			setOpenTabs(newOpenTabs);

			if (newOpenTabs[focusedTabIndex()]?.path?.includes(".sol")) {
				const callableValuesWithLines: CallableValueType[] = _callableValues
					? _callableValues?.map((cv) => {
							const cvDefinition = findCallableValueDefinition(
								newOpenTabs[focusedTabIndex()].contents.text,
								cv.name
							);
							return {
								name: cv.name,
								value: cv.value,
								line: cvDefinition
							};
					  })
					: [];
				if (callableValuesWithLines) {
					setCallableValues(callableValuesWithLines);
				}
			}

			setProjectLoaded(true);
		} else {
			openProjectFile(
				getPathOfDefaultOpenFile(newProject.rootFolder),
				null,
				newProject,
				_callableValues
			);
		}

		callback?.(newProject);

		setProjectLoaded(true);
	};

	const deleteProject = async (projectId: string) => {
		try {
			await deleteProjectById(projectId);
			await loadProjects();

			return true;
		} catch (error) {
			console.log(error);
			return;
		}
	};

	const closeProject = (callback: Function = null) => {
		clearAtoms();

		callback?.();

		navigate("/");
	};

	const openProjectFile = (
		pathToFile: string,
		callback?: any,
		newProject?: Project,
		callableValuesInput?: any[]
	) => {
		try {
			const existingOpenProjectFileIndex = openTabs().findIndex(
				(openProjectFile) => {
					return openProjectFile.path === pathToFile;
				}
			);

			if (existingOpenProjectFileIndex > -1) {
				setFocusedTabIndex(existingOpenProjectFileIndex);
				callback?.();
			} else {
				const _callableValues = callableValues();

				// Split path into folders to traverse
				const pathDirectories = path.dirname(pathToFile).split(path.sep);

				const baseFolder = pathDirectories[0];

				const _project = newProject || project();

				// Start at the root folder or imports folder
				let currentDirectory: ProjectFolder = _project?.rootFolder;
				if (baseFolder === _project?.importsFolder?.name)
					currentDirectory = _project.importsFolder;

				// Iterate over each directory in the path, looking for the next folder to traverse into
				for (const pathDirectory of pathDirectories.slice(1)) {
					// Find the next folder to traverse into
					const nextDirectory = currentDirectory.folders.find(
						(projectFolder) => {
							return projectFolder.name === pathDirectory;
						}
					);
					// If folder was not found, this path is invalid
					if (!nextDirectory) {
						// Swallow this error, since we don't want to show it to users
						// during the common case of missing imports
						console.log(
							'Could not find folder "' +
								pathDirectory +
								'/" in "' +
								pathToFile +
								'"'
						);
						return;
					}

					currentDirectory = nextDirectory;
				}

				// Find the file that matches the name of the file we are trying to open
				const projectFileThatMatchesName = currentDirectory?.files?.find(
					(projectFile) => {
						return projectFile.name === path.basename(pathToFile);
					}
				);

				// If file was not found, abort attempt to open
				if (!projectFileThatMatchesName) return;

				(projectFileThatMatchesName instanceof ProjectImport
					? loadProjectImport
					: loadProjectFile)(projectFileThatMatchesName.id, _project)
					.then((loadedProjectFile) => {
						if (loadedProjectFile !== undefined) {
							const newOpenTabs = openTabs().concat(
								new EditorTab(
									loadedProjectFile,
									loadedProjectFile.name,
									pathToFile
								)
							);

							// Expand all directories in this path
							const newExpandedPaths = path
								.dirname(pathToFile)
								.split(path.sep)
								.map<string>((element, index, array) => {
									return array.slice(0, index + 1).join(path.sep);
								});

							setExpandedPaths((old) => old.concat(newExpandedPaths));
							setFocusedTabIndex(newOpenTabs.length - 1);
							setOpenTabs(newOpenTabs);

							if (callableValuesInput) {
								const callableValuesWithLines: CallableValueType[] =
									callableValuesInput
										? callableValuesInput.map((cv) => {
												const cvDefinition = findCallableValueDefinition(
													loadedProjectFile.text,
													cv.name
												);
												return {
													name: cv.name,
													value: cv.value,
													line: cvDefinition
												};
										  })
										: [];
								if (callableValuesWithLines) {
									setCallableValues(callableValuesWithLines);
								}
							} else {
								const callableValuesWithLines: CallableValueType[] =
									_callableValues
										? _callableValues.map((cv) => {
												const cvDefinition = findCallableValueDefinition(
													loadedProjectFile.text,
													cv.name
												);
												return {
													name: cv.name,
													value: cv.value,
													line: cvDefinition
												};
										  })
										: [];
								if (callableValuesWithLines) {
									setCallableValues(callableValuesWithLines);
								}
							}

							setProjectLoaded(true);
						}
					})
					.then(() => {
						if (newProject) {
							navigate(`/project/${newProject.id}`);
						}
						callback?.();
					});
			}
		} catch (error) {
			console.log(error);
		}
	};

	const shouldDisablePrivateProject = async (): Promise<boolean> => {
		if (
			!!secureLocalStorage.getItem("privateModeEncryptionKey") &&
			userPrivacyHash()
		) {
			const key = secureLocalStorage.getItem(
				"privateModeEncryptionKey"
			) as string;
			const comparison = await compareEncryptionKeyHash(key, userPrivacyHash());
			return !comparison;
		} else if (userPrivacyHash()) {
			return true;
		} else {
			return false;
		}
	};

	const openProjectWithProjectId = async (
		projectID: string,
		_callableValues: any[] = callableValues()
	) => {
		try {
			if (!username()) handleLogout();

			const _project = await loadProject(projectID);
			const shouldDisable = await shouldDisablePrivateProject();

			if (_project?.privateMode && shouldDisable) {
				setPrivacyModeCodeNeededModalOpen(true);
				return;
			}

			if (_project !== undefined) {
				await loadFindings(projectID);
				await loadNotes(projectID);
				await loadWhiteboards(projectID);
				await loadTransactions(projectID);

				openProject(_project, _callableValues);

				navigate(`/project/${_project.id}`);
			}

			return _project?.id;
		} catch (error) {
			console.log(error);
		}
	};

	return {
		openProject,
		deleteProject,
		closeProject,
		openProjectFile,
		openProjectWithProjectId
	};
}
