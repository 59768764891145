import { Icon } from "@iconify/react";
import {
	Box,
	IconButton,
	Popover,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import React, { useState } from "react";
import ThisLibraryTab from "./LibraryTab";
import ThisProjectTab from "./ThisProjectTab";
import ChecklistButton from "./ChecklistButton";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
	borderRadius: "0px",
	...(theme.palette.mode === "light" && {
		border: 0,
		color: `${theme.palette.text.primary}!important`
	}),
	"&.Mui-selected, &.Mui-selected:hover": {
		backgroundColor: theme.palette.background["selected"],
		...(theme.palette.mode === "light" && {
			color: `${theme.palette.text.primary}!important`
		})
	}
}));

const ChecklistModal: React.FC<{
	showTooltip?: boolean;
	handleTooltipClose?: any;
	handleTooltipOpen?: any;
}> = ({ showTooltip, handleTooltipClose, handleTooltipOpen }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [toggleValue, setToggleValue] = useState(0);

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const open = Boolean(anchorEl);

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			{/* Checklist Button */}
			<ChecklistButton
				showTooltip={showTooltip}
				handleTooltipClose={handleTooltipClose}
				handleTooltipOpen={handleTooltipOpen}
				setAnchorEl={setAnchorEl}
			/>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "center",
					horizontal: "left"
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "right"
				}}
				slotProps={{
					paper: {
						sx: {
							backgroundImage: "none",
							bgcolor: "background.paper",
							border: "1px solid",
							borderColor: "primary.main",
							borderRadius: "8px",
							minWidth: { xs: 300, sm: 400 },
							height: { xs: 450, sm: 500 },
							boxShadow: 1
						}
					}
				}}
			>
				{/* Header */}
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					sx={{ p: 1, borderBottom: "1px solid", borderColor: "primary.main" }}
				>
					<Typography sx={{ fontWeight: 500 }}>Checklist</Typography>

					<IconButton sx={{ p: 0, borderRadius: "3px" }} onClick={handleClose}>
						<Icon icon="material-symbols:close" fontSize={22} />
					</IconButton>
				</Stack>

				{/* Toggle buttons */}
				<ToggleButtonGroup
					size="small"
					color="primary"
					value={toggleValue}
					exclusive
					onChange={(e, v) => {
						if (v !== null) setToggleValue(v);
					}}
					fullWidth
					sx={{
						borderRadius: "0px",
						height: 38,
						bgcolor: "background.default",
						borderBottom: "1px solid",
						borderColor: "primary.main",
						...(themeMode === "light" && {
							bgcolor: "background.paper",
							boxShadow: 1
						})
					}}
				>
					<StyledToggleButton value={0}>This project</StyledToggleButton>

					<StyledToggleButton value={1}>Library</StyledToggleButton>
				</ToggleButtonGroup>

				<Box sx={{ p: 1 }}>
					{toggleValue === 0 && <ThisProjectTab />}

					{toggleValue === 1 && <ThisLibraryTab />}
				</Box>
			</Popover>
		</>
	);
};

export default React.memo(ChecklistModal);
