import { Box, Typography } from "@mui/material";
import React from "react";

const PdfReader: React.FC<{ file: any }> = ({ file }) => {
	return (
		<Box sx={{ p: 5 }}>
			<Typography>PDF renderer coming soon...</Typography>
		</Box>
	);
};

export default PdfReader;
