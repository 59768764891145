import {
	deleteProjectFindingById,
	loadProjectFindings
} from "Resources/ServerInterface";
import { useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { findingsAtom } from "store/atoms/findingsAtom";
import { projectAtom } from "store/atoms/projectToolAtoms";

export function useFindings() {
	const setFindings = useSetAtom(findingsAtom);

	const project = useAtomCallback(
		useCallback((get) => {
			const curr = get(projectAtom);
			return curr;
		}, [])
	);

	const loadFindings = async (projectId: string) => {
		try {
			const projectFindings = await loadProjectFindings(projectId);
			setFindings(projectFindings);
			return projectFindings;
		} catch (error) {
			console.log(error);
			setFindings([]);
		}
	};

	const deleteFinding = async (findingId: string, projectId: string) => {
		try {
			await deleteProjectFindingById(findingId);
			return await loadFindings(projectId);
		} catch (error) {
			console.log(error);
			setFindings([]);
		}
	};

	return { loadFindings, deleteFinding };
}
