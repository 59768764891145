import {
	templateAddedToCollectionEvent,
	templateCollectionCreatedEvent,
	templateCollectionDeletedEvent
} from "Mixpanel/mixpanel.helper";
import { addErrorNotification } from "./Notifications";
import { getCsrfToken } from "./ServerInterface";

export interface TemplateCollection {
	isPublic: boolean;
	collectionName: string;
	templates: Template[];
	creatorID?: string;
	_id?: string;
}

export interface Template {
	name: string;
	contents: string;
	description: string;
}

const SERVER_URL =
	window.location.protocol +
	"//" +
	window.location.hostname +
	":" +
	(process.env.REACT_APP_SERVER_PORT || "5000");

export async function loadTemplateCollections(): Promise<TemplateCollection[]> {
	try {
		const url =
			SERVER_URL +
			`/templateCollection?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch(url, { credentials: "include" });
		const resText = await res.text();

		if (res.ok) {
			if (resText) {
				const templateCollections = JSON.parse(resText) as TemplateCollection[];
				return templateCollections;
			} else return [];
		} else throw new Error(resText);
	} catch (e) {
		// addErrorNotification("Error", "Error loading template collections: " + e.message);
		return [];
	}
}

export async function createTemplateCollection(
	collectionName: string,
	mixpanel: any
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/templateCollection/create?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch(url, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify({ collectionName })
		});

		templateCollectionCreatedEvent(mixpanel, { collectionName });

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification(
			"Error",
			"Error creating template collection: " + e.message
		);
	}
}

export async function addTemplateToCollection(
	template: Template,
	templateCollectionId: string,
	mixpanel: any
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/templateCollection/add/${templateCollectionId}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch(url, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify(template)
		});

		templateAddedToCollectionEvent(mixpanel, {
			templateName: template.name,
			templateCollectionId
		});

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification(
			"Error",
			"Error adding template to collection: " + e.message
		);
	}
}

export async function deleteTemplateCollectionById(
	templateCollectionId: string,
	mixpanel: any
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/templateCollection/delete/${templateCollectionId}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch(url, {
			method: "DELETE",
			credentials: "include"
		});

		templateCollectionDeletedEvent(mixpanel, { templateCollectionId });

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification(
			"Error",
			"Error deleting template collection: " + e.message
		);
	}
}
