import { SxProps, Theme } from "@mui/material";
import { hexToCSSFilter } from "hex-to-css-filter";
import hex from "utils/hexTransparency";
import ScanIcon from "assets/icons/scanIcon.png";

export const scanStyles = (theme: Theme) => {
	const styles: SxProps = {
		"& .editor-scan-High": {
			bgcolor: `${theme.palette.error.main}${hex["10%"]}`
		},
		"& .editor-scan-Medium": {
			bgcolor: `${theme.palette.warning.main}${hex["10%"]}`
		},
		"& .editor-scan-Low": {
			bgcolor: `${theme.palette.success.main}${hex["10%"]}`
		},
		"& .editor-scan-Other": {
			bgcolor: `${theme.palette.info.main}${hex["10%"]}`
		},

		"& .editor-scan-glyph": {
			cursor: "pointer",
			borderRadius: "50%",
			left: "5px !important",
			height: "16px!important",
			width: "16px!important",
			backgroundImage: `url(${ScanIcon})`,
			backgroundSize: "contain",
			backgroundPosition: "center",

			"&.glyph-scan-High": {
				filter:
					"invert(47%) sepia(13%) saturate(2563%) hue-rotate(314deg) brightness(100%) contrast(100%)"
			},
			"&.glyph-scan-Medium": {
				filter:
					"invert(81%) sepia(58%) saturate(527%) hue-rotate(337deg) brightness(300%) contrast(103%)"
			},
			"&.glyph-scan-Low": {
				filter:
					"invert(55%) sepia(78%) saturate(438%) hue-rotate(107deg) brightness(100%) contrast(92%)"
			},
			"&.glyph-scan-Other": {
				filter: hexToCSSFilter(theme.palette.info.main).filter
			}
		}
	};

	return styles;
};

export const SeverityColor = (theme: Theme, severity) => {
	switch (severity) {
		case "High":
			return `${theme.palette.error.main}${hex["30%"]}`;
		case "Medium":
			return `${theme.palette.warning.main}${hex["30%"]}`;
		case "Low":
			return `${theme.palette.success.main}${hex["30%"]}`;
		case "Other":
			return `${theme.palette.info.main}${hex["30%"]}`;

		default:
			return "#FFFFFF";
	}
};
