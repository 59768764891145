import { Button, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";

const TestTracePanel: React.FC<{
	name: string;
	durationNanos: number;
	result?: "pass" | "fail" | "error";
}> = ({ name, result, durationNanos }) => {
	const theme = useTheme();

	const backgroundType = {
		pass: `linear-gradient(269.72deg, ${theme.palette.background["nav"]} 78.02%, ${theme.palette.success.main} 144.27%)`,
		fail: `linear-gradient(269.72deg, ${theme.palette.background["nav"]} 78.02%, ${theme.palette.error.main} 144.27%)`
	};

	function nanosecondsToMilliseconds(nanoseconds: number): number {
		return nanoseconds / 1000000;
	}

	return (
		<Stack
			spacing={1}
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{
				borderRadius: "8px",
				py: 1,
				px: 1.5,
				background: backgroundType[result] || theme.palette.background["nav"]
			}}
		>
			<Stack spacing={1} direction="row" alignItems="center">
				{result === "pass" && (
					<CheckCircleIcon color="success" sx={{ fontSize: 30 }} />
				)}

				{result === "fail" && <ErrorIcon color="error" sx={{ fontSize: 30 }} />}

				<Typography sx={{ textTransform: "uppercase" }}>{result}</Typography>
			</Stack>

			<Typography>{name}</Typography>

			<Stack spacing={1} direction="row" alignItems="center">
				<AccessTimeFilledIcon sx={{ fontSize: 22 }} />

				<Typography>{`Finished in ${nanosecondsToMilliseconds(durationNanos)} ms`}</Typography>
			</Stack>
		</Stack>
	);
};

export default TestTracePanel;
