// AnimatedDialog.js

import { Circle } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Image from "assets/images/playTutorial.png";
// import "./index.css";
import { useAtom } from "jotai";
import "magic.css/dist/magic.min.css";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player/youtube";
import { tutorialModalOpenAtom } from "store/atoms/UiAtoms";
import hex from "utils/hexTransparency";

// vanishIn, twisterInDown, foolishIn, tinLeftIn, boingInUp

const SLIDES = [
	{
		thumbnail: Image,
		videoUrl: "https://youtu.be/3aFebk70Nxc",
		title: "Welcome to Audit Wizard!",
		description:
			"Audit Wizard makes security insights easy and accessible - all just one click away. Run Slither and 4naly3er with a single click, visually graph code and navigate our Interactive AST view"
	},
	{
		thumbnail: Image,
		videoUrl: "https://youtu.be/3aFebk70Nxc",
		title: "Leverage AI insights",
		description:
			"Explain contracts in simple terms, discover security threats and chat with AI about your project"
	}
];

const TutorialDialog = () => {
	const theme = useTheme();

	const [data, setData] = useState(SLIDES[0]);
	const [page, setPage] = useState(0);

	const [tutorialModalOpen, setTutorialModalOpen] = useAtom(
		tutorialModalOpenAtom
	);

	const onClose = () => {
		setTutorialModalOpen(false);

		setTimeout(() => {
			setPage(0);
		}, 1000);
	};

	const handleNextPage = () => {
		if (page === SLIDES.length - 1) return;

		setPage((old) => old + 1);
	};

	const handlePreviousPage = () => {
		if (page === 0) return;

		setPage((old) => old - 1);
	};

	useEffect(() => {
		setData(SLIDES[page]);
	}, [page]);

	return (
		<Dialog
			open={tutorialModalOpen}
			onClose={onClose}
			className="magictime vanishIn"
			maxWidth="sm"
			fullWidth
			hideBackdrop
			sx={{
				"& .MuiPaper-root": {
					bgcolor: "background.paper",
					backgroundImage: "none",
					boxShadow: "none",
					borderRadius: "16px",
					maxWidth: 640
				},
				"&.magictime": {
					backgroundColor: `${theme.palette.primary.dark}${hex["30%"]}`,
					backdropFilter: "blur(5px) !important"
				}
			}}
		>
			<DialogContent sx={{ position: "relative", textAlign: "center" }}>
				<Box
					sx={{
						width: "100%",
						height: 300,
						mb: 2
					}}
				>
					<ReactPlayer
						url={data.videoUrl}
						width="100%"
						height="100%"
						light={
							<Box
								component="img"
								src={data.thumbnail}
								sx={{
									width: "100%",
									height: "100%",
									objectFit: "cover",
									borderRadius: "12px"
								}}
							/>
						}
						controls
						playing
					/>
				</Box>

				{/* Add your content here */}
				<Typography variant="h3" sx={{ mb: 2 }}>
					{data.title}
				</Typography>

				<Typography sx={{ mb: 5, minHeight: 63, maxWidth: 500, mx: "auto" }}>
					{data.description}
				</Typography>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography variant="body2">
						{page + 1} of {SLIDES.length}
					</Typography>

					<Stack
						direction="row"
						alignItems="center"
						justifyContent="flex-end"
						spacing={1}
					>
						{page < SLIDES.length - 1 && (
							<>
								<Button color="inherit" size="small" onClick={onClose}>
									Close
								</Button>

								<Button
									variant="contained"
									size="small"
									onClick={handleNextPage}
								>
									Next
								</Button>
							</>
						)}

						{page === SLIDES.length - 1 && (
							<Button variant="contained" size="small" onClick={onClose}>
								Lets go!
							</Button>
						)}
					</Stack>
				</Stack>

				<Stack
					direction="row"
					alignItems="center"
					sx={{
						position: "absolute",
						bottom: 24,
						left: "50%",
						transform: "translate(-70%, -50%)",
						margin: "0 auto"
					}}
				>
					{SLIDES.map((_, i) => (
						<Circle
							key={i}
							onClick={() => setPage(i)}
							sx={{
								fontSize: 12,
								cursor: "pointer",
								color: i === page ? "primary.main" : grey[700],
								"&:hover": {
									color: i === page ? "primary.light" : grey[600]
								}
							}}
						/>
					))}
				</Stack>
			</DialogContent>
		</Dialog>
	);
};

export default TutorialDialog;
