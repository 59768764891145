import { Box } from "@mui/material";
import SimulationForm, { SimulationFormTypeWithParamTypes } from "components/forms/SimulationForm";
import { useSimulation } from "hooks/data/useSimulation";
import { useAtom } from "jotai";
import React from "react";
import { projectAtom } from "store/atoms/projectToolAtoms";

const BuilderTab: React.FC<{switchTab: Function}> = ({switchTab}) => {
	const { runSimulation, loadTransactions } = useSimulation();
	const [project] = useAtom(projectAtom);

	const handleSubmit = async (data: SimulationFormTypeWithParamTypes) => {
		await runSimulation(data);
		await loadTransactions(project.id);
		switchTab();
	};

	return (
		<Box sx={{ height: "100%" }}>
			{/* Form */}
			<SimulationForm handleSubmit={handleSubmit} />
		</Box>
	);
};

export default React.memo(BuilderTab);
