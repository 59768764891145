import { Typography, TypographyVariant } from "@mui/material";
import { useProject } from "hooks/data/useProject";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { JumpToLineAtom } from "store/atoms/EditorAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";

export const parseClickablePaths = (text) => {
	// eslint-disable-next-line no-useless-escape
	const REGEX = /\([^()]+\#[0-9\-]+\)/g;

	const chunks = text.split(REGEX);
	const matches = text.match(REGEX);

	const resultArray = chunks
		.map((chunk, index) => {
			if (index < matches?.length) {
				const _text = matches[index].slice(1, -1);
				const [filePath, lineNumbers] = _text.split("#");
				const [startLine, endLine] = lineNumbers.split("-");

				return [
					chunk,
					{
						filePath,
						startLineNumber: parseInt(startLine),
						endLineNumber: parseInt(endLine || startLine) || 0,
						startColumn: 0,
						endColumn: 100,
						selectedText: `(${_text})`
					}
				];
			}

			return [chunk];
		})
		.flat()
		.filter(Boolean);

	return resultArray;
};

const ClickablePathText: React.FC<{
	text: string;
	variant?: TypographyVariant;
	handleClick?: any;
}> = ({ text, variant = "body1", handleClick: propsHandleClick }) => {
	const [parsed, setParsed] = useState([]);

	const { openProjectFile } = useProject();

	const [project] = useAtom(projectAtom);

	const setJumpToEditorLine = useSetAtom(JumpToLineAtom);

	useEffect(() => {
		if (text) {
			setParsed(parseClickablePaths(text));
		}
	}, [text]);

	// Function to handle clicking on a path
	const handleClick = (_item) => {
		if (propsHandleClick) {
			return propsHandleClick(_item);
		}

		if (project.id) {
			const fileToOpen = `${project.rootFolder.name}/${_item.filePath}`;
			openProjectFile(fileToOpen);

			if (_item.startLineNumber) {
				setJumpToEditorLine({
					startLineNumber: _item.startLineNumber,
					endLineNumber: _item.endLineNumber
				});
			}
		}
	};

	return (
		<Typography
			variant={variant}
			sx={{ wordWrap: "break-word", whiteSpace: "pre-line" }}
		>
			{parsed.map((fragment, i) => {
				if (typeof fragment === "object") {
					return (
						<Typography
							variant={variant}
							component="span"
							key={i}
							onClick={() => handleClick(fragment)}
							sx={{ color: "primary.main", cursor: "pointer" }}
						>
							{fragment.selectedText}
						</Typography>
					);
				} else {
					// Otherwise, just display the text
					return (
						<Typography variant={variant} component="span" key={i}>
							{fragment}
						</Typography>
					);
				}
			})}
		</Typography>
	);
};

export default ClickablePathText;
