import { addMonacoAction } from "@auditware/CodeEditorCore/helpers";
import { useTheme } from "@mui/material";
import { mixpanelAtom } from "atoms";
import ConfirmationModal from "components/CustomModal/ConfirmationModal";
import { useFindings } from "hooks/data/useFindings";
import { useTools } from "hooks/ui/useTools";
import { useAtom, useSetAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { openedTabAtom } from "store/atoms/UiAtoms";
import { findingToEditAtom, findingsAtom } from "store/atoms/findingsAtom";
import { projectAtom } from "store/atoms/projectToolAtoms";
import FindingsWidget from "./FindingsWidget";
import {
	addFindingViewZones,
	parseFindings,
	removeFindingsGlyphsAndListeners
} from "./helpers";

const FindingsAddon: React.FC<{ editorRef: any; monacoRef: any }> = ({
	editorRef,
	monacoRef
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [viewZoneIds, setViewZoneIds] = useState([]);
	const [viewAnchor, setViewAnchor] = React.useState<null | any>(null);

	const [mixpanel] = useAtom(mixpanelAtom);
	const [findings] = useAtom(findingsAtom);
	const [project] = useAtom(projectAtom);
	const [parsedFindings, setParsedFindings] = useState([]);

	const setFindingToEdit = useSetAtom(findingToEditAtom);

	const [deleteFindingModalOpen, setDeleteFindingModalOpen] = useState<
		string | null
	>(null);

	const [{ projectFilePath }] = useAtom(openedTabAtom);

	const { selectTool } = useTools();
	const { deleteFinding } = useFindings();

	const widgetRef = useRef(null);

	// Add Finding button to right click menu
	useEffect(() => {
		if (editorRef && monacoRef) {
			addMonacoAction(editorRef, monacoRef, handleAddFinding, {
				id: "editor-add-finding-action",
				label: "Add Finding",
				keybindings: [
					monacoRef.KeyMod.CtrlCmd |
						monacoRef.KeyMod.Shift |
						monacoRef.KeyCode.KeyB
				],
				contextMenuOrder: 3
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editorRef, monacoRef]);

	// Parse findings
	useEffect(() => {
		setParsedFindings(
			parseFindings(
				findings.filter((i) => i.location?.includes?.(projectFilePath)),
				theme
			)
		);
	}, [findings, projectFilePath, theme]);

	// Add finding decorations to monaco
	useEffect(() => {
		if (parsedFindings?.length && editorRef) {
			// Glyph
			removeFindingsGlyphsAndListeners(editorRef, widgetRef, viewZoneIds);
			editorRef?.createDecorationsCollection?.(parsedFindings);

			// Zone
			setViewZoneIds(
				addFindingViewZones(
					editorRef,
					parsedFindings,
					viewZoneIds,
					handleViewFinding,
					handleEditFinding,
					handleDeleteFinding,
					themeMode
				)
			);
		} else {
			// Glyph
			removeFindingsGlyphsAndListeners(editorRef, widgetRef, viewZoneIds);
			editorRef?.createDecorationsCollection?.(parsedFindings);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parsedFindings, editorRef]);

	const closeViewFinding = () => {
		setViewAnchor(null);
	};

	const handleViewFinding = (e, _finding) => {
		setViewAnchor({ target: e.currentTarget, finding: _finding });
	};

	const handleEditFinding = (_finding) => {
		const foundIndex = findings.findIndex((i) => i._id === _finding._id);

		if (foundIndex > -1) {
			setFindingToEdit({
				title: _finding.name,
				description: _finding.description,
				severity: _finding.severity,
				recommendation: _finding.recommendation,
				source: _finding.source,
				location: _finding.location
			});

			return setTimeout(() => {
				selectTool("AddFinding", {
					editMode: true,
					_id: _finding._id
				});
			}, 100);
		}
	};

	const handleDeleteFinding = (_finding) => {
		return setTimeout(() => {
			setDeleteFindingModalOpen(_finding._id);
		}, 100);
	};

	const _deleteFinding = async (findingId) => {
		await deleteFinding(findingId, project.id);
		setDeleteFindingModalOpen(null);
	};

	const handleAddFinding = () => {
		selectTool("AddFinding", { mode: null });

		return setTimeout(() => {
			selectTool("AddFinding", { mode: "new" });
		}, 100);
	};

	return (
		<>
			<ConfirmationModal
				open={!!deleteFindingModalOpen}
				onClose={() => setDeleteFindingModalOpen(null)}
				onConfirm={() => _deleteFinding(deleteFindingModalOpen || "")}
				title="Delete Finding?"
				description="Are you sure you want to delete this finding?"
				confirmButtonText="Delete"
				confirmButtonLoadingText="Deleting..."
			/>

			{viewAnchor?.finding && (
				<FindingsWidget
					viewAnchor={viewAnchor}
					closeViewFinding={closeViewFinding}
					handleEditFinding={handleEditFinding}
					handleDeleteFinding={handleDeleteFinding}
				/>
			)}
		</>
	);
};

export default React.memo(FindingsAddon);
