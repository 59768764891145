import { ASTNode } from "solidity-parser-antlr";
import { fetchByKeyAsync, fetchByKeysAsync } from "./ServerInterface";

export default class ProjectFile {
	readonly name: string;
	text: string;
	readonly id: string;
	ast: ASTNode;
	readonly metadata: object = {};

	constructor(
		name: string,
		contents: string,
		id: string,
		ast: ASTNode,
		metadata?: object
	) {
		this.name = name;
		this.text = contents;
		this.id = id;
		this.ast = ast;
		this.metadata = metadata;
	}

	static async getTextForMany(fileIds: string[]): Promise<Map<string,string>> {
		const fileData = (await fetchByKeysAsync(fileIds)) as any[];
		const filesFromIndexedDb: ProjectFile[] = fileData.map((dbValue) => {
			try {
				return dbValue.value as ProjectFile;
			} catch {
				return null;
			}
		});

		const fileToTextMap = new Map<string, string>();
		fileIds.forEach((id) => {
			const fileFromDb = filesFromIndexedDb.find((projFile) => projFile.id === id);
			if (fileFromDb) {
				fileToTextMap.set(id, fileFromDb.text);
			} else {
				fileToTextMap.set(id, null);
			}
		})

		return fileToTextMap;
	}

	static async getASTForMany(fileIds: string[]): Promise<Map<string, ASTNode>> {
		const fileData = (await fetchByKeysAsync(fileIds)) as any[];
		const filesFromIndexedDb: ProjectFile[] = fileData.map((dbValue) => {
			try {
				return dbValue.value as ProjectFile;
			} catch {
				return null;
			}
		});

		const fileToASTMap = new Map<string, ASTNode>();
		fileIds.forEach((id) => {
			const fileFromDb = filesFromIndexedDb.find((projFile) => projFile.id === id);
			if (fileFromDb) {
				fileToASTMap.set(id, fileFromDb.ast);
			} else {
				fileToASTMap.set(id, null);
			}
		})

		return fileToASTMap;
	}

	async getText(): Promise<string> {
		if (this.text) return this.text;

		const indexDBData: any = await fetchByKeyAsync(this.id);
		if (indexDBData) {
			this.text = indexDBData.text;
			return indexDBData.text;
		} else {
			return null;
		}
	}

	async getAST(): Promise<ASTNode> {
		if (this.ast) return this.ast;

		const indexDBData: any = await fetchByKeyAsync(this.id);
		if (indexDBData) {
			this.ast = indexDBData.ast;
			return indexDBData.ast;
		} else {
			return null;
		}
	}
}
