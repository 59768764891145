import { Box, Hidden, Skeleton, Stack } from "@mui/material";
import EllipsisAnimation from "components/Toolbox/Tools/AITool/EllipsisAnimation";

const SkeletonTemplate = () => {
	return (
		<Stack direction="row" sx={{ height: "100%", width: "100%" }}>
			{/* Left project view */}
			<Hidden mdDown>
				<Stack
					spacing={2}
					sx={{
						px: 2,
						py: 4,
						width: 280,
						height: "100%",
						bgcolor: "background.nav"
					}}
				>
					{Array(5)
						.fill(5)
						.map((_, i) => (
							<Skeleton
								key={i}
								variant="rectangular"
								width="100%"
								height={40}
								sx={{ borderRadius: "8px" }}
							/>
						))}
				</Stack>
			</Hidden>

			{/* Main Content */}
			<Stack spacing={2} sx={{ height: "100%", flex: 1, p: 2 }}>
				{/* Tool Selector */}
				<Stack
					direction="row"
					alignItems="center"
					spacing={2}
					sx={{ overflow: "hidden" }}
				>
					{Array(5)
						.fill(5)
						.map((_, i) => (
							<Skeleton
								key={i}
								variant="rectangular"
								width={80}
								height={38}
								sx={{ borderRadius: "8px" }}
							/>
						))}

					<Box sx={{ flex: 1 }} />

					{Array(2)
						.fill(2)
						.map((_, i) => (
							<Skeleton
								key={i}
								variant="rectangular"
								width={110}
								height={38}
								sx={{ borderRadius: "8px" }}
							/>
						))}
				</Stack>

				{/* Code Editor */}
				<Stack
					alignItems="center"
					justifyContent="center"
					sx={{ bgcolor: "background.paper", flex: 1, borderRadius: "12px" }}
				>
					<EllipsisAnimation />
				</Stack>
			</Stack>
		</Stack>
	);
};

export default SkeletonTemplate;
