import {
	Box,
	Divider,
	Grid,
	Skeleton,
	Stack,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import { getPartnerAdsData } from "Resources/ServerInterface";
import { usernameAtom } from "atoms";
import { useImportProject } from "hooks/data/useImportProject";
import { useAtom, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import {
	PartnerAdType,
	importingAtom,
	partnerAdsAtom
} from "store/atoms/ImportsAtoms";
import { loginModalOpenAtom } from "store/atoms/UiAtoms";
import ContestsBountiesCard from "./ContestsBountiesCard";

export const StyledGridContainer = styled(Box, {
	shouldForwardProp: (prop) => {
		return prop !== "fixedWidth";
	}
})<{ fixedWidth?: boolean }>(({ theme, fixedWidth }) => ({
	display: "grid",
	gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))",
	gridGap: "16px",
	[theme.breakpoints.up("md")]: {
		...(fixedWidth && {
			gridTemplateColumns: "repeat(auto-fit, minmax(280px, 295px))"
		})
	}
}));

interface TimeRemainingType {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
	state: "upcoming" | "expired" | "active";
}

export const calculateTimeRemaining = (
	startTime: number,
	endTime: number
): TimeRemainingType => {
	const currentTime = new Date().getTime();
	let targetTime;
	let state: any = "active";

	// Determine target time based on current time and provided start/end times
	if (currentTime < startTime) {
		targetTime = startTime;
		state = "upcoming";
	} else if (currentTime > endTime) {
		targetTime = endTime;
		state = "expired";
	} else {
		targetTime = endTime;
		state = "active";
	}

	// Calculate time difference
	let timeRemaining = targetTime - currentTime;

	// Calculate days, hours, minutes, and seconds
	let days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
	let hours = Math.floor(
		(timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
	);
	let minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
	let seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

	return {
		days,
		hours,
		minutes,
		seconds,
		state
	};
};

const ContestsBountiesProjects: React.FC<{
	type: "contests" | "bounties";
}> = ({ type }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const { handleProjectClick } = useImportProject();

	const [loading, setLoading] = useState(true);

	const [partnerAds, setPartnerAds] = useAtom(partnerAdsAtom);
	const [username] = useAtom(usernameAtom);
	const setLoginModalOpen = useSetAtom(loginModalOpenAtom);
	const setImporting = useSetAtom(importingAtom);

	const contestsProject = partnerAds.filter(
		(i) => !["Immunefi", "Hats"].includes(i.source)
	);

	const bountiesProject = partnerAds.filter((i) =>
		["Immunefi", "Hats"].includes(i.source)
	);

	const _projects = type === "contests" ? contestsProject : bountiesProject;

	useEffect(() => {
		const load = async () => {
			try {
				const partnerAds: PartnerAdType[] = await getPartnerAdsData();
				setPartnerAds(partnerAds);
			} catch (error) {
				console.log("Error loading partnerAds", error);
			} finally {
				setLoading(false);
			}
		};

		load();
	}, []);

	if (loading && !_projects?.length) {
		return (
			<Grid container spacing={2}>
				{Array(8)
					.fill("")
					?.map((_, i) => (
						<Grid item xs={12} sm={6} lg={4} xl={3} key={i}>
							<Skeleton
								variant="rectangular"
								width={"100%"}
								height={"256px"}
								sx={{ borderRadius: "8px" }}
							/>
						</Grid>
					))}
			</Grid>
		);
	}

	if (!_projects?.length) {
		return (
			<Stack alignItems="center" justifyContent="center" sx={{ pt: 2 }}>
				<Typography variant="h6" sx={{ textAlign: "center" }}>
					No {type} at this time. Check back later
				</Typography>
			</Stack>
		);
	}

	const _activeProjects = [];
	const _upcomingProjects = [];
	const _expiredProjects = [];

	_projects.map((proj) => {
		const _timer = calculateTimeRemaining(proj?.startTime, proj?.endTime);

		switch (_timer.state) {
			case "active":
				_activeProjects.push(proj);
				break;
			case "expired":
				_expiredProjects.push(proj);
				break;
			case "upcoming":
				_upcomingProjects.push(proj);
				break;
		}
	});

	const activeProjects = _activeProjects.sort((a, b) => a.endTime - b.endTime);
	const upcomingProjects = _upcomingProjects.sort(
		(a, b) => a.endTime - b.endTime
	);
	const expiredProjects = _expiredProjects.sort(
		(a, b) => a.endTime - b.endTime
	);

	// Bounties
	if (type === "bounties") {
		return (
			<Box>
				<StyledGridContainer fixedWidth={_projects?.length < 4}>
					{_projects?.map((data, i) => (
						<ContestsBountiesCard
							key={i}
							data={data}
							type={type}
							handleProjectClick={() => {
								if (!username) {
									return setLoginModalOpen(true);
								}

								setImporting(true);
								handleProjectClick(data, i);
							}}
						/>
					))}
				</StyledGridContainer>
			</Box>
		);
	}

	// Contests
	return (
		<Box>
			{/* Active and Upcoming */}
			<Typography sx={{ mb: 1.5, "& span": { fontWeight: 600 } }}>
				<span>{activeProjects.length}</span> Live
			</Typography>

			<StyledGridContainer
				fixedWidth={upcomingProjects?.length + activeProjects?.length < 4}
			>
				{activeProjects?.map((data, i) => (
					<ContestsBountiesCard
						key={i}
						data={data}
						type={type}
						timeState="active"
						handleProjectClick={() => {
							if (!username) {
								return setLoginModalOpen(true);
							}

							setImporting(true);
							handleProjectClick(data, i);
						}}
					/>
				))}

				{upcomingProjects?.map((data, i) => (
					<ContestsBountiesCard
						key={i}
						data={data}
						type={type}
						timeState="upcoming"
						handleProjectClick={() => {
							if (!username) {
								return setLoginModalOpen(true);
							}

							setImporting(true);
							handleProjectClick(data, i);
						}}
					/>
				))}
			</StyledGridContainer>

			<Divider sx={{ mt: 2, mb: 1.5 }} />

			{/* Expired */}
			<Typography sx={{ mb: 1.5, "& span": { fontWeight: 600 } }}>
				<span>{expiredProjects.length}</span> Ended
			</Typography>

			<StyledGridContainer fixedWidth={expiredProjects?.length < 4}>
				{expiredProjects?.map((data, i) => (
					<ContestsBountiesCard
						key={i}
						data={data}
						type={type}
						timeState="expired"
						handleProjectClick={() => {
							if (!username) {
								return setLoginModalOpen(true);
							}

							handleProjectClick(data, i);
						}}
					/>
				))}
			</StyledGridContainer>
		</Box>
	);
};

export default ContestsBountiesProjects;
