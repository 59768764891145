import React, { createContext, useContext, useEffect, useState } from "react";

const types = ["error", "success", "aw_announcement", "aw_updates"];
export const DUMMY_NOTIFICATIONS = new Array(10).fill(null).map((_, i) => ({
	id: `ID-${i}`,
	title: "Audit Wizard update ",
	description:
		"Over the weekend we released some big upgrades to the Audit Wizard!",
	read: false,
	type: types[Math.floor(Math.random() * types.length)],
	timestamp: new Date().toISOString()
}));

export interface NotificationType {
	id: string;
	title: string;
	description: string;
	read: boolean;
	timestamp: string;
	type:
		| "error"
		| "success"
		| "alert"
		| "aw_announcement"
		| "aw_updates"
		| string; // remove this when not using dummy data
}

interface NotificationsContextType {
	notifications: NotificationType[];
	setNotifications: (val: NotificationType[]) => void;
	unreadNotifications: NotificationType[];
	addNotification: (val: NotificationType) => void;
	toggleReadNotification: (id: string) => void;
	deleteNotification: (id: string) => void;
	markAllAsRead: () => void;
}

const NotificationsContext = createContext<NotificationsContextType>({
	notifications: null,
	setNotifications: () => {},
	unreadNotifications: null,
	addNotification: () => {},
	toggleReadNotification: () => {},
	deleteNotification: () => {},
	markAllAsRead: () => {}
});

export const NotificationsContextProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const [notifications, setNotifications] = useState<NotificationType[]>([]);

	useEffect(() => {
		setNotifications(DUMMY_NOTIFICATIONS);
	}, []);

	const addNotification = (val: NotificationType) => {
		setNotifications((old) => [val, ...old]);
	};

	const toggleReadNotification = (id: string) => {
		setNotifications((prevArray) =>
			prevArray.map((item) =>
				item.id === id ? { ...item, read: !item.read } : item
			)
		);
	};

	const markAllAsRead = () => {
		setNotifications((prevArray) =>
			prevArray.map((item) => ({ ...item, read: true }))
		);
	};

	const deleteNotification = (id: string) => {
		setNotifications((prevArray) => prevArray.filter((item) => item.id !== id));
	};

	const unreadNotifications = notifications.filter((i) => !i.read);

	return (
		<NotificationsContext.Provider
			value={{
				notifications,
				setNotifications,
				unreadNotifications,
				addNotification,
				toggleReadNotification,
				deleteNotification,
				markAllAsRead
			}}
		>
			{children}
		</NotificationsContext.Provider>
	);
};

export const useNotificationsContext = () =>
	useContext<NotificationsContextType>(NotificationsContext);
