import { Icon } from "@iconify/react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { getHomepageNews } from "Resources/FirebaseInterface";
import useFirebase from "hooks/useFirebase";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { homepageFeaturesAtom } from "store/atoms/FirebaseAtoms";

const Features = () => {
	const [loading, setLoading] = useState(true);
	const [fetched, setFetched] = useState(false);
	const [features, setFeatures] = useAtom(homepageFeaturesAtom);

	const { db } = useFirebase();

	useEffect(() => {
		if (db && !fetched) {
			getHomepageNews(db)
				.then(setFeatures)
				.finally(() => setFetched(true));
		}
	}, [db, fetched]);

	useEffect(() => {
		if (!!features.length) {
			setLoading(false);
		}
	}, [features]);

	const settings = {
		autoplay: true,
		autoplaySpeed: 5000,
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false
	};

	return (
		<Stack
			sx={{
				// height: "100%",
				p: 2,
				pt: 1.7,
				borderRadius: "10px",
				width: "100%",
				bgcolor: "background.default"
			}}
		>
			<Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1.5 }}>
				<Icon icon="ph:star-fill" fontSize={18} />
				<Typography>News and Features</Typography>
			</Stack>

			{loading && (
				<Skeleton
					height={170}
					variant="rectangular"
					sx={{ borderRadius: "8px" }}
				/>
			)}

			{!loading && (
				<Box
					sx={{
						pb: 2,
						"& .slick-dots": {
							"& li": { m: 0 },
							"& li button::before": {
								fontSize: "8px",
								color: "primary.main"
							},
							"& li.slick-active button::before": {
								fontSize: "9px",
								color: "primary.main"
							}
						}
					}}
				>
					<Slider {...settings}>
						{features?.map?.((item, i) => {
							return (
								<Box key={i}>
									<Box
										sx={{
											position: "relative",
											minHeight: 150,
											height: "100%",
											cursor: "pointer",
											borderRadius: "10px",
											mb: 0.5
										}}
									>
										<Box
											component="img"
											onClick={(e) => {
												e.stopPropagation();
												window.open(item.redirectUrl, "_blank");
											}}
											src={item.imageUrl}
											sx={{
												position: "absolute",
												height: "100%",
												width: "100%",
												objectFit: "cover",
												borderRadius: "inherit",
												top: 0,
												bottom: 0,
												left: 0,
												right: 0
											}}
										/>

										{item.thumbnailType === "video" && (
											<Stack
												alignItems="center"
												justifyContent="center"
												sx={{
													position: "absolute",
													top: 0,
													left: 0,
													right: 0,
													bottom: 0,
													bgcolor: "rgba(0,0,0,0.5)",
													borderRadius: "inherit",
													"& .MuiSvgIcon-root": {
														fontSize: 40
													},
													"&:hover": {
														"& .MuiSvgIcon-root": {
															fontSize: 44
														}
													}
												}}
											>
												<PlayArrowIcon />
											</Stack>
										)}
									</Box>

									<Typography sx={{ mt: 1.5, textAlign: "center" }}>
										{item.title}
									</Typography>
								</Box>
							);
						})}
					</Slider>
				</Box>
			)}
		</Stack>
	);
};

export default React.memo(Features);
