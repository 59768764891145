import { Stack } from "@mui/material";
import { SimpleBarStyled } from "components/MainContentLayout/components/FileTabs/TabsList";
import { useHorizontalScroll } from "hooks/ui/useHorizontalScroll";
import { useAtom } from "jotai";
import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { terminalTabsAtom } from "store/atoms/terminalAtoms";
import TabItem from "./TabItem";

const TabsList = () => {
	const [terminalTabs] = useAtom(terminalTabsAtom);

	const scrollRef = useHorizontalScroll();

	return (
		<SimpleBarStyled
			autoHide
			clickOnTrack={true}
			scrollableNodeProps={{
				ref: scrollRef
			}}
		>
			<Droppable droppableId="droppable" direction="horizontal">
				{(provided, snapshot) => {
					const isDraggingOver = snapshot.isDraggingOver;

					return (
						<Stack
							{...provided.droppableProps}
							direction="row"
							ref={provided.innerRef}
							sx={{
								width: "fit-content",
								minHeight: 34
							}}
						>
							{terminalTabs.map((tab, i) => (
								<TabItem key={i} index={i} name={tab.name} id={tab.path} />
							))}

							{provided.placeholder}
						</Stack>
					);
				}}
			</Droppable>
		</SimpleBarStyled>
	);
};

export default React.memo(TabsList);
