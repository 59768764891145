import { Close as CloseIcon } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import {
	createFinding,
	loadProjectFindings,
	updateFinding
} from "Resources/ServerInterface";
import { mixpanelAtom } from "atoms";
import AddFindingsForm, {
	AddFindingsFormType
} from "components/forms/AddFindingsForm";
import { useTools } from "hooks/ui/useTools";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { findingToEditAtom, findingsAtom } from "store/atoms/findingsAtom";
import { projectAtom } from "store/atoms/projectToolAtoms";
import { ToolboxCloseButton } from "../..";
import { Finding } from "../FindingsTool";

interface Props {
	editMode?: boolean;
	values?: any;
	_id?: string;
}

const AddFindingsTool: React.FC<Props> = ({ editMode, values, _id }) => {
	const [loading, setLoading] = useState(false);
	const [mixpanel] = useAtom(mixpanelAtom);

	const setFindings = useSetAtom(findingsAtom);
	const [findingToEdit] = useAtom(findingToEditAtom);

	const { selectTool } = useTools();

	const [project] = useAtom(projectAtom);

	const addFinding = async (data: AddFindingsFormType) => {
		const { title, severity, description, location, recommendation } = data;

		setLoading(true);

		const newFinding: Finding = {
			name: title,
			description: description,
			severity: severity,
			projectID: project.id,
			source: "Manual",
			_id: "",
			location,
			recommendation
		};

		await createFinding(newFinding, mixpanel, project.privateMode);
		setLoading(false);

		const findings = await loadProjectFindings(project.id);
		setFindings(findings);

		selectTool("Findings");
	};

	const editFinding = async (data: AddFindingsFormType) => {
		const { title, severity, description, location, recommendation } = data;

		setLoading(true);

		const newFinding: Finding = {
			name: title,
			description: description,
			severity: severity,
			projectID: project.id,
			source: "Manual",
			_id,
			location,
			recommendation
		};

		await updateFinding(newFinding, project.privateMode);
		setLoading(false);

		const findings = await loadProjectFindings(project.id);
		setFindings(findings);

		selectTool("Findings");
	};

	const Header = () => (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{ mb: 3 }}
		>
			<Typography variant="h6">
				{editMode ? "Edit Finding" : "Add Finding"}
			</Typography>

			{editMode ? (
				<IconButton
					size="small"
					color="inherit"
					onClick={() => selectTool("Findings")}
				>
					<CloseIcon />
				</IconButton>
			) : (
				<ToolboxCloseButton />
			)}
		</Stack>
	);

	return (
		<div>
			<Header />

			<AddFindingsForm
				handleSubmit={editMode ? editFinding : addFinding}
				disabled={loading}
				edit={editMode}
				values={values ? values : editMode ? findingToEdit : {}}
			/>
		</div>
	);
};

export default AddFindingsTool;
