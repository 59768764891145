import { atom, useAtom } from "jotai";
import { useMemo } from "react";

const useSetAtom = (anAtom) => {
	const writeOnlyAtom = useMemo(
		() => atom(null, (get, set, x) => set(anAtom, x)),
		[anAtom]
	);
	return useAtom(writeOnlyAtom)[1];
};

export default useSetAtom;
