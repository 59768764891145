export const findLineNumberFromText = (text: string, searchString: string) => {
	const lines = text.split("\n");
	for (let i = 0; i < lines.length; i++) {
		if (lines[i].includes(searchString)) {
			return i + 1;
		}
	}
	return -1;
};

export const getLineFromMultilineString = (
	multilineString: string,
	lineNumber: number
) => {
	const lines = multilineString.split("\n");

	if (lineNumber >= 0 && lineNumber < lines.length) {
		return lines[lineNumber];
	} else {
		return null; // Return null or any other indicator for an out-of-range request
	}
};
