import { useEffect, useMemo, useState } from "react";
import { set as idbSet, get as idbGet, createStore } from "idb-keyval";

type UseIdbOptions<T> = {
	defaultValue?: T;
	dbName?: string;
	storeName?: string;
};

export function useIdb<T>(key: string, options?: UseIdbOptions<T>) {
	const {
		defaultValue = null,
		dbName = "aw-db",
		storeName = "aw-store"
	} = options || {};

	const [loadingDb, setLoadingDb] = useState(true);
	const [state, setState] = useState<T | undefined>(defaultValue);

	const store = createStore(dbName, storeName);

	useMemo(() => {
		// Load initial value from IndexedDB
		const loadValue = async () => {
			try {
				const storedValue = await idbGet<T>(key, store);
				if (storedValue !== undefined) {
					setState(storedValue);
				}
			} catch (error) {
				console.error("Error loading value from IndexedDB:", error);
			} finally {
				setLoadingDb(false);
			}
		};

		loadValue();
	}, [key]);

	const setPersistedState = async (newValue: T) => {
		try {
			// Update the state
			setState(newValue);
			// Persist the new value to IndexedDB
			await idbSet(key, newValue, store);
		} catch (error) {
			console.error("Error setting value in IndexedDB:", error);
		}
	};

	return [state, setPersistedState, loadingDb] as const;
}
