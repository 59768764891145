import { Box } from "@mui/material";
import MarkdownReader from "components/MainContentLayout/MarkdownReader";
import React, { useEffect, useState } from "react";
import { TerminalTabContentType } from "store/atoms/terminalAtoms";

const CheatcodeContent: React.FC<TerminalTabContentType> = ({ content }) => {
	return (
		<Box sx={{ px: 2, position: "relative" }}>
			<MarkdownReader value={content} sx={{ py: 0 }} withCopyButton />
		</Box>
	);
};

export default React.memo(CheatcodeContent);
