import { TypeBackground, TypeText, createTheme } from "@mui/material";
import themeTypography from "./typography";

export interface CustomTypeText extends TypeText {
	inverse: string;
}

export interface CustomBackgroundType extends TypeBackground {
	nav?: string;
	paper2?: string;
	selected?: string;
	selected2?: string;
}

const darkTheme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "#8870E6",
			light: "#CCBFFF",
			dark: "#5C48B9"
		},
		background: {
			default: "#1A1832",
			paper: "#0D0C21",
			nav: "#262348",
			paper2: "#393761",
			selected: "#0D0C21",
			selected2: "#2A294F"
		} as CustomBackgroundType,
		success: {
			main: "#1BCE8E",
			light: "#42FFB6",
			dark: "#0EA67B"
		},
		error: {
			main: "#C95656",
			light: "#FF7878",
			dark: "#B73A3A"
		},
		warning: {
			main: "#E5B129",
			light: "#FFD654",
			dark: "#C49C20"
		},
		info: {
			main: "#1E90FF",
			light: "#66AFFF",
			dark: "#006BDB"
		},
		common: {
			white: "#FFFFFF",
			black: "#000000"
		},
		text: {
			primary: "#FFFFFF",
			secondary: "rgba(255, 255, 255, 0.7)",
			disabled: "rgba(255, 255, 255, 0.5)",
			inverse: "#0D0C21"
		} as CustomTypeText
	},
	typography: themeTypography
});

export default darkTheme;
