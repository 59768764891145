import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Grow, IconButton, Popper, Stack } from "@mui/material";
import React from "react";

const COLORS = [
	"primary.main",
	"success.light",
	"info.light",
	"error.light",
	"warning.light",
	"text.disabled"
];

interface Props {
	color: string;
	onChange: (value: string) => void;
}

const NewTagIcon: React.FC<Props> = ({ color, onChange }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	return (
		<div>
			<IconButton size="small" onClick={handleClick}>
				<BookmarkIcon fontSize="small" sx={{ color }} />
			</IconButton>

			<Popper
				open={open}
				anchorEl={anchorEl}
				placement={"right"}
				sx={{ zIndex: 999999999 }}
			>
				<Stack
					direction="row"
					alignItems="center"
					sx={{ bgcolor: "background.paper" }}
				>
					{COLORS.map((item, i) => (
						<Grow in={open} key={i} timeout={i * 200}>
							<IconButton
								size="small"
								onClick={() => {
									onChange(item);
									setAnchorEl(null);
								}}
							>
								<BookmarkIcon fontSize="small" sx={{ color: item }} />
							</IconButton>
						</Grow>
					))}
				</Stack>
			</Popper>
		</div>
	);
};

export default NewTagIcon;
