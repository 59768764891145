import { Box, Stack, TextField, Typography } from "@mui/material";
import { getEmailIfExists } from "Resources/ServerInterface";
import { usernameAtom } from "atoms";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import SubmissionItem from "./SubmissionItem";
import FormToastEditorField from "components/formFields/FormToastEditorField";

export interface SubmissionItemValues {
    walletAddress: string;
    email: string;
    issueTitle: string;
    issueSeverity: number;
    issueDescription: string;
}

const SubmissionsEditor = ({submissionItem, setSubmissionItem}) => {
    const getSubmissionField = (label: string, onEdit: Function, multiline: boolean, value: string = '', disabled: boolean = false, type: string = null, props: any = null) => {
        return (
            <Stack spacing={1} sx={{flex: 5}}>
                <Typography><strong>{label}</strong></Typography>
            {!multiline && 
                    <TextField
                    variant="outlined"
                    value={value}
                    size="small"
                    placeholder={label}
                    type={type}
                    onChange={onEdit}
                    multiline={multiline}
                    sx={{
                        ...(!multiline && { "& .MuiInputBase-root": { height: 40 } }),
                    }}
                    InputProps={{
                        sx: { borderRadius: "8px", ...(multiline && { py: 2 }) },
                    }}
                    disabled={disabled}
                    {...props}
                />
                }
                {multiline &&
                    <FormToastEditorField
                        value={value}
                        height="200px"
                        name={label}
                        onChange={onEdit}
                    />
                }
            </Stack>
        )
    };

    const isValidEthereumAddress = (address: string): boolean => {
        const ethereumAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
        return ethereumAddressRegex.test(address);
    };
      
    const [username] = useAtom(usernameAtom);
    const [walletAddressLocked, setWalletAddressLocked] = useState(false);

    const getWalletAddress = (): string => {
        if (isValidEthereumAddress(username)) {
            if (!walletAddressLocked) {
                setWalletAddressLocked(true);
            }
            return username;
        }
        return '';
    }

    const [emailLocked, setEmailLocked] = useState(false);

    const getUserEmail = async (): Promise<string> => {
        const email = await getEmailIfExists();
        if (email) {
            console.log(email);
            if (!emailLocked) {
                setEmailLocked(true);
            }
            return email;
        } else {
            return '';
        }
    }

    useEffect(() => {
        const fetchEmail = async () => {
            const email = await getUserEmail();
            setSubmissionItem({...submissionItem, email, walletAddress: getWalletAddress()});
        }

        fetchEmail();
	}, []);

    return (
		<Box
			sx={{
				borderRadius: "20px",
				p: 3,
				bgcolor: "background.paper",
				minHeight: 120,
				overflow: "auto"
			}}
		>
            <Stack direction="row" spacing={2} sx={{width: '100%', mb: 4}}>
                {getSubmissionField('Wallet Address', (e) => {setSubmissionItem({...submissionItem, walletAddress: e.target.value});}, false, submissionItem.walletAddress, walletAddressLocked)}
                <Box sx={{ flex: 1 }} />
                {getSubmissionField('Email', (e) => {setSubmissionItem({...submissionItem, email: e.target.value})}, false, submissionItem.email, emailLocked)}
            </Stack>
            <SubmissionItem getSubmissionField={getSubmissionField} submissionItem={submissionItem} setSubmissionItem={setSubmissionItem}/>
		</Box>
	);
}

export default SubmissionsEditor;