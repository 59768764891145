import { SxProps, Theme } from "@mui/material";
import hex from "utils/hexTransparency";
import LiveIcon from "assets/icons/liveIcon.png";

export const callableValueStyles = (theme: Theme) => {
	const styles: SxProps = {
		"& .editor-callable-live": {
			bgcolor: `${theme.palette.success.main}${hex["10%"]}`
		},

		"& .editor-callable-glyph": {
			cursor: "pointer",
			borderRadius: "50%",
			left: "5px !important",
			height: "16px!important",
			width: "16px!important",
			backgroundImage: `url(${LiveIcon})`,
			backgroundSize: "contain",
			backgroundPosition: "center",

			"&.glyph-callable-green": {
				filter:
					"invert(55%) sepia(78%) saturate(438%) hue-rotate(107deg) brightness(100%) contrast(92%)"
			}
		}
	};

	return styles;
};

export const LiveColor = (theme: Theme) => {
	return `${theme.palette.success.main}${hex["30%"]}`;
};
