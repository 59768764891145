import { BookOutlined } from "@mui/icons-material";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Skeleton,
	Stack,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { importingAtom } from "store/atoms/ImportsAtoms";
import { BranchesAndCommitsType, getBranchesAndCommits } from "utils/githubApi";

interface RepositoryProps {
	repoOwner: string;
	repoName: string;
	repoDescription: string | null;
	repoId: string;
	repoUrl: string;
	importRepo: Function;
}

interface Revision {
	hash: string;
	date: string;
}

const RepositoryCard: React.FC<RepositoryProps> = ({
	repoOwner,
	repoName,
	repoDescription,
	repoId,
	repoUrl,
	importRepo
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [importing, setImporting] = useAtom(importingAtom);

	const [loading, setLoading] = useState(false);
	const [branchesLoading, setBranchesLoading] = useState(false);

	const [expanded, setExpanded] = useState(false);
	const [selectedBranch, setSelectedBranch] =
		useState<BranchesAndCommitsType>(null);
	const [selectedRevision, setSelectedRevision] = useState<Revision>(null);
	const [branches, setBranches] = useState<BranchesAndCommitsType[]>([]);
	const [revisions, setRevisions] = useState<Revision[]>([]);

	const handleBranchChange = (event: SelectChangeEvent<string>) => {
		const branchName = event.target.value as string;
		const branch =
			branches.find((branchData) => branchData.branchName === branchName) ??
			null;
		setSelectedBranch(branch);
		setRevisions(branch.commits);
		setSelectedRevision(branch.commits[0]);
	};

	const handleRevisionChange = (event: SelectChangeEvent<string>) => {
		const commitText = event.target.value;
		const stringParts = commitText.split("|");
		const commitShaShort = stringParts[0].trim();
		const commit = revisions.find((revision) =>
			revision.hash.includes(commitShaShort)
		);
		setSelectedRevision(commit);
	};

	const truncatedHash = (hash: string) => {
		return hash.length > 7 ? `${hash.substring(0, 7)}` : hash;
	};

	const loadBranchesAndCommits = async () => {
		setBranchesLoading(true);

		const branchesAndCommits = await getBranchesAndCommits(
			repoId,
			repoOwner,
			repoName
		);
		setBranches(branchesAndCommits);
		setSelectedBranch(branchesAndCommits.length ? branchesAndCommits[0] : null);
		setRevisions(
			branchesAndCommits.length ? branchesAndCommits[0].commits : []
		);
		setSelectedRevision(
			branchesAndCommits.length ? branchesAndCommits[0].commits[0] : null
		);

		setBranchesLoading(false);
	};

	const triggerImport = () => {
		setImporting(true);
		importRepo(repoUrl, selectedRevision?.hash);
	};

	useEffect(() => {
		if (!branches.length && !loading && expanded) {
			loadBranchesAndCommits();
		}
	}, [expanded, branches, importing]);

	return (
		<Accordion
			expanded={expanded}
			onChange={(e, v) => setExpanded(v)}
			disableGutters
			sx={{
				width: "100%",
				borderRadius: "10px !important",
				backgroundImage: "none",
				bgcolor: "background.default",
				"&.Mui-expanded": { mt: 0 },
				border: expanded && `1px solid ${theme.palette.primary.main}`
			}}
		>
			<AccordionSummary
				sx={{
					py: 0.5,
					px: "16px !important",
					m: "0px !important"
				}}
			>
				<Stack spacing={2} sx={{ width: "100%" }}>
					<Stack direction="row" spacing={1}>
						<BookOutlined sx={{ fontSize: 44 }} />

						<Stack sx={{ flex: 1 }}>
							<Typography sx={{ fontWeight: 600, fontSize: 16 }}>
								{repoName}
							</Typography>
							<Typography variant="body2">{repoOwner}</Typography>
						</Stack>

						<Tooltip title="Add this repo to your projects">
							<IconButton
								disabled={importing}
								sx={{ height: 28, width: 28, alignSelf: "center" }}
								onClick={(e) => {
									e.stopPropagation();
									triggerImport();
								}}
							>
								<SystemUpdateAltIcon fontSize="small" />
							</IconButton>
						</Tooltip>
					</Stack>

					<Box
						sx={{
							p: 1.5,
							bgcolor: "background.paper",
							borderRadius: "10px",
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "-webkit-box",
							WebkitBoxOrient: "vertical",
							WebkitLineClamp: 2,
							height: 60
						}}
					>
						{repoDescription || "No Description"}
					</Box>
				</Stack>
			</AccordionSummary>

			<AccordionDetails sx={{ px: 1.5, pt: 1 }}>
				{branchesLoading && (
					<Stack spacing={2}>
						<Skeleton
							width="100%"
							height="38px"
							variant="rectangular"
							sx={{ borderRadius: "8px" }}
						/>

						<Skeleton
							width="100%"
							height="38px"
							variant="rectangular"
							sx={{ borderRadius: "8px" }}
						/>
					</Stack>
				)}

				{!branchesLoading && (
					<Stack spacing={2}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel>Branch</InputLabel>
							<Select
								value={selectedBranch?.branchName ?? ""}
								onChange={handleBranchChange}
								onClick={(e) => e.stopPropagation()}
								disabled={loading || importing}
								label="Branch"
								size="small"
								sx={{
									borderRadius: "8px",
									...(themeMode === "light" && {
										bgcolor: "background.paper",
										boxShadow: 1,
										"& fieldset": {
											borderColor: "transparent"
										}
									})
								}}
								MenuProps={{
									slotProps: {
										paper: {
											sx: {
												mt: 1,
												bgcolor: "background.paper",
												borderRadius: "8px",
												backgroundImage: "none",
												...(themeMode === "light" && {
													border: `1px solid ${theme.palette.background["paper2"]}`,
													boxShadow: 1
												}),
												...(themeMode === "dark" && {
													border: `2px solid ${theme.palette.primary.main}`,
													boxShadow: "none"
												})
											}
										}
									}
								}}
							>
								{branches.map((branch, i) => (
									<MenuItem
										key={i}
										value={branch.branchName}
										onClick={(e) => e.stopPropagation()}
									>
										{branch.branchName}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl
							variant="outlined"
							fullWidth
							disabled={!selectedBranch}
							size="small"
						>
							<InputLabel>Revision</InputLabel>
							<Select
								label="Revision"
								value={selectedRevision?.hash ?? ""}
								disabled={loading || importing}
								onChange={handleRevisionChange}
								onClick={(e) => e.stopPropagation()}
								sx={{
									borderRadius: "8px",
									...(themeMode === "light" && {
										bgcolor: "background.paper",
										boxShadow: 1,
										"& fieldset": {
											borderColor: "transparent"
										}
									})
								}}
								MenuProps={{
									slotProps: {
										paper: {
											sx: {
												mt: 1,
												bgcolor: "background.paper",
												borderRadius: "8px",
												backgroundImage: "none",
												...(themeMode === "light" && {
													border: `1px solid ${theme.palette.background["paper2"]}`,
													boxShadow: 1
												}),
												...(themeMode === "dark" && {
													border: `2px solid ${theme.palette.primary.main}`,
													boxShadow: "none"
												})
											}
										}
									}
								}}
							>
								{revisions.map(({ hash, date }, i) => (
									<MenuItem
										key={hash}
										value={hash}
										onClick={(e) => e.stopPropagation()}
									>
										{`${i === 0 ? "HEAD" : truncatedHash(hash)} `}(
										<span style={{ color: theme.palette.primary.main }}>
											{date}
										</span>
										)
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Stack>
				)}
			</AccordionDetails>
		</Accordion>
	);
};

export default React.memo(RepositoryCard);
