import {
	Box,
	Fade,
	Stack,
	SxProps,
	Tooltip,
	Typography,
	tooltipClasses,
	useTheme
} from "@mui/material";
import { BadgeSummaryType } from "Resources/ServerInterfaceProfile";
import EmptyBadge from "assets/images/empty-badge.png";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React, { useMemo } from "react";
import { badgesAtom, openBadgeAtom } from "store/atoms/badgesAtom";

const BADGES_PER_ROW = 5;

const Badge: React.FC<{
	badge?: BadgeSummaryType;
	onBadgeClick?: any;
	imageSx?: SxProps;
}> = ({ badge, onBadgeClick, imageSx = {} }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const { imageUrl, name, description, userOwns, rarity, _id } = badge || {};

	return (
		<Tooltip
			title={
				<Stack alignItems="center" spacing={0.5} sx={{ textAlign: "center" }}>
					<Typography variant="body2" sx={{ fontWeight: 600 }}>
						{name}
					</Typography>
					<Typography variant="body2">{description}</Typography>
				</Stack>
			}
			enterDelay={500}
			enterNextDelay={500}
			slotProps={{
				tooltip: {
					sx: {
						[`&.${tooltipClasses.tooltip}`]: {
							maxWidth: 250,
							border: `1px solid ${theme.palette.primary.main}`,
							borderRadius: "8px",
							bgcolor: "background.paper"
						}
					}
				}
			}}
		>
			<Box
				onClick={() => {
					if (userOwns) {
						onBadgeClick(badge);
					}
				}}
				component="img"
				src={imageUrl}
				// onError={(e) => console.log(e)}
				sx={{
					height: 65,
					width: 50,
					// objectFit: "cover",
					maxWidth: "100%",
					cursor: userOwns && "pointer",
					filter: userOwns ? "brightness(1.1)" : "brightness(0.3)",
					...(themeMode === "light" && {
						opacity: userOwns ? 1 : 0.5,
						filter: "brightness(1)"
					}),
					...imageSx
				}}
			/>
		</Tooltip>
	);
};

const Section: React.FC<{
	title: string;
	badges: BadgeSummaryType[];
}> = ({ title, badges }) => {
	const theme = useTheme();

	const setBadge = useSetAtom(openBadgeAtom);

	const ownedBadges = useMemo(() => {
		return badges.filter((i) => !!i.userOwns);
	}, [badges.length]);

	const unOwnedBadges = useMemo(() => {
		return badges.filter((i) => !i.userOwns);
	}, [badges.length]);

	const skeletonBadges: BadgeSummaryType[] =
		badges.length > BADGES_PER_ROW - 1
			? []
			: Array(BADGES_PER_ROW - badges.length)
					.fill(BADGES_PER_ROW - badges.length)
					.map((i) => ({
						_id: `${i}`,
						imageUrl: EmptyBadge,
						name: "Unreleased",
						description: "Unreleased badge",
						rarity: 1,
						type: "none",
						userOwns: false
					}));

	const totalLength = unOwnedBadges.length + ownedBadges.length;
	const value = `${ownedBadges.length}/${totalLength}`;

	return (
		<Box>
			{/* Header */}
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{
					py: 1.2,
					px: 2,
					borderTopRightRadius: "8px",
					borderTopLeftRadius: "8px",
					bgcolor: "background.nav"
				}}
			>
				<Typography sx={{ fontWeight: 500 }}>{title}</Typography>

				<Typography sx={{ "& span": { fontWeight: 500 } }}>
					<span>{value}</span> badges claimed
				</Typography>
			</Stack>

			<Box
				sx={{
					p: 2,
					borderBottomRightRadius: "8px",
					borderBottomLeftRadius: "8px",
					bgcolor: "background.default"
				}}
			>
				<Stack
					direction="row"
					justifyContent={
						totalLength > BADGES_PER_ROW ? "flex-start" : "center"
					}
					columnGap={1}
					flexWrap="wrap"
				>
					{[...ownedBadges, ...unOwnedBadges, ...skeletonBadges].map(
						(badge, i) => (
							<Badge
								key={i}
								badge={badge}
								onBadgeClick={() => {
									setBadge(badge);
								}}
								imageSx={{
									height: 70,
									width: "auto",
									objectFit: "contain",
									[theme.breakpoints.up("sm")]: {
										...(i > BADGES_PER_ROW - 1 && { mt: "-8px !important" }),
										...([
											BADGES_PER_ROW,
											BADGES_PER_ROW * 3,
											BADGES_PER_ROW * 4
										].includes(i) && { ml: "40px !important" })
									}
								}}
							/>
						)
					)}
				</Stack>
			</Box>
		</Box>
	);
};

const BadgesGroups = () => {
	const [badges] = useAtom(badgesAtom);

	const [selectedBadge, setBadge] = useAtom(openBadgeAtom);

	const eliteBadges = useMemo(() => {
		return badges.filter((i) => i.type === "elite");
	}, [badges.length]);

	const challengesBadges = useMemo(() => {
		return badges.filter((i) => i.type === "challenges");
	}, [badges.length]);

	const otherBadges = useMemo(() => {
		return badges.filter((i) => !i.type);
	}, [badges.length]);

	return (
		<Fade in={!selectedBadge}>
			<Stack spacing={1.5}>
				<Section title="Elite Wizardry" badges={eliteBadges} />

				<Section title="Challenges" badges={challengesBadges} />

				<Section title="Other" badges={otherBadges} />
			</Stack>
		</Fade>
	);
};

export default React.memo(BadgesGroups);
