import { Box, IconButton, Stack, Typography } from "@mui/material";
import { ToolboxCloseButton } from "components/Toolbox";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TestCollectionForm from "components/forms/TestCollectionForm";
import { useTestTemplate } from "hooks/data/useTestTemplate";
import { addSuccessNotification } from "Resources/Notifications";

const CollectionFormTool: React.FC<{ backToTool: any }> = ({ backToTool }) => {
	const [disabled, setDisabled] = useState(false);
	const { addNewCollection } = useTestTemplate();

	const handleSubmit = async (data) => {
		try {
			const title: string = data.title;

			setDisabled(true);
			await addNewCollection(title);

			addSuccessNotification("Success", "Collection Created!", 1000);

			setDisabled(false);
			setTimeout(() => {
				backToTool();
			}, 500);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ mb: 2 }}
			>
				<IconButton onClick={backToTool}>
					<ArrowBackIcon fontSize="small" />
				</IconButton>

				<Typography>Back to Templates</Typography>

				<Box sx={{ flex: 1 }} />

				<ToolboxCloseButton />
			</Stack>

			<TestCollectionForm disabled={disabled} handleSubmit={handleSubmit} />
		</>
	);
};

export default CollectionFormTool;
