import { Icon } from "@iconify/react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { loadSampleProject } from "Resources/ServerInterface";
import { usernameAtom } from "atoms";
import { useProjects } from "hooks/data/useProjects";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React, { useEffect, useMemo, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { loginModalOpenAtom, projectsNewFolderName } from "store/atoms/UiAtoms";
import { projectsAtom } from "store/atoms/projectToolAtoms";
import Folder from "./Folder";
import NewFolderForm from "./NewFolderForm";
import SampleProjectUi from "./SampleProjectUi";
import {
	DEFAULT_FOLDER_NAME,
	addNewFolderToObject,
	buildProjectsArray
} from "./helpers";

const ProjectsList = () => {
	const [projects] = useAtom(projectsAtom);
	const [username] = useAtom(usernameAtom);

	const [folders, setFolders] = useState({});

	const [newFolder, setNewFolder] = useAtom(projectsNewFolderName);

	const setLoginModalOpen = useSetAtom(loginModalOpenAtom);

	const { addProjectToFolder, loadProjects } = useProjects();

	useEffect(() => {
		setFolders(
			buildProjectsArray(projects.filter((i) => i.name !== "CodeChallenge"))
		);
	}, [projects]);

	const onDragEnd = async ({ source, destination }: DropResult) => {
		if (!destination) return;

		const fromFolderName = source?.droppableId;
		const fromIndex = source?.index;

		const toFolderName = destination?.droppableId;
		const toIndex = destination?.index;

		if (fromIndex === toIndex && fromFolderName === toFolderName) return null;

		//  ------------
		const newFolders = { ...folders };

		// Remove the project from the source folder
		const [movedProject] = newFolders[fromFolderName].splice(fromIndex, 1);

		// Insert the project into the destination folder
		newFolders[toFolderName].splice(toIndex, 0, movedProject);

		// Update state
		setFolders(newFolders);

		// Update db
		await addProjectToFolder(
			newFolders[toFolderName].map((i) => i.ID),
			toFolderName
		);
	};

	const foldersLength = Object.keys(folders).filter(
		(i) => i !== DEFAULT_FOLDER_NAME
	).length;

	const handleAddNewFolder = (_name) => {
		setNewFolder(null);
		return setFolders((old) => addNewFolderToObject(_name, old));
	};

	const showSampleProjectUI = useMemo(() => {
		return !projects.filter((i) => i.name !== "CodeChallenge").length;
	}, [projects]);

	if (showSampleProjectUI) {
		return (
			<SampleProjectUi
				onClick={async () => {
					if (!username) {
						setLoginModalOpen(true);
						return;
					}

					await loadSampleProject();
					loadProjects();
				}}
			/>
		);
	}

	return (
		<Box>
			<DragDropContext onDragEnd={onDragEnd}>
				<Stack spacing={1}>
					{Object.keys(folders)
						.filter((i) => i !== DEFAULT_FOLDER_NAME)
						.map((item, i) => (
							<Folder key={i} folderName={item} folderItems={folders[item]} />
						))}

					{newFolder !== null && (
						<NewFolderForm
							folderName={newFolder}
							handleClose={() => setNewFolder(null)}
							handleSave={handleAddNewFolder}
							blacklistNames={Object.keys(folders).map((i) =>
								i.trim().toLowerCase()
							)}
						/>
					)}

					{Object.keys(folders)
						.filter((i) => i === DEFAULT_FOLDER_NAME)
						.map((item, i) => (
							<Folder key={i} folderName={item} folderItems={folders[item]} />
						))}
				</Stack>
			</DragDropContext>

			<Button color="inherit" sx={{ mt: 2 }} onClick={() => setNewFolder("")}>
				<Icon icon="basil:folder-outline" fontSize={18} />

				<Typography sx={{ fontWeight: 500, pl: 1 }}>Create Folder</Typography>
			</Button>
		</Box>
	);
};

export default React.memo(ProjectsList);
