import { Icon } from "@iconify/react";
import { useEditorTabs } from "hooks/ui/useEditorTabs";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React from "react";
import { editorTabsAtom, focusedEditorTabIndexAtom } from "store/atoms/UiAtoms";
import CustomButton from "./CustomButton";
import { projectAtom } from "store/atoms/projectToolAtoms";

const ReportButton = () => {
	const { findAndOpenTab, openCustomTab } = useEditorTabs();

	const [openTabs] = useAtom(editorTabsAtom);
	const [project] = useAtom(projectAtom);

	const setFocusedTabIndex = useSetAtom(focusedEditorTabIndexAtom);

	if (project.name === "CodeChallenge") return null;

	return (
		<CustomButton
			tooltip="Generate Report"
			onClick={() => {
				const reportIndex = openTabs.findIndex((i) =>
					i.path.includes("/report")
				);

				if (reportIndex === -1) {
					const tabName = "Audit Report";
					const tabPath = "/report";

					findAndOpenTab(tabPath, () => openCustomTab(null, tabName, tabPath));
				} else {
					setFocusedTabIndex(reportIndex);
				}
			}}
			Icon={
				<Icon icon="fluent:document-one-page-sparkle-24-filled" fontSize={23} />
			}
		/>
	);
};

export default React.memo(ReportButton);
