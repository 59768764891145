export function addMissingImportsToNewProjectTree(
	originalTree,
	missingImports
) {
	// Helper function to recursively deep copy the tree
	function deepCopy(tree) {
		return JSON.parse(JSON.stringify(tree));
	}

	// Helper function to recursively traverse the tree and find the correct folder to add imports
	function addImportsToFolder(folder, imports) {
		for (const importPath of imports) {
			const pathParts = importPath.split("/");
			const fileName = pathParts.pop();

			// Find the folder where the file should be added
			let currentFolder = folder;
			for (const folderName of pathParts) {
				let foundFolder = currentFolder.folders.find(
					(f) => f.name === folderName
				);

				if (!foundFolder) {
					foundFolder = { name: folderName, files: [], folders: [] };
					currentFolder.folders.push({
						...foundFolder,
						hasMissingImports: true
					});
				}

				currentFolder = { ...foundFolder, hasMissingImports: true };
			}

			// Add the file to the folder
			currentFolder.files.push({ name: fileName, id: "-1", missing: true });
		}
	}

	// Create a deep copy of the original tree
	const newTree = deepCopy(originalTree);

	// Start adding missing imports to the new tree
	addImportsToFolder(newTree, missingImports);

	return newTree;
}

export const folderHasMissingFile = (
	folderpath: string,
	missingPaths: string[]
) => {
	const found = missingPaths.find((i) => i.includes(folderpath));

	return !!found;
};
