import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomModal from ".";

interface ConfirmModalProps {
	title: string;
	description: string;
	open: boolean;
	confirmButtonText?: string;
	confirmButtonLoadingText?: string;
	onClose: () => void;
	onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmModalProps> = ({
	title = "Are you sure?",
	description = "Are you sure you want to complete this action?",
	confirmButtonText = "Confirm",
	confirmButtonLoadingText = "Confirming ...",
	open,
	onClose,
	onConfirm
}) => {
	const [loading, setLoading] = useState(false);

	const handleConfirm = async (e?: any) => {
		e?.stopPropagation?.();

		setLoading(true);
		await onConfirm();

		setLoading(false);
		handleClose();
	};

	const handleClose = (e?: any) => {
		e?.stopPropagation?.();

		if (!loading) {
			onClose();
		}
	};

	return (
		<CustomModal
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="md"
			paperSx={{ maxWidth: "500px", py: 3, textAlign: "center" }}
		>
			<Typography variant="h4" sx={{ textAlign: "center", mb: 2 }}>
				{title}
			</Typography>

			<Typography sx={{ mb: 4 }}>{description}</Typography>

			<LoadingButton
				loading={loading}
				variant="contained"
				fullWidth
				onClick={handleConfirm}
				sx={{ borderRadius: "8px", mb: 1 }}
			>
				{!loading ? confirmButtonText : confirmButtonLoadingText}
			</LoadingButton>

			<Button onClick={handleClose} fullWidth disabled={loading}>
				Cancel
			</Button>
		</CustomModal>
	);
};

export default ConfirmationModal;
