import { Icon } from "@iconify/react";
import { Box, Collapse, Stack, Typography, useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import {
	a11yDark,
	a11yLight
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import { StyledBox, StyledHighlighter } from "./AssetChanges";
import { convertAnsiToHtml, formatCallTrace, formatStackTrace } from "./helpers";

const CallTrace = ({ isStackTrace = false, trace }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [collapsed, setCollapsed] = useState(false);

	const toggleCollapse = () => {
		setCollapsed((old) => !old);
	};

	const traceFormatted = useMemo(() => {
		if (isStackTrace) {
			return convertAnsiToHtml(formatStackTrace(trace));
		} else {
			return convertAnsiToHtml(formatCallTrace(trace));
		}
	}, [trace]);

	return (
		<Box sx={{ mb: 2 }}>
			<Stack
				direction="row"
				alignItems="center"
				spacing={0.7}
				onClick={toggleCollapse}
				sx={{ cursor: "pointer" }}
			>
				<Typography>{isStackTrace ? "Stack Trace" : "Call Trace"}</Typography>

				{!collapsed ? (
					<Icon icon="eva:arrow-up-fill" fontSize={16} />
				) : (
					<Icon icon="eva:arrow-down-fill" fontSize={16} />
				)}
			</Stack>

			<Collapse in={!collapsed} timeout="auto" unmountOnExit>
				<Stack spacing={1.5} sx={{ mt: 1 }}>
					<StyledBox>
						<Typography
							sx={{
								fontFamily: "Monaco, monospace",
								whiteSpace: "pre-wrap",
								wordBreak: "break-all"
							}}
							dangerouslySetInnerHTML={{ __html: traceFormatted }}
						/>
					</StyledBox>
				</Stack>
			</Collapse>
		</Box>
	);
};

export default React.memo(CallTrace);
