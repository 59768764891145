import { atom } from "jotai";
import { GitRepoType } from "utils/githubApi";

export interface PartnerAdType {
	description: string;
	expiration: number;
	imageUrl: string;
	source: string;
	title: string;
	url: string;
	urlType: string;
	hatsId?: string;
	projectId?: string;
	generalizedId?: string;
	prizePool?: string | number;
	prizeCurrency?: string | number;
	endTime?: number;
	startTime?: number;
}

export const importingAtom = atom(false);
export const isPrivateModeAtom = atom(false);
export const importsLoadingAtom = atom(false);

export const partnerAdsAtom = atom<PartnerAdType[]>([]);

export const gitReposAtom = atom<GitRepoType[]>([]);
