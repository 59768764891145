import { Stack, Typography } from "@mui/material";
import { ToolboxCloseButton } from "components/Toolbox";
import StorageSlots from "./StorageSlots";
import OnchainValues from "./OnchainValues";
import AstTool from "./AstTool";
import React from "react";

const InspectTool = () => {
	const Header = () => (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{ mb: 2 }}
		>
			<Typography variant="h6">Inspection tool</Typography>

			<ToolboxCloseButton />
		</Stack>
	);

	return (
		<Stack sx={{ height: "100%" }}>
			<Header />

			<Stack spacing={1.5} sx={{ height: "100%", overflow: "auto" }}>
				<AstTool />

				<StorageSlots />

				<OnchainValues />
			</Stack>
		</Stack>
	);
};

export default React.memo(InspectTool);
