import React, { useState } from "react";
import { LOCALSTORAGE_SCAN_RESULTS } from "utils/constants";

import {
	TripOrigin as CircleIcon,
	PendingOutlined as OtherIcon,
	CropSquare as SquareIcon,
	ChangeHistory as TriangleIcon
} from "@mui/icons-material";

const IconColor = (theme: any, type: string) => {
	switch (type) {
		case "High":
			return { Icon: SquareIcon, color: theme.palette.error.main };
		case "Medium":
			return { Icon: TriangleIcon, color: theme.palette.warning.main };
		case "Low":
			return { Icon: CircleIcon, color: theme.palette.success.main };
		default:
			return { Icon: OtherIcon, color: theme.palette.info.main };
	}
};

export interface ScanEntry {
	severity: string;
	confidence: string;
	check: string;
	checkDescription: string;
	entryDescription: string;
	recommendation: string;
	elements: any;
}

export const getSlitherSeverityValue = (severity: string): number => {
	if (severity === "High") {
		return 5;
	} else if (severity === "Medium") {
		return 4;
	} else if (severity === "Low") {
		return 3;
	} else if (severity === "Informational") {
		return 2;
	} else if (severity === "Optimization") {
		return 1;
	} else {
		return 0;
	}
};

export const mapSlitherInputToEntry = (detector: any): ScanEntry[] => {
	try {
		if (!detector?.results) throw new Error("Error");

		const entries: ScanEntry[] = [];

		for (const result of detector.results) {
			entries.push({
				severity: result.impact,
				confidence: result.confidence,
				check: result.check,
				checkDescription: detector.description,
				entryDescription: result.description,
				recommendation: detector.recommendation,
				elements: result.elements
			});
		}

		return entries;
	} catch (error) {
		removeScanResultsFromStorage();

		return [];
	}
};

export const removeScanResultsFromStorage = () => {
	for (let i = 0; i < localStorage.length; i++) {
		const _key = localStorage.key(i);

		if (_key && _key.startsWith(LOCALSTORAGE_SCAN_RESULTS)) {
			localStorage.removeItem(_key);
		}
	}
};

// Get the most recent sarif run results for the tool
export function getNewestResultsFilteredByTool(
	sarifData,
	toolName,
	openedFileName
) {
	let lastIndex = -1;
	sarifData.runs.forEach((run, index) => {
		if (run.tool.driver.name === toolName && run.artifacts) {
			const hasArtifactWithFile = run.artifacts.some((artifact) => {
				return artifact.location && openedFileName.includes(artifact.location.uri.replace("file:///", ""));
			});
			if (hasArtifactWithFile) lastIndex = index;
		}
	});
	if (lastIndex === -1) return {}

	const filteredData = {
		...sarifData,
		runs: [sarifData.runs[lastIndex]]
	};

	
	return filteredData;
}

// Given sarif data, count results/findings
export function countToolResultsForOpenedFile(sarifData, openedFileName): number {
	if (!openedFileName || !sarifData) {
		return 0;
	}

	const fileFilteredRuns = sarifData.runs?.reduce((total, run) => {
		const matchingResultsInRun = run.results.filter(result =>
			result.locations.some(location =>
				openedFileName.includes(location.physicalLocation.artifactLocation.uri)
			)
		);
		return total + matchingResultsInRun.length;
	}, 0);

	return fileFilteredRuns
}

// Return mapped sarif results by severities
export function getToolResultsDividedBySeverity(sarifData, openedFileName, theme): Array<object> {
	const severityCounts = {
		high: 0,
		medium: 0,
		low: 0,
		other: 0
	};
	sarifData.runs.forEach(run => {
		run.results.forEach(result => {
			const level = result.level;
			switch (level) {
				case 'error':
					severityCounts.high++;
					break;
				case 'warning':
					severityCounts.medium++;
					break;
				case 'note':
					severityCounts.low++;
					break;
				case 'none':
					severityCounts.other++;
					break;
			}
		});
	});

	return [
		{
			value: severityCounts.high,
			name: "High",
			...IconColor(theme, "High")
		},
		{
			value: severityCounts.medium,
			name: "Medium",
			...IconColor(theme, "Medium")
		},
		{
			value: severityCounts.low,
			name: "Low",
			...IconColor(theme, "Low")
		},
		{
			value: severityCounts.other,
			name: "Other",
			...IconColor(theme, "Other")
		}
	];
}

function convertSarifResultLevelToSeverity(severity: String): string {
	switch (severity) {
		case "none":
			return "Other";
		case "note":
			return "Low";
		case "warning":
			return "Medium";
		case "error":
			return "High";
		default:
			console.warn(`Unexpected severity ${severity}`)
			return "Other"
	}
}

// convert sarif results to an easy results array for frontend parsing
export function sarifToFormattedResults(sarifData): Array<object> {
	const severityOrder = {
		"High": 1,
		"Medium": 2,
		"Low": 3,
		"Other": 4,
		"Unknown": 5
	};

	const formattedResults = sarifData.runs.flatMap(run =>
		run.results.map(result => {
			const rule = run.tool.driver.rules.find(rule => rule.id === result.ruleId);

			let confidenceCapitalized
			if (rule.properties?.precision) {
				confidenceCapitalized = `${rule.properties.precision[0].toUpperCase()}${rule.properties.precision.substring(1).toLowerCase()}`
			}

			// Locations are later parsed through the code line string e.g. (src/myfile.sol#1-2)
			// This creates a string of all the location instances and makes sure they're unique
			// Slither has a unique formatting of locations which we keep and use directly
			let locations;
			
			if (rule.slitherLocations) {
				locations = rule.slitherLocations
			} else {
				const locationSet = new Set();
				locations = result.locations
					.reduce((acc, loc) => {
						const identifier = `${loc.physicalLocation.artifactLocation.uri}#${loc.physicalLocation.region.startLine}`;
						if (!locationSet.has(identifier)) {
							locationSet.add(identifier);
							acc.push(
								`In (${loc.physicalLocation.artifactLocation.uri}#${loc.physicalLocation.region.startLine})`
							);
						}
						return acc;
					}, [])
					.join("\n");
			}

			return {
				severity: convertSarifResultLevelToSeverity(result.level),
				confidence: confidenceCapitalized,
				check: rule.name,
				description: rule.fullDescription.text,
				recommendation: rule.help.text,
				locations: locations
			};
		})
	);

	formattedResults.sort((a, b) => {
		return severityOrder[a.severity] - severityOrder[b.severity];
	});

	return formattedResults;
}

// decide if we should stop polling for new queue updates
export async function decideShouldStopPolling(taskStatuses) {
    const requiredTools = ['slither', 'aderyn', '4naly3er'];

    return taskStatuses.every(taskStatus => 
        requiredTools.every(tool => 
            tool in taskStatus && 
            (taskStatus[tool].status === 'success' || taskStatus[tool].status === 'error')
        )
    );
}