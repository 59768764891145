import {
	lightBlue,
	lightGreen,
	orange,
	purple,
	red,
	yellow
} from "@mui/material/colors";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocalGasStationOutlinedIcon from "@mui/icons-material/LocalGasStationOutlined";

export const riskLevelToText = (severity: number): string => {
	if (severity === 0) {
		return "High";
	} else if (severity === 1) {
		return "Medium";
	} else if (severity === 2) {
		return "Low";
	} else if (severity === 3) {
		return "Informational";
	} else if (severity === 4) {
		return "Gas Optimization";
	} else if (severity === 5) {
		return "Critical";
	} else {
		return "Other";
	}
};

export const riskLevelFromText = (severity: string): number => {
	if (severity === "High") {
		return 0;
	} else if (severity === "Medium") {
		return 1;
	} else if (severity === "Low") {
		return 2;
	} else if (severity === "Informational") {
		return 3;
	} else if (severity === "Gas Optimization") {
		return 4;
	} else if (severity === "Critical") {
		return 5;
	} else {
		return 2;
	}
};

export const HATS_SEVERITY_OPTIONS = [
	{
		name: "Severity: Critical",
		value: riskLevelFromText("Critical"),
		color: red["500"],
		icon: FilterAltIcon
	},
	{
		name: "Severity: High",
		value: riskLevelFromText("High"),
		color: orange["500"],
		icon: CheckBoxOutlineBlankIcon
	},
	{
		name: "Severity: Medium",
		value: riskLevelFromText("Medium"),
		color: yellow["500"],
		icon: ChangeHistoryIcon
	},
	{
		name: "Severity: Low",
		value: riskLevelFromText("Low"),
		color: lightGreen["500"],
		icon: CircleOutlinedIcon
	}
];

export const SEVERITY_OPTIONS = [
	{
		name: "Severity: Critical",
		value: riskLevelFromText("Critical"),
		color: red["500"],
		icon: FilterAltIcon
	},
	{
		name: "Severity: High",
		value: riskLevelFromText("High"),
		color: orange["500"],
		icon: CheckBoxOutlineBlankIcon
	},
	{
		name: "Severity: Medium",
		value: riskLevelFromText("Medium"),
		color: yellow["500"],
		icon: ChangeHistoryIcon
	},
	{
		name: "Severity: Low",
		value: riskLevelFromText("Low"),
		color: lightGreen["500"],
		icon: CircleOutlinedIcon
	},
	{
		name: "Informational",
		value: riskLevelFromText("Informational"),
		color: lightBlue["500"],
		icon: InfoOutlinedIcon
	},
	{
		name: "Gas Optimization",
		value: riskLevelFromText("Gas Optimization"),
		color: purple["500"],
		icon: LocalGasStationOutlinedIcon
	}
];

export const STATUS_OPTIONS = [
	{ name: "Status: Open", value: "open" },
	{ name: "Status: Closed", value: "closed" }
];
