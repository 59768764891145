import { atom } from "jotai";

export interface TerminalTabContentType {
	type: "trace" | "test" | "cheatcode" | "simulation" | "simulation2";
	content: any;
}

export interface TerminalTabsType {
	name: string;
	path: string;
	content: TerminalTabContentType;
}

export const terminalOpenAtom = atom(false);
export const terminalHeightAtom = atom(500);
export const terminalTabsAtom = atom<TerminalTabsType[]>([]);
export const terminalFocusedTab = atom(0);

export const terminalOpenedTabAtom = atom((get) => {
	const tabs = get(terminalTabsAtom);
	const focusedTabIndex = get(terminalFocusedTab);
	const tab = tabs[focusedTabIndex];

	return tab;
});
