import { Icon } from "@iconify/react";
import {
	Box,
	Button,
	LinearProgress,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import { useAtom } from "jotai";
import React from "react";
import { rateLimitAtoms } from "store/atoms/userAtoms";

const RateLimitBox = () => {
	function formatDate(date: Date | string): string {
		// Ensure the input is a Date object
		if (!(date instanceof Date)) {
		  date = new Date(date);
		}
	  
		if (isNaN(date.getTime())) {
		  throw new Error('Invalid date');
		}
	  
		// Get the components of the date
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		let hours = date.getHours();
		const minutes = date.getMinutes().toString().padStart(2, '0');
		const seconds = date.getSeconds().toString().padStart(2, '0');
	  
		// Determine AM/PM
		const ampm = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12;
		hours = hours ? hours : 12; // The hour '0' should be '12'
		const formattedHours = hours.toString().padStart(2, '0');
	  
		// Format the date in the desired format
		const formattedDate = `${month}-${day} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
	  
		return formattedDate;
	}

	const theme = useTheme();

	const [rateLimits] = useAtom(rateLimitAtoms);

	const { current, limit, retryAfter } = rateLimits["ai"] || {};

	const percentageCompleted = (current / limit) * 100;

	return (
		<Stack
			sx={{
				borderRadius: percentageCompleted > 0 ? "0px" : "8px",
				borderTopLeftRadius: "8px",
				borderTopRightRadius: "8px",
				bgcolor: "background.default",
				mt: 2
			}}
		>
			{/* Content */}
			<Stack
				direction="row"
				alignItems="center"
				spacing={1}
				sx={{
					width: "100%",
					py: 1,
					px: 1.5
				}}
			>
				<Icon icon="fluent:brain-circuit-24-filled" fontSize={20} />

				<Box>
					<Typography sx={{ fontWeight: 500 }}>
						{current}/{limit} weekly credits used
					</Typography>
					{retryAfter &&
						<Typography sx={{ fontSize: '10px', color: theme.palette.primary.main }}>Next free credit at <strong>{formatDate(retryAfter)}</strong></Typography>
					}
				</Box>
			</Stack>

			{/* Progress */}
			{percentageCompleted > 0 && (
				<LinearProgress variant="determinate" value={percentageCompleted} />
			)}
		</Stack>
	);
};

export default React.memo(RateLimitBox);
