import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FieldValues, Resolver, useForm } from "react-hook-form";
import * as Yup from "yup";
import FormTextField from "../../formFields/FormTextField";

export interface ResetPasswordFormType {
	email: string;
}

interface Props {
	handleSubmit: (data: ResetPasswordFormType) => void;
	disabled?: boolean;
}

const schema = Yup.object().shape({
	email: Yup.string().email("Invalid email").required("Email is required")
});

const ResetPasswordForm: React.FC<Props> = ({
	handleSubmit: propsHandleSubmit,
	disabled: PropsDisabled = false
}) => {
	const [disabled, setDisabled] = useState(PropsDisabled);

	useEffect(() => {
		setDisabled(PropsDisabled);
	}, [PropsDisabled]);

	const formOptions = { resolver: yupResolver(schema) as Resolver<ResetPasswordFormType, FieldValues> };

	const {
		handleSubmit,
		register,
		formState: { errors }
	} = useForm<FieldValues & ResetPasswordFormType & any>(formOptions);

	const onSubmit = (data: ResetPasswordFormType) => {
		// setDisabled(true);
		propsHandleSubmit?.(data);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
			<Stack spacing={3}>
				<FormTextField
					fullWidth
					disabled={disabled}
					required
					name="email"
					placeholder="Your email address"
					register={register}
					error={!!errors["email"]}
					helperText={errors["email"]?.message}
					sx={{ flex: 1 }}
					InputProps={{
						sx: { bgcolor: "background.paper" }
					}}
				/>

				<LoadingButton
					type="submit"
					variant="contained"
					disabled={disabled}
					loading={disabled}
					sx={{ height: 39 }}
				>
					Submit
				</LoadingButton>
			</Stack>
		</form>
	);
};

export default ResetPasswordForm;
