import path from "path";
import Project from "./Project";
import ProjectFolder from "./ProjectFolder";
import { TestResult } from "store/atoms/testAtoms";

export function getFileExtension(fileName: string) {
	return path.extname(fileName);
}

export function isFileSolidity(fileName: string) {
	if (!fileName) return false;

	return path.extname(fileName) === ".sol";
}

export function isFileMd(fileName: string) {
	if (!fileName) return false;

	return path.extname(fileName) === ".md";
}

export function isEtherscanUrl(string) {
	let url;
	try {
		url = new URL(string);
	} catch (_) {
		return false;
	}
	return (
		((url.protocol === "http:" || url.protocol === "https:") &&
			(url.hostname.includes("etherscan") ||
				url.hostname.includes("basescan") ||
				url.hostname.includes("arbiscan"))) ||
		url.hostname.includes("polygonscan")
	);
}

export function isCode4Url(string) {
	let url;
	try {
		url = new URL(string);
	} catch (_) {
		return false;
	}
	return (
		(url.protocol === "http:" || url.protocol === "https:") &&
		url.hostname.includes("code4rena")
	);
}

export function isSherlockUrl(string) {
	let url;
	try {
		url = new URL(string);
	} catch (_) {
		return false;
	}
	return (
		(url.protocol === "http:" || url.protocol === "https:") &&
		url.hostname.includes("sherlock")
	);
}

export function isGitHubUrl(url: string): boolean {
	const gitHubUrlRegex =
		/^https:\/\/github\.com\/([^/]+)\/([^/]+)(\.git)?(\/.*)?$/;
	return gitHubUrlRegex.test(url);
}

export function findFilePath(project: Project, fileId: string): string | null {
	function searchFolder(
		folder: ProjectFolder,
		currentPath: string
	): string | null {
		// Check each file in the folder
		for (const file of folder.files) {
			if (file.id === fileId) {
				return `${currentPath}/${file.name}`;
			}
		}

		// Recursively search in subfolders
		for (const subfolder of folder.folders) {
			const result = searchFolder(
				subfolder,
				`${currentPath}/${subfolder.name}`
			);
			if (result) return result;
		}

		// File not found in this folder
		return null;
	}

	// Start searching from the root folder
	return searchFolder(project.rootFolder, project.rootFolder.name);
}

function removeAfterParenthesis(str) {
	const index = str.indexOf("(");

	if (index !== -1) {
		// If '(' is found, return the substring before it
		return str.substring(0, index);
	}

	// If '(' is not found, return the original string
	return str;
}

export function parseFoundryResults(foundryResults: any): TestResult[] {
	const fileResults = foundryResults[Object.keys(foundryResults)[0]];
	const testResults = fileResults["test_results"];
	const individualResultKeys = Object.keys(testResults);

	const individualResults: TestResult[] = [];
	individualResultKeys.forEach((resultKey) => {
		const _status = testResults[resultKey]["status"];
		const status =
			_status === "Success" ? "pass" : _status === "Failure" ? "fail" : "error";

		const individualResult: TestResult = {
			testName: removeAfterParenthesis(resultKey),
			status,
			decodedLogs: testResults[resultKey]["decoded_logs"],
			traces: testResults[resultKey]["traces"],
			reason: testResults[resultKey]["reason"] ?? undefined,
			duration: fileResults["duration"]["nanos"]
		};
		individualResults.push(individualResult);
	});

	return individualResults;
}
