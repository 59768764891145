import { Icon } from "@iconify/react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
	useTheme
} from "@mui/material";
import {
	getCallableValues,
	renameProjectById
} from "Resources/ServerInterface";
import DeleteProjectModal from "components/CustomModal/DeleteProjectModal";
import { useProject } from "hooks/data/useProject";
import { useProjects } from "hooks/data/useProjects";
import useSetAtom from "hooks/useSetAtom";
import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { loginModalOpenAtom } from "store/atoms/UiAtoms";

const EditProjectForm: React.FC<{
	projectName: string;
	projectId: string;
	handleClose: any;
}> = ({ projectName, projectId, handleClose }) => {
	const theme = useTheme();
	const [value, setValue] = useState<any>(projectName);
	const { loadProjects } = useProjects();

	const handleEditProjectName = async () => {
		handleClose();
		await renameProjectById(projectId, value);
		await loadProjects();
	};

	return (
		<TextField
			size="small"
			value={value}
			onChange={(e) => setValue(e.target.value)}
			onClick={(e) => e.preventDefault()}
			InputProps={{
				autoFocus: true,
				sx: {
					bgcolor: "background.paper",
					borderRadius: "8px",
					border: `1px solid ${theme.palette.primary.dark}`
				},
				endAdornment: (
					<InputAdornment position="end" sx={{ mr: -1 }}>
						<IconButton
							sx={{ color: "primary.main", height: 22, width: 20 }}
							onClick={handleClose}
						>
							<CloseIcon sx={{ fontSize: 18 }} />
						</IconButton>

						<IconButton
							sx={{ color: "primary.main", height: 22, width: 20 }}
							onClick={handleEditProjectName}
						>
							<CheckIcon sx={{ fontSize: 18 }} />
						</IconButton>
					</InputAdornment>
				)
			}}
		/>
	);
};

const ProjectItem: React.FC<{
	text: string;
	index: number;
	project: any;
	notInFolder?: boolean;
}> = ({ text, index, project, notInFolder }) => {
	const [loading, setLoading] = useState(false);

	const [selected, setSelected] = useState(null);
	const [editMode, setEditMode] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);

	const { openProjectWithProjectId } = useProject();
	const setLoginModalOpen = useSetAtom(loginModalOpenAtom);

	const handleSelectProject = async (projectID) => {
		setLoading(true);
		setSelected(projectID);

		try {
			getCallableValues(projectID)
				.then(async (callableValues) => {
					callableValues = callableValues as any[];
					await openProjectWithProjectId(projectID, callableValues).then(() => {
						setSelected(null);
					});
				})
				.catch(async () => {
					await openProjectWithProjectId(projectID).then(() => {
						setSelected(null);
					});
				});
		} catch (error) {
			console.log(error);
		} finally {
			setTimeout(() => {
				setLoading(false);
			}, 1000);
		}
	};

	const openDeleteModal = (
		projectID: string,
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		event?.stopPropagation?.();

		setDeleteModal(true);
	};

	if (editMode) {
		return (
			<Box sx={{ pl: 2 }}>
				<EditProjectForm
					projectName={project.name}
					projectId={project.ID}
					handleClose={() => setEditMode(null)}
				/>
			</Box>
		);
	}

	return (
		<>
			<Draggable key={project.ID} draggableId={project.ID} index={index}>
				{(provided, snapshot) => {
					const isDragging = snapshot.isDragging;

					return (
						<Stack
							ref={provided.innerRef}
							{...provided.draggableProps}
							direction="row"
							alignItems="center"
							sx={{
								width: "100%",
								"&:hover": {
									"& .showOnHover": {
										display: "inline-flex",
										opacity: 1
									}
								}
							}}
						>
							<IconButton
								className="showOnHover"
								{...provided.dragHandleProps}
								sx={{
									p: 0,
									borderRadius: "5px",
									height: 30,
									opacity: isDragging ? 1 : 0
								}}
							>
								<Icon icon="ic:baseline-drag-indicator" fontSize={16} />
							</IconButton>

							<Stack
								onClick={() => handleSelectProject(project.ID)}
								direction="row"
								alignItems="center"
								spacing={1}
								sx={{
									overflow: "hidden",
									flex: 1,
									opacity: selected ? 0.6 : 1,
									borderRadius: "8px",
									p: 1,
									pl: 1.2,
									cursor: !isDragging ? "pointer !important" : "grabbing",
									bgcolor: notInFolder ? "background.nav" : "background.default"
								}}
							>
								{loading ? (
									<Icon icon="eos-icons:bubble-loading" fontSize={16} />
								) : (
									<Icon icon="file-icons:solidity" fontSize={14} />
								)}

								<Typography
									sx={{
										flex: 1,
										overflowWrap: "break-word",
										fontWeight: 500,
										overflow: "hidden",
										textOverflow: "ellipsis",
										display: "-webkit-box",
										WebkitBoxOrient: "vertical",
										WebkitLineClamp: 1
									}}
								>
									{text}
								</Typography>

								{/* Action buttons */}
								<Stack
									direction="row"
									className="showOnHover"
									sx={{
										display: "none"
									}}
								>
									{project.name !== "Sample Project" && (
										<IconButton
											onClick={(e) => {
												e.stopPropagation();
												setEditMode(true);
											}}
											sx={{
												width: 20,
												height: 20,
												p: 0
											}}
										>
											<Icon icon="ic:round-edit" fontSize={18} />
										</IconButton>
									)}

									<IconButton
										onClick={(e) => openDeleteModal(project.ID, e)}
										sx={{
											width: 20,
											height: 20,
											p: 0
										}}
									>
										<Icon icon="mdi:delete" fontSize={18} />
									</IconButton>
								</Stack>
							</Stack>
						</Stack>
					);
				}}
			</Draggable>

			{/* Delete Project Modal */}
			<DeleteProjectModal
				open={!!deleteModal}
				onClose={() => setDeleteModal(null)}
				projectId={project.ID}
			/>
		</>
	);
};

export default React.memo(ProjectItem);
