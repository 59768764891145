import { Icon } from "@iconify/react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	IconButton,
	InputAdornment,
	Stack,
	SxProps,
	TextField,
	TextFieldProps,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import ConfirmationModal from "components/CustomModal/ConfirmationModal";
import { useChecklist } from "hooks/data/useChecklist";
import React, { useState } from "react";
import { ChecklistGroupType } from "store/atoms/checklistAtoms";
import hex from "utils/hexTransparency";
import ChecklistItem from "./ChecklistItem";
import { ChecklistGroupTypeResponse } from "Resources/ServerInterfaceChecklist";

const EditGroupField: React.FC<
	TextFieldProps & {
		groupName: string;
		handleClose: any;
		addNew?: any;
		editName?: any;
		groupId?: string;
		inputSx?: SxProps;
	}
> = ({
	groupName,
	handleClose,
	addNew = undefined,
	editName = undefined,
	groupId = "",
	inputSx = {},
	...props
}) => {
	const theme = useTheme();
	const [value, setValue] = useState<any>(groupName);

	const handleSubmit = (
		e:
			| React.FormEvent<HTMLFormElement>
			| React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.preventDefault();

		if (addNew) {
			addNew(groupId, value);
		}
		if (editName) {
			editName(groupId, value);
		}

		handleClose();
	};

	return (
		<form onSubmit={handleSubmit} style={{ width: "100%" }}>
			<TextField
				size="small"
				value={value}
				onChange={(e) => setValue(e.target.value)}
				onClick={(e) => e.preventDefault()}
				fullWidth
				InputProps={{
					autoFocus: true,
					sx: {
						height: 34,
						bgcolor: "background.paper",
						borderRadius: "8px",
						border: `1px solid ${theme.palette.primary.dark}`,
						...inputSx
					},
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								sx={{ color: "primary.main", height: 20, width: 20 }}
								onClick={(e) => {
									e.stopPropagation();
									handleClose();
								}}
							>
								<CloseIcon sx={{ fontSize: 18 }} />
							</IconButton>

							<IconButton
								onClick={(e) => {
									e.stopPropagation();
									handleSubmit(e);
								}}
								sx={{ color: "primary.main", height: 22, width: 20 }}
							>
								<CheckIcon sx={{ fontSize: 18 }} />
							</IconButton>
						</InputAdornment>
					)
				}}
				{...props}
			/>
		</form>
	);
};

const ChecklistGroup: React.FC<{ group: ChecklistGroupTypeResponse }> = ({
	group
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [expanded, setExpanded] = useState(true);
	const [editTitleMode, setEditTitleMode] = useState(false);
	const [addChecklistMode, setAddChecklistMode] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);

	const { deleteGroup, renameGroup, addNewChecklist, addGroupToLibrary } =
		useChecklist();

	return (
		<Box
			sx={{
				borderRadius: "8px",
				bgcolor: "background.default"
			}}
		>
			<Accordion
				expanded={expanded}
				onChange={(e, v) => setExpanded(v)}
				disableGutters
				sx={{
					width: "100%",
					borderRadius: "8px !important",
					backgroundImage: "none",
					bgcolor: "background.default",
					"&.MuiAccordion-root:before": {
						bgcolor: "transparent"
					},
					"&.Mui-expanded": { mt: 0 },
					"&:hover": {
						bgcolor: `${theme.palette.background["nav"]}${hex["40%"]}`
					}
				}}
			>
				<AccordionSummary
					disableRipple
					disableTouchRipple
					expandIcon={<ArrowDropUpIcon />}
					sx={{
						px: 1,
						minHeight: 44,
						bgcolor: "transparent !important",
						"&:hover": {
							"& .showOnHover": {
								display: "inline-flex"
							}
						}
					}}
				>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={1}
						sx={{ flex: 1, minHeight: 24 }}
					>
						{!editTitleMode ? (
							<Typography>{group.groupName}</Typography>
						) : (
							<EditGroupField
								groupName={group.groupName}
								editName={renameGroup}
								groupId={group._id}
								handleClose={() => setEditTitleMode(false)}
							/>
						)}

						<Stack
							direction="row"
							alignItems="center"
							className="showOnHover"
							sx={{ display: "none" }}
						>
							{!group.inLibrary && (
								<Tooltip title="Save to library">
									<IconButton
										size="small"
										onClick={(e) => {
											e.stopPropagation();
											addGroupToLibrary(group._id);
										}}
										sx={{
											width: 22,
											height: 24,
											p: 0
										}}
									>
										<Icon icon="mdi:content-save-plus" fontSize={17} />
									</IconButton>
								</Tooltip>
							)}

							<Tooltip title="Add checklist item">
								<IconButton
									size="small"
									onClick={(e) => {
										e.stopPropagation();
										setAddChecklistMode(!addChecklistMode);
									}}
									sx={{
										width: 22,
										height: 24,
										p: 0
									}}
								>
									<Icon icon="ci:list-add" fontSize={20} />
								</IconButton>
							</Tooltip>

							<Tooltip title="Rename checklist group">
								<IconButton
									size="small"
									onClick={(e) => {
										e.stopPropagation();
										setEditTitleMode(!editTitleMode);
									}}
									sx={{
										width: 22,
										height: 24,
										p: 0
									}}
								>
									<Icon icon="mdi:rename" fontSize={18} />
								</IconButton>
							</Tooltip>

							<Tooltip title="Delete checklist group">
								<IconButton
									size="small"
									onClick={(e) => {
										e.stopPropagation();
										setDeleteModal(true);
									}}
									sx={{
										width: 22,
										height: 24,
										p: 0
									}}
								>
									<Icon icon="ic:baseline-delete" fontSize={18} />
								</IconButton>
							</Tooltip>
						</Stack>
					</Stack>
				</AccordionSummary>

				<AccordionDetails sx={{ p: 1.5, pt: 0, pb: 1.5 }}>
					<Stack spacing={1}>
						{/* Default empty state */}
						{!group.checklists?.length && !addChecklistMode && (
							<Button
								variant="outlined"
								size="small"
								onClick={() => setAddChecklistMode(!addChecklistMode)}
								sx={{
									cursor: "pointer",
									height: 36,
									borderRadius: "8px",
									boxShadow: 1,
									borderColor: "background.paper2",
									color: "text.secondary",
									"&:hover": {
										color: "text.primary"
									}
								}}
							>
								Add checklist item
							</Button>
						)}

						{/* All items */}
						{group?.checklists?.map((checklist, i) => (
							<ChecklistItem
								key={i}
								group={group}
								index={i}
								checklist={checklist}
							/>
						))}

						{addChecklistMode && (
							<EditGroupField
								groupName=""
								handleClose={() => setAddChecklistMode(false)}
								addNew={addNewChecklist}
								groupId={group._id}
								placeholder="What is your checklist?"
								inputSx={{ height: 42 }}
							/>
						)}
					</Stack>
				</AccordionDetails>
			</Accordion>

			{/* Delete Group Modal */}
			<ConfirmationModal
				title="Confirm Delete"
				description={`Are you sure you want to delete group - ${group.groupName}`}
				open={!!deleteModal}
				onClose={() => setDeleteModal(null)}
				onConfirm={() => deleteGroup(group._id)}
			/>
		</Box>
	);
};

export default React.memo(ChecklistGroup);
