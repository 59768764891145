import BookmarkIcon from "@mui/icons-material/Bookmark";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PolicyIcon from "@mui/icons-material/Policy";
import {
	Box,
	Collapse,
	IconButton,
	Stack,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import { formatDistance, isValid, parseISO } from "date-fns";
import { useProject } from "hooks/data/useProject";
import { useTools } from "hooks/ui/useTools";
import useSetAtom from "hooks/useSetAtom";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { JumpToLineAtom } from "store/atoms/EditorAtoms";

const DEFAULT_NOTECONTENT =
	"No description added.\nEdit this note to add more details.";

const NoteItem = ({
	_id,
	dateUpdated,
	tag,
	tagColor,
	location,
	title,
	noteContent,
	deleteNote,
	editClick,
	index
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [collapseOpen, setCollapseOpen] = useState(false);

	const { selectTool } = useTools();
	const { openProjectFile } = useProject();

	const setJumpToEditorLine = useSetAtom(JumpToLineAtom);

	const isValidDate = isValid(parseISO(dateUpdated));

	const addToFinding = () => {
		selectTool("AddFinding", {
			values: { location, title, noteContent }
		});
	};

	const openFileLocation = () => {
		const [filePath, lineNumbers] = location.split("#");
		const [startLine, endLine] = lineNumbers.split("-");

		if (filePath && startLine) {
			openProjectFile(filePath, () => {
				setTimeout(() => {
					setJumpToEditorLine({
						startLineNumber: parseInt(startLine),
						endLineNumber: parseInt(endLine) || parseInt(startLine)
					});
				}, 500);
			});
		}
	};

	return (
		<Box
			onClick={() => setCollapseOpen(!collapseOpen)}
			sx={{
				cursor: "pointer",
				borderRadius: "16px",
				...(themeMode === "light" && {
					bgcolor: theme.palette.background.paper,
					boxShadow: 1,
					"&:hover": {
						bgcolor: theme.palette.background["paper2"],
						"& .actionButton": {
							display: "inline-flex"
						}
					}
				}),
				...(themeMode === "dark" && {
					bgcolor: theme.palette.background.default,
					border: `1px solid ${theme.palette.primary.dark}`,
					"&:hover": {
						border: `1px solid ${theme.palette.primary.main}`,
						bgcolor: theme.palette.background["nav"],
						"& .actionButton": {
							display: "inline-flex"
						}
					}
				})
			}}
		>
			{/* Top */}
			<Stack
				spacing={1}
				direction="row"
				alignItems="center"
				sx={{ px: 2, borderBottom: `1px solid ${theme.palette.primary.dark}` }}
			>
				<BookmarkIcon
					fontSize="small"
					sx={{ color: tagColor?.replaceAll?.("-", ".") }}
				/>

				<Typography variant="body2" sx={{ py: 1.5, fontWeight: 600 }}>
					{title}
				</Typography>

				<Box sx={{ flex: 1 }} />

				<Tooltip title="Create finding from this note">
					<IconButton
						size="small"
						className="actionButton"
						sx={{ display: "none", color: "inherit" }}
						onClick={(e) => {
							e.stopPropagation();
							addToFinding();
						}}
					>
						<PolicyIcon sx={{ fontSize: 16 }} />
					</IconButton>
				</Tooltip>

				<Tooltip title="Edit note">
					<IconButton
						size="small"
						className="actionButton"
						sx={{ display: "none", color: "info.light" }}
						onClick={(e) => {
							e.stopPropagation();
							editClick(index);
						}}
					>
						<EditIcon sx={{ fontSize: 18 }} />
					</IconButton>
				</Tooltip>

				<Tooltip title="Delete this note">
					<IconButton
						size="small"
						className="actionButton"
						sx={{ display: "none", color: "error.main" }}
						onClick={(e) => {
							e.stopPropagation();
							deleteNote(_id);
						}}
					>
						<DeleteOutlineIcon sx={{ fontSize: 18 }} />
					</IconButton>
				</Tooltip>

				<IconButton size="small">
					{collapseOpen ? (
						<ExpandLessIcon fontSize="small" />
					) : (
						<ExpandMoreIcon fontSize="small" />
					)}
				</IconButton>
			</Stack>

			{/* Middle */}
			<Box sx={{ p: 2, pt: 1.5 }}>
				<Collapse in={!collapseOpen} timeout="auto" unmountOnExit>
					<Typography
						variant="body2"
						sx={{
							whiteSpace: "pre-line",
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "-webkit-box",
							WebkitBoxOrient: "vertical",
							WebkitLineClamp: 3,
							maxHeight: "4.2em",
							minHeight: "3rem"
						}}
					>
						{noteContent || DEFAULT_NOTECONTENT}
					</Typography>
				</Collapse>

				<Collapse in={collapseOpen} timeout="auto" unmountOnExit>
					<Box
						sx={{
							whiteSpace: "pre-line",
							px: 2,
							py: 0.5,
							borderRadius: "8px",
							bgcolor: "background.paper"
						}}
					>
						<ReactMarkdown>{noteContent || DEFAULT_NOTECONTENT}</ReactMarkdown>
					</Box>
				</Collapse>
			</Box>

			{/* Bottom */}
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				flexWrap="wrap"
				spacing={2}
				sx={{
					px: 2,
					py: 1,
					bgcolor: "background.paper",
					borderBottomLeftRadius: "inherit",
					borderBottomRightRadius: "inherit",
					borderTop: `1px solid ${theme.palette.background["paper2"]}`
				}}
			>
				{location && (
					<Typography
						variant="caption"
						sx={{
							cursor: "pointer",
							fontWeight: 600,
							overflowWrap: "anywhere",
							// whiteSpace: "pre-line"
							"&:hover": {
								color: "primary.main"
							}
						}}
						onClick={(e) => {
							e.stopPropagation();
							openFileLocation();
						}}
					>
						{location.split("/").pop()}
					</Typography>
				)}

				<Typography
					variant="caption"
					sx={{ minWidth: 80, textAlign: "right", flex: "1!important" }}
				>
					{isValidDate
						? formatDistance(new Date(dateUpdated), new Date(), {
								addSuffix: true
						  })
						: dateUpdated}
				</Typography>
			</Stack>
		</Box>
	);
};

export default NoteItem;
