import { addMonacoAction } from "@auditware/CodeEditorCore/helpers";
import { Monaco } from "@monaco-editor/react";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import {
	ClickAwayListener,
	IconButton,
	InputAdornment,
	Popover,
	Stack,
	TextField,
	useTheme
} from "@mui/material";
import { NoteTagsDropDown } from "components/Toolbox/Tools/NotesTool";
import { useNotes } from "hooks/data/useNotes";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
	editorClickListenerAtom,
	editorScrollListenerAtom
} from "store/atoms/EditorAtoms";
import { notesTagsAtom } from "store/atoms/NotesAtoms";
import { openedTabAtom, selectedToolAtom } from "store/atoms/UiAtoms";

const QuickNoteAddon: React.FC<{ editorRef: any; monacoRef: Monaco }> = ({
	editorRef,
	monacoRef
}) => {
	const theme = useTheme();

	const [{ projectFilePath }] = useAtom(openedTabAtom);

	const [anchorEl, setAnchorEl] = useState<null | any>(null);

	// Add note fields
	const [filterTag, setFilterTag] = useState("Note");
	const [noteTitle, setNoteTitle] = useState("");

	const [menuClicked, setMenuClicked] = useState(false);

	const [selectedTool] = useAtom(selectedToolAtom);
	const [clickListener] = useAtom(editorClickListenerAtom);
	const [scrollListener] = useAtom(editorScrollListenerAtom);

	const { addNote } = useNotes();
	const [notesTags] = useAtom(notesTagsAtom);

	const popperOpen = Boolean(anchorEl);
	const lineNumber = clickListener?.lineNumber;

	// Add Quick Note button to right click menu
	useEffect(() => {
		if (editorRef && monacoRef) {
			addMonacoAction(editorRef, monacoRef, () => setMenuClicked(true), {
				id: "editor-add-quick-note-action",
				label: "Add bookmark note",
				keybindings: [monacoRef.KeyMod.CtrlCmd | monacoRef.KeyCode.Slash],
				contextMenuOrder: 2
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editorRef, monacoRef]);

	// Context menu button clicked
	useEffect(() => {
		if (menuClicked) {
			setAnchorEl({
				x: clickListener?.event?.event.editorPos.x + 70,
				y: clickListener?.event?.event.pos.y
			});
			setMenuClicked(false);
		}
	}, [menuClicked, clickListener]);

	useEffect(() => {
		if (anchorEl) {
			handleClose();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scrollListener]);

	const handleClose = () => {
		setFilterTag("Note");
		setNoteTitle("");
		setAnchorEl(null);
	};

	const handleAddNote = async (e) => {
		e.preventDefault();

		if (!noteTitle) return;

		const foundNote = notesTags?.find?.((i) => i?.name === filterTag);

		await addNote({
			title: noteTitle,
			location: `${projectFilePath}#${lineNumber}-${lineNumber}`,
			tag: filterTag,
			tagColor: (foundNote?.color || "info.light").replaceAll(".", "-")
		});

		handleClose();
	};

	if (!popperOpen) return null;

	return (
		<Stack direction="row" alignItems="center">
			<Popover
				open={popperOpen}
				onClose={handleClose}
				slotProps={{
					paper: {
						sx: {
							transformOrigin: "unset !important",
							transform: `translate(${anchorEl?.x}px, ${anchorEl?.y}px) !important`
						}
					}
				}}
			>
				<ClickAwayListener onClickAway={handleClose} mouseEvent="onPointerUp">
					<form onSubmit={handleAddNote}>
						<Stack
							direction={{ xs: "column", sm: "row" }}
							alignItems="center"
							spacing={1}
							sx={{
								p: 0.5,
								bgcolor: "background.paper",
								border: `1px solid ${theme.palette.primary.main}`,
								borderRadius: "8px",
								boxShadow: 1
							}}
						>
							<NoteTagsDropDown
								value={filterTag}
								onChange={(v: string) => setFilterTag(v)}
								position="center"
								hideAllOption
							/>

							<TextField
								autoFocus
								variant="standard"
								placeholder={`Bookmark title (Line ${lineNumber})`}
								size="small"
								fullWidth
								value={noteTitle}
								onChange={(e) => setNoteTitle(e.target.value)}
								InputProps={{
									sx: {
										width: {
											xs: 250,
											sm: 350,
											md: !!selectedTool ? 300 : 400,
											lg: !!selectedTool ? 400 : 500
										}
									},
									disableUnderline: true,
									endAdornment: (
										<InputAdornment position="end">
											<Stack direction="row" alignItems="center">
												<IconButton
													type="submit"
													size="small"
													disabled={!noteTitle}
												>
													<SaveIcon fontSize="small" />
												</IconButton>

												<IconButton size="small" onClick={handleClose}>
													<CloseIcon fontSize="small" />
												</IconButton>
											</Stack>
										</InputAdornment>
									)
								}}
							/>
						</Stack>
					</form>
				</ClickAwayListener>
			</Popover>
		</Stack>
	);
};

export default React.memo(QuickNoteAddon);
