import { ContentCopy } from "@mui/icons-material";
import { Button, Divider, Typography } from "@mui/material";
import useSetAtom from "hooks/useSetAtom";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import secureLocalStorage from "react-secure-storage";
import { privacyModeSetupModalOpenAtom } from "store/atoms/UiAtoms";
import CustomModal from ".";

interface PrivacyModeSetupModalProps {
	open: boolean;
	onClose: () => void;
}

const PrivacyModeSetupModal: React.FC<PrivacyModeSetupModalProps> = ({
	open,
	onClose
}) => {
	const [copied, setCopied] = useState(false);

	const setPrivacyModeSetupModalOpen = useSetAtom(
		privacyModeSetupModalOpenAtom
	);

	return (
		<CustomModal
			open={open}
			onClose={() => {}}
			fullWidth
			maxWidth="md"
			hideCloseButton
			paperSx={{ maxWidth: "500px", py: 6 }}
		>
			<Typography variant="h6" sx={{ textAlign: "center" }}>
				Private Mode
			</Typography>

			<Divider sx={{ my: 1.5 }} />

			<Typography sx={{ fontSize: "14px", mt: 0.5 }}>
				Copy your encryption key here and store it in a safe place. If you lose
				this key and become logged out, you will lose access to your private
				mode projects forever.
			</Typography>

			<CopyToClipboard
				text={secureLocalStorage.getItem("privateModeEncryptionKey")}
				onCopy={() => {
					setCopied(true);
					setTimeout(() => {
						setPrivacyModeSetupModalOpen(false);
					}, 1500);
				}}
			>
				<Button
					variant="contained"
					fullWidth
					color="primary"
					disabled={copied}
					sx={{
						height: "40px",
						mx: "auto",
						mt: 2
					}}
				>
					<ContentCopy sx={{ fontSize: 18, mr: 1 }} />
					{copied ? "Copied" : "Copy Encryption Key"}
				</Button>
			</CopyToClipboard>
		</CustomModal>
	);
};

export default PrivacyModeSetupModal;
