import { useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import { LOCALSTORAGE_REFERRAL } from "utils/constants";

const DiscordRedirect = () => {
	const params = useParams();

	useMemo(() => {
		window.localStorage.setItem(LOCALSTORAGE_REFERRAL, params.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Navigate to="/" />;
};

export default DiscordRedirect;
