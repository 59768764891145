import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import {
	Button,
	Hidden,
	LinearProgress,
	Stack,
	Typography
} from "@mui/material";
import { useTools } from "hooks/ui/useTools";
import { useAtom } from "jotai";
import React from "react";
import { focusedChallengeAtom, userSubmissionsAtom } from "store/atoms/BugChallengeAtoms";
import { selectedToolAtom } from "store/atoms/UiAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";

const BugChallengeButton = () => {
	const { selectTool } = useTools();

	const [selectedTool] = useAtom(selectedToolAtom);
	const [project] = useAtom(projectAtom);
	const [focusedChallenge] = useAtom(focusedChallengeAtom);
	const [submissions] = useAtom(userSubmissionsAtom);

	if (project.name !== "CodeChallenge") return null;

	return (
		<Button
			variant="contained"
			disabled={submissions?.findIndex((i) => i === focusedChallenge._id) > -1 && !focusedChallenge.solutionWriteup}
			sx={{
				height: { xs: 34, md: 36, xl: 37 },
				position: "relative",
				color: "common.white",
				borderRadius: "8px",
				boxShadow: 1,
				background: "linear-gradient(45deg, #b88f26, #8b6e1e)",
				"&:hover": {
					background: "linear-gradient(45deg, #a47821, #7e5d19)"
				}
			}}
			onClick={() => {
				if (selectedTool === "BugChallenge") selectTool(null);
				else selectTool("BugChallenge");
			}}
		>
			<Stack direction="row" alignItems="center" spacing={1}>
				<EmojiEventsIcon fontSize="small" />
				{/* <Typography>Submit Challenge Answer</Typography> */}

				<Hidden smDown>
					{!focusedChallenge.solutionWriteup ? 
						<Typography>{submissions?.findIndex((i) => i === focusedChallenge._id) > -1 ? "Already Submitted" : "Submit Challenge Answer"}</Typography> :
						<Typography>View Challenge Solution</Typography>
					}
				</Hidden>
			</Stack>

			<LinearProgress
				sx={{
					opacity: 0.05,
					position: "absolute",
					top: 0,
					bottom: 0,
					right: 0,
					height: "100%",
					width: "100%",
					"& .MuiLinearProgress-bar": {
						animationDuration: "4s",
						animationDirection: "alternate"
					}
				}}
			/>
		</Button>
	);
};

export default React.memo(BugChallengeButton);
