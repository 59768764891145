import { Icon } from "@iconify/react";
import {
	Button,
	Container,
	Divider,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import ReportsForm, { ReportsFormType } from "components/forms/ReportsForm";
import { useLocalStorage } from "hooks/ui/useLocalStorage";
import { useAtom } from "jotai";
import { useMemo, useState } from "react";
import { openedTabAtom } from "store/atoms/UiAtoms";
import { findingsAtom } from "store/atoms/findingsAtom";
import { LOCALSTORAGE_REPORT_FORM } from "utils/constants";
import MdReport from "./MdReport";
import {
	buildReportMarkdown,
	generateDocDocument,
	getReportData
} from "./helpers";
import { downloadMarkdownFile } from "utils/helpersFunctions";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
	borderRadius: "8px",
	...(theme.palette.mode === "light" && {
		border: 0,
		color: `${theme.palette.text.primary}!important`
	}),
	"&.Mui-selected, &.Mui-selected:hover": {
		backgroundColor: theme.palette.background["selected"],
		...(theme.palette.mode === "light" && {
			color: `${theme.palette.text.primary}!important`
		})
	}
}));

const ReportTool = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [toggleValue, setToggleValue] = useState(0);

	const [{ fullProjectFilePath }] = useAtom(openedTabAtom);

	const [storageRes, setReponse] = useLocalStorage(
		LOCALSTORAGE_REPORT_FORM,
		{}
	);

	const storageValues = storageRes?.[fullProjectFilePath] || {};

	const [findings] = useAtom(findingsAtom);

	const handleFormChange = (data) => {
		setReponse((old) => ({ ...(old || {}), [fullProjectFilePath]: data }));
	};

	const handleFormSubmit = (data) => {};

	const reportData = useMemo(() => {
		return getReportData(storageValues, findings);
	}, [storageValues, findings]);

	const handleGenerateDocxDocument = async () => {
		await generateDocDocument(reportData);
	};

	const handleGenerateMdFile = () => {
		const markdown = buildReportMarkdown(reportData);
		const fileName = "audit_report.md";

		downloadMarkdownFile(markdown, fileName);
	};

	return (
		<Container
			maxWidth="md"
			sx={{ bgcolor: "background.default", borderRadius: "10px", p: 3, my: 3 }}
		>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Typography variant="h6">
					{toggleValue === 0 ? "Build your report" : "Preview report"}
				</Typography>

				<ToggleButtonGroup
					size="small"
					color="primary"
					value={toggleValue}
					exclusive
					onChange={(e, v) => setToggleValue(v)}
					// fullWidth
					sx={{
						height: 38,
						borderRadius: "8px",
						bgcolor: "background.default",
						...(themeMode === "light" && {
							bgcolor: "background.paper",
							boxShadow: 1
						})
					}}
				>
					<StyledToggleButton value={0}>
						<Icon icon="mdi:text-box-edit" fontSize={20} />
					</StyledToggleButton>

					<StyledToggleButton value={1}>
						<Icon icon="mdi:print-preview" fontSize={20} />
					</StyledToggleButton>
				</ToggleButtonGroup>
			</Stack>

			<Divider sx={{ mt: 1.5, mb: 2 }} />

			{toggleValue === 0 && (
				<Stack spacing={3}>
					<ReportsForm
						handleSubmit={handleFormSubmit}
						values={storageValues}
						onChange={handleFormChange}
					/>
				</Stack>
			)}

			{toggleValue === 1 && (
				<Stack spacing={2}>
					{/* Download Buttons */}
					<Stack
						spacing={1.5}
						direction={{ xs: "column", sm: "row" }}
						justifyContent="center"
					>
						<Button
							variant="contained"
							onClick={handleGenerateMdFile}
							sx={{ borderRadius: "8px", boxShadow: 1, color: "text.primary" }}
						>
							Download markdown report
						</Button>
{/* 
						<Button
							variant="outlined"
							onClick={handleGenerateDocxDocument}
							sx={{ borderRadius: "8px", boxShadow: 1 }}
						>
							Download docx report
						</Button> */}
					</Stack>

					<MdReport data={reportData} />
				</Stack>
			)}
		</Container>
	);
};

export default ReportTool;
