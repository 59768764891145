import ProjectFile from "./ProjectFile";
import ProjectFolder from "./ProjectFolder";

export default class ProjectImport extends ProjectFile {
	readonly origin: string;
	readonly source: string;
	readonly type: string;

	constructor(
		source: string,
		contents: string,
		id: string,
		origin: string,
		type: string,
		ast
	) {
		const name = source.split(/[/\\]/).pop();
		super(name, contents, id, ast);
		this.origin = origin;
		this.source = source;
		this.type = type;
	}

	static deserialize(imports): ProjectFolder {
		type TreeNode = { name: string; children: (TreeNode | ProjectImport)[] };
		const rootNode: TreeNode = { name: "Imports", children: [] };

		// Build a tree from the directory structure of the import nodes
		for (const projectImport of imports) {
			// Remove protocol string to avoid issues with split() and to get cleaner names
			const pathElements = projectImport.source
				.replace(/(^\w+:|^)\/\//, "")
				.split(/[/\\]/);
			let currentNode = rootNode;

			// Add all folders to the tree
			for (let i = 0; i < pathElements.length - 1; i++) {
				// Find existing folder or instantiate a new one
				let nextNode = currentNode.children.find(
					(node) =>
						!node.hasOwnProperty("origin") && node.name === pathElements[i]
				) as TreeNode;
				if (!nextNode) {
					nextNode = { name: pathElements[i], children: [] };
					currentNode.children.push(nextNode);
				}
				currentNode = nextNode;
			}

			// Add project import for the leaf node of this path
			currentNode.children.push(
				new ProjectImport(
					projectImport.source,
					projectImport.text,
					projectImport.id,
					projectImport.origin,
					projectImport.type,
					projectImport.ast
				)
			);
		}

		// Traverse the tree and convert to ProjectFolder
		function convertTreeNodeToProjectFolder(node: TreeNode): ProjectFolder {
			const files: ProjectImport[] = [];
			const folders: ProjectFolder[] = [];
			for (const treeNode of node.children) {
				if (treeNode.hasOwnProperty("origin"))
					files.push(treeNode as ProjectImport);
				else folders.push(convertTreeNodeToProjectFolder(treeNode as TreeNode));
			}

			return new ProjectFolder(node.name, files, folders);
		}

		return new ProjectFolder(
			rootNode.name,
			[],
			rootNode.children.map((child) => {
				return convertTreeNodeToProjectFolder(child as TreeNode);
			})
		);
	}
}
