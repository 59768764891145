import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Box,
	Stack,
	SxProps,
	Typography,
	useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import hex from "utils/hexTransparency";

const ScanToolItem: React.FC<{
	title: string;
	subtitle?: string;
	caption: string;
	image: string;
	disabled?: boolean;
	noAccordion?: boolean;
	ButtonComponent?: React.ReactNode;
	expanded?: boolean;
	sx?: SxProps;
	children?: React.ReactNode;
}> = ({
	title,
	caption,
	image,
	subtitle,
	disabled,
	ButtonComponent,
	noAccordion,
	expanded: propsExpanded = false,
	sx = {},
	children
}) => {
	const [expanded, setExpanded] = useState(propsExpanded);
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const MainComponent = () => (
		<Stack
			direction="row"
			alignItems="center"
			spacing={1.5}
			sx={{ width: "100%", mr: 1 }}
		>
			<Avatar src={image} sx={{ width: 36, height: 36 }} />

			<Stack sx={{ flex: 1 }}>
				<Stack direction="row" spacing={0.5} alignItems="center">
					<Typography sx={{ fontWeight: 600 }}>{title}</Typography>

					{subtitle && (
						<Typography color="text.secondary" variant="body2">
							{subtitle}
						</Typography>
					)}
				</Stack>

				<Typography color="text.secondary" variant="body2">
					{caption}
				</Typography>
			</Stack>

			{ButtonComponent}
		</Stack>
	);

	useEffect(() => {
		setExpanded(propsExpanded);
	}, [propsExpanded]);

	if (noAccordion) {
		return (
			<Box
				sx={{
					mt: "16px !important",
					width: "100%",
					borderRadius: "8px !important",
					backgroundImage: "none",
					py: 1,
					px: 2,
					...(themeMode === "dark" && {
						bgcolor: "transparent !important",
						border: disabled
							? `1px solid ${theme.palette.primary.dark}${hex["30%"]}`
							: `1px solid ${theme.palette.primary.dark}${hex["80%"]}`
					}),
					...(themeMode === "light" && {
						bgcolor: "background.paper",
						boxShadow: 1
					}),
					...sx
				}}
			>
				<MainComponent />
			</Box>
		);
	}

	return (
		<Accordion
			square={false}
			disableGutters
			disabled={disabled}
			expanded={expanded}
			onChange={(e, v) => setExpanded(v)}
			sx={{
				mt: "16px !important",
				width: "100%",
				borderRadius: "8px !important",
				backgroundImage: "none",
				"&.MuiAccordion-root:before": {
					bgcolor: "transparent"
				},
				...(themeMode === "dark" && {
					bgcolor: "transparent !important",
					border: disabled
						? `1px solid ${theme.palette.primary.dark}${hex["30%"]}`
						: `1px solid ${theme.palette.primary.dark}${hex["80%"]}`
				}),
				...(themeMode === "light" && {
					bgcolor: disabled
						? `${theme.palette.background.paper}${hex["80%"]}!important`
						: "background.paper",
					boxShadow: 1
				}),
				...sx
			}}
		>
			<AccordionSummary expandIcon={<ArrowDropDownIcon />}>
				<MainComponent />
			</AccordionSummary>
			
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
};

export default ScanToolItem;
