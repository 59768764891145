import { useAtom } from "jotai";
import { useParams } from "react-router-dom";
import { openedTabAtom } from "store/atoms/UiAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import {
	getObjectFromLocalStorage,
	updateObjectInLocalStorage
} from "utils/helpersFunctions";
import { LOCALSTORAGE_SCAN_RESULTS } from "../../utils/constants";

export function useCodeScan() {
	const [{ projectFilePath }] = useAtom(openedTabAtom);

	const [project] = useAtom(projectAtom);

	const params = useParams();

	const projectId = project?.id || params?.projectId;

	const loadScanResults = (withProjectFilePath = true) => {
		if (projectId) {
			// Load initial files
			const _scanResultsStorage: any = getObjectFromLocalStorage(
				`${LOCALSTORAGE_SCAN_RESULTS}_${projectId}`
			);

			return withProjectFilePath
				? _scanResultsStorage?.[projectFilePath]
				: _scanResultsStorage;
		}

		return null;
	};

	const saveScanResult = (
		response: any,
		_projectId = projectId,
		_projectFilePath = projectFilePath
	) => {
		if (_projectId) {
			const obj = { [_projectFilePath]: response };

			updateObjectInLocalStorage(
				`${LOCALSTORAGE_SCAN_RESULTS}_${_projectId}`,
				obj
			);
		}
	};

	const removePersistedProjectScans = () => {
		if (projectId) {
			localStorage.removeItem(`${LOCALSTORAGE_SCAN_RESULTS}_${projectId}`);
		}
	};

	return { loadScanResults, saveScanResult, removePersistedProjectScans };
}
