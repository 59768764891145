import { Excalidraw } from "@excalidraw/excalidraw";
import { ExcalidrawElement } from "@excalidraw/excalidraw/types/element/types";
import { AppState, BinaryFiles } from "@excalidraw/excalidraw/types/types";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, Stack, TextField, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useDebounce } from "hooks/ui/useDebounce";
import { useLocalStorage } from "hooks/ui/useLocalStorage";
import React, { useEffect, useState } from "react";
import { LOCALSTORAGE_DRAWING_TOOL } from "utils/constants";
import { timeout } from "utils/helpersFunctions";

const areValuesEqualInJSONs = (stringA, stringB) => {
	try {
		if (!stringA || !stringB) return false;

		// Parse the JSON strings into objects
		const objectA = JSON.parse(stringA);
		const objectB = JSON.parse(stringB);

		// Iterate over the keys in objectB
		for (const _key in objectB) {
			// Check if the key exists in objectA and if the values are equal
			if (
				objectA.hasOwnProperty(_key) &&
				JSON.stringify(objectA[_key]) === JSON.stringify(objectB[_key])
			) {
				continue; // Values are equal, continue to the next key
			} else {
				return false; // Values are not equal, return false
			}
		}

		return true; // All values in stringB are equal to their equivalents in stringA
	} catch (error) {
		return false;
	}
};

const ExcalidrawCore: React.FC<{
	title?: string;
	value?: string;
	files?: string;
	appState?: string;
	_id?: string;
	handleSaveBoard?: any;
	handleUpdateBoard?: any;
}> = ({
	title: propsTitle,
	value,
	files,
	appState,
	_id,
	handleSaveBoard,
	handleUpdateBoard
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const isEditingMode = !!_id;

	const [loading, setLoading] = useState(true);

	// Storage stuff
	const DRAWING_KEY = `${LOCALSTORAGE_DRAWING_TOOL}-${
		isEditingMode ? _id : "NEW"
	}`;
	const FILES_KEY = `${LOCALSTORAGE_DRAWING_TOOL}-FILES-${
		isEditingMode ? _id : "NEW"
	}`;
	const APPSTATE_KEY = `${LOCALSTORAGE_DRAWING_TOOL}-APPSTATE-${
		isEditingMode ? _id : "NEW"
	}`;
	const [storageDrawing, setStorageDrawing] = useLocalStorage(
		DRAWING_KEY,
		value || "[]"
	);
	const [storageFiles, setStorageFiles] = useLocalStorage(
		FILES_KEY,
		files || "{}"
	);
	const [storageState, setStorageState] = useLocalStorage(
		APPSTATE_KEY,
		appState || "{}"
	);
	// End of Storage stuff

	const [savedAppstate, setSavedAppstate] = useState(appState);
	const [savedDrawing, setSavedDrawing] = useState(value);

	const [savedTitle, setSavedTitle] = useState(propsTitle);
	const [title, setTitle] = useState(propsTitle);

	const debouncedTitle = useDebounce(title, 2000);
	const debouncedValue = useDebounce(storageDrawing, 5000);
	const debouncedAppstate = useDebounce(storageState, 2000);

	const disabled =
		areValuesEqualInJSONs(storageState, savedAppstate) &&
		savedDrawing === storageDrawing &&
		title === savedTitle;

	useEffect(() => {
		setLoading(false);

		return () => {
			if (_id) {
				localStorage.removeItem(DRAWING_KEY);
				localStorage.removeItem(FILES_KEY);
				localStorage.removeItem(APPSTATE_KEY);
			}
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_id]);

	const handleSave = async () => {
		setLoading(true);

		if (isEditingMode) {
			return await handleUpdateBoard(
				title,
				storageDrawing,
				storageFiles,
				storageState
			).then(() => {
				setSavedAppstate(storageState);
				setSavedDrawing(storageDrawing);
				setSavedTitle(title);
				setTimeout(() => {
					setLoading(false);
				}, 1000);
			});
		} else {
			timeout(1000).then(async () => {
				return await handleSaveBoard(
					title,
					storageDrawing,
					storageFiles,
					storageState
				)
					.then(() => {
						setSavedAppstate(storageState);
						setSavedDrawing(storageDrawing);
						setSavedTitle(title);
						setLoading(false);
					})
					.then(() => {
						localStorage.removeItem(DRAWING_KEY);
						localStorage.removeItem(FILES_KEY);
						localStorage.removeItem(APPSTATE_KEY);
					});
			});
		}
	};

	// Autosave
	useEffect(() => {
		if (!!_id && !disabled && !loading && debouncedTitle) {
			console.log("Autosaving");
			setLoading(true);
			handleSave().then(() => {
				setTimeout(() => {
					setLoading(false);
				}, 1000);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedTitle, debouncedValue, debouncedAppstate]);

	// console.log(1234, storageDrawing.slice(0, 10), storageState);

	return (
		<Box sx={{ height: "100%", position: "relative" }}>
			<Excalidraw
				initialData={{
					elements: JSON.parse(storageDrawing || "[]"),
					files: JSON.parse(storageFiles || "{}"),
					appState: JSON.parse(storageState || "{}")
				}}
				theme={themeMode}
				renderTopRightUI={() => (
					<Stack justifyContent="center">
						<LoadingButton
							disabled={disabled}
							loading={loading}
							variant="outlined"
							onClick={handleSave}
							sx={{ borderRadius: "0.5rem", minWidth: 0, height: 36 }}
						>
							<SaveOutlinedIcon fontSize="small" />
						</LoadingButton>
					</Stack>
				)}
				onChange={(
					_value: ExcalidrawElement[],
					toolState: AppState,
					_files: BinaryFiles
				) => {
					setStorageDrawing(JSON.stringify(_value));
					setStorageFiles(JSON.stringify(_files));
					setStorageState(
						JSON.stringify({
							scrollX: toolState.scrollX,
							scrollY: toolState.scrollY,
							zoom: toolState.zoom,
							offsetLeft: toolState.offsetLeft,
							offsetTop: toolState.offsetTop
						})
					);
				}}
			/>

			<Box
				sx={{
					position: "absolute",
					bottom: 20,
					left: { xs: "10%", lg: "45%" },
					zIndex: 99999
				}}
			>
				<TextField
					size="small"
					variant="outlined"
					placeholder="Board Title"
					value={title}
					onChange={(e) => setTitle(e.target.value)}
					sx={{
						boxShadow: 1,
						...(themeMode === "light" && {
							"& fieldset": {
								borderColor: grey[300]
							}
						}),
						...(themeMode === "light" && {
							bgcolor: "background.paper"
						})
					}}
					InputProps={{
						sx: {
							borderRadius: "0.5rem",
							minWidth: 200
						}
					}}
				/>
			</Box>
		</Box>
	);
};

export default React.memo(ExcalidrawCore);
