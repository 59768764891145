import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import {
	Card,
	Grid,
	Box,
	Button,
	Checkbox,
	Link,
	Collapse,
	FormControlLabel,
	Stack,
	SxProps,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import { addErrorNotification } from "Resources/Notifications";
import { scanFileWithSlither } from "Resources/ServerInterface";
import { scanFileWithAderyn } from "Resources/ServerInterface";
import { scanFileWith4naly3er } from "Resources/ServerInterface";
import { mixpanelAtom } from "atoms";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import hex from "utils/hexTransparency";
import { openedTabAtom } from "store/atoms/UiAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import stripAnsi from "strip-ansi";
import ScanToolItem from ".";
import {
	countToolResultsForOpenedFile,
	getNewestResultsFilteredByTool,
	getToolResultsDividedBySeverity
} from "../helpers";

export const parseSlitherErrorData = (inputData) => {
	const result = {};

	// Iterate through the original data
	for (const version in inputData) {
		const { title, description } = inputData[version];

		// If the title does not exist in the result, initialize it with an empty array
		if (!result[title]) {
			result[title] = {
				version: [],
				description
			};
		}

		// Push the current version to the array under the corresponding title
		result[title].version.push(version);
	}

	return result;
};

const getSeverity = (sev) => {
	const severity = !["High", "Medium", "Low"].includes(sev) ? "Other" : sev;

	return severity;
};

const StripAnsiTextComponent = ({ text }) => {
	// Remove ANSI codes from the text
	const strippedText = stripAnsi(text);

	// Render the stripped text
	return <>{strippedText}</>;
};

const SlitherScanPieChart: React.FC<{
	data: any;
	withCheckboxFilter?: boolean;
	filter?: object;
	setFilter?: any;
	onlyChart?: boolean;
}> = ({ data, withCheckboxFilter, filter, setFilter, onlyChart }) => {
	const theme = useTheme();

	const toggleChange = (name) => {
		if (filter && setFilter) {
			const severity = getSeverity(name);
			setFilter((old) => ({ ...old, [severity]: !old[severity] }));
		}
	};

	const LegendItem = ({ Icon, value, label, color }) => (
		<Stack
			direction="row"
			spacing={1}
			alignItems="center"
			sx={{
				px: 1.5,
				py: 1,
				bgcolor:
					withCheckboxFilter && !!filter?.[label]
						? `${theme.palette.primary.main}${hex["60%"]}`
						: "background.paper",
				borderRadius: "8px",
				cursor: withCheckboxFilter ? "pointer" : "default"
			}}
			onClick={() => toggleChange(label)}
		>
			<Icon sx={{ color }} />

			<Typography>
				<Typography component="span" sx={{ fontWeight: 600 }}>
					{value}
				</Typography>{" "}
				{label}
			</Typography>
		</Stack>
	);

	if (onlyChart) {
		return (
			<Box sx={{ height: 28, width: 28 }}>
				<ReactECharts
					style={{ height: "100%" }}
					option={{
						tooltip: {
							trigger: "item"
						},
						series: [
							{
								type: "pie",
								radius: ["60%", "90%"],
								avoidLabelOverlap: false,
								itemStyle: {
									borderRadius: 2,
									borderColor: theme.palette.background.default,
									borderWidth: 1
								},
								label: {
									show: false
								},
								labelLine: {
									show: false
								},
								data: data?.map?.((i) => ({
									name: i.name,
									value: i.value,
									itemStyle: { color: i.color }
								}))
							}
						]
					}}
				/>
			</Box>
		);
	}

	return (
		<Card sx={{ p: 2, borderRadius: "14px" }}>
			<Grid container spacing={2} alignItems="center">
				{/* Pie chart */}
				<Grid item xs={12} lg={6}>
					<ReactECharts
						style={{ height: 180 }}
						option={{
							tooltip: {
								trigger: "item"
							},
							series: [
								{
									type: "pie",
									radius: ["70%", "90%"],
									avoidLabelOverlap: false,
									itemStyle: {
										borderRadius: 10,
										borderColor: theme.palette.background.default,
										borderWidth: 2
									},
									label: {
										show: false
									},
									labelLine: {
										show: false
									},
									data: data?.map?.((i) => ({
										name: i.name,
										value: i.value,
										itemStyle: { color: i.color }
									}))
								}
							]
						}}
					/>
				</Grid>

				{/* Legend */}
				<Grid item xs={12} lg={6}>
					<Stack spacing={1}>
						{data?.map?.((item, i) => (
							<LegendItem
								key={i}
								Icon={item.Icon}
								value={item.value}
								label={item.name}
								color={item.color}
							/>
						))}
					</Stack>
				</Grid>
			</Grid>
		</Card>
	);
};

export const SlitherErrorItem: React.FC<{
	title: string;
	description: string;
	version: string[];
	sx?: SxProps;
	ansiText?: string;
	defaultCollapsed?: boolean;
}> = ({
	title,
	description,
	version,
	sx = {},
	ansiText = null,
	defaultCollapsed
}) => {
	const [collapsed, setCollapsed] = useState(!!defaultCollapsed);
	const [ended, setEnded] = useState(true);
	const theme = useTheme();

	const sortedVersions = version?.sort?.((a, b) =>
		a.localeCompare(b, undefined, { numeric: true })
	);
	const versionsString = sortedVersions?.join(", ");

	const rangeString =
		sortedVersions.length > 2
			? `${sortedVersions[0]} - ${sortedVersions[sortedVersions.length - 1]}`
			: versionsString;

	return (
		<Box
			sx={{
				cursor: "pointer",
				p: 1.5,
				border: `1px solid ${theme.palette.primary.dark}`,
				borderRadius: "8px",
				...sx
			}}
			onClick={() => setCollapsed(!collapsed)}
		>
			<Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
				<InfoIcon fontSize="small" sx={{ color: "warning.main" }} />

				<Typography
					sx={{ flex: 1, fontWeight: 500, overflowWrap: "break-word" }}
					variant="body2"
				>
					{title}
				</Typography>

				{rangeString && (
					<Tooltip title={sortedVersions.length > 2 ? versionsString : ""}>
						<Typography
							color="warning.light"
							variant="body2"
							sx={{
								// textAlign: "right",
								maxWidth: 100,
								pl: 1,
								overflow: "hidden",
								textOverflow: "ellipsis",
								display: "-webkit-box",
								WebkitBoxOrient: "vertical",
								WebkitLineClamp: 2,
								maxHeight: "3em"
							}}
						>
							{rangeString}
						</Typography>
					</Tooltip>
				)}
			</Stack>

			{(description || ansiText) && (
				<Collapse
					in={!collapsed}
					// collapsedSize={46}
					onEntering={() => {
						setEnded(false);
					}}
					onExited={() => {
						setEnded(true);
					}}
				>
					<Typography
						variant="body2"
						sx={{
							color: "text.secondary",
							overflowWrap: "break-word",
							fontSize: "11px",
							...(collapsed && {
								textOverflow: "ellipsis",
								display: "-webkit-box",
								WebkitBoxOrient: "vertical",
								WebkitLineClamp: ended && 3
							})
						}}
					>
						{!!ansiText ? (
							<StripAnsiTextComponent text={ansiText.toString()} />
						) : (
							<>{description}</>
						)}
					</Typography>
				</Collapse>
			)}
		</Box>
	);
};

const ImplementedScanToolItem: React.FC<{
	title: string;   // display name of the tool
	toolKey: string; // key to represent the tool programatically (e.g. slither instead of Slither)
	caption: string;
	image: string;
	loading: boolean;
	setLoading: any;
	setResultsView: any;
	setResultsFromChild: any
	setEndPolling: any
	polledToolTaskResults: Array<object>;
	scanDisabled?: boolean;
}> = ({
	title,
	toolKey,
	caption,
	image,
	loading,
	setLoading,
	setResultsView,
	setResultsFromChild,
	setEndPolling,
	polledToolTaskResults,
	scanDisabled
}) => {
	const [resultsCountBySeverity, setResultsCountBySeverity] = useState([]);

	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [includeSlitherin, setIncludeSlitherin] = useState(true);

	const [{ openedTab, projectFilePath }] = useAtom(openedTabAtom);

	const [project] = useAtom(projectAtom);
	const [mixpanel] = useAtom(mixpanelAtom);

	const [errors, setErrors] = useState({});

	const [totalResultsCount, setTotalResultsCount] = useState(0);
	const [results, setResults] = useState([]);
	const [emptyScanResults, setNoResults] = useState(false)

	// Reset result related data if currently selected file is changed
	useEffect(() => {
		setNoResults(false)
		setTotalResultsCount(0);
		setResults([]);
		setResultsFromChild([]);
		setResultsCountBySeverity([]);
		setErrors({})
		setLoading(false);
		setEndPolling(false)
	}, [openedTab?.path]);

	// React to polling data changes and update results data
	useEffect(() => {
		if (polledToolTaskResults) {
			if ("info" in polledToolTaskResults) {
				setNoResults(false)
				if (polledToolTaskResults["info"] == "No task data found for file.") {
					setErrors({})
				} else if (polledToolTaskResults["info"] == "No task results found for file.") {
					setErrors({})
				}
				return
			}

			const taskStatuses = polledToolTaskResults["taskStatuses"]
			if (taskStatuses.length == 0) return                   // no status for file at all, end effect
			if (!taskStatuses[0].hasOwnProperty(toolKey)) return   // no status for this scan tool implementation, end effect

			const currentTaskStatus = taskStatuses[0][toolKey]["status"];
			switch (currentTaskStatus) {
				case "queued":
					setLoading(true)
					setErrors({})
					break;

				case "success":
					setErrors({})
					if (!openedTab?.path) {
						console.warn("No opened tab path")
						break
					}

					const resultsFilteredByTool = getNewestResultsFilteredByTool(
						polledToolTaskResults["projectSarif"],
						toolKey,
						openedTab.path
					);



					if (Object.keys(resultsFilteredByTool).length === 0) {
						if (Object.keys(errors).length === 0)  {
							setNoResults(true)
						} 
						setLoading(false);
						break
					}
					
					const numericResultsCount = countToolResultsForOpenedFile(
						resultsFilteredByTool,
						openedTab.path
					);

					setTotalResultsCount(numericResultsCount);
					setResults(resultsFilteredByTool);
					setLoading(false);					
					setResultsFromChild(resultsFilteredByTool);

					const resultsDividedBySeverity = getToolResultsDividedBySeverity(
						resultsFilteredByTool,
						openedTab.path,
						theme
					);
					setResultsCountBySeverity(resultsDividedBySeverity);
					break;

				case "error":
					if (!openedTab?.path) {
						console.warn("No opened tab path")
						break
					}

					let ensureEmptyResultsFilteredByTool = {}
					if (polledToolTaskResults["projectSarif"]) {
						ensureEmptyResultsFilteredByTool = getNewestResultsFilteredByTool(
							polledToolTaskResults["projectSarif"],
							toolKey,
							openedTab.path
						);
					}
					if (Object.keys(ensureEmptyResultsFilteredByTool).length === 0) {
						// Either use tool's errors or the default error message.
						let errorsObject = {
							"Compilation error": {
								version: [],
								description: "Failed to compile contract."
							}
						}
						const errorsFromTool = polledToolTaskResults["taskStatuses"]?.[0]?.[toolKey]?.errors;
						if (errorsFromTool && typeof errorsFromTool === 'object' && Object.keys(errorsFromTool).length > 0) {
							errorsObject = errorsFromTool;
						}
						setErrors(errorsObject);
						setLoading(false);
					}
					break

				default:
					console.log(`Task errored with unknown status ${currentTaskStatus}`);
			}
		}
	}, [polledToolTaskResults, totalResultsCount, openedTab?.path]);

	const sendScanningTask = async () => {
		try {
			setLoading(true);
			setEndPolling(false)
			switch (toolKey) {
				case "slither":
					let slitherRes = await scanFileWithSlither(
						openedTab.path,
						project.id,
						includeSlitherin,
						mixpanel
					)
					if (slitherRes.error) {
						throw slitherRes.error
					}
					break
				case "aderyn":
					let aderynRes = await scanFileWithAderyn(
						openedTab.path,
						project.id,
						mixpanel
					)
					if (aderynRes.error) {
						throw aderynRes.error
					}
					break
				case "4naly3er":
					let analyzerRes = await scanFileWith4naly3er(
						openedTab.path,
						project.id,
						mixpanel
					)
					if (analyzerRes.error) {
						throw analyzerRes.error
					}
					break
			}
		} catch (err) {
			setLoading(false);
			setEndPolling(true)

			addErrorNotification("Error", err.message)
		}
	};

	return (
		<ScanToolItem
			title={title}
			caption={caption}
			image={image}
			disabled={loading || scanDisabled}
			expanded={!!Object.keys(errors).length}
			noAccordion={!!totalResultsCount || emptyScanResults}
			sx={{
				...(!!Object.keys(errors).length
					? {
							border: `2px solid ${theme.palette.warning.main}`,
							boxShadow: `${theme.palette.warning.main} 0px 0px 6px`,
							width: "98%"
					  }
					: {})
			}}
			ButtonComponent={
				!!Object.keys(errors).length ? (
					<Stack direction="row" spacing={0.5}>
						<InfoIcon fontSize="small" sx={{ color: "warning.main" }} />
						<Typography color="text.secondary">
							<Typography
								component="span"
								color="text.primary"
								sx={{ fontWeight: 600 }}
							>
								{Object.keys(errors).length}
							</Typography>{" "}
							errors
						</Typography>
					</Stack>
				) : !!totalResultsCount ? (
					<Button
						size="small"
						variant="outlined"
						sx={{
							borderRadius: "8px",
							py: 1,
							color: "inherit",
							boxShadow: 1
						}}
						onClick={(e) => {
							e.stopPropagation();
							setResultsView(toolKey);
						}}
					>
						<Stack direction="row" alignItems="center" spacing={1}>
							<SlitherScanPieChart data={resultsCountBySeverity} onlyChart />
							<Typography>
								<Typography component="span" sx={{ fontWeight: 600 }}>
									{totalResultsCount}
								</Typography>{" "}
								Result
								{totalResultsCount > 1 ? "s" : ""}
							</Typography>
						</Stack>
					</Button>
				) : emptyScanResults ? (
					<Button
						size="small"
						variant="outlined"
						sx={{
							borderRadius: "8px",
							py: 1,
							color: "inherit",
							boxShadow: 1
						}}
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<Stack direction="row" alignItems="center" spacing={1}>
							<SlitherScanPieChart data={resultsCountBySeverity} onlyChart />
							<Typography>
								<Typography component="span" sx={{ fontWeight: 600 }}>
									0
								</Typography>{" "}
								Results
							</Typography>
						</Stack>
					</Button>
				) : (
					<LoadingButton
						variant="outlined"
						loading={loading}
						color="inherit"
						sx={{ borderColor: "text.secondary" }}
						onClick={(e) => {
							e.stopPropagation();
							sendScanningTask();
						}}
					>
						Scan
					</LoadingButton>
				)
			}
		>
			{!!Object.keys(errors).length ? (
				<Stack spacing={1.5}>
					{Object.keys(errors).map((_key, i) => (
						<SlitherErrorItem
							key={i}
							title={_key}
							description={errors[_key].description}
							version={errors[_key].version}
						/>
					))}
				</Stack>
			) : !!results?.length ? (
				<SlitherScanPieChart data={resultsCountBySeverity} />
			) : (
				<Stack>
					{/* Conditional rendering based on toolKey */}
					{toolKey === "slither" && (
						<FormControlLabel
							control={
								<Checkbox
									checked={includeSlitherin}
									onChange={(event) =>
										setIncludeSlitherin(event.target.checked)
									}
								/>
							}
							label={
								<>
									<Typography component="span">Include </Typography>
									<Link
										target="_blank"
										rel="noreferrer"
										href={"https://github.com/pessimistic-io/slitherin/"}
									>
										Slitherin
									</Link>
									<Typography component="span"> detectors?</Typography>
								</>
							}
						/>
					)}
{/* 
					{toolKey === "aderyn" && (
						<Typography component="span">
							Discover vulnerabilities using aderyn{" "}
						</Typography>
					)} */}

					{toolKey === "4naly3er" && (
						<Typography component="span">
							Discover vulnerabilities using 4naly3er{" "}
						</Typography>
					)}
				</Stack>
			)}
		</ScanToolItem>
	);
};

export default ImplementedScanToolItem;
