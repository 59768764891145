import { goToEditorLine } from "@auditware/CodeEditorCore/helpers";
import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { JumpToLineAtom, defaultJumpToLine } from "store/atoms/EditorAtoms";

const GoToLineAddon: React.FC<{ editorRef: any; monacoRef: any }> = ({
	editorRef,
	monacoRef
}) => {
	const [jumpToEditorLine, setJumpToEditorLine] = useAtom(JumpToLineAtom);

	// Scroll to line based on state value
	useEffect(() => {
		if (jumpToEditorLine?.startLineNumber > -1) {
			goToEditorLine(
				editorRef,
				jumpToEditorLine.startLineNumber,
				jumpToEditorLine.endLineNumber,
				false
			);

			setJumpToEditorLine(defaultJumpToLine);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jumpToEditorLine]);

	return null;
};

export default React.memo(GoToLineAddon);
