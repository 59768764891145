import { yupResolver } from "@hookform/resolvers/yup";
import {
	Stack,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { FieldValues, Resolver, useForm } from "react-hook-form";
import * as Yup from "yup";
import FormTextField from "../../formFields/FormTextField";

const StyledTableCell = styled(TableCell)({
	borderBottom: "none",
	paddingBottom: "4px"
});

export interface ReportsFormType {
	auditorName: string;
	clientName: string;
	date: string;
	auditorTwitter?: string;
	auditorGithub?: string;
	auditorTelegram?: string;
	auditorLinkedin?: string;

	description: string;
	auditType: string;
	auditTimeline: string;
	evm: string;
	languages: string;
	documentationQuality: string;
	testQuality: string;
	commitHash: string;
}

interface Props {
	handleSubmit: (data: ReportsFormType) => void;
	disabled?: boolean;
	edit?: boolean;
	values?: null | any;
	onChange?: any;
}

const schema = Yup.object().shape({
	clientName: Yup.string().required("Client Name is required"),
	auditorName: Yup.string().required("Auditor Name field is required"),
	date: Yup.string().required("Date is required"),
	auditorTwitter: Yup.string(),
	auditorGithub: Yup.string(),
	auditorTelegram: Yup.string(),
	auditorLinkedin: Yup.string(),

	description: Yup.string().required("Description is required"),
	auditType: Yup.string(),
	auditTimeline: Yup.string(),
	evm: Yup.string(),
	languages: Yup.string(),
	documentationQuality: Yup.string(),
	testQuality: Yup.string(),
	commitHash: Yup.string()
});

const ReportsForm: React.FC<Props> = ({
	handleSubmit: propsHandleSubmit,
	disabled: PropsDisabled = false,
	edit,
	values = {},
	onChange
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [disabled, setDisabled] = useState(PropsDisabled);

	useEffect(() => {
		setDisabled(PropsDisabled);
	}, [PropsDisabled]);

	const formOptions = {
		resolver: yupResolver(schema) as Resolver<ReportsFormType, FieldValues>,
		defaultValues: {
			date: format(new Date(), "MM/dd/yyyy"),
			...values
		}
	};

	const {
		handleSubmit,
		register,
		control,
		setValue,
		watch,
		formState: { errors }
	} = useForm<FieldValues & ReportsFormType & any>(formOptions);

	const onSubmit = (data: ReportsFormType) => {
		// setDisabled(true);
		propsHandleSubmit?.(data);
	};

	const Item = ({ label, name, placeholder }) => (
		<TableRow>
			<StyledTableCell sx={{ width: { xs: 140, md: 220 } }}>
				{label}
			</StyledTableCell>

			<StyledTableCell>
				<FormTextField
					placeholder={placeholder}
					fullWidth
					disabled={disabled}
					required
					name={name}
					register={register}
					error={!!errors[name]}
					helperText={errors[name]?.message}
					inputSx={{ bgcolor: "background.paper" }}
				/>
			</StyledTableCell>
		</TableRow>
	);

	// Update storage based on field changes
	useEffect(() => {
		const subscription = watch((values) => onChange?.(values));

		return () => subscription.unsubscribe();
	}, [watch]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
			<Stack spacing={3}>
				{/* AUDITOR DETAILS */}
				<Stack spacing={1.5}>
					<Typography
						sx={{
							width: "fit-content",
							borderBottom: "3px solid",
							borderColor: "primary.main",
							fontWeight: 600,
							color: "text.primary"
						}}
					>
						AUDITOR DETAILS
					</Typography>

					{/* Info */}
					<Stack
						spacing={1.5}
						direction={{ xs: "column", md: "row" }}
						alignItems="center"
					>
						<Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
							Audit performed by
						</Typography>

						<FormTextField
							placeholder="Your Name"
							fullWidth
							disabled={disabled}
							required
							name="auditorName"
							register={register}
							error={!!errors["auditors"]}
							helperText={errors["auditors"]?.message}
							inputSx={{ bgcolor: "background.paper" }}
						/>

						<Typography>for</Typography>

						<FormTextField
							placeholder="Client name"
							fullWidth
							disabled={disabled}
							required
							name="clientName"
							register={register}
							error={!!errors["clientName"]}
							helperText={errors["clientName"]?.message}
							inputSx={{ bgcolor: "background.paper" }}
						/>

						<Typography>on</Typography>

						<FormTextField
							placeholder="Date"
							fullWidth
							disabled
							required
							name="date"
							register={register}
							error={!!errors["date"]}
							helperText={errors["date"]?.message}
							inputSx={{ bgcolor: "background.paper" }}
						/>
					</Stack>

					{/* Socials */}
					<Stack
						spacing={1.5}
						direction={{ xs: "column", md: "row" }}
						alignItems="center"
					>
						<Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
							Your socials
						</Typography>

						<FormTextField
							placeholder="Twitter Username"
							fullWidth
							disabled={disabled}
							required
							name="auditorTwitter"
							register={register}
							error={!!errors["auditorTwitter"]}
							helperText={errors["auditorTwitter"]?.message}
							inputSx={{ bgcolor: "background.paper" }}
						/>

						<FormTextField
							placeholder="GitHub Username"
							fullWidth
							disabled={disabled}
							required
							name="auditorGithub"
							register={register}
							error={!!errors["auditorGithub"]}
							helperText={errors["auditorGithub"]?.message}
							inputSx={{ bgcolor: "background.paper" }}
						/>

						<FormTextField
							placeholder="Telegram Username"
							fullWidth
							disabled={disabled}
							required
							name="auditorTelegram"
							register={register}
							error={!!errors["auditorTelegram"]}
							helperText={errors["auditorTelegram"]?.message}
							inputSx={{ bgcolor: "background.paper" }}
						/>

						<FormTextField
							placeholder="LinkedIn Username"
							fullWidth
							disabled={disabled}
							required
							name="auditorLinkedin"
							register={register}
							error={!!errors["auditorLinkedin"]}
							helperText={errors["auditorLinkedin"]?.message}
							inputSx={{ bgcolor: "background.paper" }}
						/>
					</Stack>
				</Stack>

				{/* AUDIT REPORT DETAILS */}
				<Stack spacing={1.5}>
					<Typography
						sx={{
							width: "fit-content",
							borderBottom: "3px solid",
							borderColor: "primary.main",
							fontWeight: 600,
							color: "text.primary"
						}}
					>
						AUDIT DETAILS
					</Typography>

					{/* Description */}
					<Stack spacing={0.5}>
						<Typography variant="body2">Overview and summary</Typography>
						<FormTextField
							placeholder="This is where you can add an overview of your audit for your client"
							fullWidth
							disabled={disabled}
							required
							name="description"
							register={register}
							error={!!errors["description"]}
							helperText={errors["description"]?.message}
							multiline
							rows={7}
							inputSx={{ bgcolor: "background.paper" }}
						/>
					</Stack>

					<Table padding="none">
						<TableBody>
							{Item({
								label: "Audit type:",
								name: "auditType",
								placeholder: "Audit type"
							})}

							{Item({
								label: "Audit timeline:",
								name: "auditTimeline",
								placeholder: "Audit timeline"
							})}

							{Item({
								label: "EVM:",
								name: "evm",
								placeholder: "EVM"
							})}

							{Item({
								label: "Languages:",
								name: "languages",
								placeholder: "Languages"
							})}

							{Item({
								label: "Documentation quality:",
								name: "documentationQuality",
								placeholder: "Documentation quality"
							})}

							{Item({
								label: "Test quality:",
								name: "testQuality",
								placeholder: "Test quality"
							})}

							{Item({
								label: "Commit hash:",
								name: "commitHash",
								placeholder: "Commit hash"
							})}
						</TableBody>
					</Table>
				</Stack>
			</Stack>
		</form>
	);
};

export default React.memo(ReportsForm);
