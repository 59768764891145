import {
	getSimulations,
	runSimulationAPI
} from "Resources/ServerInterfaceSimulation";
import { mixpanelAtom } from "atoms";
import { useTerminal } from "hooks/ui/useTerminal";
import { useAtom, useSetAtom } from "jotai";
import { openedTabAtom } from "store/atoms/UiAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import { simulatingAtom, transactionsAtom } from "store/atoms/simulationAtoms";
import { useBadges } from "./useBadges";
import { addAwardNotification } from "Resources/Notifications";
import { badgesAtom } from "store/atoms/badgesAtom";

export function useSimulation() {
	const setTransactions = useSetAtom(transactionsAtom);
	const [project] = useAtom(projectAtom);
	const [{ openedFile }] = useAtom(openedTabAtom);
	const [mixpanel] = useAtom(mixpanelAtom);
	const setSimulating = useSetAtom(simulatingAtom);
	const { loadBadges } = useBadges();
	const [badges] = useAtom(badgesAtom);

	const { openCustomTerminalTab } = useTerminal();

	const loadTransactions = async (projectId: string) => {
		try {
			const userTransactions = await getSimulations(projectId);
			setTransactions(userTransactions);
		} catch (error) {
			setTransactions([]);
		}
	};

	const runSimulation = async (data) => {
		try {
			setSimulating(true);

			const tenderlyBadge = badges.find((badge) => badge.name === "Simulation Badge");
			let issuedBadge = false;
			if (tenderlyBadge && tenderlyBadge.userOwns == false) {
				issuedBadge = true;
			}
			await runSimulationAPI(data, project.id, openedFile.id, mixpanel);
			if (issuedBadge) {
				addAwardNotification("Badge Received", "You've received the Simulation Badge! Click on the Badges dropdown to see more.", "https://i.ibb.co/T0b5C9r/new-tendelry-badge-png.png");
			}
			await loadBadges();

			setSimulating(false);
		} catch (error) {
			console.log(error);
		}
	};

	return { loadTransactions, runSimulation };
}
