import { atom } from "jotai";

export interface SlitherResultType {
	check?: string;
	confidence?: string;
	description?: string;
	elements?: any;
	first_markdown_element?: string;
	id?: string;
	impact?: string;
	markdown?: string;
	[key: string]: any;
}

export const slitherResultsAtom = atom<SlitherResultType[]>([]);
export const filteredSlitherResultsAtom = atom<SlitherResultType[]>([]);
export const filteredToolResultsAtom = atom<Array<object>>([]);