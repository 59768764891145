import { Box } from "@mui/material";
import { findNodeInAst } from "components/MainContentLayout/MonacoEditor/Addons/SolidityDefinitionProviderAddon/helpers";
import Graphviz from "graphviz-react";
import { useProject } from "hooks/data/useProject";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { JumpToLineAtom } from "store/atoms/EditorAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import { getCodeSnippet } from "utils/helpersFunctions";
import NodePopup from "./NodePopup";
import { findLineNumberFromText, getLineFromMultilineString } from "./helpers";
import { graphStyles } from "./styles";

const GraphvizContainer: React.FC<{ dot: string }> = React.memo(({ dot }) => {
	return (
		<Box
			className="Graphviz_container"
			component={Graphviz}
			options={{
				width: "100%",
				height: "100%",
				zoom: true,
				zoomScaleExtent: [0, 100]
			}}
			dot={dot}
			sx={(theme) => ({
				height: "100%",
				...graphStyles(theme)
			})}
		/>
	);
});

const GraphViewer: React.FC<{
	response: string;
	[_prop: string]: any;
}> = ({ response, ast, code, fileName, codeFilePath }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [popoverData, setPopoverData] = useState<{
		fileName: string;
		pathToFile: string;
		functionName: string;
		lineNumber: number;
		codeBlock?: string;
	} | null>(null);

	const { openProjectFile } = useProject();

	const [project] = useAtom(projectAtom);

	const setJumpToEditorLine = useSetAtom(JumpToLineAtom);

	const handleOpenLocation = () => {
		const _data = popoverData;

		setAnchorEl(null);
		setPopoverData(null);

		if (project.id && _data?.pathToFile) {
			openProjectFile(_data.pathToFile, () => {
				setTimeout(() => {
					setJumpToEditorLine({
						startLineNumber: _data.lineNumber,
						endLineNumber: _data.lineNumber
					});
				}, 500);
			});
		}
	};

	// Function to handle click on a node
	const handleNodeClick = (event) => {
		setAnchorEl(null);
		const target = event.target;
		let functionName = target.innerHTML;
		const parentNode = target.parentNode;

		if (parentNode?.id && parentNode.id.startsWith("node")) {
			if (!functionName) {
				functionName =
					parentNode.textContent?.trim?.()?.split("\n")?.slice(-1)?.[0] || "";
			}

			if (!functionName) return null;

			const left = event.clientX;
			const top = event.clientY;

			// Get the function content
			const astNode = findNodeInAst(ast, functionName, 99999, "", true);

			if (astNode && astNode?.loc?.start?.line) {
				const codeBlock = getCodeSnippet(
					code,
					astNode?.loc?.start?.line,
					astNode?.loc?.end?.line
				);

				// Set location
				setAnchorEl({ left, top, id: parentNode.id });
				setPopoverData({
					fileName,
					pathToFile: codeFilePath,
					lineNumber: astNode?.loc?.start?.line,
					functionName,
					codeBlock
				});
			} else {
				// Find line number of function
				let foundLineNumber = findLineNumberFromText(
					code,
					`function ${functionName}`
				);

				// If "function abc" not found then find "abc("
				if (foundLineNumber === -1) {
					foundLineNumber = findLineNumberFromText(code, `${functionName}(`);
				}

				if (foundLineNumber > -1) {
					const codeBlock = getLineFromMultilineString(
						code,
						foundLineNumber - 1
					);

					setAnchorEl({ left, top, id: parentNode.id });
					setPopoverData({
						fileName,
						pathToFile: codeFilePath,
						lineNumber: foundLineNumber,
						functionName,
						codeBlock
					});
				}
			}

			return;
		}
	};

	const handleClosePopover = () => {
		// Close the popover
		setAnchorEl(null);
	};

	return (
		<>
			<Box
				id="Graphviz_id"
				onClick={handleNodeClick}
				sx={{ p: 1, height: "100%", width: "100%" }}
			>
				<GraphvizContainer dot={response} />
			</Box>

			{!!anchorEl && (
				<NodePopup
					anchorEl={anchorEl}
					popoverData={popoverData}
					handleClosePopover={handleClosePopover}
					handleOpenLocation={handleOpenLocation}
				/>
			)}
		</>
	);
};

export default React.memo(GraphViewer);
