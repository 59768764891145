import { Stack, styled, useTheme } from "@mui/material";
import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import {
	terminalHeightAtom,
	terminalOpenAtom
} from "store/atoms/terminalAtoms";
import TerminalContent from "./TerminalContent";
import TerminalTabs from "./TerminalTabs";
import { useTerminal } from "hooks/ui/useTerminal";

const StyledResizable = styled(Resizable)(({ theme }) => ({
	position: "absolute",
	bottom: 0,

	"& .react-resizable-handle-n": {
		width: "100% !important",
		backgroundImage: "none",
		transform: "none",
		margin: "0px !important",
		padding: "0px !important",
		height: "1px",
		left: 0,
		right: 0,
		backgroundColor: theme.palette.background["paper2"],
		"&:hover": {
			backgroundColor: theme.palette.primary.main
		},
		"&::before": {
			content: '""',
			display: "block",
			height: "5px",
			marginTop: "-4px"
		}
	}
}));

const Terminal = () => {
	const theme = useTheme();

	const [terminalOpen, setTerminalOpen] = useAtom(terminalOpenAtom);
	const [height, setHeight] = useAtom(terminalHeightAtom);

	const { closeTerminal } = useTerminal();

	const onHeightResize = (event, { node, size, handle }) => {
		if (size.height <= 30) {
			closeTerminal();
			return setHeight(300);
		}

		setHeight(size.height);
	};

	useEffect(() => {
		if (terminalOpen) setHeight(600);
	}, [terminalOpen]);

	if (!terminalOpen) return null;

	return (
		<StyledResizable
			height={height}
			width="100%"
			onResize={onHeightResize}
			resizeHandles={["n"]}
			maxConstraints={[600, 600]}
		>
			<Stack
				sx={{
					height,
					width: "100%",
					bgcolor: "background.paper",
					position: "relative"
				}}
			>
				<TerminalTabs />

				<TerminalContent />
			</Stack>
		</StyledResizable>
	);
};

export default React.memo(Terminal);
