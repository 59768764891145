import { Stack, Typography, useTheme } from "@mui/material";
import { useAtom } from "jotai";
import { checklistLibraryAtom } from "store/atoms/checklistAtoms";
import LibraryGroup from "./LibraryGroup";

const ThisLibraryTab = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [checklistLibrary] = useAtom(checklistLibraryAtom);

	return (
		<Stack>
			<Stack spacing={2}>
				{!checklistLibrary?.length && (
					<Typography sx={{ textAlign: "center" }}>
						No library checklists available.
					</Typography>
				)}

				{checklistLibrary.map((group, i) => {
					return <LibraryGroup key={i} group={group} />;
				})}
			</Stack>
		</Stack>
	);
};

export default ThisLibraryTab;
