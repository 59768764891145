// Local storage
export const LOCALSTORAGE_KEY_CHAT = `AW-AI-CHAT`;
export const LOCALSTORAGE_REFERRAL = `AW-REFERRAL`;
export const STORAGE_FILE_LINES = "AW-FILES-SCROLL-POSITION";
export const STORAGE_SEARCH = "AW-SEARCHES-";
export const LOCALSTORAGE_KEY_ANALYZE = `AW-AI-ANALYZE`;
export const LOCALSTORAGE_REPORT_FORM = `AW-REPORT-FORM`;
export const STORAGE_OPEN_TABS = "AW-OPEN-TABS";
export const STORAGE_EXPANDED_PATHS = "AW-PROJECT-EXPANDED-PATHS";
export const STORAGE_FOCUSED_FILE = "AW-FOCUSED-FILE-INDEX";
export const LOCALSTORAGE_SCAN_RESULTS = "AW-SCAN-RESULTS";
export const STORAGE_HATS_SUBMISSION = "AW-HATS-SUBMISSION";
export const SURVEY_DONT_ASK = "AW-SURVEY-DONT-ASK";
export const LOCALSTORAGE_THEME_MODE = "AW-THEME-MODE";
export const LOCALSTORAGE_DRAWING_TOOL = "AW-DRAWING-TOOL";
export const LOCALSTORAGE_GITHUB_CONNECTION = "AW-GITHUB-CONNECTION";
export const LOCALSTORAGE_STORAGE_SLOTS = "AW-STORAGE-SLOTS";
export const LOCALSTORAGE_TEST_EDITOR = "AW-EDITOR-TEST-DRAFT";
export const LOCALSTORAGE_FIND_BUG_MODAL = "AW-FIND-BUG-MODAL";
export const LOCALSTORAGE_OPEN_CONTEST = "AW-OPEN-CONTEST";
export const STORAGE_STICKY_NOTE_OPEN = "AW-STICKY-NOTE-OPEN";
export const STORAGE_STICKY_NOTE_VALUE = "AW-STICKY-NOTE-VALUE";
export const LOCALSTORAGE_CACHE_VERSION = "AW-CACHE-VERSION";

// IndexedDB
export const IDB_TEST_RESULTS = "AW-TEST-RESULTS";
