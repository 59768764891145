import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	IconButton,
	Stack,
	Tooltip,
	Typography
} from "@mui/material";
import LibraryItem from "./LibraryItem";
import React, { useState } from "react";
import { ChecklistGroupType } from "store/atoms/checklistAtoms";
import { Icon } from "@iconify/react";
import { useChecklist } from "hooks/data/useChecklist";

const LibraryGroup: React.FC<{ group: ChecklistGroupType }> = ({ group }) => {
	const [expanded, setExpanded] = useState(true);

	const { removeGroupFromLibrary, addGroupFromLibrary } = useChecklist();

	return (
		<Accordion
			expanded={expanded}
			onChange={(e, v) => setExpanded(v)}
			disableGutters
			sx={{
				mt: "16px !important",
				width: "100%",
				borderRadius: "8px !important",
				backgroundImage: "none",
				bgcolor: "background.default",
				"&.MuiAccordion-root:before": {
					bgcolor: "transparent"
				},
				"&.Mui-expanded": { mt: 0 }
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 1.5 }}>
				<Stack
					direction="row"
					alignItems="center"
					spacing={0.5}
					sx={{ flex: 1, pr: 0.5 }}
				>
					<Typography sx={{ flex: 1 }}>{group.groupName}</Typography>

					<Tooltip title="Add checklist group to project">
						<IconButton
							size="small"
							onClick={(e) => {
								e.stopPropagation();
								addGroupFromLibrary(group);
							}}
							sx={{
								width: 20,
								height: 20,
								p: 0
							}}
						>
							<Icon icon="ci:list-add" fontSize={20} />
						</IconButton>
					</Tooltip>

					<Stack direction="row" alignItems="center">
						<Tooltip title="Remove from library">
							<IconButton
								size="small"
								onClick={(e) => {
									e.stopPropagation();
									removeGroupFromLibrary(group._id);
								}}
								sx={{
									width: 22,
									height: 24,
									p: 0
								}}
							>
								<Icon icon="mdi:content-save-minus" fontSize={17} />
							</IconButton>
						</Tooltip>
					</Stack>
				</Stack>
			</AccordionSummary>

			<AccordionDetails sx={{ px: 1.5, pt: 0 }}>
				<Stack spacing={1}>
					{group?.checklists?.map?.((checklist, j) => (
						<LibraryItem key={j} checklist={checklist} />
					))}
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};

export default React.memo(LibraryGroup);
