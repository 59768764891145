import { usernameAtom } from "atoms";
import DiscordRedirect from "components/DiscordRedirect";
import GithubRedirect from "components/GithubRedirect";
import GoogleRedirect from "components/GoogleReferralRedirect";
import OpenChallengeRedirect from "components/OpenChallengeRedirect/OpenChallengeRedirect";
import TwitterRedirect from "components/TwitterReferralRedirect";
import AppLayout from "components/layouts/AppLayout";
import AuditToolLayout from "components/layouts/AuditToolLayout";
import ImportLayout from "components/layouts/ImportLayout";
import SettingsLayout from "components/layouts/SettingsLayout";
import { useAtom } from "jotai";
import { Navigate, Route, Routes } from "react-router-dom";

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
	children
}) => {
	const [username] = useAtom(usernameAtom);

	if (!username) {
		return <Navigate to="/" />;
	}

	return <>{children}</>;
};

const AppRoutes = () => {
	return (
		<>
			<Routes>
				<Route path="/invite/:id" element={<DiscordRedirect />} />
				<Route path="/twitter/:id" element={<TwitterRedirect />} />
				<Route path="/google/:id" element={<GoogleRedirect />} />
				<Route path="/github" element={<GithubRedirect />} />
				<Route path="/challenge" element={<OpenChallengeRedirect />} />

				<Route path="/" element={<AppLayout />}>
					<Route index element={<ImportLayout />} />

					<Route
						path="/project/:projectId"
						element={
							<ProtectedRoute>
								<AuditToolLayout />
							</ProtectedRoute>
						}
					/>

					<Route
						path="/settings"
						element={
							<ProtectedRoute>
								<SettingsLayout />
							</ProtectedRoute>
						}
					/>

					{/* Not found route */}
					<Route path="/*" element={<Navigate to="/" />} />
				</Route>
			</Routes>
		</>
	);
};

export default AppRoutes;
