import { Icon } from "@iconify/react";
import {
	Box,
	Grow,
	IconButton,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import { ToolboxCloseButton } from "components/Toolbox";
import { useTests } from "hooks/data/useTests";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { testFilesAtom, testTabToggleValueAtom } from "store/atoms/testAtoms";
import SettingsBox from "./SettingsBox";
import TestBuilderTab from "./TestBuilderTab";
import TestTab from "./TestTab";
import CollectionFormTool from "./TestTemplateForm/CollectionForm";
import TemplateFormTool from "./TestTemplateForm/TemplateForm";

type Modes = "testing" | "collectionForm" | "templateForm";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
	borderRadius: "8px",
	...(theme.palette.mode === "light" && {
		border: 0,
		color: `${theme.palette.text.primary}!important`
	}),
	"&.Mui-selected, &.Mui-selected:hover": {
		backgroundColor: theme.palette.background["selected"],
		...(theme.palette.mode === "light" && {
			color: `${theme.palette.text.primary}!important`
		})
	}
}));

const TestTool: React.FC<{ mode?: Modes }> = ({
	mode: propsMode = "testing"
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [toggleValue, setToggleValue] = useAtom(testTabToggleValueAtom);
	const [testFiles] = useAtom(testFilesAtom);
	// const [collections] = useAtom(testCollectionsAtom);

	const [mode, setMode] = useState<Modes>(propsMode);
	const [settingsOpen, setSettingsOpen] = useState(false);

	const { loadTests } = useTests();

	useEffect(() => {
		setMode(propsMode);
	}, [propsMode]);

	useEffect(() => {
		loadTests();
	}, []);

	const Header = ({ settingsOpen, setSettingsOpen }) => {
		const theme = useTheme();

		return (
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ mb: 2 }}
			>
				<Typography
					variant="h6"
					sx={{
						"& span": {
							fontWeight: 700
						}
					}}
				>
					Testing
				</Typography>

				<Box sx={{ flex: 1 }} />

				<Tooltip
					title="Open test settings"
					enterDelay={500}
					enterNextDelay={500}
				>
					<IconButton
						onClick={() => setSettingsOpen((prev) => !prev)}
						sx={{ p: 0, height: 26, width: 26 }}
					>
						<Icon
							icon="ic:sharp-settings"
							fontSize={20}
							color={
								settingsOpen
									? theme.palette.primary.main
									: theme.palette.text.primary
							}
						/>
					</IconButton>
				</Tooltip>

				<ToolboxCloseButton />
			</Stack>
		);
	};

	if (mode === "collectionForm")
		return (
			<CollectionFormTool
				backToTool={() => {
					setMode("testing");
					setToggleValue(1);
				}}
			/>
		);

	if (mode === "templateForm")
		return (
			<TemplateFormTool
				backToTool={() => {
					setMode("testing");
					setToggleValue(1);
				}}
			/>
		);

	return (
		<Stack sx={{ height: "100%", px: themeMode === "light" && 0.5 }}>
			<Header settingsOpen={settingsOpen} setSettingsOpen={setSettingsOpen} />

			<Grow in={!!settingsOpen} timeout={300}>
				<Box
					sx={{
						zIndex: 999,
						position: "absolute",
						top: 30,
						right: 0,
						left: 0,
						width: "100%"
					}}
				>
					{settingsOpen && (
						<SettingsBox onClose={() => setSettingsOpen(false)} />
					)}
				</Box>
			</Grow>

			<ToggleButtonGroup
				size="small"
				color="primary"
				value={toggleValue}
				exclusive
				onChange={(e, v) => {
					if (v !== null) setToggleValue(v);
				}}
				fullWidth
				sx={{
					mb: 2,
					height: 40,
					borderRadius: "8px",
					bgcolor: "background.default",
					...(themeMode === "light" && {
						bgcolor: "background.paper",
						boxShadow: 1
					})
				}}
			>
				<StyledToggleButton value={0}>Test Builder</StyledToggleButton>

				<StyledToggleButton value={1}>
					<span style={{ fontWeight: 700, marginRight: "3px" }}>
						{testFiles?.length || 0}
					</span>
					Tests
				</StyledToggleButton>

				{/* <StyledToggleButton value={2}>
					<span style={{ fontWeight: 700, marginRight: "3px" }}>
						{templatesCount(collections)}
					</span>
					Templates
				</StyledToggleButton> */}
			</ToggleButtonGroup>

			{toggleValue === 0 && <TestBuilderTab />}

			{toggleValue === 1 && <TestTab />}

			{/* {toggleValue === 2 && <TemplatesTab setMode={setMode} />} */}
		</Stack>
	);
};

export default React.memo(TestTool);
