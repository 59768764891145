import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import { useProject } from "hooks/data/useProject";
import React, { useState } from "react";
import CustomModal from ".";

interface PersonalAccessTokenModalProps {
	open: boolean;
	onClose: () => void;
	projectId: string;
}

const DeleteProjectModal: React.FC<PersonalAccessTokenModalProps> = ({
	open,
	onClose,
	projectId
}) => {
	const [loading, setLoading] = useState(false);
	const { deleteProject } = useProject();

	const handleDeleteProject = async () => {
		setLoading(true);
		await deleteProject(projectId);

		setLoading(false);
		handleClose();
	};

	const handleClose = () => {
		if (!loading) {
			onClose();
		}
	};

	return (
		<CustomModal
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="md"
			paperSx={{ maxWidth: "500px", py: 3, textAlign: "center" }}
		>
			<Typography variant="h4" sx={{ textAlign: "center", mb: 2 }}>
				Are you sure?
			</Typography>

			<Typography sx={{ mb: 4 }}>
				Deleting your project means that you will lose all associated data
				including project findings and notes.
			</Typography>

			<LoadingButton
				loading={loading}
				variant="contained"
				fullWidth
				onClick={handleDeleteProject}
				sx={{ borderRadius: "8px", mb: 1 }}
			>
				{loading ? "Deleting..." : "Delete Project"}
			</LoadingButton>

			<Button onClick={handleClose} fullWidth disabled={loading}>
				Cancel
			</Button>
		</CustomModal>
	);
};

export default DeleteProjectModal;
