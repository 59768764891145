import { Box, IconButton, Stack, styled } from "@mui/material";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import {
	terminalFocusedTab,
	terminalTabsAtom
} from "store/atoms/terminalAtoms";
import ActionButtons from "./ActionButtons";
import TabsList from "./TabsList";

export const StyledIconbutton = styled(IconButton)({
	padding: 0,
	height: 20,
	width: 20,
	borderRadius: "5px"
});

const TerminalTabs = () => {
	const setTerminalTabs = useSetAtom(terminalTabsAtom);
	const [focusedTabIndex, setTabIndex] = useAtom(terminalFocusedTab);

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		setTerminalTabs((old) => {
			const reorderedTabs = Array.from(old);
			const [removed] = reorderedTabs.splice(result.source.index, 1);
			reorderedTabs.splice(result.destination.index, 0, removed);

			if (result.source.index === focusedTabIndex) {
				setTabIndex(result.destination.index);
			}

			if (result.destination.index === focusedTabIndex) {
				setTabIndex(result.source.index);
			}

			return reorderedTabs;
		});
	};

	return (
		<Stack
			direction="row"
			alignItems="center"
			sx={{
				minHeight: 34,
				borderBottom: "1px solid",
				borderTopColor: "background.paper2",
				borderBottomColor: "background.paper2",
				bgcolor: "background.nav"
			}}
		>
			{/* Tabs */}
			<DragDropContext onDragEnd={onDragEnd}>
				<TabsList />
			</DragDropContext>

			<Box sx={{ flex: 1 }} />

			{/* Action buttons */}
			<ActionButtons />
		</Stack>
	);
};

export default React.memo(TerminalTabs);
