import { Box, Stack, useTheme } from "@mui/material";
import ProjectFile from "Resources/ProjectFile";
import { getOpenedTabType } from "hooks/ui/useEditorTabs";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
	editorHomeModeAtom,
	editorTabsAtom,
	focusedEditorTabIndexAtom,
	openedTabAtom
} from "store/atoms/UiAtoms";
import { testFilesAtom } from "store/atoms/testAtoms";
import ExcaliDrawTool from "./ExcalidrawTool";
import GraphViewer from "./GraphViewer";
import MarkdownReader from "./MarkdownReader";
import MonacoEditor from "./MonacoEditor";
import PdfReader from "./PdfReader";
import ProjectHome from "./ProjectHome";
import ReportTool from "./ReportTool";
import Terminal from "./Terminal";
import TestCustomEditor from "./TestCustomEditor";
import TestEditor from "./TestEditor";
import TestTrace from "./TestTrace";
import FileTabs from "./components/FileTabs";
import SearchAll from "./components/SearchAll";
import SearchFiles from "./components/SearchFiles";
import FunctionExplorer from "./FunctionExplorer";

const handleKeyDown = (
	event: KeyboardEvent,
	setFocusedTabIndex,
	openTabsLength
) => {
	if (
		event.shiftKey &&
		event.altKey &&
		(event.key === "ArrowLeft" || event.key === "ArrowRight")
	) {
		if (event.key === "ArrowLeft") {
			setFocusedTabIndex((old) => (old < 1 ? 0 : old - 1));
		} else {
			setFocusedTabIndex((old) =>
				old >= openTabsLength - 1 ? openTabsLength - 1 : old + 1
			);
		}
	}
};

const MainContentLayout = () => {
	const theme = useTheme();

	const [_key, setKey] = useState(0);
	const [testFiles] = useAtom(testFilesAtom);
	const [homeMode] = useAtom(editorHomeModeAtom);

	const [{ openedTab, openedFile, projectFilePath }] = useAtom(openedTabAtom);

	const [openTabs] = useAtom(editorTabsAtom);
	const setFocusedTabIndex = useSetAtom(focusedEditorTabIndexAtom);

	const focusContents = openedTab?.contents || null;

	const openedTabType = getOpenedTabType(
		openedTab?.name,
		openedTab?.path,
		!!testFiles?.find((i) => i.originFile === openedFile?.id)
	);

	useEffect(() => {
		setKey((old) => old + 1);
	}, [projectFilePath]);

	// Shift + Alt + Arrow listener
	useEffect(() => {
		const wrapperFunction = (e) => {
			handleKeyDown(e, setFocusedTabIndex, openTabs.length);
		};

		document.addEventListener("keydown", wrapperFunction);

		return () => {
			document.removeEventListener("keydown", wrapperFunction);
		};
	}, [openTabs?.length, setFocusedTabIndex]);

	const GetComponent = () => {
		// Home page
		if (homeMode) {
			return <ProjectHome />;
		}

		// Markdown
		if (openedTabType === "markdown") {
			return (
				<MarkdownReader
					value={(focusContents as ProjectFile)?.text ?? ""}
					containerSx={{ mx: { xs: 3, lg: 5 }, my: 3 }}
				/>
			);
		}

		// PDF
		if (openedTabType === "pdf") {
			return <PdfReader file={(focusContents as ProjectFile)?.text ?? ""} />;
		}

		// Graph
		else if (openedTabType === "graph") {
			return (
				<span key={_key}>
					<GraphViewer
						response={openedFile?.text}
						ast={openedFile?.ast}
						code={openedFile?.metadata?.code}
						fileName={openedFile?.name}
						codeFilePath={openedFile?.metadata?.codeFilePath}
					/>
				</span>
			);
		}

		// Missing Imports
		else if (openedTabType === "import") {
			return <>{focusContents}</>;
		}

		// Test
		else if (openedTabType === "test") {
			return (
				<TestEditor
					value={(focusContents as ProjectFile)?.text ?? ""}
					test={testFiles?.find?.((i) => i.originFile === openedFile?.id)}
					key={_key}
				/>
			);
		}

		// Custom Test
		else if (openedTabType === "customTest") {
			return <TestCustomEditor key={_key} {...(openedFile?.metadata || {})} />;
		}

		// Test Trace
		else if (openedTabType === "testTrace") {
			return <TestTrace key={_key} {...(openedFile?.metadata || {})} />;
		}

		// Function View
		else if (openedTabType === "functionExplorer") {
			return <FunctionExplorer key={_key} {...(openedFile?.metadata || {})} />;
		}

		// Monaco Editor
		else if (openedTabType === "editor") {
			return (
				<MonacoEditor
					value={(focusContents as ProjectFile)?.text ?? ""}
					key={_key} // Temporary hack to rerender monaco component
				/>
			);
		}

		// Whiteboard
		else if (openedTabType === "whiteboard") {
			return <ExcaliDrawTool key={_key} _id={openedFile?.metadata?._id} />;
		}

		// Whiteboard
		else if (openedTabType === "report") {
			return <ReportTool key={_key} />;
		}

		return null;
	};

	return (
		<Stack
			sx={{
				position: "relative",
				height: "100%",
				borderRadius: "10px",
				bgcolor: theme.palette.background["nav"]
			}}
		>
			<FileTabs />

			<SearchAll />
			<SearchFiles />

			{/* Main Content */}

			<Stack
				sx={{
					flexGrow: 1,
					borderBottomLeftRadius: "inherit",
					borderBottomRightRadius: "inherit",
					bgcolor: theme.palette.background.paper,
					// py: 2,
					overflowX: "auto"
				}}
			>
				{GetComponent()}
			</Stack>

			<Terminal />
		</Stack>
	);
};

export default React.memo(MainContentLayout);
