import { useTheme } from "@mui/material";
import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { callableValuesAtom } from "store/atoms/CallableValueAtoms";
import { editorClickListenerAtom } from "store/atoms/EditorAtoms";
import {
	callableValueClickOpenWidget,
	parseCallableValues,
	removeCallableValueGlyphsAndListeners
} from "./helpers";

const CallableValueAddon: React.FC<{ editorRef: any; monacoRef: any }> = ({
	editorRef,
	monacoRef
}) => {
	const theme = useTheme();

	const [callableValues] = useAtom(callableValuesAtom);
	const [clickEvent] = useAtom(editorClickListenerAtom);
	const [parsedCallableValues, setParsedCallableValues] = useState([]);

	const widgetRef = useRef(null);

	// Load scan results into Editor
	useEffect(() => {
		removeCallableValueGlyphsAndListeners(editorRef, widgetRef);

		if (callableValues) {
			const parsedValues = parseCallableValues(callableValues, theme);

			setParsedCallableValues(parsedValues);
			editorRef.createDecorationsCollection?.(parsedValues);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editorRef, callableValues]);

	// Scan glyph widget click
	useEffect(() => {
		if (!!parsedCallableValues.length && editorRef) {
			removeCallableValueGlyphsAndListeners(editorRef, widgetRef);

			if (!!clickEvent) {
				if (clickEvent.clickType === 2 && clickEvent.column === 1) {
					callableValueClickOpenWidget(
						editorRef,
						monacoRef,
						clickEvent,
						widgetRef,
						parsedCallableValues
					);
				}
			}

			editorRef?.createDecorationsCollection?.(parsedCallableValues);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parsedCallableValues, editorRef, clickEvent]);

	return null;
};

export default React.memo(CallableValueAddon);
