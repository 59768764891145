import { Icon } from "@iconify/react";
import {
	Box,
	Drawer,
	IconButton,
	Stack,
	Tooltip,
	useMediaQuery,
	useTheme
} from "@mui/material";
import addFindingFormBgDark from "assets/images/backgrounds/addFindingFormBgDark.png";
import addFindingFormBgLight from "assets/images/backgrounds/addFindingFormBgLight.png";
import aiBgDark from "assets/images/backgrounds/aiBgDark.png";
import aiBgLight from "assets/images/backgrounds/aiBgLight.png";
import bugChallengeBgDark from "assets/images/backgrounds/bugChallengeBgDark.png";
import bugChallengeBgLight from "assets/images/backgrounds/bugChallengeBgLight.png";
import checklistBgDark from "assets/images/backgrounds/checklistBgDark.png";
import checklistBgLight from "assets/images/backgrounds/checklistBgLight.png";
import codeScanBgDark from "assets/images/backgrounds/codeScanBgDark.png";
import codeScanBgLight from "assets/images/backgrounds/codeScanBgLight.png";
import findingsBgDark from "assets/images/backgrounds/findingsBgDark.png";
import findingsBgLight from "assets/images/backgrounds/findingsBgLight.png";
import graphToolBgDark from "assets/images/backgrounds/graphToolBgDark.png";
import graphToolBgLight from "assets/images/backgrounds/graphToolBgLight.png";
import inspectBgDark from "assets/images/backgrounds/inspectBgDark.png";
import inspectBgLight from "assets/images/backgrounds/inspectBgLight.png";
import notesBgDark from "assets/images/backgrounds/notesBgDark.png";
import simulationBgLight from "assets/images/backgrounds/notesBgLight.png";
import simulationBgDark from "assets/images/backgrounds/simulationBgDark.png";
import notesBgLight from "assets/images/backgrounds/simulationBgLight.png";
import testBgDark from "assets/images/backgrounds/testBgDark.png";
import testBgLight from "assets/images/backgrounds/testBgLight.png";
import whiteboardBgDark from "assets/images/backgrounds/whiteboardBgDark.png";
import whiteboardBgLight from "assets/images/backgrounds/whiteboardBgLight.png";
import { NAVBAR_HEIGHT } from "components/Navbar";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React from "react";
import { selectedToolAtom, selectedToolPropsAtom } from "store/atoms/UiAtoms";
import hex from "utils/hexTransparency";
import AITool from "./Tools/AITool";
import AddFindingsTool from "./Tools/AddFindingsTool";
import BugChallengeTool from "./Tools/BugChallengeTool";
import CFGTool from "./Tools/CFG";
import ChecklistTool from "./Tools/ChecklistTool";
import CodeScanTool from "./Tools/CodeScanTool";
import FindingsTool from "./Tools/FindingsTool";
import GraphTool from "./Tools/GraphTool";
import InspectTool from "./Tools/InspectTool";
import NotesTool from "./Tools/NotesTool";
import SimulationTool from "./Tools/SimulationTool";
import TestTool from "./Tools/TestTool";
import WhiteboardTool from "./Tools/WhiteboardTool";

export const TOOLBOX_WIDTH = 400;
export const TOOLBOX_WIDTH_XXS = 325;
export const TOOLBOX_WIDTH_SMALL = 355;

export const ToolboxCloseButton = () => {
	const setSelectedTool = useSetAtom(selectedToolAtom);

	const closeTool = () => {
		setSelectedTool(null);
	};

	return (
		<Tooltip title="Close Toolbox" enterDelay={1000} enterNextDelay={1000}>
			<IconButton
				size="small"
				color="inherit"
				onClick={() => closeTool()}
				sx={{ p: 0, height: 26, width: 26 }}
			>
				<Icon icon="ion:close" fontSize={26} />
			</IconButton>
		</Tooltip>
	);
};

interface CustomModalProps {
	name?: string;
	background?: string;
}

const CustomModal: React.FC<CustomModalProps> = ({ background, children }) => {
	const theme = useTheme();
	const [selectedTool, setSelectedTool] = useAtom(selectedToolAtom);
	const isLargeScreen = useMediaQuery("(min-width:1200px)");
	const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const isExtraSmallScreen = useMediaQuery("(max-width:370px)");

	const open = !!selectedTool;

	const defaultWidth = isExtraSmallScreen
		? TOOLBOX_WIDTH_XXS
		: isMobileScreen
		? TOOLBOX_WIDTH_SMALL
		: TOOLBOX_WIDTH;

	return (
		<Drawer
			open={open}
			onClose={() => setSelectedTool(null)}
			variant={isLargeScreen ? "persistent" : "temporary"}
			anchor="right"
			sx={{
				width: open ? defaultWidth : 0,
				height: "inherit",
				position: "relative",
				transition: theme.transitions.create("margin", {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen
				}),
				...(open && {
					transition: theme.transitions.create("margin", {
						easing: theme.transitions.easing.easeOut,
						duration: theme.transitions.duration.enteringScreen
					})
				}),
				"& .MuiBackdrop-root": {
					backgroundColor: `#36325E${hex["60%"]}`,
					backdropFilter: "blur(1.5px)"
				},
				"& .MuiDrawer-paper": {
					backgroundImage: "none",
					mt: `calc(${NAVBAR_HEIGHT}px + ${theme.spacing(1.5)})`,
					pb: `calc(${NAVBAR_HEIGHT}px + ${theme.spacing(3)})`,
					bgcolor: "transparent",
					borderTopLeftRadius: "10px",
					borderBottomLeftRadius: "10px",
					borderLeft: "none",
					[theme.breakpoints.up("lg")]: {
						mt: 0,
						pb: 0,
						position: "absolute",
						bgcolor: "background.paper"
					}
				}
			}}
		>
			<Stack
				sx={{
					width: defaultWidth,
					height: "100%",
					...(background && {
						background: `url(${background})`,
						backgroundSize: "cover",
						backgroundPosition: "center"
					}),
					bgcolor: "background.paper",
					p: 2,
					borderRadius: "5px",
					borderBottomRightRadius: "10px",
					borderBottomLeftRadius: "10px"
				}}
			>
				<Stack sx={{ flex: 1, overflow: "auto", position: "relative" }}>
					{children}
				</Stack>
			</Stack>
		</Drawer>
	);
};

const Toolbox = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [selectedTool] = useAtom(selectedToolAtom);
	const [selectedToolProps] = useAtom(selectedToolPropsAtom);

	const backgrounds = {
		AIScan: themeMode === "dark" ? aiBgDark : aiBgLight,
		Graph: themeMode === "dark" ? graphToolBgDark : graphToolBgLight,
		CodeScan: themeMode === "dark" ? codeScanBgDark : codeScanBgLight,
		Notepad: themeMode === "dark" ? notesBgDark : notesBgLight,
		Inspect: themeMode === "dark" ? inspectBgDark : inspectBgLight,
		Findings: themeMode === "dark" ? findingsBgDark : findingsBgLight,
		AddFinding:
			themeMode === "dark" ? addFindingFormBgDark : addFindingFormBgLight,
		Whiteboard: themeMode === "dark" ? whiteboardBgDark : whiteboardBgLight,
		Tests: themeMode === "dark" ? testBgDark : testBgLight,
		Checklist: themeMode === "dark" ? checklistBgDark : checklistBgLight,
		Simulation: themeMode === "dark" ? simulationBgDark : simulationBgLight,
		BugChallenge:
			themeMode === "dark" ? bugChallengeBgDark : bugChallengeBgLight
	};

	return (
		<>
			<CustomModal background={backgrounds[selectedTool]}>
				<Box hidden={selectedTool !== "CodeScan"} sx={{ height: "100%" }}>
					<CodeScanTool {...selectedToolProps} />
				</Box>

				<Box hidden={selectedTool !== "Graph"} sx={{ height: "100%" }}>
					<GraphTool {...selectedToolProps} />
				</Box>

				<Box hidden={selectedTool !== "AIScan"} sx={{ height: "100%" }}>
					<AITool {...selectedToolProps} />
				</Box>

				<Box hidden={selectedTool !== "Notepad"} sx={{ height: "100%" }}>
					<NotesTool {...selectedToolProps} />
				</Box>

				<Box hidden={selectedTool !== "Inspect"} sx={{ height: "100%" }}>
					<InspectTool {...selectedToolProps} />
				</Box>

				<Box hidden={selectedTool !== "Tests"} sx={{ height: "100%" }}>
					<TestTool {...selectedToolProps} />
				</Box>

				<Stack
					hidden={selectedTool !== "Whiteboard"}
					sx={{
						height: "100%",
						...(selectedTool !== "Whiteboard" && { display: "none" })
					}}
				>
					<WhiteboardTool {...selectedToolProps} />
				</Stack>

				<Stack
					hidden={selectedTool !== "Findings"}
					sx={{
						height: "100%",
						...(selectedTool !== "Findings" && { display: "none" })
					}}
				>
					<FindingsTool {...selectedToolProps} />
				</Stack>

				<Box hidden={selectedTool !== "BugChallenge"} sx={{ height: "100%" }}>
					<BugChallengeTool {...selectedToolProps} />
				</Box>

				<Box hidden={selectedTool !== "AddFinding"} sx={{ height: "100%" }}>
					<AddFindingsTool {...selectedToolProps} />
				</Box>

				<Box hidden={selectedTool !== "Checklist"} sx={{ height: "100%" }}>
					<ChecklistTool {...selectedToolProps} />
				</Box>

				<Box hidden={selectedTool !== "Simulation"} sx={{ height: "100%" }}>
					<SimulationTool {...selectedToolProps} />
				</Box>

				<Box hidden={selectedTool !== "CFG"} sx={{ height: "100%" }}>
					<CFGTool {...selectedToolProps} />
				</Box>
			</CustomModal>
		</>
	);
};

export default React.memo(Toolbox);
