import ProjectFile from "./ProjectFile";

function sortByName(a, b) {
	return a.name.localeCompare(b.name);
}

export default class ProjectFolder {
	readonly name: string;
	files: ProjectFile[];
	readonly folders: ProjectFolder[];
	hasMissingImports: boolean;

	constructor(name: string, files: ProjectFile[], folders: ProjectFolder[]) {
		this.name = name;
		this.files = files.sort(sortByName);
		this.folders = folders.sort(sortByName);
		this.hasMissingImports = false;
	}

	getFilesAndFolders(): (ProjectFolder | ProjectFile)[] {
		return (this.folders as (ProjectFolder | ProjectFile)[]).concat(this.files);
	}

	static deserialize(parsedFolder): ProjectFolder {
		let files: ProjectFile[] = [];
		let folders: ProjectFolder[] = [];

		for (const file of parsedFolder.files) {
			//if a file has files, deserialize it as a folder
			if ("files" in file) folders.push(ProjectFolder.deserialize(file));
			else files.push(new ProjectFile(file.name, file.text, file.id, file.ast));
		}

		const folder = new ProjectFolder(parsedFolder.name, files, folders);
		const includedFiles: string[] = [];
		const folderFilesNoDups = folder.files.filter((file) => {
			if (!includedFiles.includes(file.name)) {
				includedFiles.push(file.name);
				return true;
			}
			return false;
		});
		folder.files = folderFilesNoDups;
		return folder;
	}
}
