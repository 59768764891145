import { recordTwitterReferral } from "Resources/ServerInterface";
import SplashScreen from "components/SplashScreen";
import { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

const TwitterRedirect = () => {
  const params = useParams();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    recordTwitterReferral(params.id).then(() => {
      console.log("Referral logged");
      setRedirect(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (redirect) {
    return <Navigate to="/" />;
  }

  return <SplashScreen />;
};

export default TwitterRedirect;