import { useAtom, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { CFGAtom } from "store/atoms/CFGAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import { ControlFlowGraph } from "../../Resources/SolidityParser";

export function useCFG() {
	const setCFGData = useSetAtom(CFGAtom);

	const CFGData = useAtomCallback(
		useCallback((get) => {
			const curr = get(projectAtom);
			return curr;
		}, [projectAtom])
	);

	// console.log(CFGData());

	const testing = () => {
		try {
			const testData = CFGData();
			setCFGData((old) => {
				return new ControlFlowGraph(testData);
			});
		} catch (error) {}
	};

	return { testing };
}
