import { Monaco } from "@monaco-editor/react";
import { parseClickablePaths } from "components/ClickablePathText";
import { getSlitherSeverityValue } from "components/Toolbox/Tools/CodeScanTool/helpers";
import { editor } from "monaco-editor";
import { SeverityColor } from "./styles";
import { Theme } from "@mui/material";
import ReactDOM from "react-dom";
import ScanWidget from "./ScanWidget";

export const parseScanResults = (scanResults, theme: Theme) => {
	const options = (severity) => {
		return {
			isWholeLine: true,
			className: `editor-scan-${severity}`,
			shouldFillLineOnLineBreak: true,
			minimap: {
				color: SeverityColor(theme, severity),
				darkColor: SeverityColor(theme, severity),
				position: 1
			},
			overviewRuler: {
				position: 7,
				color: SeverityColor(theme, severity),
				darkColor: SeverityColor(theme, severity)
			}
		};
	};

	const parsedScanResults = scanResults
		.flatMap((item) => {
			const description = item.locations || item.description || item.entryDescription;
			const severity = item.severity || item.impact || "Other";

			if (description) {
				const parsed = parseClickablePaths(description)
					.filter((i) => typeof i === "object")
					.flatMap((i) => [
						// Glyph
						{
							options: {
								glyphMarginClassName: `editor-scan-glyph glyph-scan-${item.severity}`
								// className: `editor-scan-glyph-${item.severity}`
							},
							range: {
								startLineNumber: i.startLineNumber,
								endLineNumber: i.startLineNumber,
								startColumn: 0,
								endColumn: 100
							},
							data: {
								...item,
								impact: severity,
								severity: getSlitherSeverityValue(severity)
							}
						},

						// Bg + minimap
						{
							range: i,
							options: options(severity),
							data: {
								...item,
								impact: severity,
								severity: getSlitherSeverityValue(severity)
							},
							type: "scan-result"
						}
					]);

				return parsed;
			}
			return null;
		})
		.filter(Boolean);
	return parsedScanResults;
};

export const removeScanGlyphsAndListeners = (
	editorRef: editor.IStandaloneCodeEditor,
	widgetRef
) => {
	if (widgetRef.current) {
		editorRef?.removeContentWidget?.(widgetRef.current);
		widgetRef.current = null;
	}

	const model = editorRef?.getModel?.();
	const allDecorations = model?.getAllDecorations?.();

	const allClassNamesToRemove = [
		"editor-scan-High",
		"editor-scan-Medium",
		"editor-scan-Low",
		"editor-scan-Other",

		"editor-scan-glyph glyph-scan-High",
		"editor-scan-glyph glyph-scan-Medium",
		"editor-scan-glyph glyph-scan-Low",
		"editor-scan-glyph glyph-scan-Other"
	];

	if (allDecorations) {
		const scanIds = allDecorations
			.filter(
				(i) =>
					allClassNamesToRemove.includes(i.options.className) ||
					allClassNamesToRemove.includes(i.options.glyphMarginClassName)
			)
			.map((i) => i.id);

		editorRef.removeDecorations(scanIds);
	}
};

export const scanClickOpenWidget = (
	editorRef: editor.IStandaloneCodeEditor,
	monacoRef: Monaco,
	position: any,
	widgetRef: any,
	scans: any
) => {
	const foundScans =
		position &&
		scans.filter(
			(i) =>
				position.lineNumber >= i.range.startLineNumber &&
				position.lineNumber <= i.range.endLineNumber &&
				!i.options.glyphMarginClassName
		);

	if (!!foundScans?.length) {
		const removeWidget = () => {
			if (widgetRef.current) {
				editorRef.removeContentWidget(widgetRef.current);
				widgetRef.current = null;
			} else {
				editorRef.removeContentWidget({
					getId: () => "editor-scan-widget",
					getDomNode: () => {
						const node = document.createElement("div");
						return node;
					},
					getPosition: () => ({
						position: {
							lineNumber: position?.lineNumber,
							column: 1
						},
						preference: [monacoRef.editor.ContentWidgetPositionPreference.BELOW]
					})
				});
			}
		};

		removeWidget();

		const widget = {
			getId: () => "editor-scan-widget",

			getDomNode: () => {
				const node = document.createElement("div");

				node.onwheel = function (e) {
					e.stopPropagation();
				};

				ReactDOM.render(
					ScanWidget({
						scans: foundScans,
						handleClose: removeWidget
					}),
					node
				);

				return node;
			},
			suppressMouseDown: true,
			getPosition: () => ({
				position: {
					lineNumber: position?.lineNumber, //foundBookmarks[0].range.endLineNumber,
					column: 2
				},
				preference: [monacoRef.editor.ContentWidgetPositionPreference.BELOW]
			})
		};

		editorRef.addContentWidget(widget);
		widgetRef.current = widget;
	}
};
