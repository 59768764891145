import {
	ChecklistGroupTypeResponse,
	addChecklistToUserLibrary,
	createChecklistGroup,
	deleteChecklistGroupById,
	loadChecklistGroups,
	removeChecklistFromUserLibrary,
	updateChecklistGroup
} from "Resources/ServerInterfaceChecklist";
import { mixpanelAtom } from "atoms";
import { useAtom } from "jotai";
import { useState } from "react";
import {
	ChecklistGroupType,
	checklistGroupsAtom,
	checklistLibraryAtom
} from "store/atoms/checklistAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import useSetAtom from "../useSetAtom";
import { addSuccessNotification } from "Resources/Notifications";

export function useChecklist() {
	const [mixpanel] = useAtom(mixpanelAtom);

	const [project] = useAtom(projectAtom);
	const projectId = project?.id;

	const [loading, setLoading] = useState(true);
	const [checklistGroups, setChecklistGroups] = useAtom(checklistGroupsAtom);
	const setChecklistLibrary = useSetAtom(checklistLibraryAtom);

	const loadChecklists = async () => {
		setLoading(true);
		const checklistGroups: ChecklistGroupTypeResponse[] = await loadChecklistGroups();

		const checklistsFiltered = checklistGroups.filter((group) =>
			group.projectID?.includes?.(projectId)
		);

		const checklistsLibraryFiltered = checklistGroups.filter((group) =>
			group.inLibrary
		);

		setChecklistGroups(checklistsFiltered);
		setChecklistLibrary(checklistsLibraryFiltered);
		setLoading(false);
	};

	const addNewGroup = async (groupTitle: string) => {
		try {
			const newChecklistGroup: ChecklistGroupType = {
				groupName: groupTitle,
				checklists: [{ title: "Unnamed checklist item", completed: false }],
				projectID: projectId
			};

			setLoading(true);
			await createChecklistGroup(newChecklistGroup, mixpanel);
			await loadChecklists();
		} catch (error) {
			console.log(error);
		}
	};

	const deleteGroup = async (groupId: string) => {
		setLoading(true);
		await deleteChecklistGroupById(groupId, mixpanel);
		await loadChecklists();
	};

	const renameGroup = async (groupId: string, newName: string) => {
		const checklistGroup = checklistGroups.find(
			(group) => group._id === groupId
		);

		if (checklistGroup) {
			setLoading(true);
			await updateChecklistGroup(
				{
					...checklistGroup,
					groupName: newName
				},
				null
			);
		}

		await loadChecklists();
	};

	const addGroupToLibrary = async (groupId: string) => {
		const checklistGroup = checklistGroups.find(
			(group) => group._id === groupId
		);

		if (checklistGroup) {
			setLoading(true);
			await addChecklistToUserLibrary(checklistGroup._id, mixpanel);
		}

		await loadChecklists();
		addSuccessNotification("Success", "Added checklist to library. This checklist can now be used in other projects.");
	};

	const removeGroupFromLibrary = async (groupId: string) => {
		const checklistGroup = checklistGroups.find(
			(group) => group._id === groupId
		);

		if (checklistGroup) {
			setLoading(true);
			await removeChecklistFromUserLibrary(checklistGroup._id, mixpanel);
		}

		await loadChecklists();
		addSuccessNotification("Success", "Removed checklist from library");
	};

	const addGroupFromLibrary = async (group: ChecklistGroupType) => {
		try {
			const newGroup = { ...group, projectID: projectId };

			setLoading(true);
			await createChecklistGroup(newGroup, mixpanel);
			await loadChecklists();
			addSuccessNotification("Success", "Checklist created in project");
		} catch (error) {
			console.log(error);
		}
	};

	const addNewChecklist = async (groupId: string, newToggleName: string) => {
		const checklistGroup = checklistGroups.find(
			(group) => group._id === groupId
		);
		if (checklistGroup) {
			setLoading(true);
			const checklists = checklistGroup.checklists;
			checklists.push({ title: newToggleName, completed: false });
			await updateChecklistGroup(
				{
					...checklistGroup,
					checklists
				},
				null
			);
		}
		await loadChecklists();
	};

	const deleteChecklist = async (groupId: string, checklistIdx: number) => {
		const checklistGroup = checklistGroups.find(
			(group) => group._id === groupId
		);
		if (checklistGroup) {
			setLoading(true);
			const checklists = checklistGroup.checklists;
			checklists.splice(checklistIdx, 1);
			await updateChecklistGroup(
				{
					...checklistGroup,
					checklists
				},
				null
			);
		}
		await loadChecklists();
	};

	const toggleChecklist = async (groupId: string, checklistIdx: number) => {
		const checklistGroup = checklistGroups.find(
			(group) => group._id === groupId
		);
		if (checklistGroup) {
			setLoading(true);
			const checklists = checklistGroup.checklists;
			const temp = checklists[checklistIdx];
			checklists[checklistIdx] = {
				title: temp.title,
				completed: !temp.completed
			};
			await updateChecklistGroup(
				{
					...checklistGroup,
					checklists
				},
				null
			);
		}
		await loadChecklists();
	};

	const renameChecklist = async (
		groupId: string,
		checklistIdx: number,
		newTitle: string
	) => {
		const checklistGroup = checklistGroups.find(
			(group) => group._id === groupId
		);
		if (checklistGroup) {
			setLoading(true);
			const checklists = checklistGroup.checklists;
			const temp = checklists[checklistIdx];
			checklists[checklistIdx] = { title: newTitle, completed: temp.completed };
			await updateChecklistGroup(
				{
					...checklistGroup,
					checklists
				},
				null
			);
		}
		await loadChecklists();
	};

	return {
		loading,
		loadChecklists,
		addNewGroup,
		deleteGroup,
		renameGroup,
		addNewChecklist,
		deleteChecklist,
		toggleChecklist,
		renameChecklist,
		addGroupToLibrary,
		removeGroupFromLibrary,
		addGroupFromLibrary
	};
}
