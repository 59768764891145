import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { forwardRef } from "react";
import NotificationToast from ".";
import { Box } from "@mui/material";

const ErrorToast = forwardRef((props: any, ref) => {
	return (
		<Box ref={ref}>
			<NotificationToast
				id={props.id}
				color="#E5B129"
				Icon={
					<PriorityHighIcon fontSize="small" sx={{ color: "text.inverse" }} />
				}
				title={props.title}
				message={props.message}
			/>
		</Box>
	);
});

export default ErrorToast;
