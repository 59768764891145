import { Icon } from "@iconify/react";
import {
	Button,
	Menu,
	MenuItem,
	Stack,
	Tooltip,
	Typography,
	styled,
	tooltipClasses,
	useTheme
} from "@mui/material";
import { clearAllCaches } from "components/ErrorFallback";
import HotkeysPopup from "components/HotkeysPopup";
import { StyledToolsButton } from "components/ToolSelector";
import { appConfig } from "config";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React, { useState } from "react";
import {
	feedbackModalOpenAtom,
	themeModeAtom,
	tutorialModalOpenAtom
} from "store/atoms/UiAtoms";
import { stickyNoteOpenAtom } from "store/atoms/stickyNoteAtoms";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
	paddingLeft: theme.spacing(1.5)
}));

const RightBarSupportButton = ({ showTooltip, handleClick, dropDownOpen }) => {
	const [isHovered, setIsHovered] = useState(false);

	return (
		<Tooltip
			title="Support and features"
			placement="left"
			open={showTooltip}
			slotProps={{
				tooltip: {
					sx: {
						[`&.${tooltipClasses.tooltip}`]: {
							bgcolor: "background.paper",
							border: "1px solid",
							color: "text.primary",
							borderColor: "background.paper2",
							boxShadow: "0px 4px 4px 0px #000000",

							borderRadius: "8px",
							...(isHovered && {
								bgcolor: "background.default",
								borderColor: "primary.main"
							})
						}
					}
				}
			}}
		>
			<StyledToolsButton
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				onClick={handleClick}
				sx={{
					borderBottomRightRadius: "10px"
				}}
			>
				<Icon icon="ri:question-line" fontSize={22} />
			</StyledToolsButton>
		</Tooltip>
	);
};

const SupportDropdown: React.FC<{
	navbarButton?: boolean;
	showTooltip?: boolean;
	handleTooltipClose?: any;
}> = ({ navbarButton, showTooltip, handleTooltipClose }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const setTutorialModalOpen = useSetAtom(tutorialModalOpenAtom);
	const setFeedbackModalOpen = useSetAtom(feedbackModalOpenAtom);

	const [hotkeysOpen, setHotkeysOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const [_themeMode, setThemeMode] = useAtom(themeModeAtom);
	const [stickyNoteOpen, setStickyNoteOpen] = useAtom(stickyNoteOpenAtom);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		handleTooltipClose?.();
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		handleTooltipClose?.();
		setAnchorEl(null);
	};

	const getButtonComponent = () => {
		if (navbarButton) {
			return (
				<Button
					disableElevation
					variant={"outlined"}
					onClick={handleClick}
					sx={{
						minWidth: 0,
						width: { xs: 34, sm: 38 },
						borderRadius: "8px",
						height: { xs: 34, sm: 38 },
						color: theme.palette.text.primary,
						borderColor:
							themeMode === "dark" ? "background.paper2" : "common.white",
						boxShadow: 1,
						"&:hover": {
							borderColor:
								themeMode === "dark" ? "primary.main" : "common.white"
						}
					}}
				>
					<Stack direction="row" alignItems="center" justifyContent="center">
						<Icon
							icon="ri:question-line"
							fontSize={26}
							color={theme.palette.common.white}
						/>
					</Stack>
				</Button>
			);
		}

		return (
			<RightBarSupportButton
				handleClick={handleClick}
				showTooltip={showTooltip}
				dropDownOpen={open}
			/>
		);
	};

	return (
		<>
			{getButtonComponent()}

			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				transformOrigin={
					navbarButton
						? { horizontal: "right", vertical: "top" }
						: { horizontal: "right", vertical: "bottom" }
				}
				anchorOrigin={
					navbarButton
						? { horizontal: "right", vertical: "bottom" }
						: { horizontal: "left", vertical: "top" }
				}
				elevation={0}
				slotProps={{
					paper: {
						sx: {
							mt: 1,
							width: 143,
							border:
								themeMode === "dark"
									? `1px solid ${theme.palette.primary.main}`
									: `1px solid ${theme.palette.background["paper2"]}`,
							borderRadius: "8px",
							boxShadow: 1
						}
					}
				}}
			>
				<a
					href={appConfig.docsUrl}
					target="_blank"
					rel="noreferrer"
					style={{ textDecoration: "none", color: "inherit" }}
				>
					<StyledMenuItem onClick={handleClose}>
						<Stack direction="row" alignItems="center" spacing={0.7}>
							<Icon icon="basil:document-solid" fontSize={22} />
							<Typography>Docs</Typography>
						</Stack>
					</StyledMenuItem>
				</a>

				<StyledMenuItem
					onClick={() => {
						setFeedbackModalOpen(true);
						handleClose();
					}}
				>
					<Stack direction="row" alignItems="center" spacing={0.7}>
						<Icon icon="material-symbols:bug-report" fontSize={23} />
						<Typography>Bug Report</Typography>
					</Stack>
				</StyledMenuItem>

				<StyledMenuItem onClick={clearAllCaches}>
					<Stack
						direction="row"
						alignItems="center"
						spacing={1}
						sx={{ pl: 0.3 }}
					>
						<Icon icon="fa6-solid:recycle" fontSize={18} />

						<Typography>Clear cache</Typography>
					</Stack>
				</StyledMenuItem>

				<StyledMenuItem
					onClick={() => {
						setHotkeysOpen(true);
						handleClose();
					}}
				>
					<Stack
						direction="row"
						alignItems="center"
						spacing={0.8}
						sx={{ pl: 0.1 }}
					>
						<Icon icon="material-symbols:keyboard-alt" fontSize={21} />
						<Typography>Hotkeys</Typography>
					</Stack>
				</StyledMenuItem>

				<StyledMenuItem
					onClick={() => {
						setStickyNoteOpen(!stickyNoteOpen);
						handleClose();
					}}
				>
					<Stack direction="row" alignItems="center" spacing={0.7}>
						<Icon icon="material-symbols-light:sticky-note-2" fontSize={22} />
						<Typography>Sticky note</Typography>
					</Stack>
				</StyledMenuItem>

				<a
					href={appConfig.socials.discord}
					target="_blank"
					rel="noreferrer"
					style={{ textDecoration: "none", color: "inherit" }}
				>
					<StyledMenuItem onClick={handleClose}>
						<Stack direction="row" alignItems="center" spacing={0.7}>
							<Icon icon="ri:discord-fill" fontSize={22} />
							<Typography>Discord</Typography>
						</Stack>
					</StyledMenuItem>
				</a>

				<StyledMenuItem
					onClick={() => {
						if (_themeMode === "dark") setThemeMode("light");
						else setThemeMode("dark");

						// handleClose();
					}}
				>
					<Stack direction="row" alignItems="center" spacing={0.7}>
						{_themeMode !== "dark" ? (
							<Icon icon="ic:round-dark-mode" fontSize={22} />
						) : (
							<Icon icon="ic:baseline-light-mode" fontSize={22} />
						)}

						<Typography>
							{_themeMode !== "dark" ? "Dark Mode" : "Light Mode"}
						</Typography>
					</Stack>
				</StyledMenuItem>
			</Menu>

			<HotkeysPopup open={hotkeysOpen} onClose={() => setHotkeysOpen(false)} />
		</>
	);
};

export default SupportDropdown;
