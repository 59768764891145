import ProjectFile from "../../../../Resources/ProjectFile";

export const getRelevantFilesList = (
	folder
): ProjectFile[] => {
	let solidityFiles: ProjectFile[] = [];

	// Process all files in the current folder
	for (const file of folder.files) {
		if (file.name.endsWith(".sol")) {
			solidityFiles.push(file);
		}
	}

	// Recursively process all subfolders
	for (const subFolder of folder.folders) {
		solidityFiles = solidityFiles.concat(getRelevantFilesList(subFolder));
	}

	// Sort the files alphabetically by name
	solidityFiles.sort((a, b) => a.name.localeCompare(b.name));

	return solidityFiles;
};

// Find file name by file ID
export const getFileById = (project, id) => {
	const files = getRelevantFilesList(project.rootFolder);
	return files.find((file) => file.id === id);
};
