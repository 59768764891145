import { Theme } from "@mui/material";
import { SxProps, keyframes } from "@mui/system";
import hex from "utils/hexTransparency";

export const searchStyles = (theme: Theme) => {
	const styles: SxProps = {
		"& .editor .overlayWidgets .find-widget": {
			height: "48px !important",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			bgcolor: theme.palette.background["nav"],
			borderBottomLeftRadius: "12px",
			borderBottomRightRadius: "12px",
			boxShadow:
				"rgba(0, 0, 0, 0.25) 0px 10px 20px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
			border: `1px solid ${theme.palette.primary.dark}${hex["20%"]} !important`,
			borderTopColor: "transparent !important",
			"& .find-part": {
				flex: 1,
				m: 0,
				p: 1,
				alignItems: "center"
			},
			"& .monaco-sash": {
				display: "none"
			},

			"& .monaco-inputbox": {
				bgcolor: `${theme.palette.background.paper} !important`,
				height: "32px",
				borderRadius: "8px",
				border: `1px solid ${theme.palette.primary.main}${hex["50%"]} !important`,
				"&.synthetic-focus": {
					outlineColor: theme.palette.primary.main
				}
			},

			"& .monaco-findInput": {
				alignItems: "center",
				"& .ibwrapper": {
					display: "flex",
					alignItems: "center",
					mt: "-2px"
				},
				"& .controls": {
					top: "unset",
					right: "6px"
				}
			}
		},
		"& .editor .view-zones": {
			zIndex: 11,
			"& > div": {
				width: "unset !important"
			}
		}
	};

	return styles;
};

export const blinkLinesStyles = (theme: Theme) => {
	const blink = keyframes`
		50% {
			opacity: 1;
			background-color: #FFFFFF1A;
		}
		0%, 100% {
			opacity: 0;
			background-color: transparent;
		}
		`;

	const styles: SxProps = {
		"& .editor-blink-lines": {
			animation: `${blink} 0.5s 2 ease-in-out`,
			WebkitAnimation: `${blink} 0.5s 2 ease-in-out`
		}
	};

	return styles;
};
