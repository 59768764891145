import { SxProps, Theme } from "@mui/material";

export const graphStyles = (theme: Theme) => {
	const styles: SxProps = {
		"& .graph": {
			"& > polygon, .cluster > polygon": {
				fill: theme.palette.background.paper,
				stroke: theme.palette.text.primary
			},

			"& text": {
				fill: theme.palette.text.primary,
				fontFamily: "Montserrat",
				fontSize: 12
			},
			"& .node": {
				cursor: "pointer",
				transform: "scale(1)",
				transformBox: "fill-box",
				transition: "all .2s ease-in-out",
				transformOrigin: "center",
				"&:hover": {
					transform: "scale(1.05)"
				},
				"& ellipse": {
					fill: theme.palette.background.default,
					stroke: theme.palette.text.primary
				}
			},
			"& .edge": {
				"& path": {
					stroke: theme.palette.success.main
				},
				"& polygon": {
					stroke: theme.palette.success.main
				}
			}
		}
	};

	return styles;
};
