import { addMonacoAction } from "@auditware/CodeEditorCore/helpers";
import { useTheme } from "@mui/material";
import { notepadOpenedFromCodeEvent } from "Mixpanel/mixpanel.helper";
import { mixpanelAtom } from "atoms";
import DeleteNoteModal from "components/CustomModal/DeleteNoteModal";
import { useEditorTabs } from "hooks/ui/useEditorTabs";
import { useTools } from "hooks/ui/useTools";
import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { notesAtom } from "store/atoms/NotesAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import NotesWidget from "./NotesWidget";
import {
	addNoteViewZones,
	parseNotes,
	removeNotesGlyphsAndListeners
} from "./helpers";
import { openedTabAtom } from "store/atoms/UiAtoms";

const NotesAddon: React.FC<{ editorRef: any; monacoRef: any }> = ({
	editorRef,
	monacoRef
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [mixpanel] = useAtom(mixpanelAtom);
	const [notes] = useAtom(notesAtom);
	const [parsedNotes, setParsedNotes] = useState([]);

	const [viewZoneIds, setViewZoneIds] = useState([]);
	const [viewAnchor, setViewAnchor] = React.useState<null | any>(null);

	const [deleteNoteModalOpen, setDeleteNoteModalOpen] = useState<string | null>(
		null
	);

	const [{ projectFilePath }] = useAtom(openedTabAtom);

	const { selectTool } = useTools();

	const [project] = useAtom(projectAtom);

	const widgetRef = useRef(null);

	// Parse notes
	useEffect(() => {
		setParsedNotes(
			parseNotes(
				notes.filter((i) => i.location?.includes?.(projectFilePath)),
				theme
			)
		);
	}, [notes, projectFilePath, theme]);

	// Add Note button to right click menu
	useEffect(() => {
		if (editorRef && monacoRef) {
			addMonacoAction(editorRef, monacoRef, handleAddNote, {
				id: "editor-add-note-action",
				label: "Add Note"
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editorRef, monacoRef]);

	// Add note decorations to monaco
	useEffect(() => {
		if (parsedNotes?.length && editorRef) {
			// Glyph
			removeNotesGlyphsAndListeners(editorRef, widgetRef, viewZoneIds);
			editorRef?.createDecorationsCollection?.(parsedNotes);

			// Zone
			setViewZoneIds(
				addNoteViewZones(
					editorRef,
					parsedNotes,
					viewZoneIds,
					handleViewNote,
					handleEditNote,
					handleDeleteNote,
					themeMode
				)
			);
		} else {
			// Glyph
			removeNotesGlyphsAndListeners(editorRef, widgetRef, viewZoneIds);
			editorRef?.createDecorationsCollection?.(parsedNotes);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parsedNotes, editorRef]);

	const closeViewNote = () => {
		setViewAnchor(null);
	};

	const handleViewNote = (e, _note) => {
		setViewAnchor({ target: e.currentTarget, note: _note });
	};

	const handleEditNote = (_note) => {
		const foundIndex = notes.findIndex((i) => i._id === _note._id);

		return setTimeout(() => {
			selectTool("Notepad", { mode: "notes" });
			selectTool("Notepad", { mode: foundIndex });
		}, 100);
	};

	const handleDeleteNote = (_note) => {
		return setTimeout(() => {
			setDeleteNoteModalOpen(_note._id);
		}, 100);
	};

	const handleAddNote = () => {
		notepadOpenedFromCodeEvent(mixpanel, {
			projectId: project.id,
			filePath: projectFilePath
		});
		selectTool("Notepad", { mode: null });

		return setTimeout(() => {
			selectTool("Notepad", { mode: "new" });
		}, 100);
	};

	return (
		<>
			<DeleteNoteModal
				noteId={deleteNoteModalOpen}
				onClose={() => setDeleteNoteModalOpen(null)}
			/>

			{viewAnchor?.note && (
				<NotesWidget
					viewAnchor={viewAnchor}
					closeViewNote={closeViewNote}
					handleEditNote={handleEditNote}
					handleDeleteNote={handleDeleteNote}
				/>
			)}
		</>
	);
};

export default React.memo(NotesAddon);
