import CodeEditorCore from "@auditware/CodeEditorCore";
import { Monaco } from "@monaco-editor/react";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
	editProjectImport,
	loadProject,
	resolveRemoteImport
} from "Resources/ServerInterface";
import { useCodeScan } from "hooks/data/useCodeScan";
import { useEditorTabs } from "hooks/ui/useEditorTabs";
import { useAtom } from "jotai";
import { editor } from "monaco-editor";
import React, { useCallback, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { editorTabsAtom } from "store/atoms/UiAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";

interface Props {
	fileName: string;
	filePath: string;
	editMode?: boolean;
	importId?: string;
}

const ResolveMissingImport: React.FC<Props> = ({
	fileName,
	filePath,
	editMode,
	importId
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const DEFAULT_PLACEHOLDER = !editMode
		? "// Paste the missing import code here"
		: "// Paste corrected import code here";

	const [editorValue, setEditorValue] = useState("");
	const [loading, setLoading] = useState(false);

	const [openTabs] = useAtom(editorTabsAtom);

	const [project, setProject] = useAtom(projectAtom);

	const { removePersistedProjectScans } = useCodeScan();
	const { closeFiles } = useEditorTabs();

	const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
	const monacoRef = useRef<Monaco | null>(null);

	const setEditorRef = (newValue) => {
		editorRef.current = newValue;
	};
	const setMonacoRef = (newValue) => {
		monacoRef.current = newValue;
	};

	const onDrop = useCallback((acceptedFiles) => {
		const file = acceptedFiles[0];

		// Check the file type
		if (
			file.type === "image/jpeg" ||
			file.type === "image/png" ||
			file.type === "application/pdf" ||
			file.type === "application/msword" ||
			file.type ===
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
		) {
			// Reject the file if it's an image, PDF, or DOC
			console.log(
				"Please select a valid file type (not an image, PDF, or DOC)"
			);
			return;
		}

		// Read the content of the uploaded text file
		const reader = new FileReader();
		reader.onload = (event) => {
			const content = event.target.result;
			if (typeof content === "string") {
				setEditorValue(content);
			}
		};
		reader.readAsText(file);
	}, []);

	const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 });

	const isValidInput = editorValue.trim()?.length > 0;

	const handleResolveImport = async () => {
		setLoading(true);
		if (!editMode) {
			await resolveRemoteImport(editorValue, project.id, filePath);
		} else {
			await editProjectImport(editorValue, project.id, importId);
		}

		const projectWithImport = await loadProject(project.id);
		setProject(projectWithImport);
		setLoading(false);

		const indexToCloseOriginal = openTabs.findIndex(
			(tab) => tab.name === fileName
		);

		const indexToClose = !editMode
			? openTabs.findIndex((tab) => tab.name === `${fileName} (Resolve Import)`)
			: openTabs.findIndex((tab) => tab.name === `${fileName} (Edit Import)`);

		closeFiles([indexToCloseOriginal, indexToClose]);

		// Remove the persisted scan errors
		removePersistedProjectScans();
	};

	return (
		<Stack sx={{ p: 2, height: "100%" }}>
			<Stack
				direction="row"
				spacing={1}
				justifyContent="space-between"
				sx={{ mb: 2 }}
			>
				<Box>
					<Typography variant="h6">
						{editMode ? "Edit existing import" : "Resolve missing import"}
					</Typography>

					<Typography color="text.secondary">
						{editMode
							? `Edit this import to resolve compilation
						issues`
							: `This import failed to fetch.
						Please provide the code for it.`}
					</Typography>
				</Box>

				<Stack direction="row" spacing={1.5}>
					<Button
						variant="outlined"
						{...getRootProps()}
						sx={{
							...(themeMode === "dark" && {
								border: `2px dashed ${theme.palette.primary.dark}`,
								boxShadow: 1,
								"&:hover": {
									border: `2px dashed ${theme.palette.primary.main}`,
									bgcolor: "transparent"
								}
							}),
							...(themeMode === "light" && {
								border: `2px dashed ${theme.palette.primary.main}`,
								boxShadow: 1,
								"&:hover": {
									border: `2px dashed ${theme.palette.primary.dark}`,
									bgcolor: "background.nav",
									color: "text.primary"
								}
							})
						}}
					>
						<input {...getInputProps()} />
						<Typography>Drag and drop or click to upload</Typography>
					</Button>

					<LoadingButton
						variant="contained"
						disabled={!isValidInput}
						loading={loading}
						onClick={handleResolveImport}
						sx={{
							borderRadius: "6px",
							...(themeMode === "light" && {
								boxShadow: 1
							})
						}}
					>
						Commit Import
					</LoadingButton>
				</Stack>
			</Stack>

			<Box
				sx={{
					flex: 1,
					border: `10px solid #1A1830`,
					borderRadius: "12px",
					pt: 1,
					mb: 1,
					...(themeMode === "light" && {
						border: `10px solid ${grey[300]}`,
						boxShadow: 1
					})
				}}
				{...getRootProps({
					onClick: (e) => e.stopPropagation()
				})}
			>
				<input
					onClick={(e) => {
						e.stopPropagation();
					}}
					{...getInputProps()}
				/>
				<CodeEditorCore
					value={editorValue}
					language={"sol"}
					editorRef={editorRef.current}
					setEditorRef={setEditorRef}
					monacoRef={monacoRef.current}
					setMonacoRef={setMonacoRef}
					editorOptions={{ readOnly: false }}
					onChange={setEditorValue}
					placeholder={DEFAULT_PLACEHOLDER}
				/>
			</Box>
		</Stack>
	);
};

export default ResolveMissingImport;
