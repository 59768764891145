import CheckIcon from "@mui/icons-material/Check";
import { Box } from "@mui/material";
import { forwardRef } from "react";
import NotificationToast from ".";

const SuccessToast = forwardRef((props: any, ref) => {
	return (
		<Box ref={ref}>
			<NotificationToast
				id={props.id}
				color="#1BCE8E"
				Icon={<CheckIcon fontSize="small" sx={{ color: "text.inverse" }} />}
				title={props.title}
				message={props.message}
			/>
		</Box>
	);
});

export default SuccessToast;
