import {
	ClickAwayListener,
	Divider,
	Grow,
	MenuItem,
	MenuList,
	Popper,
	Stack,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import { useEditorTabs } from "hooks/ui/useEditorTabs";
import { useHorizontalScroll } from "hooks/ui/useHorizontalScroll";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import SimpleBarReact from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
	editorTabsAtom,
	focusedEditorTabIndexAtom,
	openedTabAtom
} from "store/atoms/UiAtoms";
import Tab from "./TabItem";
import { useHotkeys } from "react-hotkeys-hook";

export const SimpleBarStyled = styled(SimpleBarReact)(({ theme }) => ({
	width: "100%",
	"& .simplebar-scrollbar": {
		"&:before": { backgroundColor: theme.palette.background["paper2"] },
		"&.simplebar-visible:before": {
			opacity: 1
		}
	},
	"& .simplebar-track": {
		pointerEvents: "initial"
	},
	// Vertical
	"& .simplebar-track.simplebar-vertical": {
		width: 0
	},
	// Horizontal container
	"& .simplebar-track.simplebar-horizontal": {
		height: 7
	},
	// Horizontal scroll bar
	"& .simplebar-track.simplebar-horizontal .simplebar-scrollbar": {
		height: 5,
		borderRadius: 0
	},
	"& .simplebar-mask": {
		zIndex: "inherit"
	}
}));

const TabsList = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [anchorEl, setAnchorEl] = useState<null | {
		index: number;
		el: HTMLElement;
	}>(null);

	const scrollRef = useHorizontalScroll();

	const { closeFile, closeAllTabs, closeAllOtherTabs } = useEditorTabs();

	const [focusedTabIndex, setFocusedTabIndex] = useAtom(
		focusedEditorTabIndexAtom
	);

	const [openTabs] = useAtom(editorTabsAtom);
	const [{ openedTab }] = useAtom(openedTabAtom);

	// Scroll to the specific tab
	const handleScrollToTab = () => {
		const tabToScroll = document.querySelector(
			`.editorTab_${focusedTabIndex}`
		) as HTMLElement;

		if (tabToScroll) {
			tabToScroll.scrollIntoView({ behavior: "smooth", block: "nearest" });
		}
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		handleScrollToTab();
	}, [openedTab]);

	// Close tabs functions
	const closeTab = () => {
		const idx =
			anchorEl?.index !== undefined ? anchorEl?.index : focusedTabIndex;
		closeFile(idx);
	};

	const closeOtherTabs = () => {
		const idx =
			anchorEl?.index !== undefined ? anchorEl?.index : focusedTabIndex;

		closeAllOtherTabs(idx);
	};

	useHotkeys("alt+w", closeTab, [anchorEl]);
	useHotkeys("alt+shift+w", closeAllTabs, [anchorEl]);
	useHotkeys("ctrl+alt+shift+w", closeOtherTabs, [anchorEl]);

	return (
		<>
			<SimpleBarStyled
				autoHide
				clickOnTrack={true}
				scrollableNodeProps={{
					ref: scrollRef
				}}
			>
				<Droppable droppableId="droppable" direction="horizontal">
					{(provided, snapshot) => {
						const isDraggingOver = snapshot.isDraggingOver;

						return (
							<Stack
								{...provided.droppableProps}
								direction="row"
								ref={provided.innerRef}
								sx={{ width: "fit-content" }}
							>
								{openTabs.map((tab, index) => (
									<Tab
										key={index}
										isLastEl={index === openTabs?.length - 1}
										selected={focusedTabIndex === index}
										name={tab.name}
										path={tab.path}
										id={tab.path}
										index={index}
										handleClick={() => setFocusedTabIndex(index)}
										handleClose={() => closeFile(index)}
										anchorEl={anchorEl}
										setAnchorEl={setAnchorEl}
									/>
								))}

								{provided.placeholder}

								<ClickAwayListener
									onClickAway={() => !!anchorEl && handleMenuClose()}
								>
									<Popper
										open={Boolean(anchorEl) && !isDraggingOver}
										anchorEl={anchorEl?.el}
										transition
										placement="bottom-end"
									>
										{({ TransitionProps }) => (
											<Grow {...TransitionProps}>
												<MenuList
													sx={{
														// width: 150,
														border:
															themeMode === "dark"
																? `1px solid ${theme.palette.primary.main}`
																: `1px solid ${theme.palette.background["paper2"]}`,
														borderRadius: "8px",
														boxShadow: 1,
														bgcolor: "background.paper",
														backgroundImage: "none"
													}}
												>
													<MenuItem onClick={closeTab}>
														<Stack
															direction="row"
															alignItems="center"
															justifyContent="space-between"
															sx={{ width: "100%" }}
														>
															<Typography variant="body2">Close</Typography>
															<Typography
																variant="caption"
																sx={{ color: "text.secondary" }}
															>
																ALT+W
															</Typography>
														</Stack>
													</MenuItem>

													<MenuItem onClick={closeOtherTabs}>
														<Typography variant="body2">
															Close other tabs
														</Typography>
													</MenuItem>

													<MenuItem onClick={closeAllTabs}>
														<Typography variant="body2">
															Close all tabs
														</Typography>
													</MenuItem>

													<Divider />

													<CopyToClipboard
														text={openTabs[anchorEl?.index]?.path || ""}
														onCopy={handleMenuClose}
													>
														<MenuItem>
															<Typography variant="body2">
																Copy relative path
															</Typography>
														</MenuItem>
													</CopyToClipboard>
												</MenuList>
											</Grow>
										)}
									</Popper>
								</ClickAwayListener>
							</Stack>
						);
					}}
				</Droppable>
			</SimpleBarStyled>
		</>
	);
};

export default React.memo(TabsList);
