import { exportToBlob } from "@excalidraw/excalidraw";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import ProjectFile from "Resources/ProjectFile";
import ConfirmationModal from "components/CustomModal/ConfirmationModal";
import EditorTab from "context/EditorTab";
import { useWhiteboard } from "hooks/data/useWhiteboard";
import { useEditorTabs } from "hooks/ui/useEditorTabs";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { editorTabsAtom, focusedEditorTabIndexAtom } from "store/atoms/UiAtoms";
import { clearLocalStorageItemsByString } from "utils/helpersFunctions";

const BoardListItem: React.FC<{
	title: string;
	value: any;
	[_key: string]: any;
}> = (props) => {
	const { title, value, files, _id } = props;

	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [image, setImage] = useState(null);
	const [deleteOpen, setDeleteOpen] = useState(false);

	const setFocusedTabIndex = useSetAtom(focusedEditorTabIndexAtom);

	const { openCustomTab, findAndOpenTab, changeOpenTabValues, closeFile } =
		useEditorTabs();

	const { deleteDrawing } = useWhiteboard();

	const [openTabs] = useAtom(editorTabsAtom);

	useEffect(() => {
		getBlob().then((blob) => {
			setImage(window.URL.createObjectURL(blob));
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [themeMode, value]);

	const getBlob = async () => {
		return await exportToBlob({
			elements: JSON.parse(value),
			mimeType: "image/png",
			appState: {
				viewBackgroundColor: "transparent",
				currentItemFontFamily: 1,
				exportWithDarkMode: themeMode === "dark"
			},
			files: JSON.parse(files || "{}")
		});
	};

	const handleOpenBoard = (e: any) => {
		e.stopPropagation();

		var shortTitle = title.length > 10 ? title.substring(0, 10) + "..." : title;

		const tabName = `Whiteboard (${shortTitle})`;
		const tabPath = `/whiteboard/${_id}`;

		const openedBlankBoard = openTabs.findIndex(
			(i) => i.path === "/whiteboard"
		);
		const openedIdBoard = openTabs.findIndex((i) => i.path === tabPath);

		if (openedBlankBoard > -1 && openedIdBoard < 0) {
			changeOpenTabValues(
				openedBlankBoard,
				new EditorTab(
					new ProjectFile(tabName, null, tabPath, null, { title, value, _id }),
					tabName,
					tabPath
				)
			);
			setFocusedTabIndex(openedBlankBoard);
		} else {
			// Open this board
			findAndOpenTab(tabPath, () =>
				openCustomTab(
					null,
					tabName,
					tabPath,
					new ProjectFile(tabName, null, tabPath, null, { title, value, _id })
				)
			);
		}
	};

	const handleOpenConfirmModal = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		e.preventDefault();

		setDeleteOpen(true);
	};

	const handleDelete = async () => {
		await deleteDrawing(_id);
		clearLocalStorageItemsByString(_id);

		const foundIndex = openTabs.findIndex((i) => i.path?.includes?.(_id));

		if (foundIndex > -1) {
			closeFile(foundIndex);
		}

		setDeleteOpen(false);
	};

	return (
		<Box
			onClick={handleOpenBoard}
			sx={{
				cursor: "pointer",
				borderRadius: "14px",
				bgcolor: "background.paper",
				p: 2,
				...(themeMode === "light" && {
					boxShadow: 1,
					bgcolor: "background.paper",
					"&:hover": {
						bgcolor: theme.palette.background["default"],
						"& .actionButton": {
							display: "inline-flex"
						}
					}
				}),
				...(themeMode === "dark" && {
					"&:hover": {
						bgcolor: theme.palette.background["nav"],
						"& .actionButton": {
							display: "inline-flex"
						}
					}
				})
			}}
		>
			<Stack direction="row" alignItems="center" spacing={0.7} sx={{ mb: 2 }}>
				<Typography sx={{ fontWeight: 500 }}>{title}</Typography>

				<Box sx={{ flex: 1 }} />

				<IconButton
					size="small"
					onClick={handleOpenConfirmModal}
					className="actionButton"
					sx={{
						display: "none",
						color: "error.light",
						height: 22,
						width: 22
					}}
				>
					<DeleteOutlineOutlinedIcon sx={{ fontSize: 18 }} />
				</IconButton>
			</Stack>

			<Box
				sx={{
					borderRadius: "8px",
					bgcolor: "background.default",
					boxShadow: 1
				}}
			>
				{image && (
					<img
						src={image}
						alt=""
						style={{ height: 100, width: "100%", objectFit: "contain" }}
					/>
				)}
			</Box>

			<ConfirmationModal
				open={deleteOpen}
				onClose={() => setDeleteOpen(false)}
				onConfirm={handleDelete}
				title="Delete Drawing?"
				description="Are you sure you want to delete this drawing?"
				confirmButtonText="Delete"
				confirmButtonLoadingText="Deleting..."
			/>
		</Box>
	);
};

export default BoardListItem;
