import {
	appCountAtom,
	editorTabsAtom,
	focusedEditorTabIndexAtom,
	searchAllBoxOpenAtom,
	searchFilesBoxOpenAtom,
	selectedToolAtom,
	selectedToolPropsAtom,
	submissionModeAtom
} from "store/atoms/UiAtoms";
import useSetAtom from "./useSetAtom";
import { useNavigate } from "react-router-dom";
import { useProject } from "./data/useProject";
import { getUsername } from "Resources/Auth";
import { userAvatarSrcAtom, usernameAtom } from "atoms";
import { storageSlotAtoms } from "store/atoms/storageSlotAtoms";
import { findingsAtom } from "store/atoms/findingsAtom";
import { notesTagsAtom } from "store/atoms/NotesAtoms";
import { gitReposAtom } from "store/atoms/ImportsAtoms";
import {
	projectAtom,
	projectLoadedAtom,
	projectsAtom,
	projectsLoadedAtom
} from "store/atoms/projectToolAtoms";
import { testFilesAtom, testResultsAtom } from "store/atoms/testAtoms";
import { userSubmissionsAtom } from "store/atoms/BugChallengeAtoms";
import { transactionsAtom } from "store/atoms/simulationAtoms";

export function useClearAtoms() {
	const setCount = useSetAtom(appCountAtom);
	const setUsername = useSetAtom(usernameAtom);

	const navigate = useNavigate();

	const setProjectsLoaded = useSetAtom(projectsLoadedAtom);
	const setProjectLoaded = useSetAtom(projectLoadedAtom);
	const setProject = useSetAtom(projectAtom);
	const setOpenTabs = useSetAtom(editorTabsAtom);
	const setFocusedTabIndex = useSetAtom(focusedEditorTabIndexAtom);

	const setSelectedTool = useSetAtom(selectedToolAtom);
	const setSelectedToolProps = useSetAtom(selectedToolPropsAtom);
	const setStorageSlots = useSetAtom(storageSlotAtoms);
	const setFindings = useSetAtom(findingsAtom);
	const setTransactions = useSetAtom(transactionsAtom);
	const setNotesTags = useSetAtom(notesTagsAtom);
	const setUserAvatarSrc = useSetAtom(userAvatarSrcAtom);
	const setGitRepos = useSetAtom(gitReposAtom);
	const setProjects = useSetAtom(projectsAtom);
	const setTestFiles = useSetAtom(testFilesAtom);
	const setSearchAllBoxOpen = useSetAtom(searchAllBoxOpenAtom);
	const setSubmissionMode = useSetAtom(submissionModeAtom);
	const setSearchFilesBoxOpen = useSetAtom(searchFilesBoxOpenAtom);

	const setTestResults = useSetAtom(testResultsAtom);
	const setUserChallengeSubmissions = useSetAtom(userSubmissionsAtom);

	const clearAtoms2 = async (callback?: any) => {
		// Reset All atoms
		setCount((count) => count + 1);
		callback?.();

		// Set back required atoms

		const username = getUsername();
		if (!!username) {
			setUsername(username);
		}

		// Navigate to home
		navigate("/");
	};

	const clearAtoms = (loggedOut = false) => {
		// leave this comment here, need to research more on this
		// unstable_batchedUpdates(() => {
		setProject(null);
		setProjectLoaded(false);
		setOpenTabs([]);
		setSelectedTool(null);
		setSelectedToolProps({});
		setFocusedTabIndex(0);
		setTestFiles([]);
		setFindings([]);
		setTransactions([]);
		setTestResults({});
		setProjectsLoaded(false);
		setGitRepos([]);
		setSearchAllBoxOpen(false);
		setSubmissionMode(false);
		setSearchFilesBoxOpen(false);
		setStorageSlots({ awLoading: true });

		if (loggedOut) {
			setProjects([]);
		}
	};

	return { clearAtoms };
}
