import { Stack } from "@mui/material";
import { NAVBAR_HEIGHT } from "../../Navbar";
import MyAccount from "../../MyAccount";
import AccountSidebar from "../../sidebars/AccountSidebar";

const SettingsLayout = () => {
	return (
		<Stack
			direction="row"
			sx={{
				flexGrow: 1,
				height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
				width: "100vw"
			}}
		>
			{/* Sidebar */}
			<AccountSidebar />

			{/* Main */}
			<Stack sx={{ flexGrow: 1, p: 2, width: 0, minHeight: 0 }}>
				<MyAccount />
			</Stack>
		</Stack>
	);
};

export default SettingsLayout;
