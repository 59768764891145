import { Icon } from "@iconify/react";
import { Stack } from "@mui/material";
import { useTerminal } from "hooks/ui/useTerminal";
import { useAtom } from "jotai";
import { terminalHeightAtom } from "store/atoms/terminalAtoms";
import { StyledIconbutton } from ".";
import React from "react";

const ActionButtons = () => {
	const [height, setHeight] = useAtom(terminalHeightAtom);

	const { closeTerminal } = useTerminal();

	const toggleHeight = () => {
		if (height > 300) return setHeight(300);
		else return setHeight(600);
	};

	return (
		<Stack direction="row" spacing={0.8} sx={{ pr: 1 }}>
			<StyledIconbutton onClick={toggleHeight}>
				{height > 300 ? (
					<Icon icon="ri:arrow-down-s-line" fontSize={20} />
				) : (
					<Icon icon="ri:arrow-up-s-line" fontSize={20} />
				)}
			</StyledIconbutton>

			<StyledIconbutton onClick={closeTerminal}>
				<Icon icon="material-symbols:close" fontSize={18} />
			</StyledIconbutton>
		</Stack>
	);
};

export default React.memo(ActionButtons);
