import { Challenge } from "Resources/ServerInterfaceChallenge";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { LOCALSTORAGE_FIND_BUG_MODAL } from "utils/constants";

const defaultChallenge: Challenge = {
	live: false,
	repoUrl: '',
	expectedEndDate: '',
	title: '',
	description: '',
	image: '',
	commitHash: '',
}

export const challengesAtom = atom<Challenge[]>([]);
export const focusedChallengeAtom = atom<Challenge>(defaultChallenge);
export const userSubmissionsAtom = atom<string[]>([]);
export const bugChallengeSubmittedAtom = atom(true);

export const findBugModalOpenAtom = atomWithStorage(
	LOCALSTORAGE_FIND_BUG_MODAL,
	true
);
