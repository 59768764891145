import { ChecklistGroupTypeResponse } from "Resources/ServerInterfaceChecklist";
import { atom } from "jotai";

export interface ChecklistType {
	title: string;
	completed: boolean;
}

export interface ChecklistGroupType {
	groupName: string;
	checklists: ChecklistType[];
    projectID: string;
    _id?: string;
}


// export const checklistsLoadingAtom = atom(true);
export const checklistGroupsAtom = atom<ChecklistGroupTypeResponse[]>([]);

// TODO: Fix the type for library
export const checklistLibraryAtom = atom<ChecklistGroupType[]>([]);
