import { Icon } from "@iconify/react";
import {
	Box,
	IconButton,
	Stack,
	Tooltip,
	Typography,
	tooltipClasses,
	useTheme
} from "@mui/material";
import useMiddleClick from "hooks/ui/useMiddleClick";
import React, { useRef } from "react";
import { Draggable } from "react-beautiful-dnd";
import { getFileTypeFromString } from "utils/helpersFunctions";

interface TabFileIconType {
	icon: string;
	size: number;
}

const tabIcons = (fileType: string, filePath: string = ""): TabFileIconType => {
	if (fileType.includes("custom-test") || filePath.endsWith(".t.sol")) {
		return { icon: "heroicons:beaker-20-solid", size: 18 };
	}

	if (filePath.includes("function-explorer")) {
		return { icon: "fluent:text-grammar-wand-16-filled", size: 18 };
	}

	switch (fileType) {
		case "sol":
			return { icon: "file-icons:solidity", size: 15 };
		case "js":
			return { icon: "ri:javascript-fill", size: 20 };
		case "ts":
			return { icon: "bxl:typescript", size: 20 };
		case "tsx":
			return { icon: "mdi:react", size: 18 };
		case "jsx":
			return { icon: "mdi:react", size: 18 };
		case "md":
			return { icon: "mdi:letter-m-box", size: 20 };
		case "json":
			return { icon: "lets-icons:json", size: 20 };
		case "rs":
			return { icon: "simple-icons:rust", size: 20 };
		case "pdf":
			return { icon: "tabler:pdf", size: 20 };
		case "whiteboard":
			return { icon: "fluent:whiteboard-48-filled", size: 18 };
		default:
			return { icon: "solar:file-bold", size: 16 };
	}
};

interface TabProps {
	id: string;
	name: string;
	path: string;
	index: number;
	selected: boolean;
	isLastEl: boolean;
	handleClick: any;
	handleClose: any;
	anchorEl: any;
	setAnchorEl: any;
}

const TabItem: React.FC<TabProps> = ({
	id,
	name,
	index,
	path,
	selected,
	isLastEl,
	handleClick: propsHandleClick,
	handleClose: propsHandleClose,
	anchorEl,
	setAnchorEl
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const buttonRef = useRef<any>(null);

	const fileType = getFileTypeFromString(path);
	const fileTypeIcon: TabFileIconType = tabIcons(fileType, path);

	useMiddleClick(buttonRef, () => handleClose(undefined));

	const handleClick = () => {
		propsHandleClick();
	};

	const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e?.stopPropagation?.();

		propsHandleClose();
	};

	const handleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();

		setAnchorEl({ index, el: event.currentTarget });
	};

	return (
		<>
			<Draggable key={id} draggableId={id} index={index}>
				{(provided, snapshot) => {
					const isDragging = snapshot.isDragging;

					return (
						<Tooltip
							title={anchorEl?.index !== index && !isDragging && path}
							enterDelay={1500}
							enterNextDelay={700}
							placement="bottom-start"
							slotProps={{
								tooltip: {
									sx: {
										[`&.${tooltipClasses.tooltip}`]: {
											border: `1px solid ${theme.palette.primary.dark}`,
											borderRadius: "8px",
											bgcolor: "background.paper",
											[theme.breakpoints.up("md")]: {
												mt: "8px !important",
												ml: "-4px !important"
											}
										}
									}
								}
							}}
						>
							<Box
								onContextMenu={handleContextMenu}
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
								onClick={handleClick}
								className={`editorTab_${index}`}
								sx={{
									...provided.draggableProps.style
								}}
							>
								<Stack
									direction="row"
									alignItems="center"
									ref={buttonRef}
									sx={{
										pl: 1.8,
										pr: 1.3,
										py: 0,
										height: 40,
										userSelect: "none",
										bgcolor: "background.nav",
										borderRight: `1px solid ${theme.palette.background.paper}`,
										borderLeft:
											isDragging &&
											`1px solid ${theme.palette.background.paper}`,
										borderTopRightRadius: isLastEl && selected ? "10px" : 0,
										cursor: "pointer !important",
										"&:hover": {
											bgcolor: "action.hover"
										},
										...(selected && {
											bgcolor: "background.paper",
											"&:hover": {
												bgcolor: "background.paper"
											}
										})
									}}
								>
									<Icon icon={fileTypeIcon.icon} fontSize={fileTypeIcon.size} />

									<Typography
										sx={{ fontSize: 13, ml: 0.7, whiteSpace: "nowrap" }}
									>
										{name}
									</Typography>

									<IconButton
										onClick={handleClose}
										sx={{
											height: 22,
											width: 22,
											p: 0,
											ml: 0.7,
											color: "text.secondary",
											borderRadius: "5px",
											"&:hover": {
												color: "text.primary"
											}
										}}
									>
										<Icon icon="mingcute:close-fill" fontSize={16} />
									</IconButton>
								</Stack>
							</Box>
						</Tooltip>
					);
				}}
			</Draggable>
		</>
	);
};

export default React.memo(TabItem);
