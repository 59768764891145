import React, { useEffect } from "react";
import Form from "../Form";
import { useAtom } from "jotai";
import useSetAtom from "hooks/useSetAtom";
import {
	importsLoadingAtom,
	isPrivateModeAtom
} from "store/atoms/ImportsAtoms";
import {
	Box,
	Button,
	Container,
	Grid,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import { Icon } from "@iconify/react";
import hex from "utils/hexTransparency";
import { githubClientIdAtom, githubInstallationIdAtom, githubUsernameAtom, usernameAtom } from "atoms";
import { redirectToGitHubForAuth, redirectToGitHubForInstallation } from "utils/helpersFunctions";
import { loginModalOpenAtom } from "store/atoms/UiAtoms";
import GitHubProjects from "./GitHubProjects";
import importsPage1Light from "assets/images/login/importsPage1Light.png";
import importsPage2Light from "assets/images/login/importsPage2Light.png";
import importsPage3Light from "assets/images/login/importsPage3Light.png";
import importsPage1Dark from "assets/images/login/importsPage1Dark.png";
import importsPage2Dark from "assets/images/login/importsPage2Dark.png";
import importsPage3Dark from "assets/images/login/importsPage3Dark.png";
import { getUserHasGithubInstallation, linkWithGithub } from "Resources/ServerInterface";
import secureLocalStorage from "react-secure-storage";
import { LOCALSTORAGE_GITHUB_CONNECTION } from "utils/constants";

interface BlockType {
	image: { light: any; dark: any };
	text: string;
}

const BLOCKS: BlockType[] = [
	{
		image: { light: importsPage1Light, dark: importsPage1Dark },
		text: "Import your project and start using state of the art security tools with no setup."
	},
	{
		image: { light: importsPage2Light, dark: importsPage2Dark },
		text: "Identify potential security vulnerabilities in the code you are reviewing."
	},
	{
		image: { light: importsPage3Light, dark: importsPage3Dark },
		text: "Annotate code, log findings, write foundry unit tests, and suggest mitigations."
	}
];

const BlockCard: React.FC<BlockType & { index: number }> = ({
	image,
	text,
	index
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	return (
		<Box
			sx={{
				height: "100%",
				p: 1.5,
				bgcolor: "background.paper",
				boxShadow: 1,
				borderRadius: "12px",
				position: "relative"
			}}
		>
			<Stack
				alignItems="center"
				sx={{
					position: "absolute",
					top: "-15px",
					left: 0,
					right: 0
				}}
			>
				<Stack
					alignItems="center"
					justifyContent="center"
					sx={{
						height: 40,
						width: 40,
						borderRadius: "50%",
						border: "7px solid",
						borderColor: "background.paper",
						bgcolor: "background.nav"
					}}
				>
					<Typography sx={{ fontWeight: 600 }}>{index}</Typography>
				</Stack>
			</Stack>

			<Box
				component="img"
				src={image[themeMode]}
				sx={{
					objectFit: "cover",
					height: 150,
					width: "100%",
					borderRadius: "12px",
					mb: 1
				}}
			/>

			<Typography sx={{ textAlign: "center" }}>{text}</Typography>
		</Box>
	);
};

const ImportsTab = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [loading, setLoading] = useAtom(importsLoadingAtom);
	const setPrivateMode = useSetAtom(isPrivateModeAtom);
	const [githubUsername, setGithubUsername] = useAtom(githubUsernameAtom);
	const [username] = useAtom(usernameAtom);
	const setLoginModalOpen = useSetAtom(loginModalOpenAtom);
	const setGithubInstallationId = useSetAtom(githubInstallationIdAtom);

	const handleGitHubClick = () => {
		if (!githubUsername) {
			localStorage.setItem("linkingGithub", "true");
			getUserHasGithubInstallation().then((hasInstallation) => {
				if (hasInstallation) {
					linkWithGithub().then((githubData) => {
						setGithubUsername(githubData.githubUsername);
						setGithubInstallationId(null);
						secureLocalStorage.setItem(
							"personalAccessToken",
							githubData.accessToken
						);
					})
				} else {
					redirectToGitHubForInstallation();
				}
			})
		}
	};

	useEffect(() => {
		const githubConnection = localStorage.getItem(
			LOCALSTORAGE_GITHUB_CONNECTION
		);
		
		if (!!githubConnection) {
			const githubConnectionJson = JSON.parse(githubConnection);
			setGithubUsername(githubConnectionJson.githubUsername);
		}
	}, []);

	return (
		<Stack sx={{ height: "100%" }}>
			<Container
				maxWidth="md"
				sx={{ height: "100%", pt: { xs: 3, md: 4 }, pb: 2 }}
			>
				<Stack sx={{ height: "100%" }}>
					{/* Import Form */}
					<Stack
						spacing={1.5}
						justifyContent="center"
						alignItems="center"
						sx={{ textAlign: "center", mb: 5 }}
					>
						<Typography variant="h3" sx={{ fontWeight: 600 }}>
							One-click project imports
						</Typography>

						<Typography sx={{ maxWidth: 450 }}>
							Import a smart contract from any chain, or via GitHub repository
							URL to get started
						</Typography>

						<Form loading={loading} setPrivateMode={setPrivateMode} />
					</Stack>

					{/* Blocks */}
					{!githubUsername && (
						<Box>
							<Grid
								container
								spacing={3}
								justifyContent="space-between"
								alignItems="center"
								sx={{ mb: 3 }}
							>
								{BLOCKS.map((block, i) => (
									<Grid
										item
										xs={12}
										sm={6}
										md={4}
										key={i}
										sx={{ alignSelf: "stretch" }}
									>
										<BlockCard {...block} index={i + 1} />
									</Grid>
								))}
							</Grid>
						</Box>
					)}

					{!githubUsername && <Box sx={{ flex: 1 }} />}

					{/* Github */}
					{!githubUsername && (
						<Stack
							direction={{ xs: "column", md: "row" }}
							alignItems="center"
							justifyContent="space-between"
							spacing={{ xs: 2 }}
							sx={{
								border: "1px solid",
								borderColor: "divider",
								bgcolor: `${theme.palette.background.paper}${hex["90%"]}`,
								p: { xs: 2, md: 3 },
								borderRadius: "8px"
							}}
						>
							<Typography sx={{ maxWidth: 550 }}>
								Use your GitHub to log in and simplify your workflow with
								one-click imports from your public and private repos.
							</Typography>

							<Button
								variant="outlined"
								onClick={() => {
									if (!username) {
										return setLoginModalOpen(true);
									}

									handleGitHubClick();
								}}
								sx={{
									py: 0,
									px: 2,
									height: 40,
									color: "inherit",
									borderColor: "inherit",
									borderRadius: "8px",
									boxShadow: 1,
									minWidth: 190
								}}
							>
								<Stack direction="row" alignItems="center" spacing={1}>
									<Icon icon="mdi:github" fontSize={26} />
									<Typography>Connect GitHub</Typography>
								</Stack>
							</Button>
						</Stack>
					)}
				</Stack>
			</Container>

			{!!githubUsername && <GitHubProjects />}
		</Stack>
	);
};

export default React.memo(ImportsTab);
