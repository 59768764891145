import { ContentCopy } from "@mui/icons-material";
import {
	Box,
	Button,
	Container,
	Divider,
	Stack,
	Typography
} from "@mui/material";
import ResetPrivateModeConfirmModal from "components/CustomModal/ResetPrivateModeConfirmModal";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import secureLocalStorage from "react-secure-storage";

const Privacy = () => {
	const [copied, setCopied] = useState(false);
	const [resetConfirmationOpen, setResetConfirmationOpen] = useState(false);

	return (
		<Container maxWidth="md" sx={{ textAlign: "left" }}>
			<Box
				sx={{
					width: "100%",
					borderRadius: "20px",
					bgcolor: "background.paper",
					p: 3
				}}
			>
				<Typography variant="h6">
					<strong>Private Mode</strong>
				</Typography>
				<Stack>
					<Typography sx={{ fontSize: "14px", mt: 0.5 }}>
						{!!secureLocalStorage.getItem("privateModeEncryptionKey")
							? "Copy your encryption key here and store it in a safe place. "
							: ""}
						If you have lost your key, you can reset private mode on your
						account.
					</Typography>

					<Divider sx={{ borderColor: "background.nav", mt: 1 }} />

					{!!secureLocalStorage.getItem("privateModeEncryptionKey") ? (
						<CopyToClipboard
							text={secureLocalStorage.getItem("privateModeEncryptionKey")}
							onCopy={() => {
								setCopied(true);
							}}
						>
							<Button
								variant="contained"
								fullWidth
								color="primary"
								disabled={copied}
								sx={{
									height: "40px",
									mx: "auto",
									mt: 2
								}}
							>
								<ContentCopy sx={{ fontSize: 18, mr: 1 }} />
								{copied ? "Copied" : "Copy Encryption Key"}
							</Button>
						</CopyToClipboard>
					) : (
						<></>
					)}

					<Button
						variant="contained"
						fullWidth
						color="error"
						sx={{
							height: "40px",
							mx: "auto",
							mt: 2
						}}
						onClick={() => {
							setResetConfirmationOpen(true);
						}}
					>
						Reset Private Mode
					</Button>
				</Stack>
			</Box>

			<ResetPrivateModeConfirmModal
				open={resetConfirmationOpen}
				onClose={() => setResetConfirmationOpen(false)}
			/>
		</Container>
	);
};

export default React.memo(Privacy);
