import { CssBaseline, ThemeProvider } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ErrorFallback, { clearAllCaches } from "components/ErrorFallback";
import ErrorToast from "components/NotificationToast/Error";
import InfoToast from "components/NotificationToast/Info";
import SuccessToast from "components/NotificationToast/Success";
import AppRoutes from "config/AppRoutes";
import lightTheme from "config/theme/light";
import { CTFContextProvider } from "context/CTFContext";
import { NotificationsContextProvider } from "context/NotificationsContext";
import { useAtom, useSetAtom } from "jotai";
import { SnackbarProvider } from "notistack";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import { themeModeAtom } from "store/atoms/UiAtoms";
import {
	getGithubClientId,
	getGoogleClientId,
	getMixpanelKey,
	getPrivacyHashIfExists
} from "./Resources/ServerInterface";
import {
	githubClientIdAtom,
	googleClientIdAtom,
	mixpanelAtom,
	userPrivacyHashAtom,
	usernameAtom
} from "./atoms";
import SnackbarCloseButton from "./components/buttons/SnackbarCloseButton";
import darkTheme from "./config/theme/dark";
import { AppContextProvider } from "./context/AppContext";
import { AuditToolContextProvider } from "./context/AuditToolContext";
import { LOCALSTORAGE_CACHE_VERSION } from "utils/constants";

export const cacheVersionShouldBe = "1.4"; //increment ONLY when we want all user cache to be cleared

const App = () => {
	const [mixpanel] = useAtom(mixpanelAtom);
	const [username] = useAtom(usernameAtom);
	const [googleClientId, setGoogleClientId] = useAtom(googleClientIdAtom);
	const [userPrivacyHash, setUserPrivacyHash] = useAtom(userPrivacyHashAtom);
	const setGithubClientId = useSetAtom(githubClientIdAtom);
	const [mixpanelInitialized, setMixpanelInitialized] = useState(false);
	const [themeMode] = useAtom(themeModeAtom);

	// Keep user cache up to date
	useEffect(() => {
		const clearCacheVal = localStorage.getItem(LOCALSTORAGE_CACHE_VERSION);

		if (clearCacheVal !== cacheVersionShouldBe) {
			clearAllCaches();
		}
	}, []);

	// Full story init
	useEffect(() => {
		getGoogleClientId().then((clientId) => {
			setGoogleClientId(clientId);
		});

		getGithubClientId().then((clientId) => {
			setGithubClientId(clientId);
		});
	}, [setGithubClientId, setGoogleClientId]);

	// Mixpanel init
	useEffect(() => {
		if (mixpanel) {
			getMixpanelKey().then((key) => {
				if (key && key !== "OK") {
					mixpanel.init(key);
					setMixpanelInitialized(true);
				}
			});
		}
	}, [mixpanel]);

	// Mixpanel identify
	useEffect(() => {
		if (username && mixpanelInitialized) {
			mixpanel.identify(username);
		}
	}, [username, mixpanel, mixpanelInitialized]);

	useEffect(() => {
		if (username) {
			getPrivacyHashIfExists().then((privacyHash) => {
				setUserPrivacyHash(privacyHash);
			});
		}
	}, [username]);

	return (
		<GoogleOAuthProvider clientId={googleClientId ?? "clientId"}>
			<ThemeProvider theme={themeMode === "dark" ? darkTheme : lightTheme}>
				<BrowserRouter>
					<AppContextProvider>
						<NotificationsContextProvider>
							<AuditToolContextProvider>
								<CTFContextProvider>
									<SnackbarProvider
										anchorOrigin={{ horizontal: "right", vertical: "top" }}
										maxSnack={3}
										Components={{
											error: ErrorToast,
											success: SuccessToast,
											info: InfoToast
										}}
										action={(key) => <SnackbarCloseButton snackbarKey={key} />}
									>
										<CssBaseline />
										<ErrorBoundary FallbackComponent={ErrorFallback}>
											<AppRoutes />
										</ErrorBoundary>
									</SnackbarProvider>
								</CTFContextProvider>
							</AuditToolContextProvider>
						</NotificationsContextProvider>
					</AppContextProvider>
				</BrowserRouter>
			</ThemeProvider>
		</GoogleOAuthProvider>
	);
};

export default App;
