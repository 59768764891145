import useSetAtom from "hooks/useSetAtom";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import {
	TerminalTabContentType,
	terminalFocusedTab,
	terminalOpenAtom,
	terminalTabsAtom
} from "store/atoms/terminalAtoms";

export function useTerminal() {
	const setTerminalOpen = useSetAtom(terminalOpenAtom);
	const setTerminalTabs = useSetAtom(terminalTabsAtom);
	const setFocusedTabIndex = useSetAtom(terminalFocusedTab);

	const focusedTabIndex = useAtomCallback(
		useCallback((get) => {
			const curr = get(terminalFocusedTab);
			return curr;
		}, [])
	);

	const terminalTabs = useAtomCallback(
		useCallback((get) => {
			const curr = get(terminalTabsAtom);
			return curr;
		}, [])
	);

	const openTerminal = () => {
		setTerminalOpen(true);
	};

	const closeTerminal = () => {
		setTerminalOpen(false);
	};

	const toggleTerminalOpen = () => {
		setTerminalOpen((old) => !old);
	};

	const openCustomTerminalTab = (
		name: string,
		path: string,
		content: TerminalTabContentType
	) => {
		openTerminal();

		const foundIndex = terminalTabs().findIndex((i) => i.path === path);

		if (foundIndex === -1) {
			setTerminalTabs((old) => {
				const newTabs = [...old, { name, path, content }];
				setFocusedTabIndex(newTabs.length - 1);

				return newTabs;
			});
		} else {
			setFocusedTabIndex(foundIndex);
		}
	};

	const closeTerminalTabIndex = (index: number) => {
		setTerminalTabs((old) => {
			const focusedIdx = focusedTabIndex();

			const updatedTabs = old.filter((_, i) => i !== index);

			// If 0 tabs open, close terminal
			if (!updatedTabs?.length) {
				setFocusedTabIndex(0);
				closeTerminal();
			} else {
				// If the closed tab was the focused tab, update the focusedTabIndex
				if (focusedIdx === index) {
					// Determine the index of the previous tab
					const newFocusedTabIndex = Math.max(index - 1, 0);
					setFocusedTabIndex(newFocusedTabIndex);
				} else if (index < focusedIdx) {
					// If the closed tab was before the focused tab, we need to adjust the focusedTabIndex
					setFocusedTabIndex((prevFocusedIndex) => prevFocusedIndex - 1);
				}
			}

			return updatedTabs;
		});
	};

	return {
		openTerminal,
		closeTerminal,
		toggleTerminalOpen,
		closeTerminalTabIndex,
		openCustomTerminalTab
	};
}
