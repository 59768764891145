import {
	Box,
	Grid,
	Skeleton,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import { githubUsernameAtom, usernameAtom } from "atoms";
import { useImportProject } from "hooks/data/useImportProject";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { gitReposAtom, isPrivateModeAtom } from "store/atoms/ImportsAtoms";
import { GitRepoType, getUserRepositories } from "utils/githubApi";
import RepositoryCard from "./RepositoryCard";
import { Icon } from "@iconify/react";
import hex from "utils/hexTransparency";

const GitHubProjects = () => {
	const theme = useTheme();

	const [gitReposLoading, setGitReposLoading] = useState(true);

	const { handleImportProject } = useImportProject();

	const [privateMode] = useAtom(isPrivateModeAtom);
	const [gitRepos, setGitRepos] = useAtom(gitReposAtom);
	const [githubUsername] = useAtom(githubUsernameAtom);
	const [username] = useAtom(usernameAtom);

	const handleGitImport = (githubUrl: string, commitSha: string) => {
		handleImportProject(null, null, githubUrl, null, privateMode, commitSha);
	};

	useEffect(() => {
		if (!gitRepos.length) {
			getUserRepositories()
				.then((repos: GitRepoType[]) => {
					setGitRepos(repos);
				})
				.finally(() => {
					setGitReposLoading(false);
				});
		} else {
			setGitReposLoading(false);
		}
	}, []);

	useEffect(() => {
		if (!username && gitRepos?.length) {
			setGitRepos([]);
		}
	}, [username, gitRepos]);

	if (gitReposLoading) {
		return (
			<Grid container spacing={2}>
				{Array(9)
					.fill("")
					?.map((_, i) => (
						<Grid item xs={12} sm={6} xl={4} key={i}>
							<Skeleton
								variant="rectangular"
								width={"100%"}
								height={"155px"}
								sx={{ borderRadius: "8px" }}
							/>
						</Grid>
					))}
			</Grid>
		);
	}

	if (!gitReposLoading && !gitRepos.length) {
		return (
			<Stack alignItems="center" justifyContent="center" sx={{ pt: 2 }}>
				<Typography variant="h6" sx={{ textAlign: "center" }}>
					No repos available.
				</Typography>
			</Stack>
		);
	}

	return (
		<Stack
			spacing={2}
			sx={{
				p: 2,
				border: "1px solid",
				borderColor: "divider",
				borderRadius: "8px",
				bgcolor: `${theme.palette.background.paper}${hex["90%"]}`
			}}
		>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				{/* Github  */}
				<Stack direction="row" alignItems="center" spacing={1}>
					<Icon icon="mdi:github" fontSize={26} />
					<Typography sx={{ fontWeight: 600 }}>GitHub</Typography>
				</Stack>

				{/* Connected as */}
				<Stack direction="row" alignItems="center" spacing={1}>
					<Icon icon="mingcute:github-fill" fontSize={22} />
					<Typography sx={{ "& span": { fontWeight: 600 } }}>
						Connected as <span>{githubUsername}</span>
					</Typography>
				</Stack>
			</Stack>

			<ResponsiveMasonry columnsCountBreakPoints={{ 1: 1, 600: 2, 1500: 3 }}>
				<Masonry gutter={"16px"}>
					{gitRepos?.map((repo, i) => (
						<Box sx={{ minHeight: 155, width: "100%" }} key={i}>
							<RepositoryCard
								repoName={repo.name}
								repoOwner={repo.owner}
								repoDescription={repo.description}
								repoId={repo.id}
								repoUrl={repo.htmlUrl}
								importRepo={handleGitImport}
							/>
						</Box>
					))}
				</Masonry>
			</ResponsiveMasonry>
		</Stack>
	);
};

export default React.memo(GitHubProjects);
