import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
	Button,
	ButtonProps,
	Stack,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import {
	WriteVulnContext,
	rewriteWithAI,
	writeGasOptimizationDescriptionWithAI,
	writeRecommendationWithAI,
	writeVulnerabilityDescriptionWithAI
} from "Resources/ServerInterface";
import { useEditorTabs } from "hooks/ui/useEditorTabs";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { FieldValues, Resolver, useForm } from "react-hook-form";
import { editorSelectionAtom } from "store/atoms/EditorAtoms";
import * as Yup from "yup";
import {
	SEVERITY_OPTIONS,
	riskLevelFromText,
	riskLevelToText
} from "../../Toolbox/Tools/AddFindingsTool/helpers";
import FormSelectField from "../../formFields/FormSelectField";
import FormTextField from "../../formFields/FormTextField";
import { openedTabAtom } from "store/atoms/UiAtoms";

export interface AddFindingsFormType {
	title: string;
	severity: number;
	location?: string;
	description: string;
	recommendation: string;
}

interface Props {
	handleSubmit: (data: AddFindingsFormType) => void;
	disabled?: boolean;
	edit?: boolean;
	values?: null | any;
}

const schema = Yup.object().shape({
	title: Yup.string().required("Title is required"),
	severity: Yup.number().required("Required"),
	// status: Yup.string().required("Required"),
	// location: Yup.string().required("Required"),
	description: Yup.string(),
	recommendation: Yup.string()
});

export const MagicAIButton: React.FC<ButtonProps & { text?; hideTooltip? }> = ({
	hideTooltip,
	text,
	...props
}) => {
	const theme = useTheme();

	return (
		<Tooltip
			title={
				<Typography variant="caption" sx={{ whiteSpace: "pre-line" }}>
					{"Use AI to generate from\nthe context you have provided"}
				</Typography>
			}
			disableHoverListener={!!hideTooltip}
		>
			<Button size="small" {...props}>
				<Stack direction="row" alignItems="center" spacing={0.7}>
					<Icon
						icon="mingcute:magic-hat-fill"
						fontSize={20}
						color={theme.palette.warning.main}
					/>

					<Typography sx={{ color: "warning.main", fontWeight: 600 }}>
						{text}
					</Typography>
				</Stack>
			</Button>
		</Tooltip>
	);
};

const AIButton: React.FC<ButtonProps> = (props) => (
	<Tooltip
		title={
			<Typography variant="caption" sx={{ whiteSpace: "pre-line" }}>
				{"Use AI to rewrite contents\nin a concise & professional manner"}
			</Typography>
		}
	>
		<span>
			<Button size="small" {...props}>
				<Stack direction="row" alignItems="center" spacing={0.7}>
					<Icon icon="mdi:wand" fontSize={20} />

					<Typography sx={{ fontWeight: 600, pt: "1px" }}>
						Rewrite with AI
					</Typography>
				</Stack>
			</Button>
		</span>
	</Tooltip>
);

const AddFindingsForm: React.FC<Props> = ({
	handleSubmit: propsHandleSubmit,
	disabled: PropsDisabled = false,
	edit,
	values = {}
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [disabled, setDisabled] = useState(PropsDisabled);
	const [startLocation, setStartLocation] = useState(null);
	const [endLocation, setEndLocation] = useState(null);

	const [{ openedFile, projectFilePath }] = useAtom(openedTabAtom);

	const [editorSelection] = useAtom(editorSelectionAtom);

	const formOptions = {
		resolver: yupResolver(schema) as Resolver<AddFindingsFormType, FieldValues>,
		defaultValues: { severity: riskLevelFromText("Low"), ...values }
	};

	const {
		handleSubmit,
		register,
		control,
		setValue,
		getValues,
		watch,
		formState: { errors }
	} = useForm<FieldValues & AddFindingsFormType & any>(formOptions);

	useEffect(() => {
		setDisabled(PropsDisabled);
	}, [PropsDisabled]);

	useEffect(() => {
		if (editorSelection.startLineNumber && projectFilePath) {
			const _line =
				editorSelection.startLineNumber === editorSelection.endLineNumber
					? `Line: ${editorSelection.startLineNumber}`
					: `Lines: ${editorSelection.startLineNumber} - ${editorSelection.endLineNumber}`;

			setValue("location", `File Path: ${projectFilePath}\n${_line}`);
			setStartLocation(editorSelection.startLineNumber);
			setEndLocation(editorSelection.endLineNumber);
		} else {
			setEndLocation(null);
			setStartLocation(null);
			setValue("location", edit ? values?.location || "" : "");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editorSelection, projectFilePath]);

	useEffect(() => {
		const _keys = Object.keys(values);

		if (_keys.length) {
			_keys.map((_key) => {
				return setValue(_key, values[_key]);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values, setValue]);

	useEffect(() => {
		return () => {
			if (edit) {
				resetAll();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edit]);

	const descriptionField = watch("description");
	const recommendationField = watch("recommendation");
	const titleField = watch("title");
	const locationField = watch("location");

	const onSubmit = (data: AddFindingsFormType) => {
		propsHandleSubmit?.(data);

		resetAll();
	};

	const resetAll = () => {
		setValue("title", "");
		setValue("description", "");
		setValue("recommendation", "");
		setValue("location", "");
		setValue("severity", riskLevelFromText("Low"));
		setStartLocation(null);
		setEndLocation(null);
	};

	const handleWriteDescriptionWithAi = async () => {
		setDisabled(true);

		const writeVulnContext: WriteVulnContext = {
			code: openedFile.text,
			lineStart: startLocation,
			lineEnd: endLocation,
			vulnerabilityTitle: getValues().title
		};
		if (riskLevelToText(getValues().severity) === "Gas Optimization") {
			writeGasOptimizationDescriptionWithAI(writeVulnContext, (text, done) => {
				setValue("description", text);
				if (done) {
					setDisabled(false);
				}
			});
		} else {
			writeVulnerabilityDescriptionWithAI(writeVulnContext, (text, done) => {
				setValue("description", text);
				if (done) {
					setDisabled(false);
				}
			});
		}
	};

	const handleWriteRecommendationWithAI = async () => {
		setDisabled(true);

		const writeVulnContext: WriteVulnContext = {
			code: openedFile.text,
			lineStart: startLocation,
			lineEnd: endLocation,
			vulnerabilityTitle: getValues().title,
			vulnerabilityDescription: getValues().description
		};
		writeRecommendationWithAI(writeVulnContext, (text, done) => {
			setValue("recommendation", text);
			if (done) {
				setDisabled(false);
			}
		});
	};

	const handleDescriptionAiClick = async () => {
		setDisabled(true);
		const newValue = await rewriteWithAI(descriptionField);

		setValue("description", newValue);

		setDisabled(false);
	};

	const handleRecommendationAiClick = async () => {
		setDisabled(true);

		const newValue = await rewriteWithAI(recommendationField);

		setValue("recommendation", newValue);

		setDisabled(false);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
			<Stack spacing={3}>
				{/* Title */}
				<Stack spacing={1}>
					<Typography sx={{ fontWeight: 500 }}>Findings Title</Typography>

					<FormTextField
						placeholder="Title"
						fullWidth
						disabled={disabled}
						required
						name="title"
						register={register}
						error={!!errors["title"]}
						helperText={errors["title"]?.message}
					/>
				</Stack>

				<Stack spacing={1}>
					<Typography sx={{ fontWeight: 500 }}>Severity</Typography>

					<FormSelectField
						fullWidth
						disabled={disabled}
						required
						name="severity"
						control={control}
						error={!!errors["severity"]}
						helperText={
							errors["severity"]?.message && String(errors["severity"]?.message)
						}
						defaultValue={values?.severity || riskLevelFromText("Low")}
						items={SEVERITY_OPTIONS.map((i) => ({
							name: i.name,
							value: i.value,
							Icon: <i.icon fontSize="small" sx={{ color: i.color }} />
						}))}
					/>
				</Stack>

				{/* Location */}
				<Stack spacing={1}>
					<Typography sx={{ fontWeight: 500 }}>Location</Typography>

					<Stack
						alignItems="center"
						justifyContent="center"
						sx={{
							p: 2,
							bgcolor: "background.default",
							borderRadius: "8px",
							minHeight: 80,
							boxShadow: 1
						}}
					>
						{locationField ? (
							<Typography
								sx={{ whiteSpace: "pre-line", wordBreak: "break-word" }}
							>
								{locationField}
							</Typography>
						) : (
							<Typography color="text.disabled" sx={{ textAlign: "center" }}>
								Highlight code to select a location
							</Typography>
						)}
					</Stack>

					{/* <FormTextField
						placeholder="Where is this finding referring to?"
						fullWidth
						disabled
						required
						name="location"
						register={register}
						error={!!errors["location"]}
						helperText={errors["location"]?.message}
						multiline
						rows={3}
						inputSx={{ wordBreak: "break-word" }}
					/> */}
				</Stack>

				{/* Description */}
				<Stack spacing={1}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={1}
					>
						<Typography sx={{ fontWeight: 500 }}>Description</Typography>

						{getValues().location &&
							startLocation &&
							endLocation &&
							getValues().title &&
							!getValues().description && (
								<MagicAIButton
									onClick={handleWriteDescriptionWithAi}
									disabled={disabled}
								/>
							)}
						{getValues().description && (
							<AIButton
								onClick={handleDescriptionAiClick}
								disabled={disabled}
							/>
						)}
					</Stack>

					<FormTextField
						placeholder="Describe your finding with as much detail as possible"
						fullWidth
						disabled={disabled}
						required
						name="description"
						register={register}
						error={!!errors["description"]}
						helperText={errors["description"]?.message}
						multiline
						rows={8}
					/>
				</Stack>

				{/* Recommendation */}
				<Stack spacing={1}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={1}
					>
						<Typography sx={{ fontWeight: 500 }}>Recommendation</Typography>

						{startLocation &&
							endLocation &&
							!!locationField &&
							!!titleField &&
							!!descriptionField &&
							!recommendationField && (
								<MagicAIButton
									onClick={handleWriteRecommendationWithAI}
									disabled={disabled}
								/>
							)}

						{recommendationField && (
							<AIButton
								onClick={handleRecommendationAiClick}
								disabled={disabled}
							/>
						)}
					</Stack>

					<FormTextField
						placeholder="What actions would you suggest taking to fix this?"
						fullWidth
						disabled={disabled}
						required
						name="recommendation"
						register={register}
						error={!!errors["recommendation"]}
						helperText={errors["recommendation"]?.message}
						multiline
						rows={6}
					/>
				</Stack>

				<LoadingButton
					type="submit"
					variant="contained"
					disabled={disabled}
					loading={disabled}
					sx={{
						borderRadius: "8px",
						height: 39,
						...(themeMode === "light" && {
							color: "text.primary",
							bgcolor: "background.paper",
							"&:hover": {
								bgcolor: "background.paper2"
							}
						})
					}}
				>
					{edit ? "Edit Finding" : "Add Finding"}
				</LoadingButton>
			</Stack>
		</form>
	);
};

export default AddFindingsForm;
