import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Paper,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import { useStorageSlots } from "hooks/data/useStorageSlots";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { openedTabAtom } from "store/atoms/UiAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import { storageSlotAtoms } from "store/atoms/storageSlotAtoms";
import hex from "utils/hexTransparency";

import {
	SlitherErrorItem,
	parseSlitherErrorData
} from "../CodeScanTool/ScanToolItem/ImplementedScanTool";
import { stringToColorHash } from "./helpers";

const StorageSlots = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [expanded, setExpanded] = useState(true);

	const [storageSlots] = useAtom(storageSlotAtoms);

	const { errors, slotsLoading, loadStorageSlots } = useStorageSlots();

	const [{ projectFilePath }] = useAtom(openedTabAtom);

	const [project] = useAtom(projectAtom);

	const parsedErrors =
		typeof errors === "object" ? parseSlitherErrorData(errors) : null;

	useEffect(() => {
		if (projectFilePath) {
			loadStorageSlots(projectFilePath, project.id);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectFilePath, project]);

	const LoadingComponent = () => (
		<Box sx={{ overflow: "hidden" }}>
			<Stack
				spacing={1.5}
				sx={{
					border: `1px solid ${theme.palette.background["paper2"]}`,
					borderRadius: "8px",
					boxShadow: 1,
					p: 2
				}}
			>
				{Array(7)
					.fill(7)
					.map((_, i) => (
						<Stack key={i} direction="row" spacing={1.5} alignItems={"center"}>
							<Skeleton
								variant="rectangular"
								width={150}
								height={28}
								sx={{ borderRadius: "6px" }}
							/>

							<Skeleton
								variant="rectangular"
								width={"100%"}
								height={28}
								sx={{ borderRadius: "6px" }}
							/>
						</Stack>
					))}
			</Stack>
		</Box>
	);

	const ErrorComponent = () => {
		if (typeof errors === "object") {
			return (
				<Stack
					spacing={1.5}
					sx={{
						maxHeight: 500,
						overflow: "auto"
					}}
				>
					{Object.keys(parsedErrors).map((_key, i) => (
						<SlitherErrorItem
							key={i}
							title={_key}
							description={parsedErrors[_key]?.description}
							version={parsedErrors[_key]?.version}
							sx={{
								border: `1px solid ${theme.palette.warning.main}`,
								bgcolor: `${theme.palette.warning.main}${hex["7%"]}`,
								boxShadow: `${theme.palette.warning.main} 0px 0px 6px`,
								width: "98%",
								borderRadius: "8px",
								alignSelf: "center"
							}}
						/>
					))}
				</Stack>
			);
		}

		return (
			<Stack
				spacing={1.5}
				sx={{
					border: `1px solid ${theme.palette.warning.main}`,
					bgcolor: `${theme.palette.warning.main}${hex["7%"]}`,
					boxShadow: `${theme.palette.warning.main} 0px 0px 6px`,
					width: "98%",
					borderRadius: "8px",
					alignSelf: "center",
					// boxShadow: 1,
					p: 2,
					maxHeight: 250,
					overflow: "auto",
					whiteSpace: "pre-line"
				}}
			>
				<Typography sx={{ wordBreak: "break-all" }}>{errors}</Typography>
			</Stack>
		);
	};

	return (
		<Accordion
			expanded={expanded}
			onChange={(e, v) => setExpanded(v)}
			disableGutters
			sx={{
				width: "100%",
				borderRadius: "8px !important",
				backgroundImage: "none",
				bgcolor: "background.default",
				"&.MuiAccordion-root:before": {
					bgcolor: "transparent"
				}
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 1.5 }}>
				Storage Slots
			</AccordionSummary>

			<AccordionDetails sx={{ px: 1.5, pt: 0 }}>
				{slotsLoading ? (
					<LoadingComponent />
				) : !!errors ? (
					<ErrorComponent />
				) : (
					<Stack spacing={1}>
						<Box sx={{ flex: 1, height: "100%", overflow: "auto" }}>
							<TableContainer
								component={Paper}
								elevation={0}
								sx={{
									// bgcolor: "transparent",
									border: `1px solid ${theme.palette.background["paper2"]}`,
									borderRadius: "8px",
									boxShadow: 1
								}}
							>
								<Table sx={{ minWidth: 300 }}>
									<TableBody>
										{Object.keys(storageSlots)
											// .slice(0, 3)
											.map((slot, i) => (
												<TableRow
													key={i}
													sx={{ "&:last-child td": { borderBottom: 0 } }}
												>
													<TableCell
														align="center"
														sx={{
															py: 1,
															px: 1,
															maxWidth: 100,
															minWidth: 75,
															borderRight: `1px solid ${theme.palette.background["paper2"]} !important`,
															borderBottom: `1px solid ${theme.palette.background["paper2"]}`
														}}
													>
														<Typography
															sx={{
																width: "100%",
																textAlign: "center",
																whiteSpace: "nowrap",
																overflow: "hidden",
																textOverflow: "ellipsis",
																fontWeight: 500
															}}
														>
															Slot{slot}
														</Typography>
													</TableCell>

													<TableCell
														sx={{
															p: 0.5,
															borderBottom: `1px solid ${theme.palette.background["paper2"]}`
														}}
													>
														<Stack
															direction="row"
															alignItems="center"
															spacing={0.5}
														>
															{storageSlots[slot]?.map?.((item, j) => {
																const varWidth =
																	((item.size || 256) / 256) * 100;

																return (
																	<Tooltip
																		enterNextDelay={600}
																		key={`${i}${j}`}
																		title={
																			<Stack>
																				<Typography variant="body2">
																					Var: {item.name}
																				</Typography>

																				<Typography variant="body2">
																					Slot size: {item.size}
																				</Typography>
																			</Stack>
																		}
																	>
																		<Box
																			sx={{
																				bgcolor: stringToColorHash(item.name),
																				borderRadius: "6px",
																				height: "100%",
																				px: 0.5,
																				py: 0.5,
																				textAlign: "center",
																				width: `${varWidth}%`
																			}}
																		>
																			<Typography
																				variant="body2"
																				sx={{
																					whiteSpace: "nowrap",
																					overflow: "hidden",
																					textOverflow: "ellipsis",
																					fontWeight: 500
																				}}
																			>
																				{item.name}
																			</Typography>
																		</Box>
																	</Tooltip>
																);
															})}
														</Stack>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					</Stack>
				)}
			</AccordionDetails>
		</Accordion>
	);
};

export default React.memo(StorageSlots);
