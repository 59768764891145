import {
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import { ToolboxCloseButton } from "components/Toolbox";
import React, { useState } from "react";
import ThisProject from "./ThisProjectTab";
import LibraryTab from "./LibraryTab";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
	borderRadius: "8px",
	...(theme.palette.mode === "light" && {
		border: 0,
		color: `${theme.palette.text.primary}!important`
	}),
	"&.Mui-selected, &.Mui-selected:hover": {
		backgroundColor: theme.palette.background["selected"],
		...(theme.palette.mode === "light" && {
			color: `${theme.palette.text.primary}!important`
		})
	}
}));

const ChecklistTool = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [toggleValue, setToggleValue] = useState(0);

	const Header: React.FC<{ label?: string }> = ({ label = "Checklist" }) => (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{ mb: 2 }}
		>
			<Typography variant="h6">{label}</Typography>

			<ToolboxCloseButton />
		</Stack>
	);

	return (
		<Stack>
			<Header />

			{/* Toggle buttons */}
			<ToggleButtonGroup
				size="small"
				color="primary"
				value={toggleValue}
				exclusive
				onChange={(e, v) => {
					if (v !== null) setToggleValue(v);
				}}
				fullWidth
				sx={{
					mb: 2,
					height: 40,
					borderRadius: "8px",
					bgcolor: "background.default",
					...(themeMode === "light" && {
						bgcolor: "background.paper",
						boxShadow: 1
					})
				}}
			>
				<StyledToggleButton value={0}>This project</StyledToggleButton>

				<StyledToggleButton value={1}>Library</StyledToggleButton>
			</ToggleButtonGroup>

			{toggleValue === 0 && <ThisProject />}

			{toggleValue === 1 && <LibraryTab />}
		</Stack>
	);
};

export default ChecklistTool;
