import { Theme } from "@mui/material";
import {
	lightBlue,
	lightGreen,
	orange,
	purple,
	red,
	yellow
} from "@mui/material/colors";
import { SxProps } from "@mui/system";
import BookmarkIcon from "assets/icons/bookmark.png";
import hex from "utils/hexTransparency";

export const findingStyles = (theme: Theme) => {
	const styles: SxProps = {
		"& .editor-findings-widget": {
			bgcolor: `${theme.palette.primary.dark}${hex["5%"]}`,
			"&.severity-0": {
				bgcolor: `${orange["500"]}${hex["5%"]}`
			},
			"&.severity-1": {
				bgcolor: `${yellow["500"]}${hex["5%"]}`
			},
			"&.severity-2": {
				bgcolor: `${lightGreen["500"]}${hex["5%"]}`
			},
			"&.severity-3": {
				bgcolor: `${lightBlue["500"]}${hex["5%"]}`
			},
			"&.severity-4": {
				bgcolor: `${purple["500"]}${hex["5%"]}`
			},
			"&.severity-5": {
				bgcolor: `${red["500"]}${hex["5%"]}`
			}
		}
	};

	return styles;
};

export const findingsColor = (theme: Theme, _color) => {
	switch (_color) {
		// case "text-disabled":
		// 	return `${theme.palette.text.disabled}${hex["30%"]}`;
		// case "info-light":
		// 	return `${theme.palette.info.main}${hex["30%"]}`;
		// case "success-light":
		// 	return `${theme.palette.success.main}${hex["30%"]}`;
		// case "warning-light":
		// 	return `${theme.palette.warning.main}${hex["30%"]}`;
		// case "error-light":
		// 	return `${theme.palette.error.main}${hex["30%"]}`;
		// case "primary-main":
		// 	return `${theme.palette.primary.main}${hex["30%"]}`;

		default:
			return `${_color}${hex["30%"]}`;
	}
};
