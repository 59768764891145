import { Dialog, DialogProps, IconButton, SxProps } from "@mui/material";
import React from "react";
import hex from "../../utils/hexTransparency";
import CloseIcon from "@mui/icons-material/Close";
import proFireworksBg from "../../assets/images/PremiumFireworks.png";

interface CustomDialogProps {
	open: boolean;
	onClose: () => void;
	children: React.ReactNode;
	paperSx?: SxProps;
	hideCloseButton?: boolean;
	closeButtonSx?: SxProps;
	isProModal?: boolean;
}

const CustomModal: React.FC<CustomDialogProps & DialogProps> = ({
	open,
	onClose,
	children,
	paperSx = {},
	PaperProps = {},
	hideCloseButton,
	closeButtonSx = {},
	isProModal = false,
	...props
}) => {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			sx={{
				"& .MuiBackdrop-root": {
					backgroundColor: `#36325E${hex["60%"]}`,
					backdropFilter: "blur(1.5px)"
				}
			}}
			PaperProps={{
				sx: {
					position: "relative",
					backgroundColor: "backgorund.paper",
					p: 5,
					borderRadius: "8px",
					backgroundImage: "none",
					background: isProModal ? `url(${proFireworksBg})` : undefined,
					backgroundSize: isProModal ? "cover" : undefined,
					backgroundPosition: isProModal ? "center" : undefined,
					boxShadow: 1,
					...paperSx
				},
				...PaperProps
			}}
			{...props}
		>
			{!hideCloseButton && (
				<IconButton
					size="small"
					onClick={onClose}
					sx={{
						position: "absolute",
						top: "12px",
						right: "12px",
						...closeButtonSx
					}}
				>
					<CloseIcon fontSize="small" />
				</IconButton>
			)}

			{children}
		</Dialog>
	);
};

export default CustomModal;
