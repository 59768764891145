import {
	Button,
	ButtonProps,
	Stack,
	Tooltip,
	Typography,
	tooltipClasses,
	useTheme
} from "@mui/material";
import React from "react";
import hex from "utils/hexTransparency";

const CustomButton: React.FC<
	ButtonProps & {
		Icon: React.ReactNode;
		text?: string;
		placement?: "start" | "end";
		selected?: boolean;
		tooltip?: string;
	}
> = ({
	sx = {},
	variant = "outlined",
	Icon,
	text,
	placement = "start",
	selected,
	tooltip = "",
	...props
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	return (
		<Tooltip
			title={tooltip}
			slotProps={{
				tooltip: {
					sx: {
						[`&.${tooltipClasses.tooltip}`]: {
							border: `1px solid ${theme.palette.primary.dark}`,
							borderRadius: "8px",
							backgroundColor: theme.palette.background.paper
						}
					}
				}
			}}
		>
			<Button
				variant={variant}
				sx={{
					minWidth: 0,
					height: { xs: 34, md: 36, xl: 37 },
					px: { xs: 1.2, md: 1.8 },
					color: "text.primary",
					borderRadius: "8px",
					borderWidth: "1px",
					...(themeMode === "dark" && {
						bgcolor: selected ? "background.paper" : "transparent",
						borderColor: !selected
							? `${theme.palette.primary.dark}${hex["60%"]}`
							: "primary.main"
					}),
					...(themeMode === "light" && {
						bgcolor: selected ? "background.selected" : "common.white",
						borderWidth: 1,
						borderColor: "transparent!important",
						boxShadow: 1
					}),

					...sx
				}}
				{...props}
			>
				<Stack direction="row" alignItems="center" spacing={0.6}>
					{placement === "start" && Icon}
					{text && (
						<Typography sx={{ whiteSpace: "nowrap" }}>{text}</Typography>
					)}
					{placement === "end" && Icon}
				</Stack>
			</Button>
		</Tooltip>
	);
};

export default CustomButton;
