import GitHubIcon from "@mui/icons-material/GitHub";
import {
	Box,
	Button,
	Container,
	Paper,
	Stack,
	Typography,
	styled
} from "@mui/material";
import { getUserHasGithubInstallation, linkWithGithub } from "Resources/ServerInterface";
import WizardPfpDark from "assets/images/wizardPfpDark.png";
import {
	githubInstallationIdAtom,
	githubUsernameAtom
} from "atoms";
import { useAtom, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { LOCALSTORAGE_GITHUB_CONNECTION } from "utils/constants";
import { redirectToGitHubForAuth, redirectToGitHubForInstallation } from "utils/helpersFunctions";

const StyledPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(3),
	borderRadius: "20px",
	marginBottom: theme.spacing(2),
	boxShadow: theme.shadows[1]
}));

const CustomButton = styled(Button)({
	padding: 0,
	borderRadius: 8, // Adjust the border-radius as per your preference
	textTransform: "none",
	fontWeight: "bold", // Adjust the font-weight as needed
	backgroundColor: "inherit",
	display: "flex",
	justifyContent: "start",
	height: "38px"
});

const CustomIconWrapper = styled("div")({
	display: "inline-flex",
	alignItems: "center",
	justifyContent: "center",
	backgroundColor: "#2e3b8b", // Darker part for icon
	padding: "6px 40px",
	borderTopLeftRadius: "8px",
	borderBottomLeftRadius: "8px",
	height: "38px"
});

const CustomLabelWrapper = styled("div")({
	backgroundColor: "#6157da", // Lighter part for text
	padding: "6px 16px", // Adjust padding to match the design
	color: "#ffffff", // Text color
	marginLeft: "-4px", // Negative margin to make the icon and text parts seamless
	flexGrow: 1,
	borderTopRightRadius: "8px",
	borderBottomRightRadius: "8px",
	height: "38px"
});

const LoginPassword = () => {
	const [githubInstallationId, setGithubInstallationId] = useAtom(githubInstallationIdAtom);
	const [loading, setLoading] = useState(false);
	const [githubUsername, setGithubUsername] = useAtom(githubUsernameAtom);
	const [githubImage, setGithubImage] = useState("");

	const handleGitHubClick = () => {
		if (!githubUsername) {
			localStorage.setItem("linkingGithub", "true");
			getUserHasGithubInstallation().then((hasInstallation) => {
				if (hasInstallation) {
					linkWithGithub().then((githubData) => {
						setLoading(false);
						setGithubUsername(githubData.githubUsername);
						setGithubImage(githubData.githubImage);
						setGithubInstallationId(null);
						secureLocalStorage.setItem(
							"personalAccessToken",
							githubData.accessToken
						);
					})
				} else {
					redirectToGitHubForInstallation();
				}
			})
		}
	};

	useEffect(() => {
		if (githubInstallationId) {
			setLoading(true);
			linkWithGithub(githubInstallationId).then((githubData) => {
				setLoading(false);
				setGithubUsername(githubData.githubUsername);
				setGithubImage(githubData.githubImage);
				setGithubInstallationId(null);
				secureLocalStorage.setItem(
					"personalAccessToken",
					githubData.accessToken
				);
			});
		}
	}, [githubInstallationId]);

	useEffect(() => {
		const githubConnection = localStorage.getItem(
			LOCALSTORAGE_GITHUB_CONNECTION
		);
		if (!!githubConnection) {
			const githubConnectionJson = JSON.parse(githubConnection);
			setGithubUsername(githubConnectionJson.githubUsername);
			setGithubImage(githubConnectionJson.githubImage);
		}
	}, []);

	// const handleChangeEmailClick = (data) => {
	// 	console.log(data);
	// };

	// const handleChangePasswordClick = (data) => {
	// 	console.log(data);
	// };

	return (
		<Box sx={{ height: "100%", overflow: "auto" }}>
			<Container maxWidth="sm">
				{/* Email */}
				{/* <StyledPaper elevation={0}>
					<Stack spacing={2}>
						<Typography variant="h6">Email</Typography>

						<Typography>{username}</Typography>

						<SettingsChangeEmailForm handleSubmit={handleChangeEmailClick} />
					</Stack>
				</StyledPaper> */}

				{/* Password */}
				{/* <StyledPaper elevation={0}>
					<Stack spacing={2}>
						<Typography variant="h6">Password</Typography>

						<Typography>
							Make sure it's at least 15 characters OR at least 8 characters
							including a number and a lowercase letter.
						</Typography>

						<SettingsChangePasswordForm
							handleSubmit={handleChangePasswordClick}
						/>
					</Stack>
				</StyledPaper> */}

				{/* Github */}
				<StyledPaper elevation={0}>
					<Stack spacing={2}>
						<Stack
							direction={{ xs: "column", md: "row" }}
							alignItems="center"
							justifyContent="space-between"
						>
							<Stack direction="row" alignItems="center" spacing={1}>
								<GitHubIcon sx={{ fontSize: 26 }} />

								<Typography variant="h6">GitHub</Typography>
							</Stack>

							{!!githubUsername && (
								<Stack direction="row" alignItems="center" spacing={1}>
									<Box
										component="img"
										src={githubImage ? githubImage : WizardPfpDark}
										sx={{
											height: 26,
											objectFit: "contain",
											borderRadius: "8px"
										}}
									/>

									<Typography>
										Connected as{" "}
										<Typography component="span" sx={{ fontWeight: 600 }}>
											{githubUsername}
										</Typography>
									</Typography>
								</Stack>
							)}
						</Stack>

						<Typography>
							Use your GitHub to log in and simplify your workflow with
							one-click imports from your public and private repos.
						</Typography>

						{!githubUsername && (
							<CustomButton
								variant="contained"
								disabled={loading}
								onClick={handleGitHubClick}
							>
								<CustomIconWrapper>
									<GitHubIcon style={{ color: "#ffffff" }} />
								</CustomIconWrapper>
								<CustomLabelWrapper>
									{loading ? "Loading..." : "Connect GitHub"}
								</CustomLabelWrapper>
							</CustomButton>
						)}
					</Stack>
				</StyledPaper>
			</Container>
		</Box>
	);
};

export default LoginPassword;
