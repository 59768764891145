import { ProjectsType } from "store/atoms/projectToolAtoms";

export const DEFAULT_FOLDER_NAME = "DEFAULT_FOLDER";

export interface GroupedProjects {
	[folderName: string]: ProjectsType[];
}

export const buildProjectsArray = (
	projects: ProjectsType[]
): GroupedProjects => {
	// Initialize an object to hold the grouped items
	const grouped = {};

	// Iterate over each item in the input array
	projects.forEach((item) => {
		// Determine the folder name or default to "DEFAULT"
		const folderName = item.folder?.name || DEFAULT_FOLDER_NAME;

		// Initialize the folder array if it doesn't exist
		if (!grouped[folderName]) {
			grouped[folderName] = [];
		}

		// Add the current item to the appropriate folder
		grouped[folderName].push(item);
	});

	// Convert the grouped object to an array of entries and sort alphabetically by folder name
	const sortedEntries = Object.entries(grouped).sort((a, b) => {
		if (a[0] === DEFAULT_FOLDER_NAME) return 1; // "DEFAULT" should be last
		if (b[0] === DEFAULT_FOLDER_NAME) return -1;
		return a[0].localeCompare(b[0]);
	});

	// Convert the sorted entries back to an object
	const sortedGrouped = {};
	sortedEntries.forEach(([key, value]) => {
		sortedGrouped[key] = value;
	});

	return sortedGrouped;
};

export const addNewFolderToObject = (folderName, oldObj) => {
	const defaultFolder = oldObj[DEFAULT_FOLDER_NAME];

	return { ...oldObj, [folderName]: [], [DEFAULT_FOLDER_NAME]: defaultFolder };
};
