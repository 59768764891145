import { SimulationFormTypeWithParamTypes } from "components/forms/SimulationForm";
import { addErrorNotification } from "./Notifications";
import { fetch401Interceptor, getCsrfToken } from "./ServerInterface";
import { ethers } from "ethers";
import { singleSimulationRanEvent } from "Mixpanel/mixpanel.helper";

export interface Simulation {
    _id: string;
	projectID: string;
    network: string;
    from: string;
    to: string;
    gas?: string;
    value?: string;
    functionName: string;
    functionParams: string[],
    blockNumber?: number;
    simulationId: string;
    status: boolean;
    errorMessage?: string;
    gasUsed: number;
    stateDiff?: object;
    formattedStateDiff?: string;
    assetChanges?: object;
    formattedAssetChanges?: string;
    decodedOutput?: object;
    callTrace: object[],
    stackTrace?: object[],
    createdAt?: string,
}

const SERVER_URL =
	window.location.protocol +
	"//" +
	window.location.hostname +
	":" +
	(process.env.REACT_APP_SERVER_PORT || "5000");


export async function getSimulations(projectID: string): Promise<Simulation[]> {
    try {
        const url =
            SERVER_URL +
            `/tenderly/simulations/${projectID}?` +
            new URLSearchParams({ csrfToken: await getCsrfToken() });

        const res = await fetch401Interceptor(url, { credentials: "include" });

        if (res.ok) {
            const resJson: Simulation[] = await res.json();
            return resJson.reverse();
        } else {
            const resError = await res.text();
            throw new Error(resError);
        }
    } catch (e) {
        addErrorNotification("Error", "Error loading transaction simulations: " + e);
        return [];
    }
}

function stringToBoolean(input: string): boolean {
    // Normalize the input to lowercase for consistent comparison
    const normalizedInput = input.toLowerCase().trim();

    // Define which normalized inputs should return true
    const truthyValues = ["true", "1"];
    const falsyValues = ["false", "0"];

    // Check and return true or false accordingly
    if (truthyValues.includes(normalizedInput)) {
        return true;
    } else if (falsyValues.includes(normalizedInput) || Number(normalizedInput) < 0) {
        return false;
    }

    // Return true for any string not explicitly considered false
    return true;
}

const stringToBytes32 = (str: string): string => {
    return ethers.utils.formatBytes32String(str);
};

export async function runSimulationAPI(simulationData: SimulationFormTypeWithParamTypes, projectID: string, projectFileID: string, mixpanel: any): Promise<void> {
    try {
        const url =
            SERVER_URL +
            `/tenderly/simulateSingleTx/${projectID}/${projectFileID}?` +
            new URLSearchParams({ csrfToken: await getCsrfToken() });

        const filteredParams = simulationData.params.filter((param) => param !== null);

        const typedParams = filteredParams.map((param: string, idx) => {
            if (!simulationData.paramTypes[idx]) {
                try {
                    const jsonEncoded = JSON.parse(param);
                    return jsonEncoded;
                } catch (e) {
                    return param;
                }
            }

            if (simulationData.paramTypes[idx].includes('int')) {
                return parseInt(param);
            } else if (simulationData.paramTypes[idx].includes('bool')) {
                return stringToBoolean(param);
            } else {
                return param;
            }
        })

        const simulationDataWithTypedParams = { ...simulationData, params: typedParams };

        const res = await fetch401Interceptor(url, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(simulationDataWithTypedParams)
        });

        if (!res.ok) throw new Error(await res.text());

        singleSimulationRanEvent(mixpanel, {projectID, projectFileID, simulationData});
    } catch (e) {
        const errorMessage = e instanceof Error ? e.message : JSON.stringify(e);
        try {
            const eMessageParsed = JSON.parse(errorMessage);
            addErrorNotification("Error", "Error running transaction simulation: " + eMessageParsed.message);
        } catch (e) {
            addErrorNotification("Error", "Error running transaction simulation: " + errorMessage);
        } finally {
            throw e;
        }
    }
}