import { Icon } from "@iconify/react";
import { useTools } from "hooks/ui/useTools";
import { useAtom } from "jotai";
import React from "react";
import { selectedToolAtom } from "store/atoms/UiAtoms";
import CustomButton from "./CustomButton";

const NotesButton = () => {
	const { selectTool } = useTools();

	const [selectedTool] = useAtom(selectedToolAtom);

	return (
		<CustomButton
			selected={selectedTool === "Notepad"}
			tooltip="Notepad"
			onClick={() => {
				if (selectedTool === "Notepad") selectTool(null);
				else selectTool("Notepad");
			}}
			Icon={<Icon icon="solar:document-add-bold" fontSize={21} />}
		/>
	);
};

export default React.memo(NotesButton);
