import sanitizeHtml from "sanitize-html";

const ALLOWED_TAGS = [
    "b",
    "i",
    "em",
    "strong",
    "a",
    "p",
    "pre",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "ul",
    "ol",
    "li",
    "blockquote",
    "code",
    "img"
];
const ALLOWED_ATTRIBUTES = {
    a: ["href", "target"],
    img: ["src", "alt", "width", "height"],
    "*": ["class", "style"]
};

const cache = new Map<string, string>();

export default function renderMarkdown(value): Promise<string> {
    return new Promise((resolve, error) => {
        if (cache.has(value))
            resolve(cache.get(value));
        else
            fetch("https://api.github.com/markdown", {
                method: "POST",
                headers: {
                    Accept: "application/vnd.github+json",
                    // Authorization: "Bearer <YOUR-TOKEN>",
                    "X-GitHub-Api-Version": "2022-11-28"
                },
                body: JSON.stringify({text: value})
            })
                .then((response) => {
                    if (response.ok) return response.text();
                    else error("Rate limit exceeded");
                })
                .then((res) => {
                    cache.set(value, res);
                    resolve(
                        sanitizeHtml(
                            res?.replace?.(/href/g, "target='_blank' href"),
                            {
                                allowedTags: ALLOWED_TAGS,
                                allowedAttributes: ALLOWED_ATTRIBUTES
                            }
                        )
                    );
                })
                .catch((e) => {
                    // Handle any errors
                    console.error(e);
                    error("Could not fetch markdown render");
                });
    });
}