import { Button, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import NewTagIcon from "./NewTagIcon";
import { useNotes } from "hooks/data/useNotes";

const AddNewTag = () => {
	const [newTag, setNewTag] = useState({ text: "", color: "info.light" });

	const { addNoteTag } = useNotes();

	const handleAddNewTag = () => {
		addNoteTag(newTag.text, newTag.color);
		setNewTag({ text: "", color: "info.light" });
	};

	return (
		<Stack
			direction="row"
			spacing={0.5}
			alignItems="center"
			sx={(theme) => ({
				mx: 1,
				mt: 1.5,
				mb: 0.5,
				p: 0.5,
				pl: 0.2,
				border: `1px solid ${theme.palette.primary.main}`,
				borderRadius: "8px"
			})}
		>
			<NewTagIcon
				color={newTag.color}
				onChange={(v) => setNewTag((old) => ({ ...old, color: v }))}
			/>

			<TextField
				fullWidth
				size="small"
				variant="standard"
				InputProps={{ disableUnderline: true, sx: { pt: 0.5 } }}
				value={newTag.text}
				onChange={(e) => {
					e.stopPropagation();
					setNewTag((old) => ({ ...old, text: e.target.value }));
				}}
			/>

			<Button
				variant="contained"
				size="small"
				sx={{ width: "110px!important" }}
				onClick={handleAddNewTag}
			>
				Add tag
			</Button>
		</Stack>
	);
};

export default AddNewTag;
