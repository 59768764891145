import { addSuccessNotification } from "Resources/Notifications";
import {
	createTag,
	deleteTagById,
	loadProjectNotes,
	saveProjectNotes
} from "Resources/ServerInterface";
import useSetAtom from "hooks/useSetAtom";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { notesAtom, notesTagsAtom } from "store/atoms/NotesAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";

export function useNotes() {
	const setNotes = useSetAtom(notesAtom);
	const setNotesTags = useSetAtom(notesTagsAtom);

	const project = useAtomCallback(
		useCallback((get) => {
			const curr = get(projectAtom);
			return curr;
		}, [])
	);

	const notes = useAtomCallback(
		useCallback((get) => {
			const curr = get(notesAtom);
			return curr;
		}, [])
	);

	const loadNotes = async (projectID: string = project().id) => {
		const _notes = await loadProjectNotes(projectID);
		setNotes(_notes);
	};

	const addNote = async (data: object, projectId: string = project().id) => {
		try {
			return await saveProjectNotes(
				projectId,
				[
					{
						...data,
						dateUpdated: new Date().toISOString()
					},
					...notes()
				],
				project().privateMode
			).then(async () => {
				// addSuccessNotification("Success", "Note added!");
				await loadNotes();
			});
		} catch (error) {
			console.log(error);
		}
	};

	const editNote = async (
		data: object,
		i: number,
		projectId: string = project().id
	) => {
		try {
			const _notes = [...notes()];
			_notes[i] = {
				...data,
				dateUpdated: new Date().toISOString()
			};

			return await saveProjectNotes(
				projectId,
				_notes,
				project().privateMode
			).then(async () => {
				addSuccessNotification("Success", "Note Updated!", 1000);
				await loadNotes();
			});
		} catch (error) {
			console.log(error);
		}
	};

	const deleteNote = async (
		noteId: string,
		projectId: string = project().id
	) => {
		try {
			return await saveProjectNotes(
				projectId,
				notes().filter((i) => i._id !== noteId),
				project().privateMode
			).then(async () => {
				addSuccessNotification("Success", "Note deleted!", 1000);
				await loadNotes();
			});
		} catch (error) {
			console.log(error);
		}
	};

	const addNoteTag = async (name: string, color: string) => {
		createTag({ name, value: name, color }).then((tagId) => {
			setNotesTags((old) => [...old, { name, color, value: name, _id: tagId }]);
		});
	};

	const deleteNoteTag = (value: string, _id: string = null) => {
		setNotesTags((old) => old.filter((i) => i.name !== value));

		if (_id) {
			deleteTagById(_id);
		}
	};

	return {
		loadNotes,
		addNote,
		editNote,
		deleteNote,
		addNoteTag,
		deleteNoteTag
	};
}
