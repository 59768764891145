import CampaignIcon from '@mui/icons-material/Campaign';
import { Box } from "@mui/material";
import { forwardRef } from "react";
import NotificationToast from ".";

const InfoToast = forwardRef((props: any, ref) => {
	return (
		<Box ref={ref}>
			<NotificationToast
				id={props.id}
				color="#8870E6"
				Icon={<CampaignIcon fontSize="small" sx={{ color: "text.inverse" }} />}
				title={props.title}
				message={props.message}
				action={props.action || null}
			/>
		</Box>
	);
});

export default InfoToast;
