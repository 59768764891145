import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

interface FeaturesType {
	id: string;
	title: string;
	imageUrl: string;
	redirectUrl: string;
	slideshowIndex: string;
	thumbnailType: "image" | "video";
	timestamp: any;
}

export const announcementModalsAtom = atom([]);
export const userMetadataAtom = atom<any>({});

export const homepageFeaturesAtom = atomWithStorage<FeaturesType[]>(
	"AW-HOMEPAGE-FEATURES",
	[]
);
export const homepageImprovementsAtom = atomWithStorage(
	"AW-IMPROVEMENTS-FEATURES",
	[]
);
