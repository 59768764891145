
import { Card, Stack, ThemeProvider, Typography } from "@mui/material";
import darkTheme from "config/theme/dark";

const CallableValueWidget = ({ callableValues, handleClose }) => {
	return (
		<ThemeProvider theme={darkTheme}>
            {callableValues.map((callableValue, i: number) => (
                    <Card
                        onClick={(e) => e.stopPropagation()}
                        sx={(theme) => ({
                            bgcolor: "background.default",
                            width: 700,
                            border: `2px solid #51448C`,
                            borderRadius: "8px",
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                            display: "flex",
                            flexDirection: "column",
                            p: 2,
                            maxHeight: 300,
                            overflowY: "auto"
                        })}
                    >
                        <Typography variant="body1"><strong>Live On-chain Value</strong></Typography>
                        <Stack spacing={1} sx={{mt: 1}}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography sx={{ fontWeight: 600 }} variant="body2">
                                    View Name:
                                    <Typography component="span" variant="body2">
                                        {" "}
                                        {callableValue?.data?.name}
                                    </Typography>
                                </Typography>

                                <Typography sx={{ fontWeight: 600 }} variant="body2">
                                    Value:
                                    <Typography component="span" variant="body2">
                                        {" "}
                                        {callableValue?.data?.value}
                                    </Typography>
                                </Typography>
                            </Stack>
                        </Stack>
                    </Card>
            ))}
		</ThemeProvider>
	);
};

export default CallableValueWidget;