import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import {
	Box,
	Button,
	ButtonProps,
	Hidden,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { auth, getUsername } from "Resources/Auth";
import { addErrorNotification } from "Resources/Notifications";
import {
	authWithGithub,
	authWithGoogle,
	createAWAccount,
	login
} from "Resources/ServerInterface";
import EthIconDark from "assets/icons/ethIconDark.png";
import EthIconLight from "assets/icons/ethIconLight.png";
import {
	githubClientIdAtom,
	githubLoginCodeAtom,
	mixpanelAtom,
	usernameAtom
} from "atoms";
import LoginForm, { LoginFormType } from "components/forms/LoginForm";
import SignUpForm, { SignupFormType } from "components/forms/SignUpForm";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { loginModalOpenAtom } from "store/atoms/UiAtoms";
import { LOCALSTORAGE_REFERRAL } from "utils/constants";
import { redirectToGitHubForAuth } from "utils/helpersFunctions";
import EmailVerification from "views/LoginView/EmailVerification";
import ResetPassword from "views/LoginView/ResetPassword";

const SocialButton: React.FC<ButtonProps & { text?: string; Icon: any }> = ({
	text,
	Icon,
	sx = {},
	...props
}) => (
	<Button
		fullWidth
		variant="outlined"
		sx={{
			height: 40,
			borderRadius: "8px",
			bgcolor: "background.paper",
			color: "text.primary",
			...sx
		}}
		{...props}
	>
		<Stack direction="row" alignItems="center" spacing={1}>
			<Icon />

			{text && <Typography>{text}</Typography>}
		</Stack>
	</Button>
);

const LogInSignUp: React.FC<{ full?: boolean; signUpMode?: boolean }> = ({
	full,
	signUpMode
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [mode, setMode] = useState<"signup" | "login">(
		signUpMode ? "signup" : "login"
	);

	const [passwordReset, setPasswordReset] = useState(false);
	const [emailVerification, setEmailVerification] = useState(null);
	const [loading, setLoading] = useState(false);

	const [_, setUsername] = useAtom(usernameAtom);
	const [mixpanel] = useAtom(mixpanelAtom);
	const [githubClientId] = useAtom(githubClientIdAtom);

	const switchSignupMode = () => {
		if (mode === "signup") {
			setMode("login");
		} else {
			setMode("signup");
		}
	};

	const referral = localStorage.getItem(LOCALSTORAGE_REFERRAL) || "";

	const signup = mode === "signup";

	const createAuditwareAccount = (data: SignupFormType) => {
		const { username, email, password } = data;

		setLoading(true);

		createAWAccount(username, password, email, referral, mixpanel)
			.then(() => {
				localStorage.setItem("loggedIn", "1");
				setEmailVerification(email);
			})
			.catch((e) => {
				setLoading(false);
				console.log(e);
				addErrorNotification("Error", "Error");
			});
	};

	const loginWithAuditwareAccount = (data: LoginFormType) => {
		const { username, password } = data;

		setLoading(true);

		login(username, password, mixpanel)
			.then((email) => {
				localStorage.setItem("loggedIn", "1");
				setLoading(false);
				const username = getUsername();
				if (!!username) {
					setUsername(username);
				} else {
					setEmailVerification(email);
				}
			})
			.catch((e) => {
				setLoading(false);
				console.log(e);
				addErrorNotification("Error", "Error");
			});
	};

	const forgotPassword = () => {
		setPasswordReset(true);
	};

	const handleLogin = async () => {
		await auth(mixpanel, referral);
		const username = getUsername();
		setUsername(username);
	};

	const googleLogin = useGoogleLogin({
		onSuccess: async (tokenResponse) => {
			const accessToken = tokenResponse["access_token"];
			await authWithGoogle(accessToken, mixpanel, referral);
			const username = getUsername();
			setUsername(username);
		},
		onError: () =>
			addErrorNotification(
				"Error",
				"There was an error authenticating with Google. Please try again."
			)
	});

	return (
		<Stack alignItems="center" sx={{ width: "100%" }}>
			{passwordReset && <ResetPassword goBack={() => {}} />}
			{!!emailVerification && (
				<EmailVerification goBack={() => {}} email={emailVerification} />
			)}
			{!passwordReset && !emailVerification &&
				<>
					<Typography variant={full ? "h4" : "body1"} sx={{ mb: 2 }}>
						{!signup ? "Log in" : "Sign up"} to get started
					</Typography>

					{full && (
						<Typography
							sx={{ color: "text.secondary", textAlign: "center", mb: 2 }}
						>
							Start finding vulnerabilities and securing your code in just a few
							clicks!
						</Typography>
					)}

					<Stack direction="row" alignItems="center" spacing={1.5}>
						<SocialButton
							text={full && "Google"}
							disabled={loading}
							onClick={() => googleLogin()}
							Icon={() => <GoogleIcon fontSize="small" />}
						/>

						<Hidden smDown>
							<SocialButton
								text={full && "Ethereum"}
								disabled={loading}
								onClick={handleLogin}
								Icon={() => (
									<Box
										component="img"
										src={themeMode === "dark" ? EthIconDark : EthIconLight}
										sx={{ height: 19, objectFit: "contain" }}
									/>
								)}
							/>
						</Hidden>

						<SocialButton
							text={full && "GitHub"}
							disabled={loading}
							onClick={() => redirectToGitHubForAuth(githubClientId)}
							Icon={() => <GitHubIcon fontSize="small" />}
						/>
					</Stack>

					<Typography sx={{ my: full ? { xs: 2, md: 3 } : 1 }}>Or</Typography>

					<Box sx={{ mb: 1.5, width: "100%" }}>
						{signup ? (
							<SignUpForm
								disabled={loading}
								handleSubmit={createAuditwareAccount}
							/>
						) : (
							<LoginForm
								disabled={loading}
								handleSubmit={loginWithAuditwareAccount}
							/>
						)}
					</Box>

					{full && (
						<Typography
							onClick={switchSignupMode}
							sx={{
								my: 1,
								"& span": {
									color: "primary.main",
									cursor: "pointer",
									fontWeight: 500
								}
							}}
						>
							Already have an account? <span>{signup ? "Log in" : "Sign up"}</span>
						</Typography>
					)}

					{!full && (
						<Button fullWidth onClick={switchSignupMode}>
							{signup ? "Log in" : "Sign up"}
						</Button>
					)}

					{!signup && (
						<Button fullWidth onClick={forgotPassword}>
							Forgot password?
						</Button>
					)}
				</>
			}

		</Stack>
	);
};

export default LogInSignUp;
