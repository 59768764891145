import { LoadingButton } from "@mui/lab";
import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomModal from ".";

interface PersonalAccessTokenModalProps {
	open: boolean;
	onClose: () => void;
	ctfId: string;
}

const DeleteCtfModal: React.FC<PersonalAccessTokenModalProps> = ({
	open,
	onClose,
	ctfId
}) => {
	const [loading, setLoading] = useState(false);

	const handleDeleteCtf = async () => {
		setLoading(true);
		// await deleteCtf(ctfId);

		setLoading(false);
		handleClose();
	};

	const handleClose = () => {
		if (!loading) {
			onClose();
		}
	};

	return (
		<CustomModal
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="md"
			paperSx={{ maxWidth: "500px", py: 3, textAlign: "center" }}
		>
			<Typography variant="h4" sx={{ textAlign: "center", mb: 2 }}>
				Are you sure?
			</Typography>

			<Typography sx={{ mb: 4 }}>
				Deleting your CTF means that you will lose all associated data including
				CTF findings, notes and reports.
			</Typography>

			<LoadingButton
				loading={loading}
				variant="contained"
				fullWidth
				onClick={handleDeleteCtf}
				sx={{ borderRadius: "8px", mb: 1 }}
			>
				{loading ? "Deleting..." : "Delete CTF"}
			</LoadingButton>

			<Button onClick={handleClose} fullWidth disabled={loading}>
				Cancel
			</Button>
		</CustomModal>
	);
};

export default DeleteCtfModal;
