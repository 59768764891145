import { atom } from "jotai";
import { getUsername } from "./Resources/Auth";
import mixpanel from "mixpanel-browser";
import Project from "./Resources/Project";
import { AccountSideBarOptions } from "./components/sidebars/AccountSidebar";

export const usernameAtom = atom(getUsername());
export const userAvatarSrcAtom = atom("");
export const mixpanelAtom = atom(mixpanel);
export const githubUsernameAtom = atom("");
export const googleClientIdAtom = atom("clientId");
export const githubClientIdAtom = atom("clientId");
export const githubLoginCodeAtom = atom("");
export const githubInstallationIdAtom = atom("");
export const selectedAccountSettingAtom = atom(
	AccountSideBarOptions.LoginPassword
);
export const userPrivacyHashAtom = atom("");
export const pocModalAtom = atom<{
	findingTitle: string;
	content: string;
	loading: boolean;
}>({ findingTitle: "", content: "", loading: false });
