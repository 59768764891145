import { partnerImportLinkClickedEvent } from "Mixpanel/mixpanel.helper";
import {
	getCallableValues,
	importGeneralizedImport,
	importHatsProject,
	importProjectFromUrl,
	setPrivacyHash
} from "Resources/ServerInterface";
import { mixpanelAtom, userPrivacyHashAtom } from "atoms";
import { ImportProjectFormType } from "components/forms/ImportProjectForm";
import { useProject } from "hooks/data/useProject";
import { useAtom, useSetAtom } from "jotai";
import secureLocalStorage from "react-secure-storage";
import {
	PartnerAdType,
	importingAtom,
	isPrivateModeAtom
} from "store/atoms/ImportsAtoms";
import { privacyModeSetupModalOpenAtom } from "store/atoms/UiAtoms";
import {
	generateEncryptionKey,
	hashEncryptionKey
} from "utils/privateModeEncryption";

export function useImportProject() {
	const { openProject } = useProject();

	const setPrivacyModeSetupModalOpen = useSetAtom(
		privacyModeSetupModalOpenAtom
	);

	const [privateMode] = useAtom(isPrivateModeAtom);
	const [mixpanel] = useAtom(mixpanelAtom);
	const setImporting = useSetAtom(importingAtom);
	const setUserPrivacyHash = useSetAtom(userPrivacyHashAtom);

	const handleImportProject = async (
		data: ImportProjectFormType | null,
		network: string = null,
		url: string = null,
		i: number = null,
		privateMode: boolean = false,
		commitSha?: string
	) => {
		try {
			const _url = data?.link || url;

			setImporting(true);

			if (
				(!!data?.privacyType && data?.privacyType === "private") ||
				privateMode
			) {
				if (!secureLocalStorage.getItem("privateModeEncryptionKey")) {
					const encryptionKey = generateEncryptionKey();
					secureLocalStorage.setItem("privateModeEncryptionKey", encryptionKey);

					const encryptionKeyHash = await hashEncryptionKey(encryptionKey);
					await setPrivacyHash(encryptionKeyHash);
					setUserPrivacyHash(encryptionKeyHash);

					setPrivacyModeSetupModalOpen(true);
				}
			}

			const project = await importProjectFromUrl(
				_url.trim(),
				network,
				mixpanel,
				(!!data?.privacyType && data?.privacyType === "private") || privateMode,
				commitSha
			);
			const addressRegexp = /^0x[a-fA-F0-9]{40}$/;
			let callableValues = [];
			if (addressRegexp.test(project.source)) {
				callableValues = await getCallableValues(project.id);
			}

			if (project !== undefined) openProject(project, callableValues);
		} catch (e) {
			console.log("Error opening imported project: " + e.message);
		} finally {
			setImporting(false);
		}
	};

	const handleGeneralizedImport = async (generalizedId: string) => {
		try {
			if (privateMode) {
				if (!secureLocalStorage.getItem("privateModeEncryptionKey")) {
					const encryptionKey = generateEncryptionKey();
					secureLocalStorage.setItem("privateModeEncryptionKey", encryptionKey);

					const encryptionKeyHash = await hashEncryptionKey(encryptionKey);
					await setPrivacyHash(encryptionKeyHash);
					setUserPrivacyHash(encryptionKeyHash);

					setPrivacyModeSetupModalOpen(true);
				}
			}

			const project = await importGeneralizedImport(generalizedId, privateMode);

			if (project !== undefined) openProject(project);
		} catch (error) {
			console.log(error);
		} finally {
			setImporting(false);
		}
		setImporting(true);
	};

	const handleHatsImport = async (hatsId: string) => {
		try {
			setImporting(true);
			const project = await importHatsProject(hatsId);

			if (project !== undefined) openProject(project);
		} catch (error) {
			console.log(error);
		} finally {
			setImporting(false);
		}
	};

	const handleProjectClick = (item: PartnerAdType, i: number) => {
		if (item.generalizedId) {
			handleGeneralizedImport(item.generalizedId);
		} else if (item.urlType === "import") {
			handleImportProject(null, null, item.url, i, privateMode);
		} else if (item.hatsId) {
			handleHatsImport(item.hatsId);
		} else {
			partnerImportLinkClickedEvent(mixpanel, item.url);
			window.open(item.url, "_blank");
		}
	};

	return { handleImportProject, handleProjectClick };
}
