import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { LOCALSTORAGE_DRAWING_TOOL } from "utils/constants";

export interface Whiteboard {
	createdAt?: string;
	updatedAt?: string;
	_id?: string;
	value: any;
	title: string;
	files?: any;
	appState?: any;
	projectID: string;
}

export const whiteboardAtom = atom([]);
export const storageWhiteboardAtom = atomWithStorage(
	LOCALSTORAGE_DRAWING_TOOL,
	"[]"
);

export const storageWhiteboardFilesAtom = atomWithStorage(
	`${LOCALSTORAGE_DRAWING_TOOL}_FILES`,
	"{}"
);
