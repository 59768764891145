import { Box } from "@mui/material";
import {
	getCallableValues,
	importProjectFromUrl,
	setPrivacyHash
} from "Resources/ServerInterface";
import { mixpanelAtom, userPrivacyHashAtom } from "atoms";
import ImportProjectForm, {
	ImportProjectFormType
} from "components/forms/ImportProjectForm";
import { useProject } from "hooks/data/useProject";
import { useAtom, useSetAtom } from "jotai";
import React from "react";
import secureLocalStorage from "react-secure-storage";
import { importingAtom } from "store/atoms/ImportsAtoms";
import { privacyModeSetupModalOpenAtom } from "store/atoms/UiAtoms";
import {
	generateEncryptionKey,
	hashEncryptionKey
} from "utils/privateModeEncryption";

const Form: React.FC<{ loading: boolean; setPrivateMode: any }> = ({
	loading,
	setPrivateMode
}) => {
	const { openProject } = useProject();

	const setPrivacyModeSetupModalOpen = useSetAtom(
		privacyModeSetupModalOpenAtom
	);
	const setUserPrivacyHash = useSetAtom(userPrivacyHashAtom);

	const [importing, setImporting] = useAtom(importingAtom);
	const [mixpanel] = useAtom(mixpanelAtom);

	const handleImportProject = async (
		data: ImportProjectFormType | null,
		network: string = null,
		url: string = null,
		i: number = null,
		privateMode: boolean = false,
		commitSha?: string
	) => {
		try {
			gtag_report_conversion();
			
			const _url = data?.link || url;

			setImporting(true);

			if (
				(!!data?.privacyType && data?.privacyType === "private") ||
				privateMode
			) {
				if (!secureLocalStorage.getItem("privateModeEncryptionKey")) {
					const encryptionKey = generateEncryptionKey();
					secureLocalStorage.setItem("privateModeEncryptionKey", encryptionKey);

					const encryptionKeyHash = await hashEncryptionKey(encryptionKey);
					await setPrivacyHash(encryptionKeyHash);
					setUserPrivacyHash(encryptionKeyHash);

					setPrivacyModeSetupModalOpen(true);
				}
			}

			const project = await importProjectFromUrl(
				_url.trim(),
				network,
				mixpanel,
				(!!data?.privacyType && data?.privacyType === "private") || privateMode,
				commitSha
			);
			const addressRegexp = /^0x[a-fA-F0-9]{40}$/;
			let callableValues = [];
			if (addressRegexp.test(project.source)) {
				callableValues = await getCallableValues(project.id);
			}

			if (project !== undefined) openProject(project, callableValues);
		} catch (e) {
			console.log("Error opening imported project: " + e.message);
		} finally {
			setImporting(false);
		}
	};

	return (
		<Box>
			<ImportProjectForm
				handleSubmit={handleImportProject}
				setPrivateMode={setPrivateMode}
				disabled={loading || importing}
			/>
		</Box>
	);
};

export default React.memo(Form);
