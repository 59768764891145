import path from "path";
import ProjectFolder from "../Resources/ProjectFolder";
import ProjectImport from "../Resources/ProjectImport";
import secureLocalStorage from "react-secure-storage";
import { v4 as uuidv4 } from "uuid";

export const isMac = () => {
	return (
		navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
		navigator.userAgent.indexOf("Mac") !== -1
	);
};

// Utility function to escape special characters in regular expressions.
function escapeRegExp(string) {
	return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function getGithubApp(): string {
	switch (window.location.hostname) {
		case "app.auditwizard.io":
			return "audit-wizard";
		case "dev.auditwizard.io":
			return "audit-wizard-dev";
		default:
			return "audit-wizard-local";
	}
}

export const redirectToGitHubForAuth = (githubClientId: string) => {
	const redirectUri = encodeURIComponent(
		`${window.location.protocol}//${window.location.host}${(!!window.location.port) ? ":" + window.location.port : ""}/github`
	);
	const scope = "user:email";

	window.location.href = `https://github.com/login/oauth/authorize?client_id=${githubClientId}&redirect_uri=${redirectUri}&scope=${scope}`;
};

export const redirectToGitHubForInstallation = () => {
	const state = uuidv4(); // CSRF protection
	secureLocalStorage.setItem("githubAuthState", state);
	window.location.href = `https://github.com/apps/${getGithubApp()}/installations/new?state=${state}`;
};

export function findCallableValueDefinition(solidityCode, callableValueName) {
	const lines = solidityCode.split("\n");

	// This regex matches the callableValueName when it's adjacent to common code boundary characters or at the start/end of a line.
	const regex = new RegExp(
		"(^|[^a-zA-Z0-9_])" + escapeRegExp(callableValueName) + "([^a-zA-Z0-9_]|$)"
	);

	let insideMultiLineComment = false;

	for (let i = 0; i < lines.length; i++) {
		let line = lines[i].trim();

		// Skip empty lines or lines that start with single-line comment
		if (line === "" || line.startsWith("//")) continue;

		// Handle multi-line comments
		if (line.startsWith("/*")) insideMultiLineComment = true;
		if (insideMultiLineComment) {
			if (line.endsWith("*/")) insideMultiLineComment = false;
			continue; // skip current line
		}

		if (regex.test(line)) {
			return i + 1;
		}
	}

	return null;
}

export const getPathOfDefaultOpenFile = (
	projectFolder: ProjectFolder
): string => {
	// Look for a README file
	for (const file of projectFolder.files)
		if (file.name.includes("README"))
			return path.join(projectFolder.name, file.name);

	// Open the first file, if it exists
	if (projectFolder.files.length > 0)
		return path.join(projectFolder.name, projectFolder.files[0].name);

	// Open the default focus of the first nested folder, if it exists
	if (projectFolder.folders.length > 0)
		return path.join(
			projectFolder.name,
			getPathOfDefaultOpenFile(projectFolder.folders[0])
		);

	// Else, return an empty path
	return "";
};

export const getPathOfChallengeFile = (
	projectFolder: ProjectFolder
): string => {
	// Look for a challenge file in the current folder
	for (const file of projectFolder.files) {
		if (file.name.includes("challenge_")) {
			return path.join(projectFolder.name, file.name);
		}
	}

	// Recursively look through nested folders
	for (const folder of projectFolder.folders) {
		const challengeFilePath = getPathOfChallengeFile(folder);
		if (challengeFilePath !== "") {
			return path.join(projectFolder.name, challengeFilePath);
		}
	}

	// If no challenge file is found in the current folder or any nested folders, return an empty path
	return "";
};

export const findPathOfRemoteImport = (
	importSource: string,
	folderToSearch: ProjectFolder
): string => {
	for (const importFile of folderToSearch.files)
		if ((importFile as ProjectImport).source === importSource)
			return path.join(folderToSearch.name, importFile.name);

	for (const importFolder of folderToSearch.folders) {
		let foundImportPath = findPathOfRemoteImport(importSource, importFolder);
		if (foundImportPath !== null)
			return path.join(folderToSearch.name, foundImportPath);
	}

	return null;
};

export const getValueFromObjPath = (obj: any, path: string) => {
	const parts = path.split(".");
	let current = obj;
	for (const part of parts) {
		if (typeof current !== "object" || current === null) {
			return undefined;
		}
		current = current[part];
	}
	return typeof current === "string" ? current : undefined;
};

export const isDevEnv = () =>
	!process.env.NODE_ENV || process.env.NODE_ENV === "development";

// File type validations for form uploads
export const validateFileType = (
	file: any,
	restrict: { type?: string; maxSize?: number } = {}
): string | true => {
	if (!file) return "No file found";

	const { type, maxSize } = restrict;

	const fileTypes = {
		images: ["image/png", "image/jpg", "image/jpeg"],
		pdf: ["application/pdf"]
	};

	if (type && !fileTypes[type]?.includes?.(file?.[0]?.type)) {
		console.log("Wrong File Type");
		return "Wrong File Type";
	}

	if (maxSize && file?.[0]?.size > maxSize * 1e6) {
		// console.log("File size too big");
		return "File size too big";
	}

	return true;
};

// Add timeout to async functions
export const timeout = (ms: number) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

// This function allows us to cut shorten a string and replace the middle with dots
// ie: 0x6b590....d03691
export const smallerString = (
	_string: string,
	firstN = 5,
	lastN = -4,
	numOfDots = 4
) => {
	if (!_string?.length) return _string;

	if (firstN + Math.abs(lastN) >= _string?.length) return _string;

	const _first = _string.slice(0, firstN);
	const _last = _string.slice(lastN);

	return `${_first}${Array(numOfDots).join(".")}${_last}`;
};

// This function flattens an array of nested files and folders into a
// single array of all files
export const flattenFiles = (rootFolder, path = "") => {
	const flattenedFiles = [];

	for (const file of rootFolder.files) {
		flattenedFiles.push({
			id: file.id,
			name: file.name,
			path: path + "/" + rootFolder.name
		});
	}

	for (const folder of rootFolder.folders) {
		const folderPath = path + "/" + rootFolder.name;
		flattenedFiles.push(...flattenFiles(folder, folderPath));
	}

	return flattenedFiles;
};

export function insertDataInArray<T>(
	originalArray: T[],
	newData: T,
	index: number
): T[] {
	// Check if index is within bounds
	if (index < 0 || index > originalArray.length) {
		throw new Error("Index out of bounds");
	}

	// Create a copy of the original array to avoid mutating it
	const newArray = originalArray.slice();

	// Insert newData at the specified index
	newArray.splice(index, 0, newData);

	return newArray;
}

// Function to get data from local storage
export function getObjectFromLocalStorage<T>(key: string): T | object {
	try {
		const dataString = localStorage.getItem(key);
		if (dataString) {
			const data: T = JSON.parse(dataString);
			return data;
		}
		return {};
	} catch (error) {
		console.error("Error getting data from local storage:", error);
		return {};
	}
}

// Function to update data in local storage
export function updateObjectInLocalStorage<T>(
	key: string,
	newData: Partial<T>,
	replaceFullData?: boolean
): T | null {
	try {
		if (replaceFullData) {
			localStorage.setItem(key, JSON.stringify(newData));
		} else {
			const existingDataString = localStorage.getItem(key);
			const existingData: T = existingDataString
				? JSON.parse(existingDataString)
				: ({} as T);
			const updatedData: T = { ...existingData, ...newData };
			localStorage.setItem(key, JSON.stringify(updatedData));
		}

		return null;
	} catch (error) {
		console.error("Error updating data in local storage:", error);
		return null;
	}
}

// Clear all localstorage key/value if key contains a string
export const clearLocalStorageItemsByString = (keyString: string) => {
	for (let i = 0; i < localStorage.length; i++) {
		const _key = localStorage.key(i);
		if (_key.includes(keyString)) {
			localStorage.removeItem(_key);
		}
	}
};

// Full screen mode
export const toggleFullScreen = () => {
	if (!document.fullscreenElement) {
		document.documentElement.requestFullscreen();
	} else {
		document.exitFullscreen();
	}
};

// get OS
export const getOperatingSystem = () => {
	const userAgent = navigator.userAgent;

	if (userAgent.includes("Windows")) {
		return "Windows";
	} else if (userAgent.includes("Mac OS")) {
		return "macOS";
	} else {
		return "Other OS";
	}
};

// Get code snippet from code and line numbers
export const getCodeSnippet = (
	code: string,
	startLine: number,
	endLine: number
) => {
	const lines = code.split("\n");

	if (startLine <= 0 || endLine <= 0) {
		throw new Error("Line numbers must be greater than 0");
	}

	if (startLine > endLine) {
		throw new Error(
			"Start line number should be less than or equal to end line number"
		);
	}

	if (endLine > lines.length) {
		throw new Error(
			"End line number exceeds the total number of lines in the code"
		);
	}

	const snippetLines = lines.slice(startLine - 1, endLine);
	const snippet = snippetLines.join("\n");

	return snippet;
};

// Check if variable exists (helps with number 0)
export const varExists = (value: any): boolean => {
	// Check if the value is not null and not undefined
	if (value !== null && value !== undefined) {
		// Check if the value is not an empty string
		if (typeof value === "string" && value.trim() === "") {
			return false; // Invalid
		}
		return true; // Valid
	}
	return false; // Invalid
};

export const getFileTypeFromString = (filename: string): string => {
	const fileTypeRegex = /\.([0-9a-z]+)(?:[?#]|$)/i;
	const match = fileTypeRegex.exec(filename);
	if (match && match[1]) {
		return match[1].toLowerCase();
	} else {
		return filename; // If file type doesn't exist, return the string itself
	}
};

// Download a markdown file
export const downloadMarkdownFile = (
	markdownContent: string,
	filename: string
): void => {
	const blob = new Blob([markdownContent], { type: "text/markdown" });
	const url = URL.createObjectURL(blob);

	const a = document.createElement("a");
	a.href = url;
	a.download = filename;
	a.style.display = "none";
	document.body.appendChild(a);

	a.click();

	document.body.removeChild(a);
	URL.revokeObjectURL(url);
};
