import {
	Box,
	Button,
	Container,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import React, { useState } from "react";
import ConfirmationModal from "../CustomModal/ConfirmationModal";
import { deleteAccount } from "../../Resources/ServerInterface";
import { useAppContext } from "../../context/AppContext";

const AccountSettings = () => {
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const { handleLogout } = useAppContext();

	return (
		<Container maxWidth="md">
			<Box
				sx={{
					width: "100%",
					borderRadius: "20px",
					bgcolor: theme.palette.background.paper,
					mx: "auto",
					p: 3,
					minWidth: "500px"
				}}
			>
				<Container maxWidth="sm" sx={{ textAlign: "center" }}>
					<Typography variant="h4">Delete my account</Typography>

					<Stack>
						<Typography sx={{ fontSize: "14px", mt: 2 }}>
							This will delete all projects and data associated with your
							account
						</Typography>

						<Button
							variant="contained"
							fullWidth
							color="error"
							sx={{
								maxWidth: 300,
								height: "40px",
								mx: "auto",
								mt: 2
							}}
							onClick={() => setOpen(true)}
						>
							Delete my account
						</Button>
					</Stack>
				</Container>
			</Box>

			<ConfirmationModal
				open={open}
				onClose={() => setOpen(false)}
				onConfirm={async () => {
					await deleteAccount();
					handleLogout();
				}}
				title="Delete your account?"
				description="Are you sure you want to delete your account? This action is irreversible and will result in complete data loss."
				confirmButtonText="Delete"
				confirmButtonLoadingText="Deleting..."
			/>
		</Container>
	);
};

export default React.memo(AccountSettings);
