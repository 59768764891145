import EditorTab from "context/EditorTab";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { LOCALSTORAGE_THEME_MODE } from "utils/constants";
import { projectAtom } from "./projectToolAtoms";
import { isFileSolidity } from "Resources/Helpers";
import parser from "solidity-parser-antlr";

export const expandedProjectTreeAtom = atom<string[]>([]);

export const themeModeAtom = atomWithStorage(LOCALSTORAGE_THEME_MODE, "dark");
export const importsModeAtom = atom<"project" | "ctf">("project");

export const loginModalOpenAtom = atom<boolean | "signup">(false);
export const searchAllBoxOpenAtom = atom(false);
export const submissionModeAtom = atom(false);
export const searchFilesBoxOpenAtom = atom(false);

export const feedbackModalOpenAtom = atom(false);
export const pocModalOpenAtom = atom(false);
export const tutorialModalOpenAtom = atom(false);
export const privacyModeSetupModalOpenAtom = atom(false);
export const privacyModeCodeNeededModalOpenAtom = atom(false);
export const proModalOpenAtom = atom(false);

export type SelectedToolType =
	| "CodeScan"
	| "Graph"
	| "AIScan"
	| "Notepad"
	| "BugChallenge"
	| "Inspect"
	| "Tests"
	| "Whiteboard"
	| "Findings"
	| "AddFinding"
	| "Checklist"
	| (string & { _?: never })
	| null;

// Tools
export const selectedToolAtom = atom<SelectedToolType | null>(
	null as SelectedToolType | null
);
export const selectedToolPropsAtom = atom<object>({});

// Editor tabs
export const editorHomeModeAtom = atom(false);
export const editorTabsAtom = atom<EditorTab[]>([]);
export const focusedEditorTabIndexAtom = atom<number>(0);

export const openedTabAtom = atom<{
	openedTab: EditorTab;
	openedFile: any;
	projectFilePath: string;
	fullProjectFilePath: string;
	isSolFileSelected: boolean;
}>((get) => {
	const editorTabs = get(editorTabsAtom);
	const focusedTabIndex = get(focusedEditorTabIndexAtom);
	const project = get(projectAtom);

	const openedTab = editorTabs[focusedTabIndex];
	const openedFile: any = openedTab?.contents;
	const projectFilePath = openedTab?.path;
	const fullProjectFilePath = `${project?.id}${projectFilePath}`;

	const isSolFileSelected =
		// !openedTab?.path?.includes("function-explorer") &&
		isFileSolidity(projectFilePath);

	return {
		openedTab,
		openedFile,
		projectFilePath,
		fullProjectFilePath,
		isSolFileSelected
	};
});

export const appCountAtom = atom(0);

export const badgesPopupAtom = atom(false);

export const projectsNewFolderName = atom<string | null>(null as string | null);

export const openedFileASTAtom = atom<{ ast: any }>((get) => {
	const editorTabs = get(editorTabsAtom);
	const focusedTabIndex = get(focusedEditorTabIndexAtom);

	const openedTab = editorTabs[focusedTabIndex];
	const openedFile: any = openedTab?.contents;

	const ast = openedFile?.ast;
	const contract = openedFile?.text?.split("\n");

	if (!ast || !contract?.length) return { ast: null };

	// Arrays to hold functions and variables
	const all = [];

	const _slice = (node, type) => {
		if (!node.name) return null;

		const functionCode = contract
			.slice(node.loc.start.line - 1, node.loc.end.line + 1)
			.join("\n")
			.trim();

		all.push({
			name: node.name,
			code: functionCode,
			type: node.type || type,
			visibility: node.visibility
		});
	};

	try {
		// Visit the AST nodes to find functions and variables
		parser.visit(ast, {
			// VariableDeclaration(node) {
			// 	_slice(node, "variable");
			// },
			FunctionDefinition(node) {
				_slice(node, "function");
			},
			StructDefinition(node) {
				_slice(node, "struct");
			},
			EnumDefinition(node) {
				_slice(node, "enum");
			},
			EventDefinition(node) {
				_slice(node, "event");
			},
			ImportDirective(node) {
				_slice(node, "import");
			}
		});
	} catch (error) {
		console.log(error);
	}

	return { ast: all };
});
