import { Monaco } from "@monaco-editor/react";
import { editor } from "monaco-editor";
import ReactDOM from "react-dom";
import NotesWidget from "./NotesWidget";
import { noteColor } from "./styles";
import { Theme } from "@mui/material";
import BookmarkViewZone from "./BookmarkViewZone";

export const extractLocationFromString = (inputString: string) => {
	const matches = inputString.match(/(.+)#(.+)-(.+)/);

	if (!matches) return { filePath: inputString, fromLine: -1, toLine: -1 };

	return {
		filePath: matches[1],
		fromLine: parseInt(matches[2] || ""),
		toLine: parseInt(matches[3] || "")
	};
};

export const parseNotes = (notes, theme: Theme) => {
	const options = (_color) => ({
		isWholeLine: true,
		minimap: {
			position: 2,
			color: noteColor(theme, _color),
			darkColor: noteColor(theme, _color)
		},
		overviewRuler: {
			position: 7,
			color: noteColor(theme, _color),
			darkColor: noteColor(theme, _color)
		}
	});

	const parsedNotes =
		notes.flatMap((item, i) => {
			const _location = extractLocationFromString(item.location || "");

			return [
				{
					id: `${i}`,
					options: {
						...options(item.tagColor),
						className: `editor-notes-widget.${item.tagColor}`
					},
					range: {
						startLineNumber: _location.fromLine,
						endLineNumber: _location.toLine,
						startColumn: 0,
						endColumn: 100
					},
					type: "bookmark",
					data: item
				}
			];
		}) || [];

	return parsedNotes;
};

export const removeNotesGlyphsAndListeners = (
	editorRef,
	widgetRef,
	allViewZones
) => {
	if (widgetRef.current) {
		editorRef?.removeContentWidget?.(widgetRef.current);
		widgetRef.current = null;
	}

	const model = editorRef?.getModel?.();
	const allDecorations = model?.getAllDecorations?.();

	const allClassNamesToRemove = [
		"editor-notes-widget text-disabled",
		"editor-notes-widget info-light",
		"editor-notes-widget success-light",
		"editor-notes-widget error-light",
		"editor-notes-widget warning-light",
		"editor-notes-widget primary-main",

		"glyph-notes text-disabled",
		"glyph-notes info-light",
		"glyph-notes success-light",
		"glyph-notes error-light",
		"glyph-notes warning-light",
		"glyph-notes primary-main"
	];

	// Remove viewzones
	editorRef.changeViewZones((changeAccessor) => {
		allViewZones.map((zoneId) => {
			return changeAccessor.removeZone(zoneId);
		});
	});

	// remove glyph and listeners
	if (allDecorations) {
		const notesIds = allDecorations
			.filter(
				(i) =>
					allClassNamesToRemove.includes(i.options.className) ||
					allClassNamesToRemove.includes(i.options.glyphMarginClassName)
			)
			.map((i) => i.id);

		editorRef.removeDecorations(notesIds);
	}
};

export const bookmarksClickOpenWidget = (
	editorRef: editor.IStandaloneCodeEditor,
	monacoRef: Monaco,
	position: any,
	notes: any,
	widgetRef: any
) => {
	const foundBookmarks =
		position &&
		notes.filter(
			(i) =>
				position.lineNumber >= i.range.startLineNumber &&
				position.lineNumber <= i.range.endLineNumber &&
				!i.options.glyphMarginClassName
		);

	if (!!foundBookmarks?.length) {
		const removeWidget = () => {
			if (widgetRef.current) {
				editorRef.removeContentWidget(widgetRef.current);
				widgetRef.current = null;
			} else {
				editorRef.removeContentWidget({
					getId: () => "editor-notes-widget",
					getDomNode: () => {
						const node = document.createElement("div");
						return node;
					},
					getPosition: () => ({
						position: {
							lineNumber: position?.lineNumber,
							column: 1
						},
						preference: [monacoRef.editor.ContentWidgetPositionPreference.BELOW]
					})
				});
			}
		};

		removeWidget();
	}
};

export const addNoteViewZones = (
	editorRef: editor.IStandaloneCodeEditor,
	notes: any,
	currentViewZones = [],
	handleViewNote: any,
	handleEditNote: any,
	handleDeleteNote: any,
	themeMode: "light" | "dark"
): any => {
	const allViewZones = [...(currentViewZones || [])];

	editorRef.changeViewZones((changeAccessor) => {
		allViewZones.map((zoneId) => {
			return changeAccessor.removeZone(zoneId);
		});

		const getDomNode = (data) => {
			const node = document.createElement("div");

			node.onwheel = function (e) {
				e.stopPropagation();
			};

			ReactDOM.render(BookmarkViewZone({ ...data, themeMode }), node);

			return node;
		};

		notes.map((note) => {
			const zoneId = changeAccessor.addZone({
				afterLineNumber: note?.range?.startLineNumber - 1,
				heightInPx: 31,
				domNode: getDomNode({
					...note.data,
					handleViewNote: (e) => handleViewNote(e, note.data),
					handleEditNote: () => handleEditNote(note.data),
					handleDeleteNote: () => handleDeleteNote(note.data)
				})
			});
			allViewZones.push(zoneId);

			return null;
		});
	});

	return allViewZones;
};
