import { Icon } from "@iconify/react";
import {
	Avatar,
	Divider,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import WizardPfpDark from "assets/images/wizardPfpDark.png";
import WizardPfpLight from "assets/images/wizardPfpLight.png";
import { userAvatarSrcAtom, usernameAtom } from "atoms";
import { useAppContext } from "context/AppContext";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { smallerString } from "utils/helpersFunctions";

const UserAvatarMenu = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [copied, setCopied] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const [username] = useAtom(usernameAtom);
	const [userAvatarSrc] = useAtom(userAvatarSrcAtom);

	const { handleLogout } = useAppContext();

	const navigate = useNavigate();

	const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		}
	}, [copied]);

	return (
		<>
			<IconButton
				onClick={handleOpen}
				sx={{ p: 0, mt: 1, mb: 2, boxShadow: 1 }}
			>
				{username && (
					<Avatar
						alt={username}
						src={
							userAvatarSrc
								? userAvatarSrc
								: themeMode === "dark"
								? WizardPfpDark
								: WizardPfpLight
						}
						sx={{
							height: { xs: 34, md: 36, xl: 37 },
							width: { xs: 34, md: 36, xl: 37 }
						}}
					/>
				)}
			</IconButton>

			<Menu
				anchorEl={anchorEl}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				// keepMounted
				open={open}
				onClose={handleClose}
				slotProps={{
					paper: {
						elevation: 0,
						sx: {
							borderRadius: "8px",
							minWidth: 250,
							overflow: "visible",
							mt: 1,
							boxShadow: 2,
							bgcolor:
								themeMode === "dark" ? "background.paper" : "background.paper",
							border: `1px solid ${theme.palette.primary.main}`,
							"& .MuiAvatar-root": {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1
							}
						}
					}
				}}
			>
				<MenuItem
					disableRipple
					disableTouchRipple
					sx={{ "&:hover": { bgcolor: "transparent", cursor: "default" } }}
				>
					<CopyToClipboard
						text={username}
						onCopy={() => {
							setCopied(true);
						}}
					>
						<Tooltip
							placement="left"
							enterDelay={1000}
							enterNextDelay={1000}
							title={copied ? "Copied" : "Copy to clipboard"}
						>
							<Typography
								sx={{
									color: "text.disabled",
									textAlign: "center",
									cursor: "pointer"
								}}
							>
								{smallerString(username, 15, -8)}
							</Typography>
						</Tooltip>
					</CopyToClipboard>
				</MenuItem>

				{/* Settings */}
				<MenuItem
					onClick={() => {
						setAnchorEl(null);
						navigate("/settings");
					}}
				>
					<Icon icon={"solar:settings-bold"} fontSize={19} />

					<Typography sx={{ ml: 1 }}>Settings</Typography>
				</MenuItem>

				<Divider sx={{ mx: 2, bgcolor: `background.paper2` }} />

				{/* Logout */}
				<MenuItem onClick={handleLogout}>
					<Icon icon="majesticons:logout" fontSize={19} />

					<Typography sx={{ ml: 1 }}>Logout</Typography>
				</MenuItem>
			</Menu>
		</>
	);
};

export default React.memo(UserAvatarMenu);
