import {
	Button,
	Hidden,
	Stack,
	Typography,
	useMediaQuery,
	useTheme
} from "@mui/material";
import RandomCodeBg from "assets/images/randomCodeBg.png";
import React from "react";
import hex from "utils/hexTransparency";

const SampleProjectUi: React.FC<{ buttonText?: string; onClick?: any }> = ({
	buttonText = "Load sample project",
	onClick
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const matches = useMediaQuery("(min-height:640px)");

	if (!matches) return null;

	return (
		<Stack
			alignItems="center"
			justifyContent="center"
			sx={{
				p: 3,
				pt: { xs: 5, sm: 3 },
				borderRadius: "12px",
				textAlign: "center",
				position: "relative",
				height: { xs: "30vh", sm: "50vh" },
				background:
					themeMode === "dark"
						? `linear-gradient(180deg, ${theme.palette.text["inverse"]}${hex["95%"]}, ${theme.palette.text["inverse"]}${hex["85%"]}, ${theme.palette.background["default"]}), url(${RandomCodeBg})`
						: `linear-gradient(180deg, ${theme.palette.primary.light}${hex["95%"]}, ${theme.palette.background["nav"]}${hex["85%"]}, #E7E7E7), url(${RandomCodeBg})`,
				backgroundSize: "300%",
				backgroundRepeat: "no-repeat"
			}}
		>
			<Typography variant="h5" sx={{ mb: 1.2, fontWeight: 600, mt: "-50%" }}>
				Sample project
			</Typography>

			<Hidden smDown>
				<Typography sx={{ mb: 2, fontWeight: 500 }}>
					Get a taste of your new auditing superpowers
				</Typography>
			</Hidden>

			<Button
				variant="contained"
				onClick={onClick}
				sx={{ borderRadius: "8px", fontWeight: 500 }}
			>
				{buttonText}
			</Button>
		</Stack>
	);
};

export default React.memo(SampleProjectUi);
