import { getChallengesByIds } from "Resources/ServerInterfaceCTF";
import { Contest, getContests } from "Resources/ServerInterfaceCTF";
import { usernameAtom } from "atoms";
import { useAtom } from "jotai";
import React, { createContext, useContext, useEffect, useState } from "react";

export interface CTFUIModel {
	_id?: string;
	difficulty: string;
	title: string;
	description: string;
	active: boolean;
	author: string;
	image: string;
	challenges: ChallengeUIModel[];
}

export interface ChallengeUIModel {
	title: string;
	description: string;
	difficulty: string;
	_id?: string;
}


const DUMMY_CTFS = new Array(14).fill(null).map((_, i) => ({
	_id: i,
	difficulty: i % 2 === 0 ? "Beginner" : "Expert",
	title: "Damn Vulnerable Defi",
	description:
		"Damn Vulnerable DeFi is the wargame to learn offensive security of DeFi smart contracts in Ethereum. Featuring flash loans, price oracles, governance, NFTs, DEXs, lending pools, smart contract wallets, timelocks, and more!",
	active: true,
	author: "tinchoabbette",
	image:
		i % 2 === 0
			? "https://cdn.midjourney.com/d29e75d1-f213-4f9e-87b5-b83c44f8fd08/0_1.png"
			: "https://cdn.midjourney.com/48ccff95-c80c-4549-b113-828c37e120a7/0_3.png",
	challenges: new Array(14).fill(null).map((_, i) => ({
		title: i % 2 === 0 ? "Truster" : "Wallet mining 2",
		description:
			"More and more lending pools are offering flash loans. In this case, a new pool has launched that is offering flash loans of DVT tokens for free.\\nThe pool holds 1 million DVT tokens. You have nothing.\\n\\nTo pass this challenge, take all tokens out of the pool. If possible, in a single transaction.",
		difficulty: "Medium"
	}))
}));

export const DUMMY_PARTICIPANTS = new Array(30).fill(null).map((_, i) => ({
	rank: i + 1,
	profilePicture:
		"https://www.artnews.com/wp-content/uploads/2021/08/BAYC-8746.png",
	name: "saneryee",
	progress: 80,
	score: 1025
}));

interface CTFContextType {
	ctfs: any[];
	setCtfs: (val: any) => void;
	ctf: any;
	setCtf: (val: any) => void;
	openCtf: (val: any) => void;
	closeCtf: () => void;
	ctfSidebarWidth: number;
	setCtfSidebarWidth: (val: number) => void;
}

const CTFContext = createContext<CTFContextType>({
	ctfs: null,
	setCtfs: () => {},
	ctf: null,
	setCtf: () => {},
	openCtf: () => {},
	closeCtf: () => {},
	ctfSidebarWidth: 0,
	setCtfSidebarWidth: () => {}
});

export const CTFContextProvider: React.FC<{ children: React.ReactNode }> = ({
	children
}) => {
	const [username, setUsername] = useAtom(usernameAtom);

	const [ctfs, setCtfs] = useState([]);
	const [ctfsLoaded, setCtfsLoaded] = useState<boolean>(false);
	const [ctf, setCtf] = useState(null);
	const [ctfSidebarWidth, setCtfSidebarWidth] = useState(72);

	// Load CTFs list
	useEffect(() => {
		if (!!username && !ctfsLoaded) {
			// loadCtfs();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [username, ctfsLoaded]);

	const loadCtfs = async () => {
		const contests = await getContests();
		const concatenatedChallengeIds: string[] = contests.reduce(
			(accumulator: string[], currentContest: Contest) => {
			  return accumulator.concat(currentContest.challengeIds);
			},
			[]
		);
		const challenges = await getChallengesByIds(concatenatedChallengeIds);
		const contestsForUI: CTFUIModel[] = contests.map((contest) => {
			const challengesForContest = challenges.filter((challenge) => contest.challengeIds.includes(challenge._id));
			return {
				...contest,
				challenges: challenges.map((challenge) => {
					return {
						...challenge
					};
				}),
			};
		});

		setCtfs(contestsForUI);
	};

	const openCtf = (ctfId: string) => {
		setCtf(DUMMY_CTFS[0]);
	};

	const closeCtf = () => {
		setCtf(null);
	};

	return (
		<CTFContext.Provider
			value={{
				ctfs,
				setCtfs,
				ctf,
				setCtf,
				openCtf,
				closeCtf,
				ctfSidebarWidth,
				setCtfSidebarWidth
			}}
		>
			{children}
		</CTFContext.Provider>
	);
};

export const useCTFContext = () => useContext<CTFContextType>(CTFContext);
