import { Icon } from "@iconify/react";
import {
	Box,
	Button,
	Grow,
	IconButton,
	Stack,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import { isFileSolidity } from "Resources/Helpers";
import { useAi } from "hooks/data/useAi";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { openedTabAtom } from "store/atoms/UiAtoms";
import { aiMessagesAtom } from "store/atoms/aiAtoms";
import { ToolboxCloseButton } from "../..";
import AIChatBox from "./AIChatBox";
import SettingsBox from "./SettingsBox";
import RateLimitBox from "./RateLimitBox";

const Header = ({ settingsOpen, setSettingsOpen }) => {
	const theme = useTheme();

	const [{ projectFilePath }] = useAtom(openedTabAtom);

	const [atomMessages] = useAtom(aiMessagesAtom);

	const { clearAiChat } = useAi();

	return (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			spacing={0.6}
		>
			<Typography variant="h6">AI Assistant</Typography>

			<Box sx={{ flex: 1 }} />

			{!!atomMessages?.[projectFilePath]?.length && (
				<Tooltip title="Clear chat">
					<IconButton
						onClick={() => clearAiChat(projectFilePath)}
						sx={{ p: 0, height: 26, width: 26 }}
					>
						<Icon icon="ri:chat-delete-fill" fontSize={20} />
					</IconButton>
				</Tooltip>
			)}

			<Tooltip title="Open AI settings" enterDelay={500} enterNextDelay={500}>
				<IconButton
					onClick={() => setSettingsOpen((prev) => !prev)}
					sx={{ p: 0, height: 26, width: 26 }}
				>
					<Icon
						icon="ic:sharp-settings"
						fontSize={20}
						color={
							settingsOpen
								? theme.palette.primary.main
								: theme.palette.text.primary
						}
					/>
				</IconButton>
			</Tooltip>

			<ToolboxCloseButton />
		</Stack>
	);
};

const AITool: React.FC = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [settingsOpen, setSettingsOpen] = useState(false);

	const [{ openedFile, projectFilePath }] = useAtom(openedTabAtom);

	const { loadUserAiSettings } = useAi();

	// Load AI settings
	useEffect(() => {
		loadUserAiSettings();
	}, []);

	if (!openedFile) {
		return (
			<Box>
				<Header settingsOpen={settingsOpen} setSettingsOpen={setSettingsOpen} />
				<Typography sx={{ color: "error.light" }}>Select a file.</Typography>
			</Box>
		);
	}

	if (!isFileSolidity(projectFilePath)) {
		return (
			<Box>
				<Header settingsOpen={settingsOpen} setSettingsOpen={setSettingsOpen} />
				<Typography sx={{ color: "success.light" }}>
					Please select a Solidity file.
				</Typography>
			</Box>
		);
	}

	return (
		<Stack sx={{ height: "100%", position: "relative" }}>
			<Header settingsOpen={settingsOpen} setSettingsOpen={setSettingsOpen} />

			<Grow in={!!settingsOpen} timeout={300}>
				<Box
					sx={{
						zIndex: 999,
						position: "absolute",
						top: 30,
						right: 0,
						left: 0,
						width: "100%"
					}}
				>
					{settingsOpen && (
						<SettingsBox onClose={() => setSettingsOpen(false)} />
					)}
				</Box>
			</Grow>

			<RateLimitBox />

			<AIChatBox />
		</Stack>
	);
};

export default React.memo(AITool);
