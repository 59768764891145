import AddIcon from "@mui/icons-material/Add";
import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
	useTheme
} from "@mui/material";
import { useChecklist } from "hooks/data/useChecklist";
import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { checklistGroupsAtom } from "store/atoms/checklistAtoms";
import ChecklistGroup from "./ChecklistGroup";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const NewGroupView: React.FC<{ handleSave: any; setAddGroupMode: any }> = ({
	handleSave,
	setAddGroupMode
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [value, setValue] = useState("");

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		handleSave(value);
	};

	return (
		<form onSubmit={handleSubmit}>
			<Stack
				spacing={1}
				sx={{
					p: 1.5,
					bgcolor: "background.default",
					borderRadius: "8px"
				}}
			>
				<TextField
					placeholder="New checklist group name"
					name="value"
					value={value}
					onChange={(e) => setValue(e.target.value)}
					fullWidth
					sx={{ borderWidth: "0px !important" }}
					InputProps={{
						autoFocus: true,
						sx: {
							height: 40,
							bgcolor: "background.paper",
							borderRadius: "8px",
							"& fieldset": {
								borderRadius: "8px",
								borderWidth: 1,
								borderStyle: "solid",
								borderColor:
									themeMode === "dark"
										? "background.paper2"
										: "background.paper"
							}
						},
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									sx={{ color: "primary.main", height: 24, width: 22 }}
									onClick={() => setAddGroupMode(false)}
								>
									<CloseIcon sx={{ fontSize: 18 }} />
								</IconButton>

								<IconButton
									sx={{ color: "primary.main", height: 24, width: 22 }}
									type="submit"
								>
									<CheckIcon sx={{ fontSize: 18 }} />
								</IconButton>
							</InputAdornment>
						)
					}}
				/>
			</Stack>
		</form>
	);
};

const ThisProjectTab = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [checklistsGroups] = useAtom(checklistGroupsAtom);

	const [addGroupMode, setAddGroupMode] = useState(false);

	const { loadChecklists, addNewGroup } = useChecklist();

	const bottomRef = useRef(null);

	useEffect(() => {
		loadChecklists();
	}, []);

	const handleNewGroup = async (val) => {
		await addNewGroup(val);
		setAddGroupMode(false);
	};

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		bottomRef.current?.scrollIntoView(false, { behavior: "smooth" });
	// 	}, 500);
	// }, [checklistsGroups]);

	return (
		<Stack>
			{/* Add checklist group button */}
			{!addGroupMode && (
				<Button
					variant="outlined"
					fullWidth
					onClick={() => setAddGroupMode(!addGroupMode)}
					sx={{
						borderRadius: "8px",
						color: "inherit",
						height: 40,
						borderColor:
							themeMode === "dark" ? "background.paper2" : "background.paper",
						boxShadow: 1
					}}
				>
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<AddIcon fontSize="small" />
						<Typography>Add checklist group</Typography>
					</Stack>
				</Button>
			)}

			{addGroupMode && (
				<NewGroupView
					handleSave={handleNewGroup}
					setAddGroupMode={setAddGroupMode}
				/>
			)}

			<Stack spacing={2}>
				{checklistsGroups.map((group, i) => {
					return <ChecklistGroup key={i} group={group} />;
				})}

				<Box ref={bottomRef} sx={{ m: "0px !important" }} />
			</Stack>
		</Stack>
	);
};

export default React.memo(ThisProjectTab);
