import { Button, Stack, Typography } from "@mui/material";
import { ToolboxCloseButton } from "components/Toolbox";
import { useCFG } from "hooks/data/useCFG";
import { useAtom } from "jotai";
import React from "react";
import { CFGAtom } from "store/atoms/CFGAtoms";

const CFGTool = () => {
	const [CFGData, setCFGData] = useAtom(CFGAtom);

	const { testing } = useCFG();

	const Header = () => (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{ mb: 2 }}
		>
			<Typography variant="h6">CFG</Typography>

			<ToolboxCloseButton />
		</Stack>
	);

	return (
		<Stack>
			<Header />

			<Stack spacing={1.5} sx={{ height: "100%", overflow: "auto" }}>
				<Typography>{CFGData?.getFunctions()?.reduce((previousValue, currentValue) => {
					return previousValue + '\n' + currentValue.name;
				}, "")}</Typography>

				{/* JOE: DO EVERYTHING IN HERE */}
				<Button onClick={testing}>Click me</Button>
			</Stack>
		</Stack>
	);
};

export default React.memo(CFGTool);
