import { addErrorNotification } from "Resources/Notifications";
import {
	createWhiteboard,
	deleteProjectWhiteboardById,
	loadProjectWhiteboards,
	updateWhiteboard
} from "Resources/ServerInterface";
import { mixpanelAtom } from "atoms";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import { projectAtom } from "store/atoms/projectToolAtoms";
import { whiteboardAtom } from "store/atoms/whiteboardAtoms";

export function useWhiteboard() {
	const setWhiteboards = useSetAtom(whiteboardAtom);
	const [mixpanel] = useAtom(mixpanelAtom);

	const [project] = useAtom(projectAtom);

	const getBoard = (whiteboardId: string) => {
		let whiteboards = [];

		setWhiteboards((old) => {
			whiteboards = old;
			return old;
		});

		return whiteboards.find((i) => i._id === whiteboardId);
	};

	const loadWhiteboards = async (projectID: string = project.id) => {
		const _whiteboards = await loadProjectWhiteboards(projectID);

		const _sortedWhiteboards = _whiteboards.sort(
			(a, b) => +new Date(a.updatedAt) - +new Date(b.updatedAt)
		);

		const _allWhiteboards = _sortedWhiteboards.reverse();

		setWhiteboards(_allWhiteboards);

		return _allWhiteboards;
	};

	const createDrawing = async (
		title: string,
		value: string,
		files: string,
		appState: string
	) => {
		try {
			return await createWhiteboard(
				{
					projectID: project.id,
					title,
					value,
					files,
					appState
				},
				project.privateMode,
				mixpanel
			).then(async () => {
				return await loadWhiteboards().then((res) => res[0]);
			});
		} catch (error) {
			console.log(error);
			addErrorNotification("Error", error.message);
		}
	};

	const editDrawing = async (
		whiteboardId: string,
		title: string,
		value: string,
		files: string,
		appState: string
	) => {
		try {
			return await updateWhiteboard(
				{
					projectID: project.id,
					value,
					title,
					files,
					appState,
					_id: whiteboardId
				},
				project.privateMode
			).then(async () => {
				// addSuccessNotification("Success", "Drawing Updated!");
				await loadWhiteboards();
			});
		} catch (error) {
			console.log(error);
		}
	};

	const deleteDrawing = async (whiteboardId: string) => {
		try {
			return await deleteProjectWhiteboardById(whiteboardId).then(async () => {
				// addSuccessNotification("Success", "Drawing deleted!");
				await loadWhiteboards();
			});
		} catch (error) {
			console.log(error);
		}
	};

	return {
		getBoard,
		loadWhiteboards,
		createDrawing,
		editDrawing,
		deleteDrawing
	};
}
