import { MixpanelEvents } from "./mixpanelEvents";

export const toolOpenedEvent = (mixpanel, tool: string, projectId: string) => {
	mixpanel.track(MixpanelEvents.ToolOpened, {
		tool,
		projectId
	});
};

export const slitherScanSuccessEvent = (
	mixpanel,
	projectId: string,
	withSlitherin = false
) => {
	mixpanel.track(MixpanelEvents.SlitherScanSuccess, {
		projectId,
		withSlitherin
	});
};

export const aderynScanSuccessEvent = (
	mixpanel,
	projectId: string,
	withSlitherin = false
) => {
	mixpanel.track(MixpanelEvents.AderynScanSuccess, {
		projectId,
		withSlitherin
	});
};

export const analyzerScanSuccessEvent = (
	mixpanel,
	projectId: string
) => {
	mixpanel.track(MixpanelEvents.AnalyzerScanSuccess, {
		projectId
	});
};

export const slitherScanFailureEvent = (
	mixpanel,
	projectId: string,
	error: string
) => {
	mixpanel.track(MixpanelEvents.SlitherScanFailure, {
		projectId,
		error
	});
};

export const aderynScanFailureEvent = (
	mixpanel,
	projectId: string,
	error: string
) => {
	mixpanel.track(MixpanelEvents.AderynScanFailure, {
		projectId,
		error
	});
};

export const analyzerScanFailureEvent = (
	mixpanel,
	projectId: string,
	error: string
) => {
	mixpanel.track(MixpanelEvents.AnalyzerScanFailure, {
		projectId,
		error
	});
};

export const getStorageSlotsSuccessEvent = (mixpanel, projectId: string) => {
	mixpanel.track(MixpanelEvents.StorageSlotsSuccess, {
		projectId
	});
};

export const getStorageSlotsFailureEvent = (
	mixpanel,
	projectId: string,
	error: string
) => {
	mixpanel.track(MixpanelEvents.StorageSlotsFailure, {
		projectId,
		error
	});
};

export const aiScanSuccessEvent = (mixpanel, projectId: string) => {
	mixpanel.track(MixpanelEvents.AIScanSuccess, {
		projectId
	});
};

export const aiScanFailureEvent = (
	mixpanel,
	projectId: string,
	error: string
) => {
	mixpanel.track(MixpanelEvents.AIScanFailure, {
		projectId,
		error
	});
};

export const noteCreatedEvent = (
	mixpanel,
	projectId: string,
	noteType: string
) => {
	mixpanel.track(MixpanelEvents.NoteCreated, {
		projectId,
		noteType
	});
};

export const projectImportSuccessEvent = (
	mixpanel,
	projectSource: string,
	privateMode: boolean
) => {
	mixpanel.track(MixpanelEvents.ProjectImportSuccess, {
		projectSource,
		privateMode
	});
};

export const projectImportFailureEvent = (
	mixpanel,
	projectSource: string,
	error: string
) => {
	mixpanel.track(MixpanelEvents.ProjectImportFailure, {
		projectSource,
		error
	});
};

export const partnerImportLinkClickedEvent = (
	mixpanel,
	projectSource: string
) => {
	mixpanel.track(MixpanelEvents.PartnerImportLinkClicked, {
		projectSource
	});
};

export const projectOpenedEvent = (mixpanel, projectId: string) => {
	mixpanel.track(MixpanelEvents.ProjectOpened, {
		projectId
	});
};

export const authEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.SignedUp, {
		...data
	});
};

export const noteAddedFromToolEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.NoteAdded, {
		...data
	});
};

export const notepadOpenedFromCodeEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.NotepadOpenedFromTool, {
		...data
	});
};

export const findingAddedEvent = (mixpanel) => {
	mixpanel.track(MixpanelEvents.FindingAdded);
};

export const whiteboardAddedEvent = (mixpanel) => {
	mixpanel.track(MixpanelEvents.WhiteboardAdded);
};

export const graphCreatedEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.GraphCreated, {
		...data
	});
};

export const messageSentToAIEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.MessageSentToAI, {
		...data
	});
};

export const testCreatedEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.TestCreated, {
		...data
	});
};

export const testUpdatedEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.TestUpdated, {
		...data
	});
};

export const testRanEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.TestRan, {
		...data
	});
};

export const templateCollectionCreatedEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.TemplateCollectionCreated, {
		...data
	});
};

export const templateAddedToCollectionEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.TemplateAddedToCollection, {
		...data
	});
};

export const templateCollectionDeletedEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.TemplateCollectionDeleted, {
		...data
	});
};

export const checklistGroupCreatedEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.ChecklistGroupCreated, {
		...data
	});
};

export const checklistGroupDeletedEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.ChecklistGroupDeleted, {
		...data
	});
};

export const checklistAddedToLibraryEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.ChecklistAddedToLibrary, {
		...data
	});
};

export const checklistRemovedFromLibraryEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.ChecklistRemovedFromLibrary, {
		...data
	});
};

export const aiSliderModifiedEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.AISliderModified, {
		...data
	});
};

export const codeChallengeOpenedEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.CodeChallengeOpened, {
		...data
	});
};

export const codeChallengeSubmittedEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.CodeChallengeSubmitted, {
		...data
	});
};

export const singleSimulationRanEvent = (mixpanel, data: object) => {
	mixpanel.track(MixpanelEvents.SingleSimulationRan, {
		...data
	});
};