import { Box, Divider, Grid, Skeleton, Typography } from "@mui/material";
import { Challenge, getChallenges } from "Resources/ServerInterfaceChallenge";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { challengesAtom } from "store/atoms/BugChallengeAtoms";
import ChallengesCard from "./ChallengesCard";
import { StyledGridContainer } from "./ContestsBountiesProjects";

const ChallengesProjects = () => {
	const [challenges, setChallenges] = useAtom(challengesAtom);

	useEffect(() => {
		const load = async () => {
			try {
				const _challenges: Challenge[] = await getChallenges();

				if (_challenges?.length) setChallenges(_challenges);
			} catch (error) {
				console.log("Error fetching challenges", error);
			}
		};

		load();
	}, []);

	// No challanges
	// if (true) {
	// 	return (
	// 		<Stack alignItems="center" justifyContent="center" sx={{ pt: 2 }}>
	// 			<Typography variant="h6" sx={{ textAlign: "center" }}>
	// 				No challenges at this time. Please check back later. <br /> <br />
	// 				Submissions from Bug Challenge #1 are under review.
	// 			</Typography>
	// 		</Stack>
	// 	);
	// }

	// Skeletons
	if (false) {
		return (
			<Grid container spacing={2}>
				{Array(8)
					.fill("")
					?.map((_, i) => (
						<Grid item xs={12} sm={6} lg={4} xl={3} key={i}>
							<Skeleton
								variant="rectangular"
								width={"100%"}
								height={"256px"}
								sx={{ borderRadius: "8px" }}
							/>
						</Grid>
					))}
			</Grid>
		);
	}

	const activeLength = challenges.filter(
		(challenge) => !challenge.solutionWriteup
	).length;
	const expiredLength = challenges.filter(
		(challenge) => challenge.solutionWriteup
	).length;

	return (
		<Box>
			{/* Active and Upcoming */}
			<Typography sx={{ mb: 1.5, "& span": { fontWeight: 600 } }}>
				<span>{activeLength}</span> Ongoing Challenge
				{activeLength === 1 ? "" : "s"}
			</Typography>

			<StyledGridContainer fixedWidth={activeLength < 4}>
				{challenges?.map((data, i) => {
					if (!data.solutionWriteup) {
						return <ChallengesCard key={i} timeState="active" data={data} />;
					} else {
						return null;
					}
				})}
			</StyledGridContainer>

			<Divider sx={{ mt: 2, mb: 1.5 }} />

			{/* Expired */}
			<Typography sx={{ mb: 1.5, "& span": { fontWeight: 600 } }}>
				Past Challenges
			</Typography>

			<StyledGridContainer fixedWidth={expiredLength < 4}>
				{challenges?.map((data, i) => {
					if (data.solutionWriteup) {
						return <ChallengesCard key={i} timeState="expired" data={data} />;
					} else {
						return null;
					}
				})}
			</StyledGridContainer>
		</Box>
	);
};

export default ChallengesProjects;
