import { Grid, Stack, useTheme } from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import {
	importsLoadingAtom,
	isPrivateModeAtom
} from "store/atoms/ImportsAtoms";
import Features from "./Features";
import Form from "./Form";
import LatestImprovements from "./LatestImprovements";
import ProjectContent from "./ProjectContent";
import Stats from "./Stats";

const ProjectImportView = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [loading, setLoading] = useAtom(importsLoadingAtom);
	const setPrivateMode = useSetAtom(isPrivateModeAtom);

	return (
		<Stack sx={{ p: 2, height: "100%" }}>
			<Grid container rowGap={2} columnSpacing={2} sx={{ height: "100%" }}>
				{/* Left - Main content */}
				<Grid item xs={12} md={8} lg={8.5} xl={9.5} sx={{ height: "100%" }}>
					{/* Projects */}
					<ProjectContent />
				</Grid>

				{/* Right side */}
				<Grid
					item
					xs={12}
					md={4}
					lg={3.5}
					xl={2.5}
					sx={{ height: "100%", overflow: "auto" }}
				>
					<Stack spacing={2} sx={{ height: "100%" }}>
						<Stats />

						<Features />

						<LatestImprovements />
					</Stack>
				</Grid>
			</Grid>
		</Stack>
	);
};

export default ProjectImportView;
