import { Icon } from "@iconify/react";
import {
	Box,
	Collapse,
	Stack,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import React, { useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import {
	a11yDark,
	a11yLight
} from "react-syntax-highlighter/dist/esm/styles/hljs";

export const StyledBox = styled(Box)(({ theme }) => ({
	borderRadius: "8px",
	border: "1px solid",
	borderColor: theme.palette.divider,
	padding: theme.spacing(1.5),
	backgroundColor: theme.palette.background.default,
	width: "100%"
}));

export const StyledHighlighter = styled(SyntaxHighlighter)({
	background: "transparent !important",
	padding: "0px !important",
	margin: "0px !important"
});

const AssetChanges = ({ assetChanges }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [collapsed, setCollapsed] = useState(false);

	const toggleCollapse = () => {
		setCollapsed((old) => !old);
	};

	return (
		<Box sx={{ mb: 2 }}>
			<Stack
				direction="row"
				alignItems="center"
				spacing={0.7}
				onClick={toggleCollapse}
				sx={{ cursor: "pointer" }}
			>
				<Typography>Asset Changes</Typography>

				{!collapsed ? (
					<Icon icon="eva:arrow-up-fill" fontSize={16} />
				) : (
					<Icon icon="eva:arrow-down-fill" fontSize={16} />
				)}
			</Stack>

			<Collapse in={!collapsed} timeout="auto" unmountOnExit>
				<Stack spacing={1.5} sx={{ mt: 1 }}>
					<StyledBox>
						<StyledHighlighter
							language="json"
							style={themeMode === "dark" ? a11yDark : a11yLight}
						>
							{assetChanges}
						</StyledHighlighter>
					</StyledBox>
				</Stack>
			</Collapse>
		</Box>
	);
};

export default React.memo(AssetChanges);
