import React, { useState } from "react";
import CustomButton from "../CustomButton";
import { Icon } from "@iconify/react";
import { Box, IconButton, Popover, Stack, Typography } from "@mui/material";
import BadgesGroups from "./BadgesGroups";
import { useAtom } from "jotai";
import { openBadgeAtom } from "store/atoms/badgesAtom";
import BadgeFull from "./BadgeFull";

const BadgeButton = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const [selectedBadge, setBadge] = useAtom(openBadgeAtom);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	return (
		<>
			<CustomButton
				variant="contained"
				tooltip="Badges"
				sx={{
					background: "linear-gradient(45deg, #b88f26, #8b6e1e)",
					"&:hover": {
						background: "linear-gradient(45deg, #a47821, #7e5d19)"
					}
				}}
				onClick={handleClick}
				Icon={
					<Stack direction="row" spacing={0.5} alignItems="center">
						<Icon icon="fluent:hexagon-sparkle-24-regular" fontSize={20} />

						<Typography sx={{ fontWeight: 500 }}>Badges</Typography>

						{open ? (
							<Icon icon="eva:arrow-up-fill" fontSize={16} />
						) : (
							<Icon icon="eva:arrow-down-fill" fontSize={16} />
						)}
					</Stack>
				}
			/>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
				slotProps={{
					paper: {
						sx: {
							mt: 1,
							backgroundImage: "none",
							bgcolor: "background.paper",
							border: "1px solid #E5B129",
							// borderColor: "primary.main",
							borderRadius: "10px",
							width: { xs: 280, sm: 345, md: 370 },
							minHeight: { xs: 450, sm: 500 },
							boxShadow: "0px 0px 25px 3px rgba(229,177,41,0.5)"
						}
					}
				}}
			>
				{/* Header */}
				<Box sx={{ bgcolor: "rgba(229, 177, 41, 0.5)" }}>
					<Stack
						spacing={1}
						direction="row"
						alignItems="center"
						sx={{
							py: 1.1,
							px: 1.5,
							background:
								"linear-gradient(180deg, rgba(229, 177, 41, 0.5) 40.71%, rgba(255, 255, 255, 0.5) 130.94%)"
						}}
					>
						{!!selectedBadge && (
							<>
								<IconButton
									size="small"
									sx={{ p: 0 }}
									onClick={() => setBadge(null)}
								>
									<Icon icon="lets-icons:back" fontSize={20} />
								</IconButton>

								<Typography sx={{ fontWeight: 600 }}>
									{selectedBadge.name}
								</Typography>
							</>
						)}

						{!selectedBadge && (
							<>
								<Icon icon="fluent:hexagon-sparkle-24-regular" fontSize={20} />

								{/* <Typography sx={{ "& span": { fontWeight: 600 } }}>
									<span>1872</span> $WZRD
								</Typography> */}

								<Typography sx={{ fontWeight: 500 }}>Your Badges</Typography>
							</>
						)}
					</Stack>
				</Box>

				{!!selectedBadge && (
					<Box>
						<BadgeFull />
					</Box>
				)}

				{!selectedBadge && (
					<Stack spacing={1} sx={{ p: 1.5 }}>
						{/* Streak */}

						{/* Badges */}
						<BadgesGroups />
					</Stack>
				)}
			</Popover>
		</>
	);
};

export default React.memo(BadgeButton);
