import { Shadows, createTheme } from "@mui/material";
import themeTypography from "./typography";
import { CustomBackgroundType, CustomTypeText } from "./dark";

const lightTheme = createTheme({
	palette: {
		mode: "light",
		primary: {
			main: "#8870E6",
			light: "#E2D0FF",
			dark: "#5C48B9"
		},
		background: {
			default: "#F2F2F2",
			paper: "#FFFFFF",
			nav: "#E1DAFF",
			paper2: "#F0F0F0",
			selected: "#BDAEF8",
			selected2: "#E9DAFF"
		} as CustomBackgroundType,
		success: {
			main: "#1BCE8E",
			light: "#A4FFD9",
			dark: "#0EA67B"
		},
		error: {
			main: "#C95656",
			light: "#FFBABA",
			dark: "#B73A3A"
		},
		warning: {
			main: "#E5B129",
			light: "#FFEBA2",
			dark: "#C49C20"
		},
		info: {
			main: "#1E90FF",
			light: "#A0C9FF",
			dark: "#006BDB"
		},
		common: {
			white: "#FFFFFF",
			black: "#000000"
		},
		text: {
			primary: "#000000",
			secondary: "rgba(0, 0, 0, 0.8)",
			disabled: "rgba(0, 0, 0, 0.6)",
			inverse: "#FFFFFF"
		} as CustomTypeText
	},

	shadows: [
		"none",
		"0px 3px 4px 2px #0000001A",
		...Array(23).fill("none")
	] as Shadows,
	typography: themeTypography,
	components: {
		MuiInputBase: {
			styleOverrides: {
				input: {
					"&::placeholder": {
						opacity: 0.7
					}
				}
			}
		}
	}
});

export default lightTheme;
