import { Box, Stack } from "@mui/material";
import { ProjectTest } from "Resources/ServerInterfaceProjectTest";
import React from "react";
import MonacoEditor from "../MonacoEditor";
import TestPanel from "./TestPanel";

const TestEditor: React.FC<{ value: string; test: ProjectTest }> = ({
	value,
	test
}) => {
	return (
		<Stack sx={{ height: "100%" }}>
			{/* Test Panel */}
			<TestPanel
				testName={test?.testName}
				existingFile
				contentToCopy={value}
				testId={test?._id}
			/>

			<Box sx={{ flex: 1 }}>
				<MonacoEditor value={value} />
			</Box>
		</Stack>
	);
};

export default React.memo(TestEditor);
