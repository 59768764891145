import { parseFoundryResults } from "Resources/Helpers";
import {
	addErrorNotification,
	addSuccessNotification
} from "Resources/Notifications";
import { loadProjectFile } from "Resources/ServerInterface";
import {
	ProjectTest,
	ProjectTestResult,
	createProjectTest,
	deleteProjectTestById,
	getTestUnitsForProject,
	loadProjectTests,
	runTests,
	updateProjectTest
} from "Resources/ServerInterfaceProjectTest";
import { getFunctionsFromSolidity } from "Resources/SolidityParser";
import { mixpanelAtom } from "atoms";
import { useAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback, useEffect } from "react";
import { projectAtom } from "store/atoms/projectToolAtoms";
import {
	TestResult,
	testCompilerErrorAtom,
	testFilesAtom,
	testResultsAtom,
	testRunningAtom,
	testsInitializingAtom,
	testEnvVarsAtom
} from "store/atoms/testAtoms";
import { IDB_TEST_RESULTS } from "utils/constants";
import { useIdb } from "../useIdb";
import useSetAtom from "../useSetAtom";

const parseUnits = (fileText: string, testNames: any) => {
	const functions = getFunctionsFromSolidity(fileText).map(
		(aFunc) => aFunc.name
	);

	let filteredFunctions = functions.filter((aFunc) =>
		testNames.includes(aFunc)
	);

	// Something is probably wrong with solidity parser in this case
	// fall back on text search for function
	if (!functions.length) {
		filteredFunctions = testNames.filter((aFunc) =>
			fileText.includes(`${aFunc}(`)
		);
	}

	const newUnits = filteredFunctions.map((aFunc) => {
		const resultStatus: ProjectTestResult = ProjectTestResult.untested;
		return {
			testName: aFunc,
			status: resultStatus
		};
	});

	return newUnits;
};

export function useTests(propsProjectId = null) {
	const setTestRunning = useSetAtom(testRunningAtom);
	const setTestsInitializing = useSetAtom(testsInitializingAtom);
	const setTestCompilerError = useSetAtom(testCompilerErrorAtom);

	const setTestFiles = useSetAtom(testFilesAtom);
	const [testResults, setTestResults] = useAtom(testResultsAtom);
	const [mixpanel] = useAtom(mixpanelAtom);

	const testEnvVars = useAtomCallback(
		useCallback((get) => {
			const curr = get(testEnvVarsAtom);
			return curr;
		}, [])
	);

	const project = useAtomCallback(
		useCallback((get) => {
			const curr = get(projectAtom);
			return curr;
		}, [])
	);

	const [idbResults, setIdbResults] = useIdb(
		`${IDB_TEST_RESULTS}_${propsProjectId}`,
		{
			defaultValue: {}
		}
	);

	useEffect(() => {
		if (propsProjectId) {
			if (Object.keys(testResults)?.length) {
				setIdbResults(testResults);
			} else if (
				Object.keys(idbResults)?.length &&
				!Object.keys(testResults)?.length
			) {
				setTestResults(idbResults);
			}
		}
	}, [idbResults, testResults, propsProjectId]);

	const getTestNames = async () => {
		try {
			const projectID = project()?.id;

			return await getTestUnitsForProject(projectID);
		} catch (error) {
			setTestCompilerError(error);
			return [];
		}
	};

	const loadTests = async () => {
		try {
			const projectID = project()?.id;

			if (!projectID) return null;

			setTestCompilerError("");
			setTestsInitializing(true);

			const fetchedTests = await loadProjectTests(projectID);

			const _testNames = await getTestNames();

			// Parse units
			const _tests = [];

			for await (const testItem of fetchedTests) {
				let units = [];

				if (testItem?.originFile) {
					await loadProjectFile(testItem.originFile, project()).then(
						(loadedFile) => {
							units = parseUnits(loadedFile.text, _testNames);
						}
					);
				} else {
					units = parseUnits(testItem.testContents, _testNames);
				}

				_tests.push({ ...testItem, units });
			}

			setTestFiles(_tests);

			setTestsInitializing(false);

			// Set foundry results

			return _tests;
		} catch (error) {
			console.log("ERROR", error);

			setTestCompilerError(error);
			setTestsInitializing(false);
		}
	};

	const addNewTest = async (testName: string, testContents: string) => {
		try {
			const projectID = project()?.id;

			await createProjectTest(
				{ testName, testContents, projectID },
				mixpanel
			).then(async () => {
				// addSuccessNotification("Success", "Test Added!", 1000);
			});

			return await loadTests().then((res) => {
				if (!!res?.length) return res[res?.length - 1];
				return null;
			});
		} catch (error) {
			console.log(error);
		}
	};

	const editTest = async (testData: ProjectTest) => {
		try {
			const projectID = project()?.id;

			const testId = testData._id;

			await updateProjectTest(testData, mixpanel).then(async () => {
				addSuccessNotification("Success", "Test Updated!", 1000);
			});

			return await loadTests().then((res) => {
				if (!!res?.length) {
					return res.find((i) => i._id === testId);
				}
				return null;
			});
		} catch (error) {
			console.log(error);
		}
	};

	const deleteTest = async (testId: string) => {
		try {
			await deleteProjectTestById(testId).then(async () => {
				addSuccessNotification("Success", "Test Deleted!", 1000);
			});

			await loadTests();

			return testId;
		} catch (error) {
			console.log(error);
		}
	};

	const runAllTests = async (testFileId: string) => {
		const projectID = project()?.id;

		setTestRunning(true);

		const testResult = await runTests(
			projectID,
			testFileId,
			undefined,
			testEnvVars(),
			mixpanel
		);

		if (testResult.error) {
			addErrorNotification("Error", testResult.error);
			setTestRunning(false);
			return;
		}

		const testResultsParsed: TestResult[] = parseFoundryResults(testResult);

		setTestResults((old) => ({ ...old, [testFileId]: testResultsParsed }));

		setTestRunning(false);
	};

	const runOneTest = async (testFileId: string, name: string) => {
		const projectID = project()?.id;

		setTestRunning(true);

		const testResult = await runTests(
			projectID,
			testFileId,
			name,
			testEnvVars(),
			mixpanel
		);

		if (testResult.error) {
			addErrorNotification("Error", testResult.error);
			setTestRunning(false);
			return;
		}

		const testResultsParsed: TestResult[] = parseFoundryResults(testResult);

		setTestResults((old) => {
			const oldResultsForFile = Object.keys(old).includes(testFileId)
				? old[testFileId]
				: [];
			testResultsParsed.forEach((result) => {
				const oldIdx = oldResultsForFile.findIndex(
					(oldResult) => oldResult.testName === result.testName
				);
				if (oldIdx >= 0) {
					oldResultsForFile[oldIdx] = result;
				} else {
					oldResultsForFile.push(result);
				}
			});

			return {
				...old,
				[testFileId]: oldResultsForFile
			};
		});

		setTestRunning(false);
	};

	return {
		loadTests,
		addNewTest,
		editTest,
		deleteTest,
		runAllTests,
		runOneTest
	};
}
