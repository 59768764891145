export const groupDataBySlot = (data = {}) => {
	const groupedData = {};

	for (const key in data) {
		const item = data[key];
		const slot = item.slot;

		if (!groupedData[slot]) {
			groupedData[slot] = [];
		}

		groupedData[slot].push(item);
	}

	return groupedData;
};

export const stringToColorHash = (input: string) => {
	const colors: string[] = [
		"#333333", // Dark mode: Dark text
		"#ACACAC", // Light mode: Light gray text
		"#0066CC", // Dark mode: Blue text
		"#FF9900", // Light mode: Orange text
		"#990099", // Dark mode: Purple text
		"#00CC66", // Light mode: Green text
		"#FF3300", // Dark mode: Red text
		"#669900", // Light mode: Green text
		"#993366", // Dark mode: Brown text
		"#0099CC", // Light mode: Blue text
		"#FF6600", // Dark mode: Orange text
		"#3366FF", // Light mode: Blue text
		"#CC0066", // Dark mode: Magenta text
		"#66CC00", // Light mode: Green text
		"#FF3366", // Dark mode: Pink text
		"#FFCC00", // Light mode: Yellow text
		"#FF5050", // Light mode: Light red text
		"#669966", // Dark mode: Olive green text
		"#FF3399", // Light mode: Pink text
		"#006666", // Dark mode: Teal text
		"#FF6633", // Light mode: Light orange text
		"#660033", // Dark mode: Dark purple text
		"#FF99EE", // Light mode: Light pink text
		"#004080" // Dark mode: Navy blue text
	];

	let hash: number = 0;
	if (!input?.length) return hash;

	for (let i = 0; i < input.length; i++) {
		hash = input.charCodeAt(i) + ((hash << 5) - hash);
		hash = hash & hash;
	}

	hash = ((hash % colors.length) + colors.length) % colors.length;
	return colors[hash];
};
