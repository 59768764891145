import {
	Box,
	Button,
	Container,
	Dialog,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import { cacheVersionShouldBe } from "App";
import Wizard from "assets/images/404-wizard.png";
import { useState } from "react";
import { LOCALSTORAGE_CACHE_VERSION } from "utils/constants";
import hex from "utils/hexTransparency";

const clearCache = () => {
	caches.keys().then((names) => {
		names.forEach((name) => {
			caches.delete(name);
		});
	});

	// Update the latest cache version
	localStorage.setItem(LOCALSTORAGE_CACHE_VERSION, cacheVersionShouldBe);
};

export const clearAllCaches = async () => {
	const OLD_CACHE_VERSION = localStorage.getItem(LOCALSTORAGE_CACHE_VERSION);

	// Check for Promise support
	if (!window.Promise) {
		// Handle lack of Promise support
		return;
	}

	// Clear app cache and cache storage
	if ("caches" in window) {
		try {
			const cacheNames = await caches.keys();
			await Promise.all(cacheNames.map((name) => caches.delete(name)));
		} catch (error) {
			// Handle cache deletion error
			console.error("Error deleting caches:", error);
		}
	}

	// Clear cookies
	document.cookie.split(";").forEach(function (c) {
		document.cookie = c
			.replace(/^ +/, "")
			.replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
	});

	// Clear indexed db
	try {
		const dbs = await indexedDB.databases();
		await Promise.all(dbs.map((db) => indexedDB.deleteDatabase(db.name)));
	} catch (error) {
		// Handle indexedDB deletion error
		console.error("Error deleting indexedDB:", error);
	}

	// Clear local storage
	localStorage.clear();

	// Unregister service workers
	if ("serviceWorker" in navigator) {
		try {
			const registrations = await navigator?.serviceWorker?.getRegistrations();
			await Promise.all(
				registrations.map((registration) => registration.unregister())
			);
		} catch (error) {
			// Handle service worker unregister error
			console.error("Error unregistering service workers:", error);
		}
	}

	// Update the latest cache version
	localStorage.setItem(LOCALSTORAGE_CACHE_VERSION, cacheVersionShouldBe);

	// Only refresh the page if cache version ever existed
	if (!!OLD_CACHE_VERSION) {
		// Clear cache
		setTimeout(() => {
			window.location.reload();
		}, 300);
	}
};

const ErrorFallback = ({ error, resetErrorBoundary }) => {
	const theme = useTheme();
	const [expanded, setExpanded] = useState(false);

	const reloadPage = () => {
		setExpanded(false);
		window.location.reload();
	};

	return (
		<Box sx={{ bgcolor: "background.paper", height: "100%" }}>
			<Container maxWidth="sm" sx={{ py: 2, height: "100%" }}>
				<Stack
					spacing={2}
					alignItems="center"
					justifyContent="center"
					sx={{ height: "100%", textAlign: "center" }}
				>
					<Box
						component="img"
						src={Wizard}
						sx={{
							width: "100%",
							objectFit: "contain",
							maxWidth: 420,
							transition: "transform 1s",
							zIndex: 0,
							"&:hover": {
								transform: "scale(1.05)"
							}
						}}
					/>

					<Typography variant="h1">Oops, something went wrong.</Typography>

					<Typography sx={{ maxWidth: 450 }}>
						This page didn't return from a trip to the Ether so we suggest you
						return to the Audit Wizard
					</Typography>

					<Stack
						alignItems="center"
						spacing={1.3}
						sx={{ width: "100%", pt: 1 }}
					>
						<Button
							onClick={reloadPage}
							variant="contained"
							fullWidth
							sx={{ maxWidth: 450 }}
						>
							Reload Page
						</Button>

						<Button
							onClick={clearAllCaches}
							variant="contained"
							fullWidth
							sx={{ maxWidth: 450 }}
						>
							Clear cache and reload Page
						</Button>

						{error?.message && (
							<Button
								onClick={() => setExpanded(!expanded)}
								fullWidth
								sx={{ maxWidth: 450 }}
							>
								View error
							</Button>
						)}
					</Stack>

					<Dialog
						open={expanded}
						onClose={() => setExpanded(false)}
						fullWidth
						maxWidth="sm"
						sx={{
							"& .MuiPaper-root": {
								bgcolor: "transparent",
								backgroundImage: "none",
								boxShadow: "none"
							},
							"& .MuiBackdrop-root": {
								backgroundColor: `${theme.palette.background.paper}${hex["85%"]}`,
								backdropFilter: "blur(15px)"
							}
						}}
					>
						<Stack
							spacing={2}
							justifyContent="center"
							alignItems="center"
							sx={{ height: "100%" }}
						>
							<Typography variant="h6" sx={{ fontWeight: 600 }}>
								Error Message
							</Typography>

							<Typography sx={{ textAlign: "center" }}>
								{error.message || ""}
							</Typography>

							<Button onClick={() => setExpanded(false)}>Close</Button>
						</Stack>
					</Dialog>
				</Stack>
			</Container>
		</Box>
	);
};

export default ErrorFallback;
