import { Icon } from "@iconify/react";
import {
	Box,
	Button,
	Stack,
	Tooltip,
	Typography,
	styled,
	tooltipClasses,
	useTheme
} from "@mui/material";
import TenderlyLogo from "assets/images/tenderlyLogoGrey.svg";
import ChecklistModal from "components/ChecklistModal";
import SupportDropdown from "components/SupportDropdown";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { openedTabAtom, selectedToolAtom } from "store/atoms/UiAtoms";

const tools: {
	id: string;
	text: string;
	icon: string;
	image?: any;
	requiresSolFile?: boolean;
}[] = [
	{
		id: "AIScan",
		text: "AI Assistant",
		icon: "carbon:chip",
		requiresSolFile: true
	},
	{
		id: "CodeScan",
		text: "Scan",
		icon: "tabler:line-scan",
		requiresSolFile: true
	},
	{
		id: "Tests",
		text: "Tests",
		icon: "fluent:beaker-settings-20-filled"
	},
	{
		id: "Graph",
		text: "Graph",
		icon: "ph:graph-fill",
		requiresSolFile: true
	},
	{
		id: "Inspect",
		text: "Inspect",
		icon: "material-symbols:troubleshoot-rounded",
		requiresSolFile: true
	},
	{
		id: "Simulation",
		text: "Simulation",
		icon: "ci:triangle",
		image: TenderlyLogo,
		requiresSolFile: false
	}
	// {
	// 	id: "CFG",
	// 	text: "CFG",
	// 	icon: "tabler:graph",
	// 	requiresSolFile: false
	// }
];

export const StyledToolsButton = styled(Button)<{ selected?: boolean }>(
	({ theme, selected }) => ({
		p: 0,
		borderRadius: 0,
		height: 42,
		width: 42,
		minWidth: 0,
		color: theme.palette.text.primary,
		"&:hover": { color: theme.palette.primary.main },
		...(selected && {
			backgroundColor: theme.palette.background.paper,
			"&:hover": { backgroundColor: theme.palette.background.paper }
		})
	})
);

let hoverTimeout: NodeJS.Timeout;

const ToolSelector = () => {
	const theme = useTheme();

	const [toolipOpen, setTooltipOpen] = useState(false);

	const [hoveredTool, setHoveredTool] = useState(null);
	const [selectedTool, setSelectedTool] = useAtom(selectedToolAtom);

	const [{ isSolFileSelected }] = useAtom(openedTabAtom);

	const handleClose = () => {
		setTooltipOpen(false);
		clearTimeout(hoverTimeout);
	};

	const handleOpen = () => {
		hoverTimeout = setTimeout(() => {
			setTooltipOpen(true);
		}, 500);
	};

	const openTool = (toolId) => {
		setTooltipOpen(false);

		if (selectedTool === toolId) {
			setSelectedTool(null);
		} else {
			setSelectedTool(toolId);
		}
	};

	useEffect(() => {
		handleClose();
	}, [selectedTool]);

	return (
		<Box
			sx={{
				height: "100%",
				bgcolor: "background.default",
				pr: { xs: 0, sm: 1.5 },
				[theme.breakpoints.up("lg")]: {
					zIndex: { sm: theme.zIndex.drawer + 1 }
				}
			}}
		>
			<Stack
				alignItems="center"
				sx={{
					height: "100%",
					borderRadius: "10px",
					bgcolor: "background.nav",

					...(!!selectedTool && {
						ml: 0,
						borderTopLeftRadius: "0px",
						borderBottomLeftRadius: "0px"
					}),
					[theme.breakpoints.down("sm")]: {
						borderTopRightRadius: "0px",
						borderBottomRightRadius: "0px"
					}
				}}
				onMouseLeave={handleClose}
			>
				<Stack onMouseEnter={handleOpen}>
					{tools.map((tool, i) => {
						const selected = selectedTool === tool.id;
						const disabled = !!tool.requiresSolFile && !isSolFileSelected;

						return (
							<Tooltip
								key={i}
								title={
									<Typography
										variant="body2"
										sx={{ textAlign: "center", whiteSpace: "pre-line" }}
									>
										{tool.text}
										{disabled && " (.SOL file)"}
									</Typography>
								}
								placement="left"
								open={toolipOpen}
								slotProps={{
									tooltip: {
										sx: {
											[`&.${tooltipClasses.tooltip}`]: {
												bgcolor: "background.paper",
												color: "text.primary",
												border: "1px solid",
												borderColor: "background.paper2",
												boxShadow: "0px 4px 4px 0px #000000",
												borderRadius: "8px",
												...(hoveredTool === tool.id && {
													bgcolor: "background.default",
													borderColor: "primary.main"
												})
											}
										}
									}
								}}
							>
								<StyledToolsButton
									disabled={disabled}
									selected={selected}
									onMouseEnter={() => setHoveredTool(tool.id)}
									onMouseLeave={() => setHoveredTool(null)}
									onClick={() => {
										if (!disabled) openTool(tool?.id);
									}}
									sx={{
										borderTopRightRadius: i === 0 ? "10px" : 0
									}}
								>
									{tool.image ? (
										<Box
											component="img"
											src={tool.image}
											sx={{ height: 22, width: "100%", objectFit: "contain" }}
										/>
									) : (
										<Icon icon={tool.icon} fontSize={22} />
									)}
								</StyledToolsButton>
							</Tooltip>
						);
					})}
				</Stack>

				<Box sx={{ flex: 1 }} />

				<ChecklistModal
					showTooltip={toolipOpen}
					handleTooltipClose={handleClose}
					handleTooltipOpen={handleOpen}
				/>

				<Stack onMouseEnter={handleOpen}>
					<SupportDropdown
						showTooltip={toolipOpen}
						handleTooltipClose={handleClose}
					/>
				</Stack>
			</Stack>
		</Box>
	);
};

export default React.memo(ToolSelector);
