import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FieldValues, Resolver, useForm } from "react-hook-form";
import * as Yup from "yup";
import FormTextField from "../../formFields/FormTextField";

export interface BugChallengeFormType {
	startLine: string;
	endLine: string;
	description: string;
}

interface Props {
	handleSubmit: (data: BugChallengeFormType) => void;
	disabled?: boolean;
	edit?: boolean;
	values?: any;
}

const schema = Yup.object().shape({
	startLine: Yup.string().required("Start line number is required"),
	endLine: Yup.string().required("End line number is required"),
	description: Yup.string().required("Required")
});

const BugChallengeForm: React.FC<Props> = ({
	handleSubmit: propsHandleSubmit,
	disabled: PropsDisabled = false,
	edit,
	values = {}
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [disabled, setDisabled] = useState(PropsDisabled);
	const [loading, setLoading] = useState(false);

	const formOptions = {
		resolver: yupResolver(schema) as Resolver<BugChallengeFormType, FieldValues>
	};

	const {
		handleSubmit,
		register,
		control,
		setValue,
		getValues,
		watch,
		formState: { errors }
	} = useForm<FieldValues & BugChallengeFormType & any>(formOptions);

	useEffect(() => {
		setDisabled(PropsDisabled);
	}, [PropsDisabled]);

	const onSubmit = (data: BugChallengeFormType) => {
		try {
			setLoading(true);
			propsHandleSubmit?.(data);
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
			<Stack spacing={2}>
				{/* Line numbers */}
				<Stack direction={{ xs: "column", md: "row" }} spacing={1}>
					<FormTextField
						label="Start line in code"
						fullWidth
						disabled={disabled}
						required
						name="startLine"
						register={register}
						error={!!errors["startLine"]}
						helperText={errors["startLine"]?.message}
					/>

					<FormTextField
						label="End line in code"
						fullWidth
						disabled={disabled}
						required
						name="endLine"
						register={register}
						error={!!errors["endLine"]}
						helperText={errors["endLine"]?.message}
					/>
				</Stack>

				{/* Description */}
				<FormTextField
					label="What have you found?"
					fullWidth
					multiline
					rows={6}
					disabled={disabled}
					required
					name="description"
					register={register}
					error={!!errors["description"]}
					helperText={errors["description"]?.message}
				/>

				<LoadingButton
					loading={loading}
					type="submit"
					variant="contained"
					disabled={disabled}
					sx={{
						borderRadius: "8px",
						height: 39,
						...(themeMode === "light" && {
							color: "text.primary",
							bgcolor: "background.paper",
							"&:hover": {
								bgcolor: "background.paper2"
							}
						})
					}}
				>
					{disabled ? "Already submitted" : "Submit answer"}
				</LoadingButton>
			</Stack>
		</form>
	);
};

export default BugChallengeForm;
