import monacoType from "@auditware/CodeEditorCore/types/monaco";
import { Monaco } from "@monaco-editor/react";
import { editor } from "monaco-editor";
import parser from "solidity-parser-antlr";
import path from "path";

export const provideDefinition = async (
	model: editor.ITextModel,
	position: monacoType.Position,
	monaco: Monaco,
	editor: editor.IStandaloneCodeEditor,
	ast?: any,
	callback?: any
): Promise<
	monacoType.languages.Definition | monacoType.languages.LocationLink[]
> => {
	const line = model.getLineContent(position.lineNumber); // full line content
	const clickedWord = model.getWordAtPosition(position); // word clicked with word and position

	const foundNode = findNodeInAst(
		ast,
		clickedWord.word,
		position.lineNumber,
		line
	);

	// 3. Path to file, create logic if in a seperate file
	const path = "path";

	if (foundNode?.visitType === "ImportDirective") {
		callback?.(foundNode);
	}

	if (foundNode?.loc) {
		return [
			{
				uri: monaco.Uri.parse(path),
				range: {
					startLineNumber: foundNode?.loc?.start?.line,
					endLineNumber: foundNode?.loc?.end?.line,
					startColumn: foundNode?.loc?.start?.column,
					endColumn: 1000000
				}
			}
		];
	}

	return [];
};

export const findNodeInAst = (
	ast: any,
	wordToFind: string,
	foundOnLine: number,
	lineContent: string,
	firstInstanceOnly: boolean = false
) => {
	let firstFoundNode = null;
	let _node = null;

	parser.visit(ast, {
		// VariableDeclarationStatement: (node) => {
		// 	if (
		// 		JSON.stringify(node).includes(wordToFind) &&
		// 		JSON.stringify(node).includes("240")
		// 	) {
		// 		console.log(node);
		// 	}
		// },
		VariableDeclaration: (node) => {
			if (
				node.name === wordToFind &&
				node.loc.start.line < foundOnLine &&
				(node.isIndexed ? node.typeName.type === "ElementaryTypeName" : true)
			) {
				_node = { ...node, visitType: "VariableDeclaration" };
				if (!firstFoundNode) firstFoundNode = _node;

				return;
			}
		},
		FunctionDefinition: (node) => {
			if (node.name === wordToFind && node.loc.start.line !== foundOnLine) {
				_node = { ...node, visitType: "FunctionDefinition" };
				if (!firstFoundNode) firstFoundNode = _node;

				return;
			}
		},
		StructDefinition: (node) => {
			if (node.name === wordToFind && node.loc.start.line !== foundOnLine) {
				_node = { ...node, visitType: "StructDefinition" };
				if (!firstFoundNode) firstFoundNode = _node;

				return;
			}
		},
		EnumDefinition: (node) => {
			if (node.name === wordToFind && node.loc.start.line !== foundOnLine) {
				_node = { ...node, visitType: "EnumDefinition" };
				if (!firstFoundNode) firstFoundNode = _node;

				return;
			}
		},
		EventDefinition: (node) => {
			if (node.name === wordToFind && node.loc.start.line !== foundOnLine) {
				_node = { ...node, visitType: "EventDefinition" };
				if (!firstFoundNode) firstFoundNode = _node;

				return;
			}
		},
		ImportDirective: (node) => {
			if (node.path.includes(wordToFind)) {
				if (
					node.loc.start.line === foundOnLine &&
					lineContent.startsWith("import")
				) {
					_node = { ...node, visitType: "ImportDirective" };
					if (!firstFoundNode) firstFoundNode = _node;
				}
			}
		}
	});

	return firstInstanceOnly ? firstFoundNode : _node;
};

const resolvePath = (currentPath, targetPath) => {
	return path.resolve(currentPath, targetPath);
};

export const getProjectPath = (
	nodePath: string,
	currentFilePath: string,
	importsFolderName?: string
) => {
	// If path starts with ./ or ../
	if (nodePath.startsWith(".")) {
		const resolvedPath = resolvePath(currentFilePath, `../${nodePath}`);
		return resolvedPath.replace(/^\//, "");
	} else {
		return `${importsFolderName}/${nodePath}`;
	}
};
