import { Button, Stack, Typography, useTheme } from "@mui/material";
import { ToolboxCloseButton } from "components/Toolbox";
import { useEditorTabs } from "hooks/ui/useEditorTabs";
import { useAtom } from "jotai";
import React from "react";
import { whiteboardAtom } from "store/atoms/whiteboardAtoms";
import BoardListItem from "./BoardListItem";

const WhiteboardTool: React.FC = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [whiteboards] = useAtom(whiteboardAtom);

	const { openCustomTab, findAndOpenTab } = useEditorTabs();

	const Header = () => (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{ mb: 2 }}
		>
			<Typography variant="h6">Whiteboard</Typography>

			<ToolboxCloseButton />
		</Stack>
	);

	const handleOpenWhiteboardClick = () => {
		const tabName = "New Whiteboard";
		const tabPath = "/whiteboard";

		findAndOpenTab(tabPath, () => openCustomTab(null, tabName, tabPath));
	};

	return (
		<>
			<Header />

			<Typography sx={{ mb: 3 }}>
				Create a drawing that you can add to your notes and findings.
			</Typography>

			{!!whiteboards.length && (
				<Stack spacing={2} sx={{ height: "100%", overflow: "scroll", flex: 1 }}>
					{whiteboards.map((item, i) => (
						<BoardListItem key={i} {...item} />
					))}
				</Stack>
			)}

			<Stack spacing={2}>
				<Button
					fullWidth
					variant={themeMode === "dark" ? "contained" : "text"}
					onClick={handleOpenWhiteboardClick}
					sx={{
						mt: 4,
						boxShadow: 1,
						borderRadius: "8px",
						bgcolor: "primary.main",
						color: "text.primary",
						...(themeMode === "light" && {
							bgcolor: "background.paper",
							"&:hover": {
								bgcolor: "background.paper2"
							}
						})
					}}
				>
					Create new Drawing
				</Button>
			</Stack>
		</>
	);
};

export default WhiteboardTool;
