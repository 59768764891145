import { testCollectionsAtom } from "store/atoms/testAtoms";
import useSetAtom from "../useSetAtom";
import {
	Template,
	addTemplateToCollection,
	createTemplateCollection,
	deleteTemplateCollectionById,
	loadTemplateCollections
} from "Resources/ServerInterfaceTemplateCollection";
import { mixpanelAtom } from "atoms";
import { useAtom } from "jotai";

export function useTestTemplate(propsProjectId = null) {
	const setTestCollections = useSetAtom(testCollectionsAtom);
	const [mixpanel] = useAtom(mixpanelAtom);

	const loadCollections = async () => {
		const collections = await loadTemplateCollections();
		setTestCollections(collections);
	};

	const addNewCollection = async (collectionTitle: string) => {
		await createTemplateCollection(collectionTitle, mixpanel);
		await loadCollections();
	};

	const deleteCollection = async (collectionId: string) => {
		await deleteTemplateCollectionById(collectionId, mixpanel);
	};

	const addNewTemplate = async (collectionId: string, template: Template) => {
		await addTemplateToCollection(template, collectionId, mixpanel);
		await loadCollections();
	};

	return {
		loadCollections,
		addNewCollection,
		deleteCollection,
		addNewTemplate
	};
}
