import { Icon } from "@iconify/react";
import {
	Box,
	Button,
	Stack,
	Tooltip,
	Typography,
	tooltipClasses,
	useTheme
} from "@mui/material";
import { useChecklist } from "hooks/data/useChecklist";
import { useAtom } from "jotai";
import React, { useEffect, useMemo, useState } from "react";
import { checklistGroupsAtom } from "store/atoms/checklistAtoms";
import Wave from "./wave.svg";
import hex from "utils/hexTransparency";

const ChecklistButton: React.FC<{
	showTooltip: any;
	handleTooltipClose: any;
	handleTooltipOpen: any;
	setAnchorEl: any;
}> = ({ showTooltip, handleTooltipClose, handleTooltipOpen, setAnchorEl }) => {
	const theme = useTheme();

	const [isHovered, setIsHovered] = useState(false);

	const [checklistsGroups] = useAtom(checklistGroupsAtom);

	const { loadChecklists, addNewGroup } = useChecklist();

	useEffect(() => {
		loadChecklists();
	}, []);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		handleTooltipClose?.();
		setAnchorEl(event.currentTarget);
	};

	const allChecklists = useMemo(() => {
		return checklistsGroups.map((group) => group.checklists).flat();
	}, [checklistsGroups]);

	const completedChecklists = useMemo(() => {
		return allChecklists.filter((i) => !!i.completed);
	}, [allChecklists]);

	const percentageCompleted =
		(completedChecklists.length / allChecklists.length) * 100 || 0;

	return (
		<Tooltip
			title="Checklist"
			placement="left"
			open={showTooltip}
			slotProps={{
				tooltip: {
					sx: {
						[`&.${tooltipClasses.tooltip}`]: {
							bgcolor: "background.paper",
							border: "1px solid",
							color: "text.primary",
							borderColor: "background.paper2",
							boxShadow: "0px 4px 4px 0px #000000",

							borderRadius: "8px",
							...(isHovered && {
								bgcolor: "background.default",
								borderColor: "primary.main"
							})
						}
					}
				}
			}}
		>
			<Button
				size="small"
				sx={{
					py: 0.6,
					// px: 1,
					minWidth: 0,
					color: "inherit",
					borderRadius: "8px",
					width: 34,
					mb: 1,
					position: "relative",
					overflow: "hidden",
					...(percentageCompleted === 100
						? {
								boxShadow: `0 0 3px 1px ${theme.palette.success.main}, 0 0 5px 1px ${theme.palette.success.main}`
						  }
						: {
								boxShadow: `0 0 5px 1px ${theme.palette.primary.main}, 0 0 10px 1px ${theme.palette.primary.main}`,
								"&:hover": {
									boxShadow: `0 0 10px 1px ${theme.palette.primary.main}, 0 0 10px 1px ${theme.palette.primary.main}`
								}
						  })
				}}
				onClick={handleClick}
				onMouseEnter={() => {
					setIsHovered(true);
					handleTooltipOpen();
				}}
				onMouseLeave={() => setIsHovered(false)}
			>
				<Box
					sx={{
						position: "absolute",
						left: 0,
						right: 0,
						bottom: 0,
						height: `${percentageCompleted}%`,
						width: "100%"
					}}
				>
					{/* Bg */}
					<Box
						sx={{
							left: 0,
							right: 0,
							bottom: 0,
							width: "100%",
							position: "absolute",
							borderBottomLeftRadius: "6px",
							borderBottomRightRadius: "6px",
							...(percentageCompleted === 100
								? {
										bgcolor: `${theme.palette.success.main}${hex["80%"]}`,
										height: "100%",
										borderRadius: "6px"
								  }
								: {
										bgcolor: "primary.main",
										height: "95%"
								  })
						}}
					/>

					{/* Wave */}
					{percentageCompleted !== 100 && (
						<Box
							component="img"
							src={Wave}
							sx={{
								mt: -0.8,
								position: "absolute",
								left: 0,
								right: 0,
								top: 0,
								width: "100%"
							}}
						/>
					)}
				</Box>

				<Stack
					spacing={0.5}
					alignItems="center"
					justifyContent="center"
					sx={{ zIndex: 1 }}
				>
					<Typography variant="body2" sx={{ fontWeight: 500 }}>
						{percentageCompleted.toFixed(0)}%
					</Typography>

					<Icon icon="solar:checklist-minimalistic-bold" fontSize={24} />
				</Stack>
			</Button>
		</Tooltip>
	);
};

export default ChecklistButton;
