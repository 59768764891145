import { getBadgeSummaryForUser } from "Resources/ServerInterfaceProfile";
import { useSetAtom } from "jotai";
import { badgesAtom } from "store/atoms/badgesAtom";

export function useBadges() {
	const setBadges = useSetAtom(badgesAtom);

	const loadBadges = async () => {
		try {
			await getBadgeSummaryForUser().then((res) => {
				setBadges(res || []);
			});
		} catch (error) {
			console.log(error);
		}
	};

	return { loadBadges };
}
