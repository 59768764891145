import { getFirebaseKeys } from "Resources/ServerInterface";
import { getAnalytics } from "firebase/analytics";
import { FirebaseOptions, getApp, getApps, initializeApp } from "firebase/app";
import {
	browserSessionPersistence,
	getAuth,
	setPersistence
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";
import { getStorage } from "firebase/storage";
import { useEffect, useState } from "react";

const useFirebase = () => {
	const [firebaseApp, setFirebaseApp] = useState(null);

	useEffect(() => {
		const initializeFirebase = async () => {
			try {
				// Check if Firebase has been initialized
				if (!getApps().length) {
					// Fetch Firebase keys asynchronously
					const firebaseKeys = await getFirebaseKeys();

					// Initialize Firebase with the fetched keys
					const firebaseConfig: FirebaseOptions = {
						apiKey: firebaseKeys.firebaseApiKey,
						authDomain: firebaseKeys.firebaseAuthDomain,
						projectId: firebaseKeys.firebaseProjectId,
						storageBucket: firebaseKeys.firebaseStorageBucket,
						messagingSenderId: firebaseKeys.firebaseMessagingSenderId,
						appId: firebaseKeys.firebaseAppId,
						measurementId: firebaseKeys.firebaseMeasurementId
					};

					const _app = initializeApp(firebaseConfig);

					// Set authentication persistence
					const auth = getAuth(_app);
					await setPersistence(auth, browserSessionPersistence);

					setFirebaseApp(_app);
				} else {
					const _app = getApp();
					setFirebaseApp(_app);
				}
			} catch (error) {
				console.error("Error initializing Firebase:", error);
			}
		};

		// Call the initialization function
		initializeFirebase();
	}, []);

	const requestNotificationPermission = async () => {
		try {
			const permission = await Notification.requestPermission();

			if (permission === "granted") {
				console.log("Notification permission granted.");
			} else {
				console.log("Notification permission denied.");
			}
		} catch (error) {
			console.error("Error requesting notification permission:", error);
		}
	};

	// Return Firebase objects once initialized
	return {
		auth: firebaseApp ? getAuth(firebaseApp) : null,
		db: firebaseApp ? getFirestore(firebaseApp) : null,
		storage: firebaseApp ? getStorage(firebaseApp) : null,
		analytics: firebaseApp ? getAnalytics(firebaseApp) : null,
		messaging:
			firebaseApp && !!navigator?.serviceWorker
				? getMessaging(firebaseApp)
				: null,
		requestNotificationPermission
	};
};

export default useFirebase;
