import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { closeSnackbar } from "notistack";
import React from "react";
import hex from "utils/hexTransparency";

interface Props {
	color: string;
	Icon: React.ReactNode;
	title: string;
	message: string;
	id: string;
	action?: Function;
}

const NotificationToast: React.FC<Props> = ({
	id,
	color,
	Icon,
	title,
	message,
	action,
}) => {
	return (
		<Box
			sx={{
				width: { xs: "100%", sm: 410 },
				display: "flex",
				alignItems: "center",
				position: "relative",
				p: 2,
				borderRadius: "12px",
				background: `linear-gradient(265.83deg, #262348 11.73%, ${color} 303.73%)`
			}}
		>
			{/* Icon */}
			<Stack
				alignItems="center"
				justifyContent="center"
				sx={{
					mr: 2.5,
					height: 40,
					width: 40,
					borderRadius: "50%",
					bgcolor: `${color}${hex["20%"]}`
				}}
			>
				<Stack
					alignItems="center"
					justifyContent="center"
					sx={{
						height: 24,
						width: 24,
						borderRadius: "50%",
						bgcolor: color
					}}
				>
					{Icon}
				</Stack>
			</Stack>

			{/* Text */}
			<Stack sx={{ maxWidth: 274 }}>
				{title && (
					<Typography
						variant="h6"
						sx={{ fontWeight: 600, wordWrap: "break-word" }}
					>
						{title}
					</Typography>
				)}

				<Typography
					sx={{
						wordBreak: "break-word",
						overflow: "hidden",
						textOverflow: "ellipsis",
						display: "-webkit-box",
						WebkitBoxOrient: "vertical",
						WebkitLineClamp: 3, // Limit message to 3 lines max
						maxHeight: "4.2em"
					}}
				>
					{message}
				</Typography>
				{action && <br />}
				{action ? action(id) : null}
			</Stack>

			{/* Close */}
			<IconButton
				size="small"
				sx={{
					position: "absolute",
					top: 10,
					right: 10,
					color: "text.secondary"
				}}
				onClick={() => closeSnackbar(id)}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</Box>
	);
};

export default NotificationToast;
