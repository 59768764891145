import { getUserAvatarSrc, loadUserTags } from "Resources/ServerInterface";
import { useBugChallenge } from "hooks/data/useBugChallenge";
import { useProjects } from "hooks/data/useProjects";
import { useTestTemplate } from "hooks/data/useTestTemplate";
import { useRenderCount } from "hooks/ui/useRenderCount";
import { useClearAtoms } from "hooks/useClearAtoms";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React, { createContext, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router";
import { NOTES_TAG_ITEMS, notesTagsAtom } from "store/atoms/NotesAtoms";
import {
	editorTabsAtom,
	expandedProjectTreeAtom,
	focusedEditorTabIndexAtom,
	openedTabAtom,
	searchAllBoxOpenAtom,
	searchFilesBoxOpenAtom
} from "store/atoms/UiAtoms";
import {
	projectAtom,
	projectLoadedAtom,
	projectsLoadedAtom
} from "store/atoms/projectToolAtoms";
import {
	STORAGE_EXPANDED_PATHS,
	STORAGE_FOCUSED_FILE,
	STORAGE_OPEN_TABS
} from "utils/constants";
import { userAvatarSrcAtom, usernameAtom } from "../atoms";
import { isMac, updateObjectInLocalStorage } from "../utils/helpersFunctions";
import { useBadges } from "hooks/data/useBadges";
import { useRateLimits } from "hooks/data/useRateLimits";

interface AuditToolContextType {}

export interface Tag {
	name: string;
	value: string;
	color: string;
	_id?: string;
}

const AuditToolContext = createContext<AuditToolContextType>({});

export const AuditToolContextProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const renders = useRenderCount();

	const { loadCollections } = useTestTemplate();
	const { loadProjects } = useProjects();
	const { loadUserChallengeSubmissions } = useBugChallenge();
	const { clearAtoms } = useClearAtoms();
	const { loadBadges } = useBadges();
	const { loadUserRateLimit } = useRateLimits();

	const [{ openedTab }] = useAtom(openedTabAtom);

	const [projectsLoaded] = useAtom(projectsLoadedAtom);
	const [projectLoaded] = useAtom(projectLoadedAtom);
	const [project] = useAtom(projectAtom);
	const [username] = useAtom(usernameAtom);
	const [openTabs] = useAtom(editorTabsAtom);
	const [expandedPaths] = useAtom(expandedProjectTreeAtom);
	const [focusedTabIndex] = useAtom(focusedEditorTabIndexAtom);

	const setNotesTags = useSetAtom(notesTagsAtom);
	const setUserAvatarSrc = useSetAtom(userAvatarSrcAtom);
	const setSearchAllBoxOpen = useSetAtom(searchAllBoxOpenAtom);
	const setSearchFilesBoxOpen = useSetAtom(searchFilesBoxOpenAtom);

	const navigate = useNavigate();
	const isOsMac = isMac();

	useHotkeys(
		isOsMac ? "meta+shift+f" : "ctrl+shift+f",
		() => {
			if (!!project && !!project?.id) {
				setSearchAllBoxOpen(true);
			}
		},
		[project]
	);

	useHotkeys(
		isOsMac ? "meta+alt+shift+f" : "ctrl+alt+shift+f",
		() => {
			if (!!project && !!project?.id) {
				setSearchFilesBoxOpen(true);
			}
		},
		[project]
	);

	useEffect(() => {
		return () => clearStates();
	}, []);

	useEffect(() => {
		if (!username) {
			clearStates(true);
		} else {
			loadCollections();
			loadBadges();
			loadUserRateLimit();

			getUserAvatarSrc().then((avatarSrc) => {
				if (!!avatarSrc) {
					setUserAvatarSrc(avatarSrc);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [username]);

	// Load projects list
	useEffect(() => {
		if (!!username && !projectsLoaded) {
			loadProjects();
			loadUserChallengeSubmissions();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [username, projectsLoaded]);

	// Update open tabs to storage
	useEffect(() => {
		if (project && projectLoaded) {
			const tabsToPutToStorage = openTabs?.filter(
				(i) =>
					!i.path.includes("graph-") &&
					!i.path.includes("missing-import") &&
					!i.path.includes("whiteboard")
			);

			updateObjectInLocalStorage(`${STORAGE_OPEN_TABS}_${project.id}`, {
				[project?.id]: tabsToPutToStorage
			});
		}
	}, [project, openTabs, projectLoaded]);

	// Update focused tab to storage
	useEffect(() => {
		if (project && projectLoaded) {
			updateObjectInLocalStorage(STORAGE_FOCUSED_FILE, {
				[project?.id]: focusedTabIndex
			});
		}
	}, [project, openTabs, focusedTabIndex, projectLoaded]);

	// Update expanded paths to storage
	useEffect(() => {
		if (project && projectLoaded) {
			updateObjectInLocalStorage(STORAGE_EXPANDED_PATHS, {
				[project?.id]: expandedPaths
			});
		}
	}, [project, expandedPaths, projectLoaded]);

	// Fetch users note tags
	useEffect(() => {
		if (!!username) {
			let tags = NOTES_TAG_ITEMS as Tag[];
			loadUserTags()
				.then((customTags) => {
					tags = tags.concat(customTags);
				})
				.finally(() => {
					setNotesTags(tags);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [username, setNotesTags]);

	const clearStates = (loggedOut = false) => {
		clearAtoms(loggedOut);
	};

	return (
		<AuditToolContext.Provider value={{}}>
			{!!openedTab?.name && (
				<Helmet>
					<title>
						{openedTab?.name || ""} - {project?.name || ""} - Audit Wizard
					</title>
				</Helmet>
			)}

			{!openedTab?.name && project?.name && (
				<Helmet>
					<title>{project?.name || ""} - Audit Wizard</title>
				</Helmet>
			)}

			{!openedTab?.name && !project?.name && (
				<Helmet>
					<title>Audit Wizard</title>
				</Helmet>
			)}

			{children}

			{/* <Box
				sx={{
					p: 1,
					borderRadius: "8px",
					bgcolor: "background.paper",
					zIndex: 99999999,
					position: "absolute",
					bottom: 30,
					right: 30
				}}
			>
				<Button onClick={() => clearAtoms()}>clearAtoms</Button>

				<Typography>{"RENDER " + renders}</Typography>
			</Box> */}
		</AuditToolContext.Provider>
	);
};

export const useAuditToolContext = () =>
	useContext<AuditToolContextType>(AuditToolContext);
