import { ArrowBack } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { getUsername } from "../../Resources/Auth";
import {
	changePassword,
	createPasswordChangeRequest
} from "../../Resources/ServerInterface";
import { usernameAtom } from "../../atoms";
import ResetPasswordForm, {
	ResetPasswordFormType
} from "../../components/forms/ResetPasswordForm";
import NewPasswordForm, {
	NewPasswordFormType
} from "../../components/forms/ResetPasswordForm/NewPasswordForm";

interface Props {
	goBack: Function;
}

const ResetPassword: React.FC<Props> = ({ goBack }) => {
	const [email, setEmail] = useState(null);
	const [loading, setLoading] = useState(false);
	const [verifyMode, setVerifyMode] = useState(false);

	const [_, setUsername] = useAtom(usernameAtom);

	const handleResetPassword = async (data: ResetPasswordFormType) => {
		const { email } = data;
		setLoading(true);
		setEmail(email);

		try {
			await createPasswordChangeRequest(email);
			setLoading(false);
			setVerifyMode(true);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const handleVerifyNewPassword = async (data: NewPasswordFormType) => {
		const { password, verification_code } = data;
		setLoading(true);

		await changePassword(email, verification_code, password);

		setLoading(false);
		const username = getUsername();
		if (!!username) {
			setUsername(username);
		}

		goBack?.();
	};

	return (
		<Stack alignItems="center" justifyContent="center" spacing={3}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="flex-start"
				sx={{ width: "100%" }}
			>
				<Typography
					variant="h6"
					sx={{ flexGrow: 1, textAlign: "center" }}
				>
					<strong>Reset Password</strong>
				</Typography>
			</Stack>

			<Typography sx={{flexGrow: 1, textAlign: "center"}}>
				{!verifyMode
					? "Please enter the email address associated with your account."
					: "If an account exists, a verficiation code was sent to your email. Please enter the code and your new password."}
			</Typography>

			{!verifyMode ? (
				<ResetPasswordForm
					handleSubmit={handleResetPassword}
					disabled={loading}
				/>
			) : (
				<NewPasswordForm
					handleSubmit={handleVerifyNewPassword}
					disabled={loading}
				/>
			)}
		</Stack>
	);
};

export default ResetPassword;
