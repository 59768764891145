import React from "react";
import LogInSignUp from ".";
import { useAtom } from "jotai";
import { loginModalOpenAtom } from "store/atoms/UiAtoms";
import { Dialog, IconButton, Slide, useTheme } from "@mui/material";
import hex from "utils/hexTransparency";
import CloseIcon from "@mui/icons-material/Close";

const SignInWithModal = () => {
	const theme = useTheme();

	const [loginModalOpen, setLoginModalOpen] = useAtom(loginModalOpenAtom);

	const handleClose = () => {
		setLoginModalOpen(false);
	};

	return (
		<Dialog
			open={!!loginModalOpen}
			onClose={handleClose}
			fullWidth
			maxWidth="sm"
			sx={{
				"& .MuiPaper-root": {
					position: "relative",
					borderRadius: "16px",
					backgroundImage: "none",
					// boxShadow: "none",
					px: { xs: 3, md: 5 },
					py: { xs: 3, md: 4 },
					maxWidth: 450
				},
				"& .MuiBackdrop-root": {
					bgcolor: `${theme.palette.background["nav"]}${hex["60%"]}`,
					backdropFilter: "blur(2px)"
				},
				"& .MuiDialog-container": {
					pt: 8,
					alignItems: "flex-start"
				}
			}}
		>
			<LogInSignUp full signUpMode={loginModalOpen === "signup"} />

			<IconButton
				onClick={handleClose}
				size="small"
				sx={{
					position: "absolute",
					top: "16px",
					right: "16px"
				}}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</Dialog>
	);
};

export default SignInWithModal;
