import {
	AppBar,
	Box,
	Button,
	Hidden,
	Stack,
	Toolbar,
	useTheme
} from "@mui/material";
import BetaChipDark from "assets/images/betaChipDark.png";
import BetaChipLight from "assets/images/betaChipLight.png";
import DarkBg from "assets/images/navBackground.png";
import { usernameAtom } from "atoms";
import UserAvatarMenu from "components/buttons/UserAvatarMenu";
import { appConfig } from "config";
import { useAtom, useSetAtom } from "jotai";
import React from "react";
import { useNavigate } from "react-router-dom";
import { loginModalOpenAtom } from "store/atoms/UiAtoms";
import AnnotationsGroup from "./AnnotationsGroup";
import { projectAtom } from "store/atoms/projectToolAtoms";
import SupportDropdown from "components/SupportDropdown";
import BadgeButton from "./AnnotationsGroup/BadgeButton";

export const NAVBAR_HEIGHT = 60;

const Navbar: React.FC = () => {
	const [username] = useAtom(usernameAtom);
	const [project] = useAtom(projectAtom);

	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const setLoginModalOpen = useSetAtom(loginModalOpenAtom);

	const navigate = useNavigate();

	return (
		<AppBar
			position="fixed"
			elevation={0}
			sx={{
				zIndex: (theme) => theme.zIndex.drawer + 1,
				height: NAVBAR_HEIGHT,
				bgcolor: "background.paper",
				overflow: "hidden"
			}}
		>
			{themeMode === "dark" && (
				<Box
					component="img"
					src={DarkBg}
					sx={{
						position: "absolute",
						top: 0,
						right: 0,
						bottom: 0,
						left: "-5px",
						objectFit: "cover",
						height: NAVBAR_HEIGHT + 10,
						width: "calc(100% + 10px)"
					}}
				/>
			)}

			<Toolbar
				disableGutters
				sx={{
					px: 3,
					height: NAVBAR_HEIGHT,
					minHeight: "0px !important",
					bgcolor: "transparent",
					...(themeMode === "light" && {
						boxShadow: 1,
						bgcolor: "background.paper",
						background:
							"linear-gradient(135deg, rgba(135,79,230,1) 0%, rgba(144,100,219,1) 8%, rgba(101,81,191,1) 38%, rgba(130,83,209,1) 73%, rgba(75,54,161,1) 100%)"
					})
				}}
			>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					spacing={2}
					sx={{ width: "100%" }}
				>
					{/* Left side - Logo */}
					<Stack direction="row" alignItems="center" spacing={2}>
						{/* Logo */}
						<Stack
							direction="row"
							component="a"
							onClick={() => navigate("/")}
							target="_blank"
							alignItems="center"
							spacing={0.3}
							sx={{
								textDecoration: "none",
								color: "inherit",
								cursor: "pointer"
							}}
						>
							<Box
								component="img"
								sx={{
									height: { xs: 20, sm: 22, md: 26 },
									objectFit: "contain"
								}}
								alt="Audit Wizard"
								src={
									themeMode === "dark"
										? appConfig.darkLogo
										: appConfig.lightLogo
								}
							/>
						</Stack>

						{/* Beta chip */}
						<Hidden smDown>
							<Box
								component="img"
								sx={{
									height: 30,
									objectFit: "contain",
									boxShadow: 1,
									borderRadius: "100px"
								}}
								alt="Audit Wizard"
								src={themeMode === "dark" ? BetaChipDark : BetaChipLight}
							/>
						</Hidden>
						{/* {username && (
							<>
								<Box width={5} height={10}></Box>
								<MagicButton />
							</>
						)} */}
					</Stack>

					{/* Right side - actions */}
					<Stack direction="row" alignItems="center" spacing={1.5}>
						{project?.id && <AnnotationsGroup />}

						{!!username && (
							<Hidden smDown>
								<BadgeButton />
							</Hidden>
						)}

						{!project?.id && <SupportDropdown navbarButton />}

						{/* User Menu */}
						{!!username && <UserAvatarMenu />}

						{/* Login button */}
						{!username && (
							<Hidden smDown>
								<Button
									onClick={() => setLoginModalOpen(true)}
									variant="outlined"
									sx={{
										borderRadius: "8px",
										color: "inherit",
										height: { xs: 34, md: 36, xl: 37 }
									}}
								>
									Log In
								</Button>
							</Hidden>
						)}

						{/* Sign up button */}
						{!username && (
							<Button
								onClick={() => setLoginModalOpen("signup")}
								variant="contained"
								sx={{
									borderRadius: "8px",
									height: { xs: 34, md: 36, xl: 37 },
									[theme.breakpoints.down("sm")]: {
										fontSize: 12,
										whiteSpace: "nowrap"
									}
								}}
							>
								Sign Up
							</Button>
						)}
					</Stack>
				</Stack>
			</Toolbar>
		</AppBar>
	);
};

export default Navbar;
