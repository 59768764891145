import { Icon } from "@iconify/react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	TextField,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import { useTerminal } from "hooks/ui/useTerminal";
import { useAtom } from "jotai";
import React from "react";
import { transactionsAtom } from "store/atoms/simulationAtoms";
import { convertToRelativeTime } from "./helpers";
import ArrowDown from "assets/icons/dottedArrowDown.png";
import TenderlyLogo from "assets/images/tenderlyLogo.svg";
import FadingBackground from "./FadingBackground";

const StyledBlock: React.FC<{ title: string; value: string | number }> = ({
	title,
	value
}) => {
	return (
		<Stack
			alignItems="center"
			justifyContent="center"
			spacing={0.3}
			sx={{
				borderRadius: "8px",
				py: 1,
				px: 2,
				width: "100%",
				height: "100%",
				bgcolor: "background.nav"
			}}
		>
			<Typography variant="body2" sx={{ color: "text.secondary", 	fontSize: '10px', }}>
				{title}
			</Typography>

			<Typography sx={{ fontWeight: 600, fontSize: '12px', }}>{value}</Typography>
		</Stack>
	);
};

const StyledOutput = ({ label, value, sx = {} }) => {
	return (
		<TextField
			size="small"
			variant="outlined"
			fullWidth
			label={label}
			value={value}
			sx={{ mt: 2, ...sx }}
			InputProps={{
				readOnly: true,
				sx: {
					borderRadius: "8px",
					"& input": { cursor: "default" }
				}
			}}
		/>
	);
};

const FromToArrow = () => (
	<Stack
		alignItems="center"
		justifyContent="center"
		sx={{ position: "relative", my: 0.5 }}
	>
		<Box component="img" src={ArrowDown} sx={{ height: 40 }} />

		<Box
			component="img"
			src={TenderlyLogo}
			sx={{ position: "absolute", height: 18, mt: -0.5 }}
		/>
	</Stack>
);

const TransactionsTab = () => {
	const theme = useTheme();

	const { openCustomTerminalTab } = useTerminal();
	const [transactions] = useAtom(transactionsAtom);

	const handleOpenInTerminal = (data) => {
		const id = data._id;
		const name = data.functionName || id;

		openCustomTerminalTab(
			`Simulation - ${name}`,
			`terminal/simulation2/${id}`,
			{
				type: "simulation2",
				content: data
			}
		);
	};

	return (
		<Stack spacing={1.5}>
			{transactions.map((item, i) => {
				return (
					<Box
						key={i}
						sx={{
							position: "relative",
							overflow: "hidden",
							borderRadius: "8px"
						}}
					>
						<Accordion
							// expanded={isExpanded}
							// onChange={(e, v) => toggleExpanded(i)}
							disableGutters
							sx={{
								width: "100%",
								borderRadius: "8px !important",
								backgroundImage: "none",
								bgcolor: "background.paper",
								border: "1px solid",
								borderColor: "background.nav",
								"&.MuiAccordion-root:before": {
									bgcolor: "transparent"
								}
							}}
						>
							<AccordionSummary
								expandIcon={<ArrowDropDownIcon />}
								sx={{
									"&:hover": {
										"& .showOnHover": {
											display: "inline-flex"
										}
									}
								}}
							>
								<Stack
									direction="row"
									alignItems="center"
									sx={{ width: "100%", mr: 1 }}
								>
									{/* Icon */}
									<Stack direction="row" alignItems="center" spacing={1}>
										{item.status ? (
											<Icon
												icon="icon-park-outline:check-one"
												color={theme.palette.success.main}
												fontSize={22}
											/>
										) : (
											<Icon
												icon="ic:outline-cancel"
												color={theme.palette.error.main}
												fontSize={22}
											/>
										)}

										<Typography>
											<Typography component="span" color="text.secondary">
												Function:
											</Typography>{" "}
											{item.functionName}
										</Typography>
									</Stack>

									<Box sx={{ flex: 1 }} />

									<Stack direction="row" alignItems="center" spacing={1}>
										<Typography
											sx={{ color: "grey", fontSize: "12px", mr: 0.5 }}
										>
											{convertToRelativeTime(item.createdAt)}
										</Typography>
									</Stack>
								</Stack>
							</AccordionSummary>

							<AccordionDetails sx={{ px: 1.5, pt: 0 }}>
								{/* Error */}
								{item.errorMessage && (
									<Typography
										sx={{
											whiteSpace: "pre-line",
											wordBreak: "break-all",
											color: theme.palette.error.main,
											mb: 1
										}}
									>
										Error: {item.errorMessage}
									</Typography>
								)}

								{/* Block */}
								<Stack
									spacing={1}
									direction="row"
									alignItems="center"
									justifyContent="space-between"
									sx={{ mb: 1 }}
								>
									{item.gasUsed && (
										<StyledBlock title="Gas Used" value={item.gasUsed} />
									)}

									{item.network && (
										<StyledBlock title="Network" value={item.network} />
									)}

									{item.blockNumber && (
										<StyledBlock
											title="Block Number"
											value={item.blockNumber}
										/>
									)}
								</Stack>

								<Button
									variant="outlined"
									sx={{
										height: 39,
										borderRadius: "8px",
										color: "text.primary",
										bgcolor: "background.paper",
										mt: 1,
										mb: 2.5,
										width: '100%',
										"&:hover": { bgcolor: "background.nav" }
									}}
									onClick={() => {
										handleOpenInTerminal(item);
									}}
								>
									<Stack direction="row" alignItems="center" spacing={1}>
										<Icon icon="gravity-ui:terminal" fontSize={18} />

										<Typography>Open in Terminal</Typography>
									</Stack>
								</Button>

								<StyledOutput label="From" value={item.from} sx={{ mt: 0 }} />

								<FromToArrow />

								<StyledOutput label="To" value={item.to} sx={{ mt: 0 }} />

								{!!item.value && <>
									<Divider sx={{ mt: 2 }} />
									<StyledOutput label="Value (wei)" value={item.value} /></>
								}
								
								{item.decodedOutput &&
									item.decodedOutput["__length__"] !== 0 && (
										<>
											<Divider sx={{ mt: 2 }} />
											<StyledOutput label={"Output"} value={JSON.stringify(item.decodedOutput)} />
										</>
								)}
							</AccordionDetails>
						</Accordion>

						<FadingBackground status={item.status ? "success" : "error"} />
					</Box>
				);
			})}
		</Stack>
	);
};

export default React.memo(TransactionsTab);
