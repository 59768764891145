import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";

const DUMMY_CHECKLIST = [
	{ name: "Compiler errors", status: "Passed" },
	{ name: "Possible delays in data delivery", status: "Passed" },
	{ name: "Oracle calls", status: "Failed" },
	{ name: "Front running", status: "Passed" },
	{ name: "Timestamp dependence", status: "Passed" },
	{ name: "Private user data leaks", status: "Passed" },
	{ name: "Methods execution permissions", status: "Passed" }
];

export const buildReportMarkdown = (data: any): string => {
	const {
		auditorLogo,
		auditorName,
		clientName,
		date,
		auditorTwitter,
		auditorGithub,
		auditorTelegram,
		auditorLinkedin,
		description,
		auditType,
		auditTimeline,
		evm,
		languages,
		documentationQuality,
		testQuality,
		commitHash,
		findings,
		checklist
	} = data;

	const auditorDetails = `## Auditor Details
  
  - **Auditor Name:** ${auditorName}
  - **Client Name:** ${clientName}
  - **Date:** ${date}
  - **Auditor Logo:** ${auditorLogo || "Not provided"}
  - **Auditor Twitter:** ${auditorTwitter || "Not provided"}
  - **Auditor Github:** ${auditorGithub || "Not provided"}
  - **Auditor Telegram:** ${auditorTelegram || "Not provided"}
  - **Auditor Linkedin:** ${auditorLinkedin || "Not provided"}
  `;

	const auditDetails = `
  ## Audit Details
  
  - **Description:** ${description}
  - **Audit Type:** ${auditType}
  - **Audit Timeline:** ${auditTimeline}
  - **EVM:** ${evm}
  - **Languages:** ${languages}
  - **Documentation Quality:** ${documentationQuality}
  - **Test Quality:** ${testQuality}
  - **Commit Hash:** ${commitHash}
  `;

	const findingsSection = `
  ## Findings
  
  ${findings
		.map(
			(finding) => `
  ### ${finding.name}
  
  - **Description:** ${finding.description}
  - **Recommendation:** ${finding.recommendation}
  - **Severity:** ${finding.severity}
  - **Location in Code:** ${finding.location}
  - **Status:** ${finding.status}
  `
		)
		.join("\n")}
  `;

	const markdownString = `
  # Smart Contract Audit Report
  
  ${auditorDetails}
  
  ${auditDetails}
  
  ${findingsSection}
  `;

	return markdownString;
};

export const getReportData = (storageValues, findings) => {
	const {
		date,
		auditTimeline,
		auditType,
		auditorName,
		clientName,
		commitHash,
		description,
		documentationQuality,
		evm,
		languages,
		testQuality,
		auditorLogo,
		auditorGithub,
		auditorLinkedin,
		auditorTelegram,
		auditorTwitter
	} = storageValues;

	const today = new Date();
	const formattedDate = format(today, "d-MMMM-yyyy", { locale: enGB });
	const capitalizedFormattedDate =
		formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

	return {
		auditorName,
		projectName: storageValues.clientName,
		clientName,
		description,
		date: capitalizedFormattedDate,
		auditorGithub,
		auditorLinkedin,
		auditorTelegram,
		auditorTwitter,
		auditDetails: [
			{ title: "auditType", value: auditType },
			{ title: "auditTimeline", value: auditTimeline },
			{ title: "documentationQuality", value: documentationQuality },
			{ title: "languages", value: languages },
			{ title: "evm", value: evm },
			{ title: "commitHash", value: commitHash },
			{ title: "testQuality", value: testQuality }
		],
		findings: findings.map((i) => ({
			name: i.name,
			description: i.description,
			recommendation: i.recommendation,
			severity: i.severity,
			location: i.location,
			status: i.status
		})),
		checklist: DUMMY_CHECKLIST
	};
};

const loadFile = async (url, callback) => {
	try {
		return await PizZipUtils.getBinaryContent(url, callback);
	} catch (error) {
		console.log(error);
	}
};

export const generateDocDocument = async (data) => {
	try {
		const url =
			"https://firebasestorage.googleapis.com/v0/b/auditwizard-1b2af.appspot.com/o/audit_templates%2Faudit_template.docx?alt=media&token=2a9f37e8-1163-4046-a85f-0237622ec754";

		await loadFile(url, function (error, content) {
			if (error) {
				console.log("ERROR", error);
			}
			const zip = new PizZip(content);
			const doc = new Docxtemplater(zip, {
				paragraphLoop: true,
				linebreaks: true
			});
			doc.render(data);
			const out = doc.getZip().generate({
				type: "blob",
				mimeType:
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			}); //Output the document using Data-URI
			saveAs(out, "Audit Report.docx");
		});
	} catch (error) {
		console.log(error);
	}
};
