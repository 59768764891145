import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, InputAdornment, TextField, useTheme } from "@mui/material";
import React, { useState } from "react";

const NewFolderForm: React.FC<{
	folderName: string;
	handleClose: any;
	handleSave: any;
	blacklistNames: any[];
}> = ({ folderName, handleClose, handleSave, blacklistNames }) => {
	const theme = useTheme();
	const [value, setValue] = useState<string>(folderName);
	const [nameError, setNameError] = useState(false);

	const handleNewFolderSave = () => {
		if (nameError) return null;

		handleSave(value);
		setNameError(false);
	};

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const _val = e.target.value;

		setValue(_val);

		if (blacklistNames?.includes(_val.trim().toLowerCase())) {
			setNameError(true);
		} else {
			setNameError(false);
		}
	};

	return (
		<TextField
			size="small"
			value={value}
			onChange={handleChange}
			onClick={(e) => e.preventDefault()}
			InputProps={{
				autoFocus: true,
				sx: {
					bgcolor: "background.paper",
					borderRadius: "8px",
					border: `1px solid ${theme.palette.primary.dark}`
				},
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							disabled={!!nameError}
							sx={{ color: "primary.main", height: 22, width: 20 }}
							onClick={handleClose}
						>
							<CloseIcon sx={{ fontSize: 18 }} />
						</IconButton>

						<IconButton
							disabled={!!nameError}
							sx={{ color: "primary.main", height: 22, width: 20 }}
							onClick={handleNewFolderSave}
						>
							<CheckIcon sx={{ fontSize: 18 }} />
						</IconButton>
					</InputAdornment>
				)
			}}
			error={nameError}
			helperText={nameError && "Folder name already exists"}
		/>
	);
};

export default React.memo(NewFolderForm);
