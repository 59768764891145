import CircleIcon from "@mui/icons-material/Circle";
import DescriptionIcon from "@mui/icons-material/Description";
import XIcon from "@mui/icons-material/X";
import {
	Box,
	Button,
	Stack,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import DiscordIconDark from "assets/icons/discordIconDark.png";
import DiscordIconLight from "assets/icons/discordIconLight.png";
import { appConfig } from "config";
import { useFetch } from "hooks/ui/useFetch";

const StyledButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	borderRadius: "10px",
	height: 80,
	width: "100%",
	padding: "8px",
	color: `${theme.palette.text.primary}!important`,
	"&:hover": {
		backgroundColor: theme.palette.background["paper2"]
	},
	[theme.breakpoints.up("lg")]: {
		height: 85
	}
}));

const Stats = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const { data, error } = useFetch<{ presence_count: number }>(
		"https://discord.com/api/guilds/962101971081392128/widget.json"
	);

	const discordOnlineCount = data?.presence_count || 0;

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			spacing={1.5}
			sx={{
				p: 2,
				borderRadius: "10px",
				width: "100%",
				bgcolor: "background.default"
			}}
		>
			{/* Docs */}
			<StyledButton
				variant="contained"
				onClick={() => window.open(appConfig.docsUrl, "_blank")}
			>
				<Stack alignItems="center" justifyContent="center" spacing={1}>
					<DescriptionIcon sx={{ fontSize: { xs: 18, md: 28 } }} />
					<Typography sx={{ lineHeight: "1rem" }}>Docs</Typography>
				</Stack>
			</StyledButton>

			{/* Discord */}
			<StyledButton
				variant="contained"
				onClick={() => window.open(appConfig.socials.discord, "_blank")}
			>
				<Stack alignItems="center" justifyContent="center" spacing={1}>
					<Box
						sx={{
							position: "relative",
							height: { xs: 22, md: 28 }
						}}
					>
						<Box
							component="img"
							src={themeMode === "dark" ? DiscordIconDark : DiscordIconLight}
							sx={{
								height: "100%",
								width: "100%",
								maxWidth: { xs: 27, xl: 29 },
								objectFit: "contain"
							}}
						/>

						{!!discordOnlineCount && (
							<Stack
								alignItems="center"
								justifyContent="center"
								sx={{
									position: "absolute",
									top: -2,
									right: -2,
									bgcolor: "background.paper",
									borderRadius: "50%",
									height: 14,
									width: 14
								}}
							>
								<CircleIcon sx={{ fontSize: 12, color: "success.main" }} />
							</Stack>
						)}
					</Box>

					{!!discordOnlineCount ? (
						<Typography
							sx={{ lineHeight: "1rem", "& span": { fontWeight: 600 } }}
						>
							<span>{discordOnlineCount}</span>
						</Typography>
					) : (
						<Typography sx={{ lineHeight: "1rem" }}>Discord</Typography>
					)}
				</Stack>
			</StyledButton>

			{/* X */}
			<StyledButton
				variant="contained"
				onClick={() => window.open(appConfig.socials.twitter, "_blank")}
			>
				<Stack alignItems="center" justifyContent="center" spacing={1}>
					<XIcon sx={{ fontSize: { xs: 18, md: 26, xl: 28 } }} />
					<Typography sx={{ lineHeight: "1rem" }}>Follow</Typography>
				</Stack>
			</StyledButton>
		</Stack>
	);
};

export default Stats;
