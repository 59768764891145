import forge from 'node-forge';
import bcrypt from 'bcryptjs';
import secureLocalStorage from 'react-secure-storage';
import { Finding } from 'components/Toolbox/Tools/FindingsTool';
import { Whiteboard } from 'store/atoms/whiteboardAtoms';

const IV_LENGTH = 12; // 12 bytes for GCM
const TAG_LENGTH = 16; // 16 bytes, 128 bits for GCM
const encryptedPrefix = "$PRIVATEMODE:";

const SALT_WORK_FACTOR = 10;

export function decryptPrivateMode(text: string): string {
    const key = secureLocalStorage.getItem("privateModeEncryptionKey") as string;

    if (!key) {
        return "";
    }
    if (!text) {
        return "";
    }

    if (!text.startsWith(encryptedPrefix)) {
        throw new Error('Invalid encrypted text format');
      }
    
      // Decode the base64 data after removing the prefix
      const decodedData = forge.util.decode64(text.substring(encryptedPrefix.length));
    
      // Extract IV, encrypted data, and tag
      const iv = decodedData.substring(0, IV_LENGTH);
      const encryptedText = decodedData.substring(IV_LENGTH, decodedData.length - TAG_LENGTH);
      const tag = decodedData.substring(decodedData.length - TAG_LENGTH);
    
      const decipher = forge.cipher.createDecipher('AES-GCM', key);
      decipher.start({ iv: iv, tag: tag });  // Provide the tag for authentication
    
      decipher.update(forge.util.createBuffer(encryptedText));
      if (!decipher.finish()) {
        throw new Error('Failed to decrypt');
      }
    
      return decipher.output.toString();
}

export function encryptPrivateMode(text: string): string {
    const key = secureLocalStorage.getItem("privateModeEncryptionKey") as string;

    if (!key) {
        return "";
    }
    if (!text) {
        return "";
    }
    
    const cipher = forge.cipher.createCipher('AES-GCM', key);

    const iv = forge.random.getBytesSync(IV_LENGTH);
    cipher.start({ iv: iv });
    cipher.update(forge.util.createBuffer(text));
    cipher.finish();
  
    const encryptedData = cipher.output.getBytes();
    const tag = cipher.mode.tag.getBytes();  // Get the authentication tag
  
    // Concatenate IV + encrypted data + tag and then base64 encode
    const combined = iv + encryptedData + tag;
    return encryptedPrefix + forge.util.encode64(combined);
  }

export function getPrivateModeValue(value: string): string {
	try {
		if (value?.startsWith(encryptedPrefix)) {
			return decryptPrivateMode(value);
		} else {
			return value;
		}
	} catch (e) {
        console.log(e);
		return "";
	}
}

export function generateEncryptionKey(length: number = 16): string {
    return forge.util.bytesToHex(forge.random.getBytesSync(length));
}

export async function hashEncryptionKey(key: string): Promise<string> {
    const salt = await bcrypt.genSalt(SALT_WORK_FACTOR);
    return await bcrypt.hash(key, salt);
}

export async function compareEncryptionKeyHash(key: string, storedHash: string): Promise<boolean> {
    return await bcrypt.compare(key, storedHash);
}

export function encryptProjectNotes(notes: any[]): any[] {
    return notes.map((note) => {
        return {
            title: note.title ? encryptPrivateMode(note.title) : undefined,
            location: note.location ? encryptPrivateMode(note.location) : undefined,
            noteContent: note.noteContent ? encryptPrivateMode(note.noteContent) : undefined,
            tag: note.tag ? encryptPrivateMode(note.tag) : undefined,
            tagColor: note.tagColor ? encryptPrivateMode(note.tagColor) : undefined,
            dateUpdated: note.dateUpdated,
        };
    });
}

export function decryptProjectNotes(notes: any[]): any[] {
    return notes.map((note) => {
        return {
            title: note.title ? getPrivateModeValue(note.title) : "",
            location: note.location ? getPrivateModeValue(note.location) : "",
            noteContent: note.noteContent ? getPrivateModeValue(note.noteContent) : "",
            tag: note.tag ? getPrivateModeValue(note.tag) : "",
            tagColor: note.tagColor ? getPrivateModeValue(note.tagColor) : "",
            dateUpdated: note.dateUpdated,
            _id: note._id,
        };
    });
}

export const encryptFinding = (finding: Finding): Finding => {
	const descriptionEncrypted = finding.description ? encryptPrivateMode(finding.description) : "";
	const sourceEncrypted = finding.source ? encryptPrivateMode(finding.source) : "";
	const nameEncrypted = finding.name ? encryptPrivateMode(finding.name) : "";
	const locationEncrypted = finding.location ? encryptPrivateMode(finding.location) : "";
	const recommendationEncrypted = finding.recommendation ? encryptPrivateMode(finding.recommendation) : "";

	return {
		severity: finding.severity,
		projectID: finding.projectID,
		description: descriptionEncrypted,
		source: sourceEncrypted,
		name: nameEncrypted,
		_id: finding._id,
		location: locationEncrypted,
		recommendation: recommendationEncrypted,
	};
}

export const decryptFinding = (finding: Finding): Finding => {
	return {
		severity: finding.severity,
		projectID: finding.projectID,
		description: getPrivateModeValue(finding.description),
		source: getPrivateModeValue(finding.source),
		name: getPrivateModeValue(finding.name),
		_id: finding._id,
		location: getPrivateModeValue(finding.location),
		recommendation: getPrivateModeValue(finding.recommendation),
	}
};

export const encryptWhiteboard = (whiteboard: Whiteboard): Whiteboard => {
    const valueEncrypted = whiteboard.value ? encryptPrivateMode(whiteboard.value) : "";
    const titleEncrypted = whiteboard.title ? encryptPrivateMode(whiteboard.title) : "";
    const filesEncrypted = whiteboard.files ? encryptPrivateMode(whiteboard.files) : "";

	return {
        value: valueEncrypted,
        files: filesEncrypted,
        title: titleEncrypted,
		appState: whiteboard.appState,
        _id: whiteboard._id,
        projectID: whiteboard.projectID,
	};
}

export const decryptWhiteboard = (whiteboard: Whiteboard): Whiteboard => {
	return {
		createdAt: whiteboard.createdAt,
		updatedAt: whiteboard.updatedAt,
		value: getPrivateModeValue(whiteboard.value),
		title: getPrivateModeValue(whiteboard.title),
		files: getPrivateModeValue(whiteboard.files),
		appState: whiteboard.appState,
		_id: whiteboard._id,
        projectID: whiteboard.projectID,
	}
};