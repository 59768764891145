import { Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";
import React from "react";
import { terminalOpenedTabAtom } from "store/atoms/terminalAtoms";
import CheatcodeContent from "./CheatcodeContent";
import TestContent from "./TestContent";
import TraceContent from "./TraceContent";
import SimulationContent from "./SimulationContent";

const TerminalContent = () => {
	const [tab] = useAtom(terminalOpenedTabAtom);
	const type = tab?.content?.type;

	const GetComponent = () => {
		switch (type) {
			case "trace":
				return <TraceContent {...tab.content} />;

			case "test":
				return <TestContent {...tab.content} />;

			case "cheatcode":
				return <CheatcodeContent {...tab.content} />;

			case "simulation2":
				return <SimulationContent {...tab.content} />;

			default:
				return (
					<Stack sx={{ p: 2 }}>
						<Typography>Terminal coming soon</Typography>
						<Typography>(open cheatcodes under Testing tool)</Typography>
					</Stack>
				);
		}
	};

	return <Stack sx={{ overflow: "auto" }}>{GetComponent()}</Stack>;
};

export default React.memo(TerminalContent);
