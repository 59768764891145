import {
	Box,
	Button,
	IconButton,
	Popover,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import MarkdownReader from "components/MainContentLayout/MarkdownReader";
import React from "react";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CloseIcon from "@mui/icons-material/Close";
import hex from "utils/hexTransparency";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

const NotesWidget = ({
	viewAnchor,
	closeViewNote,
	handleEditNote,
	handleDeleteNote
}) => {
	const theme = useTheme();

	const target = viewAnchor?.target;
	const note = viewAnchor?.note;

	const tagColor = note.tagColor.replaceAll("-", ".");

	return (
		<Popover
			open={Boolean(viewAnchor)}
			anchorEl={target}
			onClose={closeViewNote}
			slotProps={{
				paper: {
					sx: {
						p: 1.5,
						border: "2px solid",
						borderRadius: "8px",
						borderColor: tagColor,
						backgroundImage: "none",
						minWidth: { xs: "100%", md: 400 }
					}
				}
			}}
		>
			<Stack spacing={1}>
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<BookmarkIcon fontSize="small" sx={{ color: tagColor }} />

					<Typography sx={{ fontWeight: 600 }}>
						{note.tag}: {note.title}
					</Typography>

					<Box sx={{ flex: 1 }} />

					<IconButton
						color="inherit"
						onClick={closeViewNote}
						sx={{ height: 22, width: 20 }}
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				</Stack>

				<MarkdownReader value={note.noteContent} sx={{ py: 0 }} />

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="flex-end"
					spacing={1}
				>
					<Button
						size="small"
						color="inherit"
						variant="outlined"
						onClick={() => {
							closeViewNote();
							handleEditNote(note);
						}}
						sx={{
							borderColor: `${theme.palette.primary.main}${hex["50%"]}`,
							borderRadius: "8px"
						}}
					>
						<EditIcon sx={{ fontSize: 16, mr: 0.5 }} />
						Edit
					</Button>

					<Button
						size="small"
						color="inherit"
						variant="outlined"
						onClick={() => {
							closeViewNote();
							handleDeleteNote(note);
						}}
						sx={{
							borderColor: `${theme.palette.primary.main}${hex["50%"]}`,
							borderRadius: "8px"
						}}
					>
						<DeleteForeverOutlinedIcon sx={{ fontSize: 16, mr: 0.5 }} />
						Delete
					</Button>
				</Stack>
			</Stack>
		</Popover>
	);
};

export default NotesWidget;
