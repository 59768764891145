import ProjectImport from "Resources/ProjectImport";
import ProjectFile from "Resources/ProjectFile";

export type EditorTabType =
	| "test"
	| "markdown"
	| "pdf"
	| "graph"
	| "import"
	| "whiteboard"
	| "report"
	| "customTest"
	| "testTrace"
	| "editor"
	| "functionExplorer"
	| null;

export default class EditorTab {
	readonly contents: ProjectFile | JSX.Element;
	readonly name: string;
	readonly path: string;
	readonly type?: EditorTabType;
	readonly metadata?: any;

	constructor(
		contents: ProjectFile | ProjectImport | JSX.Element,
		name: string,
		path: string,
		type: EditorTabType = null,
		metadata: any = {}
	) {
		this.contents = contents;
		this.name = name;
		this.path = path;
		this.type = type;
		this.metadata = metadata;
	}

	static isProjectFile(
		contents: ProjectFile | JSX.Element
	): contents is ProjectFile {
		return contents instanceof ProjectFile;
	}

	getProjectFile(): ProjectFile {
		return EditorTab.isProjectFile(this.contents) ? this.contents : undefined;
	}
}
