import AddIcon from "@mui/icons-material/Add";
import {
	Button,
	InputAdornment,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme
} from "@mui/material";
import DeleteNoteModal from "components/CustomModal/DeleteNoteModal";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import hex from "utils/hexTransparency";
import { ToolboxCloseButton } from "../..";
import AddNote from "./AddNote";
import NoteItem from "./NoteItem";
import { notesAtom, notesTagsAtom } from "store/atoms/NotesAtoms";
import SearchIcon from "@mui/icons-material/Search";
import BookmarkIcon from "@mui/icons-material/Bookmark";

const getFilteredNotes = (notes, searchTerm: string, _tag: string) => {
	let _filtered = notes;

	if (searchTerm) {
		_filtered = _filtered.filter(
			(i) =>
				i.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
				i.noteContent?.toLowerCase().includes(searchTerm.toLowerCase()) ||
				i.location?.toLowerCase().includes(searchTerm.toLowerCase())
		);
	}

	if (_tag !== "all") {
		_filtered = _filtered.filter((i) => i.tag === _tag);
	}

	return _filtered;
};

export const NoteTagsDropDown: React.FC<{
	value: string;
	onChange: any;
	position?: "right" | "left" | "center";
	hideAllOption?: boolean;
}> = ({ value, onChange, position = "right", hideAllOption }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [notesTags] = useAtom(notesTagsAtom);

	return (
		<Select
			variant="outlined"
			size="small"
			sx={{
				mb: 2,
				flex: 1,
				borderRadius: "8px",
				fieldset: {
					borderColor: `${theme.palette.primary.main}${hex["30%"]}`
				},
				...(themeMode === "light" && {
					boxShadow: 1,
					bgcolor: "background.paper"
				})
			}}
			MenuProps={{
				anchorOrigin: {
					vertical: "bottom",
					horizontal: position
				},
				transformOrigin: {
					vertical: "top",
					horizontal: position
				},
				slotProps: {
					paper: {
						sx: {
							mt: 1,
							bgcolor: "background.paper",
							borderRadius: "8px",
							boxShadow: 1,
							backgroundImage: "none",
							...(themeMode === "dark" && {
								border: `2px solid ${theme.palette.primary.main}`
							}),
							...(themeMode === "light" && {
								border: `1px solid ${theme.palette.background["paper2"]}`
							})
						}
					}
				}
			}}
			value={value}
			onChange={(e) => onChange(e.target.value as string)}
		>
			{!hideAllOption && (
				<MenuItem value="all">
					<Stack direction="row" spacing={0.7} alignItems="center">
						<BookmarkIcon fontSize="small" sx={{ color: "primary.main" }} />
						<Typography>All</Typography>
					</Stack>
				</MenuItem>
			)}

			{notesTags?.map((item) => (
				<MenuItem key={item.name} value={item.name}>
					<Stack direction="row" spacing={0.7} alignItems="center">
						<BookmarkIcon fontSize="small" sx={{ color: item.color }} />
						<Typography>{item.name}</Typography>
					</Stack>
				</MenuItem>
			))}
		</Select>
	);
};

const NotesTool: React.FC<{
	mode?: string;
	deleteId?: string | false;
}> = ({ mode: propsMode = "notes", deleteId = false }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [loading, setLoading] = useState(false);
	const [mode, setMode] = useState<string | number>(propsMode);
	const [notes, setNotes] = useAtom(notesAtom);
	const [filteredNotes, setFilteredNotes] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [filterTag, setFilterTag] = useState("all");
	const [deleteModalOpen, setDeleteModalOpen] = useState<string | false>(
		deleteId
	);

	useEffect(() => {
		if (typeof propsMode === "number" && propsMode > -1) {
			setMode(propsMode);
		} else if (typeof propsMode === "string") {
			setMode(propsMode);
		}
	}, [propsMode]);

	useEffect(() => {
		const _filteredNotes = getFilteredNotes(notes, searchTerm, filterTag);

		setFilteredNotes(_filteredNotes);
	}, [notes, searchTerm, filterTag]);

	const handleEditClick = (i: number) => {
		setMode(i);
	};

	const Header: React.FC<{ label?: string }> = ({ label = "Notes" }) => (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{ mb: 2 }}
		>
			<Typography variant="h6">{label}</Typography>

			<ToolboxCloseButton />
		</Stack>
	);

	if (mode === "new" && !loading) {
		return <AddNote setMode={setMode} />;
	}

	if (typeof mode === "number" && !loading) {
		return (
			<AddNote values={notes[mode]} setMode={setMode} editMode index={mode} />
		);
	}

	if (mode === "notes" && !loading && !notes.length) {
		return (
			<>
				<Header />

				<Typography sx={{ mb: 2 }}>
					Seamlessly save all your thoughts, ideas and findings as your work
					through the code.
				</Typography>

				<Button
					variant="contained"
					fullWidth
					sx={{ borderRadius: "8px", height: 40 }}
					onClick={() => setMode("new")}
					disabled={loading}
				>
					<AddIcon fontSize="small" sx={{ mr: 0.5 }} />
					New Note
				</Button>
			</>
		);
	}

	return (
		<>
			<Header />

			<Button
				variant={themeMode === "dark" ? "contained" : "text"}
				fullWidth
				sx={{
					height: 40,
					color: "text.primary",
					borderColor: `${theme.palette.primary.main}${hex["30%"]}`,
					mb: 1.5,

					boxShadow: 1,
					borderRadius: "8px",
					bgcolor: "primary.main",
					...(themeMode === "light" && {
						bgcolor: "background.paper",
						"&:hover": {
							bgcolor: "background.paper2"
						}
					})
				}}
				onClick={() => setMode("new")}
				disabled={loading}
			>
				<AddIcon fontSize="small" sx={{ mr: 0.5 }} />
				New Note
			</Button>

			<Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2 }}>
				<TextField
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					variant={"outlined"}
					fullWidth
					placeholder="Search your notes"
					disabled={loading}
					sx={{
						borderRadius: "8px",
						"& .MuiInputBase-root": { height: 40 },
						fieldset: {
							borderColor: `${theme.palette.primary.main}${hex["30%"]}`
						}
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon fontSize="small" />
							</InputAdornment>
						),
						sx: {
							borderRadius: "8px",
							...(themeMode === "light" && {
								boxShadow: 1,
								bgcolor: "background.paper"
							})
						}
					}}
				/>

				<NoteTagsDropDown
					value={filterTag}
					onChange={(v: string) => setFilterTag(v)}
				/>
			</Stack>

			<Stack spacing={2}>
				{filteredNotes.map((note, i) => (
					<NoteItem
						key={i}
						index={i}
						deleteNote={setDeleteModalOpen}
						editClick={handleEditClick}
						{...note}
					/>
				))}
			</Stack>

			<DeleteNoteModal
				noteId={deleteModalOpen}
				onClose={() => setDeleteModalOpen(false)}
			/>
		</>
	);
};

export default React.memo(NotesTool);
