import { Icon } from "@iconify/react";
import { Stack, Typography } from "@mui/material";
import { useTerminal } from "hooks/ui/useTerminal";
import { useAtom } from "jotai";
import React from "react";
import { terminalFocusedTab } from "store/atoms/terminalAtoms";
import { smallerString } from "utils/helpersFunctions";
import { StyledIconbutton } from ".";
import { Draggable } from "react-beautiful-dnd";

const TabItem = ({ index, name, id }) => {
	const [focusedTabIndex, setFocusedTabIndex] = useAtom(terminalFocusedTab);

	const { closeTerminalTabIndex } = useTerminal();

	const handleSelectTab = (i: number) => {
		setFocusedTabIndex(i);
	};

	return (
		<Draggable key={id} draggableId={id} index={index}>
			{(provided, snapshot) => {
				const isDragging = snapshot.isDragging;

				const selected = focusedTabIndex === index;

				return (
					<Stack
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						alignItems="center"
						direction="row"
						spacing={0.5}
						onClick={() => handleSelectTab(index)}
						sx={{
							...provided.draggableProps.style,
							px: 1.2,
							height: "34px !important",
							cursor: "pointer !important",
							bgcolor: "background.nav",
							borderRight: "1px solid",
							borderRightColor: "background.paper2",
							color: "text.secondary",
							...(selected && {
								color: "text.primary",
								bgcolor: "background.default"
							}),
							...(isDragging && {
								border: "1px solid",
								borderColor: "background.paper2"
							}),
							"&:hover": { color: "text.primary" }
						}}
					>
						<Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
							{smallerString(name, 12, -8)}
						</Typography>

						<StyledIconbutton
							onClick={(e) => {
								e.stopPropagation();
								closeTerminalTabIndex(index);
							}}
						>
							<Icon icon="material-symbols-light:close" fontSize={18} />
						</StyledIconbutton>
					</Stack>
				);
			}}
		</Draggable>
	);
};

export default React.memo(TabItem);
