import {
	LockOutlined,
	ManageAccountsOutlined,
	MonetizationOnOutlined
} from "@mui/icons-material";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {
	Box,
	Button,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import { selectedAccountSettingAtom } from "atoms";
import { useAtom } from "jotai";
import secureLocalStorage from "react-secure-storage";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DraggableSidebar from "../DraggableSidebar";

export enum AccountSideBarOptions {
	LoginPassword = "Authentication Options",
	PaymentsAndBilling = "Payments and Billing",
	Privacy = "Privacy",
	AccountSettings = "Account Settings"
}

const menuItems = [
	{ name: AccountSideBarOptions.LoginPassword, Icon: ExitToAppIcon },
	// {
	// 	name: AccountSideBarOptions.PaymentsAndBilling,
	// 	Icon: MonetizationOnOutlined
	// },
	{
		name: AccountSideBarOptions.Privacy,
		Icon: LockOutlined
	},
	{ name: AccountSideBarOptions.AccountSettings, Icon: ManageAccountsOutlined }
];

const AccountSidebar = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [sidebarWidth, setSidebarWidth] = useState(280);
	const [selected, setSelected] = useAtom(selectedAccountSettingAtom);

	const navigate = useNavigate();

	return (
		<DraggableSidebar width={sidebarWidth} setWidth={setSidebarWidth}>
			<Box sx={{ p: 2, width: "100%", minWidth: 265 }}>
				<Button
					variant={themeMode === "light" ? "contained" : "outlined"}
					fullWidth
					onClick={() => navigate("/")}
					sx={{
						height: 40,
						color: "text.primary",
						mb: 1,
						borderRadius: "8px",
						...(themeMode === "light" && {
							border: "none",
							bgcolor: "background.paper",
							boxShadow: 1,
							":hover": {
								bgcolor: "#EEE",
								boxShadow: 1
							}
						})
					}}
				>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="center"
						spacing={1}
					>
						<AppShortcutIcon sx={{ fontSize: 18 }} />
						<Typography variant="body2">Return to Application</Typography>
					</Stack>
				</Button>

				<List>
					{menuItems.map((item, i) => (
						<ListItem disablePadding disableGutters key={i}>
							<ListItemButton
								disableGutters
								onClick={() => {
									setSelected(item.name);
								}}
								sx={{
									p: 1,
									borderRadius: "8px",
									...(selected === item.name && {
										bgcolor: "background.paper",
										color: "primary.main"
									}),
									"&:hover": {
										bgcolor: "background.default",
										color: "primary.main"
									}
								}}
							>
								<ListItemIcon sx={{ color: "inherit", minWidth: 0, mr: 1 }}>
									<item.Icon fontSize="small" />
								</ListItemIcon>
								<ListItemText primary={item.name} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Box>
		</DraggableSidebar>
	);
};

export default AccountSidebar;
