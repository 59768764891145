import CodeEditorCore from "@auditware/CodeEditorCore";
import {
	Box,
	Button,
	Paper,
	Popover,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import React from "react";

const NodePopup: React.FC<{ [_key: string]: any }> = ({
	anchorEl,
	popoverData,
	handleClosePopover,
	handleOpenLocation
}) => {
	const theme = useTheme();

	const lineCount = popoverData?.codeBlock?.trim()?.split("\n").length;

	return (
		<Popover
			open={Boolean(anchorEl) && Boolean(popoverData)}
			anchorReference="anchorPosition"
			anchorPosition={anchorEl}
			onClose={handleClosePopover}
		>
			{/* Content of the popover */}
			<Paper
				sx={{
					p: 2,
					border: `2px solid ${theme.palette.primary.main}`,
					borderRadius: "8px",
					width: 500
				}}
			>
				<Stack spacing={1}>
					<Typography variant="body2">
						File Name:{" "}
						<Typography
							variant="body2"
							component="span"
							sx={{ fontWeight: 600 }}
						>
							{popoverData?.fileName}
						</Typography>
					</Typography>

					{popoverData?.codeBlock && (
						<Box
							sx={{
								border: `2px solid ${theme.palette.primary.dark}`,
								borderRadius: "5px",
								bgcolor: "background.paper",
								overflow: "auto",
								height: 22 * ((lineCount > 15 ? 15 : lineCount) + 1),
								maxHeight: 200,
								py: 1
							}}
						>
							<CodeEditorCore
								value={popoverData?.codeBlock}
								language={"sol"}
								hideControlsBar
								editorOptions={{
									minimap: { enabled: false },
									lineNumbers: "off",
									glyphMargin: false,
									folding: false,
									lineDecorationsWidth: 0,
									lineNumbersMinChars: 0
								}}
							/>
						</Box>
					)}

					<Typography variant="body2">
						Line Number:{" "}
						<Typography
							variant="body2"
							component="span"
							sx={{ fontWeight: 600 }}
						>
							{popoverData?.lineNumber}
						</Typography>
					</Typography>

					<Button size="small" onClick={handleOpenLocation}>
						Open Location
					</Button>
				</Stack>
			</Paper>
		</Popover>
	);
};

export default React.memo(NodePopup);
