import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TreeItem, TreeView } from "@mui/lab";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Stack,
	useTheme
} from "@mui/material";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { openedTabAtom } from "store/atoms/UiAtoms";

const AstTool = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [expanded, setExpanded] = useState(false);

	const [{ openedFile }] = useAtom(openedTabAtom);

	const getNodeAsTreeItem = (
		node,
		id = Math.random().toString()
	): JSX.Element => {
		return (
			<TreeItem
				key={id}
				nodeId={id}
				label={
					<span
						style={{
							color:
								themeMode === "dark"
									? theme.palette.info.light
									: theme.palette.info.dark,
							fontWeight: themeMode === "dark" ? 400 : 500
						}}
					>
						{node["type"]}
					</span>
				}
			>
				{getPropertiesAsTreeItems(node)}
			</TreeItem>
		);
	};

	const getPropertiesAsTreeItems = (node): JSX.Element[] => {
		const treeItems = [];

		Object.entries(node).forEach((prop) => {
			if (prop[1] === null) treeItems.push(buildTreeItem(prop[0], "null"));
			else if (Array.isArray(prop[1]))
				treeItems.push(
					(prop[1] as Array<any>).length === 1
						? buildTreeItem(prop[0], null, getPropertiesAsTreeItems(prop[1][0]))
						: buildTreeItem(
								prop[0],
								null,
								prop[1].map((p) => {
									return p === null
										? buildTreeItem("null", null)
										: getNodeAsTreeItem(p);
								})
						  )
				);
			else if (typeof prop[1] === "object")
				treeItems.push(
					buildTreeItem(prop[0], null, getPropertiesAsTreeItems(prop[1]))
				);
			else treeItems.push(buildTreeItem(prop[0], prop[1]));
		});

		return treeItems;
	};

	const buildTreeItem = (name, contents, children = null) => {
		return (
			<TreeItem
				key={Math.random().toString()}
				nodeId={Math.random().toString()}
				label={
					<>
						<span
							style={{
								color:
									themeMode === "dark"
										? theme.palette.warning.main
										: theme.palette.primary.dark,
								fontWeight: themeMode === "dark" ? 400 : 500
							}}
						>
							{contents !== null ? name + ": " : name}
						</span>
						{contents !== null && (
							<span
								style={{
									color:
										themeMode === "dark"
											? theme.palette.success.main
											: theme.palette.success.dark,
									fontWeight: themeMode === "dark" ? 400 : 500
								}}
							>
								{JSON.stringify(contents).replaceAll('"', "")}
							</span>
						)}
					</>
				}
			>
				{children}
			</TreeItem>
		);
	};

	if (!openedFile?.ast) {
		return null;
	}

	return (
		<Accordion
			expanded={expanded}
			onChange={(e, v) => setExpanded(v)}
			disableGutters
			sx={{
				width: "100%",
				borderRadius: "8px !important",
				backgroundImage: "none",
				bgcolor: "background.default",
				"&.Mui-expanded": { mt: 0 }
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 1.5 }}>
				AST
			</AccordionSummary>

			<AccordionDetails sx={{ px: 1.5, pt: 0 }}>
				<Stack spacing={1}>
					<Box sx={{ flex: 1, height: "100%", overflow: "auto" }}>
						<TreeView
							defaultCollapseIcon={<RemoveIcon />}
							defaultExpandIcon={<AddIcon />}
							defaultExpanded={["base"]}
							sx={{ width: "100%", height: "100%" }}
						>
							{getNodeAsTreeItem(openedFile?.ast, "base")}
						</TreeView>
					</Box>
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};

export default React.memo(AstTool);
