import SearchIcon from "@mui/icons-material/Search";
import {
	Box,
	Container,
	Dialog,
	Divider,
	InputAdornment,
	List,
	ListItemButton,
	Slide,
	Stack,
	TextField,
	Typography
} from "@mui/material";
import ProjectFile from "Resources/ProjectFile";
import { fetchByKeysAsync } from "Resources/ServerInterface";
import { NAVBAR_HEIGHT } from "components/Navbar";
import { useLocalStorage } from "hooks/ui/useLocalStorage";
import { useProject } from "hooks/data/useProject";
import { useAtom, useSetAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { JumpToLineAtom } from "store/atoms/EditorAtoms";
import { searchAllBoxOpenAtom } from "store/atoms/UiAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import { STORAGE_SEARCH } from "utils/constants";
import { flattenFiles } from "utils/helpersFunctions";

interface FileType {
	id: string;
	name: string;
	path: string;
}

interface OccurrenceType {
	fileId: string;
	fileName: string;
	lineContents: string;
	lineNumber: number;
}

interface RecentSearchesType {
	searchTerm: string;
	type: "file" | "term";
	id?: string;
	path?: string;
}

const buildTooltipTitle = (obj: RecentSearchesType): string => {
	let text = `Search Term: ${obj.searchTerm}`;

	if (obj.type === "file") {
		text += `\nPath: ${obj.path}\nSearch Type: File`;
	}

	return text;
};

const SearchAll = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [files, setFiles] = useState<FileType[]>([]);
	const [filteredOccurrences, setFilteredOccurrences] = useState<
		OccurrenceType[]
	>([]);
	const [totalOccurrences, setTotalOccurrences] = useState(0);
	const setJumpToEditorLine = useSetAtom(JumpToLineAtom);

	const [searchAllBoxOpen, setSearchAllBoxOpen] = useAtom(searchAllBoxOpenAtom);

	const { openProjectFile } = useProject();

	const [project] = useAtom(projectAtom);

	const inputRef = useRef(null);

	const [recentSearches, setRecentSearches] = useLocalStorage<
		RecentSearchesType[]
	>(`${STORAGE_SEARCH}${project.id || ""}`, []);

	useEffect(() => {
		setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}, 500);
	}, [searchAllBoxOpen]);

	useEffect(() => {
		const root = flattenFiles(project.rootFolder);
		const imports = flattenFiles(project.importsFolder);

		setFiles([...root, ...imports]);
	}, [project]);

	function searchInCode(code: string, searchTerm: string) {
		if (code) {
			const lines = code.split("\n"); // Split the code into lines
			const occurrences = [];

			for (let i = 0; i < lines.length; i++) {
				const line = lines[i];
				if (line.includes(searchTerm)) {
					// Check if the search term exists in the line
					const occurrence = {
						lineContents: line,
						lineNumber: i + 1 // Line numbers start from 1
					};
					occurrences.push(occurrence);
				}
			}

			return occurrences;
		}

		return [];
	}

	const searchForOccurrences = async (searchTerm: string) => {
		const fileIds = files.map((file) => {
			return file.id;
		});
		const fileData = (await fetchByKeysAsync(fileIds)) as any[];
		const filesFromIndexedDb: ProjectFile[] = fileData.map((dbValue) => {
			try {
				return dbValue.value as ProjectFile;
			} catch {
				return null;
			}
		});

		const occurrences: OccurrenceType[] = filesFromIndexedDb.reduce(
			(acc: OccurrenceType[], current: ProjectFile) => {
				if (current) {
					const fileOccurrences = searchInCode(current.text, searchTerm);
					acc = acc.concat(
						fileOccurrences.map(
							(fileOccurrence: {
								lineContents: string;
								lineNumber: number;
							}) => {
								return {
									lineContents: fileOccurrence.lineContents,
									lineNumber: fileOccurrence.lineNumber,
									fileId: current.id,
									fileName: current.name
								};
							}
						)
					);
				}
				return acc;
			},
			[]
		);

		setFilteredOccurrences(occurrences.slice(0, 100));
		setTotalOccurrences(occurrences.length);
	};

	// Initialize a timer ID to hold the timeout ID
	const timerId = useRef(null);
	useEffect(() => {
		const _filtered = files.filter(
			(i) =>
				i.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
				i.path.toLowerCase().includes(searchTerm.toLowerCase())
		);

		// We want a timer here to not run search with each keystroke, still relatively expensive
		// Cancel the previous timer if there is one
		if (timerId.current) {
			clearTimeout(timerId.current);
		}

		// Set up a new timer
		timerId.current = setTimeout(() => {
			if (searchTerm) {
				searchForOccurrences(searchTerm);
			} else {
				setTotalOccurrences(0);
				setFilteredOccurrences([]);
			}
		}, 500); // 500 milliseconds delay

		// Cleanup
		return () => {
			if (timerId.current) {
				clearTimeout(timerId.current);
			}
		};
	}, [files, searchTerm]);

	const handleClose = () => {
		setSearchAllBoxOpen(false);
	};

	const handleFileClick = (file: FileType) => {
		const _path = `${file.path}/${file.name}`.slice(1);

		// Add to search only if it doesnt exist there
		if (
			!recentSearches.find(
				(i) => i.path === file.path && i.searchTerm === file.name
			)
		) {
			setRecentSearches([
				{
					searchTerm: file.name,
					id: file.id,
					path: file.path,
					type: "file"
				},
				...recentSearches
			]);
		}

		openProjectFile(_path);
		setSearchAllBoxOpen(false);
	};

	const handleOccurrenceClick = (occurrence: OccurrenceType) => {
		const fileWithPath = files.find((file) => file.id === occurrence.fileId);
		const _path = `${fileWithPath.path}/${fileWithPath.name}`.slice(1);

		openProjectFile(_path, () => {
			setTimeout(() => {
				setJumpToEditorLine({
					startLineNumber: occurrence.lineNumber,
					endLineNumber: occurrence.lineNumber
				});
			}, 300);
		});
		setSearchAllBoxOpen(false);
	};

	const handleRecentSearchClick = (data: RecentSearchesType) => {
		if (data.type === "file") {
			handleFileClick({ id: data.id, name: data.searchTerm, path: data.path });
		}
	};

	function escapeRegExp(string) {
		return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
	}

	const HighlightText: React.FC<{ text: string; target: string }> = ({
		text,
		target
	}) => {
		// Split the text by target string
		const parts = text.split(new RegExp(`(${escapeRegExp(target)})`, "gi"));

		return (
			<span>
				{parts.map((part, index) =>
					part.toLowerCase() === target.toLowerCase() ? (
						<span
							key={index}
							style={{ backgroundColor: "rgba(128, 128, 128, 0.5)" }}
						>
							{part}
						</span>
					) : (
						part
					)
				)}
			</span>
		);
	};

	return (
		<Dialog
			open={searchAllBoxOpen}
			onClose={handleClose}
			fullWidth
			maxWidth="md"
			sx={{
				"& .MuiPaper-root": {
					bgcolor: "transparent",
					backgroundImage: "none",
					boxShadow: "none"
					// mt: `${NAVBAR_HEIGHT}px`
				},
				"& .MuiBackdrop-root": {
					backdropFilter: "blur(2px)"
				},
				"& .MuiDialog-container": {
					pt: 4,
					alignItems: "flex-start"
				}
			}}
		>
			<Slide direction="down" in={searchAllBoxOpen} mountOnEnter unmountOnExit>
				<Container
					sx={{
						bgcolor: "background.nav",
						position: "relative",
						borderRadius: "12px",
						pt: 2.5,
						pb: 2
					}}
				>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="center"
						sx={{ height: "100%" }}
						spacing={2}
					>
						<TextField
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							inputRef={inputRef}
							fullWidth
							placeholder="Search for text (case sensitive)"
							variant="standard"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								disableUnderline: true
							}}
						/>

						<Box
							sx={{
								py: 0.3,
								px: 0.8,
								border: "1px solid gray",
								borderRadius: "4px",
								cursor: "pointer",
								"&:hover": {
									bgcolor: "background.default"
								}
							}}
							onClick={handleClose}
						>
							<Typography variant="caption" color="text.secondary">
								ESC
							</Typography>
						</Box>
					</Stack>

					<Divider sx={{ my: 2 }} />

					<Box
						sx={{
							maxHeight: `calc(100vh - ${NAVBAR_HEIGHT * 4}px)`,
							overflow: "auto"
						}}
					>
						{!!filteredOccurrences.length && (
							<Box>
								<Typography color="text.secondary">
									<strong>{totalOccurrences}</strong> Occurrences
								</Typography>
								<List>
									{filteredOccurrences.map((item, i) => (
										<ListItemButton
											key={i}
											onClick={() => handleOccurrenceClick(item)}
											sx={{ flexWrap: "wrap" }}
										>
											<Stack
												direction="row"
												spacing={2}
												alignItems="center"
												justifyContent="space-between"
												flexWrap="nowrap"
												sx={{ width: "100%" }}
											>
												<Typography>{item.fileName}</Typography>

												<Typography
													variant="body2"
													color="text.disabled"
													sx={{ textAlign: "right" }}
												>
													<HighlightText
														text={item.lineContents}
														target={searchTerm}
													/>
												</Typography>
											</Stack>
										</ListItemButton>
									))}
								</List>
							</Box>
						)}
						{!filteredOccurrences.length && !!searchTerm.length && (
							<Typography color="text.secondary">
								No Results for {searchTerm}
							</Typography>
						)}
						{!filteredOccurrences.length && !searchTerm.length && (
							<Typography color="text.secondary">
								Type text to search
							</Typography>
						)}
					</Box>
				</Container>
			</Slide>
		</Dialog>
	);
};

export default SearchAll;
