import React from 'react';
import { styled } from '@mui/material';

const EllipsisAnimationContainer = styled('span')({
	'& span': {
		opacity: 0,
		'-webkit-animation': 'ellipsis-dot 1s infinite',
		animation: 'ellipsis-dot 1s infinite',
		fontSize: '3em',
		lineHeight: 0,
		'-webkit-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		userSelect: 'none',
	},
	'& span:nth-child(1)': {
		'-webkit-animation-delay': '0.0s',
		animationDelay: '0.0s',
	},
	'& span:nth-child(2)': {
		'-webkit-animation-delay': '0.1s',
		animationDelay: '0.1s',
	},
	'& span:nth-child(3)': {
		'-webkit-animation-delay': '0.2s',
		animationDelay: '0.2s',
	},
	'@-webkit-keyframes ellipsis-dot': {
		'0%': { opacity: 0 },
		'50%': { opacity: 1 },
		'100%': { opacity: 0 },
	},
	'@keyframes ellipsis-dot': {
		'0%': { opacity: 0 },
		'50%': { opacity: 1 },
		'100%': { opacity: 0 },
	}
});
const EllipsisAnimation = () => {
	return (
		<EllipsisAnimationContainer>
			<span>.</span>
			<span>.</span>
			<span>.</span>
		</EllipsisAnimationContainer>
	);
};
export default EllipsisAnimation;