import React from "react";
import { buildReportMarkdown } from "./helpers";
import { Box } from "@mui/material";
import MarkdownReader from "../MarkdownReader";

const MdReport: React.FC<{ data: any }> = ({ data }) => {
	const markdown = buildReportMarkdown(data);

	return (
		<Box>
			<MarkdownReader value={markdown} sx={{ p: 0 }} />
		</Box>
	);
};

export default MdReport;
