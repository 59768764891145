import {
	Avatar,
	Box,
	Button,
	Grid,
	Stack,
	SxProps,
	Typography,
	useTheme
} from "@mui/material";
import { useAtom } from "jotai";
import React from "react";
import { projectAtom } from "store/atoms/projectToolAtoms";
import CircleIcon from "@mui/icons-material/Circle";

const StyledBlock: React.FC<{ title: string; sx?: SxProps }> = ({
	title,
	sx = {},
	children
}) => {
	const theme = useTheme();

	return (
		<Stack
			spacing={1}
			sx={{
				bgcolor: "background.default",
				borderRadius: "8px",
				p: 1.5,
				border: `1px solid ${theme.palette.background.default}`,
				...sx
			}}
		>
			<Typography variant="body2" color="text.secondary">
				{title}
			</Typography>

			{children}
		</Stack>
	);
};

const ProjectSummary = () => {
	const [project] = useAtom(projectAtom);

	return (
		<Stack
			direction={{ xs: "column", md: "row" }}
			alignItems="center"
			justifyContent="space-between"
		>
			{/* Left Side */}
			<Stack direction="row" alignItems="center" spacing={1.5}>
				<Avatar
					sx={{
						height: { xs: 40, sm: 50, md: 60 },
						width: { xs: 40, sm: 50, md: 60 }
					}}
				/>

				<Stack spacing={0.5}>
					<Stack>
						<Typography variant="h6">{project.name}</Typography>
					</Stack>

					<Typography sx={{ maxWidth: 550 }}>
						Advanced smart contracts for launching generative art projects on
						Ethereum.
					</Typography>
				</Stack>
			</Stack>

			{/* Submit button */}
			<Button variant="contained" sx={{ borderRadius: "8px", height: 40 }}>
				Submit findings
			</Button>
		</Stack>
	);
};

const ProjectHome = () => {
	const theme = useTheme();

	const [project] = useAtom(projectAtom);

	return (
		<Stack spacing={1.5} sx={{ p: 2, height: "100%" }}>
			{/* Top */}
			<ProjectSummary />

			{/* Blocks */}
			<Box>
				<Grid container spacing={1.5}>
					{/* Duration */}
					<Grid item xs={12} sm={6} md={4}>
						<StyledBlock title="Duration">
							<Stack direction="row" alignItems="center" spacing={1}>
								<Typography>14 days - 6 left</Typography>
							</Stack>
						</StyledBlock>
					</Grid>

					{/* Lines of code */}
					<Grid item xs={12} sm={6} md={4}>
						<StyledBlock title="Lines of code">
							<Typography sx={{ fontWeight: 600 }}>3851</Typography>
						</StyledBlock>
					</Grid>

					{/* Total awards */}
					<Grid item xs={12} sm={6} md={4}>
						<StyledBlock
							title="Total awards"
							sx={{
								border: `1px solid ${theme.palette.warning.main}`,
								background: `linear-gradient(89.44deg, rgba(229, 177, 41, 0.2) 0.35%, ${theme.palette.background.default} 92%)`
							}}
						>
							<Stack direction="row" alignItems="center" spacing={1}>
								<CircleIcon sx={{ fontSize: 18, color: "warning.main" }} />
								<Typography>
									<Typography
										component="span"
										sx={{ color: "warning.main", fontWeight: 500 }}
									>
										$60,500
									</Typography>{" "}
									USDC
								</Typography>
							</Stack>
						</StyledBlock>
					</Grid>
				</Grid>
			</Box>

			{/* Overview */}
			<Box
				sx={{
					flex: 1,
					height: "100%",
					width: "100%",
					py: 2,
					px: 1.5,
					borderRadius: "8px",
					bgcolor: "background.default"
				}}
			>
				<Typography sx={{ fontWeight: 600 }}>Overview</Typography>
			</Box>
		</Stack>
	);
};

export default React.memo(ProjectHome);
