import { importProjectFromUrl } from "Resources/ServerInterface";
import {
	ChallengeSubmission,
	SubmittedChallengesResponse,
	getSubmittedChallenges,
	submitChallengeAnswer
} from "Resources/ServerInterfaceChallenge";
import { mixpanelAtom } from "atoms";
import { useProject } from "hooks/data/useProject";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import { useEffect } from "react";
import {
	bugChallengeSubmittedAtom,
	challengesAtom,
	focusedChallengeAtom,
	userSubmissionsAtom
} from "store/atoms/BugChallengeAtoms";
import { importingAtom } from "store/atoms/ImportsAtoms";
import { focusedEditorTabIndexAtom } from "store/atoms/UiAtoms";
import { projectAtom, projectLoadedAtom } from "store/atoms/projectToolAtoms";
import {
	getPathOfChallengeFile,
	getPathOfDefaultOpenFile
} from "utils/helpersFunctions";

const PROJECT_URL =
	"https://github.com/immunefi-team/forge-poc-templates/tree/main";

export function useBugChallenge() {
	const [submitted, setSubmitted] = useAtom(bugChallengeSubmittedAtom);
	const [mixpanel] = useAtom(mixpanelAtom);
	const [importing, setImporting] = useAtom(importingAtom);

	const setUserChallengeSubmissions = useSetAtom(userSubmissionsAtom);
	const setFocusedTabIndex = useSetAtom(focusedEditorTabIndexAtom);

	const { openProject, openProjectFile } = useProject();

	const [focusedChallenge] = useAtom(focusedChallengeAtom);

	useEffect(() => {
		setSubmitted(false);
	}, []);

	const importAndOpenProject = async (
		commitHash: string,
		bypassSubmissionCheck: boolean = null
	) => {
		if (bypassSubmissionCheck === false) {
			return;
		}

		if (!importing) {
			setImporting(true);

			const proj = await importProjectFromUrl(
				"https://github.com/Auditware/CodeChallenge",
				null,
				mixpanel,
				false,
				commitHash
			);

			setImporting(false);

			if (proj !== undefined)
				openProject(proj, [], (project) => {
					const pathToFileToOpen = getPathOfChallengeFile(project.rootFolder);

					openProjectFile(pathToFileToOpen, () => {
						setFocusedTabIndex(0);
					});
				});
		}
	};

	const submitAnswer = async (data: any): Promise<boolean> => {
		const challengeSubmission: ChallengeSubmission = {
			answerLineStart: data.startLine,
			answerLineEnd: data.endLine,
			challengeID: focusedChallenge._id ?? "",
			answerDescription: data.description
		};

		const success = await submitChallengeAnswer(challengeSubmission, mixpanel);

		if (success) {
			setSubmitted(true);
		}

		return success;
	};

	const loadUserChallengeSubmissions = async () => {
		const res: SubmittedChallengesResponse = await getSubmittedChallenges();

		setUserChallengeSubmissions(res?.challengeIds || []);

		return res;
	};

	return {
		importAndOpenProject,
		submitAnswer,
		projectUrl: PROJECT_URL,
		loadUserChallengeSubmissions
	};
}
