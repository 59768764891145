import { Theme } from "@mui/material";
import { editor } from "monaco-editor";
import { LiveColor } from "./styles";
import { CallableValueType } from "store/atoms/CallableValueAtoms";
import { Monaco } from "@monaco-editor/react";
import ReactDOM from "react-dom";
import CallableValueWidget from "./CallableValueWidget";

export const parseCallableValues = (callableValues, theme: Theme) => {
	const options = () => {
		return {
			isWholeLine: true,
			className: `editor-callable-live`,
			shouldFillLineOnLineBreak: true,
			minimap: {
				color: LiveColor(theme),
				darkColor: LiveColor(theme),
				position: 1
			},
			overviewRuler: {
				position: 7,
				color: LiveColor(theme),
				darkColor: LiveColor(theme)
			}
		};
	};

	return callableValues
		.flatMap((item: CallableValueType) => [
			// Glyph
			{
				options: {
					glyphMarginClassName: `editor-callable-glyph glyph-callable-green`
					// className: ""
				},
				range: {
					startLineNumber: item.line,
					endLineNumber: item.line,
					startColumn: 0,
					endColumn: 100
				},
				data: {
					...item
				}
			},

			// Bg + minimap
			{
				range: {
					startLineNumber: item.line,
					endLineNumber: item.line,
					startColumn: 0,
					endColumn: 100
				},
				options: options(),
				data: {
					...item
				},
				type: "callable-type"
			}
		])
		.filter(Boolean);
};

export const removeCallableValueGlyphsAndListeners = (
	editorRef: editor.IStandaloneCodeEditor,
	widgetRef
) => {
	if (widgetRef.current) {
		editorRef?.removeContentWidget?.(widgetRef.current);
		widgetRef.current = null;
	}

	const model = editorRef?.getModel?.();
	const allDecorations = model?.getAllDecorations?.();

	const allClassNamesToRemove = [
		"editor-callable-live",
		"editor-callable-glyph glyph-callable-green"
	];

	if (allDecorations) {
		const callableIds = allDecorations
			.filter(
				(i) =>
					allClassNamesToRemove.includes(i.options.className) ||
					allClassNamesToRemove.includes(i.options.glyphMarginClassName)
			)
			.map((i) => i.id);

		editorRef.removeDecorations(callableIds);
	}
};

export const callableValueClickOpenWidget = (
	editorRef: editor.IStandaloneCodeEditor,
	monacoRef: Monaco,
	position: any,
	widgetRef: any,
	callableValues: any
) => {
	const foundCallableValues =
		position &&
		callableValues.filter(
			(i) =>
				position.lineNumber >= i.range.startLineNumber &&
				position.lineNumber <= i.range.endLineNumber &&
				!i.options.glyphMarginClassName
		);

	if (!!foundCallableValues?.length) {
		const removeWidget = () => {
			if (widgetRef.current) {
				editorRef.removeContentWidget(widgetRef.current);
				widgetRef.current = null;
			} else {
				editorRef.removeContentWidget({
					getId: () => "editor-callable-value-widget",
					getDomNode: () => {
						const node = document.createElement("div");
						return node;
					},
					getPosition: () => ({
						position: {
							lineNumber: position?.lineNumber,
							column: 1
						},
						preference: [monacoRef.editor.ContentWidgetPositionPreference.BELOW]
					})
				});
			}
		};

		removeWidget();

		const widget = {
			getId: () => "editor-callable-value-widget",

			getDomNode: () => {
				const node = document.createElement("div");

				node.onwheel = function (e) {
					e.stopPropagation();
				};

				ReactDOM.render(
					CallableValueWidget({
						callableValues: foundCallableValues,
						handleClose: removeWidget
					}),
					node
				);

				return node;
			},
			suppressMouseDown: true,
			getPosition: () => ({
				position: {
					lineNumber: position?.lineNumber, //foundBookmarks[0].range.endLineNumber,
					column: 2
				},
				preference: [monacoRef.editor.ContentWidgetPositionPreference.BELOW]
			})
		};

		editorRef.addContentWidget(widget);
		widgetRef.current = widget;
	}
};
