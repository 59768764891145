import { TextField, TextFieldProps, useTheme } from "@mui/material";
import React from "react";

interface FormTextFieldProps {
	label?: string;
	register: any;
	inputSx?: object;
}

const FormTextField: React.FC<TextFieldProps & FormTextFieldProps> = ({
	name,
	type = "text",
	register,
	sx = {},
	inputSx = {},
	InputProps = {},
	required,
	error,
	helperText,
	disabled,
	multiline,
	...props
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	return (
		<TextField
			variant="outlined"
			size="small"
			type={type}
			{...register(name, { required })}
			error={error}
			multiline={multiline}
			helperText={helperText}
			sx={{
				...(!multiline && { "& .MuiInputBase-root": { height: 40 } }),
				...(themeMode === "light" && {
					"& fieldset": {
						borderColor: "transparent"
					}
				}),
				...sx
			}}
			InputProps={{
				sx: {
					borderRadius: "8px",
					...(multiline && { py: 2 }),
					...(themeMode === "light" && {
						bgcolor: "background.paper",
						boxShadow: 1
					}),
					...sx,
					...inputSx
				},
				...InputProps
			}}
			disabled={disabled}
			{...props}
		/>
	);
};

export default FormTextField;
