import { Theme } from "@mui/material";
import { editor } from "monaco-editor";
import ReactDOM from "react-dom";
import FindingViewZone from "./FindingViewZone";
import { findingsColor } from "./styles";
import { SEVERITY_OPTIONS } from "components/Toolbox/Tools/AddFindingsTool/helpers";
import { yellow } from "@mui/material/colors";

const extractLocationFromString = (inputString: string) => {
	const matches = inputString.match(/File Path: (.+)\nLines: (\d+) - (\d+)/);

	if (!matches) return { filePath: inputString, fromLine: -1, toLine: -1 };

	return {
		filePath: matches[1],
		fromLine: parseInt(matches[2] || ""),
		toLine: parseInt(matches[3] || "")
	};
};

export const parseFindings = (findings, theme: Theme) => {
	const options = (_color) => ({
		isWholeLine: true,
		minimap: {
			position: 2,
			color: findingsColor(theme, _color),
			darkColor: findingsColor(theme, _color)
		},
		overviewRuler: {
			position: 7,
			color: findingsColor(theme, _color),
			darkColor: findingsColor(theme, _color)
		}
	});

	const parsedNotes =
		findings.flatMap((item, i) => {
			const _location = extractLocationFromString(item.location || "");

			const tagColor =
				SEVERITY_OPTIONS.find((i) => i.value === item.severity).color ||
				yellow["500"];

			return [
				{
					id: `${i}`,
					options: {
						...options(tagColor),
						className: `editor-findings-widget.severity-${item.severity}`
					},
					range: {
						startLineNumber: _location.fromLine,
						endLineNumber: _location.toLine,
						startColumn: 0,
						endColumn: 100
					},
					type: "finding",
					data: item
				}
			];
		}) || [];

	return parsedNotes;
};

export const removeFindingsGlyphsAndListeners = (
	editorRef,
	widgetRef,
	allViewZones
) => {
	if (widgetRef.current) {
		editorRef?.removeContentWidget?.(widgetRef.current);
		widgetRef.current = null;
	}

	const model = editorRef?.getModel?.();
	const allDecorations = model?.getAllDecorations?.();

	const allClassNamesToRemove = [
		"editor-findings-widget severity-0",
		"editor-findings-widget severity-1",
		"editor-findings-widget severity-2",
		"editor-findings-widget severity-3",
		"editor-findings-widget severity-4",
		"editor-findings-widget severity-5",

		"glyph-fingings severity-0",
		"glyph-fingings severity-1",
		"glyph-fingings severity-2",
		"glyph-fingings severity-3",
		"glyph-fingings severity-4",
		"glyph-fingings severity-5"
	];

	// Remove viewzones
	editorRef.changeViewZones((changeAccessor) => {
		allViewZones.map((zoneId) => {
			return changeAccessor.removeZone(zoneId);
		});
	});

	// remove glyph and listeners
	if (allDecorations) {
		const notesIds = allDecorations
			.filter(
				(i) =>
					allClassNamesToRemove.includes(i.options.className) ||
					allClassNamesToRemove.includes(i.options.glyphMarginClassName)
			)
			.map((i) => i.id);

		editorRef.removeDecorations(notesIds);
	}
};

export const addFindingViewZones = (
	editorRef: editor.IStandaloneCodeEditor,
	findings: any,
	currentViewZones = [],
	handleViewFinding: any,
	handleEditFinding: any,
	handleDeleteFinding: any,
	themeMode: "light" | "dark"
): any => {
	const allViewZones = [...(currentViewZones || [])];

	editorRef.changeViewZones((changeAccessor) => {
		allViewZones.map((zoneId) => {
			return changeAccessor.removeZone(zoneId);
		});

		const getDomNode = (data) => {
			const node = document.createElement("div");

			node.onwheel = function (e) {
				e.stopPropagation();
			};

			ReactDOM.render(FindingViewZone({ ...data, themeMode }), node);

			return node;
		};

		findings?.map((finding) => {
			const zoneId = changeAccessor.addZone({
				afterLineNumber: finding?.range?.startLineNumber - 1,
				heightInPx: 31,
				domNode: getDomNode({
					...finding.data,
					handleViewFinding: (e) => handleViewFinding(e, finding.data),
					handleEditFinding: () => handleEditFinding(finding.data),
					handleDeleteFinding: () => handleDeleteFinding(finding.data)
				})
			});
			allViewZones.push(zoneId);

			return null;
		});
	});

	return allViewZones;
};
