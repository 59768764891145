import { Icon } from "@iconify/react";
import { useEditorTabs } from "hooks/ui/useEditorTabs";
import { useTools } from "hooks/ui/useTools";
import { useAtom } from "jotai";
import React from "react";
import { editorTabsAtom, selectedToolAtom } from "store/atoms/UiAtoms";
import CustomButton from "./CustomButton";

const WhiteboardButton = () => {
	const { selectTool } = useTools();
	const { findAndOpenTab, openCustomTab } = useEditorTabs();

	const [selectedTool] = useAtom(selectedToolAtom);
	const [openTabs] = useAtom(editorTabsAtom);

	return (
		<CustomButton
			selected={selectedTool === "Whiteboard"}
			tooltip="Whiteboard"
			onClick={() => {
				// Open the toolbox
				if (selectedTool === "Whiteboard") {
					selectTool(null);
				} else {
					// Open whiteboard if not already open
					const anyWhiteboardOpen = openTabs.findIndex((i) =>
						i.path.includes("/whiteboard")
					);

					if (anyWhiteboardOpen === -1) {
						const tabName = "New Whiteboard";
						const tabPath = "/whiteboard";

						findAndOpenTab(tabPath, () =>
							openCustomTab(null, tabName, tabPath)
						);

						setTimeout(() => {
							selectTool("Whiteboard");
						}, 100);
					} else {
						selectTool("Whiteboard");
					}
				}
			}}
			Icon={<Icon icon="fluent:whiteboard-48-filled" fontSize={23} />}
		/>
	);
};

export default React.memo(WhiteboardButton);
