import { ChecklistGroupType } from "store/atoms/checklistAtoms";
import { fetch401Interceptor, getCsrfToken } from "./ServerInterface";
import { addErrorNotification } from "./Notifications";
import { checklistAddedToLibraryEvent, checklistGroupCreatedEvent, checklistGroupDeletedEvent, checklistRemovedFromLibraryEvent } from "Mixpanel/mixpanel.helper";

const SERVER_URL =
	window.location.protocol +
	"//" +
	window.location.hostname +
	":" +
	(process.env.REACT_APP_SERVER_PORT || "5000");

export interface ChecklistGroupTypeResponse extends ChecklistGroupType {
	inLibrary: boolean
}
	
export async function loadChecklistGroups(): Promise<ChecklistGroupTypeResponse[]> {
    try {
        const url =
            SERVER_URL +
            `/checklist?` +
            new URLSearchParams({ csrfToken: await getCsrfToken() });

        const res = await fetch401Interceptor(url, { credentials: "include" });

        if (res.ok) {
            const resJson: ChecklistGroupTypeResponse[] = await res.json();
            return resJson;
        } else {
            const resError = await res.text();
            throw new Error(resError);
        }
    } catch (e) {
        addErrorNotification("Error", "Error loading checklists: " + e);
        return [];
    }
}

export async function createChecklistGroup(
	checklistGroup: ChecklistGroupType,
	mixpanel: any
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/checklist?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch(url, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify({ checklistGroup })
		});

        checklistGroupCreatedEvent(mixpanel, { projectID: checklistGroup.projectID });

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error creating checklist: " + e.message);
	}
}

export async function updateChecklistGroup(checklistGroup: ChecklistGroupType, mixpanel: any): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/checklist/update?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify(checklistGroup)
		});

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error updating checklist: " + e.message);
	}
}

export async function deleteChecklistGroupById(
	checklistId: string,
	mixpanel: any,
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/checklist/${checklistId}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch(url, {
			method: "DELETE",
			credentials: "include"
		});

		checklistGroupDeletedEvent(mixpanel, { projectID: checklistId });

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error deleting checklist: " + e.message);
	}
}

export async function addChecklistToUserLibrary(
	checklistId: string,
	mixpanel: any,
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/checklist/library/add/${checklistId}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch(url, {
			method: "PUT",
			credentials: "include"
		});

		checklistAddedToLibraryEvent(mixpanel, { checklistId });

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error adding to checklist library: " + e.message);
	}
}

export async function removeChecklistFromUserLibrary(
	checklistId: string,
	mixpanel: any,
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/checklist/library/remove/${checklistId}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch(url, {
			method: "DELETE",
			credentials: "include"
		});

		checklistRemovedFromLibraryEvent(mixpanel, {checklistId});

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error removing from checklist library: " + e.message);
	}
}