import { Icon } from "@iconify/react";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import React from "react";
import { expandedProjectTreeAtom } from "store/atoms/UiAtoms";

const CollapseAllButton: React.FC<{ folderPath: string }> = ({
	folderPath
}) => {
	const theme = useTheme();

	const [_paths, setPaths] = useAtom(expandedProjectTreeAtom);
	const paths = Array.from(new Set(_paths));

	const isAlreadyCollapsed = paths?.length == 1 && paths[0] === folderPath;

	const handleButtonClick = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.preventDefault();
		e.stopPropagation();

		setPaths([folderPath]);
	};

	if (!paths?.length || !paths?.includes(folderPath)) return null;
	if (isAlreadyCollapsed) return null;

	return (
		<Tooltip
			title="Collapse folders in path"
			enterDelay={700}
			enterNextDelay={500}
			leaveDelay={0}
			placement="right"
		>
			<IconButton
				className="showOnHover"
				onClick={handleButtonClick}
				sx={{
					p: 0,
					height: 18,
					width: 18,
					// display: "none",
					opacity: 0.3,
					"&:hover": {
						opacity: 1
					}
				}}
			>
				<Icon
					icon="codicon:collapse-all"
					fontSize={16}
					color={theme.palette.text.primary}
				/>
			</IconButton>
		</Tooltip>
	);
};

export default CollapseAllButton;
