import { saveAs } from "file-saver"; // Library to save files

export const downloadSvg = async (fileName, type) => {
	// Step 1: Get the elements in DOM
	const gElement: any = document.querySelector(".Graphviz_container g");
	// gElement.setAttribute("transform", "scale(1) translate(4, 100%)");
	gElement.style.transform = "translate(4px,99%) scale(0.98)";
	const svgElement = document.querySelector(".Graphviz_container svg");

	// const mainElement = document.getElementById("Graphviz_id");

	// mainElement.style.width = "5000px";
	// mainElement.style.height = "5000px";

	// const canvas = await html2canvas(mainElement);

	// mainElement.style.width = "100%";
	// mainElement.style.height = "100%";
	// return;

	switch (type) {
		case "SVG":
			const svgData = new XMLSerializer().serializeToString(svgElement);
			const blob = new Blob([svgData], { type: "image/svg+xml" });
			saveAs(blob, `${fileName}.svg`);

			gElement.style.removeProperty("transform");
			break;

		// case "PNG":
		// 	canvas.toBlob((blob) => {
		// 		if (blob) {
		// 			saveAs(blob, `${fileName}.png`);
		// 		}
		// 	});
		// 	break;

		// case "JPG":
		// 	canvas.toBlob((blob) => {
		// 		if (blob) {
		// 			saveAs(blob, `${fileName}.jpg`);
		// 		}
		// 	});
		// 	break;

		// case "PDF":
		// 	const pdfData = canvas.toDataURL("image/png");
		// 	const pdf = new jsPDF("l", "mm", [canvas.width, canvas.height]);
		// 	pdf.addImage(pdfData, "PNG", 0, 0, canvas.width, canvas.height);
		// 	pdf.save(`${fileName}.pdf`);
		// 	break;

		default:
			break;
	}
};

export const extractLinesFromString = (
	inputString: string,
	startLine: number,
	endLine: number
) => {
	if (!inputString.length) return inputString;

	const lines = inputString.split("\n");
	const extractedLines = [];

	for (let i = startLine - 1; i < endLine && i < lines.length; i++) {
		extractedLines.push(lines[i]);
	}

	return extractedLines.join("\n");
};
