import { Stack } from "@mui/material";
import StickyNote from "components/StickyNote";
import { Outlet } from "react-router";
import Navbar, { NAVBAR_HEIGHT } from "../../Navbar";

const AppLayout = () => {
	return (
		<Stack
			sx={{
				height: "100vh",
				overflowX: "hidden",
				transition: "all 0.1s ease-in-out"
			}}
		>
			{/* Navbar */}
			<Navbar />

			{/* Content */}
			<Stack
				sx={{
					flexGrow: 1,
					paddingTop: `${NAVBAR_HEIGHT}px`
				}}
			>
				<Outlet />
			</Stack>

			<StickyNote />
		</Stack>
	);
};

export default AppLayout;
