import {
	Box,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import { ToolboxCloseButton } from "components/Toolbox";
import React, { useState } from "react";
import BuilderTab from "./BuilderTab";
import TransactionsTab from "./TransactionsTab";
import TenderlyLogo from "assets/images/tenderlyLogo.svg";
import { useAtom } from "jotai";
import { projectAtom } from "store/atoms/projectToolAtoms";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
	borderRadius: "8px",
	...(theme.palette.mode === "light" && {
		border: 0,
		color: `${theme.palette.text.primary}!important`
	}),
	"&.Mui-selected, &.Mui-selected:hover": {
		backgroundColor: theme.palette.background["selected"],
		...(theme.palette.mode === "light" && {
			color: `${theme.palette.text.primary}!important`
		})
	}
}));

const SimulationTool = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [toggleValue, setToggleValue] = useState(0);
	const [project] = useAtom(projectAtom);

	const Header = () => (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{ mb: 2 }}
		>
			<Typography variant="h6">Simulation</Typography>

			<Box sx={{ flex: 1 }} />

			<Stack direction="row" alignItems="center" sx={{ mr: 1 }}>
				<Typography color="text.disabled" variant="body2">
					Powered by
				</Typography>

				<Box
					component="img"
					src={TenderlyLogo}
					sx={{ height: 22, objectFit: "contain", mr: 0.7, ml: 0.7 }}
				/>

				<Typography sx={{ fontWeight: 600 }}>tenderly</Typography>
			</Stack>

			<ToolboxCloseButton />
		</Stack>
	);

	if (!project.sourceNetwork) {
		return (
			<Box>
				<Header />
				<Typography sx={{ color: "success.light" }}>
					Transaction simulation only available for contract address imports
				</Typography>
			</Box>
		);
	}

	const switchToTransactionsTab = () => {
		setToggleValue(1);
	};

	return (
		<Stack sx={{ height: "100%" }}>
			<Header />

			<Stack sx={{ height: "100%", overflow: "auto" }}>
				{/* Toggle Buttons */}
				<ToggleButtonGroup
					size="small"
					color="primary"
					value={toggleValue}
					exclusive
					onChange={(e, v) => setToggleValue(v)}
					fullWidth
					sx={{
						mb: 3,
						height: 40,
						borderRadius: "8px",
						bgcolor: "background.default",
						...(themeMode === "light" && {
							bgcolor: "background.paper",
							boxShadow: 1
						})
					}}
				>
					<StyledToggleButton value={0}>Builder</StyledToggleButton>

					<StyledToggleButton value={1}>Transactions</StyledToggleButton>
				</ToggleButtonGroup>

				{toggleValue === 0 && <BuilderTab switchTab={switchToTransactionsTab}/>}

				{toggleValue === 1 && <TransactionsTab />}
			</Stack>
		</Stack>
	);
};

export default React.memo(SimulationTool);
