import { testCreatedEvent, testRanEvent, testUpdatedEvent } from "Mixpanel/mixpanel.helper";
import { addErrorNotification } from "./Notifications";
import { fetch401Interceptor, getCsrfToken } from "./ServerInterface";

export interface ProjectTest {
	projectID: string;
	testName: string;
	testContents?: string;
	originFile?: string;
	originFilePath?: string;
	units?: any;
	_id?: any;
}

export interface ProjectTestUnit {
	unitName: string;
	resultStatus?: ProjectTestResult;
	resultTrace?: string[];
	resultLogs?: string[];
	resultDurationNanos?: number;
	reason?: string;
}

export enum ProjectTestResult {
	untested = "untested",
	pass = "pass",
	fail = "fail",
	error = "error"
}

const SERVER_URL =
	window.location.protocol +
	"//" +
	window.location.hostname +
	":" +
	(process.env.REACT_APP_SERVER_PORT || "5000");

function collectTests(testObj): string[] {
	let allTests = [];

	// Loop through each file key in the JSON object
	for (const fileKey in testObj) {
		// Access the nested object
		const testClasses = testObj[fileKey];

		// Loop through each class in the nested object
		for (const classKey in testClasses) {
			// Concatenate the array of test function names to the result
			allTests = allTests.concat(testClasses[classKey]);
		}
	}

	return allTests;
}

export async function getTestUnitsForProject(
	projectID: string
): Promise<string[]> {
	try {
		const url =
			SERVER_URL +
			`/getTestsForProject?` +
			new URLSearchParams({ csrfToken: await getCsrfToken(), projectID });

		const res = await fetch401Interceptor(url, { credentials: "include" });

		if (res.ok) {
			const resJson = await res.json();
			const testNames: string[] = collectTests(resJson);
			return testNames;
		} else {
			const resError = await res.text();
			throw new Error(resError);
		}
	} catch (e) {
		throw e;
	}
}

export async function runTests(
	projectID: string,
	projectTestID: string,
	projectTestName: string = undefined,
	testEnvVars,
	mixpanel: any = undefined
): Promise<any> {
	try {
		const envVars = JSON.stringify(testEnvVars.reduce((obj, variable) => {
			obj[variable.name] = variable.value;
			return obj;
		}, {}));
		console.log(envVars);
		const url = !!projectTestName
			? SERVER_URL +
			  `/runTests?` +
			  new URLSearchParams({
					csrfToken: await getCsrfToken(),
					projectID,
					projectTestID,
					projectTestName,
					envVars
			  })
			: SERVER_URL +
			  `/runTests?` +
			  new URLSearchParams({
					csrfToken: await getCsrfToken(),
					projectID,
					projectTestID,
					envVars
			  });

		const res = await fetch401Interceptor(url, { credentials: "include" });

		if (res.ok) {
			if (mixpanel) {
				testRanEvent(mixpanel, {projectTestID, projectTestName});
			}
			const resJson: any = await res.json();
			return resJson;
		} else {
			const resError: string = await res.text();
			throw new Error(resError);
		}
	} catch (e) {
		console.log(e);
		return {
			error: e
		};
	}
}

export async function loadProjectTests(projectID): Promise<ProjectTest[]> {
	try {
		const url =
			SERVER_URL +
			`/test/${projectID}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, { credentials: "include" });
		const resText = await res.text();

		if (res.ok) {
			if (resText) {
				const projectTests = JSON.parse(resText) as ProjectTest[];
				return projectTests;
			} else return [];
		} else throw new Error(resText);
	} catch (e) {
		addErrorNotification("Error", "Error loading project tests: " + e.message);
	}
}

export async function createProjectTest(
	projectTest: ProjectTest,
	mixpanel: any,
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/test?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify(projectTest)
		});

		if (!res.ok) throw new Error(await res.text());
		else testCreatedEvent(mixpanel, { projectID: projectTest.projectID });
	} catch (e) {
		addErrorNotification("Error", "Error creating project test: " + e.message);
	}
}

export async function updateProjectTest(
	projectTest: ProjectTest,
	mixpanel: any,
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/test/update?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify(projectTest)
		});

		if (!res.ok) throw new Error(await res.text());
		else testUpdatedEvent(mixpanel, { projectID: projectTest.projectID });
	} catch (e) {
		addErrorNotification("Error", "Error updating project test: " + e.message);
	}
}

export async function deleteProjectTestById(
	projectTestId: string
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/test/${projectTestId}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "DELETE",
			credentials: "include"
		});

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error deleting project test: " + e.message);
	}
}
