import {
	Button,
	Divider,
	FormControlLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography,
	useTheme
} from "@mui/material";
import useSetAtom from "hooks/useSetAtom";
import { useState } from "react";
import { feedbackModalOpenAtom } from "store/atoms/UiAtoms";
import CustomModal from ".";
import { submitFeedback } from "../../Resources/ServerInterface";
import { appConfig } from "../../config";

interface FeedbackModalProps {
	open: boolean;
	handleClose: () => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ open, handleClose }) => {
	const [submitting, setSubmitting] = useState(false);
	const [feedbackType, setFeedbackType] = useState("bug");
	const [feedbackDescription, setFeedbackDescription] = useState("");

	const setFeedbackModalOpen = useSetAtom(feedbackModalOpenAtom);

	const theme = useTheme();

	const handleSubmitFeedback = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setSubmitting(true);

		submitFeedback(feedbackType, feedbackDescription).then(() => {
			setSubmitting(false);
			setFeedbackType("bug");
			setFeedbackDescription("");
			setFeedbackModalOpen(false);
		});
	};

	return (
		<CustomModal
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="md"
			paperSx={{ maxWidth: "609px", py: 6 }}
		>
			<Typography variant="h4" sx={{ textAlign: "center", mb: 2 }}>
				How can we improve Audit Wizard?
			</Typography>

			<Typography sx={{ textAlign: "center" }}>
				Please include as much detail as possible so we can make auditing even
				easier for you! Thanks for helping us build a better product.{" "}
			</Typography>

			<Divider sx={{ my: 3 }} />

			<form onSubmit={handleSubmitFeedback}>
				<RadioGroup
					row
					sx={{ mb: 3, justifyContent: "space-evenly" }}
					value={feedbackType}
					onChange={(e, v) => setFeedbackType(v)}
				>
					<FormControlLabel
						value="bug"
						control={<Radio />}
						label="Bug report"
					/>
					<FormControlLabel
						value="feature"
						control={<Radio />}
						label="Feature request"
					/>
					<FormControlLabel value="other" control={<Radio />} label="Other" />
				</RadioGroup>

				<TextField
					multiline
					fullWidth
					rows={5}
					placeholder="What is your feedback?"
					required
					sx={{
						mb: 2,
						".MuiInputBase-root": {
							borderRadius: "10px"
						}
					}}
					value={feedbackDescription}
					onChange={(e) => setFeedbackDescription(e.target.value)}
				/>

				<Button
					fullWidth
					variant="contained"
					sx={{
						color: "text.inverse",
						fontWeight: 600,
						borderRadius: "10px"
					}}
					type="submit"
					disabled={submitting}
				>
					{submitting ? "Submitting..." : "Submit feedback"}
				</Button>
			</form>

			<Typography sx={{ textAlign: "center", mt: 4 }}>
				Free to share feedback with us on{" "}
				<Typography
					component="a"
					href={appConfig.socials.discord}
					target="_blank"
					rel="noreferrer"
					sx={{
						fontWeight: 500,
						color: "primary.main",
						cursor: "pointer",
						borderBottom: `1px solid ${theme.palette.primary.main}`,
						textDecoration: "none"
					}}
				>
					Discord
				</Typography>{" "}
				or{" "}
				<Typography
					component="a"
					href={appConfig.socials.twitter}
					target="_blank"
					rel="noreferrer"
					sx={{
						fontWeight: 500,
						color: "primary.main",
						cursor: "pointer",
						borderBottom: `1px solid ${theme.palette.primary.main}`,
						textDecoration: "none"
					}}
				>
					Twitter
				</Typography>
			</Typography>
		</CustomModal>
	);
};

export default FeedbackModal;
