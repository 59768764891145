import { resetPrivateMode } from "Resources/ServerInterface";
import { useProjects } from "hooks/data/useProjects";
import React from "react";
import ConfirmationModal from "./ConfirmationModal";

interface ResetPrivateModeConfirmModalProps {
	open: boolean;
	onClose: () => void;
}

const ResetPrivateModeConfirmModal: React.FC<
	ResetPrivateModeConfirmModalProps
> = ({ open, onClose }) => {
	const { loadProjects } = useProjects();

	return (
		<ConfirmationModal
			open={open}
			onClose={onClose}
			onConfirm={async () => {
				await resetPrivateMode();
				await loadProjects();
			}}
			title="Reset private mode?"
			description="Are you sure you want to reset private mode? All private mode projects will be deleted and cannot be recovered."
			confirmButtonText="Reset"
			confirmButtonLoadingText="Resetting..."
		/>
	);
};

export default ResetPrivateModeConfirmModal;
