import { Button, Stack } from "@mui/material";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import { ReactText } from "react";
import { SURVEY_DONT_ASK } from "utils/constants";
import { updateObjectInLocalStorage } from "utils/helpersFunctions";

function truncateString(_input: string): string {
	const input = String(_input || "");

	const maxLength = 200;

	if (input.length <= maxLength) {
		return input;
	} else {
		return input?.slice?.(0, maxLength - 3) + "...";
	}
}

export function addSurveyNotification() {
	const sendHotjarEvent = () => {
		// Check if Hotjar is loaded
		if (window.hj) {
			window.hj("trigger", "triggered_survey");
		}
	};

	const SnackbarActions = ({ snackbarKey }: { snackbarKey: ReactText }) => (
		<Stack direction={"row"} spacing={0.5}>
			<Button
				fullWidth
				variant="contained"
				sx={{
					mb: 3,
					color: "#fff",
					fontWeight: 700,
					fontSize: 12,
					height: 40,
					borderRadius: "10px"
				}}
				onClick={() => {
					// Handle action button click
					sendHotjarEvent();
					updateObjectInLocalStorage(SURVEY_DONT_ASK, { dontAsk: true });
					closeSnackbar(snackbarKey);
				}}
			>
				Start survey
			</Button>
			<Button
				fullWidth
				variant="outlined"
				sx={{
					mb: 3,
					color: "#fff",
					fontWeight: 500,
					fontSize: 11,
					height: 40,
					borderRadius: "10px"
				}}
				onClick={() => {
					// Handle "Do not ask again" button click
					updateObjectInLocalStorage(SURVEY_DONT_ASK, { dontAsk: true });
					closeSnackbar(snackbarKey);
				}}
			>
				Don't show again
			</Button>
		</Stack>
	);

	enqueueSnackbar({
		title: "How can we improve Audit Wizard?",
		message:
			"Share your requests and ideas with us on a quick survey so we can build what you need",
		variant: "info",
		autoHideDuration: 120000,
		action: (key) => <SnackbarActions snackbarKey={key} />,
		style: { whiteSpace: "pre-line" }
	});
}

export function addErrorNotification(
	title: string,
	message: string,
	duration: number = 5000
) {
	const truncatedMessage = truncateString(message);

	enqueueSnackbar({
		title: title,
		message: truncatedMessage,
		variant: "error",
		autoHideDuration: duration,
		style: { whiteSpace: "pre-line" }
	});
}

export function addSuccessNotification(
	title: string,
	message: string,
	duration: number = 5000
) {
	enqueueSnackbar({
		title: title,
		message,
		variant: "success",
		autoHideDuration: duration,
		style: { whiteSpace: "pre-line" }
	});
}

export function addInfoNotification(
	title: string,
	message: string,
	duration: number = 5000
) {
	enqueueSnackbar({
		title: title,
		message,
		variant: "info",
		autoHideDuration: duration,
		style: { whiteSpace: "pre-line" }
	});
}

export function addAwardNotification(
	title: string,
	message: string,
	imageUrl: string,
	duration: number = 10000 // Increased duration
) {
	enqueueSnackbar({
		content: (key) => (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					maxWidth: '400px',
					background: 'linear-gradient(45deg, #b88f26, #8b6e1e)',
					color: '#fff',
					fontWeight: '500',
					borderRadius: '10px',
					padding: '10px 20px',
					fontSize: '14px',
					boxSizing: 'border-box',
					position: 'relative'
				}}
			>
				<div
					style={{
						width: "60px",
						height: "60px",
						marginRight: "10px",
						background: "rgba(139, 110, 30, 0.8)",
						borderRadius: "10px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						padding: "5px",
						boxSizing: "border-box"
					}}
				>
					<img
						src={imageUrl}
						alt="Award Badge"
						style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "5px" }}
					/>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
					<strong>{title}</strong>
					<span>{message}</span>
				</div>
				<button
					onClick={() => closeSnackbar(key)}
					style={{
						background: 'none',
						border: 'none',
						color: '#fff',
						fontSize: '16px',
						position: 'absolute',
						right: '10px',
						top: '10px',
						cursor: 'pointer'
					}}
				>
					&times;
				</button>
			</div>
		),
		variant: "default",
		autoHideDuration: duration
	});
}