import { useNotes } from "hooks/data/useNotes";
import React from "react";
import ConfirmationModal from "./ConfirmationModal";

interface DeleteNoteModalProps {
	noteId: string | false;
	onClose: () => void;
}

const DeleteNoteModal: React.FC<DeleteNoteModalProps> = ({
	noteId,
	onClose
}) => {
	const { deleteNote } = useNotes();

	return (
		<ConfirmationModal
			open={!!noteId}
			onClose={onClose}
			onConfirm={() => deleteNote(noteId || "")}
			title="Delete Note?"
			description="Are you sure you want to delete this note?"
			confirmButtonText="Delete"
			confirmButtonLoadingText="Deleting..."
		/>
	);
};

export default React.memo(DeleteNoteModal);
