import { fetch401Interceptor, getCsrfToken } from "./ServerInterface";

export interface BadgeType {
	_id?: string | number;
	imageUrl: string;
	name: string;
	description: string;
	rarity: number;
	type?: string;
}

export interface BadgeSummaryType extends BadgeType {
	userOwns: boolean;
}

export type RateLimitConfigNames =
	| "ai"
	| "aiFilePoc"
	| "aiIssueDescription"
	| "aiIssueRecommendation"
	| "aiPoc"
	| "aiRewrite"
	| "createPasswordReset"
	| "foundry"
	| "global"
	| "imports"
	| "scans"
	| "signup"
	| "simulateSingleTx"
	| "storage"
	| "verifyPasswordReset";

export interface RateLimitSummaryType {
	configName: RateLimitConfigNames;
	limit: number;
	current: number;
	remaining: number;
	retryAfter?: Date;
}

const SERVER_URL =
	window.location.protocol +
	"//" +
	window.location.hostname +
	":" +
	(process.env.REACT_APP_SERVER_PORT || "5000");

export async function getBadgeSummaryForUser(): Promise<BadgeSummaryType[]> {
	try {
		const url =
			SERVER_URL +
			`/badgeSummary?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, { credentials: "include" });
		if (res.ok) {
			const badgeSummaries: BadgeSummaryType[] = await res.json();
			return badgeSummaries;
		} else {
			const resError = await res.text();
			throw new Error(resError);
		}
	} catch (e) {
		throw e;
	}
}

export async function getRateLimitSummaryForUser(): Promise<
	RateLimitSummaryType[]
> {
	try {
		const url =
			SERVER_URL +
			`/rateLimitSummary?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, { credentials: "include" });
		if (res.ok) {
			const rateLimitSummaries: RateLimitSummaryType[] = await res.json();
			return rateLimitSummaries;
		} else {
			const resError = await res.text();
			throw new Error(resError);
		}
	} catch (e) {
		throw e;
	}
}
