import Project from "Resources/Project";
import { ControlFlowGraph } from "Resources/SolidityParser";
import { atom } from "jotai";

export interface ProjectsType {
	name: string;
	ID: string;
	privateMode?: boolean;
	folder?: { name: string; order: number };
}

// Project
export const projectsLoadedAtom = atom<boolean>(false);
export const projectsAtom = atom<ProjectsType[]>([]);
export const projectAtom = atom<Project | null>(null as Project | null);
export const projectLoadedAtom = atom<boolean>(false);
export const projectCFGAtom = atom<ControlFlowGraph | null>(null as ControlFlowGraph | null);
