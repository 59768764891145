// KeyboardButton.tsx
import React from "react";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import "keyboard-css/dist/css/main.min.css";

interface KeyboardButtonProps {
	label: string;
	variant?: string;
	index?: number;
	handleClick?: any;
}

const KeyboardButton: React.FC<KeyboardButtonProps> = ({
	label,
	variant = "",
	index,
	handleClick
}) => {
	return (
		<Button
			onClick={() => handleClick?.(index)}
			className={`kbc-button ${
				variant && "kbc-button-" + variant
			} kbc-button-xxs`}
			style={{ width: "max-content", minWidth: 0 }}
		>
			<Typography variant="body2" sx={{ fontWeight: 600, lineHeight: 1.1 }}>
				{label}
			</Typography>
		</Button>
	);
};

export default KeyboardButton;
