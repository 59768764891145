import { Icon } from "@iconify/react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
	Checkbox,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	TextFieldProps,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import { useChecklist } from "hooks/data/useChecklist";
import React, { useEffect, useState } from "react";
import { ChecklistGroupType, ChecklistType } from "store/atoms/checklistAtoms";

const EditChecklistField: React.FC<
	TextFieldProps & {
		checklistName: string;
		handleSubmit: any;
		handleClose: any;
	}
> = ({
	checklistName,
	handleClose,
	handleSubmit: propsHandleSubmit,
	...props
}) => {
	const theme = useTheme();
	const [value, setValue] = useState<any>(checklistName);

	const handleSubmit = (
		e:
			| React.FormEvent<HTMLFormElement>
			| React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e?.preventDefault?.();

		if (value === checklistName) return handleClose();

		propsHandleSubmit(value);
	};

	return (
		<form onSubmit={handleSubmit} style={{ width: "100%" }}>
			<TextField
				size="small"
				value={value}
				onChange={(e) => setValue(e.target.value)}
				onClick={(e) => e.preventDefault()}
				fullWidth
				InputProps={{
					autoFocus: true,
					sx: {
						height: 47,
						bgcolor: "background.paper",
						borderRadius: "8px",
						border: `1px solid ${theme.palette.background["nav"]}`
					},
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								sx={{ color: "primary.main", height: 20, width: 20 }}
								onClick={(e) => {
									e.stopPropagation();
									handleClose();
								}}
							>
								<CloseIcon sx={{ fontSize: 18 }} />
							</IconButton>

							<IconButton
								onClick={(e) => {
									e.stopPropagation();
									handleSubmit(e);
								}}
								sx={{ color: "primary.main", height: 22, width: 20 }}
							>
								<CheckIcon sx={{ fontSize: 18 }} />
							</IconButton>
						</InputAdornment>
					)
				}}
				{...props}
			/>
		</form>
	);
};

const ChecklistItem: React.FC<{
	group: ChecklistGroupType;
	index: number;
	checklist: ChecklistType;
}> = ({ checklist, group, index }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;
	const { title, completed } = checklist;

	const [renamingMode, setRenamingMode] = useState(false);
	const [checked, setChecked] = useState(false);

	const { toggleChecklist, renameChecklist, deleteChecklist } = useChecklist();

	const handleToggleChecked = () => {
		toggleChecklist(group._id, index);
	};

	const handleDelete = () => {
		deleteChecklist(group._id, index);
	};

	const handleRename = async (value) => {
		await renameChecklist(group._id, index, value);

		setRenamingMode(false);
	};

	useEffect(() => {
		setChecked(!!completed);
	}, [completed]);

	if (renamingMode) {
		return (
			<EditChecklistField
				checklistName={title}
				handleClose={() => setRenamingMode(false)}
				handleSubmit={handleRename}
			/>
		);
	}

	return (
		<Stack
			direction="row"
			spacing={1}
			onClick={handleToggleChecked}
			sx={{
				cursor: "pointer",
				p: 1.5,
				border: "1px solid",
				borderColor: "background.paper2",
				borderRadius: "8px",
				bgcolor: completed ? "background.paper" : "transparent",
				...(themeMode === "light" && {
					borderColor: "background.paper",
					boxShadow: 1
				}),
				"&:hover": {
					bgcolor: checked ? "background.default" : "background.nav",
					"& .showOnHover": {
						display: "inline-flex"
					}
				}
			}}
		>
			<Checkbox
				size="small"
				checked={!!checked}
				sx={{ height: 16, width: 16, color: "background.paper2" }}
			/>

			<Typography
				sx={{ textDecoration: checked ? "line-through" : "unset", flex: 1 }}
			>
				{title}
			</Typography>

			<Stack
				direction="row"
				alignItems="center"
				className="showOnHover"
				sx={{ display: "none" }}
			>
				<Tooltip title="Rename">
					<IconButton
						size="small"
						onClick={(e) => {
							e.stopPropagation();
							setRenamingMode(true);
						}}
						sx={{
							width: 20,
							height: 20,
							p: 0
						}}
					>
						<Icon icon="mdi:rename-outline" fontSize={18} />
					</IconButton>
				</Tooltip>

				<Tooltip title="Delete">
					<IconButton
						size="small"
						onClick={(e) => {
							e.stopPropagation();
							handleDelete();
						}}
						sx={{
							width: 20,
							height: 20,
							p: 0
						}}
					>
						<Icon icon="ic:outline-delete" fontSize={18} />
					</IconButton>
				</Tooltip>
			</Stack>
		</Stack>
	);
};

export default React.memo(ChecklistItem);
