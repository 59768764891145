import { Icon } from "@iconify/react";
import { IconButton, Tooltip } from "@mui/material";
import { ButtonGroupProps } from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { downloadSvg } from "components/Toolbox/Tools/GraphTool/helpers";
import { useAtom } from "jotai";
import * as React from "react";
import { openedTabAtom } from "store/atoms/UiAtoms";

const options = ["PNG", "SVG", "JPG", "PDF"];

const DownloadButton: React.FC<ButtonGroupProps> = (props) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [selectedIndex, setSelectedIndex] = React.useState(1);
	const open = !!anchorEl;

	const [{ openedTab }] = useAtom(openedTabAtom);

	const handleMenuItemClick = (
		event: React.MouseEvent<HTMLLIElement, MouseEvent>,
		index: number
	) => {
		setSelectedIndex(index);

		const type = options[index];
		downloadSvg(openedTab?.name || "Graph", type || "PNG");
		handleClose();
	};

	const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClick = () => {
		downloadSvg(openedTab?.name || "Graph", "SVG");
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<Tooltip title="Download Graph as SVG">
				<IconButton sx={{ height: 24, width: 24, p: 0 }} onClick={handleClick}>
					<Icon icon="material-symbols:download" fontSize={20} />
				</IconButton>
			</Tooltip>

			<Popper
				sx={{
					zIndex: 1
				}}
				open={open}
				anchorEl={anchorEl}
				placement="bottom-end"
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: "bottom-end"
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{options.map((option, index) => (
										<MenuItem
											key={option}
											selected={index === selectedIndex}
											onClick={(event) => handleMenuItemClick(event, index)}
										>
											{option}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</React.Fragment>
	);
};

export default DownloadButton;
