import { useAtom } from "jotai";
import React from "react";
import { selectedAccountSettingAtom } from "../../atoms";
import { AccountSideBarOptions } from "../sidebars/AccountSidebar";
import AccountSettings from "./AccountSettings";
import LoginPassword from "./LoginPassword";
import PaymentsAndBilling from "./PaymentsAndBilling";
import Privacy from "./Privacy";

const MyAccount = () => {
	const [selectedAccountSetting] = useAtom(selectedAccountSettingAtom);

	const getAccountComponent = () => {
		switch (selectedAccountSetting) {
			case AccountSideBarOptions.PaymentsAndBilling:
				return <PaymentsAndBilling />;
			case AccountSideBarOptions.AccountSettings:
				return <AccountSettings />;
			case AccountSideBarOptions.Privacy:
				return <Privacy />;
			case AccountSideBarOptions.LoginPassword:
				return <LoginPassword />;
			default:
				return <></>;
		}
	};

	return <>{getAccountComponent()}</>;
};

export default React.memo(MyAccount);
