import CodeEditorCore from "@auditware/CodeEditorCore";
import { Monaco } from "@monaco-editor/react";
import { Box, Stack } from "@mui/material";
import ProjectFile from "Resources/ProjectFile";
import { ProjectTest } from "Resources/ServerInterfaceProjectTest";
import EditorTab from "context/EditorTab";
import { useTests } from "hooks/data/useTests";
import { useEditorTabs } from "hooks/ui/useEditorTabs";
import { useLocalStorage } from "hooks/ui/useLocalStorage";
import { useAtom, useSetAtom } from "jotai";
import { editor } from "monaco-editor";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { focusedEditorTabIndexAtom, openedTabAtom } from "store/atoms/UiAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import {
	DEFAULT_TEST_EDITOR_VALUE,
	pocAIContentsAtom,
	pocAIGeneratingAtom,
	testTemplateEditorValueAtom,
	testTemplateHoverAtom,
	testTemplateToSaveAtom
} from "store/atoms/testAtoms";
import { LOCALSTORAGE_TEST_EDITOR } from "utils/constants";
import TestPanel from "../TestEditor/TestPanel";

const TestCustomEditor: React.FC<ProjectTest> = ({
	projectID,
	testName,
	_id,
	originFile,
	testContents = ""
}) => {
	const [loading, setLoading] = useState(true);

	const { addNewTest, editTest } = useTests();
	const { changeOpenTabValues, changeOpenTabName } = useEditorTabs();

	const [{ openedTab, projectFilePath }] = useAtom(openedTabAtom);

	const [project] = useAtom(projectAtom);
	const [hoverValue] = useAtom(testTemplateHoverAtom);
	const setTestTemplateToSave = useSetAtom(testTemplateToSaveAtom);
	const [editorValue, setEditorValue] = useAtom(testTemplateEditorValueAtom);
	const [focusedTabIndex] = useAtom(focusedEditorTabIndexAtom);

	const [pocAiGenerating] = useAtom(pocAIGeneratingAtom);
	const [pocAIContents] = useAtom(pocAIContentsAtom);

	const STORAGE_KEY = `${LOCALSTORAGE_TEST_EDITOR}_${project?.id}_${projectFilePath}`;
	const [storageVal, setStorageVal] = useLocalStorage(
		STORAGE_KEY,
		testContents || DEFAULT_TEST_EDITOR_VALUE
	);

	const handleUpdateLocalStorage = (val: string) => {
		setStorageVal(val);
	};

	const deleteLocalStorageKey = () => {
		localStorage.removeItem(STORAGE_KEY);
	};

	// Replace editor value
	useEffect(() => {
		if (openedTab?.metadata?.aiGenerating) {
			let pocContentString = pocAIContents;
			pocContentString = pocContentString.replaceAll("```solidity", "");
			pocContentString = pocContentString.replaceAll("```", "");

			if (!testContents && projectFilePath === openedTab?.metadata?.tabPath) {
				setEditorValue(pocContentString);
			}
		}
	}, [pocAIContents, openedTab]);

	// Get Value
	useEffect(() => {
		if (!!testContents) {
			if (!storageVal?.length || testContents === storageVal) {
				setEditorValue(testContents);
			} else {
				setEditorValue(storageVal);
			}
		} else if (!testContents && projectFilePath === "/custom-test") {
			setEditorValue(
				storageVal ||
					(pocAiGenerating ? pocAIContents : DEFAULT_TEST_EDITOR_VALUE)
			);
		}

		setLoading(false);
	}, [testContents, projectFilePath]);

	// Set storage val
	useEffect(() => {
		if (editorValue !== storageVal && !loading)
			handleUpdateLocalStorage(editorValue);
	}, [editorValue, projectFilePath, loading]);

	// Update tab name
	useMemo(() => {
		if (!loading) {
			if (testContents === storageVal) {
				deleteLocalStorageKey();
				changeOpenTabName(
					focusedTabIndex,
					openedTab.name?.replaceAll(" *", "")
				);
			} else {
				if (!openedTab?.name?.endsWith(" *")) {
					changeOpenTabName(focusedTabIndex, `${openedTab?.name} *`);
				}
			}
		}
	}, [storageVal, loading]);

	const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
	const monacoRef = useRef<Monaco | null>(null);

	const setEditorRef = (newValue) => {
		editorRef.current = newValue;
	};

	const setMonacoRef = (newValue) => {
		monacoRef.current = newValue;
	};

	const handleSaveClick = async (_testName) => {
		setLoading(true);

		const alterTab = (res) => {
			setLoading(false);

			const tabName = _testName;
			const tabPath = `/custom-test/${res?._id}`;

			changeOpenTabValues(
				focusedTabIndex,
				new EditorTab(
					new ProjectFile(tabName, null, tabPath, null, {
						...(res || {})
					}),
					tabName,
					tabPath
				)
			);
		};

		// If existing test
		if (!!_id) {
			await editTest({
				projectID,
				testName: _testName,
				_id,
				originFile,
				testContents: editorValue
			}).then((res) => {
				alterTab(res);
			});
		} else {
			await addNewTest(_testName, editorValue).then((res) => {
				alterTab(res);
			});
		}

		deleteLocalStorageKey();
	};

	const prepareTestAsTemplate = () => {
		setTestTemplateToSave(editorValue);
	};

	return (
		<Stack sx={{ height: "100%" }}>
			{/* Test Panel */}
			<TestPanel
				testName={testName || ""}
				onSaveClick={handleSaveClick}
				contentToCopy={editorValue}
				prepareTestAsTemplate={prepareTestAsTemplate}
				loading={loading}
			/>

			<Box sx={{ flex: 1, opacity: hoverValue?.length ? 0.3 : 1 }}>
				<CodeEditorCore
					value={hoverValue?.length ? hoverValue : editorValue}
					language={"sol"}
					editorRef={editorRef.current}
					setEditorRef={setEditorRef}
					monacoRef={monacoRef.current}
					setMonacoRef={setMonacoRef}
					editorOptions={{
						readOnly: !!hoverValue || !!pocAiGenerating || loading
					}}
					onChange={(v) => {
						if (!pocAiGenerating) setEditorValue(v);
					}}
				/>
			</Box>
		</Stack>
	);
};

export default React.memo(TestCustomEditor);
