import {
	Box,
	Card,
	Stack,
	Tab,
	Tabs,
	Typography,
	useTheme
} from "@mui/material";
import hex from "utils/hexTransparency";
import { difficulty_icon } from "./ImportCTF";
import { useCTFContext } from "context/CTFContext";

const CTFList: React.FC<{
	selectedCtfIndex: number;
	setSelectedCtfIndex: (val: number) => void;
}> = ({ selectedCtfIndex, setSelectedCtfIndex }) => {
	const theme = useTheme();

	const { ctfs } = useCTFContext();

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedCtfIndex(newValue);
	};

	console.log(selectedCtfIndex);

	return (
		<Box
			sx={{
				bgcolor: "background.nav",
				py: 2,
				width: "100%",
				borderRadius: "12px",
				mb: 2
			}}
		>
			<Tabs
				variant="scrollable"
				scrollButtons="auto"
				value={selectedCtfIndex}
				onChange={handleChange}
				sx={{
					width: "100%",
					"& .MuiTabs-scrollButtons.Mui-disabled": {
						// display: "none",
						width: "16px"
					}
				}}
				TabIndicatorProps={{ sx: { bgcolor: "transparent" } }}
			>
				{ctfs.map((item, i) => (
					<Tab
						disableRipple
						// value={i}
						key={i}
						sx={{ p: 0, pr: i < ctfs.length - 1 && 2 }}
						label={
							<Card
								elevation={0}
								sx={{
									width: 170,
									height: 194,
									borderRadius: 2,
									background: `url(${item.image})`,
									backgroundSize: "cover",
									backgroundPosition: "center",
									textAlign: "start",
									border:
										selectedCtfIndex === i &&
										`3px solid ${theme.palette.primary.main}`
								}}
							>
								<Stack
									alignItems="flex-start"
									sx={{
										p: 1.5,
										height: "100%",
										backgroundColor: `#000000${hex["70%"]}`
									}}
								>
									<Typography sx={{ fontWeight: 700 }}>{item.title}</Typography>

									<Typography color="primary.light">{item.author}</Typography>

									<Box sx={{ flex: 1 }} />

									<Stack direction="row" alignItems="center" spacing={0.5}>
										<Box
											component="img"
											src={difficulty_icon[item.difficulty]}
										/>
										<Typography>{item.difficulty}</Typography>
									</Stack>
								</Stack>
							</Card>
						}
					/>
				))}
			</Tabs>
		</Box>
	);
};

export default CTFList;
