import ExcalidrawCore from "@auditware/ExcalidrawCore";
import ProjectFile from "Resources/ProjectFile";
import EditorTab from "context/EditorTab";
import { useWhiteboard } from "hooks/data/useWhiteboard";
import { useEditorTabs } from "hooks/ui/useEditorTabs";
import { useAtom } from "jotai";
import React from "react";
import { focusedEditorTabIndexAtom } from "store/atoms/UiAtoms";
import { Whiteboard } from "store/atoms/whiteboardAtoms";

const ExcaliDrawTool: React.FC<{
	_id?: string;
}> = ({ _id }) => {
	const { changeOpenTabValues } = useEditorTabs();
	const { getBoard, createDrawing, editDrawing } = useWhiteboard();
	const board = getBoard(_id);

	const [focusedTabIndex] = useAtom(focusedEditorTabIndexAtom);

	const saveNewDrawing = async (
		title: string,
		value: string,
		files: string,
		appState: string
	) => {
		const _title = title?.trim() || "My Whiteboard";

		await createDrawing(_title, value, files, appState).then(
			(res: Whiteboard) => {
				if (!res) return;
				const __id = res._id;

				const shortTitle =
					_title.length > 10 ? _title.substring(0, 10) + "..." : _title;

				let tabName = `Whiteboard (${shortTitle})`;
				let tabPath = `/whiteboard/${__id}`;

				changeOpenTabValues(
					focusedTabIndex,
					new EditorTab(
						new ProjectFile(tabName, null, tabPath, null, {
							title,
							value,
							_id: __id
						}),
						tabName,
						tabPath
					)
				);
			}
		);
	};

	const updateDrawing = async (
		title: string,
		value: string,
		files: string,
		appState: string
	) => {
		const _title = title?.trim() || "My Whiteboard";

		await editDrawing(_id, _title, value, files, appState).then(() => {
			const shortTitle =
				_title.length > 10 ? _title.substring(0, 10) + "..." : _title;

			let tabName = `Whiteboard (${shortTitle})`;
			let tabPath = `/whiteboard/${_id}`;

			changeOpenTabValues(
				focusedTabIndex,
				new EditorTab(
					new ProjectFile(tabName, null, tabPath, null, {
						title,
						value,
						_id: _id
					}),
					tabName,
					tabPath
				)
			);
		});
	};

	return (
		<ExcalidrawCore
			_id={_id}
			title={board?.title}
			value={board?.value}
			files={board?.files}
			appState={board?.appState}
			handleSaveBoard={saveNewDrawing}
			handleUpdateBoard={updateDrawing}
		/>
	);
};

export default React.memo(ExcaliDrawTool);
