import {
	Box,
	Container,
	IconButton,
	SxProps,
	Tooltip,
	styled,
	useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./githubTheme.css";
import renderMarkdown from "Resources/MarkdownRender";
import ReactMarkdown from "react-markdown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

const StyledDiv = styled("div")({
	"& pre": {
		whiteSpace: "pre-line",
		bgcolor: "red"
	}
});

const MarkdownReader: React.FC<{
	value: string;
	errorCallback?: Function;
	sx?: SxProps;
	containerSx?: SxProps;
	withCopyButton?: boolean;
}> = ({ value, errorCallback, sx = {}, containerSx = {}, withCopyButton }) => {
	const [error, setError] = useState(false);
	const [htmlResponse, setHtmlResponse] = useState(null);
	const [copied, setCopied] = useState(false);

	const theme = useTheme();

	useEffect(() => {
		if (value)
			renderMarkdown(value)
				.then((res) => {
					if (!res) return setError(true);
					setHtmlResponse(res);
				})
				.catch((err) => {
					setError(true);
					errorCallback?.();
				});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		}
	}, [copied]);

	return (
		<Box
			sx={{
				my: 2,
				...containerSx
			}}
		>
			<Container
				maxWidth="lg"
				sx={{
					border: !error && `1px solid ${theme.palette.background["nav"]}`,
					p: 1,
					borderRadius: "8px",
					height: "auto",
					overflow: "auto",
					boxShadow: 1,
					position: "relative",
					...sx
				}}
			>
				{withCopyButton && (
					<CopyToClipboard text={value} onCopy={() => setCopied(true)}>
						<Tooltip title={copied ? "Copied" : "Copy to clipboard"}>
							<IconButton
								size="small"
								sx={{
									height: 20,
									width: 20,
									position: "absolute",
									top: 10,
									right: 10,
									opacity: 0.2,
									"&:hover": { opacity: 1 }
								}}
							>
								<ContentCopyIcon sx={{ fontSize: 16 }} />
							</IconButton>
						</Tooltip>
					</CopyToClipboard>
				)}

				{!error && (
					<StyledDiv
						dangerouslySetInnerHTML={{
							__html: htmlResponse
						}}
					/>
				)}

				{error && (
					<Box sx={{ px: 5 }}>
						<ReactMarkdown>{value}</ReactMarkdown>
					</Box>
				)}
			</Container>
		</Box>
	);
};

export default MarkdownReader;
