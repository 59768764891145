import { ProjectTest } from "Resources/ServerInterfaceProjectTest";
import { TemplateCollection } from "Resources/ServerInterfaceTemplateCollection";
import { atom } from "jotai";

export const DEFAULT_TEST_EDITOR_VALUE = `pragma solidity ^0.8.13;
import "forge-std/Test.sol";

contract PoC is Test {
	function testPoC() public {

	}
}`;

export interface TestResult {
	testName: string;
	decodedLogs: string[];
	status: string;
	traces: string[];
	reason?: string;
	duration: number;
}
export enum TestTabs {
	Builder,
	Collection
}

export const testRunningAtom = atom<boolean>(false);
export const testFilesAtom = atom<ProjectTest[]>([]);
export const testResultsAtom = atom<{ [key: string]: TestResult[] }>({});
export const testCompilerErrorAtom = atom("");
export const testsInitializingAtom = atom(false);
export const testCollectionsAtom = atom<TemplateCollection[]>([]);
export const testTemplateToSaveAtom = atom("");

export const testTemplateHoverAtom = atom("");
export const testTemplateEditorValueAtom = atom("");

export const pocAIGeneratingAtom = atom(false);
export const pocAIContentsAtom = atom("");

export const testTabToggleValueAtom = atom<TestTabs>(TestTabs.Collection);

export const testEnvVarsAtom = atom<{name: string, value: string}[]>([]);
export const testPanelNameAtom = atom("");