import { Icon } from "@iconify/react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
	Box,
	CircularProgress,
	Collapse,
	IconButton,
	LinearProgress,
	Stack,
	SxProps,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";
import ProjectFile from "Resources/ProjectFile";
import { ProjectTest } from "Resources/ServerInterfaceProjectTest";
import { useProject } from "hooks/data/useProject";
import { useTests } from "hooks/data/useTests";
import { useEditorTabs } from "hooks/ui/useEditorTabs";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { projectAtom } from "store/atoms/projectToolAtoms";
import { testResultsAtom, testRunningAtom } from "store/atoms/testAtoms";
import hex from "utils/hexTransparency";

const TestCase: React.FC<{
	name: string;
	type: string | null;
	resultId: string;
	data: any;
	runOneTest: any;
}> = ({ name, type, resultId, data, runOneTest }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const { findAndOpenTab, openCustomTab } = useEditorTabs();
	
	const [testRunning] = useAtom(testRunningAtom);

	const handleOpenTrace = () => {
		const tabName = `Trace - ${name}`;
		const tabPath = `/test-trace/${name}`;

		findAndOpenTab(tabPath, () =>
			openCustomTab(
				null,
				tabName,
				tabPath,
				new ProjectFile(tabName, null, tabPath, null, {
					name,
					data
				})
			)
		);
	};

	return (
		<Box sx={{ bgcolor: "background.paper", borderRadius: "8px" }}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				spacing={2}
				sx={{
					p: 1.5,
					borderRadius: "8px",
					bgcolor:
						type === "pass"
							? `${theme.palette.success.dark}${
									themeMode === "light" ? hex["30%"] : hex["15%"]
							  }`
							: type === "error" || type === "fail"
							? `${theme.palette.error.main}${
									themeMode === "light" ? hex["30%"] : hex["15%"]
							  }`
							: `${theme.palette.primary.main}${
									themeMode === "light" ? hex["30%"] : hex["10%"]
							  }`
				}}
			>
				<Typography
					sx={{
						flex: 1,
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis"
					}}
				>
					{name}
				</Typography>

				<Stack direction="row" alignItems="center" spacing={0.5}>
					{(type === "pass" || type === "error" || type === "fail") && (
						<Tooltip title="View trace">
							<IconButton
								size="small"
								sx={{
									p: 0,
									height: 28,
									width: 28,
									opacity: 0.6,
									"&:hover": { opacity: 1 }
								}}
								onClick={handleOpenTrace}
							>
								<Icon icon="fluent:document-search-32-filled" fontSize={18} />
							</IconButton>
						</Tooltip>
					)}

					{type === "pass" && (
						<IconButton
							size="small"
							sx={{ p: 0, height: 28, width: 28, cursor: "default" }}
							disableRipple
						>
							<CheckCircleIcon
								fontSize="small"
								sx={{ color: "success.main" }}
							/>
						</IconButton>
					)}

					{(type === "error" || type === "fail") && (
						<IconButton
							sx={{ p: 0, height: 28, width: 28, cursor: "default" }}
							disableRipple
						>
							<CancelIcon fontSize="small" sx={{ color: "error.main" }} />
						</IconButton>
					)}

					<Tooltip title={type === "untested" ? "Run Test" : "Rerun Test"}>
						<IconButton
							size="small"
							disabled={testRunning}
							onClick={() => runOneTest(resultId, name)}
							sx={{ p: 0, height: 28, width: 28 }}
						>
							{testRunning ? (
								<CircularProgress size={24} color="inherit" />
							) : (
								<Icon
									icon={
										type === "untested"
											? "heroicons:play-solid"
											: "ic:outline-replay"
									}
									fontSize={20}
								/>
							)}
						</IconButton>
					</Tooltip>
				</Stack>
			</Stack>
		</Box>
	);
};

const ColorsOverview: React.FC<{ tests: string[] | null[] }> = ({
	tests = []
}) => {
	return (
		<Stack
			rowGap={0.5}
			columnGap={0.25}
			alignContent="center"
			direction="row"
			flexWrap="wrap"
			sx={{ height: 8 }}
		>
			{tests.map((type, i) => (
				<Box
					key={i}
					sx={{
						height: "100%",
						width: tests.length >= 50 ? 4 : tests.length >= 9 ? 8 : 16,
						bgcolor:
							type === "pass"
								? "success.main"
								: type === "error" || type === "fail"
								? "error.main"
								: "background.paper2",
						...(i === 0 && {
							borderTopLeftRadius: "40%",
							borderBottomLeftRadius: "40%"
						}),
						...(i === tests.length - 1 && {
							borderTopRightRadius: "40%",
							borderBottomRightRadius: "40%"
						})
					}}
				/>
			))}
		</Stack>
	);
};

const TestItem: React.FC<{
	result: ProjectTest;
	testCompilerError?: boolean;
	sx?: SxProps;
}> = ({ sx = {}, result, testCompilerError }) => {
	const [project] = useAtom(projectAtom);

	const [expanded, setExpanded] = useState(false);
	const [testsRunning] = useAtom(testRunningAtom);
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const { testName, _id, originFile, originFilePath, units = [] } = result;

	const [testResults] = useAtom(testResultsAtom);
	const testFileResults = testResults[result._id] || [];

	const { findAndOpenTab, openCustomTab } = useEditorTabs();

	const { openProjectFile } = useProject();

	const { deleteTest, runAllTests, runOneTest } = useTests(project.id);

	const handleEditClick = () => {
		const tabName = testName;
		const tabPath = `/custom-test/${_id}`;

		findAndOpenTab(tabPath, () =>
			openCustomTab(
				null,
				tabName,
				tabPath,
				new ProjectFile(tabName, null, tabPath, null, result)
			)
		);
	};

	return (
		<Box
			sx={{
				width: "100%",
				borderRadius: "8px",
				backgroundImage: "none",
				overflow: "hidden",
				...(themeMode === "dark" && {
					bgcolor: "transparent !important",
					border: `1px solid ${theme.palette.primary.dark}${hex["80%"]}`
				}),
				...(themeMode === "light" && {
					bgcolor: "background.paper",
					boxShadow: 1
				}),
				...sx
			}}
		>
			{/* Top */}
			<Box onClick={() => setExpanded(!expanded)} sx={{ cursor: "pointer" }}>
				<Stack
					direction="row"
					alignItems="center"
					// spacing={0.5}
					sx={{
						position: "relative",
						bgcolor: "background.paper",
						p: 1.5,
						pb: 1.2,
						"&:hover .showOnHover": { display: "inline-flex" }
					}}
				>
					<Typography>
						File:{" "}
						<Typography component="span" sx={{ fontWeight: 600 }}>
							{testName}
						</Typography>
					</Typography>

					<Box sx={{ flex: 1 }} />

					{!!originFilePath && (
						<Tooltip title="Run all tests">
							<IconButton
								disabled={testsRunning}
								size="small"
								className="showOnHover"
								sx={{
									height: 22,
									width: 22,
									display: "none",
									color: "success.main"
								}}
								onClick={(e) => {
									e.stopPropagation();
									runAllTests(_id);
								}}
							>
								<PlayArrowIcon sx={{ fontSize: 22 }} />
							</IconButton>
						</Tooltip>
					)}

					{!!originFilePath && (
						<Tooltip title="Open test file">
							<IconButton
								size="small"
								className="showOnHover"
								sx={{
									height: 22,
									width: 22,
									display: "none",
									color: "info.light"
								}}
								onClick={(e) => {
									e.stopPropagation();
									openProjectFile(originFilePath);
								}}
							>
								<FileOpenIcon sx={{ fontSize: 16 }} />
							</IconButton>
						</Tooltip>
					)}

					{!originFile && (
						<>
							<Tooltip title="Edit test file">
								<IconButton
									size="small"
									color="info"
									className="showOnHover"
									sx={{ height: 22, display: "none" }}
									onClick={(e) => {
										e.stopPropagation();
										handleEditClick();
									}}
								>
									<EditIcon sx={{ fontSize: 16 }} />
								</IconButton>
							</Tooltip>
{/* 
							<Tooltip title="Delete test file">
								<IconButton
									size="small"
									color="error"
									className="showOnHover"
									sx={{ height: 22, display: "none" }}
									onClick={(e) => {
										e.stopPropagation();
										deleteTest(_id);
									}}
								>
									<DeleteIcon sx={{ fontSize: 16 }} />
								</IconButton>
							</Tooltip> */}
						</>
					)}

					{!expanded ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}

					{testsRunning && (
						<LinearProgress
							sx={{
								opacity: 0.1,
								position: "absolute",
								top: 0,
								bottom: 0,
								right: 0,
								height: "100%",
								width: "100%"
							}}
						/>
					)}
				</Stack>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					spacing={2}
					sx={{
						bgcolor:
							themeMode === "light"
								? `${theme.palette.background["paper2"]}`
								: `${theme.palette.background["nav"]}${hex["80%"]}`,
						px: 1.5,
						py: 1.2
					}}
				>
					<Typography>
						{units?.length || 0} test{units?.length !== 1 && "s"}{" "}
						{testCompilerError && (
							<Typography
								component="span"
								variant="body2"
								sx={{
									fontStyle: "italic",
									color: themeMode === "light" ? "error.main" : "error.light"
								}}
							>
								(Failed to compile)
							</Typography>
						)}
					</Typography>

					<ColorsOverview
						tests={units?.map((item) => {
							return (
								testFileResults.find((el) => el.testName === item.testName)
									?.status || item.status
							);
						})}
					/>
				</Stack>
			</Box>

			{/* Content */}
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<Stack spacing={1} sx={{ p: 1 }}>
					{ !!testFileResults.find((el) => el.testName === 'setUp') ? 
						<TestCase
							name={testFileResults.find((el) => el.testName === 'setUp').testName}
							type={testFileResults.find((el) => el.testName === 'setUp').status}
							resultId={result._id}
							data={testFileResults.find((el) => el.testName === 'setUp')}
							runOneTest={runOneTest}
						/> : 
						null
					}
					{units.map((_item, i) => {
						const item =
							testFileResults.find((el) => el.testName === _item.testName) ||
							_item;

						return (
							<TestCase
								key={i}
								name={item.testName}
								type={item.status}
								resultId={result._id}
								data={item}
								runOneTest={runOneTest}
							/>
						);
					})}
				</Stack>
			</Collapse>
		</Box>
	);
};

export default React.memo(TestItem);
