import { Icon } from "@iconify/react";
import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";

const StatusBadge = ({ isSuccess }) => {
	const theme = useTheme();

	return (
		<Stack direction="row" alignItems="center" spacing={0.7} sx={{ mb: 1 }}>
			{isSuccess ? (
				<>
					<Icon
						icon="icon-park-outline:check-one"
						color={theme.palette.success.main}
						fontSize={20}
					/>

					<Typography color="success.main" sx={{ fontWeight: 600 }}>
						Success
					</Typography>
				</>
			) : (
				<>
					<Icon
						icon="ic:outline-cancel"
						color={theme.palette.error.main}
						fontSize={20}
					/>

					<Typography color="error.main" sx={{ fontWeight: 600 }}>
						Failed
					</Typography>
				</>
			)}
		</Stack>
	);
};

export default React.memo(StatusBadge);
