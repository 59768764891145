import {
	Box,
	Container,
	Dialog,
	Divider,
	List,
	ListItem,
	ListItemSecondaryAction,
	Slide,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import { usernameAtom } from "atoms";
import KeyboardButton from "components/KeyboardButton";
import { useWindowSize } from "hooks/ui/useWindowSize";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { getOperatingSystem } from "utils/helpersFunctions";

const HIDDEN_CODE = "17, 1, 24";

const SHORTCUTS = [
	{
		label: "Full Screen",
		windowsKeys: [{ index: 0, name: "F11" }],
		macKeys: [{ index: 0, name: "F11" }]
	},
	{
		label: "Search All Text",
		windowsKeys: [
			{ index: 4, name: "ctrl" },
			{ index: 5, name: "shift" },
			{ index: 6, name: "F" }
		],
		macKeys: [
			{ index: 4, name: "cmd" },
			{ index: 5, name: "shift" },
			{ index: 6, name: "F" }
		]
	},
	{
		label: "Search Files",
		windowsKeys: [
			{ index: 1, name: "ctrl" },
			{ index: 2, name: "alt" },
			{ index: 3, name: "shift" },
			{ index: 4, name: "F" }
		],
		macKeys: [
			{ index: 1, name: "cmd" },
			{ index: 2, name: "alt" },
			{ index: 3, name: "shift" },
			{ index: 4, name: "F" }
		]
	},
	{
		label: "Open/close Sticky Note",
		windowsKeys: [
			{ index: 5, name: "ctrl" },
			{ index: 6, name: "shift" },
			{ index: 7, name: "/" }
		],
		macKeys: [
			{ index: 5, name: "cmd" },
			{ index: 6, name: "shift" },
			{ index: 7, name: "/" }
		]
	},
	{
		label: "Add Note",
		windowsKeys: [
			{ index: 7, name: "ctrl" },
			{ index: 8, name: "B" }
		],
		macKeys: [
			{ index: 7, name: "cmd" },
			{ index: 8, name: "B" }
		]
	},
	{
		label: "Add Bookmark",
		windowsKeys: [
			{ index: 9, name: "ctrl" },
			{ index: 10, name: "/" }
		],
		macKeys: [
			{ index: 9, name: "cmd" },
			{ index: 10, name: "/" }
		]
	},
	{
		label: "Add Finding",
		windowsKeys: [
			{ index: 11, name: "ctrl" },
			{ index: 12, name: "shift" },
			{ index: 13, name: "B" }
		],
		macKeys: [
			{ index: 11, name: "cmd" },
			{ index: 12, name: "shift" },
			{ index: 13, name: "B" }
		]
	},
	{
		label: "Previous Tab",
		windowsKeys: [
			{ index: 23, name: "shift" },
			{ index: 24, name: "alt" },
			{ index: 25, name: "←" }
		],
		macKeys: [
			{ index: 23, name: "shift" },
			{ index: 24, name: "alt" },
			{ index: 25, name: "←" }
		]
	},
	{
		label: "Next Tab",
		windowsKeys: [
			{ index: 26, name: "shift" },
			{ index: 27, name: "alt" },
			{ index: 28, name: "→" }
		],
		macKeys: [
			{ index: 26, name: "shift" },
			{ index: 27, name: "alt" },
			{ index: 28, name: "→" }
		]
	},
	{
		label: "Close Tab",
		windowsKeys: [
			{ index: 19, name: "alt" },
			{ index: 20, name: "W" }
		],
		macKeys: [
			{ index: 19, name: "alt" },
			{ index: 20, name: "W" }
		]
	},
	{
		label: "Close All Tabs",
		windowsKeys: [
			{ index: 21, name: "alt" },
			{ index: 21, name: "shift" },
			{ index: 22, name: "W" }
		],
		macKeys: [
			{ index: 21, name: "alt" },
			{ index: 21, name: "shift" },
			{ index: 22, name: "W" }
		]
	},
	{
		label: "Navigate Cursor History",
		windowsKeys: [
			{ index: 0, name: "alt" },
			{ index: 0, name: "<", or: true },
			{ index: 0, name: ">" }
		],
		macKeys: [
			{ index: 0, name: "alt" },
			{ index: 0, name: "<", or: true },
			{ index: 0, name: ">" }
		]
	}
];

export interface Props {
	open: boolean;
	onClose: () => void;
}

const HotkeysPopup = (props: Props) => {
	const { onClose, open } = props;

	const { width, height } = useWindowSize();

	const theme = useTheme();

	const [username] = useAtom(usernameAtom);

	const [codeCombo, setCodeCombo] = useState([]);
	const [wrongCode, setWrongCode] = useState(false);
	const [correctCode, setCorrectCode] = useState(false);

	const handleClose = () => {
		onClose();
	};

	const operatingSystem = getOperatingSystem();

	const handleButtonClick = (i: number) => {
		if (codeCombo.length > 2) {
			setWrongCode(false);
			setCorrectCode(false);
			setCodeCombo([i]);
		} else {
			setCodeCombo((old) => [...old, i]);
		}
	};

	useEffect(() => {
		if (codeCombo.length === 3) {
			if (codeCombo.join(", ") === HIDDEN_CODE) {
				setWrongCode(false);
				setCorrectCode(true);
			} else {
				setWrongCode(true);
				setCorrectCode(false);
			}
		}
	}, [codeCombo]);

	useEffect(() => {
		if (!open) {
			setWrongCode(false);
			setCorrectCode(false);
			setCodeCombo([]);
		}
	}, [open]);

	return (
		<>
			<Dialog
				onClose={handleClose}
				open={open}
				fullWidth
				maxWidth="sm"
				sx={{
					"& .MuiPaper-root": {
						bgcolor: "transparent",
						backgroundImage: "none",
						boxShadow: "none"
					},
					"& .MuiBackdrop-root": {
						backdropFilter: "blur(2px)"
					},
					"& .MuiDialog-container": {
						pt: 4,
						alignItems: "flex-start"
					}
				}}
			>
				<Slide direction="down" in={open} mountOnEnter unmountOnExit>
					<Container
						sx={{
							bgcolor: "background.nav",
							position: "relative",
							borderRadius: "12px",
							pt: 2.5,
							pb: 2
						}}
					>
						<Stack
							direction={{ xs: "column", sm: "row" }}
							justifyContent="space-between"
							alignItems="center"
						>
							<Box>
								<Typography variant="h6">Keyboard Shortcuts</Typography>
								<Typography variant="body2" color="text.secondary">
									(Use these with the IDE)
								</Typography>
							</Box>

							{/* EASTER EGG - DISABLED */}
							{/* <Stack
								sx={{ opacity: codeCombo.length > 0 ? 1 : 0.1 }}
								spacing={0.5}
								alignItems="flex-end"
							>
								<Typography variant="body2">Crack the code! 🎁</Typography>

								<Stack direction="row" spacing={1}>
									{Array(3)
										.fill(3)
										.map((_, i) => (
											<Stack
												alignItems="center"
												justifyContent="center"
												key={i}
												sx={{
													height: 30,
													width: 32,
													borderRadius: "6px",
													border: wrongCode
														? "error.main"
														: correctCode
														? "success.main"
														: `1px solid ${theme.palette.text.primary}`,
													bgcolor: wrongCode
														? `${theme.palette.error.main}${hex["80%"]}`
														: correctCode
														? `${theme.palette.success.main}${hex["80%"]}`
														: "transparent"
												}}
											>
												<Typography variant="h6">
													{codeCombo[i] || ""}
												</Typography>
											</Stack>
										))}
								</Stack>

								{!username && (
									<Typography variant="body2">Login to play</Typography>
								)}
							</Stack> */}
						</Stack>

						{correctCode && username && (
							<>
								<Divider sx={{ my: 2 }} />

								<Typography
									variant="body2"
									sx={{
										textAlign: "center",
										whiteSpace: "pre-line"
									}}
								>
									{`YOU GOT IT!!! Screenshot this page and send it to us!\n${username}`}
								</Typography>
							</>
						)}

						<Divider sx={{ my: 2 }} />

						<List>
							{SHORTCUTS.map((item, i) => (
								<React.Fragment key={i}>
									<ListItem sx={{ py: 0 }}>
										<Stack spacing={2}>
											{operatingSystem === "Windows" && (
												<Stack
													direction="row"
													alignItems="center"
													key={i}
													spacing={1}
												>
													{item.windowsKeys.map((_key, j) => (
														<React.Fragment key={`${i}${j}`}>
															<KeyboardButton
																label={_key.name}
																index={_key.index}
																// handleClick={(_i) =>
																// 	!correctCode &&
																// 	!!username &&
																// 	handleButtonClick(_i)
																// }
															/>

															{j < item.windowsKeys.length - 1 && (
																<Typography sx={{ lineHeight: 1 }}>
																	{_key.or ? "or" : "+"}
																</Typography>
															)}
														</React.Fragment>
													))}
												</Stack>
											)}

											{operatingSystem !== "Windows" && (
												<Stack
													direction="row"
													alignItems="center"
													key={i}
													spacing={1}
												>
													{item.macKeys.map((_key, j) => (
														<React.Fragment key={`${i}${j}`}>
															<KeyboardButton
																label={_key.name}
																index={_key.index}
																// handleClick={(_i) =>
																// 	!correctCode &&
																// 	!!username &&
																// 	handleButtonClick(_i)
																// }
															/>

															{j < item.macKeys.length - 1 && (
																<Typography sx={{ lineHeight: 1 }}>
																	{_key.or ? "or" : "+"}
																</Typography>
															)}
														</React.Fragment>
													))}
												</Stack>
											)}
										</Stack>

										<ListItemSecondaryAction>
											<Typography color="text.secondary" variant="body2">
												{item.label}
											</Typography>
										</ListItemSecondaryAction>
									</ListItem>

									{i < SHORTCUTS.length - 1 && <Divider sx={{ my: 1.5 }} />}
								</React.Fragment>
							))}
						</List>
					</Container>
				</Slide>

				{open && correctCode && (
					<Confetti width={width} height={1000} style={{ zIndex: 999999 }} />
				)}
			</Dialog>
		</>
	);
};

export default HotkeysPopup;
