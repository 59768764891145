import { Icon } from "@iconify/react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
	CssBaseline,
	IconButton,
	Stack,
	ThemeProvider,
	Typography
} from "@mui/material";
import { yellow } from "@mui/material/colors";
import { SEVERITY_OPTIONS } from "components/Toolbox/Tools/AddFindingsTool/helpers";
import darkTheme from "config/theme/dark";
import lightTheme from "config/theme/light";
import hex from "utils/hexTransparency";

const FindingViewZone = ({
	name,
	description,
	recommendation,
	severity,
	handleViewFinding,
	handleEditFinding,
	handleDeleteFinding,
	themeMode
}) => {
	const tagColor =
		SEVERITY_OPTIONS.find((i) => i.value === severity).color || yellow["500"];

	// @audit-
	return (
		<ThemeProvider theme={themeMode === "dark" ? darkTheme : lightTheme}>
			<CssBaseline />

			<Stack
				spacing={1}
				direction="row"
				alignItems="center"
				sx={(theme) => ({
					zIndex: 999,
					width: "fit-content",
					"&:hover": {
						"& .actionButtons": {
							opacity: 1
						}
					}
				})}
			>
				{/* Content */}
				<Stack
					spacing={0.5}
					direction="row"
					alignItems="center"
					sx={(theme) => ({
						border: `1px solid ${tagColor}`,
						width: "fit-content",
						borderRadius: "8px",
						px: 1.5,
						py: 0.5,
						bgcolor: `${tagColor}${hex["10%"]}`
					})}
				>
					<Icon icon="ph:detective-fill" fontSize={20} color={tagColor} />

					<Typography>{name}</Typography>
				</Stack>

				{/* Actions */}
				<Stack
					direction="row"
					alignItems="center"
					className="actionButtons"
					sx={{ opacity: themeMode === "dark" ? 0.1 : 0.3 }}
				>
					{(!!description || !!recommendation) && (
						<IconButton
							size="small"
							onClick={handleViewFinding}
							sx={{
								height: 20,
								width: 22,
								opacity: themeMode === "dark" ? 0.6 : 0.8,
								"&:hover": { opacity: 1 }
							}}
						>
							<VisibilityIcon sx={{ fontSize: 16 }} />
						</IconButton>
					)}

					<IconButton
						size="small"
						onClick={handleEditFinding}
						sx={{
							height: 20,
							width: 22,
							opacity: themeMode === "dark" ? 0.6 : 0.8,
							"&:hover": { opacity: 1 }
						}}
					>
						<EditIcon sx={{ fontSize: 16 }} />
					</IconButton>

					<IconButton
						size="small"
						onClick={handleDeleteFinding}
						sx={{
							height: 20,
							width: 22,
							opacity: themeMode === "dark" ? 0.6 : 0.8,
							"&:hover": { opacity: 1 }
						}}
					>
						<DeleteIcon sx={{ fontSize: 16 }} />
					</IconButton>
				</Stack>
			</Stack>
		</ThemeProvider>
	);
};

export default FindingViewZone;
