import * as siwe from "siwe";
import { ethers } from "ethers";
import { authenticate } from "./ServerInterface";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

const COOKIE_NAME = "aw-auth";

export async function auth(mixpanel, referral?: string) {
	try {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const signer = provider.getSigner();

		// Check for existing auth session cookie
		if (!isUserLoggedIn()) {
			// Method from Metamask docs
			// await window.ethereum
			// 	.request({ method: "eth_requestAccounts" })
			// 	.then((res) => {
			// 		// Return the address of the wallet
			// 		console.log(res);
			// 	});

			// Connect the user's web3 wallet
			await provider.send("eth_requestAccounts", []);

			// Set expiration time
			const expirationTime = new Date();
			expirationTime.setDate(new Date().getDate() + 180);

			const address = await signer.getAddress();

			// Craft the login attestation to sign
			const message = createSiweMessage(
				await address,
				"Sign in to Audit Wizard",
				expirationTime.toISOString()
			);

			// Sign the message
			const signature = await signer.signMessage(message);

			// Send the message and signature in an auth request
			await authenticate(message, signature, mixpanel, referral, address);
		}
	} catch (e) {
		console.log(e);
		alert("Error logging in. Do you have a web3 wallet extension installed?");
	}
}

function createSiweMessage(address, statement, expirationTime) {
	const siweMessage = new siwe.SiweMessage({
		domain: window.location.host,
		address,
		statement,
		uri: window.location.origin,
		version: "1",
		chainId: 1,
		nonce: siwe.generateNonce(),
		expirationTime
	});
	return siweMessage.prepareMessage();
}

export function getDecodedJwt() {
	try {
		const cookie = Cookies.get(COOKIE_NAME);
		if (!cookie) return null;
		return jwtDecode(cookie);
	} catch (error) {
		console.log(error);
	}
}

export function getUsername() {
	const jwt = getDecodedJwt();

	return jwt ? jwt["userID"] : null;
}

export function isUserLoggedIn() {
	const jwt = getDecodedJwt();

	// User is logged in if the JWT is set and has not expired
	return jwt && new Date(jwt["expirationTime"]) > new Date();
}

export function logout() {
	Cookies.remove(COOKIE_NAME);
}
