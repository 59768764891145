import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FieldValues, Resolver, useForm } from "react-hook-form";
import * as Yup from "yup";
import FormTextField from "../../formFields/FormTextField";

export interface SignupFormType {
	username: string;
	email: string;
	password: string;
	confirm_password: string;
}

interface Props {
	handleSubmit: (data: SignupFormType) => void;
	disabled?: boolean;
}

const schema = Yup.object().shape({
	username: Yup.string().required("Username is required"),
	email: Yup.string().email("Invalid email").required("Email is required"),
	password: Yup.string()
		.required("Password is required")
		.min(8, "Password must be at least 8 characters long"),
	confirm_password: Yup.string().oneOf(
		[Yup.ref("password"), null],
		"Passwords must match"
	)
});

const SignUpForm: React.FC<Props> = ({
	handleSubmit: propsHandleSubmit,
	disabled: PropsDisabled = false
}) => {
	const [disabled, setDisabled] = useState(PropsDisabled);
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		setDisabled(PropsDisabled);
	}, [PropsDisabled]);

	const formOptions = {
		resolver: yupResolver(schema) as Resolver<SignupFormType, FieldValues>
	};

	const {
		handleSubmit,
		register,
		formState: { errors }
	} = useForm<FieldValues & SignupFormType & any>(formOptions);

	const onSubmit = (data: SignupFormType) => {
		setDisabled(true);
		propsHandleSubmit?.(data);
	};

	const togglePassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={2}>
				<FormTextField
					fullWidth
					disabled={disabled}
					required
					name="username"
					label="Username"
					register={register}
					error={!!errors["username"]}
					helperText={errors["username"]?.message}
					inputSx={{ bgcolor: "background.paper" }}
					type="text"
				/>

				<FormTextField
					fullWidth
					disabled={disabled}
					required
					name="email"
					label="Email"
					register={register}
					error={!!errors["email"]}
					helperText={errors["email"]?.message}
					inputSx={{ bgcolor: "background.paper" }}
				/>

				<FormTextField
					fullWidth
					required
					disabled={disabled}
					name="password"
					label="Password"
					register={register}
					type={showPassword ? "text" : "password"}
					error={!!errors["password"]}
					helperText={errors["password"]?.message}
					inputSx={{ bgcolor: "background.paper" }}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={togglePassword} size="small">
									{showPassword ? (
										<Visibility fontSize="small" />
									) : (
										<VisibilityOff fontSize="small" />
									)}
								</IconButton>
							</InputAdornment>
						)
					}}
				/>

				<FormTextField
					fullWidth
					required
					disabled={disabled}
					name="confirm_password"
					label="Confirm Password"
					register={register}
					type={showPassword ? "text" : "password"}
					error={!!errors["confirm_password"]}
					helperText={errors["confirm_password"]?.message}
					inputSx={{ bgcolor: "background.paper" }}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={togglePassword} size="small">
									{showPassword ? (
										<Visibility fontSize="small" />
									) : (
										<VisibilityOff fontSize="small" />
									)}
								</IconButton>
							</InputAdornment>
						)
					}}
				/>

				<Button
					fullWidth
					disabled={disabled}
					variant="contained"
					type="submit"
					sx={{ height: 40, borderRadius: "8px" }}
				>
					Sign up
				</Button>
			</Stack>
		</form>
	);
};

export default SignUpForm;
