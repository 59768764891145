import { TypographyVariantsOptions } from "@mui/material";

const themeTypography: TypographyVariantsOptions = {
	fontFamily: ["Montserrat"].join(","),
	caption: {
		fontSize: "10px",
		fontWeight: 300
	},
	body2: {
		fontSize: "12px",
		fontWeight: 400
	},
	body1: {
		fontSize: "14px",
		fontWeight: 400
	},
	h6: {
		fontSize: "18px",
		fontWeight: 500
	},
	h5: {
		fontSize: "22px",
		fontWeight: 500
	},
	h4: {
		fontSize: "24px",
		fontWeight: 600
	},
	h3: {
		fontSize: "28px",
		fontWeight: 500
	},
	h2: {
		fontSize: "32px",
		fontWeight: 600
	},
	h1: {
		fontSize: "40px",
		fontWeight: 600
	},
	button: {
		fontSize: "14px",
		textTransform: "none"
	}
};

export default themeTypography;
