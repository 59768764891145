import {
	Box,
	CircularProgress,
	Dialog,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import { appConfig } from "config";

const { darkLogo, lightLogo, iconLogo } = appConfig;

const SplashScreen = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	return (
		<Dialog fullScreen open>
			<Stack
				spacing={3}
				alignItems="center"
				justifyContent="center"
				sx={{
					height: "100%",
					width: "100%",
					bgcolor: "#0D0C21",
					color: "white !important"
				}}
			>
				<Box
					component="img"
					src={iconLogo}
					sx={{ objectFit: "contain", width: 100 }}
				/>

				<Typography color="text.secondary" variant="h6">
					Loading...
				</Typography>

				<CircularProgress size={60} />
			</Stack>
		</Dialog>
	);
};

export default SplashScreen;
