import SearchIcon from "@mui/icons-material/Search";
import {
	Box,
	Chip,
	Container,
	Dialog,
	Divider,
	InputAdornment,
	List,
	ListItemButton,
	Slide,
	Stack,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import { NAVBAR_HEIGHT } from "components/Navbar";
import { useLocalStorage } from "hooks/ui/useLocalStorage";
import { useProject } from "hooks/data/useProject";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { searchFilesBoxOpenAtom } from "store/atoms/UiAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import { STORAGE_SEARCH } from "utils/constants";
import { flattenFiles, smallerString } from "utils/helpersFunctions";

interface FileType {
	id: string;
	name: string;
	path: string;
}

interface RecentSearchesType {
	searchTerm: string;
	type: "file" | "term";
	id?: string;
	path?: string;
}

const buildTooltipTitle = (obj: RecentSearchesType): string => {
	let text = `Search Term: ${obj.searchTerm}`;

	if (obj.type === "file") {
		text += `\nPath: ${obj.path}\nSearch Type: File`;
	}

	return text;
};

const SearchFiles = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [files, setFiles] = useState<FileType[]>([]);
	const [filteredFiles, setFilteredFiles] = useState<FileType[]>([]);

	const { openProjectFile } = useProject();

	const [project] = useAtom(projectAtom);
	const [searchFilesBoxOpen, setSearchFilesBoxOpen] = useAtom(
		searchFilesBoxOpenAtom
	);

	const [recentSearches, setRecentSearches] = useLocalStorage<
		RecentSearchesType[]
	>(`${STORAGE_SEARCH}${project.id || ""}`, []);

	useEffect(() => {
		const root = flattenFiles(project.rootFolder);
		const imports = flattenFiles(project.importsFolder);

		setFiles([...root, ...imports]);
	}, [project]);

	useEffect(() => {
		const _filtered = files.filter(
			(i) =>
				i.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
				i.path.toLowerCase().includes(searchTerm.toLowerCase())
		);

		setFilteredFiles(_filtered);
	}, [files, searchTerm]);

	const handleClose = () => {
		setSearchFilesBoxOpen(false);
	};

	const handleFileClick = (file: FileType) => {
		const _path = `${file.path}/${file.name}`.slice(1);

		// Add to search only if it doesnt exist there
		if (
			!recentSearches.find(
				(i) => i.path === file.path && i.searchTerm === file.name
			)
		) {
			setRecentSearches([
				{
					searchTerm: file.name,
					id: file.id,
					path: file.path,
					type: "file"
				},
				...recentSearches
			]);
		}

		openProjectFile(_path);
		setSearchFilesBoxOpen(false);
	};

	const handleRecentSearchClick = (data: RecentSearchesType) => {
		if (data.type === "file") {
			handleFileClick({ id: data.id, name: data.searchTerm, path: data.path });
		}
	};

	return (
		<Dialog
			open={searchFilesBoxOpen}
			onClose={handleClose}
			fullWidth
			maxWidth="md"
			sx={{
				"& .MuiPaper-root": {
					bgcolor: "transparent",
					backgroundImage: "none",
					boxShadow: "none"
					// mt: `${NAVBAR_HEIGHT}px`
				},
				"& .MuiBackdrop-root": {
					backdropFilter: "blur(2px)"
				},
				"& .MuiDialog-container": {
					pt: 4,
					alignItems: "flex-start"
				}
			}}
		>
			<Slide
				direction="down"
				in={searchFilesBoxOpen}
				mountOnEnter
				unmountOnExit
			>
				<Container
					sx={{
						bgcolor: "background.nav",
						position: "relative",
						borderRadius: "12px",
						pt: 2.5,
						pb: 2
					}}
				>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="center"
						sx={{ height: "100%" }}
						spacing={2}
					>
						<TextField
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							fullWidth
							placeholder="Search files"
							variant="standard"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								disableUnderline: true
							}}
						/>

						<Box
							sx={{
								py: 0.3,
								px: 0.8,
								border: "1px solid gray",
								borderRadius: "4px",
								cursor: "pointer",
								"&:hover": {
									bgcolor: "background.default"
								}
							}}
							onClick={handleClose}
						>
							<Typography variant="caption" color="text.secondary">
								ESC
							</Typography>
						</Box>
					</Stack>

					<Divider sx={{ my: 2 }} />

					<Box
						sx={{
							maxHeight: `calc(100vh - ${NAVBAR_HEIGHT * 4}px)`,
							overflow: "auto"
						}}
					>
						{!!recentSearches.length && (
							<Box sx={{ mb: 2.5 }}>
								<Typography color="text.secondary" sx={{ mb: 1.5 }}>
									Recent Searches
								</Typography>

								<Stack flexWrap="wrap" direction="row" columnGap={1} rowGap={1}>
									{recentSearches.map((item, i) => (
										<Tooltip
											key={i}
											title={
												<Typography
													variant="caption"
													sx={{ whiteSpace: "pre-line" }}
												>
													{buildTooltipTitle(item)}
												</Typography>
											}
											componentsProps={{
												tooltip: {
													sx: {
														bgcolor: "background.default"
													}
												}
											}}
										>
											<Chip
												label={item.searchTerm}
												clickable
												variant="outlined"
												onClick={() => handleRecentSearchClick(item)}
											/>
										</Tooltip>
									))}
								</Stack>
							</Box>
						)}

						<Box>
							<Typography color="text.secondary">Files</Typography>
							<List>
								{filteredFiles.map((item, i) => (
									<ListItemButton
										key={i}
										onClick={() => handleFileClick(item)}
										sx={{ flexWrap: "wrap" }}
									>
										<Stack
											direction="row"
											spacing={2}
											alignItems="center"
											justifyContent="space-between"
											flexWrap="nowrap"
											sx={{ width: "100%" }}
										>
											<Typography>{item.name}</Typography>

											<Typography
												variant="body2"
												color="text.disabled"
												sx={{ textAlign: "right" }}
											>
												{smallerString(item.path, 30, -30, 7)}
											</Typography>
										</Stack>
									</ListItemButton>
								))}
							</List>
						</Box>
					</Box>
				</Container>
			</Slide>
		</Dialog>
	);
};

export default SearchFiles;
