import { Icon } from "@iconify/react";
import {
	Avatar,
	Box,
	Button,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import FindTheBugSubmittedModal from "components/CustomModal/FindTheBugSubmittedModal";
import MarkdownReader from "components/MainContentLayout/MarkdownReader";
import { ToolboxCloseButton } from "components/Toolbox";
import BugChallengeForm from "components/forms/BugChallengeForm";
import { useBugChallenge } from "hooks/data/useBugChallenge";
import { useProject } from "hooks/data/useProject";
import { useTools } from "hooks/ui/useTools";
import { useAtom } from "jotai";
import React, { useState } from "react";
import {
	bugChallengeSubmittedAtom,
	challengesAtom,
	focusedChallengeAtom,
} from "store/atoms/BugChallengeAtoms";
import WizardPfpDark from "assets/images/wizardPfpDark.png";
import WizardPfpLight from "assets/images/wizardPfpLight.png";

const Host: React.FC<{
	image: string;
	name: string;
	subtitle: string;
	link: string;
}> = ({ image, name, subtitle, link }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	return (
		<Stack
			direction="row"
			spacing={1.3}
			alignItems="center"
			onClick={() => window.open(link, "_blank")}
			sx={{
				p: 1,
				borderRadius: "8px",
				bgcolor: "background.paper",
				cursor: "pointer",
				border: "1px solid",
				borderColor: "background.default",
				"&:hover": { borderColor: "primary.dark" },
				...(themeMode === "light" && {
					bgcolor: "background.default",
					boxShadow: 2
				})
			}}
		>
			<Avatar src={image} sx={{ height: 40, width: 40 }} />

			<Stack spacing={0.2}>
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<Typography sx={{ fontWeight: 600 }}>{name}</Typography>

					<Stack>
						<Icon icon="ri:verified-badge-fill" fontSize={18} color="#E5B129" />
					</Stack>
				</Stack>

				<Typography variant="body2">{subtitle}</Typography>
			</Stack>
		</Stack>
	);
};

const BugChallengeTool = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [modalOpen, setModalOpen] = useState(false);

	const { selectTool } = useTools();

	const [submitted] = useAtom(bugChallengeSubmittedAtom);
	const [challenges] = useAtom(challengesAtom);
	const [focusedChallenge] = useAtom(focusedChallengeAtom);

	const { submitAnswer } = useBugChallenge();
	const { loadUserChallengeSubmissions } = useBugChallenge();

	const { closeProject } = useProject();

	const handleSubmit = async (data) => {
		const success = await submitAnswer(data);

		if (success) {
			await loadUserChallengeSubmissions();

			setModalOpen(true);
		}
	};

	const Header: React.FC<{ title?: string }> = ({ title }) => (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{ mb: 3 }}
		>
			<Typography variant="h6">
				{title ? title : submitted ? "Already Submitted" : "Submit your answer"}
			</Typography>

			<ToolboxCloseButton />
		</Stack>
	);

	if (focusedChallenge.solutionWriteup) {
		return (
			<Box>
				<Header title="Challenge Solution" />

				<Box
					sx={{
						mt: -2,
						p: 2,
						borderRadius: "8px",
						bgcolor: "background.default",
						...(themeMode === "light" && {
							bgcolor: "background.paper",
							boxShadow: 1
						})
					}}
				>
					{/* TODO: REMOVE HARDCODED USER */}
					<Host
						image={themeMode === "dark"
						? WizardPfpDark
						: WizardPfpLight}
						name="forefy"
						subtitle="Guest host"
						link="https://twitter.com/forefy"
					/>

					<MarkdownReader
						value={focusedChallenge.solutionWriteup ?? ""}
						sx={{ p: 0, border: "unset", mx: -2 }}
					/>
				</Box>

				<Button
					variant="contained"
					fullWidth
					color="primary"
					sx={{
						height: "40px",
						mt: 2,
						borderRadius: "8px"
					}}
					onClick={() => {
						selectTool("Tests");
					}}
				>
					Verify Solution with PoC Test
				</Button>
			</Box>
		);
	}

	return (
		<Box>
			<Header />

			{submitted ? (
				<>
					<Typography sx={{ mb: 2 }}>
						Thank you for your submission! This will be reviewed upon closure of
						the challenge. If your submission is valid, you will be notified and
						will earn this first-of-its kind badge on your auditor profile!
						There will be many opportunities to earn more badges in the future.
					</Typography>

					<Button
						variant="contained"
						fullWidth
						sx={{ borderRadius: "8px" }}
						onClick={() => {
							closeProject();
						}}
					>
						Return to home
					</Button>
				</>
			) : (
				<>
					<BugChallengeForm
						handleSubmit={handleSubmit}
						values={{}}
						disabled={submitted}
					/>
				</>
			)}

			<FindTheBugSubmittedModal
				open={modalOpen}
				handleClose={() => {
					closeProject();
					setModalOpen(false);
				}}
			/>
		</Box>
	);
};

export default BugChallengeTool;
