import { Box, useTheme } from "@mui/material";
import React from "react";
import { TerminalTabContentType } from "store/atoms/terminalAtoms";
import StatusBadge from "./StatusBadge";
import AssetChanges from "./AssetChanges";
import CallTrace from "./CallTrace";
import StateChanges from "./StateChanges";
import FadingBackground from "components/Toolbox/Tools/SimulationTool/FadingBackground";

const SimulationContent: React.FC<TerminalTabContentType> = ({ content }) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const {
		formattedStateDiff,
		formattedAssetChanges,
		status,
		stackTrace,
		callTrace
	} = content || {};

	const isSuccess = !!status;

	return (
		<Box sx={{ p: 1.5, position: "relative" }}>
			<FadingBackground
				status={isSuccess ? "success" : "error"}
				sx={{ width: 400, left: -200, opacity: 0.6 }}
			/>

			{/* Status */}
			<StatusBadge isSuccess={isSuccess} />

			{/* Asset Changes */}
			{!!formattedAssetChanges && (
				<AssetChanges assetChanges={formattedAssetChanges} />
			)}

			{/* Stack Trace */}
			{!!stackTrace && <CallTrace trace={stackTrace} isStackTrace />}

			{/* Call Trace */}
			{!!callTrace && <CallTrace trace={callTrace} />}

			{/* State Changes */}
			{!!formattedStateDiff && (
				<StateChanges stateChanges={formattedStateDiff} />
			)}
		</Box>
	);
};

export default React.memo(SimulationContent);
