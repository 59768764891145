import { useEffect } from "react";

type EventHandler = (event: MouseEvent) => void;

const useMiddleClick = (
	ref: React.RefObject<HTMLElement>,
	callback: EventHandler
) => {
	useEffect(() => {
		const handleMouseDown = (event: MouseEvent) => {
			if (
				event.button === 1 &&
				ref.current &&
				ref.current.contains(event.target as Node)
			) {
				callback(event);
			}
		};

		document.addEventListener("mousedown", handleMouseDown);

		return () => {
			document.removeEventListener("mousedown", handleMouseDown);
		};
	}, [callback, ref]);
};

export default useMiddleClick;
