import { Box, Stack, useTheme } from "@mui/material";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { editorTabsAtom, focusedEditorTabIndexAtom } from "store/atoms/UiAtoms";
import ActionButtons from "./ActionButtons";
import TabsList from "./TabsList";

export interface TabType {
	id: string;
	name: string;
	path: string;
}

const FileTabs = () => {
	const theme = useTheme();

	const setOpenTabs = useSetAtom(editorTabsAtom);

	const [focusedTabIndex, setTabIndex] = useAtom(focusedEditorTabIndexAtom);

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		setOpenTabs((old) => {
			const reorderedTabs = Array.from(old);
			const [removed] = reorderedTabs.splice(result.source.index, 1);
			reorderedTabs.splice(result.destination.index, 0, removed);

			if (result.source.index === focusedTabIndex) {
				setTabIndex(result.destination.index);
			}

			if (result.destination.index === focusedTabIndex) {
				setTabIndex(result.source.index);
			}

			return reorderedTabs;
		});
	};

	return (
		<Stack direction="row" alignItems="center" sx={{ minHeight: 40 }}>
			{/* Home button */}
			{/* <HomeButton /> */}

			{/* Tabs */}
			<DragDropContext onDragEnd={onDragEnd}>
				<TabsList />
			</DragDropContext>

			<Box sx={{ flex: 1 }} />

			{/* Action Buttons */}
			<ActionButtons />
		</Stack>
	);
};

export default React.memo(FileTabs);
