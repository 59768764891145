import { useTheme } from "@mui/material";
import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { editorClickListenerAtom } from "store/atoms/EditorAtoms";
import { filteredSlitherResultsAtom, filteredToolResultsAtom } from "store/atoms/ScanAtoms";
import { selectedToolAtom } from "store/atoms/UiAtoms";
import {
	parseScanResults,
	removeScanGlyphsAndListeners,
	scanClickOpenWidget
} from "./helpers";

const ScanAddon: React.FC<{ editorRef: any; monacoRef: any }> = ({
	editorRef,
	monacoRef
}) => {
	const theme = useTheme();
	const [parsedScanResults, setParsedScanResults] = useState([]);

	const [selectedTool] = useAtom(selectedToolAtom);
	const [slitherResults] = useAtom(filteredSlitherResultsAtom);
	const [toolResults] = useAtom(filteredToolResultsAtom);
	const [clickEvent] = useAtom(editorClickListenerAtom);

	const widgetRef = useRef(null);

	// Load scan results into Editor
	useEffect(() => {
		removeScanGlyphsAndListeners(editorRef, widgetRef);

		if (slitherResults && selectedTool === "CodeScan") {
			const parsedScan = parseScanResults(slitherResults, theme);

			setParsedScanResults(parsedScan);
			editorRef.createDecorationsCollection?.(parsedScan);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editorRef, slitherResults, selectedTool]);

	// Generalized version of above - load scan results into editor
	useEffect(() => {
		removeScanGlyphsAndListeners(editorRef, widgetRef);

		if (toolResults && selectedTool === "CodeScan") {
			const parsedScan = parseScanResults(toolResults, theme);

			setParsedScanResults(parsedScan);
			editorRef.createDecorationsCollection?.(parsedScan);
		}

	}, [editorRef, toolResults, selectedTool]);

	// Scan glyph widget click
	useEffect(() => {
		if (
			!!parsedScanResults.length &&
			editorRef &&
			selectedTool === "CodeScan"
		) {
			removeScanGlyphsAndListeners(editorRef, widgetRef);

			if (!!clickEvent) {
				if (clickEvent.clickType === 2 && clickEvent.column === 1) {
					scanClickOpenWidget(
						editorRef,
						monacoRef,
						clickEvent,
						widgetRef,
						parsedScanResults
					);
				}
			}

			editorRef?.createDecorationsCollection?.(parsedScanResults);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parsedScanResults, editorRef, clickEvent, selectedTool]);

	return null;
};

export default React.memo(ScanAddon);
