import { usernameAtom } from "atoms";
import { useMediaQuery } from "hooks/ui/useMediaQuery";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { projectAtom } from "store/atoms/projectToolAtoms";
import DraggableSidebar from "../DraggableSidebar";
import ProjectTree from "./ProjectTree";

export const PROJECT_SIDEBAR_WIDTH = 280;

const ProjectSidebar = () => {
	const [username] = useAtom(usernameAtom);
	const isLargeScreen = useMediaQuery("(min-width:1200px)");

	const [sidebarWidth, setSidebarWidth] = useState(PROJECT_SIDEBAR_WIDTH);

	const [project] = useAtom(projectAtom);

	useEffect(() => {
		setSidebarWidth(PROJECT_SIDEBAR_WIDTH);
	}, [isLargeScreen]);

	return (
		<DraggableSidebar
			defaultWidth={PROJECT_SIDEBAR_WIDTH}
			width={sidebarWidth}
			setWidth={setSidebarWidth}
			disableOverflowScroll
		>
			{!!username && project && <ProjectTree />}
		</DraggableSidebar>
	);
};

export default ProjectSidebar;
