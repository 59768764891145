import {
	Box,
	Button,
	Checkbox,
	Container,
	Grid,
	Stack,
	Typography
} from "@mui/material";
import CTFList from "./CTFList";
import BeginnerWizardIcon from "assets/icons/beginner-wizard.svg";
import IntermediateWizardIcon from "assets/icons/intermediate-wizard.svg";
import ExpertWizardIcon from "assets/icons/expert-wizard.svg";
import { useState } from "react";
import hex from "utils/hexTransparency";
import { useCTFContext } from "context/CTFContext";

export const difficulty_icon = {
	Beginner: BeginnerWizardIcon,
	Intermediate: IntermediateWizardIcon,
	Expert: ExpertWizardIcon
};

const ImportCTF = () => {
	const { ctfs, openCtf } = useCTFContext();
	const [selectedCtfIndex, setSelectedCtfIndex] = useState(0);
	const selectedCtf = ctfs[selectedCtfIndex] || {};

	return (
		<Stack sx={{ p: 2, height: "100%" }}>
			<CTFList
				selectedCtfIndex={selectedCtfIndex}
				setSelectedCtfIndex={setSelectedCtfIndex}
			/>

			<Box
				sx={{
					bgcolor: "background.nav",
					borderRadius: "12px",
					width: "100%",
					height: "100%",
					background: `url(${selectedCtf.image})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					minHeight: 0
				}}
			>
				<Box
					sx={{
						p: 2,
						height: "100%",
						backgroundColor: `#000000${hex["80%"]}`,
						borderRadius: "inherit"
					}}
				>
					<Container
						maxWidth="sm"
						sx={{
							height: "100%",
							position: "relative"
						}}
					>
						<Stack
							alignItems="center"
							sx={{ textAlign: "center", height: "100%" }}
						>
							{/* Title */}
							<Typography variant="h2" sx={{ mb: 1.3 }}>
								{selectedCtf.title}
							</Typography>

							{/* Metadata */}
							<Stack
								direction={{ xs: "column", md: "row" }}
								spacing={3}
								sx={{ mb: 2 }}
							>
								<Typography sx={{ fontWeight: 500, color: "success.main" }}>
									{selectedCtf.challenges.length} Challenges
								</Typography>

								<Stack direction="row" alignItems="center" spacing={0.5}>
									<Box
										component="img"
										src={difficulty_icon[selectedCtf.difficulty]}
									/>
									<Typography>{selectedCtf.difficulty}</Typography>
								</Stack>

								<Typography>
									Made by{" "}
									<Typography
										component="span"
										color="primary.light"
										sx={{ fontWeight: 500 }}
									>
										{selectedCtf.author}
									</Typography>
								</Typography>
							</Stack>

							{/* Description */}
							<Typography sx={{ mb: 2, maxWidth: "550px!important" }}>
								{selectedCtf.description}
							</Typography>

							{/* Challenges */}
							<Box sx={{ mb: 4, pl: 1, flex: 1, overflow: "scroll" }}>
								<Grid container spacing={1}>
									{selectedCtf.challenges.map((challenge, i) => (
										<Grid item xs={12} md={4} key={i}>
											<Stack direction="row" alignItems="center">
												<Checkbox size="small" />

												<Typography>
													{i + 1}. {challenge.title}
												</Typography>
											</Stack>
										</Grid>
									))}
								</Grid>
							</Box>

							{/* Get Started */}
							<Button
								fullWidth
								variant="contained"
								sx={{ borderRadius: 2, position: "absolute", bottom: 0 }}
								onClick={() => openCtf(selectedCtf._id)}
							>
								Get started
							</Button>
						</Stack>
					</Container>
				</Box>
			</Box>
		</Stack>
	);
};

export default ImportCTF;
