import { addErrorNotification } from "./Notifications";
import ProjectFile from "./ProjectFile";
import { getCsrfToken } from "./ServerInterface";

const SERVER_URL =
	window.location.protocol +
	"//" +
	window.location.hostname +
	":" +
	(process.env.REACT_APP_SERVER_PORT || "5000");

export interface Contest {
	title: string;
	description: string;
	difficulty: string;
	author: string;
	image: string;
	startTime?: number;
	endTime?: number;
	active: boolean;
	challengeIds?: string[];
	_id?: string;
}

export interface Challenge {
	challengeContractFiles: string[];
	fullScript: string;
	userEditableStart: number;
	title: string;
	description: string;
	difficulty: string;
	_id?: string;
}

interface ContestParticipation {
	challengeSubmissions: Map<string, string>;
	challengeCompletion: Map<string, boolean>;
	userId: string;
	contestId: string;
}

export async function getContests(): Promise<Contest[]> {
	try {
		const url =
			SERVER_URL +
			"/contests?" +
			new URLSearchParams({
				csrfToken: await getCsrfToken()
			});

		const res = await fetch(url, { credentials: "include" });

		if (res.ok) {
			const contests = await res.json();
			return contests;
		} else throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error loading contests: " + e.message);
	}
}

export async function getChallengesByIds(
	challengeIds: string[]
): Promise<Challenge[]> {
	try {
		const url =
			SERVER_URL +
			"/challenges?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify({ ids: challengeIds }),
			headers: { "Content-Type": "text/plain" }
		});

		if (res.ok) {
			const challenges = await res.json();
			return challenges;
		} else throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error loading challenges: " + e.message);
	}
}

export async function loadCtfFile(projectFileID): Promise<ProjectFile> {
	try {
		const url =
			SERVER_URL +
			"/loadCtfFile?" +
			new URLSearchParams({
				projectFileID,
				csrfToken: await getCsrfToken()
			});

		const res = await fetch(url, { credentials: "include" });

		if (res.ok) {
			const pf = await res.json();
			return new ProjectFile(pf.name, pf.contents, pf._id, pf.ast);
		} else throw new Error(res.statusText);
	} catch (e) {
		addErrorNotification("Error", "Error loading ctf file: " + e.message);
	}
}
