import { Hidden, Stack } from "@mui/material";
import React from "react";
import BugChallengeButton from "./BugChallengeButton";
import FindingsButton from "./FindingsButton";
import NotesButton from "./NotesButton";
import ReportButton from "./ReportButton";
import WhiteboardButton from "./WhiteboardButton";
import BadgeButton from "./BadgeButton";

const AnnotationsGroup = () => {
	return (
		<Stack direction="row" alignItems="center" spacing={1}>
			<NotesButton />

			<Hidden smDown>
				<WhiteboardButton />
			</Hidden>

			<Hidden smDown>
				<FindingsButton />
			</Hidden>

			<BugChallengeButton />

			<Hidden smDown>
				<ReportButton />
			</Hidden>
		</Stack>
	);
};

export default React.memo(AnnotationsGroup);
