import {
	Box,
	Button,
	ClickAwayListener,
	Divider,
	Stack,
	TextField,
	Typography
} from "@mui/material";
import { useAtom } from "jotai";
import React from "react";
import { testEnvVarsAtom } from "store/atoms/testAtoms";
import AddIcon from "@mui/icons-material/Add";

const SettingsBox: React.FC<{ onClose: any }> = ({ onClose }) => {
	const [testEnvVars, setTestEnvVars] = useAtom(testEnvVarsAtom);

	return (
		<ClickAwayListener onClickAway={onClose}>
			<Box
				sx={{
					width: "100%",
					p: 1.5,
					bgcolor: "background.paper",
					border: "1px solid",
					borderColor: "primary.main",
					borderRadius: "8px"
				}}
			>
				<Typography sx={{ fontWeight: "600" }}>
					Environment Variables:
				</Typography>

				<Stack spacing={1.2}>
					<Divider sx={{ my: 1 }} />

					{testEnvVars.map((variable, index) => {
						return (
							<Stack direction="row" alignItems="center" spacing={0.5}>
								<TextField
									value={variable.name}
									onChange={(e) => {
										setTestEnvVars((old) => {
											const newEnvVars = [...old];
											newEnvVars[index].name = e.target.value;
											return newEnvVars;
										});
									}}
									variant="outlined"
									placeholder="Variable name"
									label="Name"
									InputLabelProps={{ shrink: true }}
									InputProps={{
										sx: { height: 34, borderRadius: "8px" }
									}}
								/>

								<TextField
									value={variable.value}
									onChange={(e) => {
										setTestEnvVars((old) => {
											const newEnvVars = [...old];
											newEnvVars[index].value = e.target.value;
											return newEnvVars;
										});
									}}
									variant="outlined"
									placeholder="Variable value"
									label="Value"
									InputLabelProps={{ shrink: true }}
									InputProps={{
										sx: { height: 34, borderRadius: "8px" }
									}}
								/>
							</Stack>
						);
					})}

					<Button
						variant="contained"
						sx={{ borderRadius: "8px", boxShadow: 1, mb: 2 }}
						size="small"
						onClick={() => {
							setTestEnvVars((old) => {
								return [...old, { name: "", value: "" }];
							});
						}}
						fullWidth
					>
						<AddIcon sx={{ mr: 0.2 }} /> Add new variable
					</Button>
				</Stack>
			</Box>
		</ClickAwayListener>
	);
};

export default SettingsBox;
