import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { LoadingButton } from "@mui/lab";
import {
	Box,
	FormControlLabel,
	Radio,
	RadioGroup,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import { isFileSolidity } from "Resources/Helpers";
import { useEditorTabs } from "hooks/ui/useEditorTabs";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { openedTabAtom, selectedToolAtom } from "store/atoms/UiAtoms";
import { ToolboxCloseButton } from "../..";

const GraphTool: React.FC = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const setSelectedTool = useSetAtom(selectedToolAtom);

	const [loading, setLoading] = useState(false);
	const [mode, setMode] = useState("function");
	const { openGraphFile } = useEditorTabs();

	const [{ openedFile }] = useAtom(openedTabAtom);

	const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMode((event.target as HTMLInputElement).value);
	};

	const getGraphData = async () => {
		setLoading(true);
		openGraphFile(mode);

		setLoading(false);
		setSelectedTool(null);
	};

	const Header = () => (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			sx={{ mb: 2 }}
		>
			{openedFile?.name ? (
				<Typography sx={{ fontSize: 16 }}>
					Generate graph for{" "}
					<Typography component="span" sx={{ fontSize: 16, fontWeight: 600 }}>
						{openedFile?.name}
					</Typography>
				</Typography>
			) : (
				<Typography variant="h6">Audit Wizard x Graph</Typography>
			)}

			<ToolboxCloseButton />
		</Stack>
	);

	if (!isFileSolidity(openedFile?.name)) {
		return (
			<Box>
				<Header />
				<Typography sx={{ color: "success.light" }}>
					Please select a Solidity file.
				</Typography>
			</Box>
		);
	}

	return (
		<>
			<Header />

			<Stack spacing={2} alignItems="center">
				<Typography>
					Explore solidity abstract syntax trees to get insight on code patterns
					and how everything connects.
				</Typography>

				<RadioGroup row value={mode} onChange={handleModeChange}>
					<FormControlLabel
						value="function"
						control={<Radio />}
						label="Function Scope"
					/>
					<FormControlLabel
						value="contract"
						control={<Radio />}
						label="Contract Scope"
					/>
				</RadioGroup>

				<LoadingButton
					loading={loading}
					fullWidth
					variant={themeMode === "dark" ? "contained" : "text"}
					onClick={getGraphData}
					sx={{
						boxShadow: 1,
						borderRadius: "8px",
						bgcolor: "primary.main",
						color: "text.primary",
						...(themeMode === "light" && {
							bgcolor: "background.paper",
							"&:hover": {
								bgcolor: "background.paper2"
							}
						})
					}}
				>
					<AccountTreeIcon fontSize="small" sx={{ fontSize: 18, mr: 0.7 }} />
					Generate Graph
				</LoadingButton>
			</Stack>
		</>
	);
};

export default GraphTool;
