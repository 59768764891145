import useSetAtom from "hooks/useSetAtom";
import { selectedToolAtom, selectedToolPropsAtom } from "store/atoms/UiAtoms";

export function useTools() {
	const setSelectedTool = useSetAtom(selectedToolAtom);
	const setSelectedToolProps = useSetAtom(selectedToolPropsAtom);

	const selectTool = (tool: string, _props?: object) => {
		if (_props) {
			setSelectedToolProps(_props);
		} else {
			setSelectedToolProps({});
		}

		setSelectedTool(tool);
	};

	return { selectTool };
}
