import IconLogo from "../assets/images/iconLogo.png";
import DarkLogo from "../assets/images/fullLogoDark.png";
import LightLogo from "../assets/images/fullLogoLight.png";

export const appConfig = {
	projectName: "Auditware Wizard",
	projectUrl: "https://auditwizard.io/",
	docsUrl: "http://docs.auditwizard.io/",
	iconLogo: IconLogo,
	darkLogo: DarkLogo,
	lightLogo: LightLogo,
	socials: {
		discord: "https://discord.gg/VxnJJsvaQd",
		twitter: "https://twitter.com/audit_wizard"
	},
	surveyLink: "https://mh2y2zvcene.typeform.com/to/IiGDBZno"
};
