import { IconButton } from "@mui/material";
import React from "react";
import { SnackbarKey, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";

const SnackbarCloseButton: React.FC<{ snackbarKey: SnackbarKey }> = ({
	snackbarKey
}) => {
	const { closeSnackbar } = useSnackbar();

	return (
		<IconButton onClick={() => closeSnackbar(snackbarKey)} size="small">
			<CloseIcon fontSize="small" />
		</IconButton>
	);
};

export default SnackbarCloseButton;
