import { addErrorNotification } from "Resources/Notifications";
import secureLocalStorage from "react-secure-storage";

const githubApiBaseUrl = "https://api.github.com";

export interface GitRepoType {
	owner: string;
	name: string;
	description: string;
	id: string;
	htmlUrl: string;
}

export interface BranchesAndCommitsType {
	branchName: string;
	commits: Commit[];
}

interface Commit {
	hash: string;
	date: string;
}

// Function to get all repositories
async function getUserRepositories(): Promise<GitRepoType[]> {
	const accessToken = secureLocalStorage.getItem(
		"personalAccessToken"
	) as string;

	if (!accessToken) {
		return [];
	}

	try {
		const response = await fetch(`${githubApiBaseUrl}/installation/repositories`, {
			headers: { Authorization: `token ${accessToken}` }
		});

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const responseJson = await response.json();
		const repos = responseJson.repositories;
		return repos.map((repo: any) => ({
			owner: repo.owner.login,
			name: repo.name,
			description: repo.description,
			id: repo.id,
			htmlUrl: repo.html_url
		}));
	} catch (error) {
		addErrorNotification("Error", `Error fetching repositories: ${error}`);
		return [];
	}
}

async function getBranchesAndCommits(
	repoId: string,
	repoOwner: string,
	repoName: string
): Promise<BranchesAndCommitsType[]> {
	const accessToken = secureLocalStorage.getItem(
		"personalAccessToken"
	) as string;

	if (!accessToken) {
		return [];
	}

	try {
		const branchesResponse = await fetch(
			`${githubApiBaseUrl}/repositories/${repoId}/branches`,
			{
				headers: { Authorization: `token ${accessToken}` }
			}
		);

		if (!branchesResponse.ok) {
			throw new Error(`HTTP error! status: ${branchesResponse.status}`);
		}

		let branches = await branchesResponse.json();

		// Sort branches to put main/master first
		branches = branches.sort((a: any, b: any) => {
			if (a.name === "main" || a.name === "master") return -1;
			if (b.name === "main" || b.name === "master") return 1;
			return 0;
		});

		const branchDetails = await Promise.all(
			branches.map(async (branch: any) => {
				// Fetch the last 20 commits for the branch
				const commitsUrl = `${githubApiBaseUrl}/repos/${repoOwner}/${repoName}/commits?sha=${branch.name}&per_page=20`;
				const commitsResponse = await fetch(commitsUrl, {
					headers: { Authorization: `token ${accessToken}` }
				});

				if (!commitsResponse.ok) {
					throw new Error(`HTTP error! status: ${commitsResponse.status}`);
				}

				const commits = await commitsResponse.json();
				return {
					branchName: branch.name,
					commits: commits.map((commit: any) => ({
						hash: commit.sha,
						date: new Date(commit.commit.committer.date).toLocaleDateString(
							"en-US"
						)
					}))
				};
			})
		);

		return branchDetails;
	} catch (error) {
		// Make sure to handle errors appropriately in your application context
		addErrorNotification(
			"Error",
			`Error fetching branches and commits: ${error}`
		);
		return [];
	}
}

export { getUserRepositories, getBranchesAndCommits };
