import { yupResolver } from "@hookform/resolvers/yup";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PolicyIcon from "@mui/icons-material/Policy";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton, Stack, Typography, useTheme } from "@mui/material";
import FormSelectField from "components/formFields/FormSelectField";
import { useNotes } from "hooks/data/useNotes";
import { useTools } from "hooks/ui/useTools";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { FieldValues, Resolver, useForm } from "react-hook-form";
import { editorSelectionAtom } from "store/atoms/EditorAtoms";
import { notesTagsAtom } from "store/atoms/NotesAtoms";
import { openedTabAtom } from "store/atoms/UiAtoms";
import * as Yup from "yup";
import FormTextField from "../../formFields/FormTextField";
import FormToastEditorField from "../../formFields/FormToastEditorField";
import AddNewTag from "./AddNewTag";
export interface AddNoteFormType {
	title: string;
	location: string;
	noteContent: string;
	tag?: string;
	tagColor?: string;
}

interface Props {
	handleSubmit: (data: AddNoteFormType) => void;
	disabled?: boolean;
	edit?: boolean;
	values?: null | any;
}

const schema = Yup.object().shape({
	title: Yup.string().required("Title is required"),
	location: Yup.string(),
	noteContent: Yup.string()
});

const AddNoteForm: React.FC<Props> = ({
	handleSubmit: propsHandleSubmit,
	disabled: PropsDisabled = false,
	edit,
	values = {}
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [wait, setWait] = useState(edit ? true : false);
	const [disabled, setDisabled] = useState(PropsDisabled);

	const { selectTool } = useTools();

	const [{ openedFile, projectFilePath }] = useAtom(openedTabAtom);

	const [editorSelection] = useAtom(editorSelectionAtom);
	const [notesTags] = useAtom(notesTagsAtom);

	const { deleteNoteTag } = useNotes();

	useEffect(() => {
		setDisabled(PropsDisabled);
	}, [PropsDisabled]);

	const formOptions = {
		resolver: yupResolver(schema) as Resolver<AddNoteFormType, FieldValues>,
		defaultValues: {
			tag: notesTags?.[0]?.name || "Note",
			tagColor: notesTags?.[0]?.color || "primary-main",
			...values
		}
	};

	const {
		handleSubmit,
		register,
		control,
		setValue,
		setFocus,
		watch,
		formState: { errors }
	} = useForm<FieldValues & AddNoteFormType & any>(formOptions);

	useEffect(() => {
		setFocus("title");
	}, [setFocus]);

	useEffect(() => {
		if (wait) {
			setTimeout(() => {
				setWait(false);
			}, 100);
		}
	}, [wait]);

	// Update location
	useEffect(() => {
		if (
			editorSelection.startLineNumber &&
			openedFile &&
			!wait
			// && !(
			// 	editorSelection.startLineNumber === editorSelection.endLineNumber &&
			// 	editorSelection.startColumn === editorSelection.endColumn
			// )
		) {
			setValue(
				"location",
				`${projectFilePath}#${editorSelection.startLineNumber}-${editorSelection.endLineNumber}`
			);
		} else {
			setValue("location", edit ? values?.location || "" : "");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editorSelection, openedFile]);

	const onSubmit = (data: AddNoteFormType) => {
		// setDisabled(true);
		propsHandleSubmit?.(data);
	};

	const titleField = watch("title");
	const locationField = watch("location");
	const noteContentField = watch("noteContent");
	const tag = watch("tag");

	const disableButton = !titleField || !tag;

	// Change tag color based on tag
	useEffect(() => {
		const foundNote = notesTags?.find?.((i) => i?.name === tag);

		setValue(
			"tagColor",
			(foundNote?.color || values?.tagColor || "success.light").replaceAll(
				".",
				"-"
			)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tag, notesTags]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
			<Stack spacing={3} sx={{ height: "100%" }}>
				{/* Title */}
				<Stack spacing={1}>
					<Typography>Note Title</Typography>

					<FormTextField
						placeholder="Title"
						fullWidth
						disabled={disabled}
						required
						name="title"
						register={register}
						error={!!errors["title"]}
						helperText={errors["title"]?.message}
					/>
				</Stack>

				{/* Tag */}
				<Stack spacing={1}>
					<Typography>Tag</Typography>

					<FormSelectField
						placeholder="Select a tag"
						fullWidth
						disabled={disabled}
						required
						name="tag"
						control={control}
						error={!!errors["tag"]}
						items={notesTags.filter(Boolean).map((item, i) => {
							if (!item) return { name: "" };

							const isNewCustomTag = !!item._id && !notesTags[i - 1]?._id;

							return {
								...item,
								Icon: <BookmarkIcon sx={{ color: item.color }} />,
								Action: item._id ? (
									<Stack direction="row" alignItems="center" spacing={0.5}>
										<IconButton
											size="small"
											onClick={(e) => {
												e.stopPropagation();
												deleteNoteTag(item.name, item._id);
											}}
											className="selectActionShowOnhover"
											sx={{ display: "none", height: 24, width: 24 }}
										>
											<DeleteOutlineIcon sx={{ fontSize: 18 }} />
										</IconButton>
									</Stack>
								) : null,
								borderTop: !!isNewCustomTag
							};
						})}
						// defaultValue={tag}
					>
						<AddNewTag />
					</FormSelectField>
				</Stack>

				{/* Location */}
				<Stack spacing={1}>
					<Typography>Location</Typography>

					<Stack
						alignItems="center"
						justifyContent="center"
						sx={{
							p: 2,
							bgcolor: "background.default",
							borderRadius: "8px",
							minHeight: 80,
							...(themeMode === "light" && {
								boxShadow: 1
							})
						}}
					>
						{locationField ? (
							<Typography
								sx={{ whiteSpace: "pre-line", wordBreak: "break-word" }}
							>
								{locationField}
							</Typography>
						) : (
							<Typography color="text.disabled" sx={{ textAlign: "center" }}>
								Highlight code to select a location
							</Typography>
						)}
					</Stack>

					{/* <FormTextField
						placeholder="Where is this note referring to?"
						fullWidth
						disabled
						required
						name="location"
						register={register}
						error={!!errors["location"]}
						helperText={errors["location"]?.message}
						multiline
						rows={3}
					/> */}
				</Stack>

				{/* Note Content */}
				<Stack spacing={1}>
					<Typography>Description</Typography>

					<FormToastEditorField
						value={values?.noteContent || ""}
						name="noteContent"
						setValue={setValue}
						// error={errors["noteContent"]?.message}
					/>
				</Stack>

				<LoadingButton
					type="submit"
					variant="contained"
					disabled={disableButton || disabled}
					loading={disabled}
					sx={{ height: 39, borderRadius: "8px" }}
				>
					{edit ? "Save Note" : "Add Note"}
				</LoadingButton>

				{edit && (
					<Button
						variant="outlined"
						sx={{
							mt: "16px!important",
							height: 39,
							borderRadius: "8px",
							color: "text.primary",
							bgcolor: "background.paper",
							"&:hover": { bgcolor: "background.nav" }
						}}
						onClick={() => {
							selectTool("AddFinding", {
								values: {
									title: titleField,
									noteContent: noteContentField,
									location: locationField
								}
							});
						}}
					>
						<Stack direction="row" alignItems="center" spacing={1}>
							<PolicyIcon fontSize="small" />

							<Typography>Create Finding with this note</Typography>
						</Stack>
					</Button>
				)}
			</Stack>
		</form>
	);
};

export default AddNoteForm;
