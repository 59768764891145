import ProjectFolder from "./ProjectFolder";
import ProjectImport from "./ProjectImport";
import { ControlFlowGraph } from "./SolidityParser";

export default class Project {
	readonly rootFolder: ProjectFolder;
	readonly importsFolder: ProjectFolder;
	readonly name: string;
	readonly source: string;
	readonly id: string;
	readonly submittable: boolean;
	readonly imageUrl: string;
	readonly descriptionUrl: string;
	readonly hatsProjectId: string;
	readonly missingImports: string[];
	readonly privateMode: boolean;
	readonly sourceNetwork: string;
	readonly remappings: {scopePath: string, source: string, remappedPath: string}[];
	CFG: ControlFlowGraph;
	readonly libPaths: string[];
	readonly configRootPath: string;
	readonly contractSourcePath: string;
	readonly subProjects: {configRootPath: string, contractSourcePath: string}[];

	constructor(
		projectName: string,
		rootFolder: ProjectFolder,
		imports: ProjectFolder,
		source: string,
		id: string,
		submittable: boolean = false,
		imageUrl: string = null,
		descriptionUrl: string = null,
		hatsProjectId: string = null,
		missingImports: string[] = [],
		privateMode: boolean = false,
		sourceNetwork: string = null,
		remappings: {scopePath: string, source: string, remappedPath: string}[] = [],
		libPaths: string[] = [],
		configRootPath: string,
		contractSourcePath: string,
		subProjects: {configRootPath: string, contractSourcePath: string}[] = []
	) {
		this.name = projectName;
		this.rootFolder = rootFolder;
		this.importsFolder = imports;
		this.source = source;
		this.id = id;
		this.submittable = submittable;
		this.imageUrl = imageUrl;
		this.descriptionUrl = descriptionUrl;
		this.hatsProjectId = hatsProjectId;
		this.missingImports = missingImports || [];
		this.privateMode = privateMode;
		this.sourceNetwork = sourceNetwork;
		this.remappings = remappings || [];
		this.libPaths = libPaths || [];
		this.configRootPath = configRootPath;
		this.contractSourcePath = contractSourcePath;
		this.subProjects = subProjects;
	}

	static deserialize(data: string): Project {
		// Deserializing just gives us a plain javascript object, we need to reconstruct it as a Project object
		const parsedProject = JSON.parse(data);

		const rootFolder = ProjectFolder.deserialize(parsedProject.rootFolder);
		const projectImports = ProjectImport.deserialize(parsedProject.imports);

		return new Project(
			parsedProject.name,
			rootFolder,
			projectImports,
			parsedProject.source,
			parsedProject.id,
			parsedProject.submittable,
			parsedProject.imageUrl,
			parsedProject.descriptionUrl,
			parsedProject.hatsProjectId,
			parsedProject.missingImports,
			parsedProject.privateMode,
			parsedProject.sourceNetwork,
			parsedProject.remappings,
			parsedProject.libPaths,
			parsedProject.configRootPath,
			parsedProject.contractSourcePath,
			parsedProject.subProjects
		);
	}

	async buildCFG(): Promise<ControlFlowGraph> {
		const cfg = new ControlFlowGraph(this);
		await cfg.generate();
		return cfg;
	}
}
