import { Box, Stack, styled } from "@mui/material";
import { useAppContext } from "context/AppContext";
import hex from "utils/hexTransparency";
import ImportCTF from "./ImportCTF";
import ProjectImportView from "./ProjectImportView";
import { useAtom } from "jotai";
import { importsModeAtom } from "store/atoms/UiAtoms";

export const StyledImportBox = styled(Box)(({ theme }) => ({
	width: "100%",
	padding: theme.spacing(2),
	backgroundColor: `#000000${hex["30%"]}`,
	borderRadius: "16px"
}));

const ImportsView = () => {
	const [importsMode] = useAtom(importsModeAtom);

	return (
		<Stack
			sx={{
				minHeight: 0,
				flexGrow: 1,
				bgcolor: "background.paper",
				backgroundSize: "cover",
				backgroundPosition: "center center",
				width: "100%",
				overflowX: "auto"
			}}
		>
			{importsMode === "project" ? (
				<ProjectImportView />
			) : importsMode === "ctf" ? (
				<ImportCTF />
			) : null}
		</Stack>
	);
};

export default ImportsView;
