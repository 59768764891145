import {
	addProjectsToFolderOrReorderFolder,
	loadProjectsList
} from "Resources/ServerInterface";
import { usernameAtom } from "atoms";
import { useAppContext } from "context/AppContext";
import { useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { projectsAtom, projectsLoadedAtom } from "store/atoms/projectToolAtoms";

export function useProjects() {
	const { handleLogout } = useAppContext();

	const setProjects = useSetAtom(projectsAtom);
	const setProjectsLoaded = useSetAtom(projectsLoadedAtom);

	const username = useAtomCallback(
		useCallback((get) => {
			const curr = get(usernameAtom);
			return curr;
		}, [])
	);

	const loadProjects = async () => {
		try {
			if (!username()) handleLogout();

			const projectsFetch = await loadProjectsList();

			if (!!projectsFetch) {
				const _projects = projectsFetch.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});

				setProjects(_projects);
			}

			setProjectsLoaded(true);
		} catch (e) {
			console.log(e);
			setProjects([]);
		}
	};

	const addProjectToFolder = async (projectsIds: any[], folderName: string) => {
		try {
			await addProjectsToFolderOrReorderFolder(projectsIds, folderName);

			await loadProjects();
		} catch (error) {
			console.log(error);
		}
	};

	const removeProjectFromFolder = async () => {
		try {
			// Logic to remove project from folder
			// clearFolderForProjects

			await loadProjects();
		} catch (error) {
			console.log(error);
		}
	};

	return { loadProjects, addProjectToFolder, removeProjectFromFolder };
}
