import CodeEditorCore from "@auditware/CodeEditorCore";
import { Monaco } from "@monaco-editor/react";
import { Box, useTheme } from "@mui/material";
import { getFileExtension } from "Resources/Helpers";
import useSetAtom from "hooks/useSetAtom";
import { useAtom } from "jotai";
import { editor } from "monaco-editor";
import React, { useEffect, useRef, useState } from "react";
import {
	JumpToLineAtom,
	defaultEditorSelection,
	defaultJumpToLine,
	editorClickListenerAtom,
	editorHoverListenerAtom,
	editorScrollListenerAtom,
	editorSelectionAtom
} from "store/atoms/EditorAtoms";
import { openedTabAtom } from "store/atoms/UiAtoms";
import CallableValueAddon from "./Addons/CallableValueAddon";
import { callableValueStyles } from "./Addons/CallableValueAddon/styles";
import FindingsAddon from "./Addons/FindingsAddon";
import { findingStyles } from "./Addons/FindingsAddon/styles";
import GoToLineAddon from "./Addons/GoToLineAddon";
import NotesAddon from "./Addons/NotesAddon";
import { bookmarkStyles } from "./Addons/NotesAddon/styles";
import QuickNoteAddon from "./Addons/QuickNoteAddon";
import SaveFileScrollAddon from "./Addons/SaveFileScrollAddon";
import ScanAddon from "./Addons/ScanAddon";
import { scanStyles } from "./Addons/ScanAddon/styles";
import SolidityDefinitionProviderAddon from "./Addons/SolidityDefinitionProviderAddon";

const extensionLanguage = {
	js: "javascript",
	jsx: "javascript",
	ts: "typescript",
	tsx: "typescript",
	json: "json",
	py: "python"
};

const ADDONS = [
	NotesAddon,
	FindingsAddon,
	SaveFileScrollAddon,
	ScanAddon,
	GoToLineAddon,
	SolidityDefinitionProviderAddon,
	CallableValueAddon,
	QuickNoteAddon
];

interface Props {
	value: string;
}

// export type editorRefType =
// 	React.MutableRefObject<editor.IStandaloneCodeEditor>;

// export type monacoRefType = React.MutableRefObject<Monaco>;

const MonacoEditor: React.FC<Props> = ({ value }) => {
	const theme = useTheme();

	const [mounted, setMounted] = useState(false);

	const [{ projectFilePath }] = useAtom(openedTabAtom);

	const setJumpToEditorLine = useSetAtom(JumpToLineAtom);

	const setClickListener = useSetAtom(editorClickListenerAtom);
	const setScrollListener = useSetAtom(editorScrollListenerAtom);
	const setEditorSelection = useSetAtom(editorSelectionAtom);
	const setHoverListener = useSetAtom(editorHoverListenerAtom);

	const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
	const monacoRef = useRef<Monaco | null>(null);

	const setEditorRef = (newValue) => {
		editorRef.current = newValue;
	};

	const setMonacoRef = (newValue) => {
		monacoRef.current = newValue;
	};

	const fileExt = getFileExtension(projectFilePath).replace(".", "");

	// Reset states when changes file
	useEffect(() => {
		setJumpToEditorLine(defaultJumpToLine);
		setEditorSelection(defaultEditorSelection);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectFilePath]);

	return (
		<Box sx={{ height: "100%" }}>
			<CodeEditorCore
				value={value}
				language={extensionLanguage[fileExt] || "sol"}
				rootStyles={{
					...bookmarkStyles(theme),
					...findingStyles(theme),
					...scanStyles(theme),
					...callableValueStyles(theme)
				}}
				controlsBarText={projectFilePath.replaceAll("/", " > ")}
				handleOnMount={(e, m) => {}}
				editorRef={editorRef.current}
				setEditorRef={setEditorRef}
				monacoRef={monacoRef.current}
				setMonacoRef={setMonacoRef}
				onReady={setMounted}
				handleClickListener={setClickListener}
				handleScrollListener={setScrollListener}
				// handleHoverListener={setHoverListener}
				handleSelectionListener={setEditorSelection}
			/>

			{/* Addons */}
			{mounted &&
				editorRef.current &&
				monacoRef.current &&
				ADDONS.map((Component, i) => (
					<Component
						key={i}
						editorRef={editorRef.current}
						monacoRef={monacoRef.current}
					/>
				))}
		</Box>
	);
};

export default React.memo(MonacoEditor);
