import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	Button,
	Dialog,
	IconButton,
	Stack,
	Typography,
	Zoom,
	useTheme
} from "@mui/material";
import darkBg from "assets/images/backgrounds/challengeCompletedModalDark.png";
import lightBg from "assets/images/backgrounds/challengeCompletedModalLight.png";
import { useProject } from "hooks/data/useProject";
import hex from "utils/hexTransparency";

export interface Props {
	open: boolean;
	handleClose: () => void;
}

const FindTheBugSubmittedModal = (props: Props) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const { handleClose, open } = props;

	const { closeProject } = useProject();

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="sm"
			sx={{
				"& .MuiPaper-root": {
					bgcolor: "transparent",
					backgroundImage: "none",
					boxShadow: "none"
				},
				"& .MuiBackdrop-root": {
					bgcolor: `${theme.palette.background["nav"]}${hex["50%"]}`,
					backdropFilter: "blur(2px)"
				},
				"& .MuiDialog-container": {
					pt: 8,
					alignItems: "flex-start"
				}
			}}
		>
			<Zoom in={open}>
				<Box
					sx={{
						position: "relative",
						bgcolor: "background.paper",

						height: { xs: 500, md: 600 },
						borderRadius: "20px"
					}}
				>
					<Box
						src={themeMode === "dark" ? darkBg : lightBg}
						component="img"
						sx={{
							position: "absolute",
							top: 0,
							bottom: 0,
							left: 0,
							right: 0,
							width: "100%",
							height: "90%",
							objectFit: "cover",
							borderRadius: "inherit"
						}}
					/>

					<IconButton
						onClick={() => {
							handleClose();
						}}
						size="small"
						sx={{ position: "absolute", top: 10, right: 10 }}
					>
						<CloseIcon />
					</IconButton>

					<Stack
						alignItems="center"
						sx={{
							textAlign: "center",
							position: "absolute",
							bottom: 0,
							width: "100%",
							px: 4,
							pb: 4
						}}
					>
						<Typography variant="h3" sx={{ mb: 1.5 }}>
							Challenge Submitted Successfully
						</Typography>

						<Typography sx={{ mb: 2 }}>
							Thank you for your submission! Your submission will be reviewed
							upon closure of the challenge. If your submission is valid, you
							will earn a badge on your auditor profile!
							There will be many opportunities to earn more badges in the
							future.
						</Typography>

						<Button
							variant="contained"
							fullWidth
							sx={{ borderRadius: "8px" }}
							onClick={() => {
								closeProject();
							}}
						>
							Return to home
						</Button>
					</Stack>
				</Box>
			</Zoom>
		</Dialog>
	);
};

export default FindTheBugSubmittedModal;
