import { LoadingButton } from "@mui/lab";
import { Box, Stack, SvgIcon, Typography } from "@mui/material";
import { addErrorNotification } from "Resources/Notifications";
import { signSubmission } from "Resources/ServerInterface";
import { HatsSubmission } from "Resources/models";
import { useAtom } from "jotai";
import { projectAtom } from "store/atoms/projectToolAtoms";
import { ReactComponent as HatsIcon } from "../../../assets/icons/hatsIcon.svg";
import { riskLevelToText } from "../Tools/AddFindingsTool/helpers";
import { SubmissionItemValues } from "./SubmissionsEditor";

const SubmissionsToolbar = ({ submissionItem }) => {
	const [project] = useAtom(projectAtom);

	const submitIsDisabled = () => {
		const addressRegexp = /^0x[a-fA-F0-9]{40}$/;
		const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

		const submissionValues = submissionItem as SubmissionItemValues;
		if (
			!submissionValues.email ||
			!submissionValues.walletAddress ||
			!submissionValues.issueDescription ||
			!submissionValues.issueTitle ||
			!riskLevelToText(submissionValues.issueSeverity)
		) {
			return true;
		}

		if (
			!addressRegexp.test(submissionValues.walletAddress) ||
			!emailRegExp.test(submissionValues.email)
		) {
			return true;
		}
		return false;
	};

	return (
		<Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
			<Stack
				direction={"row"}
				spacing={0.2}
				alignItems="left"
				sx={{
					border: "1px solid white",
					borderRadius: "10px",
					paddingTop: "16px",
					paddingLeft: "16px",
					paddingRight: "16px"
				}}
			>
				<SvgIcon sx={{ mt: -0.3, fontSize: "40px" }}>
					<HatsIcon />
				</SvgIcon>
				<Typography>
					<strong>Hats Finance</strong>
				</Typography>
			</Stack>
			<Box
				component="img"
				sx={{
					height: "100%",
					width: 38,
					objectFit: "contain"
				}}
				alt={project.name}
				src={project.imageUrl ?? ""}
			/>
			<Typography>{project.name}</Typography>
			<Typography
				component="a"
				href={project.descriptionUrl ?? ""}
				target="_blank"
				rel="noopener noreferrer"
				sx={{
					color: "#8870E6",
					textDecoration: "none",
					"&:hover": {
						textDecoration: "underline"
					}
				}}
			>
				Learn more
			</Typography>

			<Box sx={{ flex: 1 }} />

			<LoadingButton
				disabled={submitIsDisabled()}
				loading={false}
				variant="contained"
				sx={{ borderRadius: "8px", height: "50px" }}
				onClick={async () => {
					// Compose data into json object
					// Hit server endpoint to sign the data
					// Post message to hats
					const itemValues = submissionItem as SubmissionItemValues;
					if (!project.hatsProjectId) {
						addErrorNotification(
							"Error",
							"This import has missing data. Please re-import the latest version of this contest to submit."
						);
						return;
					}
					const submission: HatsSubmission = {
						project: {
							projectId: project.hatsProjectId
						},
						contact: {
							beneficiary: itemValues.walletAddress,
							communicationChannel: itemValues.email,
							communicationChannelType: "email"
						},
						submissionsDescriptions: {
							descriptions: [
								{
									title: itemValues.issueTitle,
									description: itemValues.issueDescription,
									severity: riskLevelToText(itemValues.issueSeverity)
								}
							]
						}
					};
					const signature = await signSubmission(submission);
					const signedSubmission = { ...submission, signature };

					const targetWindow = window.open(
						"https://app.hats.finance/vulnerability",
						"_blank"
					);
					setTimeout(() => {
						targetWindow.postMessage(
							signedSubmission,
							"https://app.hats.finance/vulnerability"
						);
					}, 2000);
				}}
			>
				Submit Vulnerability
			</LoadingButton>
		</Stack>
	);
};

export default SubmissionsToolbar;
