import BugReportIcon from "@mui/icons-material/BugReport";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import GitHubIcon from "@mui/icons-material/GitHub";
import {
	Hidden,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import DarkBg from "assets/images/backgrounds/importListDarkBg.png";
import LightBg from "assets/images/backgrounds/importListLightBg.png";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { importingAtom } from "store/atoms/ImportsAtoms";
import hex from "utils/hexTransparency";
import ChallengesProjects from "./ProjectsTabs/ChallengesProjects";
import ContestsBountiesProjects from "./ProjectsTabs/ContestsBountiesProjects";
import GitHubProjects from "./ProjectsTabs/GitHubProjects";
import ImportsTab from "./ProjectsTabs/ImportsTab";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
	borderRadius: "8px",
	...(theme.palette.mode === "light" && {
		borderLeft: 0,
		borderTop: 0,
		borderBottom: 0,
		color: `${theme.palette.text.primary}!important`
	}),
	"&.MuiButtonBase-root": {
		borderColor: theme.palette.primary.main + hex["30%"]
	},
	"&.Mui-selected, &.Mui-selected:hover": {
		backgroundColor: theme.palette.background["selected"],
		...(theme.palette.mode === "light" && {
			color: `${theme.palette.text.primary}!important`
		})
	}
}));

const ProjectContent = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [importing, setImporting] = useAtom(importingAtom);

	const [selectedType, setSelectedType] = useState(0);

	const handleTypeChange = (e: React.MouseEvent<HTMLElement>, val: number) => {
		if (val === null) return null;

		setSelectedType(val);
	};

	useEffect(() => {
		setImporting(false);

		if (localStorage.getItem("challengeTabDefault") === "true") {
			localStorage.removeItem("challengeTabDefault");
			setSelectedType(3);
		}
	}, []);

	return (
		<Stack
			sx={{
				p: 2,
				flex: 1,
				borderRadius: "10px",
				width: "100%",
				height: "100%",
				background: `url(${themeMode === "dark" ? DarkBg : LightBg})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				bgcolor: "background.default",
				overflow: "hidden"
			}}
		>
			<ToggleButtonGroup
				disabled={importing}
				size="small"
				color="primary"
				value={selectedType}
				exclusive
				onChange={!importing ? handleTypeChange : null}
				fullWidth
				sx={{
					mb: 2.5,
					height: { xs: 38, md: 42 },
					borderRadius: "8px",
					...(themeMode === "light" && {
						bgcolor: "background.paper",
						boxShadow: 1
					})
				}}
			>
				<StyledToggleButton value={0}>
					<CrisisAlertIcon sx={{ fontSize: 18, mr: 0.6 }} />
					<Hidden smDown>
						<Typography>Imports</Typography>
					</Hidden>
				</StyledToggleButton>

				<StyledToggleButton value={1}>
					<CrisisAlertIcon sx={{ fontSize: 18, mr: 0.6 }} />
					<Hidden smDown>
						<Typography>Contests</Typography>
					</Hidden>
				</StyledToggleButton>

				<StyledToggleButton value={2}>
					<BugReportIcon sx={{ fontSize: 18, mr: 0.6 }} />
					<Hidden smDown>
						<Typography>Bounties</Typography>
					</Hidden>
				</StyledToggleButton>

				<StyledToggleButton value={3}>
					<EmojiEventsIcon sx={{ fontSize: 18, mr: 0.6 }} />
					<Hidden smDown>
						<Typography>Challenges</Typography>
					</Hidden>
				</StyledToggleButton>
			</ToggleButtonGroup>

			<Stack sx={{ height: "100%", overflow: "auto" }}>
				{selectedType === 0 && <ImportsTab />}

				{selectedType === 1 && <ContestsBountiesProjects type="contests" />}

				{selectedType === 2 && <ContestsBountiesProjects type="bounties" />}

				{selectedType === 3 && <ChallengesProjects />}
			</Stack>
		</Stack>
	);
};

export default React.memo(ProjectContent);
