import { getStorageSlots } from "Resources/ServerInterface";
import { mixpanelAtom } from "atoms";
import { groupDataBySlot } from "components/Toolbox/Tools/InspectTool/helpers";
import { useAtom } from "jotai";
import { useState } from "react";
import { storageSlotAtoms } from "store/atoms/storageSlotAtoms";
import useSetAtom from "../useSetAtom";
import { LOCALSTORAGE_STORAGE_SLOTS } from "utils/constants";
import path from "path";

export function useStorageSlots() {
	const setStorageSlots = useSetAtom(storageSlotAtoms);
	const [mixpanel] = useAtom(mixpanelAtom);

	const [errors, setErrors] = useState<string | object>(null);
	const [slotsLoading, setSlotsLoading] = useState(true);

	const loadStorageSlots = async (projectFilePath, projectId) => {
		if (path.extname(projectFilePath) === ".sol") {
			try {
				setErrors(null);
				setSlotsLoading(true);

				// Fetch from localstorage if exists
				const storageVar = `${LOCALSTORAGE_STORAGE_SLOTS}-${projectFilePath}`;
				const storageData = localStorage.getItem(storageVar);

				if (storageData) {
					const jsonRes = JSON.parse(storageData);

					if (jsonRes?.errors) {
						setErrors(jsonRes?.errors);
					}

					return setStorageSlots(groupDataBySlot(jsonRes?.storageSlots));
				} else {
					const response = await getStorageSlots(
						projectFilePath,
						projectId,
						mixpanel
					);

					if (response) {
						if (response?.status === "success") {
							localStorage.setItem(storageVar, JSON.stringify(response));
							return setStorageSlots(groupDataBySlot(response?.storageSlots));
						}

						if (response.status === "compilation_error") {
							localStorage.setItem(storageVar, JSON.stringify(response));
							setErrors(response?.errors);
							setStorageSlots({});
						}
					} else {
						setErrors(
							"Compilation error - Unable to fetch storage slots for this file"
						);
						setStorageSlots({});
					}
				}
			} catch (error) {
				setStorageSlots({});
			} finally {
				setSlotsLoading(false);
			}
		}
	};

	return { errors, loadStorageSlots, slotsLoading };
}
