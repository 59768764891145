import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditIcon from "@mui/icons-material/Edit";
import {
	Box,
	Button,
	Divider,
	IconButton,
	Popover,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import { yellow } from "@mui/material/colors";
import { SEVERITY_OPTIONS } from "components/Toolbox/Tools/AddFindingsTool/helpers";
import hex from "utils/hexTransparency";

const FindingsWidgetNew = ({
	viewAnchor,
	closeViewFinding,
	handleEditFinding,
	handleDeleteFinding
}) => {
	const theme = useTheme();

	const target = viewAnchor?.target;
	const finding = viewAnchor?.finding;

	const tagColor =
		SEVERITY_OPTIONS.find((i) => i.value === finding.severity).color ||
		yellow["500"];

	return (
		<Popover
			open={Boolean(viewAnchor)}
			anchorEl={target}
			onClose={closeViewFinding}
			slotProps={{
				paper: {
					sx: {
						p: 1.5,
						border: "2px solid",
						borderRadius: "8px",
						borderColor: tagColor,
						backgroundImage: "none",
						minWidth: { xs: "100%", md: 400 }
					}
				}
			}}
		>
			<Stack spacing={1}>
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<Icon icon="ph:detective-fill" fontSize={20} color={tagColor} />

					<Typography sx={{ fontWeight: 600 }}>{finding.name}</Typography>

					<Box sx={{ flex: 1 }} />

					<IconButton
						color="inherit"
						onClick={closeViewFinding}
						sx={{ height: 22, width: 20 }}
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				</Stack>

				<Divider />

				<Typography sx={{ fontWeight: 600 }}>Description:</Typography>
				<Typography sx={{ whiteSpace: "pre-line" }}>
					{finding.description}
				</Typography>

				<Divider />

				<Typography sx={{ fontWeight: 600 }}>Recommendation:</Typography>
				<Typography sx={{ whiteSpace: "pre-line" }}>
					{finding.recommendation}
				</Typography>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="flex-end"
					spacing={1}
				>
					<Button
						size="small"
						color="inherit"
						variant="outlined"
						onClick={() => {
							closeViewFinding();
							handleEditFinding(finding);
						}}
						sx={{
							borderColor: `divider`,
							borderRadius: "8px"
						}}
					>
						<EditIcon sx={{ fontSize: 16, mr: 0.5 }} />
						Edit
					</Button>

					<Button
						size="small"
						color="inherit"
						variant="outlined"
						onClick={() => {
							closeViewFinding();
							handleDeleteFinding(finding);
						}}
						sx={{
							borderColor: `${theme.palette.primary.main}${hex["50%"]}`,
							borderRadius: "8px"
						}}
					>
						<DeleteForeverOutlinedIcon sx={{ fontSize: 16, mr: 0.5 }} />
						Delete
					</Button>
				</Stack>
			</Stack>
		</Popover>
	);
};

export default FindingsWidgetNew;
