import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FieldValues, Resolver, useForm } from "react-hook-form";
import * as Yup from "yup";
import FormTextField from "../../formFields/FormTextField";
import FormSelectField from "components/formFields/FormSelectField";
import { useAtom } from "jotai";
import { testCollectionsAtom } from "store/atoms/testAtoms";

export interface TestTemplateFormType {
	name: string;
	collection: string;
}

interface Props {
	handleSubmit: (data: TestTemplateFormType) => void;
	disabled?: boolean;
}

const schema = Yup.object().shape({
	name: Yup.string().required("Template name is required"),
	collection: Yup.string().required("Add this test to a collection")
});

const TestCollectionForm: React.FC<Props> = ({
	handleSubmit: propsHandleSubmit,
	disabled: PropsDisabled = false
}) => {
	const [disabled, setDisabled] = useState(PropsDisabled);

	useEffect(() => {
		setDisabled(PropsDisabled);
	}, [PropsDisabled]);

	const [testCollections] = useAtom(testCollectionsAtom);

	// Filter and map testCollections to the required format for the select field
	const collectionItems = testCollections
		.filter((collection) => !collection.isPublic) // Filter out public collections
		.map((collection) => ({
			name: collection.collectionName, // Display value
			value: collection._id // Actual value set on selection
		}));
	const defaultValue =
		collectionItems?.length > 0 ? collectionItems[0].value : "";

	const formOptions = {
		resolver: yupResolver(schema) as Resolver<
			TestTemplateFormType,
			FieldValues
		>,
		defaultValues: { collection: defaultValue }
	};

	const {
		handleSubmit,
		register,
		control,
		formState: { errors }
	} = useForm<FieldValues & TestTemplateFormType & any>(formOptions);

	const onSubmit = (data: TestTemplateFormType) => {
		// setDisabled(true);
		propsHandleSubmit?.(data);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={1.5} sx={{ px: 0.4 }}>
				<Typography sx={{ fontWeight: 600 }}>Create test template</Typography>

				<Typography>
					The code in the test editor will be saved as a template for instant
					reuse at any time.
				</Typography>

				<FormTextField
					fullWidth
					disabled={disabled}
					required
					multiline
					rows={1}
					name="name"
					placeholder="Add a template name"
					register={register}
					error={!!errors["name"]}
					helperText={errors["name"]?.message}
					sx={{ flex: 1 }}
				/>

				<FormSelectField
					fullWidth
					disabled={disabled}
					required
					name="collection"
					control={control}
					error={!!errors["collection"]}
					helperText={
						errors["collection"]?.message &&
						String(errors["collection"]?.message)
					}
					defaultValue={defaultValue}
					items={collectionItems}
				/>

				<LoadingButton
					type="submit"
					variant="contained"
					disabled={disabled}
					loading={disabled}
					sx={{ height: 39 }}
				>
					Create template
				</LoadingButton>
			</Stack>
		</form>
	);
};

export default TestCollectionForm;
