import secureLocalStorage from "react-secure-storage";
import {
	aiScanFailureEvent,
	aiScanSuccessEvent,
	authEvent,
	findingAddedEvent,
	graphCreatedEvent,
	messageSentToAIEvent,
	projectImportFailureEvent,
	projectImportSuccessEvent,
	slitherScanFailureEvent,
	slitherScanSuccessEvent,
	getStorageSlotsFailureEvent,
	getStorageSlotsSuccessEvent,
	whiteboardAddedEvent,
	analyzerScanSuccessEvent,
	analyzerScanFailureEvent,
	aderynScanSuccessEvent,
	aderynScanFailureEvent
} from "../Mixpanel/mixpanel.helper";
import { Finding } from "../components/Toolbox/Tools/FindingsTool";
import { getDecodedJwt, logout } from "./Auth";
import { addSuccessNotification, addErrorNotification } from "./Notifications";
import Project from "./Project";
import ProjectFile from "./ProjectFile";
import ProjectImport from "./ProjectImport";
import { HatsSubmission } from "./models";
import { Tag } from "context/AuditToolContext";
import { Whiteboard } from "store/atoms/whiteboardAtoms";
import {
	decryptFinding,
	decryptProjectNotes,
	decryptWhiteboard,
	encryptFinding,
	encryptProjectNotes,
	encryptWhiteboard
} from "utils/privateModeEncryption";
import { LOCALSTORAGE_GITHUB_CONNECTION } from "utils/constants";
import { clearAllCaches } from "components/ErrorFallback";
import GlobalState from 'utils/globals';

const workerCode = `
  importScripts('https://cdn.jsdelivr.net/npm/dexie@3/dist/dexie.min.js');

  const db = new Dexie('FileHydrationDB');
  db.version(1).stores({
    keyValueStore: 'key'
  });

  self.addEventListener('message', async (event) => {
    switch (event.data.command) {
      case 'save':
        await db.table('keyValueStore').bulkPut(event.data.keyValuePairs);
        self.postMessage({ success: true });
        break;
      case 'fetch':
        const value = await db.table('keyValueStore').get(event.data.key);
        self.postMessage({ success: true, value });
        break;
	  case 'bulkFetch':
		const values = await db.table('keyValueStore').bulkGet(event.data.keys);
		self.postMessage({ success: true, values });
		break;
    }
  });
`;

const blob = new Blob([workerCode], { type: "application/javascript" });
const workerURL = URL.createObjectURL(blob);
const FileHydrationWorker = new Worker(workerURL);

export interface ProjectNote {
	noteContent: string;
	dateUpdated: string;
}

const SERVER_URL =
	window.location.protocol +
	"//" +
	window.location.hostname +
	":" +
	(process.env.REACT_APP_SERVER_PORT || "5000");

export async function getCsrfToken(): Promise<string> {
	try {
		const jwt = getDecodedJwt();

		return jwt ? jwt["csrfToken"] : "notFound";
	} catch (e) {
		addErrorNotification("Error", "Error getting csrf token: " + e.message);
	}
}

const clearUserData = () => {
	logout();
	secureLocalStorage.removeItem("personalAccessToken");
	secureLocalStorage.removeItem("csrfToken");
};

function handleResponse(response) {
	if (response.status === 401 || response.status === 403) {
		// Log out the user and clear auth
		clearUserData();
	}
	return response;
}

export function fetch401Interceptor(url, options = undefined) {
	return fetch(url, options).then(handleResponse);
}

export async function submitFeedback(
	topic: string,
	description: string
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			"/submitFeedback?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });
		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify({ topic, description }),
			headers: { "Content-Type": "text/plain" }
		});

		if (res.ok) {
			addSuccessNotification(
				"Success",
				"Feedback successfully submitted.\nThank you for being an early tester."
			);
			return;
		} else throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error submitting feedback: " + e.message);
	}
}

export async function getMixpanelKey(): Promise<string> {
	try {
		const url =
			SERVER_URL +
			"/mixpanel?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });
		const res = await fetch401Interceptor(url);

		if (res.ok) return await res.text();
		else return "fakeToken";
	} catch (e) {
		console.log("Error initializing Mixpanel: " + e.message);
		return "fakeToken";
	}
}

export async function getFirebaseKeys(): Promise<any> {
	try {
		const url =
			SERVER_URL +
			"/firebase?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });
		const res = await fetch401Interceptor(url);

		if (res.ok) return await res.json();
		else return null;
	} catch (e) {
		console.log("Error initializing Firebase: " + e.message);
		return null;
	}
}

export async function getEmailIfExists(): Promise<string> {
	try {
		const url =
			SERVER_URL +
			"/user/email?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });
		const res = await fetch401Interceptor(url, {
			method: "GET",
			credentials: "include"
		});

		if (res.ok) return await res.text();
		else return null;
	} catch (e) {
		return null;
	}
}

export async function getUserHasGithubInstallation(): Promise<boolean> {
	try {
		const url =
			SERVER_URL +
			"/user/hasInstallation?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });
		const res = await fetch401Interceptor(url, {
			method: "GET",
			credentials: "include"
		});

		if (res.ok) {
			const resJson = await res.json();
			return resJson.hasInstallation;
		}
		else return false;
	} catch (e) {
		return null;
	}
}

export async function getGithubInstallationId(code: string): Promise<string> {
	try {
		const url =
			SERVER_URL +
			"/getInstallationId?" +
			new URLSearchParams({ csrfToken: await getCsrfToken(), code });
		const res = await fetch401Interceptor(url, {
			method: "GET",
			credentials: "include"
		});

		if (res.ok) {
			const resJson = await res.json();
			return resJson.installation_id;
		}
		else return null;
	} catch (e) {
		return null;
	}
}

export async function getPrivacyHashIfExists(): Promise<string> {
	try {
		const url =
			SERVER_URL +
			"/user/privacyHash?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });
		const res = await fetch401Interceptor(url, {
			method: "GET",
			credentials: "include"
		});

		if (res.ok) return await res.text();
		else return null;
	} catch (e) {
		return null;
	}
}

export async function setPrivacyHash(keyHash: string): Promise<void> {
	try {
		const url =
			SERVER_URL +
			"/user/privacyHash?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });
		const res = await fetch401Interceptor(url, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify({ keyHash })
		});

		if (!res.ok) throw new Error(res.statusText);
	} catch (e) {
		addErrorNotification(
			"Error",
			"Error setting user privacy key: " + e.message
		);
	}
}

export async function getGoogleClientId(): Promise<string> {
	try {
		const url =
			SERVER_URL +
			"/google?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });
		const res = await fetch401Interceptor(url);

		if (res.ok) return await res.text();
		else return null;
	} catch (e) {
		console.log("Error initializing Google: " + e.message);
		return "clientId";
	}
}

export async function getGithubClientId(): Promise<string> {
	try {
		const url =
			SERVER_URL +
			"/github?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });
		const res = await fetch401Interceptor(url);

		if (res.ok) return await res.text();
		else return null;
	} catch (e) {
		console.log("Error initializing Github: " + e.message);
		return "clientId";
	}
}

export async function getUserAvatarSrc(): Promise<string> {
	try {
		const url =
			SERVER_URL +
			"/settings/userAvatar?" +
			new URLSearchParams({
				csrfToken: await getCsrfToken()
			});

		const res = await fetch401Interceptor(url, {
			method: "GET",
			credentials: "include"
		});

		const resText = await res.text();
		if (res.ok) {
			return resText;
		} else {
			throw new Error(resText);
		}
	} catch (e) {
		// addErrorNotification("Error", "Error loading user avatar: " + e.message);
	}
}

export async function getTickerFeedData(): Promise<any> {
	try {
		const url = SERVER_URL + "/tickerFeed";

		const res = await fetch401Interceptor(url);

		if (res.ok) return await res.json();
		else throw new Error(await res.text());
	} catch (e) {
		addErrorNotification(
			"Error",
			"Error loading ticker feed data: " + e.message
		);
	}
}

export async function getPartnerAdsData(): Promise<any> {
	try {
		const url = SERVER_URL + "/getPartnerAds";

		const res = await fetch401Interceptor(url);

		if (res.ok) return await res.json();
		else throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error loading contest data: " + e.message);
	}
}

export async function importGeneralizedImport(
	generalizedId: string,
	privateMode: boolean
): Promise<any> {
	try {
		const url =
			SERVER_URL +
			`/imports/generalized/${generalizedId}/${privateMode}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, { credentials: "include" });
		const resText = await res.text();

		if (res.ok) {
			return Project.deserialize(resText);
		} else throw new Error(resText);
	} catch (e) {
		addErrorNotification(
			"Error",
			"Error importing one-click project: " + e.message
		);
	}
}

export async function importHatsProject(hatsId: string): Promise<any> {
	try {
		const url =
			SERVER_URL +
			`/imports/hats/${hatsId}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, { credentials: "include" });
		const resText = await res.text();

		if (res.ok) {
			return Project.deserialize(resText);
		} else throw new Error(resText);
	} catch (e) {
		addErrorNotification("Error", "Error importing Hats project: " + e.message);
	}
}

export async function signSubmission(
	submission: HatsSubmission
): Promise<string> {
	try {
		const url =
			SERVER_URL +
			`/submissions/sign?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify(submission)
		});

		const resText = await res.text();
		if (!res.ok) throw new Error(resText);
		return resText;
	} catch (e) {
		addErrorNotification("Error", "Error updating finding: " + e.message);
		return "";
	}
}

export async function resolveRemoteImport(
	contents: string,
	projectId: string,
	source: string
): Promise<string> {
	try {
		const url =
			SERVER_URL +
			`/resolveImport/${projectId}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify({ contents, source })
		});

		const resText = await res.text();
		if (!res.ok) throw new Error(resText);
		return resText;
	} catch (e) {
		addErrorNotification(
			"Error",
			"Error resolving remote import: " + e.message
		);
		return "";
	}
}

export async function editProjectImport(
	newContents: string,
	projectId: string,
	importId: string
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/editImportById?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify({ newContents, projectId, importId })
		});

		if (!res.ok) throw new Error(res.statusText);
	} catch (e) {
		addErrorNotification("Error", "Error editing remote import: " + e.message);
	}
}

export async function loadProjectsList(): Promise<any> {
	try {
		const url =
			SERVER_URL +
			"/loadProjectsList?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, { credentials: "include" });

		if (res.ok) return await res.json();
		else throw new Error(await res.text());
	} catch (e) {
		// addErrorNotification("Error", "Error loading projects list: " + e.message);
		console.log(e);
		clearAllCaches();
		throw e;
	}
}

export async function importProjectFromUrl(
	source,
	network,
	mixpanel,
	privateMode,
	commitSha?: string
): Promise<Project> {
	try {
		const url =
			SERVER_URL +
			"/importProject?" +
			new URLSearchParams({ source: source, csrfToken: await getCsrfToken() });

		const pat = secureLocalStorage.getItem("personalAccessToken") || undefined;

		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify({ pat, network, privateMode, commitSha })
		});
		const resText = await res.text();

		if (res.ok) {
			projectImportSuccessEvent(mixpanel, source, privateMode);
			return Project.deserialize(resText);
		} else throw new Error(resText);
	} catch (e) {
		projectImportFailureEvent(mixpanel, source, e.message);
		const addressRegexp = /^0x[a-fA-F0-9]{40}$/;
		if (addressRegexp.test(source)) {
			addErrorNotification(
				"Error",
				"Error importing project. Please ensure you have selected the correct nework."
			);
		} else {
			addErrorNotification("Error", e.message);
		}
	}
}

export async function renameProjectById(
	projectId: string,
	newName: string
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/editProjectName/${projectId}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify({ newName })
		});

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error renaming project: " + e.message);
		return e;
	}
}

export async function deleteProjectById(projectId: string): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/deleteProject/${projectId}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "DELETE",
			credentials: "include"
		});

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error deleting project: " + e.message);
		return e;
	}
}

const projectCache = new Map<string, Project>();

export async function loadProject(projectID): Promise<Project> {
	try {
		const url =
			SERVER_URL +
			"/loadProject?" +
			new URLSearchParams({
				projectID: projectID,
				csrfToken: await getCsrfToken()
			});

		const res = await fetch401Interceptor(url, { credentials: "include" });
		const resText = await res.text();

		if (res.ok) {
			let project = Project.deserialize(resText);
			projectCache.set(projectID, project);
			return project;
		} else throw new Error(resText);
	} catch (e) {
		addErrorNotification("Error", "Error loading project: " + e.message);
	}
}

export async function loadSampleProject(): Promise<void> {
	try {
		const url =
			SERVER_URL +
			"/loadSampleProject?" +
			new URLSearchParams({
				csrfToken: await getCsrfToken()
			});

		const res = await fetch401Interceptor(url, { credentials: "include" });
		if (res.ok) {
			return;
		} else {
			const resText = await res.text();
			throw new Error(resText);
		}
	} catch (e) {
		addErrorNotification("Error", "Error loading sample project: " + e.message);
	}
}

export async function getCallableValues(projectID): Promise<any[]> {
	try {
		const url =
			SERVER_URL +
			"/getCallableValues?" +
			new URLSearchParams({
				projectID: projectID,
				csrfToken: await getCsrfToken()
			});

		const res = await fetch401Interceptor(url, { credentials: "include" });
		if (res.ok) {
			return await res.json();
		} else {
			const resText = await res.text();
			throw new Error(resText);
		}
	} catch (e) {
		// Don't notify the user about this failure, OK to fail in background
		// console.log(`Error getting callable values for smart contract ${e}`);
	}
}

export const fetchByKeysAsync = async (keys) => {
	return new Promise((resolve, reject) => {
		// post a message to the worker to fetch values by keys
		FileHydrationWorker.postMessage({
			command: "bulkFetch",
			keys: keys
		});

		// set up the event listener for the worker's response
		const listener = (event) => {
			if (event.data.success) {
				resolve(event.data.values);
			} else {
				reject(new Error("Failed to fetch data"));
			}

			// remove this listener since we're done with it
			FileHydrationWorker.removeEventListener("message", listener);
		};

		FileHydrationWorker.addEventListener("message", listener);
	});
};

// Function to fetch data by key using the worker and await its result
export const fetchByKeyAsync = (key) => {
	return new Promise((resolve, reject) => {
		// Listener for fetching a single key
		const fetchListener = (event) => {
			if (event.data.value && event.data.value.key === key) {
				// Remove this event listener once the fetch is complete
				FileHydrationWorker.removeEventListener("message", fetchListener);

				// Resolve the promise with the fetched value or undefined
				resolve(event.data.value.value);
			} else if (!event.data.success) {
				// Handle an error case
				reject(new Error("Fetch failed"));
			} else {
				resolve(null);
			}
		};

		FileHydrationWorker.addEventListener("message", fetchListener);
		FileHydrationWorker.postMessage({ command: "fetch", key });
	});
};

export async function loadProjectFile(
	projectFileID,
	project: Project
): Promise<ProjectFile> {
	try {
		// Try to grab from local storage first
		// Load project file and begin hydration if file is not in local storage
		const projectFile = await fetchByKeyAsync(projectFileID);
		const globalState = GlobalState.getInstance();
		if (projectFile) {
			return projectFile as ProjectFile;
		} else {
			if (
				!globalState.getHydratingProject()
			) {
				console.log("Beginning hydration");
				hydrateProject(project.id, (text, done) => {
					if (text) {
						const textParts = text?.split("$$delimeter$$") ?? [];
						const filesFromParts = textParts.map((part) => {
							try {
								if (part) {
									const projectFileJson = JSON.parse(part);
									return new ProjectFile(
										projectFileJson.name,
										projectFileJson.contents,
										projectFileJson._id,
										projectFileJson.ast
									);
								}
								return null;
							} catch (e) {
								return null;
							}
						});
						const fileFromPartsNullFilter = filesFromParts.filter(
							(file: ProjectFile) => !!file
						);
						const keyValuePairs = fileFromPartsNullFilter.map(
							(file: ProjectFile) => {
								return { key: file.id, value: file };
							}
						);
						FileHydrationWorker.postMessage({ command: "save", keyValuePairs });
					}
					if (done) {
						globalState.setHydratingProject(false);
						console.log("Project hydration complete");
					}
				});
			}
		}

		const url =
			SERVER_URL +
			"/loadProjectFile?" +
			new URLSearchParams({
				projectFileID,
				projectID: project.id,
				csrfToken: await getCsrfToken()
			});

		const res = await fetch401Interceptor(url, { credentials: "include" });

		if (res.ok) {
			const pf = await res.json();
			const projectFile = new ProjectFile(pf.name, pf.contents, pf._id, pf.ast);
			const keyValuePairs = [{ key: projectFile.id, value: projectFile }];
			FileHydrationWorker.postMessage({ command: "save", keyValuePairs });
			return projectFile;
		} else throw new Error(res.statusText);
	} catch (e) {
		addErrorNotification("Error", "Error loading project file: " + e.message);
	}
}

export async function loadProjectImport(
	projectImportID,
	project: Project
): Promise<ProjectImport> {
	try {
		const url =
			SERVER_URL +
			"/loadProjectImport?" +
			new URLSearchParams({
				projectImportID,
				projectID: project.id,
				csrfToken: await getCsrfToken()
			});

		const res = await fetch401Interceptor(url, { credentials: "include" });

		if (res.ok) {
			const pi = await res.json();
			return new ProjectImport(
				pi.source,
				pi.contents,
				pi._id,
				pi.origin,
				pi.type,
				pi.ast
			);
		} else throw new Error(res.statusText);
	} catch (e) {
		addErrorNotification("Error", "Error loading project file: " + e.message);
	}
}

export async function loadProjectNotes(projectID): Promise<ProjectNote[]> {
	try {
		const url =
			SERVER_URL +
			"/loadProjectNotes?" +
			new URLSearchParams({
				projectID: projectID,
				csrfToken: await getCsrfToken()
			});

		const res = await fetch401Interceptor(url, { credentials: "include" });
		const resText = await res.text();

		if (res.ok) {
			if (resText) return decryptProjectNotes(JSON.parse(resText));
			else return [];
		} else throw new Error(resText);
	} catch (e) {
		addErrorNotification("Error", "Error loading project notes: " + e.message);
	}
}

export async function loadUserTags(): Promise<Tag[]> {
	try {
		const url =
			SERVER_URL +
			`/tag?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, { credentials: "include" });
		const resText = await res.text();

		if (res.ok) {
			if (resText) return JSON.parse(resText);
			else return [];
		} else throw new Error(resText);
	} catch (e) {
		// addErrorNotification("Error", "Error loading custom tags: " + e.message);
		console.log(e);
	}
}

export async function deleteTagById(tagID: string): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/tag/${tagID}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "DELETE",
			credentials: "include"
		});

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error deleting tag: " + e.message);
	}
}

export async function createTag(tag: Tag): Promise<string> {
	try {
		const url =
			SERVER_URL +
			`/tag?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify(tag)
		});

		if (!res.ok) throw new Error(await res.text());

		const resText = await res.text();
		return resText.replace(/"/g, "");
	} catch (e) {
		addErrorNotification("Error", "Error creating tag: " + e.message);
	}
}

export async function loadProjectFindings(projectID): Promise<Finding[]> {
	try {
		const url =
			SERVER_URL +
			`/finding/${projectID}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, { credentials: "include" });
		const resText = await res.text();

		if (res.ok) {
			if (resText) {
				const findings = JSON.parse(resText) as Finding[];
				return findings.map((finding) => {
					return decryptFinding(finding);
				});
			} else return [];
		} else throw new Error(resText);
	} catch (e) {
		addErrorNotification(
			"Error",
			"Error loading project findings: " + e.message
		);
	}
}

export async function deleteProjectFindingById(
	findingID: string
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/finding/${findingID}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "DELETE",
			credentials: "include"
		});

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification(
			"Error",
			"Error deleting project finding: " + e.message
		);
	}
}

export async function createFinding(
	finding: Finding,
	mixpanel,
	privateMode: boolean
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/finding?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify(privateMode ? encryptFinding(finding) : finding)
		});

		if (!res.ok) throw new Error(await res.text());
		findingAddedEvent(mixpanel);
	} catch (e) {
		addErrorNotification("Error", "Error creating finding: " + e.message);
	}
}

export async function updateFinding(
	finding: Finding,
	privateMode: boolean
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/finding/update?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify(privateMode ? encryptFinding(finding) : finding)
		});

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error updating finding: " + e.message);
	}
}

export async function loadProjectWhiteboards(projectID): Promise<Whiteboard[]> {
	try {
		const url =
			SERVER_URL +
			`/whiteboard/${projectID}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, { credentials: "include" });
		const resText = await res.text();

		if (res.ok) {
			if (resText) {
				const whiteboards = JSON.parse(resText) as Whiteboard[];
				return whiteboards.map((whiteboard) => {
					return decryptWhiteboard(whiteboard);
				});
			} else return [];
		} else throw new Error(resText);
	} catch (e) {
		addErrorNotification(
			"Error",
			"Error loading project whiteboards: " + e.message
		);
	}
}

export async function deleteProjectWhiteboardById(
	whiteboardID: string
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/whiteboard/${whiteboardID}?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "DELETE",
			credentials: "include"
		});

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification(
			"Error",
			"Error deleting project whiteboard: " + e.message
		);
	}
}

export async function createWhiteboard(
	whiteboard: Whiteboard,
	privateMode: boolean,
	mixpanel
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/whiteboard?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify(
				privateMode ? encryptWhiteboard(whiteboard) : whiteboard
			)
		});

		if (!res.ok) throw new Error(await res.text());
		whiteboardAddedEvent(mixpanel);
	} catch (e) {
		addErrorNotification("Error", "Error creating whiteboard: " + e.message);
	}
}

export async function updateWhiteboard(
	whiteboard: Whiteboard,
	privateMode: boolean
): Promise<void> {
	try {
		const url =
			SERVER_URL +
			`/whiteboard/update?` +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify(
				privateMode ? encryptWhiteboard(whiteboard) : whiteboard
			)
		});

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error updating whiteboard: " + e.message);
	}
}

export async function saveProjectNotes(
	projectID: string,
	data: any, // { title: string; location: string; noteContent: string }
	privateMode: boolean
) {
	try {
		const url =
			SERVER_URL +
			"/saveProjectNotes?" +
			new URLSearchParams({
				projectID: projectID,
				csrfToken: await getCsrfToken()
			});

		const res = await fetch401Interceptor(url, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify(privateMode ? encryptProjectNotes(data) : data)
		});

		if (!res.ok) throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error saving project notes: " + e.message);
	}
}

export async function chatWithAI(
	fileID,
	projectId,
	messages,
	randomness: number,
	uniqueness: number,
	brevity: number,
	mixpanel,
	updateCallback: Function,
	abortController: AbortController
) {
	try {
		const url =
			SERVER_URL +
			"/analyzeWithAI?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify({
				fileID,
				type: "chat",
				messages,
				randomness,
				uniqueness,
				brevity
			}),
			headers: { "Content-Type": "text/plain" },
			signal: abortController.signal
		});

		if (res.ok) {
			const decoder = new TextDecoder();
			const reader = res.body.getReader();
			let done = false;
			let responseText = "";

			while (!done) {
				let chunk = await reader.read();

				responseText += decoder.decode(chunk.value);
				done = chunk.done;
				updateCallback(responseText, done);
			}

			messageSentToAIEvent(mixpanel, { projectId, messages });
		} else throw new Error(await res.text());
	} catch (e) {
		aiScanFailureEvent(mixpanel, projectId, e.message);
		// addErrorNotification("Error", e.message);
		console.log(e.message);
	}
}

async function hydrateProject(projectID: string, updateCallback: Function) {
	const globalState = GlobalState.getInstance();
	globalState.setHydratingProject(true);

	const controller = new AbortController();
	const signal = controller.signal;

	try {
		const url =
			SERVER_URL +
			"/hydrateProjectFiles?" +
			new URLSearchParams({
				projectID,
				csrfToken: await getCsrfToken()
			});

		const res = await fetch401Interceptor(url, {
			credentials: "include",
			headers: { "Content-Type": "text/plain" },
			signal: signal
		});

		if (res.ok) {
			const decoder = new TextDecoder();
			const reader = res.body.getReader();
			let buffer = "";
			let done = false;

			const checkLocalStorageAndAbort = () => {
				if (!globalState.getHydratingProject()) {
					console.log("Hydration aborted due to flag.");
					controller.abort();
				}
			};

			const checkInterval = setInterval(checkLocalStorageAndAbort, 1000); // Check every second

			while (!done) {
				try {
					const { value, done: chunkDone } = await reader.read();
					done = chunkDone;
					if (value) {
						buffer += decoder.decode(value, { stream: true });

						let delimiterIndex;
						while ((delimiterIndex = buffer.indexOf("$$delimeter$$")) !== -1) {
							// Extract the complete part up to the delimiter
							const completePart = buffer.substring(0, delimiterIndex);
							buffer = buffer.substring(
								delimiterIndex + "$$delimeter$$".length
							); // Remove the processed part

							// Now process this complete part
							updateCallback(completePart, false); // false indicates this is not the final call
						}
					} else if (done) {
						updateCallback(undefined, true);
					}
				} catch (error) {
					if (error.name === "AbortError") {
						console.log("Stream reading aborted.");
						clearInterval(checkInterval);
						return;
					} else {
						throw error;
					}
				}
			}

			// After the loop, process any remaining part in the buffer
			if (buffer) {
				updateCallback(buffer, true); // true indicates this is the final part
			}

			clearInterval(checkInterval);
		} else {
			throw new Error(await res.text());
		}
	} catch (e) {
		console.log(`Project file hydration failed: ${e}`);
	} finally {
		globalState.setHydratingProject(false);
	}
}

export async function analyzeWithAI(
	fileID,
	type,
	projectId,
	mixpanel,
	isImport = "false",
	updateCallback: Function
) {
	try {
		const url =
			SERVER_URL +
			"/analyzeWithAI?" +
			new URLSearchParams({
				csrfToken: await getCsrfToken(),
				import: isImport
			});

		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify({ fileID: fileID, type: type }),
			headers: { "Content-Type": "text/plain" }
		});

		if (res.ok) {
			const decoder = new TextDecoder();
			const reader = res.body.getReader();
			let done = false;
			let responseText = "";
			while (!done) {
				let chunk = await reader.read();
				responseText += decoder.decode(chunk.value);
				done = chunk.done;
				updateCallback(responseText, done);
			}
			aiScanSuccessEvent(mixpanel, projectId);
		} else throw new Error(await res.text());
	} catch (e) {
		aiScanFailureEvent(mixpanel, projectId, e.message);
		addErrorNotification("Error", e.message);
	}
}

export async function rewriteWithAI(textToRewrite: string): Promise<string> {
	try {
		const url =
			SERVER_URL +
			"/rewriteWithAI?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify({ textToRewrite }),
			headers: { "Content-Type": "text/plain" }
		});

		if (res.ok) {
			const json = await res.json();
			return json.content;
		} else throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", e.message);
	}
}

export interface WriteVulnContext {
	code: string;
	lineStart: number;
	lineEnd: number;
	vulnerabilityTitle: string;
	vulnerabilityDescription?: string;
}

export async function writeVulnerabilityDescriptionWithAI(
	vulnContext: WriteVulnContext,
	updateCallback: Function
) {
	try {
		const url =
			SERVER_URL +
			"/writeVulnWithAI?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify(vulnContext)
		});

		if (res.ok) {
			const decoder = new TextDecoder();
			const reader = res.body.getReader();
			let done = false;
			let responseText = "";
			while (!done) {
				let chunk = await reader.read();
				responseText += decoder.decode(chunk.value);
				done = chunk.done;
				updateCallback(responseText, done);
			}
		} else throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", e.message);
	}
}

export async function writeGasOptimizationDescriptionWithAI(
	vulnContext: WriteVulnContext,
	updateCallback: Function
) {
	try {
		const url =
			SERVER_URL +
			"/writeGasOptimizationWithAI?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify(vulnContext)
		});

		if (res.ok) {
			const decoder = new TextDecoder();
			const reader = res.body.getReader();
			let done = false;
			let responseText = "";
			while (!done) {
				let chunk = await reader.read();
				responseText += decoder.decode(chunk.value);
				done = chunk.done;
				updateCallback(responseText, done);
			}
		} else throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", e.message);
	}
}

export async function writeRecommendationWithAI(
	vulnContext: WriteVulnContext,
	updateCallback: Function
) {
	try {
		const url =
			SERVER_URL +
			"/writeRecommendationWithAI?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify(vulnContext)
		});

		if (res.ok) {
			const decoder = new TextDecoder();
			const reader = res.body.getReader();
			let done = false;
			let responseText = "";
			while (!done) {
				let chunk = await reader.read();
				responseText += decoder.decode(chunk.value);
				done = chunk.done;
				updateCallback(responseText, done);
			}
		} else throw new Error(await res.text());
	} catch (e) {
		addErrorNotification(
			"Error",
			"Error writing recommendation with AI: " + e.message
		);
	}
}

export async function writePocWithAI(
	vulnContext: WriteVulnContext,
	updateCallback: Function
) {
	try {
		const url =
			SERVER_URL +
			"/writePocWithAI?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify(vulnContext)
		});

		if (res.ok) {
			const decoder = new TextDecoder();
			const reader = res.body.getReader();
			let done = false;
			let responseText = "";
			while (!done) {
				let chunk = await reader.read();
				responseText += decoder.decode(chunk.value);
				done = chunk.done;
				updateCallback(responseText, done);
			}
		} else throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error writing PoC with AI: " + e.message);
	}
}

export async function writePocWithAIFromFile(
	vulnContext: { prompt: string, fileId: string },
	updateCallback: Function
) {
	try {
		const url =
			SERVER_URL +
			"/writePocFromFileWithAI?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify(vulnContext)
		});

		if (res.ok) {
			const decoder = new TextDecoder();
			const reader = res.body.getReader();
			let done = false;
			let responseText = "";
			while (!done) {
				let chunk = await reader.read();
				responseText += decoder.decode(chunk.value);
				done = chunk.done;
				updateCallback(responseText, done);
			}
		} else throw new Error(await res.text());
	} catch (e) {
		addErrorNotification("Error", "Error writing PoC with AI: " + e.message);
	}
}

export async function graphFile(
	mixpanel,
	fileID,
	mode,
	isImport = "false"
): Promise<string> {
	try {
		const url =
			SERVER_URL +
			"/getGraphOfFile?" +
			new URLSearchParams({
				fileID: fileID,
				mode: mode,
				import: isImport,
				csrfToken: await getCsrfToken()
			});

		const res = await fetch401Interceptor(url, { credentials: "include" });
		const resText = await res.text();

		if (res.ok) {
			graphCreatedEvent(mixpanel, { fileID, mode, isImport });
			return resText;
		} else throw new Error(resText);
	} catch (e) {
		addErrorNotification("Error", "Error graphing file: " + e.message);
	}
}

export async function scanFileWithSlither(
	projectFilePath,
	projectID,
	includeSlitherin,
	mixpanel
): Promise<any> {
	const scanType = "slither"
	try {
		const url =
			SERVER_URL +
			"/scanSingleFile?" +
			new URLSearchParams({
				projectFilePath,
				projectID,
				scanType,
				includeSlitherin,
				csrfToken: await getCsrfToken()
			});

		const res = await fetch401Interceptor(url, { credentials: "include" });

		if (res.ok) {
			try {
				const res_json = await res.json();
				slitherScanSuccessEvent(mixpanel, projectID);
				return { response: res_json, error: null };
			} catch (err) {
				throw new Error("Error parsing response from the server.");
			}
		} else {
			try {
				const error_res_json = await res.json();
				let parsedError =
					"Unknown error occurred while processing the response.";
				if (error_res_json.error) parsedError = error_res_json.error;
				throw new Error(parsedError);
			} catch (err) {
				throw new Error(err);
			}
		}
	} catch (err) {
		slitherScanFailureEvent(mixpanel, projectID, err);
		return { response: null, error: err };
	}
}

export async function scanFileWithAderyn(
	projectFilePath,
	projectID,
	mixpanel
): Promise<any> {
	const scanType = "aderyn"
	try {
		const url =
			SERVER_URL +
			"/scanSingleFile?" +
			new URLSearchParams({
				projectFilePath,
				projectID,
				scanType,
				csrfToken: await getCsrfToken()
			});

		const res = await fetch401Interceptor(url, { credentials: "include" });

		if (res.ok) {
			try {
				const res_json = await res.json();
				aderynScanSuccessEvent(mixpanel, projectID);
				return { response: res_json, error: null };
			} catch (err) {
				throw new Error("Error parsing response from the server.");
			}
		} else {
			try {
				const error_res_json = await res.json();
				let parsedError =
					"Unknown error occurred while processing the response.";
				if (error_res_json.error) parsedError = error_res_json.error;
				throw new Error(parsedError);
			} catch (err) {
				throw new Error(err);
			}
		}
	} catch (err) {
		aderynScanFailureEvent(mixpanel, projectID, err);
		return { response: null, error: err };
	}
}

export async function scanFileWith4naly3er(
	projectFilePath,
	projectID,
	mixpanel
): Promise<any> {
	const scanType = "4naly3er"
	try {
		const url =
			SERVER_URL +
			"/scanSingleFile?" +
			new URLSearchParams({
				projectFilePath,
				projectID,
				scanType,
				csrfToken: await getCsrfToken()
			});

		const res = await fetch401Interceptor(url, { credentials: "include" });
		
		if (res.ok) {
			try {
				const res_json = await res.json();
				analyzerScanSuccessEvent(mixpanel, projectID);
				return { response: res_json, error: null };
			} catch (err)  {
				throw new Error('Error parsing response from the server.')
			}
		} else {
			try {
				const error_res_json = await res.json();
				let parsedError = 'Unknown error occurred while processing the response.'
				if (error_res_json.error) parsedError = error_res_json.error
				throw new Error(parsedError)
			} catch (err) {
				throw new Error(err);
			}
		}
	} catch (err) {
		analyzerScanFailureEvent(mixpanel, projectID, err);
		return { response: null, error: err };
	}
}

export async function getScanResultsForFile(
	projectFilePath,
	projectID
): Promise<any> {
	try {
		const url =
			SERVER_URL +
			"/getScanResultsForFile?" +
			new URLSearchParams({
				projectFilePath,
				projectID,
				csrfToken: await getCsrfToken()
			});

		const res = await fetch401Interceptor(url, { credentials: "include" });
		if (res.ok) {
			const res_json = await res.json()
			return res_json
		} else throw new Error(await res.text());
	} catch (e) {
		console.log(e)
		addErrorNotification("Error", e.message);
		throw new Error(e)
	}
}

export async function getStorageSlots(
	projectFilePath,
	projectID,
	mixpanel
): Promise<any> {
	try {
		const url =
			SERVER_URL +
			"/getContractStorage?" +
			new URLSearchParams({
				projectFilePath,
				projectID,
				csrfToken: await getCsrfToken()
			});

		const res = await fetch401Interceptor(url, { credentials: "include" });
		const resText = await res.text();

		if (resText.startsWith("Exception("))
			throw new Error(resText.split("'")[1]);

		if (res.ok) {
			getStorageSlotsSuccessEvent(mixpanel, projectID);
			return JSON.parse(resText);
		} else throw new Error(resText);
	} catch (e) {
		getStorageSlotsFailureEvent(mixpanel, projectID, e);
		// addErrorNotification("Error", e.message);
	}
}

export async function recordLogout(): Promise<void> {
	const url =
		SERVER_URL +
		"/logout?" +
		new URLSearchParams({
			csrfToken: await getCsrfToken()
		});

	await fetch401Interceptor(url, {
		method: "POST",
		credentials: "include"
	});
}

export async function authenticate(
	message,
	signature,
	mixpanel,
	referral,
	address
) {
	try {
		const url = SERVER_URL + "/auth";

		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify({ message, signature })
		});
		const resText = await res.text();

		if (res.ok) {
			authEvent(mixpanel, {
				address,
				referral,
				type: "ethAuth"
			});
		} else throw new Error(resText);
	} catch (e) {
		addErrorNotification("Error", "Error authenticating: " + e.message);
	}
}

export async function authWithGithub(accessCode, mixpanel, referral) {
	try {
		const url = SERVER_URL + "/loginWithGithub";

		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify({ accessCode })
		});

		if (res.ok) {
			const resJson = await res.json();
			authEvent(mixpanel, {
				referral,
				type: "githubAuth"
			});
			return resJson.accessToken;
		} else throw new Error();
	} catch (e) {
		addErrorNotification(
			"Error",
			"Error authenticating with Github. Email may already be in use."
		);
	}
}

export async function linkWithGithub(installationId = null): Promise<{
	accessToken: string;
	githubImage: string;
	githubUsername: string;
}> {
	try {
		const url = SERVER_URL + "/linkWithGithub?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });;

		const reqBody = installationId ? {
			installationId
		} : {};

		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify(reqBody)
		});

		if (res.ok) {
			const resJson = await res.json();
			localStorage.setItem(
				LOCALSTORAGE_GITHUB_CONNECTION,
				JSON.stringify(resJson)
			);
			return resJson;
		} else throw new Error();
	} catch (e) {
		addErrorNotification(
			"Error",
			"Error linking with Github. Please try again."
		);
	}
}

export async function authWithGoogle(accessToken, mixpanel, referral) {
	try {
		const url = SERVER_URL + "/loginWithGoogle";

		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify({ accessToken })
		});

		if (res.ok) {
			authEvent(mixpanel, {
				referral,
				type: "googleAuth"
			});
		} else throw new Error();
	} catch (e) {
		addErrorNotification(
			"Error",
			"Error authenticating with Google: " + e.message
		);
	}
}

export async function createAWAccount(
	username: string,
	password: string,
	email: string,
	referral: string,
	mixpanel: any
): Promise<void> {
	return new Promise(async (resolve, reject) => {
		try {
			const url = SERVER_URL + "/signup";

			const res = await fetch401Interceptor(url, {
				method: "POST",
				credentials: "include",
				body: JSON.stringify({ username, password, email })
			});
			if (res.ok) {
				authEvent(mixpanel, {
					username,
					email,
					referral,
					type: "emailPasswordSignUp"
				});
				resolve();
			} else throw new Error(await res.text());
		} catch (e) {
			addErrorNotification("Error", e.message);
			reject(e);
		}
	});
}

export async function resendVerification(email: string): Promise<void> {
	return new Promise(async (resolve, reject) => {
		try {
			const url = SERVER_URL + "/resendVerification";

			const res = await fetch401Interceptor(url, {
				method: "POST",
				credentials: "include",
				body: JSON.stringify({ email })
			});

			if (res.ok) {
				addSuccessNotification("Success", "Verification email resent");
				resolve();
			} else {
				throw new Error("Error resending verification email");
			}
		} catch (e) {
			addErrorNotification(
				"Error",
				"Error resending verification email: " + e.message
			);
			reject(e);
		}
	});
}

export async function verifyEmail(
	email: string,
	verificationCode: string
): Promise<void> {
	return new Promise(async (resolve, reject) => {
		try {
			const url = SERVER_URL + "/verifyEmail";

			const res = await fetch401Interceptor(url, {
				method: "POST",
				credentials: "include",
				body: JSON.stringify({ email, verificationCode })
			});

			if (res.ok) {
				resolve();
			} else {
				throw new Error("Error verifying email");
			}
		} catch (e) {
			addErrorNotification("Error", "Invalid verification code");
			reject(e);
		}
	});
}

export async function login(
	usernameOrEmail: string,
	password: string,
	mixpanel
): Promise<string> {
	return new Promise(async (resolve, reject) => {
		try {
			const url = SERVER_URL + "/login";

			const res = await fetch401Interceptor(url, {
				method: "POST",
				credentials: "include",
				body: JSON.stringify({ usernameOrEmail, password })
			});

			if (res.ok) {
				const responseJson = await res.json();
				authEvent(mixpanel, {
					email: usernameOrEmail,
					type: "emailPasswordSignIn"
				});

				resolve(responseJson.email);
			} else {
				throw new Error("Please check your credentials and try again");
			}
		} catch (e) {
			addErrorNotification("Error", "Error logging in: " + e.message);
			reject(e);
		}
	});
}

export async function deleteAccount(): Promise<void> {
	try {
		const url =
			SERVER_URL +
			"/settings/deleteAccount?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });
		const res = await fetch401Interceptor(url, {
			method: "DELETE",
			credentials: "include"
		});
		if (!res.ok) {
			throw new Error();
		}
	} catch (e) {
		addErrorNotification(
			"Error",
			"There was a problem deleting your account. Please try again."
		);
	}
}

export async function resetPrivateMode(): Promise<void> {
	try {
		const url =
			SERVER_URL +
			"/settings/resetPrivateMode?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });
		const res = await fetch401Interceptor(url, {
			method: "PUT",
			credentials: "include"
		});
		if (!res.ok) {
			throw new Error();
		} else {
			secureLocalStorage.removeItem("privateModeEncryptionKey");
		}
	} catch (e) {
		addErrorNotification(
			"Error",
			"There was a problem resetting private mode. Please try again."
		);
	}
}

export async function createPasswordChangeRequest(email: string) {
	try {
		const url = SERVER_URL + "/createPasswordResetRequest";
		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify({ email })
		});
		if (!res.ok) {
			throw new Error();
		} else {
			addSuccessNotification("Success", "Email verification sent.");
		}
	} catch (e) {
		addErrorNotification("Error", e.message);
		throw new Error();
	}
}

export async function changePassword(
	email: string,
	code: string,
	newPassword: string
) {
	try {
		const url = SERVER_URL + "/changePassword";
		const res = await fetch401Interceptor(url, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify({ email, code, newPassword })
		});
		const resText = await res.text();
		if (!res.ok) {
			throw new Error(resText);
		}
	} catch (e) {
		addErrorNotification("Error", e.message);
	}
}

// Ordering within folder is dictated by the order of the project IDs
// Note: this function will first clear out the folder to prevent ordering issues so make sure to pass every ID contained in the folder every time
export async function addProjectsToFolderOrReorderFolder(
	projectIds: string[],
	folderName: string,
) {
	try {
		const url =
			SERVER_URL +
			"/placeProjectsInFolder?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify({ projectIds, folderName })
		});

		if (!res.ok) throw new Error(res.statusText);
	} catch (e) {
		addErrorNotification("Error", e.message);
	}
}

export async function clearFolderForProjects(
	projectIds: string[],
) {
	try {
		const url =
			SERVER_URL +
			"/removeProjectsFromFolder?" +
			new URLSearchParams({ csrfToken: await getCsrfToken() });

		const res = await fetch401Interceptor(url, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify({ projects: projectIds })
		});

		if (!res.ok) throw new Error(res.statusText);
	} catch (e) {
		addErrorNotification("Error", e.message);
	}
}

export async function recordTwitterReferral(
	referralID: string,
) {
	try {
		const url =
			SERVER_URL +
			"/twitterReferral?" +
			new URLSearchParams({ referralID });

		const res = await fetch401Interceptor(url, {
			method: "PUT",
		});

		if (!res.ok) throw new Error(res.statusText);
	} catch (e) {
		// Swallow this error, not critical
		return;
	}
}

export async function recordGoogleReferral(
	referralID: string,
) {
	try {
		const url =
			SERVER_URL +
			"/googleReferral?" +
			new URLSearchParams({ referralID });

		const res = await fetch401Interceptor(url, {
			method: "PUT",
		});

		if (!res.ok) throw new Error(res.statusText);
	} catch (e) {
		// Swallow this error, not critical
		return;
	}
}