import { goToEditorLine } from "@auditware/CodeEditorCore/helpers";
import { useAtom } from "jotai";
import React, { useEffect, useMemo, useState } from "react";
import { editorScrollListenerAtom } from "store/atoms/EditorAtoms";
import { openedTabAtom } from "store/atoms/UiAtoms";
import { STORAGE_FILE_LINES } from "utils/constants";
import {
	getObjectFromLocalStorage,
	updateObjectInLocalStorage
} from "utils/helpersFunctions";

const ScrollToSavedLineAddon: React.FC<{ editorRef: any; monacoRef: any }> = ({
	editorRef,
	monacoRef
}) => {
	const [ready, setReady] = useState(false);

	const [{ projectFilePath }] = useAtom(openedTabAtom);

	const [scrollListener, setScrollListener] = useAtom(editorScrollListenerAtom);

	// Scroll to saved localstorage line when mounted
	useMemo(() => {
		setReady(false);

		const fileLines = getObjectFromLocalStorage(STORAGE_FILE_LINES);

		if (editorRef && projectFilePath && !!fileLines?.[projectFilePath]) {
			goToEditorLine(editorRef, fileLines[projectFilePath], null, true, false);

			setScrollListener({
				visibleRanges: [],
				scrollTop: fileLines[projectFilePath]
			});
		}

		setReady(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editorRef, projectFilePath]);

	// Update scroll position in local storage
	useEffect(() => {
		if (projectFilePath && scrollListener.scrollTop > -1 && ready) {
			updateObjectInLocalStorage(STORAGE_FILE_LINES, {
				[projectFilePath]: scrollListener.scrollTop || 0
			});
		}
	}, [projectFilePath, scrollListener, ready]);

	return null;
};

export default React.memo(ScrollToSavedLineAddon);
