import HomeIcon from "@mui/icons-material/Home";
import {
	Box,
	Stack,
	ToggleButton,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import { usernameAtom } from "atoms";
import LogInSignUp from "components/LogInSignUp";
import { useMediaQuery } from "hooks/ui/useMediaQuery";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { importsModeAtom } from "store/atoms/UiAtoms";
import { projectsAtom } from "store/atoms/projectToolAtoms";
import DraggableSidebar from "../DraggableSidebar";
import ProjectsList from "./ProjectsList";
import SelectCtf from "./SelectCtf";

export const PROJECT_SIDEBAR_WIDTH = 280;

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
	borderRadius: "8px",
	"&.Mui-selected, &.Mui-selected:hover": {
		backgroundColor: theme.palette.background.paper
	}
}));

const ImportsSidebar = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [projects] = useAtom(projectsAtom);
	const [username] = useAtom(usernameAtom);
	const [sidebarWidth, setSidebarWidth] = useState(280);
	const isLargeScreen = useMediaQuery("(min-width:1200px)");
	const [importsMode, setImportsMode] = useAtom(importsModeAtom);

	const handleTypeChange = (
		e: React.MouseEvent<HTMLElement>,
		val: "project" | "ctf"
	) => {
		if (val !== null) {
			setImportsMode(val);
		}
	};

	useEffect(() => {
		setSidebarWidth(280);
	}, [isLargeScreen]);

	const projectsLength = projects?.filter(
		(i) => i.name !== "CodeChallenge"
	)?.length;

	return (
		<DraggableSidebar
			width={sidebarWidth}
			setWidth={setSidebarWidth}
			paperSx={{ borderTopLeftRadius: "20px", bgcolor: "background.default" }}
			sx={{
				bgcolor: themeMode === "dark" ? "background.paper" : "primary.main"
			}}
			handleSx={{
				bgcolor: themeMode === "dark" ? "background.default" : "#E1E1E1"
			}}
		>
			<Stack
				spacing={1.8}
				sx={{
					flex: 1,
					p: 2,
					width: "100%",
					height: "100%",
					minWidth: 280,
					borderTopLeftRadius: "20px",
					position: "relative"
				}}
			>
				{/* Select project */}
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
					spacing={1}
					sx={{
						py: 1,
						px: 2,
						bgcolor: "background.selected",
						color: themeMode === "dark" ? "primary.main" : "text.primary",
						borderRadius: "8px",
						boxShadow: 1
					}}
				>
					<HomeIcon sx={{ fontSize: 20 }} />

					<Typography sx={{ fontWeight: 500 }}>
						{username ? `Projects (${projectsLength})` : "Home"}
					</Typography>
				</Stack>

				<Stack spacing={2} sx={{ overflow: "auto", flex: 1 }}>
					{importsMode === "project" && <ProjectsList />}

					{/* {importsMode === "project" && <SelectProject />} */}

					{!!username && importsMode === "ctf" && <SelectCtf />}
				</Stack>

				{!username && (
					<Box
						sx={{
							position: "absolute",
							bottom: "16px",
							left: 0,
							right: 0,
							width: "100%",
							px: 2
						}}
					>
						<LogInSignUp />
					</Box>
				)}
			</Stack>
		</DraggableSidebar>
	);
};

export default React.memo(ImportsSidebar);
