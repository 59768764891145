import { Box, Stack } from "@mui/material";
import { getCallableValues } from "Resources/ServerInterface";
import MainContentLayout from "components/MainContentLayout";
import ToolSelector from "components/ToolSelector";
import Submissions from "components/Toolbox/Submissions";
import { useProject } from "hooks/data/useProject";
import { useAtom, useSetAtom } from "jotai";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import {
	JumpToLineAtom,
	defaultEditorClickListener,
	defaultEditorScrollListener,
	defaultEditorSelection,
	defaultJumpToLine,
	editorClickListenerAtom,
	editorScrollListenerAtom,
	editorSelectionAtom
} from "store/atoms/EditorAtoms";
import { openedTabAtom, submissionModeAtom } from "store/atoms/UiAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import { NAVBAR_HEIGHT } from "../../Navbar";
import Toolbox from "../../Toolbox";
import ProjectSidebar from "../../sidebars/ProjectSidebar";
import SkeletonTemplate from "./SkeletonTemplate";

const GetToolComponent = () => {
	const [{ projectFilePath }] = useAtom(openedTabAtom);

	const [project] = useAtom(projectAtom);
	const [submissionMode] = useAtom(submissionModeAtom);

	const setClickListener = useSetAtom(editorClickListenerAtom);
	const setScrollListener = useSetAtom(editorScrollListenerAtom);
	const setSelectionListener = useSetAtom(editorSelectionAtom);
	const setJumpToEditorLine = useSetAtom(JumpToLineAtom);

	const resetEditorAtoms = () => {
		setClickListener?.(defaultEditorClickListener);
		setScrollListener?.(defaultEditorScrollListener);
		setSelectionListener?.(defaultEditorSelection);
		setJumpToEditorLine?.(defaultJumpToLine);
	};

	// Reset context when project has changed
	useEffect(() => {
		if (!project?.id) {
			resetEditorAtoms();
		}

		return () => {
			resetEditorAtoms();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project?.id, projectFilePath]);

	if (submissionMode) {
		return <Submissions />;
	}

	return (
		<Stack sx={{ flexGrow: 1, minHeight: 0, pt: 1.5 }} spacing={2}>
			<Stack direction="row" sx={{ flex: 1, minHeight: 0 }}>
				<Box
					sx={{
						flexGrow: 1,
						width: 0,
						overflowX: "auto",
						borderRadius: "10px",
						boxShadow: 1,
						mr: 1.5
					}}
				>
					{/* Code Box */}
					<MainContentLayout />
				</Box>

				{/* Toolbox drawer */}
				<Toolbox />

				<ToolSelector />
			</Stack>
		</Stack>
	);
};

const AuditToolLayout = () => {
	const { openProjectWithProjectId } = useProject();
	const [project] = useAtom(projectAtom);

	const navigate = useNavigate();
	const params = useParams();

	useEffect(() => {
		if (!params.projectId) navigate("/");
		else if (params.projectId !== project?.id) {
			try {
				getCallableValues(params.projectId)
					.then((callableValues) => {
						callableValues = callableValues as any[];
						openProjectWithProjectId(params.projectId, callableValues).then(
							(res) => {
								if (res !== params.projectId) {
									navigate("/");
								}
							}
						);
					})
					.catch(() => {
						openProjectWithProjectId(params.projectId).then((res) => {
							if (res !== params.projectId) {
								navigate("/");
							}
						});
					});
			} catch (error) {
				console.log(error);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project, params]);

	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		const surveyPermission = getObjectFromLocalStorage(SURVEY_DONT_ASK);
	// 		if (Object.keys(surveyPermission).length === 0) {
	// 			addSurveyNotification();
	// 		}
	// 	}, 120000);

	// 	return () => {
	// 		clearTimeout(timer); // Clear the timer if the component unmounts
	// 	};
	// }, []);

	if (!project?.id) return <SkeletonTemplate />;

	return (
		<>
			<Stack
				direction="row"
				sx={{
					flexGrow: 1,
					height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
					width: "100vw",
					bgcolor: "background.default"
				}}
			>
				{/* Sidebar */}
				<ProjectSidebar />

				{/* Main */}
				<Stack
					sx={{
						flexGrow: 1,
						pl: 2,
						pt: 0,
						// pr: 1.5,
						pb: 1.5,
						width: 0,
						minHeight: 0
					}}
				>
					<GetToolComponent />
				</Stack>
			</Stack>
		</>
	);
};

export default React.memo(AuditToolLayout);
