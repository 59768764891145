import { buttonClasses } from "@mui/base/Button";
import { Tab, tabClasses } from "@mui/base/Tab";
import { TabPanel } from "@mui/base/TabPanel";
import { Tabs } from "@mui/base/Tabs";
import { TabsList } from "@mui/base/TabsList";
import { Box, Card, Stack, ThemeProvider, Typography } from "@mui/material";
import { styled } from "@mui/system";
import darkTheme from "config/theme/dark";
import MarkdownReader from "components/MainContentLayout/MarkdownReader";

const _substring = (text = "", maxChars = 50) => {
	return text.length > maxChars ? text.substring(0, maxChars) + "..." : text;
};

const ScanWidget = ({ scans, handleClose }) => {
	return (
		<ThemeProvider theme={darkTheme}>
			<Tabs defaultValue={0} onClick={(e) => e.stopPropagation()}>
				{scans?.length > 1 && (
					<StyledTabsList>
						{scans.map((scan, i: number) => (
							<StyledTab value={i} key={i}>
								<Typography
									variant="body2"
									sx={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										display: "-webkit-box",
										WebkitBoxOrient: "vertical",
										WebkitLineClamp: 2,
										maxHeight: "3em"
									}}
								>
									{_substring(scan.data.check || "", 25)}
								</Typography>
							</StyledTab>
						))}
					</StyledTabsList>
				)}

				{scans.map((scan, i: number) => (
					<TabPanel key={i} value={i}>
						<Card
							onClick={(e) => e.stopPropagation()}
							sx={(theme) => ({
								bgcolor: "background.default",
								width: 350,
								border: `2px solid #51448C`,
								borderRadius: "8px",
								boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
								display: "flex",
								flexDirection: "column",
								p: 2,
								maxHeight: 300,
								overflowY: "auto"
							})}
						>
							<Stack spacing={1} sx={{ mb: 1.5 }}>
								<Stack direction="row" alignItems="center" spacing={0.7}>
									<Typography sx={{ fontWeight: 600 }}>
										Finding:
										<Typography component="span">
											{" "}
											{scan?.data?.check}
										</Typography>
									</Typography>
								</Stack>

								<Stack direction="row" alignItems="center" spacing={2}>
									<Typography sx={{ fontWeight: 600 }} variant="body2">
										Severity:
										<Typography component="span" variant="body2">
											{" "}
											{scan?.data?.impact || scan?.data?.severity}
										</Typography>
									</Typography>

									<Typography sx={{ fontWeight: 600 }} variant="body2">
										Confidence:
										<Typography component="span" variant="body2">
											{" "}
											{scan?.data?.confidence}
										</Typography>
									</Typography>
								</Stack>
							</Stack>

							<Stack spacing={1.5}>
								{scan?.data?.checkDescription && (
									<Stack spacing={0.7}>
										<Typography sx={{ fontWeight: 600 }} variant="body2">
											Description:
										</Typography>
										<Box
											sx={{
												bgcolor: "background.paper",
												p: 1.5,
												borderRadius: "8px"
											}}
										>
											<Typography
												variant="body2"
												sx={{ wordWrap: "break-word" }}
											>
												{scan?.data?.checkDescription}
											</Typography>
										</Box>
									</Stack>
								)}

								{scan?.data?.recommendation && (
									<Stack spacing={0.7}>
										<Typography sx={{ fontWeight: 600 }} variant="body2">
											Recommendation:
										</Typography>
										<Typography
												variant="body2"
												sx={{ wordWrap: "break-word" }}
											>
												<MarkdownReader
													value={scan?.data?.recommendation}
													sx={{
														bgcolor: "background.paper",
														p: 1.5,
														borderRadius: "8px"
													}}
												/>
										</Typography>
									</Stack>
								)}
							</Stack>
						</Card>
					</TabPanel>
				))}
			</Tabs>
		</ThemeProvider>
	);
};

export default ScanWidget;

const StyledTab = styled(Tab)(
	({ theme }) => `
	color: #fff;
	cursor: pointer;
	font-size: 0.875rem;
	font-weight: 500;
	background-color: transparent;
	width: 100%;
	padding: 6px 8px;
	margin: 6px;
	border: none;
	border-radius: 7px;
	display: flex;
	justify-content: center;
	width: 140px;
	overflow: hidden;

	&:hover {
		background-color: ${theme.palette.background["paper2"]};
	}

	&:focus {
		color: #fff;
		outline: 2px solid ${theme.palette.background.paper};
	}

	&.${tabClasses.selected} {
		background-color: ${theme.palette.background.paper};
		color: ${theme.palette.text.primary};
	}

	&.${buttonClasses.disabled} {
		opacity: 0.5;
		cursor: not-allowed;
	}
`
);

const StyledTabsList = styled(TabsList)(
	({ theme }) => `
	flex-wrap: wrap;
	min-width: 352px;
	max-width: 352px;
	background-color: ${theme.palette.background["nav"]};
	border-radius: 8px;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: space-between;
	justify-content: flex-start;
	`
);
