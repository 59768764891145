import { Icon } from "@iconify/react";
import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineSeparator
} from "@mui/lab";
import {
	Box,
	Skeleton,
	Stack,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import { getHomepageImprovements } from "Resources/FirebaseInterface";
import { formatDistance } from "date-fns";
import useFirebase from "hooks/useFirebase";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { homepageImprovementsAtom } from "store/atoms/FirebaseAtoms";
import hex from "utils/hexTransparency";

const StyledTimelineItem = styled(TimelineItem)({
	minHeight: 0,
	"&:before": {
		content: '""',
		flex: 1,
		display: "none"
	}
});

const LatestImprovements = () => {
	const theme = useTheme();

	const [fetched, setFetched] = useState(false);
	const [loading, setLoading] = useState(true);
	const [improvements, setImprovements] = useAtom(homepageImprovementsAtom);

	const { db } = useFirebase();

	useEffect(() => {
		if (db && !fetched) {
			getHomepageImprovements(db)
				.then((res) => setImprovements(res || []))
				.finally(() => setFetched(true));
		}
	}, [db, fetched]);

	useEffect(() => {
		if (!!improvements?.length) {
			setLoading(false);
		}
	}, [improvements]);

	const getFormatedTimestamp = (_time) => {
		try {
			return formatDistance(_time, new Date(), {
				addSuffix: true
			});
		} catch (error) {
			return "";
		}
	};

	return (
		<Stack
			sx={{
				p: 2,
				borderRadius: "10px",
				flex: 1,
				height: "100%",
				overflow: "hidden",
				width: "100%",
				bgcolor: "background.default"
			}}
		>
			<Stack direction="row" spacing={1} alignItems="center">
				<Icon icon="ic:baseline-insights" fontSize={20} />
				<Typography>Latest improvements</Typography>
			</Stack>

			{loading && (
				<Stack spacing={2} sx={{ mt: 2 }}>
					{Array(5)
						.fill("")
						.map((_, i) => (
							<Skeleton
								key={i}
								height={75}
								variant="rectangular"
								sx={{ borderRadius: "8px" }}
							/>
						))}
				</Stack>
			)}

			{!loading && (
				<Box sx={{ overflow: "auto" }}>
					<Timeline sx={{ px: 0 }}>
						{improvements?.map?.((item, i) => (
							<StyledTimelineItem key={i}>
								<TimelineSeparator>
									<TimelineDot
										sx={{
											bgcolor: `${theme.palette.primary.main}${hex["40%"]}`,
											my: 0,
											mt: i === 0 && 0.5
										}}
									/>
									{i < improvements.length - 1 && (
										<TimelineConnector
											sx={{
												bgcolor: `${theme.palette.primary.main}${hex["40%"]}`
											}}
										/>
									)}
								</TimelineSeparator>

								<TimelineContent
									sx={{
										flex: 1,
										pt: 0,
										pb: i < improvements.length - 1 && 2,
										mt: i > 0 && -0.5
									}}
								>
									<Stack>
										<Typography variant="body2" sx={{ color: "text.disabled" }}>
											{getFormatedTimestamp(item?.timestamp)}
										</Typography>
										<Typography>{item.title}</Typography>
									</Stack>
								</TimelineContent>
							</StyledTimelineItem>
						))}
					</Timeline>
				</Box>
			)}
		</Stack>
	);
};

export default React.memo(LatestImprovements);
