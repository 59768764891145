export const computeFilters = (
	functions,
	searchText,
	selectedVisibilities,
	selectedTypes,
	selectedMutabilities
) => {
	// Convert selected options to sets for easier lookup
	const visibilitySet = new Set(selectedVisibilities.map((v) => v.name));
	const typeSet = new Set(selectedTypes.map((t) => t.name));
	const mutabilitySet = new Set(selectedMutabilities.map((t) => t.name));

	return functions.filter((item) => {
		// Filter based on searchText
		const matchesSearchText = item.codeString
			.toLowerCase()
			.includes(searchText.toLowerCase());

		// Filter based on selected visibilities
		const matchesVisibility =
			visibilitySet.size === 0 || visibilitySet.has(item.visibility);

		// Filter based on selected types
		const matchesType = typeSet.size === 0 || typeSet.has(item.type);

		// Filter based on selected types
		const matchesMutabilities =
			mutabilitySet.size === 0 || mutabilitySet.has(item.stateMutability);

		// Return true if all filters match
		return (
			matchesSearchText &&
			matchesVisibility &&
			matchesType &&
			matchesMutabilities
		);
	});
};
