import { atom } from "jotai";

interface EditorSelectionType {
	startLineNumber: number;
	endLineNumber: number;
	startColumn: number;
	endColumn: number;
	selectedText?: string;
	filePath?: string;
	[key: string]: any;
}

interface JumpToLineType {
	startLineNumber: number;
	endLineNumber?: number;
}

interface EditorClickType {
	clickType: any;
	offset: any;
	lineNumber: number;
	column: number;
	[key: string]: any;
}

interface EditorScrollType {
	scrollTop: number;
	visibleRanges: any[];
}

interface EditorHoverType {
	event: any;
	target: any;
}

// Editor Selection
export const defaultEditorSelection = {
	startLineNumber: 0,
	endLineNumber: 0,
	startColumn: 0,
	endColumn: 0,
	selectedText: ""
};
export const editorSelectionAtom = atom<EditorSelectionType>(
	defaultEditorSelection
);

// Click Listener
export const defaultEditorClickListener = {
	clickType: -1,
	offset: null,
	lineNumber: 0,
	column: 0
};
export const editorClickListenerAtom = atom<EditorClickType>(
	defaultEditorClickListener
);

// Scroll Listener
export const defaultEditorScrollListener = {
	scrollTop: 0,
	visibleRanges: []
};
export const editorScrollListenerAtom = atom<EditorScrollType>(
	defaultEditorScrollListener
);

// Hover Listener
export const defaultEditorHoverListener = {
	event: {},
	target: {}
};
export const editorHoverListenerAtom = atom<EditorHoverType>(
	defaultEditorHoverListener
);

// Jump To Line
export const defaultJumpToLine = {
	startLineNumber: -1,
	endLineNumber: -1
};
export const JumpToLineAtom = atom<JumpToLineType>(defaultJumpToLine);
