import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import "@toast-ui/editor/dist/theme/toastui-editor-dark.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import { useNotes } from "hooks/data/useNotes";
import React, { useState } from "react";
import { ToolboxCloseButton } from "../..";
import AddNoteForm, { AddNoteFormType } from "../../../forms/AddNoteForm";
import { useAtom } from "jotai";
import { mixpanelAtom } from "atoms";
import { noteAddedFromToolEvent } from "Mixpanel/mixpanel.helper";

interface Props {
	editMode?: boolean;
	setMode: (val: string) => void;
	values?: any;
	index?: number;
}

const AddNote: React.FC<Props> = ({ setMode, editMode, values, index }) => {
	const [loading, setLoading] = useState(false);
	const [mixpanel] = useAtom(mixpanelAtom);

	const { addNote, editNote } = useNotes();

	const backToNotes = () => {
		setMode("notes");
	};

	const handleAddNote = async (data: AddNoteFormType) => {
		const { title, noteContent, location, tag, tagColor } = data;

		setLoading(true);

		await addNote({
			title,
			location,
			noteContent,
			tag,
			tagColor
		});

		noteAddedFromToolEvent(mixpanel, {
			title,
			location,
			noteContent
		});

		setLoading(false);

		setMode("notes");
	};

	const handleEditNote = async (data: AddNoteFormType) => {
		const { title, noteContent, location, tag, tagColor } = data;

		setLoading(true);

		await editNote(
			{
				title,
				location,
				tag,
				tagColor,
				noteContent
			},
			index
		);

		setLoading(false);

		setMode("notes");
	};

	return (
		<>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ mb: 2 }}
			>
				<IconButton onClick={backToNotes}>
					<ArrowBackIcon fontSize="small" />
				</IconButton>

				<Typography>Back to notes</Typography>

				<Box sx={{ flex: 1 }} />

				<ToolboxCloseButton />
			</Stack>

			<AddNoteForm
				handleSubmit={editMode ? handleEditNote : handleAddNote}
				disabled={loading}
				edit={editMode}
				values={values}
			/>
		</>
	);
};

export default AddNote;
