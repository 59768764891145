import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { LoadingButton } from "@mui/lab";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	Switch,
	SwitchProps,
	Typography,
	styled,
	useTheme
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
	FieldValues,
	Resolver,
	useFieldArray,
	useForm,
	useWatch
} from "react-hook-form";
import * as Yup from "yup";
import FormTextField from "../../formFields/FormTextField";
import { useAtom } from "jotai";
import { openedTabAtom } from "store/atoms/UiAtoms";
import {
	CfgContract,
	getFunctionsFromSolidity
} from "Resources/SolidityParser";
import FormSelectField from "components/formFields/FormSelectField";
import { getValueFromObjPath } from "utils/helpersFunctions";
import { projectAtom } from "store/atoms/projectToolAtoms";
import { simulatingAtom } from "store/atoms/simulationAtoms";
import GlobalState from "utils/globals";

const IOSSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 34,
	height: 18,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		color: theme.palette.primary.dark,
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: theme.palette.primary.main,
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.background["nav"],
				opacity: 1,
				border: 0
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5
			}
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff"
		}
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 14,
		height: 14
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: "transparent",
		border: "1px solid",
		borderColor: theme.palette.divider,
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500
		})
	}
}));

interface SimulationFormType {
	network: string;
	from: string;
	to: string;
	functionName: string;
	params: unknown[];
	value?: number;
	blockNumber?: number;
	gas?: number;
}

export interface SimulationFormTypeWithParamTypes extends SimulationFormType {
	paramTypes: string[];
}

interface Props {
	handleSubmit: (data: SimulationFormTypeWithParamTypes) => void;
	disabled?: boolean;
}

const schema = Yup.object().shape({
	network: Yup.string().required("Network is required"),
	from: Yup.string().required("From address is required"),
	to: Yup.string().required("To address is required"),
	functionName: Yup.string().required("Function Name is required"),
	params: Yup.array().test({
		test: function (value) {
			let okay = true;
			if (Array.isArray(value)) {
				value.forEach((param) => {
					if (param === "") {
						okay = false;
					}
				});
			} else {
				okay = false;
			}
			return okay;
		},
		message: "All params must be filled"
	}),
	value: Yup.number()
		.nullable()
		.transform((value, originalValue) =>
			originalValue?.trim() === "" ? null : value
		)
		.typeError("Value must be a number"),
	blockNumber: Yup.number()
		.nullable()
		.transform((value, originalValue) =>
			originalValue?.trim() === "" ? null : value
		)
		.typeError("Block number must be a number"),
	gas: Yup.number()
		.nullable()
		.transform((value, originalValue) =>
			originalValue?.trim() === "" ? null : value
		)
		.typeError("Gas must be a number")
});

const SimulationForm: React.FC<Props> = ({
	handleSubmit: propsHandleSubmit,
	disabled: PropsDisabled = false
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [disabled, setDisabled] = useState(PropsDisabled);
	const [project] = useAtom(projectAtom);

	const [advancedModes, setAdvancedModes] = useState([]);
	const [expanded, setExpanded] = useState([]);

	const [solidityFunctions, setSolidityFunctions] = useState([]);
	const [hydratingProject, setHydratingProject] = useState(
		GlobalState.getInstance().getHydratingProject()
	);

	const [{ openedFile }] = useAtom(openedTabAtom);
	const [simulating, setSimulating] = useAtom(simulatingAtom);

	useEffect(() => {
		setDisabled(PropsDisabled);
	}, [PropsDisabled]);

	const formOptions = {
		resolver: yupResolver(schema) as Resolver<SimulationFormType, FieldValues>,
		defaultValues: {
			network: project.sourceNetwork,
			params: [],
			value: null,
			blockNumber: null,
			gas: null,
			to: project.source
		}
	};

	const {
		handleSubmit,
		register,
		control,
		resetField,
		setValue,
		watch,
		formState: { errors }
	} = useForm<FieldValues & SimulationFormType & any>(formOptions);

	const { fields, append, remove } = useFieldArray({
		control,
		name: "params"
	});

	const handleAdvanceClick = (
		e: React.ChangeEvent<HTMLInputElement>,
		index: number
	): void => {
		e.stopPropagation();

		const indexExists = advancedModes.includes(index);

		if (indexExists) {
			const updatedModes = advancedModes.filter(
				(modeIndex) => modeIndex !== index
			);
			setAdvancedModes(updatedModes);
		} else {
			setAdvancedModes([...advancedModes, index]);
		}
	};

	const toggleExpanded = (index: number) => {
		const indexExists = expanded.includes(index);

		if (indexExists) {
			const updatedModes = expanded.filter((modeIndex) => modeIndex !== index);
			setExpanded(updatedModes);
		} else {
			setExpanded([...expanded, index]);
		}
	};

	useEffect(() => {
		const globalState = GlobalState.getInstance();

		const handleHydrateChange = (project) => {
			setHydratingProject(project);
		};

		globalState.on("hydrateChange", handleHydrateChange);

		return () => {
			globalState.off("hydrateChange", handleHydrateChange);
		};
	}, []);

	useEffect(() => {
		const addressRegexp = /^0x[a-fA-F0-9]{40}$/;

		if (addressRegexp.test(project.source)) {
			if (hydratingProject) {
				setSolidityFunctions([]);
			} else {
				project.buildCFG().then((cfg) => {
					let _temp = cfg.getFunctions();
					_temp = _temp
						.filter((func) => !func.isConstructor)
						.sort((a, b) => {
							const contractComparison =
								a?.parentContract?.node.name.localeCompare(
									b?.parentContract?.node.name
								);
							if (contractComparison !== 0) return contractComparison;
							return a.name?.localeCompare(b.name);
						});
					setSolidityFunctions(_temp);
				});
			}
		}
	}, [openedFile, hydratingProject]);

	const allFields = watch();

	const functionName = watch("functionName");
	const selectedFunction = useMemo(() => {
		if (!functionName) return null;
		return solidityFunctions.find((func) => func.name === functionName);
	}, [functionName, solidityFunctions]);

	const onSubmit = (data: SimulationFormType) => {
		setSimulating(true);
		const functionParamTypes: string[] = [];
		selectedFunction.parameters.map((param) => {
			const paramTypeName: any = param.typeName;
			functionParamTypes.push(paramTypeName.name);
		});

		propsHandleSubmit?.({
			...data,
			paramTypes: functionParamTypes
		});
	};

	useEffect(() => {
		// Reset and reinitialize the params field array whenever the selected function changes
		remove(); // Remove all existing param fields first
		if (selectedFunction && selectedFunction.parameters.length > 0) {
			selectedFunction.parameters.forEach(() => append("")); // Append a field for each parameter
		}
	}, [selectedFunction, append, remove]);

	useEffect(() => {
		if (fields.length === 0) append(null);
	}, [fields]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%" }}>
			<Stack spacing={3} sx={{ height: "100%" }}>
				{/* List */}
				<Stack spacing={0.5}>
					{fields.map((field, i) => {
						const isAdvancedMode = advancedModes.includes(i);
						const isExpanded = true; // expanded.includes(i);

						const _fieldName = {
							functionName: `functionName`
						};

						let _selectedName = getValueFromObjPath(
							allFields,
							_fieldName.functionName
						);
						if (_selectedName) {
							_selectedName = _selectedName;
						}

						const selectedFunction = solidityFunctions.find(
							(i) => i.name === _selectedName
						);

						return (
							<React.Fragment key={field.id}>
								<Accordion
									defaultExpanded={i === fields.length - 1}
									// expanded={isExpanded}
									// onChange={(e, v) => toggleExpanded(i)}
									expanded
									disableGutters
									sx={{
										width: "100%",
										borderRadius: "8px !important",
										backgroundImage: "none",
										bgcolor: "background.paper",
										border: "1px solid",
										borderColor: isExpanded
											? "background.nav"
											: "background.nav",
										"&.MuiAccordion-root:before": {
											bgcolor: "transparent"
										}
									}}
								>
									{/* Header */}
									<AccordionSummary
										// expandIcon={<ArrowDropDownIcon />}
										sx={{
											px: 1.5,
											minHeight: 42,
											height: 42,
											"&:hover": {
												"& .showOnHover": {
													display: "inline-flex"
												}
											}
										}}
									>
										<Stack
											direction="row"
											alignItems="center"
											justifyContent="space-between"
											spacing={1}
											sx={{ width: "100%", pr: 1 }}
										>
											<Typography>Build Transaction</Typography>

											<Stack direction="row" alignItems="center" spacing={1}>
												{/* <IconButton
													onClick={() => remove(i)}
													size="small"
													className="showOnHover"
													sx={{
														display: "none",
														color: "text.secondary",
														"&:hover": { color: "text.primary" }
													}}
												>
													<DeleteForeverOutlinedIcon sx={{ fontSize: 16 }} />
												</IconButton> */}

												<IOSSwitch
													checked={isAdvancedMode}
													onClick={(e) => {
														e.stopPropagation();
													}}
													onChange={(e) => handleAdvanceClick(e, i)}
												/>

												<Typography
													color={
														isAdvancedMode ? "text.primary" : "text.secondary"
													}
													variant="body2"
													sx={{ ml: 1 }}
												>
													Advanced
												</Typography>
											</Stack>
										</Stack>
									</AccordionSummary>

									{/* Form */}
									<AccordionDetails sx={{ px: 1.5, pt: 1 }}>
										<Stack spacing={1.5}>
											<FormTextField
												fullWidth
												disabled={disabled}
												required
												name={`network`}
												label={"Network"}
												value={project.sourceNetwork}
												register={register}
												error={!!errors[`network`]}
												helperText={errors[`network`]?.message}
												InputProps={{ disabled: true }}
											/>

											<FormTextField
												fullWidth
												disabled={disabled}
												required
												name={`from`}
												label={"From Address"}
												register={register}
												error={!!errors[`from`]}
												helperText={errors[`from`]?.message}
											/>

											<FormTextField
												fullWidth
												disabled={disabled}
												required
												name={`to`}
												label={"To Address"}
												value={project.source}
												register={register}
												error={!!errors[`to`]}
												helperText={errors[`to`]?.message}
												InputProps={{ disabled: true }}
											/>

											<FormSelectField
												fullWidth
												disabled={disabled}
												required
												name={"functionName"}
												control={control}
												label="Function Name"
												placeholder="Function Name"
												error={!!errors[_fieldName.functionName]}
												onChange={(e) => {
													setValue("params", []);
													setValue("functionName", e.target.value);
												}}
												helperText={
													errors[_fieldName.functionName]?.message &&
													String(errors[_fieldName.functionName]?.message)
												}
												items={solidityFunctions.map((i) => ({
													value: i.name ?? "",
													prefix: (i.parentContract as CfgContract)?.node.name,
													name: "." + (i.name ?? "") + "()",
													Action: (
														<Typography
															variant="body2"
															color="text.secondary"
															sx={{ fontStyle: "italic" }}
														>
															{i.mutability || ""}
														</Typography>
													)
												}))}
											/>

											{selectedFunction?.parameters.map((param: any, index) => (
												<FormTextField
													key={field.id}
													fullWidth
													disabled={disabled}
													required
													name={`params.${index}`}
													label={
														`${param.name} (Type: ${
															param.typeName?.name ?? "Encoded JSON"
														})` || "Parameter"
													}
													register={register}
													error={!!errors["params"]}
													helperText={errors["params"]?.message || ""}
													variant="outlined"
												/>
											))}

											<FormTextField
												fullWidth
												disabled={disabled}
												required
												name={`value`}
												label={"Value (wei)"}
												type="number"
												register={register}
												error={!!errors[`value`]}
												helperText={errors[`value`]?.message}
											/>

											{isAdvancedMode && (
												<Stack direction="row" spacing={1}>
													<FormTextField
														fullWidth
														disabled={disabled}
														required
														name={`blockNumber`}
														type="number"
														label={"Block Number"}
														register={register}
														error={!!errors[`blockNumber`]}
														helperText={errors[`blockNumber`]?.message}
													/>
												</Stack>
											)}
										</Stack>
									</AccordionDetails>
								</Accordion>

								{false && i < 3 && (
									<Divider
										orientation="vertical"
										sx={{
											height: 15,
											width: 2,
											bgcolor: "background.nav",
											alignSelf: "center"
										}}
									/>
								)}
							</React.Fragment>
						);
					})}

					{false && fields.length < 4 && (
						<Button
							onClick={() => {
								append(null);
								setExpanded([...expanded, fields.length]);
							}}
							variant="outlined"
							sx={{
								bgcolor: "background.paper",
								borderColor: "divider",
								color: "text.primary",
								borderRadius: "8px",
								height: 40
							}}
						>
							<AddIcon fontSize="small" sx={{ mr: 0.5 }} />
							Add new transaction
						</Button>
					)}
				</Stack>

				<Box sx={{ flex: 1 }} />

				<LoadingButton
					type="submit"
					variant="contained"
					loading={simulating}
					sx={{ height: 40, borderRadius: "8px" }}
				>
					<PlayArrowIcon sx={{ mr: 0.5 }} />
					Simulate transaction
				</LoadingButton>
			</Stack>
		</form>
	);
};

export default React.memo(SimulationForm);
