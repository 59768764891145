import {
	TripOrigin as CircleIcon,
	PendingOutlined as OtherIcon,
	CropSquare as SquareIcon,
	ChangeHistory as TriangleIcon
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Card,
	Grid,
	Stack,
	Typography,
	useTheme
} from "@mui/material";
import ReactECharts from "echarts-for-react";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
	SlitherResultType,
    filteredToolResultsAtom,
} from "store/atoms/ScanAtoms";
import hex from "utils/hexTransparency";
import {
	ScanEntry,
	getToolResultsDividedBySeverity,
    sarifToFormattedResults
} from "./helpers";
import ClickablePathText from "components/ClickablePathText";
import { openedTabAtom } from "store/atoms/UiAtoms";
import MarkdownReader from "components/MainContentLayout/MarkdownReader";

const IconColor = (theme: any, type: string) => {
	switch (type) {
		case "High":
			return { Icon: SquareIcon, color: theme.palette.error.main };
		case "Medium":
			return { Icon: TriangleIcon, color: theme.palette.warning.main };
		case "Low":
			return { Icon: CircleIcon, color: theme.palette.success.main };
		default:
			return { Icon: OtherIcon, color: theme.palette.info.main };
	}
};

const getSeverity = (sev) => {
	const severity = !["High", "Medium", "Low"].includes(sev) ? "Other" : sev;

	return severity;
};

export const ToolScanPieChart: React.FC<{
	data: any;
	withCheckboxFilter?: boolean;
	filter?: object;
	setFilter?: any;
	onlyChart?: boolean;
}> = ({ data, withCheckboxFilter, filter, setFilter, onlyChart }) => {
	const theme = useTheme();

	const toggleChange = (name) => {
		if (filter && setFilter) {
			const severity = getSeverity(name);
			setFilter((old) => ({ ...old, [severity]: !old[severity] }));
		}
	};

	const LegendItem = ({ Icon, value, label, color }) => (
		<Stack
			direction="row"
			spacing={1}
			alignItems="center"
			sx={{
				px: 1.5,
				py: 1,
				bgcolor:
					withCheckboxFilter && !!filter?.[label]
						? `${theme.palette.primary.main}${hex["60%"]}`
						: "background.paper",
				borderRadius: "8px",
				cursor: withCheckboxFilter ? "pointer" : "default"
			}}
			onClick={() => toggleChange(label)}
		>
			<Icon sx={{ color }} />

			<Typography>
				<Typography component="span" sx={{ fontWeight: 600 }}>
					{value}
				</Typography>{" "}
				{label}
			</Typography>
		</Stack>
	);

	if (onlyChart) {
		return (
			<Box sx={{ height: 28, width: 28 }}>
				<ReactECharts
					style={{ height: "100%" }}
					option={{
						tooltip: {
							trigger: "item"
						},
						series: [
							{
								type: "pie",
								radius: ["60%", "90%"],
								avoidLabelOverlap: false,
								itemStyle: {
									borderRadius: 2,
									borderColor: theme.palette.background.default,
									borderWidth: 1
								},
								label: {
									show: false
								},
								labelLine: {
									show: false
								},
								data: data?.map?.((i) => ({
									name: i.name,
									value: i.value,
									itemStyle: { color: i.color }
								}))
							}
						]
					}}
				/>
			</Box>
		);
	}

	return (
		<Card sx={{ p: 2, borderRadius: "14px" }}>
			<Grid container spacing={2} alignItems="center">
				{/* Pie chart */}
				<Grid item xs={12} lg={6}>
					<ReactECharts
						style={{ height: 180 }}
						option={{
							tooltip: {
								trigger: "item"
							},
							series: [
								{
									type: "pie",
									radius: ["70%", "90%"],
									avoidLabelOverlap: false,
									itemStyle: {
										borderRadius: 10,
										borderColor: theme.palette.background.default,
										borderWidth: 2
									},
									label: {
										show: false
									},
									labelLine: {
										show: false
									},
									data: data?.map?.((i) => ({
										name: i.name,
										value: i.value,
										itemStyle: { color: i.color }
									}))
								}
							]
						}}
					/>
				</Grid>

				{/* Legend */}
				<Grid item xs={12} lg={6}>
					<Stack spacing={1}>
						{data?.map?.((item, i) => (
							<LegendItem
								key={i}
								Icon={item.Icon}
								value={item.value}
								label={item.name}
								color={item.color}
							/>
						))}
					</Stack>
				</Grid>
			</Grid>
		</Card>
	);
};

export const ToolScanResultItem: React.FC<
	(ScanEntry | SlitherResultType) & { Icon: any; color: string }
> = ({
	check,
	severity,
	confidence,
	entryDescription,
	checkDescription,
	recommendation,
	Icon,
	color
}) => (
	<Card
		sx={{
			borderRadius: "14px",
			"> div": {
				borderRadius: "inherit !important"
			}
		}}
		elevation={0}
	>
		<Accordion sx={{ m: 0 }}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Stack spacing={1}>
					<Stack direction="row" alignItems="center" spacing={0.7}>
						<Icon sx={{ color }} />

						<Typography sx={{ fontWeight: 600 }}>
							Finding:
							<Typography component="span"> {check}</Typography>
						</Typography>
					</Stack>

					<Stack direction="row" alignItems="center" spacing={2}>
						<Typography sx={{ fontWeight: 600 }} variant="body2">
							Severity:
							<Typography component="span" variant="body2">
								{" "}
								{severity}
							</Typography>
						</Typography>

						<Typography sx={{ fontWeight: 600 }} variant="body2">
							Confidence:
							<Typography component="span" variant="body2">
								{" "}
								{confidence}
							</Typography>
						</Typography>
					</Stack>
				</Stack>
			</AccordionSummary>

			<AccordionDetails sx={{ pt: 0 }}>
				<Stack spacing={1.5}>
					<Stack spacing={0.7}>
						<Typography sx={{ fontWeight: 600 }} variant="body2">
							Location:
						</Typography>

						<ClickablePathText text={entryDescription} variant="body2" />
					</Stack>

					{checkDescription && (
						<Stack>
							<Typography sx={{ fontWeight: 600 }} variant="body2">
								Description:
							</Typography>
							<Typography variant="body2" sx={{ wordWrap: "break-word" }}>
								<MarkdownReader
									value={checkDescription}
									sx={{
										bgcolor: "background.paper",
										p: 1.5,
										borderRadius: "8px"
									}}
								/>
							</Typography>
						</Stack>
					)}

					{recommendation && (
						<Stack>
							<Typography sx={{ fontWeight: 600 }} variant="body2">
								Recommendation:
							</Typography>
							<Typography variant="body2" sx={{ wordWrap: "break-word" }}>
								<MarkdownReader
									value={recommendation}
									sx={{
										bgcolor: "background.paper",
										p: 1.5,
										borderRadius: "8px"
									}}
								/>
							</Typography>
						</Stack>
					)}
				</Stack>
			</AccordionDetails>
		</Accordion>
	</Card>
);

const ToolScanResults: React.FC<{
	scanResults: object;
    toolName: string
}> = ({
	scanResults,
}) => {
	const theme = useTheme();

    const [{ openedTab }] = useAtom(openedTabAtom);

    const [resultsCountBySeverity, setResultsCountBySeverity] = useState([]);
	const [filter, setFilter] = useState({
		High: true,
		Medium: true,
		Low: true,
		Other: false
	});
    const [toolResultsData, setToolResultsData] = useState([])
    const [filteredToolResultsData, setFilteredToolResultsData] = useAtom(filteredToolResultsAtom)

    // Update results data
	useEffect(() => {
        const resultsDividedBySeverity = getToolResultsDividedBySeverity(
            scanResults,
            openedTab.path,
            theme
        );
        setResultsCountBySeverity(resultsDividedBySeverity);

        const formattedResults = sarifToFormattedResults(scanResults)
        setToolResultsData(formattedResults)

	}, [scanResults, theme]);

    // Filter findings based on piechart severity selections
	useEffect(() => {
		if (toolResultsData.length) {
			const _filtered = toolResultsData.filter(
				(item) => filter[getSeverity(item.severity)]
			);

			setFilteredToolResultsData(_filtered);
		}

		return () => {
			setFilteredToolResultsData([]);
		};
	}, [toolResultsData, filter, setFilteredToolResultsData]);

	return (
		<Box>
			<Stack spacing={2}>
				<ToolScanPieChart
					withCheckboxFilter
					filter={filter}
					setFilter={setFilter}
					data={resultsCountBySeverity}
				/>

				{filteredToolResultsData?.map?.((item: any, i) => (
					<ToolScanResultItem
						key={i}
                        check={item.check} // finding name
                        severity={item.severity}
                        confidence={item.confidence}
                        entryDescription={item.locations} // location string with parsable text (i.e. (file#line))
                        checkDescription={item.description} // description
                        recommendation={item.recommendation}
						{...IconColor(theme, item.severity)}
					/>
				))}
			</Stack>
		</Box>
	);
};

export default ToolScanResults;
