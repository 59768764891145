import Editor, { Monaco } from "@monaco-editor/react";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import {
	Box,
	Divider,
	IconButton,
	Stack,
	Tooltip,
	Typography,
	styled,
	tooltipClasses,
	useTheme
} from "@mui/material";
import { editor } from "monaco-editor";
import React, { useEffect } from "react";
import {
	addPlaceholderOnMount,
	createCustomTheme,
	editorDidMount,
	expandCode,
	foldCode,
	listenClickLocation,
	listenEditorSelection,
	listenHoverLocation,
	listenScrollPosition,
	removePlaceholderOnChange,
	zoomIn,
	zoomOut
} from "./helpers";
import { blinkLinesStyles, searchStyles } from "./styles";

const createMonacoTheme = (monaco, theme, monacoThemeColors) => {
	createCustomTheme(
		monaco,
		{
			"editor.background": theme.palette.background.paper,
			...monacoThemeColors
		},
		theme.palette.mode
	);
};

const StyledActionButton = styled(IconButton)({
	color: "text.secondary",
	width: 20,
	height: 24
});

interface CoreEditorProp {
	language: string;
	value: string;
	handleBeforeMount?: (monaco: Monaco) => void;
	monacoThemeColors?: editor.IColors;
	handleOnMount?: (
		_editor: editor.IStandaloneCodeEditor,
		monaco: Monaco
	) => void;
	monacoRef?: Monaco | null;
	editorRef?: editor.IStandaloneCodeEditor | null;
	setMonacoRef?: (val: Monaco | null) => void;
	setEditorRef?: any; // (val: editor.IStandaloneCodeEditor | null) => void;
	handleClickListener?: (data: any) => void;
	handleScrollListener?: (data: any) => void;
	handleSelectionListener?: (data: any) => void;
	handleHoverListener?: (data: any) => void;
	onReady?: (val: boolean) => void;
	onChange?: (val: any) => void;
	controlsBarText?: string;
	rootStyles?: object;
	editorOptions?: object;
	hideControlsBar?: boolean;
	placeholder?: string;
}

const CodeEditorCore: React.FC<CoreEditorProp> = ({
	language = "sol",
	value = "",
	handleBeforeMount,
	monacoThemeColors,
	handleOnMount,
	editorRef,
	monacoRef,
	setMonacoRef,
	setEditorRef,
	handleClickListener,
	handleScrollListener,
	handleSelectionListener,
	handleHoverListener,
	onReady,
	onChange,
	controlsBarText,
	rootStyles = {},
	editorOptions = {},
	hideControlsBar,
	placeholder
}) => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const toolTipStyles = {
		tooltip: {
			sx: {
				[`&.${tooltipClasses.tooltip}`]: {
					border: `1px solid ${theme.palette.primary.dark}`,
					borderRadius: "8px",
					backgroundColor: theme.palette.background.paper
				}
			}
		}
	};

	// Initialize Click Listener
	useEffect(() => {
		let _clickListener: any = null;

		_clickListener?.dispose?.();

		if (!!editorRef) {
			// Get where the user has clicked
			_clickListener = listenClickLocation(editorRef, (data) =>
				handleClickListener?.(data)
			);
		}

		return () => {
			_clickListener?.dispose?.();
		};
	}, [editorRef, handleClickListener]);

	// Initialize Scroll Listener
	useEffect(() => {
		let _scrollListener: any = null;

		_scrollListener?.dispose?.();

		if (!!editorRef && !!handleScrollListener) {
			// Get where the user has clicked
			_scrollListener = listenScrollPosition(editorRef, (data) =>
				handleScrollListener?.(data)
			);
		}

		return () => {
			_scrollListener?.dispose?.();
		};
	}, [editorRef, handleScrollListener]);

	// Initialize Hover Listener
	useEffect(() => {
		let _hoverListener: any = null;

		_hoverListener?.dispose?.();

		if (!!editorRef && !!handleHoverListener) {
			// Get where the user has clicked
			_hoverListener = listenHoverLocation(editorRef, (data) => {
				handleHoverListener?.(data);
			});
		}

		return () => {
			_hoverListener?.dispose?.();
		};
	}, [editorRef, handleHoverListener]);

	// Initialize Selection Listener
	useEffect(() => {
		let _selectionListener: any = null;

		_selectionListener?.dispose?.();

		if (!!editorRef && !!handleSelectionListener) {
			// Get where the user has clicked
			_selectionListener = listenEditorSelection(editorRef, (data) =>
				handleSelectionListener?.(data)
			);
		}

		return () => {
			_selectionListener?.dispose?.();
		};
	}, [editorRef, handleSelectionListener]);

	useEffect(() => {
		if (monacoRef) {
			createMonacoTheme(monacoRef, theme, monacoThemeColors);
		}
	}, [monacoRef, monacoThemeColors, theme, themeMode]);

	return (
		<Stack
			sx={{
				position: "relative",
				height: "100%",
				pt: 0.1,
				...searchStyles(theme),
				...blinkLinesStyles(theme),
				...rootStyles
			}}
		>
			{/* Editor */}
			<Box sx={{ minHeight: 0, height: "100%" }}>
				<MemoizedEditor
					className="editor"
					theme="aw-theme"
					defaultLanguage={language}
					language={language}
					defaultValue=""
					value={value}
					options={{
						lineNumbersMinChars: 1,
						scrollbar: {
							useShadows: false,
							verticalScrollbarSize: 10,
							horizontalScrollbarSize: 10
						},
						selectOnLineNumbers: true,
						readOnly: true,
						showFoldingControls: "always",
						roundedSelection: true,
						overviewRulerBorder: false,
						scrollBeyondLastLine: false,
						hover: { delay: 0 },
						contextmenu: true,
						copyWithSyntaxHighlighting: true,
						cursorStyle: "line-thin",
						domReadOnly: true,
						links: false,
						mouseStyle: "default",
						mouseWheelZoom: true,
						renderControlCharacters: false,
						glyphMargin: true,
						...editorOptions
						// automaticLayout: true
					}}
					beforeMount={(monaco) => {
						createMonacoTheme(monaco, theme, monacoThemeColors);

						handleBeforeMount?.(monaco);
					}}
					onMount={(_editor, _monaco) => {
						setEditorRef?.(_editor);
						setMonacoRef?.(_monaco);

						if (placeholder && !value) addPlaceholderOnMount();

						editorDidMount(_editor, _monaco);

						handleOnMount?.(_editor, _monaco);

						onReady?.(true);
					}}
					path="path"
					onChange={(val) => {
						if (placeholder) removePlaceholderOnChange?.(val);
						onChange?.(val);
					}}
				/>
			</Box>

			<Box
				className="monaco-placeholder"
				sx={{
					position: "absolute",
					display: "none",
					whiteSpace: "pre-wrap",
					top: 0,
					left: 52,
					fontSize: 14,
					color: "#608b4e",
					fontFamily: "Consolas, 'Courier New', monospace",
					pointerEvents: "none",
					userSelect: "none"
				}}
			>
				{placeholder}
			</Box>

			<Divider sx={{ borderColor: "background.nav" }} />

			{/* Controls Bar */}
			{!hideControlsBar && (
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					spacing={1}
					sx={{
						minHeight: "30px",
						px: 2,
						py: 0.3,
						bgcolor: "background.paper",
						width: "100%"
					}}
				>
					{controlsBarText && (
						<Typography variant="body2">{controlsBarText}</Typography>
					)}

					<Box sx={{ flex: 1 }} />

					{editorRef && (
						<Stack direction="row" alignItems="center">
							<Tooltip title="Zoom in" slotProps={toolTipStyles}>
								<StyledActionButton onClick={() => zoomIn(editorRef!)}>
									<ZoomInIcon sx={{ fontSize: 16 }} />
								</StyledActionButton>
							</Tooltip>

							<Tooltip title="Zoom out" slotProps={toolTipStyles}>
								<StyledActionButton onClick={() => zoomOut(editorRef!)}>
									<ZoomOutIcon sx={{ fontSize: 16 }} />
								</StyledActionButton>
							</Tooltip>

							<Tooltip
								title={
									<Typography
										variant="body2"
										sx={{ textAlign: "center", whiteSpace: "pre-line" }}
									>
										Fold all code{"\n(ctrl/cmd + L)"}
									</Typography>
								}
								slotProps={toolTipStyles}
							>
								<StyledActionButton onClick={() => foldCode(editorRef!)}>
									<UnfoldLessIcon sx={{ fontSize: 16 }} />
								</StyledActionButton>
							</Tooltip>

							<Tooltip
								title={
									<Typography
										variant="body2"
										sx={{ textAlign: "center", whiteSpace: "pre-line" }}
									>
										Expand all code{"\n(ctrl/cmd + shift + L)"}
									</Typography>
								}
								slotProps={toolTipStyles}
							>
								<StyledActionButton onClick={() => expandCode(editorRef!)}>
									<UnfoldMoreIcon sx={{ fontSize: 16 }} />
								</StyledActionButton>
							</Tooltip>
						</Stack>
					)}
				</Stack>
			)}
		</Stack>
	);
};

const MemoizedEditor = React.memo(Editor);

export default React.memo(CodeEditorCore);
