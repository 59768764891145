import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
	Button,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme
} from "@mui/material";
import { useAi } from "hooks/data/useAi";
import { useTerminal } from "hooks/ui/useTerminal";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { projectAtom } from "store/atoms/projectToolAtoms";
import { pocAIGeneratingAtom } from "store/atoms/testAtoms";
import ProjectFile from "../../../../Resources/ProjectFile";
import { getFileById, getRelevantFilesList } from "./helpers";

const ImportManager = () => {
	return (
		<Stack
			spacing={1}
			sx={{ bgcolor: "background.default", borderRadius: "10px", p: 1.5 }}
		>
			<Stack direction="row" alignItems="center" spacing={1} sx={{ pb: 0.5 }}>
				<Icon icon="uil:import" fontSize={20} />
				<Typography>Import Manager</Typography>
			</Stack>

			<Button
				variant="outlined"
				fullWidth
				sx={{
					height: 40,
					color: "inherit",
					borderColor: "divider",
					borderRadius: "8px"
				}}
			>
				Create a library/helper
			</Button>
		</Stack>
	);
};

const cheatCodes = [
	{
		name: "vm.warp",
		descriptionMarkdown:
			"Set block.timestamp.\n\n```\nvm.warp(uint256 timestamp)\n```"
	},
	{
		name: "skip",
		descriptionMarkdown:
			"Increase block.timestamp by specified seconds.\n\n```\nskip(uint256 time)\n```"
	},
	{
		name: "rewind",
		descriptionMarkdown:
			"Decrease block.timestamp by specified seconds.\n\n```\nrewind(uint256 time)\n```"
	},
	{
		name: "vm.roll",
		descriptionMarkdown:
			"Set block.number.\n\n```\nvm.roll(uint256 blockNumber)\n```"
	},
	{
		name: "vm.load",
		descriptionMarkdown:
			"Load a storage slot from an address.\n\n```\nvm.load(address account, bytes32 slot)\n```"
	},
	{
		name: "vm.store",
		descriptionMarkdown:
			"Store a value to an address' storage slot.\n\n```\nvm.store(address account, bytes32 slot, bytes32 value)\n```"
	},
	{
		name: "vm.etch",
		descriptionMarkdown:
			"Set code at address.\n\n```\nvm.etch(address addr, bytes calldata code)\n```"
	},
	{
		name: "vm.prank",
		descriptionMarkdown:
			"Set msg.sender for the next call.\n\n```\nvm.prank(address msgSender)\n```"
	},
	{
		name: "vm.startPrank",
		descriptionMarkdown:
			"Set msg.sender for subsequent calls.\n\n```\nvm.startPrank(address msgSender)\n```"
	},
	{
		name: "vm.stopPrank",
		descriptionMarkdown:
			"Reset msg.sender for subsequent calls.\n\n```\nvm.stopPrank()\n```"
	},
	{
		name: "changePrank",
		descriptionMarkdown:
			"Change msg.sender for subsequent calls.\n\n```\nchangePrank(address msgSender)\n```"
	},
	{
		name: "hoax",
		descriptionMarkdown:
			"Set msg.sender and give it ether for the next call.\n\n```\nhoax(address who)\nhoax(address who, uint256 give)\nhoax(address who, address origin)\nhoax(address who, address origin, uint256 give)\n```"
	},
	{
		name: "startHoax",
		descriptionMarkdown:
			"Set msg.sender and give it ether for subsequent calls.\n\n```\nstartHoax(address who)\nstartHoax(address who, uint256 give)\nstartHoax(address who, address origin)\nstartHoax(address who, address origin, uint256 give)\n```"
	},
	{
		name: "vm.mockCall",
		descriptionMarkdown:
			"Mock calls to an address `where`. If the call data `data` matches, return `retdata`.\n\n```\n// Without value\nvm.mockCall(address where, bytes calldata data, bytes calldata retdata);\n\n// With value\nvm.mockCall(address where, uint256 value, bytes calldata data, bytes calldata retdata);\n```"
	},
	{
		name: "vm.expectRevert",
		descriptionMarkdown:
			"Expect the next call to revert.\n\n```\nvm.expectRevert()\n\n// With message\nvm.expectRevert(bytes calldata message)\n\n// With custom error selector\nvm.expectRevert(bytes4 data)\n```"
	},
	{
		name: "vm.snapshot",
		descriptionMarkdown:
			"Take a snapshot of the current state.\n\n```\nuint256 snapshot = vm.snapshot();\n```"
	},
	{
		name: "vm.revertTo",
		descriptionMarkdown:
			"Restore the state.\n\n```\nvm.revertTo(uint256 snapshot);\n```"
	},
	{
		name: "deal",
		descriptionMarkdown:
			"Set ether balance for an address.\n\n```\ndeal(address to, uint256 balance)\n```"
	},
	{
		name: "dealERC721",
		descriptionMarkdown:
			"Give ERC721 token with `id` to an address.\n\n```\ndealERC721(address token, address to, uint256 id)\n```"
	},
	{
		name: "dealERC1155",
		descriptionMarkdown:
			"Set ERC1155 token balance for an address.\n\n```\ndealERC1155(address token, address to, uint256 id, uint256 balance)\ndealERC1155(address token, address to, uint256 id, uint256 balance, bool adjust)\n```"
	}
];

const AIAssitant = () => {
	const [projectFile, setprojectFile] = useState<ProjectFile>(null);
	const [promptText, setPromptText] = useState("");

	const [project] = useAtom(projectAtom);
	const [pocAIGenerating] = useAtom(pocAIGeneratingAtom);

	const { generateAITest } = useAi();

	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const defaultPrompt = "Write a test for each function in this contract";

	const handleGenerateClick = async () => {
		await generateAITest(projectFile, promptText || defaultPrompt);
	};

	return (
		<Stack
			spacing={1.5}
			sx={{ bgcolor: "background.default", borderRadius: "10px", p: 1.5 }}
		>
			<Stack direction="row" alignItems="center" spacing={1} sx={{ pb: 0.5 }}>
				<Icon icon="mingcute:magic-hat-fill" fontSize={20} />
				<Typography>AI Test Assistant</Typography>
			</Stack>

			<Select<ProjectFile>
				value={projectFile}
				displayEmpty
				onChange={(e) => setprojectFile(getFileById(project, e.target.value))}
				sx={{
					height: 40,
					borderRadius: "8px",
					...(themeMode === "light" && {
						bgcolor: "background.paper",
						boxShadow: 1,
						"& fieldset": {
							borderColor: "transparent"
						}
					})
				}}
				renderValue={(selectedValue) => {
					if (!selectedValue) {
						return <em>Select File</em>;
					}
					return selectedValue.name;
				}}
				MenuProps={{
					slotProps: {
						paper: {
							sx: {
								mt: 1,
								bgcolor: "background.paper",
								borderRadius: "8px",
								backgroundImage: "none",
								...(themeMode === "light" && {
									border: `1px solid ${theme.palette.background["paper2"]}`,
									boxShadow: 1
								}),
								...(themeMode === "dark" && {
									border: `2px solid ${theme.palette.primary.main}`,
									boxShadow: "none"
								})
							}
						}
					}
				}}
			>
				{getRelevantFilesList(project.rootFolder).map((file) => {
					return (
						<MenuItem value={file.id}>
							<Typography>{file.name}</Typography>
						</MenuItem>
					);
				})}
			</Select>

			<TextField
				value={promptText}
				onChange={(e) => setPromptText(e.target.value)}
				placeholder={defaultPrompt}
				label="Prompt"
				InputLabelProps={{ shrink: true }}
				multiline
				maxRows={3}
				InputProps={{
					sx: { borderRadius: "8px", bgcolor: "background.paper" }
				}}
			/>

			<LoadingButton
				variant="contained"
				disabled={!projectFile || pocAIGenerating}
				fullWidth
				loading={!!pocAIGenerating}
				sx={{
					height: 40,
					borderRadius: "8px"
				}}
				onClick={handleGenerateClick}
			>
				Generate test
			</LoadingButton>
		</Stack>
	);
};

const Cheatcodes = () => {
	const { openCustomTerminalTab } = useTerminal();

	const handleClick = (name, content) => {
		openCustomTerminalTab(`Cheatcode - ${name}`, `terminal/cheatcode/${name}`, {
			type: "cheatcode",
			content
		});
	};

	return (
		<Stack
			sx={{
				bgcolor: "background.default",
				borderRadius: "10px",
				p: 1.5
			}}
		>
			<Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1.5 }}>
				<Icon icon="ph:code-fill" fontSize={20} />
				<Typography>Foundry Cheatcodes List</Typography>
			</Stack>

			<Stack direction="row" flexWrap="wrap" rowGap={0.7} columnGap={1}>
				{cheatCodes.map((item, i) => (
					<Button
						variant="contained"
						size="small"
						key={i}
						onClick={() => handleClick(item.name, item.descriptionMarkdown)}
						sx={{
							py: 0,
							px: 1,
							minHeight: 0,
							borderRadius: "8px",
							cursor: "pointer",
							color: "text.primary",
							bgcolor: "background.paper2",
							boxShadow: 1,
							"&:hover": {
								bgcolor: "background.paper"
							}
						}}
					>
						{item.name}
					</Button>
				))}
			</Stack>
		</Stack>
	);
};

const TestBuilderTab = () => {
	return (
		<Stack spacing={1.5} sx={{ flex: 1 }}>
			{/* <ImportManager /> */}

			<AIAssitant />

			<Cheatcodes />
		</Stack>
	);
};

export default React.memo(TestBuilderTab);
