import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import InfoIcon from "@mui/icons-material/Info";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { isFileSolidity } from "Resources/Helpers";
import { mixpanelAtom } from "atoms";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { openedTabAtom } from "store/atoms/UiAtoms";
import { projectAtom } from "store/atoms/projectToolAtoms";
import hex from "utils/hexTransparency";
import { ToolboxCloseButton } from "../..";


import ImplementedScanToolItem from "./ScanToolItem/ImplementedScanTool";
import ToolScanResults from "./ScanResult";

import { getScanResultsForFile } from "Resources/ServerInterface";
import { decideShouldStopPolling } from "./helpers";

import SlitherLogoDark from "assets/icons/slitherDark.png";
import SlitherLogoLight from "assets/icons/slitherLight.png";

import AderynLogoDark from "assets/icons/aderynDark.png";
import AderynLogoLight from "assets/icons/aderynLight.png";

import AnalyzerLogoDark from "assets/icons/4naly3erDark.png";
import AnalyzerLogoLight from "assets/icons/4naly3erLight.png";
import ScanToolItem from "./ScanToolItem";

const CodeScanTool: React.FC = () => {
	const theme = useTheme();
	const themeMode = theme.palette.mode;

	const [loadingSlither, setLoadingSlither] = useState(false);
	const [slitherResults, setSlitherResultsFromChild] = useState({});
	const [loadingAderyn, setLoadingAderyn] = useState(false);
	const [aderynResults, setAderynResultsFromChild] = useState({});
	const [loading4naly3er, setLoading4naly3er] = useState(false);
	const [analyzerResults, setAnalyzerResultsFromChild] = useState({});

	const [mixpanel] = useAtom(mixpanelAtom);
	const [project] = useAtom(projectAtom);
	const [{ openedFile, openedTab }] = useAtom(openedTabAtom);
	const [resultsView, setResultsView] = useState<
		"slither" | "aderyn" | "4naly3er"
	>(null);
	const [endPolling, setEndPolling] = useState(false)

	useEffect(() => {
		setLoadingSlither(false);
		setLoadingAderyn(false);
		setLoading4naly3er(false);
		setResultsView(null);
	}, [openedFile]);

	const Header = ({ title = "Scan", backArrow = false }) => (
		<Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
			{backArrow && (
				<IconButton size="small" onClick={() => setResultsView(null)}>
					<ArrowBackIosNewIcon sx={{ fontSize: 16 }} />
				</IconButton>
			)}

			{openedFile?.name ? (
				<Typography sx={{ fontSize: 16 }}>
					{title}{" "}
					<Typography component="span" sx={{ fontSize: 16, fontWeight: 600 }}>
						{openedFile?.name}
					</Typography>
				</Typography>
			) : (
				<Typography variant="h6">Audit Wizard x Scan</Typography>
			)}

			<Box sx={{ flex: 1 }} />

			<ToolboxCloseButton />
		</Stack>
	);

	// Polling task statuses for currently opened file every x seconds
	const [polledToolTaskResults, setToolTaskData] = useState<any>(null);

	// TODO: Move this to its own hook
	useEffect(() => {
		if (endPolling == true) return

		let intervalId

		const pollToolTaskStatuses = async () => {
			try {
				const response = await getScanResultsForFile(
					openedTab.path,
					project.id
				);
				
				if (response?.info) {
					setToolTaskData(response)
				}
				if (response?.taskStatuses) {
					setToolTaskData(response);
					
					const shouldStopPolling = await decideShouldStopPolling(response.taskStatuses)
					if (shouldStopPolling) {
						setEndPolling(true)
						clearInterval(intervalId);
					}
				} 
			} catch (error) {
				setEndPolling(true)
				clearInterval(intervalId);
				console.log("Failed to poll task statuses data:", error);
			}
		};

		
		if (isFileSolidity(openedFile?.name) && !endPolling) {
			pollToolTaskStatuses();
			intervalId = setInterval(pollToolTaskStatuses, 3000);
		}

		return () => clearInterval(intervalId)

	}, [openedTab, openedFile, endPolling]);


	if (!isFileSolidity(openedFile?.name)) {
		return (
			<Box>
				<Header />
				<Typography sx={{ color: "success.light" }}>
					Please select a Solidity file.
				</Typography>
			</Box>
		);
	}

	// Post results-click views (piechart / findings / etc)
	if (resultsView === "slither") {
		return (
			<>
				<Header title="Scan results for" backArrow />

				<ToolScanResults scanResults={slitherResults} toolName="Slither" />
			</>
		);
	} else if (resultsView === "aderyn") {
		return (
			<>
				<Header title="Scan results for" backArrow />

				<ToolScanResults scanResults={aderynResults} toolName="aderyn" />
			</>
		);
	} else if (resultsView === "4naly3er") {
		return (
			<>
				<Header title="Scan results for" backArrow />

				<ToolScanResults scanResults={analyzerResults} toolName="4naly3er" />
			</>
		);
	}

	return (
		<>
			<Header />

			<Stack alignItems="center">
				<Typography>
					Select the type of scan you'd like to run so Audit Wizard can identify
					vulnerabilities and filter by severity so you can focus on what is
					most important
				</Typography>

				{/* Missing imports warning */}
				{project.missingImports?.length > 0 && (
					<Stack
						direction="row"
						spacing={1}
						sx={{
							borderRadius: "8px",
							p: 1.5,
							border: `2px solid ${theme.palette.warning.dark}${hex["30%"]}`,
							width: "100%",
							mt: 3
						}}
					>
						<InfoIcon fontSize="small" sx={{ color: "warning.main" }} />

						<Typography variant="body2">
							Warning - This project has missing imports. Scanning may fail if
							all imports are not resolved.
						</Typography>
					</Stack>
				)}

				<ImplementedScanToolItem
					title="Slither"
					toolKey="slither"
					caption="Solidity source analyzer"
					image={themeMode === "dark" ? SlitherLogoDark : SlitherLogoLight}
					loading={loadingSlither}
					setLoading={setLoadingSlither}
					setResultsView={setResultsView}
					setResultsFromChild={setSlitherResultsFromChild}
					setEndPolling={setEndPolling}
					polledToolTaskResults={polledToolTaskResults}	
				/>

				<ImplementedScanToolItem
					title="4naly3er"
					toolKey="4naly3er"
					caption="Solidity source analyzer"
					image={themeMode === "dark" ? AnalyzerLogoDark : AnalyzerLogoLight}
					loading={loading4naly3er}
					setLoading={setLoading4naly3er}
					setResultsView={setResultsView}
					setResultsFromChild={setAnalyzerResultsFromChild}
					setEndPolling={setEndPolling}
					polledToolTaskResults={polledToolTaskResults}	
				/>

				<ImplementedScanToolItem
					title="aderyn"
					toolKey="aderyn"
					caption="Rust-based Solidity AST analyzer"
					image={themeMode === "dark" ? AderynLogoDark : AderynLogoLight}
					loading={loadingAderyn}
					setLoading={setLoadingAderyn}
					setResultsView={setResultsView}
					setResultsFromChild={setAderynResultsFromChild}
					setEndPolling={setEndPolling}
					polledToolTaskResults={polledToolTaskResults}
				/>

				{/* Future tool example */}
				{/* <ScanToolItem
					title="Example"
					caption="Solidity source analyzer"
					image={themeMode === "dark" ? AderynLogoDark : AderynLogoLight}
					disabled
					ButtonComponent={
						<Typography variant="body2" color="text.secondary">
							(coming soon)
						</Typography>
					}
				/> */}
			</Stack>
		</>
	);
};

export default CodeScanTool;
