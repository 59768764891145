import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
	Button,
	Divider,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography
} from "@mui/material";
import React, { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import CustomModal from ".";

interface PrivacyModeCodeNeededProps {
	open: boolean;
	onClose: () => void;
}

const PrivacyModeCodeNeededModal: React.FC<PrivacyModeCodeNeededProps> = ({
	open,
	onClose
}) => {
	const [keyField, setKeyField] = useState("");
	const [showKey, setShowKey] = useState(false);

	const handleClickShowKey = () => setShowKey(!showKey);
	const handleMouseDownShowKey = () => setShowKey(!showKey);

	const handleSubmitFeedback = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		secureLocalStorage.setItem("privateModeEncryptionKey", keyField);
		setKeyField("");
		onClose();
	};

	return (
		<CustomModal
			open={open}
			onClose={onClose}
			fullWidth
			paperSx={{ maxWidth: "500px", py: 4, borderRadius: "12px" }}
		>
			<Typography variant="h4" sx={{ textAlign: "center" }}>
				Enter your private mode encryption key
			</Typography>

			<Divider sx={{ my: 3 }} />

			<form onSubmit={handleSubmitFeedback}>
				<Stack spacing={2}>
					<TextField
						fullWidth
						placeholder="Private Mode Encryption Key"
						required
						type={showKey ? "text" : "password"}
						sx={{
							".MuiInputBase-root": {
								borderRadius: "10px"
							}
						}}
						size="small"
						value={keyField}
						onChange={(e) => setKeyField(e.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										size="small"
										onClick={handleClickShowKey}
										onMouseDown={handleMouseDownShowKey}
										edge="end"
									>
										{showKey ? (
											<VisibilityOff fontSize="small" />
										) : (
											<Visibility fontSize="small" />
										)}
									</IconButton>
								</InputAdornment>
							)
						}}
					/>

					<Button
						fullWidth
						variant="contained"
						sx={{
							color: "text.inverse",
							fontWeight: 600,
							borderRadius: "10px"
						}}
						type="submit"
						disabled={!(keyField.length === 32)}
					>
						{"Submit"}
					</Button>
				</Stack>
			</form>

			<Typography
				variant="body2"
				sx={{ textAlign: "center", color: "primary.light", mt: 3 }}
			>
				Your encryption key is needed to access and create private projects. If
				you have lost your key, you can reset private mode in the settings menu.
			</Typography>
		</CustomModal>
	);
};

export default PrivacyModeCodeNeededModal;
