import {
	RateLimitConfigNames,
	RateLimitSummaryType
} from "Resources/ServerInterfaceProfile";
import { atom } from "jotai";

type RateLimitSummaryRecord = Partial<{
	[key in RateLimitConfigNames]: RateLimitSummaryType;
}>;

export const rateLimitAtoms = atom<RateLimitSummaryRecord>({});
