import { ArrowBack } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { getUsername } from "../../Resources/Auth";
import { verifyEmail } from "../../Resources/ServerInterface";
import { usernameAtom } from "../../atoms";
import EmailVerificationForm, {
	EmailVerificationFormType
} from "../../components/forms/EmailVerificationForm";

interface Props {
	email: string;
	goBack: Function;
}

const EmailVerification: React.FC<Props> = ({ goBack, email }) => {
	const [loading, setLoading] = useState(false);

	const [_, setUsername] = useAtom(usernameAtom);

	const handleSubmit = async (data: EmailVerificationFormType) => {
		const { verification_code } = data;

		setLoading(true);
		verifyEmail(email, verification_code)
			.then(() => {
				setLoading(false);
				const username = getUsername();
				if (!!username) {
					setUsername(username);
				}
			})
			.catch(() => {
				setLoading(false);
				goBack?.();
			});
	};

	return (
		<Stack alignItems="center" justifyContent="center" spacing={3}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="flex-start"
				sx={{ width: "100%" }}
			>
				<Typography
					variant="h6"
					sx={{ flexGrow: 1, textAlign: "center" }}
				>
					<strong>Verify Your Email</strong>
				</Typography>
			</Stack>

			<Typography sx={{ flexGrow: 1, textAlign: "center" }}>
				Please enter the verification code sent to {email}
			</Typography>

			<EmailVerificationForm handleSubmit={handleSubmit} disabled={loading} />
		</Stack>
	);
};

export default EmailVerification;
