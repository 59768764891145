import { Box, SxProps, useTheme } from "@mui/material";
import React from "react";

const FadingBackground: React.FC<{
	status: "error" | "success";
	sx?: SxProps;
}> = ({ status, sx = {} }) => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				position: "absolute",
				top: -150,
				left: -100,
				height: 100,
				width: 150,
				borderRadius: "100%",
				boxShadow: `10px 10px 170px 10px ${
					status === "error"
						? theme.palette.error.main
						: theme.palette.success.main
				}`,
				...sx
			}}
		/>
	);
};

export default FadingBackground;
